import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectOption } from 'utils/forms';
import { number, object, string } from 'yup';
import { useDispatch } from 'react-redux';
import {
  assignTenantToSegmentProfile,
  deassignTenantToSegmentProfile,
  getTenantsBySegmentProfile,
} from 'store/actions/tenants';

/* NOTE: CC-1285 */
export default function useSegmentHandlers(
  selectOptions: { permissions?: SelectOption[]; tenants?: SelectOption[] },
  id: string
) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const segmentSchema = useMemo(
    () =>
      object().shape({
        tenant: string().required(),
        permissions: number().required(),
      }),
    []
  );

  const handleGetTenantsBySegment = useCallback(async () => {
    const owners = await dispatch(getTenantsBySegmentProfile(id));
    return owners;
  }, [dispatch, id]);

  const segmentFormTitles = useMemo(
    () => ({
      modalTitle: formatMessage({ id: 'segments.form.segment.manageTenants.title' }),
      fieldsetTitle: formatMessage({ id: 'segments.form.segment.manageTenants' }),
    }),
    [formatMessage]
  );

  const handleAddTenantToSegment = useCallback(
    async (tenant_id: string, permissions: string) => {
      return dispatch(
        assignTenantToSegmentProfile(id, {
          tenant_id,
          permissions,
        })
      );
    },
    [dispatch, id]
  );

  const handleDeleteTenantFromSegment = useCallback(
    async (tenantId) => {
      return dispatch(deassignTenantToSegmentProfile(id, tenantId));
    },
    [dispatch, id]
  );

  const segmentfields = useMemo(
    () => [
      {
        name: 'tenant',
        label: formatMessage({ id: 'segments.form.segment.tenant_id.label' }),
        placeholder: formatMessage({ id: 'segments.form.segment.tenant_id.placeholder' }),
        error: formatMessage({ id: 'segments.form.segment.tenant_id.error' }),
        options: selectOptions.tenants,
        gridClassName: 'fullwidth',
      },
      {
        name: 'permissions',
        label: formatMessage({ id: 'segments.form.segment.permissions.label' }),
        placeholder: formatMessage({ id: 'segments.form.segment.permissions.placeholder' }),
        error: formatMessage({ id: 'segments.form.segment.permissions.error' }),
        gridClassName: 'fullwidth',
        options: selectOptions.permissions,
      },
    ],
    [formatMessage, selectOptions]
  );

  return {
    handleGetTenantsBySegment,
    handleAddTenantToSegment,
    handleDeleteTenantFromSegment,
    segmentSchema,
    segmentFormTitles,
    segmentfields,
  };
}
