import styled from 'styled-components';

export const UsimEventsStyled = styled.div`
  .usim-events-row {
    /* min-width: 260px;
        display: flex;
        justify-content: space-between;
        align-items: center; */

    .usim-events-row-data {
      display: flex;
      justify-content: space-between;

      .usim-events-row-label {
        font-weight: bold;
        margin-right: 1em;
      }

      .usim-events-row-user {
        font-style: italic;
      }

      .usim-events-row-timestamp {
        text-align: right;
        margin-right: 1em;
      }
    }
  }
`;
