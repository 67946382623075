import React, { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'store';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { ObjectSchema, array, object, string } from 'yup';
import SelectField from 'components/Form/Field/SelectField';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useIntl } from 'react-intl';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { useFormikContext } from 'formik';
import TextFieldField from 'components/Form/Field/TextFieldField';
import FileUploadField from 'components/Form/Field/FileUploadField';
import { CreateGpgKeyRequest, createGpgKey, getGpgKeys } from 'store/actions/gpgKeys';
import FormDialog from 'components/Dialog/FormDialog';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { showSuccessToast } from 'store/actions/toast';

type CreateGpgKeyType = {
  name: string;
  /**is_private alias */
  type: 'true' | 'false';
  upload: File[];
  passphrase?: string;
};

const CreateGpgKeyDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();

  const handleOnSubmit = useCallback(
    async (formValues: CreateGpgKeyType) => {
      const payload: CreateGpgKeyRequest = {
        name: formValues.name,
        is_private: formValues.type === 'true',
        upload: formValues.upload,
        passphrase: formValues.passphrase,
      };
      const result = await dispatch(createGpgKey(payload));
      if (createGpgKey.fulfilled.match(result)) {
        dispatch(showSuccessToast());
        await dispatch(getGpgKeys({}));
        dialogClose();
      }
    },
    [dialogClose, dispatch]
  );

  const initialValues: CreateGpgKeyType = useMemo(
    () => ({
      type: 'false',
      name: '',
      passphrase: '',
      upload: [],
    }),
    []
  );

  const validationSchema: ObjectSchema<any> = useMemo(
    () =>
      object().shape({
        type: string().required(),
        name: string().required(),
        passphrase: string().when('type', {
          is: 'true',
          then: (fieldSchema: any) => fieldSchema.required(),
        }),
        upload: array().min(1).max(1).required(),
      }),
    []
  );

  return (
    <FormDialog
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      validationPrefix={'usims.form.gpgkeys'}
      alertPendingChanges={true}
      formComponent={<FormContent />}
    />
  );
};

const FormContent = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<CreateGpgKeyType>();
  const { formatMessage } = useIntl();

  return (
    <BaseFieldset label={''}>
      <TextFieldField name="name" />
      <GridContainer spacing={0}>
        <GridItem size={{ xs: 6 }} sx={{ pr: 1 }}>
          <SelectField
            name="type"
            fast
            fullWidth
            onChange={(e) => {
              if (e.target.value === 'false') {
                setFieldValue('passphrase', '');
                setFieldTouched('passphrase', false);
              }
            }}
          >
            <MenuItem value={'false'}>{formatMessage({ id: 'usims.form.gpgkeys.public' })}</MenuItem>
            <MenuItem value={'true'}>{formatMessage({ id: 'usims.form.gpgkeys.private' })}</MenuItem>
          </SelectField>
        </GridItem>
        <GridItem size={{ xs: 6 }} sx={{ pl: 1 }}>
          <TextFieldField name="passphrase" disabled={values['type'] === 'false'} fullWidth />
        </GridItem>
      </GridContainer>
      <FileUploadField name="upload" fast label={formatMessage({ id: 'usims.form.gpgkeys.file.label' })} />
    </BaseFieldset>
  );
};

export default CreateGpgKeyDialog;
