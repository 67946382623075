import { Thunk } from '.';
import { getFileName } from 'utils/getFileName';
import config from 'config';
import { exportViews } from './exportViews';
import { Segment } from 'store/models/segment';
import { AxiosRequestConfig } from 'axios';
import { fetchData } from './fetchData';
import { sentryLogError } from 'sentry';

/* NOTE: CC-1285 */
export const FILTERS_LOADING = 'SEGMENTS_FILTERS_LOADING';
export const FILTERS_SUCCESS = 'SEGMENTS_FILTERS_SUCCESS';
export const FILTERS_FAILURE = 'SEGMENTS_FILTERS_FAILURE';
export const FILTERS_SET = 'SEGMENTS_FILTERS_SET';
export const FILTERS_INJECT = 'SEGMENTS_FILTERS_INJECT';

export const SORT_SET = 'SEGMENTS_SORT_SET';

export const LIST_LOADING = 'SEGMENTS_LIST_LOADING';
export const LIST_SUCCESS = 'SEGMENTS_LIST_SUCCESS';
export const LIST_FAILURE = 'SEGMENTS_LIST_FAILURE';
export const LIST_CLEAR = 'SEGMENTS_LIST_CLEAR';

export const ENTITY_LOADING = 'SEGMENTS_ENTITY_LOADING';
export const ENTITY_SUCCESS = 'SEGMENTS_ENTITY_SUCCESS';
export const ENTITY_FAILURE = 'SEGMENTS_ENTITY_FAILURE';

export function filtersLoading() {
  return {
    type: FILTERS_LOADING,
  };
}

export function filtersSuccess(payload: unknown) {
  return {
    type: FILTERS_SUCCESS,
    payload,
  };
}

export function filtersFailure() {
  return {
    type: FILTERS_FAILURE,
  };
}

export function filtersSet(payload: unknown) {
  return {
    type: FILTERS_SET,
    payload,
  };
}

export function filtersInject(payload: unknown) {
  return {
    type: FILTERS_INJECT,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: LIST_FAILURE,
  };
}
export function listClear() {
  return {
    type: LIST_CLEAR,
  };
}

export function exportSegmentsData(filter: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const filtersQuery = filter.replace('&', '?');
    return dispatch(
      exportViews({
        fileName: getFileName('segments'),
        url: `${config.apis.exportSegments}`,
        data: null,
        filtersQuery,
      })
    );
  };
}

export function getSegment(id: Segment['id'], refresh: boolean = false): Thunk<Promise<void>> {
  return async (dispatch) => {
    if (!refresh) {
      dispatch({
        type: ENTITY_LOADING,
      });
    }
    const options: AxiosRequestConfig = {
      url: config.apis.getSegment.replace('{id}', id),
    };

    try {
      const segments = await dispatch(fetchData<{ items: Segment[] }>(options));
      if (!segments.items.length) {
        throw new Error('No segment found');
      }

      dispatch({
        type: ENTITY_SUCCESS,
        payload: segments.items[0],
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: ENTITY_FAILURE,
      });
    }
  };
}
