// React
import PropTypes from 'prop-types';

// Material
import MenuItem from '@material-ui/core/MenuItem';

// Style
import { InputStyled, OptionTitle } from './styled';

const getOptions = (options) =>
  options.map((option, index) => {
    if (option.title) {
      return <OptionTitle key={index}>{option.title}</OptionTitle>;
    } else {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    }
  });

const Input = ({
  id,
  label,
  error,
  variant,
  className,
  color,
  multiline,
  rows,
  rowsMax,
  size,
  required,
  disabled,
  defaultValue,
  type,
  autoComplete,
  autoFocus,
  inputProps,
  InputLabelProps,
  helperText,
  onChange,
  SelectProps,
  style,
  fullWidth,
  margin,
  options,
  ...props
}) => {
  return (
    <InputStyled
      id={id}
      label={label}
      error={error}
      variant={variant || 'outlined'}
      className={className}
      color={color}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      size={size || 'small'}
      required={required}
      disabled={disabled}
      defaultValue={defaultValue}
      type={type}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputProps={inputProps}
      InputLabelProps={InputLabelProps}
      helperText={helperText}
      onChange={onChange}
      SelectProps={SelectProps}
      style={style}
      fullWidth={fullWidth}
      margin={margin}
      select={options?.length > 0}
      {...props}
    >
      {options && getOptions(options)}
    </InputStyled>
  );
};

Input.propTypes = {
  children: PropTypes.element,
};

export default Input;
