import { useCallback, useMemo } from 'react';
import { string } from 'yup';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getUsers } from 'store/actions/users';
import { FiltersDrawer, FiltersDrawerItem } from '../Drawer';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue } from 'store/models/filters';
import { useUsersFiltersSelector } from 'store/selectors/users';

export default function Users() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const values = useUsersFiltersSelector();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'fullname',
        label: formatMessage({ id: 'users.filters.fullname' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['fullname'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'email',
        label: formatMessage({ id: 'users.filters.email' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['email'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
    ],
    [formatMessage, values]
  );

  const handleSubmit = useCallback(
    (filtersValues) => {
      dispatch(getUsers({ filterBy: filtersValues }));
    },
    [dispatch]
  );

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
