import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTenant, getTenantsList, reset } from 'store/actions/tenants';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Tenants';
import List from 'components/List';
import DropdownTools from 'components/DropdownTools';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { ic_business as ico_business } from 'react-icons-kit/md/ic_business';
import { RESOURCE } from 'store/models/modal';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList } from 'utils/permissionCodes';
import { getCountryLabel } from 'utils/getStatesCountries';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import config from 'config';
import LogoUpload from 'components/Modals/LogoUpload';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Button } from '@athonet/ui/components/Input/Button';
import EditTenant from 'components/Edit/Tenant/EditTenant';
import { useTenantFiltersSelector, useTenantsListSelector } from 'store/selectors/tenants';
import { useUserSelector, useCanUserCreateTenantSelector } from 'store/selectors/user';
import { Tenant } from 'store/models/tenant';

export default function Tenants() {
  const bootstrap = useBootstrapSelector();
  const userdata = useUserSelector();
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const {
    data: { data: tenants, page, total },
    state: loadingState,
  } = useTenantsListSelector();
  const filters = useTenantFiltersSelector();
  const { confirmationDialogOpen, dialogOpen } = useOverlay();
  const canUserCreateTenant = useCanUserCreateTenantSelector();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTenantsList({}));
  }, [bootstrap?.pageLimit, dispatch]);

  const handlePageChange = useCallback(
    (newPage) => {
      dispatch(getTenantsList({ page: newPage }));
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (orderQuery) => {
      dispatch(getTenantsList({ sortBy: orderQuery }));
    },
    [dispatch]
  );

  const handleRefresh = useCallback(() => {
    dispatch(getTenantsList({}));
  }, [dispatch]);

  const openBranding = useCallback(
    ({ id, name }) => {
      dialogOpen({
        title: `${formatMessage({ id: 'tenants.form.logoUpload.title' })} ${name}`,
        content: () => <LogoUpload resource={RESOURCE.TENANTS} id={id} />,
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, formatMessage]
  );

  const openEdit = useCallback(
    (idEdit) => {
      dialogOpen({
        title: formatMessage({ id: 'tenants.editTenant' }),
        content: () => <EditTenant tenantId={idEdit} />,
        'data-testid': 'edit-form',
        id: idEdit,
      });
    },
    [dialogOpen, formatMessage]
  );

  const openDelete = useCallback(
    ({ id, name }) => {
      confirmationDialogOpen({
        description: formatMessage({ id: 'tenants.deleteTenant.confirm' }),
        title: formatMessage({ id: 'tenants.deleteTenant.confirm.title' }),
        alertMessage: formatMessage({ id: 'tenants.itemsAffected' }, { element: name }),
        continueButtonText: formatMessage({ id: 'tenants.deleteTenant.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => void dispatch(deleteTenant(id)),
        severity: 'danger',
        dataTestid: 'confirm-delete-tenant',
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage]
  );

  const handleOpenCreate = useCallback(
    () =>
      dialogOpen({
        title: formatMessage({ id: 'tenants.newTenant' }),
        content: () => <EditTenant />,
        'data-testid': 'create-form',
      }),
    [dialogOpen, formatMessage]
  );

  const getTools = useCallback(
    (rowData) => {
      const options = checkPermissionsList(
        userdata?.permissions || [],
        [
          {
            icon: ico_edit,
            name: 'edit-tenant',
            label: formatMessage({ id: 'tenants.editTenant' }),
            action: () => openEdit(rowData.id),
            disabled: false,
            permissions: [U_PERMISSIONS.UPDATE_TENANT],
          },
          ...(bootstrap && bootstrap.branding_enabled
            ? [
                {
                  icon: ico_business,
                  name: 'branding',
                  label: formatMessage({ id: 'user.menu.branding' }),
                  action: () => openBranding(rowData),
                  disabled: false,
                  dataTestId: 'branding',
                },
              ]
            : []),
          {
            icon: ico_delete,
            name: 'delete-tenant',
            label: formatMessage({ id: 'tenants.deleteTenant' }),
            action: () => openDelete(rowData),
            disabled: false,
            permissions: [U_PERMISSIONS.DELETE_TENANT],
          },
        ],
        false
      );

      return <DropdownTools options={options} />;
    },
    [bootstrap, formatMessage, openBranding, openDelete, openEdit, userdata?.permissions]
  );

  const columns = useMemo(() => {
    const initialCols = [
      {
        key: 'id',
        title: 'ID',
        dataKey: 'id',
        width: 200,
        maxWidth: 300,
        minWidth: 100,
        visible: false,
      },
      {
        key: 'type',
        title: formatMessage({ id: 'tenants.table.type' }),
        dataKey: 'type',
        cellRenderer: ({ cellData: type }: { cellData: string }) => {
          let label;
          switch (type) {
            case 'master':
              label = formatMessage({ id: 'common.master' });
              break;
            case 'channel_partner':
              label = formatMessage({ id: 'common.channelPartner' });
              break;
            case 'network_manager':
              label = formatMessage({ id: 'common.networkManager' });
              break;
            default:
              label = '';
              break;
          }
          return <Chip id={label} size="small" label={label} color="secondary" />;
        },
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        permissions: [T_PERMISSIONS.MASTER],
        visible: false,
      },
      {
        key: 'name',
        title: formatMessage({ id: 'tenants.table.tenantName' }),
        dataKey: 'name',
        sortable: true,
        defaultSort: 'asc', // set the columns sorted as default
        width: 200,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
      },
      {
        key: 'parent',
        title: formatMessage({ id: 'tenants.table.parent' }),
        dataKey: 'parent_tenant_name',
        sortable: true,
        width: 200,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
        permissions: [T_PERMISSIONS.MASTER],
      },
      {
        key: 'address',
        title: formatMessage({ id: 'tenants.table.address' }),
        dataKey: 'address',
        sortable: true,
        width: 400,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
      },
      {
        key: 'country',
        title: formatMessage({ id: 'tenants.table.country' }),
        dataKey: 'country',
        sortable: true,
        width: 300,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
        cellRenderer: ({ cellData: country }: { cellData: string }) => {
          return getCountryLabel(country);
        },
      },
      {
        key: 'created_at',
        title: formatMessage({ id: 'tenants.table.creation' }),
        dataKey: 'created_at',
        sortable: true,
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        cellRenderer: ({ cellData }: { cellData: string }) =>
          cellData && <Moment format={localizedConfig.fullDateFormat}>{cellData}</Moment>,
      },
      {
        key: 'tools',
        title: '',
        dataKey: 'tools',
        width: 60,
        maxWidth: 60,
        minWidth: 60,
        secret: true, // secret used to hide from columns management panel
        cellRenderer: ({ rowData }: { rowData: Tenant }) => {
          return getTools(rowData);
        },
      },
    ];
    return checkPermissionsList([userdata?.tenant_type], initialCols);
  }, [formatMessage, getTools, localizedConfig.fullDateFormat, userdata]);

  return (
    <List
      key="tenant-list"
      columns={columns}
      totalRows={total}
      data={tenants}
      page={page}
      filters={filters}
      onOrderChange={handleSortChange}
      loadingState={loadingState}
      {...(canUserCreateTenant && {
        createComponent: (
          <Button
            onClick={handleOpenCreate}
            text={formatMessage({ id: 'tenants.newTenant' })}
            data-testid="toolbar-new-button"
            startIcon="Add"
            variant="outlined"
          />
        ),
      })}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      toolbar={{ actions: false }}
      rowsPerPage={bootstrap?.pageLimit}
      onRefresh={handleRefresh}
    />
  );
}
