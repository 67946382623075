import { parseError } from 'utils/errorString';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AxiosError, AxiosRequestConfig } from 'axios';
import config from 'config';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { GetResultsRes } from 'store/models/filters';
import { Service, ServiceProfile } from 'store/models/serviceProfile';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { sentryLogError } from 'sentry';

export enum SERVICE_PROFILES_ACTION_TYPE {
  FILTERS_SET = 'SERVICE_PROFILES_FILTERS_SET',
  SORT_SET = 'SERVICE_PROFILES_SORT_SET',
  LIST_LOADING = 'SERVICE_PROFILES_LIST_LOADING',
  LIST_SUCCESS = 'SERVICE_PROFILES_LIST_SUCCESS',
  LIST_FAILURE = 'SERVICE_PROFILES_LIST_FAILURE',
  LIST_CLEAR = 'SERVICE_PROFILES_LIST_CLEAR',
  RESET = 'SERVICE_PROFILES_RESET',
}

export function filtersSet(payload: unknown) {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.FILTERS_SET,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.LIST_FAILURE,
  };
}

export function listClear() {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.LIST_CLEAR,
  };
}

export function reset() {
  return {
    type: SERVICE_PROFILES_ACTION_TYPE.RESET,
  };
}

export function getServiceProfiles(nodeId: string, page = 0): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: SERVICE_PROFILES_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getServiceProfiles.replace('{node_id}', nodeId),
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<ServiceProfile>>(options))), page };

      dispatch({
        type: SERVICE_PROFILES_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({ type: SERVICE_PROFILES_ACTION_TYPE.LIST_FAILURE });
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    }
  };
}
export function getServiceProfilesOptions(nodeId: string): Thunk<Promise<AutocompleteItemProps[]>> {
  return async (dispatch) => {
    dispatch({
      type: SERVICE_PROFILES_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getServiceProfiles.replace('{node_id}', nodeId),
      };

      const result = await dispatch(fetchData<GetResultsRes<ServiceProfile>>(options));

      return result.items.map((item) => ({ label: item.name, value: item.id }));
    } catch (e) {
      sentryLogError(e);
      return [];
    }
  };
}

function editServiceProfileFetcher(options: AxiosRequestConfig, nodeId: string): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      await dispatch(fetchData(options));
      await dispatch(getServiceProfiles(nodeId));
      dispatch(showSuccessToast());
      return;
    } catch (e) {
      sentryLogError(e);

      const error = e as AxiosError;
      return parseError(error);
    }
  };
}

type CreateServiceProfilePayload = {
  nodeId: string;
  data: { name: string; slices: { name: string; differentiator: string; services: Omit<Service, 'id'>[] }[] };
};
export function createServiceProfile({ nodeId, data }: CreateServiceProfilePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createServiceProfile.replace('{node_id}', nodeId),
      method: 'POST',
      data: {
        name: data.name,
        slices: data.slices || [],
      },
    };

    return dispatch(editServiceProfileFetcher(options, nodeId));
  };
}

type EditServiceProfilePayload = {
  nodeId: string;
  serviceProfileId: string;
  data: { name: string };
};
export function editServiceProfile({
  nodeId,
  serviceProfileId,
  data,
}: EditServiceProfilePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.editServiceProfile
        .replace('{node_id}', nodeId)
        .replace('{service_profile_id}', serviceProfileId),
      method: 'PUT',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, nodeId));
  };
}

type CreateSlicePayload = {
  nodeId: string;
  serviceProfileId: string;
  data: { name: string; differentiator: string };
};
export function createSlice({ nodeId, serviceProfileId, data }: CreateSlicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createSlice.replace('{node_id}', nodeId).replace('{service_profile_id}', serviceProfileId),
      method: 'POST',
      data: { services: [], ...data },
    };

    return dispatch(editServiceProfileFetcher(options, nodeId));
  };
}

type EditSlicePayload = {
  nodeId: string;
  serviceProfileId: string;
  sliceId: string;
  data: { name: string; differentiator: string };
};
export function editSlice({
  nodeId,
  serviceProfileId,
  sliceId,
  data,
}: EditSlicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.editSlice
        .replace('{node_id}', nodeId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId),
      method: 'PUT',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, nodeId));
  };
}

type DeleteSlicePayload = {
  nodeId: string;
  serviceProfileId: string;
  sliceId: string;
};
export function deleteSlice({ nodeId, serviceProfileId, sliceId }: DeleteSlicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteSlice
        .replace('{node_id}', nodeId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId),
      method: 'DELETE',
    };

    const resError = await dispatch(editServiceProfileFetcher(options, nodeId));
    if (resError) {
      dispatch(showErrorToast({ message: resError }));
    }
  };
}

type CreateServicePayload = {
  nodeId: string;
  serviceProfileId: string;
  sliceId: string;
  data: Omit<Service, 'id'>;
};
export function createService({
  nodeId,
  serviceProfileId,
  sliceId,
  data,
}: CreateServicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createService
        .replace('{node_id}', nodeId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId),
      method: 'POST',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, nodeId));
  };
}

type EditServicePayload = {
  nodeId: string;
  serviceProfileId: string;
  sliceId: string;
  serviceId: string;
  data: Omit<Service, 'id'>;
};
export function editService({
  nodeId,
  serviceProfileId,
  sliceId,
  serviceId,
  data,
}: EditServicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.editService
        .replace('{node_id}', nodeId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId)
        .replace('{service_id}', serviceId),
      method: 'PUT',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, nodeId));
  };
}

type DeleteServicePayload = {
  nodeId: string;
  serviceProfileId: string;
  sliceId: string;
  serviceId: string;
};
export function deleteService({
  nodeId,
  serviceProfileId,
  sliceId,
  serviceId,
}: DeleteServicePayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteService
        .replace('{node_id}', nodeId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId)
        .replace('{service_id}', serviceId),
      method: 'DELETE',
    };

    const resError = await dispatch(editServiceProfileFetcher(options, nodeId));
    if (resError) {
      dispatch(showErrorToast({ message: resError }));
    }
  };
}
