import { Moment } from 'moment';

export type SourceNetwork = string;

export type SourceNetworkOperation = '+' | '-';

export type SourceNetworkAggregation = {
  source_network: SourceNetwork;
  operation: SourceNetworkOperation;
};

type CdrSeries<D> = {
  datetime: D;
};

export type CdrTrafficVolumeMetrics<T = string> = {
  uplink: T;
  downlink: T;
  totallink: T;
};

export type CdrTrafficVolumeMetricsField = keyof CdrTrafficVolumeMetrics;

export type CdrTrafficVolumeTotal<T = string> = CdrTrafficVolumeMetrics<T>;

export type CdrTrafficVolumeSeries<T = string, D = string> = (CdrSeries<D> & CdrTrafficVolumeMetrics<T>)[];

export type CdrImeiChangesMetrics = {
  imei_changes: number | null;
};

export type CdrImeiChangesMetricsField = keyof CdrImeiChangesMetrics;

export type CdrImeiChangesTotal = CdrImeiChangesMetrics;

export type CdrImeiChangesSeries<D = string> = (CdrSeries<D> & CdrImeiChangesMetrics)[];

export type CdrChangesHistory<D = string> = {
  datetime: D;
  from_imei: string;
  to_imei: string;
}[];

export type CdrPeriod = 'day' | 'week' | 'month' | 'year';

export type CdrSchemaGroupType = 'string' | 'datetime' | 'numeric' | string[] | 'boolean';

export type CdrRankingOrder<G, M> = {
  group_by: {
    field: string;
    value: G;
  };
  metric: {
    field: string;
    value: M;
  };
};

export type CdrRanking<G = string, M = number> = {
  top: CdrRankingOrder<G, M>[];
  bottom?: CdrRankingOrder<G, M>[];
};

export type CdrSchemaField = {
  field: string;
  sortable: boolean;
  type: CdrSchemaGroupType;
  label: string;
  pattern?: string;
  search?: true;
};

export type CdrAvailabilityPeriodRange<D = string> = {
  max: D | null;
  min: D | null;
};

export type CdrAvailabilityDates<D = string> = Record<CdrPeriod, CdrAvailabilityPeriodRange<D>>;

export type CdrSchema = {
  global: {
    group: string[];
    period: CdrPeriod[];
    default_period: {
      end_date: string;
      start_date: string;
      period: CdrPeriod;
    };
  };
  schema: CdrSchemaField[];
};

export type CdrDateRange = {
  start: Moment;
  end: Moment;
};

export type CdrFilter<T> = {
  group_by: string;
  group_filter: T;
};

export type CdrRankingGroupBy = string;

export type CdrMetricsField = CdrTrafficVolumeMetricsField | CdrImeiChangesMetricsField;

export type CdrRankingCalendarDate = Moment | null;

export type CdrGroupByOptions = {
  value: string;
  label?: string;
};

export type CdrMetricOptions<T extends CdrMetricsField> = {
  value: T;
  label: string;
};

export type CdrSearch = {
  field: string;
  results: string[];
};

export enum CDR_SOURCE_NETWORKS_AGGREGATION_TYPE {
  DIFFERENCE = 'DIFFERENCE',
  MVNO = 'MVNO',
  MNO = 'MNO',
}

export type CdrTrafficDifferenceTotal<T = string> = {
  type: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE;
  total: CdrTrafficVolumeTotal<T>;
};

export type CdrTrafficDifferenceSeries<T = string, D = string> = {
  type: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE;
  series: (CdrSeries<D> & CdrTrafficVolumeMetrics<T>)[];
};

export type CdrTimeSeriesSchema = {
  default_period: {
    end_datetime: string;
    start_datetime: string;
  };
  query_parameters: string[];
  max_datetime_range_sec: number;
  schema: Pick<CdrSchemaField, 'field' | 'label' | 'type'>[];
};

export type CdrTimeSeriesQueryData = {
  source_network: SourceNetwork;
  start_datetime: string;
  end_datetime: string;
  [key: string]: string | boolean;
};

export type CdrTimeSeriesDataItem = { seq_number: number } & Record<string, string | number | boolean>;

export type CdrTimeSeriesData = {
  next_page: string | null;
  prev_page: string | null;
  data: CdrTimeSeriesDataItem[];
};

export type CdrTimeSeriesQuery = {
  currentQuery: CdrTimeSeriesQueryData | null;
  defaultQuery: CdrTimeSeriesQueryData | null;
};
