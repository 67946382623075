import { useSelector } from 'react-redux';

export function useRolesSelector() {
  const roles = useSelector((state) => state.roles);
  return roles;
}

export function useRolesListSelector() {
  const rolesList = useSelector((state) => state.roles.list);
  return rolesList;
}
