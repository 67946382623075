import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { object, string, number } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';

const Create = ({ data, onSubmit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [data]);

  const apnRegExp = /^((\*)|([a-zA-Z0-9]+))((\.|-)([a-zA-Z0-9]*[a-zA-Z0-9])+)*$/;

  const schema = object().shape(
    {
      name: string().trim().required(),
      apn: string().matches(apnRegExp).required(),
      pdp_type: number().min(1).max(3).required(),
      //charging_characteristics: number(),
      id_qos_template_4g: string().required(),

      //pgw_allocation_profile: string(),
      //non_ip_template_id: string()
    },
    [['id_qos_template_4g']]
  );

  const initials = {
    name: '',
    status: '',
    apn: '',
    pdp_type: 1,
    //"charging_characteristics": "",
    id_qos_template_4g: '',
    //"pgw_allocation_profile": "",
    //"non_ip_template_id": ""
  };

  const pdpTypeOptions = [
    { value: 1, label: 'IPv4' },
    { value: 2, label: 'IPv6' },
    { value: 3, label: 'IPv4v6' },
  ];

  const fieldset = [
    {
      title: formatMessage({ id: 'segments.form.segment.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'segments.form.segment.name.label' }),
          placeholder: formatMessage({ id: 'segments.form.segment.name.placeholder' }),
          error: formatMessage({ id: 'segments.form.segment.name.error' }),
          gridClassName: 'fullwidth',
        },
      ],
    },
    {
      title: formatMessage({ id: 'segments.form.segment.apn.title' }),
      data: [
        {
          name: 'apn',
          label: formatMessage({ id: 'segments.form.segment.apn.label' }),
          placeholder: formatMessage({ id: 'segments.form.segment.apn.placeholder' }),
          error: formatMessage({ id: 'segments.form.segment.apn.error' }),
        },
        {
          name: 'pdp_type',
          label: formatMessage({ id: 'segments.form.segment.pdp_type' }),
          options: pdpTypeOptions,
        },
      ],
    },
    {
      title: formatMessage({ id: 'segments.form.segment.profile' }),
      data: [
        // {
        //   name: "charging_characteristics",
        //   label: formatMessage({id:"segments.form.segment.charging_characteristics"}),
        //   placeholder: formatMessage({id:"segments.form.segment.charging_characteristics.placeholder"}),
        //   error: formatMessage({id:"segments.form.segment.charging_characteristics.error"}),
        //   type: "number",
        //   gridClassName: "fullwidth",
        // },
        {
          name: 'id_qos_template_4g',
          label: formatMessage({ id: 'segments.form.segment.id_qos_template_4g.label' }),
          placeholder: formatMessage({ id: 'segments.form.segment.id_qos_template_4g.placeholder' }),
          error: formatMessage({ id: 'segments.form.segment.id_qos_template_4g.error' }),
          gridClassName: 'fullwidth',
          options: data.qos_templates_4g,
        },
        /*{
          name: "pgw_allocation_profile",
          label: formatMessage({id:"segments.form.segment.pgw_allocation_profile"}),
          placeholder: formatMessage({id:"segments.form.segment.pgw_allocation_profile.placeholder"}),
          error: formatMessage({id:"segments.form.segment.pgw_allocation_profile.error"}),
          gridClassName: "fullwidth",
          options: data.pgw_allocation_profiles
        },
        {
          name: "non_ip_template_id",
          label: formatMessage({id:"segments.form.segment.non_ip_template_id"}),
          placeholder: formatMessage({id:"segments.form.segment.non_ip_template_id.placeholder"}),
          error: formatMessage({id:"segments.form.segment.non_ip_template_id.error"}),
          gridClassName: "fullwidth",
          options: data.non_ip_templates_id
        }*/
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onSubmit}
        modalTitle={formatMessage({ id: 'segments.newSegment' })}
        buttonLabel={formatMessage({ id: 'segments.newSegment' })}
      />
    </FormStyled>
  );
};

Create.propTypes = {
  data: PropTypes.any,
};

export default Create;
