import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const { status, data, message } = useRouteError() as {
    status: number;
    data: string;
    message?: string;
  };

  return (
    <Stack fullWidth sx={{ pt: 20 }} align="center">
      <Stack justify="flex-start" spacing={10}>
        <Box>
          <Box sx={{ width: 280 }}>
            <Logo width={280} />
          </Box>
        </Box>
        <Box sx={{ maxWidth: { xs: 280, sm: 600 } }}>
          <Text type="h1" fontWeight={'bold'} fontSize={160}>
            {status || 500}
          </Text>
          <Text type="h3">{data || message}</Text>
        </Box>
      </Stack>
    </Stack>
  );
}
