import {
  useCdrImeiChangesHistory,
  useCdrImeiChangesPeriod,
  useCdrImeiChangesRankingCalendarDate,
  useDescImeiChangesHistory,
} from 'store/selectors/cdrReports/imeiChanges';
import { isEntityLoading } from 'store/reducers';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { TimeLine } from '@athonet/ui/components/Data/TimeLine';
import { Progress } from '@athonet/ui/components/Feedback/Progress';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { DrawerContent } from '@athonet/ui/components/Overlay/Drawer/DrawerContent';
import { useCallback, useEffect } from 'react';
import { getCdrImeiChangesHistory } from 'store/actions/cdrReports/imeiChanges';
import { useDispatch } from 'react-redux';
import { getTooltipDateTitle } from '../utils/utils';
import { Moment } from 'moment';
import {
  getCdrActiveSourceNetworkAggregation,
  useCdrActiveSourceNetwork,
} from 'store/selectors/cdrReports/sourceNetwork';

type TimeLineDrawerContentProps = {
  imsi: string;
};

export function TimeLineDrawerContent({ imsi }: TimeLineDrawerContentProps) {
  const dispatch = useDispatch();
  const cdrImeiChangesHistory = useCdrImeiChangesHistory();
  const descImeiChangesHistory = useDescImeiChangesHistory();
  const imeiChangesPeriod = useCdrImeiChangesPeriod();
  const activeSourceNetwork = useCdrActiveSourceNetwork();
  const imeiChangesRankingCalendarDate = useCdrImeiChangesRankingCalendarDate();

  useEffect(() => {
    if (!imeiChangesRankingCalendarDate || !activeSourceNetwork) {
      return;
    }
    dispatch(
      getCdrImeiChangesHistory({
        start_date: imeiChangesRankingCalendarDate,
        end_date: imeiChangesRankingCalendarDate,
        period: imeiChangesPeriod,
        imsi: imsi,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [dispatch, imeiChangesPeriod, imsi, activeSourceNetwork, imeiChangesRankingCalendarDate]);

  const getFormattedDateRange = useCallback(
    (date?: Moment) => {
      const format = getTooltipDateTitle(imeiChangesPeriod);
      return date?.format(format);
    },
    [imeiChangesPeriod]
  );

  return (
    <DrawerContent>
      {isEntityLoading(cdrImeiChangesHistory || !descImeiChangesHistory.length) ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Progress type="circular" />
        </Box>
      ) : (
        <Stack spacing={2} nowrap justify="center" align="center" sx={{ pt: 2 }}>
          {imeiChangesRankingCalendarDate && (
            <Text type="body1" color="text.secondary">
              {getFormattedDateRange(imeiChangesRankingCalendarDate)}
            </Text>
          )}
          <Chip id="filter" label={`IMSI: ${imsi}`} color="secondary" />
          {descImeiChangesHistory.length && <TimeLine series={descImeiChangesHistory} />}
        </Stack>
      )}
    </DrawerContent>
  );
}
