import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';

// Style
import { FormStyled } from '../styled';

const Edit = ({ data, dataOptions, isOpen, onClose, onEdit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [data, dataOptions]);

  const schema = object().shape(
    {
      name: string().trim().required(),
      //charging_characteristics: string(),
      id_qos_template_4g: string().required(),
      //pgw_allocation_profile: string(),
      //non_ip_template_id: string()
    },
    [['id_qos_template_4g']]
  );

  const initials = {
    name: data?.name,
    //charging_characteristics: data?.charging_characteristics,
    id_qos_template_4g: data?.qos_template_4g?.id,
    pgw_allocation_profile: data?.pgw_allocation_profile?.id,
    non_ip_template_id: data?.non_ip_template?.id,
  };

  /* NOTE: CC-1285 */

  const segmentNodes = data?.nodes.map((n) => n.id);

  const availableQoSProfiles4G =
    segmentNodes?.length > 0
      ? dataOptions.qos_templates_4g.filter((profile) => {
          if (profile.nodes.length === 0) {
            return false;
          } else {
            return profile.nodes?.reduce((acc, node) => {
              return acc && segmentNodes?.indexOf(node.id) >= 0;
            }, true);
          }
        })
      : dataOptions.qos_templates_4g;

  const fieldset = [
    {
      title: formatMessage({ id: 'segments.form.segment.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'segments.form.segment.name.label' }),
          placeholder: formatMessage({ id: 'segments.form.segment.name.placeholder' }),
          error: formatMessage({ id: 'segments.form.segment.name.error' }),
          gridClassName: 'fullwidth',
        },
      ],
    },
    {
      title: formatMessage({ id: 'segments.form.segment.profile' }),
      data: [
        // {
        //   name: "charging_characteristics",
        //   label: formatMessage({id:"segments.form.segment.charging_characteristics"}),
        //   placeholder: formatMessage({id:"segments.form.segment.charging_characteristics.placeholder"}),
        //   error: formatMessage({id:"segments.form.segment.charging_characteristics.error"}),
        //   gridClassName: "fullwidth"
        // },
        {
          name: 'id_qos_template_4g',
          label: formatMessage({ id: 'segments.form.segment.id_qos_template_4g.label' }),
          placeholder: formatMessage({ id: 'segments.form.segment.id_qos_template_4g.placeholder' }),
          error: formatMessage({ id: 'segments.form.segment.id_qos_template_4g.error' }),
          gridClassName: 'fullwidth',
          options: availableQoSProfiles4G,
        },
        /*{
          name: "pgw_allocation_profile",
          label: formatMessage({id:"segments.form.segment.pgw_allocation_profile"}),
          placeholder: formatMessage({id:"segments.form.segment.pgw_allocation_profile.placeholder"}),
          error: formatMessage({id:"segments.form.segment.pgw_allocation_profile.error"}),
          gridClassName: "fullwidth",
          options: availablePGWAllocationProfiles
        },
        {
          name: "non_ip_template_id",
          label: formatMessage({id:"segments.form.segment.non_ip_template_id"}),
          placeholder: formatMessage({id:"segments.form.segment.non_ip_template_id.placeholder"}),
          error: formatMessage({id:"segments.form.segment.non_ip_template_id.error"}),
          gridClassName: "fullwidth",
          options: availableNonIPTemplates
        }*/
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onEdit}
        modalTitle={formatMessage({ id: 'segments.actions.edit.title' })}
      />
    </FormStyled>
  );
};

Edit.propTypes = {
  data: PropTypes.any,
  dataOptions: PropTypes.any,
};

export default Edit;
