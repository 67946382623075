import en from 'translations/en.json';
import it from 'translations/it.json';
import de from 'translations/de.json';
import es from 'translations/es.json';
import fr from 'translations/fr.json';
import { lsGetLocale } from './localStorage';

export type Locale = 'en' | 'it' | 'fr' | 'de' | 'es';

export type LocaleOption = {
  name: string;
  locale: Locale;
  translations: Record<string, string>;
};

export const localeOptions: LocaleOption[] = [
  {
    name: 'Italiano',
    locale: 'it',
    translations: it,
  },
  {
    name: 'English',
    locale: 'en',
    translations: en,
  },
  {
    name: 'Français',
    locale: 'fr',
    translations: fr,
  },
  {
    name: 'Español',
    locale: 'es',
    translations: es,
  },
  {
    name: 'Deutsch',
    locale: 'de',
    translations: de,
  },
];

export const defaultLocale: Locale = 'en';

export function detectLocale(): Locale {
  const locale = lsGetLocale() || navigator.language.split('-')[0];
  const option = localeOptions.find((localeOption) => localeOption.locale === locale);

  if (option) {
    return option.locale;
  }

  return defaultLocale;
}

export function getTranslations(locale: Locale) {
  return localeOptions.find((localeOption) => localeOption.locale === locale)?.translations;
}
