import { Toast, TOAST_TYPE } from 'store/models/toast';

export enum TOAST_ACTION_TYPE {
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
}

export interface ShowToastAction {
  type: TOAST_ACTION_TYPE.SHOW_TOAST;
  payload: Toast;
}

export interface HideToastAction {
  type: TOAST_ACTION_TYPE.HIDE_TOAST;
}

export function showToast(payload: Toast) {
  return {
    type: TOAST_ACTION_TYPE.SHOW_TOAST,
    payload,
  };
}

export function showSuccessToast(
  payload: Omit<Toast, 'type'> = {
    message: 'toastr.successful',
    intlMessage: true,
  }
) {
  return showToast({
    ...payload,
    type: TOAST_TYPE.SUCCESS,
  });
}

export function showErrorToast(
  payload: Omit<Toast, 'type'> = {
    message: 'toastr.failure',
    intlMessage: true,
  }
) {
  return showToast({
    ...payload,
    type: TOAST_TYPE.ERROR,
  });
}

export function showWarningToast(
  payload: Omit<Toast, 'type'> = {
    message: 'toastr.warning',
    intlMessage: true,
  }
) {
  return showToast({
    ...payload,
    type: TOAST_TYPE.WARNING,
  });
}

export function showInfoToast(
  payload: Omit<Toast, 'type'> = {
    message: 'toastr.info',
    intlMessage: true,
  }
) {
  return showToast({
    ...payload,
    type: TOAST_TYPE.INFO,
  });
}

export function showLoadingToast(
  payload: Omit<Toast, 'type'> = {
    message: 'toastr.loading',
    intlMessage: true,
  }
) {
  return showToast({
    ...payload,
    type: TOAST_TYPE.LOADING,
  });
}

export function hideToast() {
  return {
    type: TOAST_ACTION_TYPE.HIDE_TOAST,
  };
}
