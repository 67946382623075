const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const convertBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return { value: 0, size: sizes[0] };
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  const size = sizes[i];

  return { value: value, size: size };
};

const sizes_bytes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const sizes_bits = ['Bits', 'Kbit', 'Mbit', 'Gbit', 'Tbit', 'Pbit', 'Ebit', 'Zbit', 'Ybit'];

export const sizeToString = (amount, what = 'bytes') => {
  let humanizedSizes = sizes_bytes;
  let k = 1024;

  if (amount === undefined) return '-';
  switch (what) {
    case 'bits':
      humanizedSizes = sizes_bits;
      k = 1000;
      break;
    case 'bytes':
    default:
      humanizedSizes = sizes_bytes;
      k = 1024;
      break;
  }

  if (amount === 0) return '0 ' + humanizedSizes[0];
  const i = parseInt(Math.floor(Math.log(amount) / Math.log(k)));
  return (amount / Math.pow(k, i)).toFixed(2) + ' ' + humanizedSizes[i];
};

export const bitRateToString = (amount) => {
  return sizeToString(amount, 'bits') + '/s';
};
