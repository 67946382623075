import { LoginState } from 'store/reducers/login';

export const U_PERMISSIONS = {
  ACTIVATE_USIM: 'ACTIVATE_USIM',
  ASSIGN_NODE: 'ASSIGN_NODE',
  DEASSIGN_NODE: 'DEASSIGN_NODE',
  ASSOCIATE_SITE_NODE: 'ASSOCIATE_SITE_NODE',
  BAR_USIM: 'BAR_USIM',
  CREATE_GPG_KEY: 'CREATE_GPG_KEY',
  CREATE_NETWORK_ELEMENT: 'CREATE_NETWORK_ELEMENT',
  CREATE_NODE: 'CREATE_NODE',
  CREATE_QOS_PROFILE: 'CREATE_QOS_PROFILE',
  CREATE_ROLE: 'CREATE_ROLE',
  CREATE_SEGMENT: 'CREATE_SEGMENT',
  CREATE_SITE: 'CREATE_SITE',
  CREATE_BILLING: 'CREATE_BILLING',
  CREATE_API_KEY: 'CREATE_API_KEY',
  CREATE_TENANT: 'CREATE_TENANT',
  CREATE_USER: 'CREATE_USER',
  CREATE_USIM_ORDER: 'CREATE_USIM_ORDER',
  CREATE_USIM_PROFILE: 'CREATE_USIM_PROFILE',
  CREATE_USIM: 'CREATE_USIM',
  DEACTIVATE_USIM: 'DEACTIVATE_USIM',
  DELETE_GPG_KEY: 'DELETE_GPG_KEY',
  DELETE_NETWORK_ELEMENT: 'DELETE_NETWORK_ELEMENT',
  DELETE_NODE: 'DELETE_NODE',
  DELETE_QOS_PROFILE: 'DELETE_QOS_PROFILE',
  DELETE_ROLE: 'DELETE_ROLE',
  DELETE_SEGMENT: 'DELETE_SEGMENT',
  DELETE_SITE: 'DELETE_SITE',
  DELETE_BILLING: 'DELETE_BILLING',
  DELETE_API_KEY: 'DELETE_API_KEY',
  DELETE_TENANT: 'DELETE_TENANT',
  DELETE_USER: 'DELETE_USER',
  DELETE_USIM_ORDER: 'DELETE_USIM_ORDER',
  DELETE_USIM_PROFILE: 'DELETE_USIM_PROFILE',
  DELETE_USIM: 'DELETE_USIM',
  DOWNLOAD_USIM_ORDER: 'DOWNLOAD_USIM_ORDER',
  READ_BULK_OPERATION: 'READ_BULK_OPERATION',
  READ_GPG_KEY: 'READ_GPG_KEY',
  READ_NETWORK_ELEMENT: 'READ_NETWORK_ELEMENT',
  READ_NODE: 'READ_NODE',
  READ_POOL: 'READ_POOL',
  READ_QOS_PROFILE: 'READ_QOS_PROFILE',
  READ_ROLE: 'READ_ROLE',
  READ_SEGMENT: 'READ_SEGMENT',
  READ_SITE: 'READ_SITE',
  READ_TRAFFIC: 'READ_TRAFFIC',
  READ_SIM_TRACKING: 'READ_SIM_TRACKING',
  READ_BILLING: 'READ_BILLING',
  READ_TENANT: 'READ_TENANT',
  READ_USER: 'READ_USER',
  READ_USIM_ORDER: 'READ_USIM_ORDER',
  READ_USIM_PROFILE: 'READ_USIM_PROFILE',
  READ_USIM: 'READ_USIM',
  UNBAR_USIM: 'UNBAR_USIM',
  UPDATE_NETWORK_ELEMENT: 'UPDATE_NETWORK_ELEMENT',
  UPDATE_NODE: 'UPDATE_NODE',
  UPDATE_QOS_PROFILE: 'UPDATE_QOS_PROFILE',
  UPDATE_ROLE: 'UPDATE_ROLE',
  UPDATE_SEGMENT: 'UPDATE_SEGMENT',
  UPDATE_SITE: 'UPDATE_SITE',
  UPDATE_BILLING: 'UPDATE_BILLING',
  UPDATE_API_KEY: 'UPDATE_API_KEY',
  UPDATE_TENANT: 'UPDATE_TENANT',
  UPDATE_TENANT_PARENT: 'UPDATE_TENANT_PARENT',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
  UPDATE_USIM_PROFILE: 'UPDATE_USIM_PROFILE',
  UPDATE_USIM: 'UPDATE_USIM',
  UPLOAD_USIM_ORDER: 'UPLOAD_USIM_ORDER',
  UPDATE_FEATURE_FLAGS: 'UPDATE_FEATURE_FLAGS',
  VIEW_DASHBOARD_MODULE: 'VIEW_DASHBOARD_MODULE',
  VIEW_HSS_MODULE: 'VIEW_HSS_MODULE',
  VIEW_NODE_MODULE: 'VIEW_NODE_MODULE',
  VIEW_REPORTS_TRAFFIC_MODULE: 'VIEW_REPORTS_TRAFFIC_MODULE',
  VIEW_REPORTS_TRAFFIC_DIFFERENCE_MODULE: 'VIEW_REPORTS_TRAFFIC_DIFFERENCE_MODULE',
  VIEW_REPORTS_IMEI_CHANGES_MODULE: 'VIEW_REPORTS_IMEI_CHANGES_MODULE',
  VIEW_BILLING_MODULE: 'VIEW_BILLING_MODULE',
  VIEW_SECURITY_MODULE: 'VIEW_SECURITY_MODULE',
  VIEW_SEGMENT_MODULE: 'VIEW_SEGMENT_MODULE',
  VIEW_SITE_MODULE: 'VIEW_SITE_MODULE',
  VIEW_TENANT_MODULE: 'VIEW_TENANT_MODULE',
  VIEW_USER_MODULE: 'VIEW_USER_MODULE',
  VIEW_USIM_MODULE: 'VIEW_USIM_MODULE',
  EXEC_MATERIALIZATION_RE_RUN: 'EXEC_MATERIALIZATION_RE_RUN',
  READ_METADATA_KEY: 'READ_METADATA_KEY',
  UPDATE_TENANT_LOGO: 'UPDATE_TENANT_LOGO',
};

export const T_PERMISSIONS = {
  MASTER: 'MASTER',
  CHANNEL_PARTNER: 'CHANNEL_PARTNER',
  NETWORK_MANAGER: 'NETWORK_MANAGER',
};

type ItemWithPermission<D> = D & {
  permissions?: string[];
  data?: {
    permissions?: string[];
  }[];
};

export function checkPermissionsList<D>(
  permissions: (string | undefined)[],
  list: ItemWithPermission<D>[],
  strict = false
): ItemWithPermission<D>[] {
  return list.reduce((res, item) => {
    if (!item.permissions && !item.data) {
      return [...res, item];
    }

    if (item.permissions) {
      // datalist

      const isAllowed = strict
        ? item.permissions.every((permission) => permissions.includes(permission.toUpperCase()))
        : item.permissions.some((permission) => permissions.includes(permission.toUpperCase()));

      if (isAllowed) {
        return [...res, item];
      }
    } else if (item.data) {
      // dataset

      const allowedData = checkPermissionsList(permissions, item.data, strict);

      if (allowedData.length > 0) {
        return [...res, { ...item, data: allowedData }];
      }
    }

    return res;
  }, [] as ItemWithPermission<D>[]);
}

export const checkPermissionToUse = (loginState: LoginState, permission: string | string[], strict = false) => {
  if (!loginState?.permissions) {
    return false;
  }
  if (Array.isArray(permission)) {
    // multiple permissions (array)

    if (strict) {
      return permission.every((r) =>
        loginState.permissions ? loginState.permissions.indexOf(r.toUpperCase()) >= 0 : false
      );
    } else {
      return loginState.permissions.some((r) => permission.indexOf(r.toUpperCase()) >= 0);
    }
  } else {
    // single permission (string)

    return loginState.permissions.includes(permission);
  }
};

export const checkTenantType = (tenantType: string = '', allowedTenantTypes: string[]) =>
  allowedTenantTypes.some((allowedTenantType) => tenantType.toUpperCase() === allowedTenantType);
