import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createTenant, editTenant, getParentTenants, getTenant } from 'store/actions/tenants';
import { Tenant, TENANT_TYPE } from 'store/models/tenant';
import { useCanUserUpdateTenantParentSelector, useUserCPTenantSelector, useUserSelector } from 'store/selectors/user';
import getStatesCountries from 'utils/getStatesCountries';
import { object, string } from 'yup';

type EditTenantProps = {
  tenantId?: Tenant['id'];
};

type FormValues = {
  parent: AutocompleteItemProps | null;
  type: AutocompleteItemProps | null;
  name: Tenant['name'];
  address: Tenant['address'];
  country: AutocompleteItemProps | null;
};

const countryOptions = getStatesCountries();

export default function EditTenant({ tenantId }: EditTenantProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { dialogClose } = useOverlay();
  const userdata = useUserSelector();
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [parentTenants, setParentTenants] = useState<Tenant[]>([]);
  const [parentOptions, setParentOptions] = useState<AutocompleteItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);
  const canUserUpdateTenantParent = useCanUserUpdateTenantParentSelector();
  const userCPTenant = useUserCPTenantSelector();

  useEffect(() => {
    async function populateTenant() {
      if (tenantId) {
        setIsLoading(true);
        const fetchedTenant = await dispatch(getTenant(tenantId));
        setTenant(fetchedTenant);
        setIsLoading(false);
      }
    }
    async function populateParentOptions() {
      setAreOptionsLoading(true);
      const fetchedParents: Tenant[] = await dispatch(getParentTenants());
      setParentTenants(fetchedParents);
      setParentOptions(
        fetchedParents.map((p) => ({
          label: p.name,
          value: p.id,
        }))
      );
      setAreOptionsLoading(false);
    }
    void populateParentOptions();
    void populateTenant();
  }, [dispatch, tenantId]);

  // SCHEMA: stateCountryShape, tenantOptionShape, autocompleteOptionShape
  const stateCountryShape = object()
    .shape({
      label: string(),
      value: string(),
      group: string(),
    })
    .nullable();

  const tenantOptionShape = object()
    .shape({
      label: string().required(),
      value: string().required(),
    })
    .nullable();

  const autocompleteOptionShape = object()
    .shape({
      label: string().required(),
      value: string().required(),
    })
    .nullable();

  const schema = useMemo(
    () =>
      object().shape({
        parent: tenantOptionShape.required(),
        type: autocompleteOptionShape.required(),
        name: string().required(),
        country: stateCountryShape,
        phone: string(),
      }),
    [autocompleteOptionShape, stateCountryShape, tenantOptionShape]
  );

  const typeOptions = useMemo(
    () => [
      {
        label: formatMessage({ id: 'tenants.type.channelPartners' }),
        value: TENANT_TYPE.CHANNEL_PARTNER as AutocompleteItemProps['value'],
      },
      {
        label: formatMessage({ id: 'tenants.type.networkManager' }),
        value: TENANT_TYPE.NETWORK_MANAGER as AutocompleteItemProps['value'],
      },
    ],
    [formatMessage]
  );

  const initialParentOption = useMemo(() => {
    if (tenant) {
      return parentOptions.find((p) => p.value === tenant.parent_id) || null;
    }
    if (userdata?.tenant_id && userCPTenant) {
      return parentOptions.find((p) => p.value === userCPTenant) || null;
    }
    return null;
  }, [parentOptions, tenant, userCPTenant, userdata?.tenant_id]);

  const initials: FormValues = useMemo(
    () =>
      tenant
        ? {
            parent: initialParentOption,
            type: typeOptions.find((option) => option.value === tenant.type) || null,
            name: tenant.name,
            address: tenant.address || '',
            country: countryOptions.find((country) => country.value === tenant.country) || null,
          }
        : {
            parent: initialParentOption,
            type: Boolean(userCPTenant) ? typeOptions[1] : null,
            name: '',
            address: '',
            country: null,
          },

    [initialParentOption, tenant, typeOptions, userCPTenant]
  );

  const handleSubmit = useCallback(
    ({ parent, type, country, ...values }: FormValues) => {
      const submitValues = {
        ...values,
        country: String(country?.value) || '',
        type: (String(type?.value) as TENANT_TYPE) || '',
        parent_id: String(parent?.value) || '',
      };

      if (tenantId) {
        void dispatch(editTenant({ values: submitValues, tenantId }));
      } else {
        void dispatch(createTenant(submitValues));
      }
      dialogClose();
    },
    [dialogClose, dispatch, tenantId]
  );

  const getInputTextsError = useCallback(
    //TODOPS use unique hook
    (inputname, errors, touched) => ({
      label: formatMessage({ id: `tenants.form.tenant.${inputname}.label` }),
      placeholder: formatMessage({ id: `tenants.form.tenant.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `tenants.form.tenant.${inputname}.error` }),
      }),
    }),

    [formatMessage]
  );

  const canTenantBeUpdated = useMemo(
    () => !tenantId || (tenant && tenant.type === TENANT_TYPE.NETWORK_MANAGER && canUserUpdateTenantParent),
    [canUserUpdateTenantParent, tenant, tenantId]
  );

  return (
    <Formik initialValues={initials} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize>
      {({ isSubmitting, values, setFieldValue, errors, touched }) => {
        return (
          <Form noValidate autoComplete="off">
            <DialogContent loading={isLoading}>
              <Stack fullWidth spacing={2} sx={{ pt: 2 }}>
                <Field name="parent">
                  {({ field }: FieldProps<AutocompleteItemProps>) => (
                    <Autocomplete
                      disabled={!canTenantBeUpdated || isSubmitting}
                      size="small"
                      {...field}
                      options={parentOptions}
                      onChange={(_, v) => {
                        if (!v) {
                          return;
                        }
                        const parentType = parentTenants.find((p) => p.id === v.value)?.type;

                        if (parentType === TENANT_TYPE.CHANNEL_PARTNER) {
                          setFieldValue('type', typeOptions[1]);
                        }
                        setFieldValue('parent', v);
                      }}
                      {...getInputTextsError('parent', errors, touched)}
                      loading={areOptionsLoading}
                      multiple={false}
                      freeSolo={false}
                    />
                  )}
                </Field>
                <Field name="type">
                  {({ field }: FieldProps<AutocompleteItemProps>) => {
                    // note: this field depends on parent selection in many ways
                    const parentType = parentTenants.find((p) => {
                      if (typeof values['parent'] === 'string') {
                        return false;
                      }
                      return p.id === values['parent']?.value;
                    })?.type;

                    const options =
                      values['parent'] && parentType === TENANT_TYPE.CHANNEL_PARTNER ? [typeOptions[1]] : typeOptions;

                    return (
                      <Autocomplete
                        disabled={Boolean(tenantId || isSubmitting || !values['parent'])}
                        size="small"
                        {...field}
                        options={options}
                        {...getInputTextsError('type', errors, touched)}
                        onChange={(_, v) => setFieldValue('type', v)}
                        freeSolo={false}
                      />
                    );
                  }}
                </Field>

                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('name', errors, touched)}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>

                <Field name="address">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('address', errors, touched)}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>

                <Field name="country">
                  {({ field }: FieldProps<AutocompleteItemProps>) => (
                    <Autocomplete
                      size="small"
                      {...field}
                      {...getInputTextsError('country', errors, touched)}
                      onChange={(_, v) => setFieldValue('country', v)}
                      disabled={isSubmitting}
                      options={countryOptions}
                      multiple={false}
                      freeSolo={false}
                    />
                  )}
                </Field>
              </Stack>
            </DialogContent>

            {!isLoading && (
              <DialogActions>
                <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                  <Button
                    variant="outlined"
                    data-testid="footer-cancel"
                    onClick={dialogClose}
                    text={formatMessage({ id: 'common.form.cancel' })}
                    disabled={isSubmitting}
                    loading={isSubmitting || isLoading}
                  />
                  <Button
                    data-testid="footer-continue"
                    type="submit"
                    text={formatMessage({ id: 'common.form.continue' })}
                    disabled={isSubmitting}
                    loading={isSubmitting || isLoading}
                  />
                </Stack>
              </DialogActions>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
