import styled from 'styled-components';

// Icon
import Icon from 'react-icons-kit';
import { IconProps } from '.';

export const IconStyled = styled(Icon)``;

export const SvgIco = styled.div<IconProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
