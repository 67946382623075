import styled from 'styled-components';

// Material
import { TextField } from '@material-ui/core';

export const InputStyled = styled(TextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    background-color: ${(props) => props.theme.input.background} !important;
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.input.background} inset !important;
  }

  .MuiInputBase-input {
    background-color: ${(props) => props.theme.input.background};
  }

  .MuiFormHelperText-root {
    position: absolute;
    top: 38px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.input.borderHover};
  }
`;

export const OptionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 10px 15px 2px;
`;
