import { useCallback, useMemo } from 'react';
import { checkPermissionsList, T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { NavigationDrawer, NavigationDrawerProps } from '@athonet/ui/components/Navigation/Drawer';
import { matchPath, useLocation } from 'react-router';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { NavigationItemProps } from '@athonet/ui/components/Navigation/Drawer/NavigationItem';
import { useNavigate } from 'react-router-dom';

export default function Navigation() {
  const userdata = useSelector((state) => state.user);
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const navigationItems: NavigationDrawerProps['items'] = useMemo(() => {
    if (!userdata || !bootstrap) {
      return [];
    }

    return checkPermissionsList([userdata.tenant_type].concat(userdata.permissions), [
      {
        value: '/home',
        label: formatMessage({ id: 'menu.home' }),
        iconName: 'Grid-1',
        permissions: [U_PERMISSIONS.VIEW_DASHBOARD_MODULE],
      },
      ...(bootstrap.athux_support_enabled || bootstrap.athonetos_support_enabled
        ? [
            {
              value: '/networks',
              label: formatMessage({ id: 'menu.networks' }),
              iconName: 'Network-Settings',
              permissions: [U_PERMISSIONS.VIEW_NODE_MODULE, U_PERMISSIONS.VIEW_HSS_MODULE],
              subItems: checkPermissionsList([userdata.tenant_type].concat(userdata.permissions), [
                {
                  value: '/networks/nodes',
                  label: formatMessage({ id: 'menu.networks.nodes' }),
                  permissions: [T_PERMISSIONS.MASTER, U_PERMISSIONS.VIEW_NODE_MODULE],
                },
              ]),
            },
          ]
        : []),
      ...(bootstrap.athux_support_enabled
        ? [
            {
              label: formatMessage({ id: 'menu.sites' }),
              iconName: 'Location-4',
              value: '/sites',
              permissions: [U_PERMISSIONS.VIEW_SITE_MODULE],
            },
          ]
        : []),
      /* NOTE: CC-1285 */
      ...(bootstrap.athux_support_enabled
        ? [
            {
              label: formatMessage({ id: 'menu.4g-provisioning' }),
              iconName: 'Serer-Connections',
              value: '/4g-provisioning',
              permissions: [U_PERMISSIONS.VIEW_USIM_MODULE, U_PERMISSIONS.VIEW_SEGMENT_MODULE],
              subItems: [
                ...checkPermissionsList([userdata.tenant_type].concat(userdata.permissions), [
                  {
                    value: '/4g-provisioning/usim-cards',
                    label: formatMessage({ id: 'menu.4g-provisioning.usim-cards' }),
                    permissions: [U_PERMISSIONS.VIEW_USIM_MODULE],
                  },
                  {
                    value: '/4g-provisioning/usim-profiles',
                    label: formatMessage({ id: 'menu.4g-provisioning.usim-profiles' }),
                    permissions: [U_PERMISSIONS.VIEW_USIM_MODULE],
                  },
                ]),
                ...checkPermissionsList([userdata.tenant_type].concat(userdata.permissions), [
                  {
                    value: '/4g-provisioning/apn-profiles',
                    label: formatMessage({ id: 'menu.4g-provisioning.apn-profiles' }),
                    permissions: [U_PERMISSIONS.VIEW_SEGMENT_MODULE],
                  },
                  {
                    value: '/4g-provisioning/qos-profiles',
                    label: formatMessage({ id: 'menu.4g-provisioning.qos-profiles' }),
                    permissions: [U_PERMISSIONS.VIEW_SEGMENT_MODULE],
                  },
                ]),
              ],
            },
          ]
        : []),
      ...(bootstrap.athonetos_support_enabled
        ? [
            {
              label: formatMessage({ id: 'menu.5g-provisioning' }),
              value: '/subscribers',
              iconName: 'Serer-Connections',
              // TODO: Update to 5g permissions once they are added
              permissions: [U_PERMISSIONS.VIEW_USIM_MODULE],
            },
          ]
        : []),

      ...(bootstrap.usim_orders && bootstrap.athux_support_enabled
        ? [
            {
              label: 'Usim Orders',
              iconName: 'Sim-Card1',
              value: '/usim-orders',
              permissions: [T_PERMISSIONS.MASTER],
              subItems: [
                {
                  value: '/usim-orders/usim-cards',
                  label: formatMessage({ id: 'menu.usim-orders.usim-cards' }),
                },
                {
                  value: '/usim-orders/gpg-keys',
                  label: formatMessage({ id: 'menu.usim-orders.gpg-keys' }),
                },
              ],
            },
          ]
        : []),
      ...(bootstrap.billing_service && bootstrap.athux_support_enabled
        ? [
            {
              label: formatMessage({ id: 'menu.billing' }),
              iconName: 'Dollar-Card',
              value: '/billing',
              permissions: [U_PERMISSIONS.VIEW_BILLING_MODULE],
              subItems: [
                {
                  value: '/billing/configuration',
                  label: formatMessage({ id: 'menu.billing.configuration' }),
                },
                {
                  value: '/billing/calculatedbills',
                  label: formatMessage({ id: 'menu.billing.calculatedbills' }),
                },
              ],
            },
          ]
        : []),
      ...((bootstrap.traffic_service || bootstrap.traffic_difference_service) && bootstrap.athux_support_enabled
        ? [
            {
              label: formatMessage({ id: 'menu.reports' }),
              iconName: 'Bar-Chart-2',
              value: '/reports',
              permissions: [
                U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_MODULE,
                U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_DIFFERENCE_MODULE,
                U_PERMISSIONS.VIEW_REPORTS_IMEI_CHANGES_MODULE,
              ],
              subItems: checkPermissionsList([userdata.tenant_type].concat(userdata.permissions), [
                ...(bootstrap.traffic_service
                  ? [
                      {
                        value: '/reports/traffic-volume',
                        label: formatMessage({ id: 'menu.reports.traffic-volume' }),
                        permissions: [U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_MODULE],
                      },
                    ]
                  : []),
                ...(bootstrap.traffic_difference_service
                  ? [
                      {
                        value: '/reports/traffic-difference',
                        label: formatMessage({ id: 'menu.reports.traffic-difference' }),
                        permissions: [U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_DIFFERENCE_MODULE],
                      },
                    ]
                  : []),
                ...(bootstrap.traffic_service
                  ? [
                      {
                        value: '/reports/imei-changes',
                        label: formatMessage({ id: 'menu.reports.imei-changes' }),
                        permissions: [U_PERMISSIONS.VIEW_REPORTS_IMEI_CHANGES_MODULE],
                      },
                    ]
                  : []),
              ]),
            },
          ]
        : []),
    ]) as NavigationDrawerProps['items'];
  }, [bootstrap, formatMessage, userdata]);

  const handleNavigationItemClick = useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const getMatchingItemValue = useCallback(
    (item: NavigationItemProps) => {
      const itemMatch = Boolean(
        matchPath(
          {
            path: item.value,
          },
          location.pathname
        )
      );
      if (Boolean(itemMatch)) {
        return item.value;
      }
      return;
    },
    [location.pathname]
  );

  const activeNavigationValue = useMemo(() => {
    let activeValue: string | undefined;
    navigationItems.forEach((item) => {
      activeValue = getMatchingItemValue(item) || activeValue;
      item.subItems?.forEach((subItem) => {
        activeValue = getMatchingItemValue(subItem) || activeValue;
      });
    });
    return activeValue;
  }, [getMatchingItemValue, navigationItems]);

  if (!bootstrap) {
    return null;
  }

  return (
    <NavigationDrawer
      items={navigationItems}
      onClick={handleNavigationItemClick}
      activeValue={activeNavigationValue}
      headerComponent={
        <Stack justify="center" align="flex-start" sx={{ pt: 2 }}>
          <Logo imageUrl={bootstrap.logo} width={208} />
        </Stack>
      }
      footerComponent={
        <Box sx={{ pb: 1 }}>
          <Text type="caption">v{process.env.REACT_APP_VERSION}</Text>
        </Box>
      }
    />
  );
}
