import { Table, TableRowKey } from '@athonet/ui/components/Data/Table';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { UsimEvent } from 'store/models/usimEvent';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { isEntityLoading } from 'store/reducers';
import { useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { useUsimEntitySelector } from 'store/selectors/usims/cards';
import { TAB } from '.';

export function UsimCardsIdEvents() {
  const { id } = useParams<DetailPageParams<TAB>>();
  const usimDetail = useUsimEntitySelector(id as string);

  if (!usimDetail) {
    return null;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Panel title="USIM Events">
        <PanelContent>
          <Table
            rowKey="timestamp"
            page={0}
            rowsPerPage={usimDetail.data?.events.length || 0}
            loading={isEntityLoading(usimDetail)}
            hidePagination={true}
            data={usimDetail.data?.events || []}
            rowsCount={usimDetail.data?.events.length || 0}
            orderBy="timestamp"
            columns={[
              {
                key: 'timestamp',
                label: 'Timestamp',
                cellRender: (event) => {
                  return <Text>{moment(event.timestamp).format('YYYY/MM/DD HH:mm:ss')}</Text>;
                },
              },
              {
                key: 'event_type',
                label: 'Event Type',
              },
              {
                key: 'operator.tenant.name' as TableRowKey<UsimEvent>,
                label: 'Tenant',
              },
              {
                key: 'operator.user.name' as TableRowKey<UsimEvent>,
                label: 'User',
              },
            ]}
          />
        </PanelContent>
      </Panel>
    </Box>
  );
}
