import { AxiosRequestConfig } from 'axios';
import {
  CdrPeriod,
  CdrDateRange,
  CdrRanking,
  CdrSchema,
  CdrTrafficVolumeSeries,
  CdrTrafficVolumeTotal,
  CdrFilter,
  CdrRankingGroupBy,
  CdrRankingCalendarDate,
  CdrTrafficVolumeMetricsField,
  CdrSearch,
  CdrAvailabilityDates,
  CdrTimeSeriesSchema,
  CdrAvailabilityPeriodRange,
  CdrTimeSeriesQueryData,
  CdrTimeSeriesQuery,
} from 'store/models/cdr';
import { Thunk } from '..';
import config from 'config';
import { fetchData } from '../fetchData';
import { showErrorToast } from '../toast';
import {
  CdrRankingPayload,
  CdrSeriesPayload,
  CdrTotalPayload,
  CDR_ACTION_TYPE,
  GetCdrSearchAction,
  GetCdrSeriesActionArgs,
  getCdrTimeseriesData,
} from '.';
import { getCdrTrafficVolumeRankingMetricOptions } from 'store/selectors/cdrReports/trafficVolume';
import { getFileName } from 'utils/getFileName';
import { exportViews } from '../exportViews';
import moment from 'moment';
import { sentryLogError } from 'sentry';

export function fetchCdrTrafficVolumeTotal(payload: CdrTotalPayload): Thunk<Promise<CdrTrafficVolumeTotal>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.total,
      method: 'POST',
      data: {
        ...payload,
        start_date: payload.start_date.format('YYYY-MM-DD'),
        end_date: payload.end_date.format('YYYY-MM-DD'),
      },
    };
    return dispatch(fetchData<CdrTrafficVolumeTotal>(options));
  };
}

export function getCdrTrafficVolumeTotal(payload: CdrTotalPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_LOADING });
      const trafficVolumeTotal = await dispatch(fetchCdrTrafficVolumeTotal(payload));
      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_SUCCESS,
        payload: trafficVolumeTotal,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_FAILURE });
    }
  };
}

export function fetchCdrTrafficVolumeSeries(payload: CdrTotalPayload): Thunk<Promise<CdrTrafficVolumeSeries>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.series,
      method: 'POST',
      data: {
        ...payload,
        start_date: payload.start_date.format('YYYY-MM-DD'),
        end_date: payload.end_date.format('YYYY-MM-DD'),
      },
    };
    return dispatch(fetchData<CdrTrafficVolumeSeries>(options));
  };
}

export function getCdrTrafficSeries({
  payload,
  actions: { loadingAction, successAction, failureAction },
}: GetCdrSeriesActionArgs<CdrSeriesPayload>): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({ type: loadingAction });
      const trafficVolumeSeries = await dispatch(fetchCdrTrafficVolumeSeries(payload));
      dispatch({
        type: successAction,
        payload: trafficVolumeSeries,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: failureAction });
    }
  };
}

export function getCdrTrafficVolumeTrendSeries(payload: CdrSeriesPayload) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_LOADING,
    successAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_SUCCESS,
    failureAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_FAILURE,
  };

  return getCdrTrafficSeries({ payload, actions });
}

export function getCdrTrafficVolumeRankingSeries(payload: CdrSeriesPayload) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_LOADING,
    successAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_SUCCESS,
    failureAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_FAILURE,
  };

  return getCdrTrafficSeries({ payload, actions });
}

export function getCdrTrafficVolumeAvailabilityDates(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.availability_dates,
      method: 'POST',
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_LOADING });
      const trafficVolumeAvailabilityDates = await dispatch(fetchData<CdrAvailabilityDates>(options));
      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_SUCCESS,
        payload: trafficVolumeAvailabilityDates,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_FAILURE });
    }
  };
}

export function getCdrTrafficVolumeSchema(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      cdrReportsTrafficVolume: {
        trafficVolumeRankingGroupBy,
        trafficVolumeRankingMetric,
        trafficVolumeDateRange,
        trafficVolumePeriod,
      },
    } = getState();

    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.schema,
      method: 'POST',
    };

    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_LOADING });
      const trafficVolumeSchema = await dispatch(fetchData<CdrSchema>(options));

      if (!trafficVolumePeriod) {
        dispatch(setCdrTrafficVolumePeriod(trafficVolumeSchema.global.default_period.period));
      }

      if (!trafficVolumeDateRange) {
        const start = moment(trafficVolumeSchema.global.default_period.start_date);
        const end = moment(trafficVolumeSchema.global.default_period.end_date);
        dispatch(
          setCdrTrafficVolumeDateRange({
            start,
            end,
          })
        );
      }

      if (!trafficVolumeRankingGroupBy) {
        dispatch(setCdrTrafficVolumeRankingGroupBy(trafficVolumeSchema.global.group[0]));
      }

      if (!trafficVolumeRankingMetric) {
        const trafficVolumeMetricOptions = getCdrTrafficVolumeRankingMetricOptions(trafficVolumeSchema.schema);
        dispatch(setCdrTrafficVolumeRankingMetric(trafficVolumeMetricOptions[0].value));
      }

      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_SUCCESS,
        payload: trafficVolumeSchema,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_FAILURE });
    }
  };
}

export function getCdrTrafficRanking({
  payload,
  actions: { loadingAction, successAction, failureAction },
}: GetCdrSeriesActionArgs<CdrRankingPayload>): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.ranking,
      method: 'POST',
      data: {
        ...payload,
        date: payload.date.format('YYYY-MM-DD'),
        type: 'collapsed_topbottom',
      },
    };

    try {
      dispatch({ type: loadingAction });
      const trafficVolumeRanking = await dispatch(fetchData<CdrRanking<string, string>>(options));
      dispatch({
        type: successAction,
        payload: trafficVolumeRanking,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: failureAction });
    }
  };
}

export function getCdrTrafficVolumeRanking(payload: CdrRankingPayload): Thunk<Promise<void>> {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_LOADING,
    successAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SUCCESS,
    failureAction: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_FAILURE,
  };

  return getCdrTrafficRanking({ payload, actions });
}

export function setCdrTrafficVolumeDateRange(payload: CdrDateRange) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_DATE_RANGE,
    payload,
  };
}

export function setCdrTrafficVolumePeriod(payload: CdrPeriod) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_PERIOD,
    payload,
  };
}

export function setCdrTrafficVolumeFilter(payload: CdrFilter<string>) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_FILTER,
    payload,
  };
}

export function setCdrTrafficVolumeRankingGroupBy(payload: CdrRankingGroupBy) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_GROUP_BY,
    payload,
  };
}

export function setCdrTrafficVolumeRankingMetric(payload: CdrTrafficVolumeMetricsField) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_METRIC,
    payload,
  };
}

export function setCdrTrafficVolumeRankingCalendarDate(payload: CdrRankingCalendarDate) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_CALENDAR_DATE,
    payload,
  };
}

export const getCdrTrafficVolumeSearch: GetCdrSearchAction = (payload) => {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.search,
      method: 'POST',
      data: {
        ...payload,
        limit: 100,
      },
    };

    try {
      return dispatch(fetchData<CdrSearch>(options));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return;
    }
  };
};

export function getCdrTrafficVolumeTimeseriesSchema(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { bootstrap } = getState();
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.timeseries.schema,
      method: 'POST',
    };

    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_LOADING });
      const trafficVolumeTimeseriesSchema = await dispatch(fetchData<CdrTimeSeriesSchema>(options));

      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_SUCCESS,
        payload: trafficVolumeTimeseriesSchema,
      });

      const initValues: Record<string, string | undefined> = {
        start_datetime: trafficVolumeTimeseriesSchema?.default_period.start_datetime,
        end_datetime: moment(trafficVolumeTimeseriesSchema?.default_period.start_datetime)
          .clone()
          .add(trafficVolumeTimeseriesSchema.max_datetime_range_sec, 'seconds')
          .toISOString(),
        source_network: bootstrap?.source_networks[0],
      };

      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SET_QUERY,
        payload: { currentQuery: initValues, defaultQuery: initValues },
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_FAILURE });
    }
  };
}

export function getCdrTrafficVolumeTimeseriesAvailabilityDates(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.timeseries.availabilityDates,
      method: 'POST',
    };

    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_LOADING });
      const trafficVolumeTimeseriesAvailabilityDates = await dispatch(fetchData<CdrAvailabilityPeriodRange>(options));

      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_SUCCESS,
        payload: trafficVolumeTimeseriesAvailabilityDates,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_FAILURE });
    }
  };
}

export function getCdrTrafficVolumeTimeseriesData(payload: CdrTimeSeriesQueryData, page: string, page_size: number) {
  const endpoint = config.cdrReportsApis.trafficVolume.timeseries.data;
  return getCdrTimeseriesData(endpoint, payload, page, page_size);
}

export function setTrafficVolumeTimeSeriesQuery(payload: CdrTimeSeriesQuery) {
  return { type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SET_QUERY, payload };
}

export function getTrafficVolumeTimeseriesExportData(payload: CdrTimeSeriesQueryData): Thunk<Promise<void>> {
  return async (dispatch) => {
    return dispatch(
      exportViews({
        fileName: getFileName('trafficData'),
        url: config.cdrReportsApis.trafficVolume.timeseries.exportData,
        data: payload,
        filtersQuery: '',
      })
    );
  };
}
