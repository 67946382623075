import { useState, useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Icon from 'components/Icon';

import { DropdownToolsStyle, MenuItemStyled } from './styled';

type DropdownToolsOptions = {
  icon: string;
  label: string;
  action: () => void;
  disabled: boolean;
  name: string;
};

type DropdownToolsProps = {
  options: DropdownToolsOptions[];
};

export default function DropdownTools({ options }: DropdownToolsProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const doAction = useCallback(
    (action) => {
      handleClose();
      action();
    },
    [handleClose]
  );

  return (
    <DropdownToolsStyle data-testid="dropdown-modal">
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        disabled={!options.length}
        data-testid="dropdown-tools"
      >
        <MoreVertIcon />
      </IconButton>

      {Boolean(anchorEl) && (
        <Menu
          data-testid="dropdown-menu"
          id="list-row-menu"
          anchorEl={anchorEl}
          keepMounted
          open={true}
          onClose={handleClose}
        >
          {options.map((item, index) => (
            <MenuItemStyled
              data-testid={item.name}
              key={index}
              onClick={() => doAction(item.action)}
              disabled={item.disabled}
            >
              <ListItemIcon>
                <Icon icon={item.icon} size={16} />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </MenuItemStyled>
          ))}
        </Menu>
      )}
    </DropdownToolsStyle>
  );
}
