import { useSelector } from 'react-redux';

/* NOTE: CC-1285 */
export function useSegmentsSelector() {
  const segments = useSelector((state) => state.segments);
  return segments;
}

export function useSegmentsListSelector() {
  const list = useSelector((state) => state.segments.list);
  return list;
}

export function useSegmentsFiltersSelector() {
  const filters = useSelector((state) => state.segments.filters);
  return filters;
}

export function useSegmentsEntitySelector() {
  const segment = useSelector((state) => state.segments.entity);
  return segment;
}
