import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue } from 'store/models/filters';
import { useCallback, useMemo } from 'react';
import { FiltersDrawer, FiltersDrawerItem } from '../Drawer';
import { string } from 'yup';
import { useDispatch } from 'react-redux';
import { getTenantsList } from 'store/actions/tenants';
import getStatesCountries, { getStateContryGroupBy } from 'utils/getStatesCountries';
import { useTenantFiltersSelector } from 'store/selectors/tenants';

export default function Tenants() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const values = useTenantFiltersSelector();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'type',
        label: formatMessage({ id: 'tenants.filters.type' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        initial: values['type'],
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'tenants.type.channelPartners' }), value: 'channel_partner' },
          { label: formatMessage({ id: 'tenants.type.networkManager' }), value: 'network_manager' },
        ],
        // permissions: [T_PERMISSIONS.MASTER],
      },
      {
        name: 'name',
        label: formatMessage({ id: 'tenants.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'address',
        label: formatMessage({ id: 'tenants.filters.address' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['address'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'country',
        label: formatMessage({ id: 'tenants.filters.tenant.country' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        autocompleteOptions: getStatesCountries(),
        initial: values['country'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        groupBy: getStateContryGroupBy,
      },
    ],
    [formatMessage, values]
  );

  const handleSubmit = useCallback(
    (filtersValues) => dispatch(getTenantsList({ filterBy: filtersValues })),
    [dispatch]
  );

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
