import { AxiosRequestConfig } from 'axios';
import { DiagnosticInfo, DiagnosticNWConnectivityEvent, DiagnosticOperator } from 'store/models/reports';
import { fetchData } from '../fetchData';
import config from 'config';
import { Thunk } from '..';

let diagnosticUsimInfoAbortController: AbortController;
export function getDiagnosticUsimInfo(imsi: string): Thunk<Promise<DiagnosticInfo>> {
  return async (dispatch) => {
    if (diagnosticUsimInfoAbortController) {
      diagnosticUsimInfoAbortController.abort();
    }

    diagnosticUsimInfoAbortController = new AbortController();

    const options: AxiosRequestConfig = {
      url: config.reportsApis.diagnostic.usimInfo.replace('{imsi}', imsi),
      signal: diagnosticUsimInfoAbortController.signal,
    };

    return dispatch(fetchData<DiagnosticInfo>(options));
  };
}

let diagnosticUsimOperatorAbortController: AbortController;
export function getDiagnosticUsimOperator(imsi: string): Thunk<Promise<DiagnosticOperator>> {
  return async (dispatch) => {
    if (diagnosticUsimOperatorAbortController) {
      diagnosticUsimOperatorAbortController.abort();
    }

    diagnosticUsimOperatorAbortController = new AbortController();
    const options: AxiosRequestConfig = {
      url: config.reportsApis.diagnostic.usimOperator.replace('{imsi}', imsi),
      signal: diagnosticUsimOperatorAbortController.signal,
    };

    return dispatch(fetchData<DiagnosticOperator>(options));
  };
}

export type DiagnosticNWUsimEventsRes = {
  items: DiagnosticNWConnectivityEvent[];
  total_items: { value: number };
};

let diagnosticUsimNWEventsAbortController: AbortController;
export function getDiagnosticUsimNWEvents({
  imsi,
  page = 0,
}: {
  imsi: string;
  page?: number;
}): Thunk<Promise<DiagnosticNWUsimEventsRes>> {
  return async (dispatch) => {
    if (diagnosticUsimNWEventsAbortController) {
      diagnosticUsimNWEventsAbortController.abort();
    }

    diagnosticUsimNWEventsAbortController = new AbortController();

    const options: AxiosRequestConfig = {
      url: config.reportsApis.diagnostic.usimEvents
        .replace('{imsi}', imsi)
        .replace('{limit}', 10)
        .replace('{page}', `${page}`),
      signal: diagnosticUsimNWEventsAbortController.signal,
    };

    return dispatch(fetchData<DiagnosticNWUsimEventsRes>(options));
  };
}
