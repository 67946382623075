import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSitesSelector } from 'store/selectors/tenants';
import { resetTenantSites } from 'store/actions/tenants';
import { getLandingPageNodes4g, getLandingPageUsimsInfo } from 'store/actions/landing';
import { useLandingSelector } from 'store/selectors/landing';
import { isEntityLoading, isListLoading } from 'store/reducers';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { MapBox, Location } from '@athonet/ui/components/Data/MapBox';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { useCanUserReadUsimSelector, useCanUserViewCDRSelector, useUserSelector } from 'store/selectors/user';
import { checkPermissionToUse, U_PERMISSIONS } from 'utils/permissionCodes';
import { useBootstrapSelector, useIsCDREnabledSelector } from 'store/selectors/bootstrap';
import { getUsimCountBySite } from 'store/actions/usims';
import { getSites } from 'store/actions/sites';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Node } from 'store/models/node';
import { tenantsCellRenderer } from 'utils/tenantsCellRenderer';

export function Provisioning4g() {
  const sites = useSitesSelector();
  const { usimsInfo, nodes4gInfo } = useLandingSelector();
  const userdata = useUserSelector();
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const dispatch = useDispatch();
  const bootstrap = useBootstrapSelector();
  const canUserViewCDR = useCanUserViewCDRSelector();
  const isCDREnabled = useIsCDREnabledSelector();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const canUserReadUsim = useCanUserReadUsimSelector();

  const panelHeight = useMemo(() => (canUserViewCDR && isCDREnabled ? 370 : 620), [canUserViewCDR, isCDREnabled]);

  useEffect(() => {
    dispatch(getSites());
    dispatch(getLandingPageUsimsInfo());
    dispatch(getLandingPageNodes4g());

    return () => {
      dispatch(resetTenantSites());
    };
  }, [dispatch]);

  useEffect(() => {
    async function getLocations() {
      if (isListLoading(sites)) {
        return;
      }

      setLocationsLoading(true);

      const locatedSites = sites.data.data.filter((site) => site.coords);
      const usimCountsBySites = await Promise.all(
        locatedSites.map((locatedSite) => dispatch(getUsimCountBySite(locatedSite)))
      );

      setLocations(usimCountsBySites.filter((location) => location.kpi > 0));
      setLocationsLoading(false);
    }
    void getLocations();
  }, [dispatch, sites]);

  const shouldMapRender = useMemo(
    () =>
      !isListLoading(sites) &&
      locations.length > 0 &&
      !locationsLoading &&
      bootstrap?.mapbox_center &&
      bootstrap?.mapbox_token &&
      bootstrap?.mapbox_styles,
    [sites, locations, locationsLoading, bootstrap]
  );

  const nodeColumns = useMemo(() => {
    const cols: TableColumn<Node>[] = [
      {
        key: 'display_name',
        label: formatMessage({ id: 'nodes.table.name' }),
        sortable: true,
        cellRender: ({ display_name, id }) => {
          return (
            <Link
              onClick={() => {
                navigate(`/networks/nodes/${id}`);
              }}
            >
              <Text type={'body2'}>{display_name}</Text>
            </Link>
          );
        },
      },
      {
        key: 'product_version',
        label: formatMessage({ id: 'nodes.table.nodeType' }),
        sortable: true,
      },
    ];

    if (!shouldMapRender) {
      cols.push({
        // backend still returning "owners"
        key: 'owners',
        label: formatMessage({ id: 'nodes.table.tenants' }),
        sortable: false,
        cellRender: ({ owners }) => {
          return <>{tenantsCellRenderer(owners)}</>;
        },
      });
    }
    return cols;
  }, [formatMessage, navigate, shouldMapRender]);

  return (
    <>
      <>
        <GridItem
          size={{
            xs: 12,
          }}
        >
          <Box>
            <Text type="h5" fontWeight={600} sx={{ mb: 1 }}>
              {formatMessage({ id: 'landing.panel.title.4GProvisioning' })}
            </Text>
            <Divider color="secondary" />
          </Box>
        </GridItem>
        {canUserReadUsim && (
          <>
            <GridItem
              size={{
                xs: 12,
                sm: 4,
              }}
              zeroMinWidth
              sx={{ display: 'flex', alignContent: 'stretch' }}
            >
              <Panel>
                <PanelContent>
                  {!isEntityLoading(usimsInfo) ? (
                    <SquaredTile
                      onClick={() => navigate('/4g-provisioning/usim-cards')}
                      data-testid="landing-usims-total-kpi"
                      icon="Sim-Card1"
                    >
                      <SquaredChildTile
                        mainValue={usimsInfo.data?.total ?? ''}
                        variant={'medium'}
                        title={formatMessage({ id: 'landing.panel.tile.totalSims' })}
                        titleColor="primary"
                      />
                    </SquaredTile>
                  ) : (
                    <Skeleton height={97} variant="rectangular" />
                  )}
                </PanelContent>
              </Panel>
            </GridItem>
            <GridItem
              size={{
                xs: 12,
                sm: 4,
              }}
              zeroMinWidth
              sx={{ display: 'flex', alignContent: 'stretch' }}
            >
              <Panel>
                <PanelContent>
                  {!isEntityLoading(usimsInfo) ? (
                    <SquaredTile
                      onClick={() =>
                        navigate(
                          '/4g-provisioning/usim-cards?filters=%257B%2522status%2522%253A%257B%2522value%2522%253A%255B%257B%2522label%2522%253A%2522Active%2522%252C%2522value%2522%253A%2522active%2522%257D%255D%252C%2522activeFilterType%2522%253A%2522Options%2522%252C%2522not%2522%253Afalse%257D%257D'
                        )
                      }
                      data-testid="landing-usims-active-kpi"
                      icon="Sim-Card1"
                    >
                      <SquaredChildTile
                        mainValue={usimsInfo.data?.activated ?? ''}
                        variant={'medium'}
                        title={formatMessage({ id: 'landing.panel.tile.activeSims' })}
                        titleColor="success"
                      />
                    </SquaredTile>
                  ) : (
                    <Skeleton height={97} variant="rectangular" />
                  )}
                </PanelContent>
              </Panel>
            </GridItem>
            <GridItem
              size={{
                xs: 12,
                sm: 4,
              }}
              zeroMinWidth
              sx={{ display: 'flex', alignContent: 'stretch' }}
            >
              <Panel>
                <PanelContent>
                  {!isEntityLoading(usimsInfo) ? (
                    <SquaredTile
                      onClick={() =>
                        navigate(
                          '/4g-provisioning/usim-cards?filters=%257B%2522status%2522%253A%257B%2522value%2522%253A%255B%257B%2522label%2522%253A%2522Active%2522%252C%2522value%2522%253A%2522active%2522%257D%252C%257B%2522label%2522%253A%2522Inactive%2522%252C%2522value%2522%253A%2522inactive%2522%257D%255D%252C%2522activeFilterType%2522%253A%2522Options%2522%252C%2522not%2522%253Afalse%257D%257D'
                        )
                      }
                      data-testid="landing-usims-provisioned-kpi"
                      icon="Sim-Card1"
                    >
                      <SquaredChildTile
                        mainValue={usimsInfo.data?.provisioned ?? ''}
                        variant={'medium'}
                        title={formatMessage({ id: 'landing.panel.tile.provisionedSims' })}
                        titleColor="secondary"
                      />
                    </SquaredTile>
                  ) : (
                    <Skeleton height={97} variant="rectangular" />
                  )}
                </PanelContent>
              </Panel>
            </GridItem>
          </>
        )}
      </>
      {/* Map */}
      {locations.length > 0 &&
        !isListLoading(sites) &&
        !locationsLoading &&
        bootstrap?.mapbox_center &&
        bootstrap?.mapbox_token &&
        bootstrap?.mapbox_styles && (
          <GridItem
            size={{
              xs: 12,
              md: 7,
            }}
            zeroMinWidth
          >
            <Panel fullHeight title={formatMessage({ id: 'landing.panel.title.simsBySite' })}>
              <PanelContent>
                {/* SIMS BY SITES */}
                {checkPermissionToUse(userdata, U_PERMISSIONS.READ_SITE) &&
                  checkPermissionToUse(userdata, U_PERMISSIONS.READ_USIM) && (
                    <Stack spacing={2}>
                      <MapBox
                        id="landing-sites-4g-map"
                        centerAddress={[Number(bootstrap.mapbox_center[1]), Number(bootstrap.mapbox_center[0])]}
                        height={panelHeight}
                        locations={locations}
                        zoom={5}
                        clusters
                        data-testid="landing-sites-map"
                        accessToken={bootstrap.mapbox_token}
                        stylesUrl={bootstrap.mapbox_styles}
                      />
                    </Stack>
                  )}
              </PanelContent>
            </Panel>
          </GridItem>
        )}
      {/* NODES */}
      {checkPermissionToUse(userdata, U_PERMISSIONS.READ_SITE) &&
        checkPermissionToUse(userdata, U_PERMISSIONS.READ_USIM) && (
          <GridItem
            size={{
              xs: 12,
              md: shouldMapRender ? 5 : 12,
            }}
            zeroMinWidth
          >
            <Panel fullHeight title={formatMessage({ id: 'landing.panel.title.nodes' })}>
              <Stack spacing={2} sx={{ height: `${panelHeight}px` }}>
                <Table
                  asPanel
                  orderBy="name"
                  columns={nodeColumns}
                  data={nodes4gInfo.data.data}
                  rowsCount={nodes4gInfo.data.total}
                  page={0}
                  rowsPerPage={nodes4gInfo.data.total}
                  rowKey="name"
                  autoScale
                />
              </Stack>
            </Panel>
          </GridItem>
        )}
    </>
  );
}
