import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';

export const BaseStyled = styled.div``;

export const FormBaseStyled = styled.div`
  .fullwidth {
    flex: 1;
    max-width: 200px;
    min-width: 100%;
    width: 100%;
  }

  .MuiGrid-root.MuiGrid-item {
    padding: 0 8px;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 30px;
  }

  .form-title > h2 {
    font-size: ${(props) => props.theme.form.title.fontSize};
    font-weight: ${(props) => props.theme.form.title.fontWeight};
    color: ${(props) => props.theme.form.title.color};
  }

  .form-fieldset-title {
    font-size: ${(props) => props.theme.form.fontSize};
    text-transform: uppercase;
    width: 100%;
    margin: 20px 0 0px;
    padding: 8px;
  }

  .MuiDialogActions-root {
    padding: 24px;
  }

  .spinner-wrapper {
    min-width: 250px;
    min-height: 100px;
    text-align: center;

    p {
      margin-top: 0;
    }
  }

  .field-component {
    position: relative;
  }

  .error-field-component {
    fieldset {
      border-color: ${(props) => props.theme.colors.error};
    }
    label {
      color: ${(props) => props.theme.colors.error};
    }
  }
`;

export const FormHelperErrorStyled = styled(FormHelperText)`
  position: absolute;
  top: 38px;
`;

export const FormStyled = styled.div``;

export const AddListStyled = styled.div`
  .MuiDialogContent-root {
    padding: 0 24px;
    overflow: hidden;
  }

  .MuiDialogActions-root {
    padding: 0 24px;
    position: relative;

    > button {
      padding: 3px 10px;
    }
  }
`;

export const TenantsSitesListStyled = styled.div`
  width: calc(100% - 46px);
  margin: 0 auto 20px;

  .tenantlist-total {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.typo.paragraph};
  }

  ul {
    height: 295px;
    overflow-y: scroll;
    border: solid 1px ${(props) => props.theme.colors.dark10};

    .MuiListItemText-multiline {
      margin: 0;
    }
  }
`;

export const TenantsListStyled = styled.div`
  width: calc(100% - 46px);
  margin: 0 auto 20px;

  .tenantlist-total {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.typo.paragraph};
  }

  ul {
    height: 295px;
    overflow-y: scroll;
    border: solid 1px ${(props) => props.theme.colors.dark10};

    .MuiListItemText-multiline {
      margin: 0;
    }
  }
`;

export const NodesListStyled = styled.div`
  width: calc(100% - 46px);
  margin: 0 auto 20px;

  .tenantlist-total {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.typo.paragraph};
  }

  ul {
    height: 295px;
    overflow-y: scroll;
    border: solid 1px ${(props) => props.theme.colors.dark10};

    .MuiListItemText-multiline {
      margin: 0;
    }
  }
`;

export const UsimProfileSegmentsListStyled = styled.div`
  width: calc(100% - 46px);
  margin: 0 auto 20px;

  .segmentlist-total {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.typo.paragraph};
  }

  ul {
    height: 295px;
    overflow-y: scroll;
    border: solid 1px ${(props) => props.theme.colors.dark10};

    .MuiListItemText-multiline {
      margin: 0;
    }
  }
`;
