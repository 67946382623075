import { AnyAction } from 'redux';
import { GPGKEYS_ACTION_TYPE } from 'store/actions/gpgKeys';
import { FiltersObj } from 'store/models/filters';
import { GpgKey } from 'store/models/gpgKey';
import { List, listFailure, listIdle, listLoading, listSuccess, Sort } from '.';

export type GpgKeysState = {
  filters: FiltersObj;
  sort: Sort;
  list: List<GpgKey>;
};

const initialState: GpgKeysState = {
  filters: {},
  sort: '',
  list: listIdle<GpgKey>(),
};

export default function gpgKeysReducer(state = initialState, action: AnyAction): GpgKeysState {
  switch (action.type) {
    case GPGKEYS_ACTION_TYPE.FILTERS_SET:
      return {
        ...state,
        filters: action.payload,
      };

    case GPGKEYS_ACTION_TYPE.SORT_SET:
      return {
        ...state,
        sort: action.payload,
      };

    case GPGKEYS_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };

    case GPGKEYS_ACTION_TYPE.LIST_SUCCESS:
      const { items, total_items, page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ...listSuccess({
            data: items,
            total: total_items,
            page,
          }),
        },
      };
    case GPGKEYS_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...listFailure(),
        },
      };

    case GPGKEYS_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
