import React, { useCallback, useMemo } from 'react';
import { CreateOrderRequest, createUsimOrder } from 'store/actions/usimOrders/createUsimOrder';
import { useAppDispatch } from 'store';
import CreateOrderForm from './create/CreateOrderForm';
import CreateOrderConfirm from './create/CreateOrderConfirm';
import FormDialog from 'components/Dialog/FormDialog';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { getUsimOrders } from 'store/actions/usimOrders/getUsimOrders';
import { ObjectSchema, StringSchema, number, object, string } from 'yup';
import { imsiExactRegExp, keysRegExp, tkRegExp } from 'store/models/usim';
import { CreateOrderType } from './create/types';

const CreateOrderDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();

  const handleCreate = useCallback(
    async (formValues: CreateOrderType) => {
      const orderRequest: CreateOrderRequest = {
        address_1: formValues.address_1,
        address_2: formValues.address_2,
        address_3: formValues.address_3,
        address_4: formValues.address_4,
        address_5: formValues.address_5,
        customer: formValues.customer,
        description: formValues.description,
        first_imsi: formValues.first_imsi !== '' ? formValues.first_imsi : undefined,
        graph_ref: formValues.graph_ref,
        imsi2_pool_id: formValues.is_dual_imsi === 'true' ? formValues.imsi2_pool_id?.value : undefined,
        imsi_pool_id: formValues.imsi_pool_id?.value ?? undefined,
        msisdn_pool_id: formValues.msisdn_pool_id?.value ?? undefined,
        msisdn_type: formValues.msisdn_type,
        profile: formValues.profile,
        quantity: Number(formValues.quantity),
        sim_id_1: formValues.sim_id_1,
        sim_id_2: formValues.sim_id_2,
        sim_id_3: formValues.sim_id_3,
        sim_id_4: formValues.sim_id_4,
        transport_key: formValues.transport_key,
        type: formValues.type,
        encrypt: formValues.encrypt,
        use_key: formValues.encrypt === 1 && formValues.use_default_tk === 'false' ? formValues.use_key : undefined,
        op: formValues.key_type === 1 ? formValues.op : undefined,
      };

      const resultAction = await dispatch(createUsimOrder(orderRequest));
      if (createUsimOrder.fulfilled.match(resultAction)) {
        await dispatch(getUsimOrders()); //TODOPS add a new record in the state without reload
        dialogClose();
      }
    },
    [dialogClose, dispatch]
  );

  const initialValues: CreateOrderType = useMemo(
    () => ({
      msisdn_pool_id: null,
      imsi_pool_id: null,
      imsi2_pool_id: null,
      msisdn_type: 'new',
      description: 'New USIM order',
      first_imsi: '',
      quantity: '1',
      customer: '',
      type: '',
      preset_profile: 0,
      profile: '',
      is_dual_imsi: 'false',
      graph_ref: '',
      transport_key: '',
      sim_id_1: '01',
      sim_id_2: '01',
      sim_id_3: '',
      sim_id_4: '',
      address_1: '',
      address_2: '',
      address_3: '',
      address_4: '',
      address_5: '',
      encrypt: 1,
      use_default_tk: 'false',
      use_key: '',
      key_type: 1,
      op: '',
    }),
    []
  );

  const validationSchema: ObjectSchema<any> = useMemo(
    () =>
      object().shape({
        imsi_pool_id: object().shape({ label: string(), value: string() }).nullable().required(),
        msisdn_pool_id: object().shape({ label: string(), value: string() }).nullable().required(),
        imsi2_pool_id: object()
          .shape({ label: string(), value: string() })
          .nullable()
          .when('is_dual_imsi', {
            is: 'true',
            then: (fieldSchema: StringSchema<any>) => fieldSchema.required(),
          }),

        description: string().required(),
        customer: string().required(),
        first_imsi: string().matches(imsiExactRegExp),
        msisdn_type: string().required(),
        quantity: number().required().min(1).max(100000),
        profile: string().required(),
        is_dual_imsi: string().required(),
        type: string().required(),
        graph_ref: string().required(),
        transport_key: string().required(),
        sim_id_1: string(),
        sim_id_2: string(),
        sim_id_3: string(),
        sim_id_4: string(),
        address_1: string(),
        address_2: string(),
        address_3: string(),
        address_4: string(),
        address_5: string(),
        key_type: number().required().min(0).max(1),
        op: string().when(['key_type'], {
          is: (key_type) => {
            return key_type === 1;
          },
          then: string().required().matches(keysRegExp),
          otherwise: string(),
        }),
        encrypt: number().required().min(0).max(1),
        use_key: string().when(['encrypt', 'use_default_tk'], {
          is: (encryptValue, use_default_tk) => encryptValue === 1 && use_default_tk === 'false',
          then: string().matches(tkRegExp).required(),
          otherwise: string(),
        }),
      }),
    []
  );

  return (
    <FormDialog
      alertPendingChanges={true}
      onSubmit={handleCreate}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validationPrefix={'usims.orders.form.order'}
      formComponent={<CreateOrderForm />}
      confirmComponent={<CreateOrderConfirm />}
    />
  );
};

export default CreateOrderDialog;
