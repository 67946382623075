import { useIntl } from 'react-intl';
import { Accordion } from '@athonet/ui/components/Surfaces/Accordion';
import { AccordionGroup } from '@athonet/ui/components/Surfaces/AccordionGroup';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Role } from 'store/models/role';
import { ReactNode } from 'react';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';

export type SecurityProps = {
  message: string;
  roles: Role[];
  createComponent: ReactNode;
  disableEdit: boolean;
  disableDelete: boolean;
  toggleEdit: (role: Role) => void;
  toggleDelete: (role: Role) => void;
  loading: boolean;
};

export default function Security({
  roles,
  message,
  createComponent,
  disableEdit,
  disableDelete,
  toggleEdit,
  toggleDelete,
  loading,
}: SecurityProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Toolbar>
        <Stack fullWidth direction="row" justify="flex-end" sx={{ mb: 2 }}>
          {createComponent}
        </Stack>
      </Toolbar>

      {loading ? (
        <Stack spacing={0.1}>
          <Skeleton height={60} variant="rectangular" />
          <Skeleton height={60} variant="rectangular" />
          <Skeleton height={60} variant="rectangular" />
          <Skeleton height={60} variant="rectangular" />
        </Stack>
      ) : roles.length === 0 && !loading ? (
        <div className="no-roles">
          <span>{message}</span>
        </div>
      ) : (
        <AccordionGroup exclusive={false}>
          {roles.map((role) => (
            <Accordion
              title={role.name}
              actions={[
                {
                  iconName: 'Pencil',
                  label: formatMessage({ id: 'common.button.edit' }),
                  onClick: () => toggleEdit(role),
                  disabled: disableEdit,
                },
                {
                  iconName: 'Trashcan',
                  label: formatMessage({ id: 'common.button.delete' }),
                  onClick: () => toggleDelete(role),
                  disabled: disableDelete,
                },
              ]}
              content={
                <GridContainer>
                  {role.permissions.map(({ id: permissionId, code }) => (
                    <GridItem
                      size={{
                        xs: 12,
                        md: 4,
                      }}
                      key={permissionId}
                    >
                      {formatMessage({ id: `permissions.${code}` })}
                    </GridItem>
                  ))}
                </GridContainer>
              }
            />
          ))}
        </AccordionGroup>
      )}
    </>
  );
}
