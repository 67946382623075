import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import EditUsim from './EditUsim';
import { useCallback } from 'react';
import { Button } from '@athonet/ui/components/Input/Button';
import { createBulkUsim, createUsim } from 'store/actions/usims';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AxiosError } from 'axios';

export default function CreateUsim() {
  const { dialogOpen, dialogClose } = useOverlay();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        if (data.single) {
          await dispatch(createUsim(data));
        } else {
          await dispatch(createBulkUsim(data));
        }
        dispatch(showSuccessToast());
        dialogClose();
      } catch (e) {
        const error = e as AxiosError;

        dispatch(
          showErrorToast(
            error?.response?.data.error
              ? { message: error.response.data.error, intlMessage: false }
              : { message: 'usims.actions.create.errorMessage', intlMessage: true }
          )
        );
      }
    },
    [dialogClose, dispatch]
  );

  const handleOpenCreate = useCallback(
    () =>
      dialogOpen({
        title: formatMessage({ id: 'usims.newUsim' }),
        content: () => (
          <EditUsim
            data-testid="create-form"
            create
            onSubmit={(data) => void handleOnSubmit(data)}
            show={[
              {
                label: formatMessage({ id: 'usims.form.usim.typology' }),
                fields: ['single'],
              },
              {
                label: formatMessage({ id: 'usims.form.usim.multiple_data_file_type' }),
                fields: ['upload'],
                showFieldset: ({ single }) => Boolean(!single),
              },
              {
                label: formatMessage({ id: 'usims.form.usim.single_data' }),
                fields: ['name', 'imsi', 'iccid', 'ims_profile', 'msisdn'],
                showFieldset: ({ single }) => Boolean(single),
              },
              {
                label: formatMessage({ id: 'usims.form.usim.profile' }),
                fields: ['profile'],
              },
              {
                label: 'Tenant data', // TODO: intl
                fields: ['tenant'],
              },
              {
                label: 'Secret K for AKA authentication', // TODO: intl
                fields: ['k', 'encrypt', 'use_default_tk', 'use_key'],
                showFieldset: ({ single }) => Boolean(single),
                showIf: [
                  {
                    field: 'use_default_tk',
                    condition: ({ encrypt }) => Boolean(encrypt),
                  },
                  {
                    field: 'use_key',
                    condition: ({ encrypt, use_default_tk }) => Boolean(encrypt && !use_default_tk),
                  },
                ],
              },
              {
                label: 'Secret OP/OPC for AKA authentication', // TODO: intl
                fields: ['key_type', 'op', 'opc'],
                showFieldset: ({ single }) => Boolean(single),
                showIf: [
                  {
                    field: 'op',
                    condition: ({ key_type }) => Boolean(key_type === 1),
                  },
                  {
                    field: 'opc',
                    condition: ({ key_type }) => Boolean(key_type === 2),
                  },
                ],
              },
            ]}
            initials={{
              single: false,
              name: 'New USIM',
              imsi: '',
              iccid: '',
              ims_profile: false,
              msisdn: '',
              k: '',
              encrypt: 0,
              use_default_tk: false,
              use_key: '',
              key_type: 1,
              op: '',
              opc: '',
            }}
          />
        ),
        closeOnBackdropClick: false,
      }),
    [dialogOpen, formatMessage, handleOnSubmit]
  );
  return (
    <Button
      onClick={handleOpenCreate}
      text={formatMessage({ id: 'usims.newUsim' })}
      data-testid="toolbar-new-button"
      startIcon="Add"
      variant="outlined"
    />
  );
}
