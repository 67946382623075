import { useSelector } from 'react-redux';

export function useTenantsListSelector() {
  const tenants = useSelector((state) => state.tenants.list);
  return tenants;
}

export function useChannelPartnersSelector() {
  const channelPartners = useSelector((state) => state.tenants.channelPartners);
  return channelPartners;
}

export function useNetworkManagersSelector() {
  const networkManagers = useSelector((state) => state.tenants.networkManagers);
  return networkManagers;
}

export function useSitesSelector() {
  const sites = useSelector((state) => state.tenants.sites);
  return sites;
}

export function useTenantsWithSubtenantsSelector() {
  const tenantsWithSubtenants = useSelector((state) => state.tenants.subTenants);
  return tenantsWithSubtenants;
}

export function useTenantFiltersSelector() {
  const filters = useSelector((state) => state.tenants.filters);
  return filters;
}
