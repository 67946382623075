import { AnalysisTable } from '../AnalysisTable';
import {
  getCdrTrafficVolumeTimeseriesAvailabilityDates,
  getCdrTrafficVolumeTimeseriesData,
  getCdrTrafficVolumeTimeseriesSchema,
} from 'store/actions/cdrReports/trafficVolume';
import {
  useCdrTrafficVolumeTimeseriesAvailabilityDates,
  useCdrTrafficVolumeTimeseriesQuery,
  useCdrTrafficVolumeTimeseriesSchema,
} from 'store/selectors/cdrReports/trafficVolume';

export function TrafficVolumeAnalysis() {
  const trafficVolumeTimeseriesSchema = useCdrTrafficVolumeTimeseriesSchema();
  const trafficVolumeTimeseriesAvailabilityDates = useCdrTrafficVolumeTimeseriesAvailabilityDates();
  const { currentQuery: query } = useCdrTrafficVolumeTimeseriesQuery();

  return (
    <AnalysisTable
      timeseriesSchema={trafficVolumeTimeseriesSchema}
      timeseriesAvailabilityDates={trafficVolumeTimeseriesAvailabilityDates}
      query={query}
      getTimeseriesAvailabilityDates={getCdrTrafficVolumeTimeseriesAvailabilityDates}
      getTimeseriesData={getCdrTrafficVolumeTimeseriesData}
      getTimeseriesSchema={getCdrTrafficVolumeTimeseriesSchema}
    />
  );
}
