import { CdrReportsAction, CDR_ACTION_TYPE } from 'store/actions/cdrReports';
import { SourceNetwork } from 'store/models/cdr';

export type CdrReportsSourceNetworkState = {
  activeSourceNetwork: SourceNetwork | null;
};

const initialState: CdrReportsSourceNetworkState = {
  activeSourceNetwork: null,
};

export default function cdrReportsSourceNetwork(
  state = initialState,
  action: CdrReportsAction
): CdrReportsSourceNetworkState {
  switch (action.type) {
    case CDR_ACTION_TYPE.SOURCE_NETWORK_SET_ACTIVE:
      return {
        ...state,
        activeSourceNetwork: action.payload,
      };
    default:
      return state;
  }
}
