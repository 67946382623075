import { AUTH_PROVIDER } from './../models/environmentConfiguration';
import { DefaultRootState, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

export function useBootstrapSelector() {
  const bootstrap = useSelector((state) => state.bootstrap);

  return bootstrap;
}

export function useDefaultAuthProviderSelector(): AUTH_PROVIDER | void {
  const bootstrap = useBootstrapSelector();

  if (!bootstrap?.auth_providers) {
    return;
  }
  if (Array.isArray(bootstrap.auth_providers)) {
    if (bootstrap.auth_providers.length) {
      return bootstrap.auth_providers[0];
    }
  } else {
    return bootstrap.auth_providers;
  }
}

export function useCreateUserAuthProvidersSelector(): AUTH_PROVIDER[] {
  const bootstrap = useBootstrapSelector();

  if (!bootstrap || !bootstrap?.auth_providers) {
    return [];
  }
  if (Array.isArray(bootstrap.auth_providers)) {
    if (bootstrap.create_user_with_enterprise_auth === false) {
      return bootstrap.auth_providers.filter((realm) => realm !== AUTH_PROVIDER.ENTERPRISE);
    }
    return bootstrap.auth_providers;
  } else {
    return [bootstrap.auth_providers];
  }
}

export const selectBootstrap = (state: DefaultRootState) => state.bootstrap;

export const getPlatForm = createSelector([selectBootstrap], (bootstrap) => {
  let platform = '';
  if (bootstrap?.athonetos_support_enabled && bootstrap?.athux_support_enabled) {
    platform = '';
  } else {
    if (bootstrap?.athonetos_support_enabled) platform = 'athonetos';
    if (bootstrap?.athux_support_enabled) platform = 'athux';
  }

  return platform;
});

export function useIsCDREnabledSelector() {
  const bootstrap = useBootstrapSelector();
  return bootstrap?.athux_support_enabled && (bootstrap?.traffic_service || bootstrap?.traffic_difference_service);
}
