import { AnyAction } from 'redux';
import { FiltersObj } from 'store/models/filters';
import { Site } from 'store/models/site';
import { SubTenant, Tenant, TENANT_TYPE } from 'store/models/tenant';
import { List, listFailure, listIdle, listLoading, listSuccess } from '.';
import { TENANTS_ACTION_TYPE } from '../actions/tenants';

export type TenantsState = {
  filters: FiltersObj;
  sort: string;
  list: List<Tenant>;
  channelPartners: List<Tenant>;
  networkManagers: List<Tenant>;
  sites: List<Site>;
  subTenants: List<SubTenant>;
};

const initialState: TenantsState = {
  filters: {},
  sort: '',
  list: listIdle<Tenant>(),
  channelPartners: listIdle<Tenant>(),
  networkManagers: listIdle<Tenant>(),
  sites: listIdle<Site>(),
  subTenants: listIdle<Tenant>(),
};

// TODO: AnyAction must be typed with a proper action type here
export default function tenantsPageReducer(state = initialState, action: AnyAction): TenantsState {
  switch (action.type) {
    case TENANTS_ACTION_TYPE.FILTERS_SET:
      return {
        ...state,
        filters: action.payload,
      };

    case TENANTS_ACTION_TYPE.SORT_SET:
      return {
        ...state,
        sort: action.payload,
      };

    case TENANTS_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };

    case TENANTS_ACTION_TYPE.LIST_SUCCESS:
      const { tenants: listTenants, total_items: listTotal, page } = action.payload;

      return {
        ...state,
        list: listSuccess({ data: listTenants, total: listTotal, page }),
      };

    case TENANTS_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: listFailure(),
      };

    case TENANTS_ACTION_TYPE.LIST_CLEAR:
      return {
        ...state,
        list: listIdle(),
      };

    case TENANTS_ACTION_TYPE.CHANNEL_PARTNER_SUCCESS: {
      const { tenants, total_items } = action.payload;
      return {
        ...state,
        channelPartners: listSuccess({
          data: tenants,
          total: total_items,
          type: TENANT_TYPE.CHANNEL_PARTNER,
        }),
      };
    }

    case TENANTS_ACTION_TYPE.CHANNEL_PARTNER_FAILURE:
      return {
        ...state,
        channelPartners: listFailure(),
      };

    case TENANTS_ACTION_TYPE.CHANNEL_PARTNER_LOADING:
      return {
        ...state,
        channelPartners: listLoading(state.channelPartners.data),
      };

    case TENANTS_ACTION_TYPE.NETWORK_MANAGERS_SUCCESS: {
      const { tenants, total_items } = action.payload;
      return {
        ...state,
        networkManagers: listSuccess({
          data: tenants,
          total: total_items,
          type: TENANT_TYPE.NETWORK_MANAGER,
        }),
      };
    }

    case TENANTS_ACTION_TYPE.NETWORK_MANAGERS_FAILURE:
      return {
        ...state,
        networkManagers: listFailure(),
      };

    case TENANTS_ACTION_TYPE.NETWORK_MANAGERS_LOADING:
      return {
        ...state,
        networkManagers: listLoading(state.networkManagers.data),
      };

    case TENANTS_ACTION_TYPE.SITES_SUCCESS: {
      const { items, total_items } = action.payload;
      return {
        ...state,
        sites: listSuccess({
          data: items,
          total: total_items,
          type: 'sites', // TODO: type this
        }),
      };
    }

    case TENANTS_ACTION_TYPE.SITES_FAILURE:
      return {
        ...state,
        sites: listFailure(),
      };

    case TENANTS_ACTION_TYPE.SITES_RESET:
      return {
        ...state,
        sites: listIdle(),
      };

    case TENANTS_ACTION_TYPE.SITES_LOADING:
      return {
        ...state,
        sites: listLoading(state.sites.data),
      };

    case TENANTS_ACTION_TYPE.SUBTENANTS_SUCCESS:
      const subTenants = action.payload;
      return {
        ...state,
        subTenants: listSuccess({
          data: subTenants,
          total: subTenants.length,
        }),
      };

    case TENANTS_ACTION_TYPE.SUBTENANTS_FAILURE:
      return {
        ...state,
        subTenants: listFailure(),
      };

    case TENANTS_ACTION_TYPE.SUBTENANTS_LOADING:
      return {
        ...state,
        subTenants: listLoading(state.subTenants.data),
      };

    case TENANTS_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
