import {
  setCdrTrafficDifferenceDateRange,
  setCdrTrafficDifferenceFilter,
  setCdrTrafficDifferencePeriod,
  getCdrTrafficDifferenceSearch,
  setCdrTrafficDifferenceRankingMetric,
} from 'store/actions/cdrReports/trafficDifference';
import {
  useCdrTrafficDifferenceDateRange,
  useCdrTrafficDifferencePeriod,
  useCdrTrafficDifferenceFilter,
  useCdrTrafficDifferenceRankingMetric,
} from 'store/selectors/cdrReports/trafficDifference';
import CdrToolbar from '../CdrToolbar';
import { useCdrTrafficDifferenceAvailabilityDates } from 'store/selectors/cdrReports/trafficDifference';
import {
  useCdrTrafficVolumeGroupByOptions,
  useCdrTrafficVolumeRankingMetricOptions,
  useCdrTrafficVolumeSchema,
} from 'store/selectors/cdrReports/trafficVolume';

export default function TrafficDifferenceToolbar() {
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();
  const trafficDifferenceDateRange = useCdrTrafficDifferenceDateRange();
  const trafficDifferencePeriod = useCdrTrafficDifferencePeriod();
  const trafficDifferenceFilter = useCdrTrafficDifferenceFilter();
  const trafficVolumeRankingMetricOptions = useCdrTrafficVolumeRankingMetricOptions();
  const trafficVolumeGroupByOptions = useCdrTrafficVolumeGroupByOptions();
  const trafficDifferenceRankingMetric = useCdrTrafficDifferenceRankingMetric();
  const trafficDifferenceAvailabilityDates = useCdrTrafficDifferenceAvailabilityDates();

  return (
    <CdrToolbar
      schema={trafficVolumeSchema}
      availabilityDates={trafficDifferenceAvailabilityDates}
      dateRange={trafficDifferenceDateRange}
      period={trafficDifferencePeriod}
      filter={trafficDifferenceFilter}
      metricOptions={trafficVolumeRankingMetricOptions}
      groupByOptions={trafficVolumeGroupByOptions}
      metric={trafficDifferenceRankingMetric}
      setDateRange={setCdrTrafficDifferenceDateRange}
      setFilter={setCdrTrafficDifferenceFilter}
      setPeriod={setCdrTrafficDifferencePeriod}
      setMetric={setCdrTrafficDifferenceRankingMetric}
      getSearch={getCdrTrafficDifferenceSearch}
      path={'traffic-difference'}
      metricLabel="Traffic" // TODO: intl
    />
  );
}
