// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';
import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';

// Utils
import getTimezones from 'utils/getTimezones';
import getStatesCountries, { getStateContryGroupBy } from 'utils/getStatesCountries';
import { T_PERMISSIONS } from 'utils/permissionCodes';

// Style
import { FormStyled } from '../styled';

const Create = ({ data, onSubmit, userdata, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();
  const [types, setTypes] = useState();
  // TODO domenico: remove this state here the value is never set
  const [typeDisabled] = useState(true);

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
    setTypes(data.type);
  }, [data]);

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [types, typeDisabled]);

  const schema = object().shape({
    name: string().required(),
    tenant_id:
      [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER].indexOf(userdata.tenant_type.toUpperCase()) > -1
        ? string().required()
        : string(),
    address: string(),
    country: string(),
    additional_info: string(),
    network_name: string(),
    timezone: string(),
  });

  const initials = {
    name: '',
    tenant_id: '',
    address: '',
    country: '',
    additional_info: '',
    network_name: '',
    timezone: '',
  };

  const fieldset = [
    {
      title: formatMessage({ id: 'sites.form.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'sites.form.name.label' }),
          placeholder: formatMessage({ id: 'sites.form.name.placeholder' }),
          error: formatMessage({ id: 'sites.form.name.error' }),
        },
        {
          name: 'tenant_id',
          label: formatMessage({ id: 'sites.form.tenant.label' }),
          placeholder: formatMessage({ id: 'sites.form.tenant.placeholder' }),
          error: formatMessage({ id: 'sites.form.tenant.error' }),
          options: data.tenants,
          permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
        },
      ],
    },
    {
      title: formatMessage({ id: 'sites.form.location' }),
      data: [
        {
          name: 'address',
          label: formatMessage({ id: 'sites.form.address.label' }),
          placeholder: formatMessage({ id: 'sites.form.address.placeholder' }),
          gridClassName: 'fullwidth',
          error: formatMessage({ id: 'sites.form.address.error' }),
        },
        {
          name: 'country',
          label: formatMessage({ id: 'sites.form.country.label' }),
          placeholder: formatMessage({ id: 'sites.form.country.placeholder' }),
          error: formatMessage({ id: 'sites.form.country.error' }),
          fieldComponent: Autocomplete,
          componentProps: {
            options: getStatesCountries(),
            multiple: false,
            size: 'small',
            groupBy: getStateContryGroupBy,
            disableClearable: false,
          },
        },
        {
          name: 'timezone',
          label: formatMessage({ id: 'sites.form.timezone.label' }),
          placeholder: formatMessage({ id: 'sites.form.timezone.placeholder' }),
          error: formatMessage({ id: 'sites.form.timezone.error' }),
          options: getTimezones(),
        },
      ],
    },
    {
      title: formatMessage({ id: 'sites.form.extra' }),
      data: [
        {
          name: 'network_name',
          label: formatMessage({ id: 'sites.form.networkname.label' }),
          placeholder: formatMessage({ id: 'sites.form.networkname.placeholder' }),
          error: formatMessage({ id: 'sites.form.networkname.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'additional_info',
          label: formatMessage({ id: 'sites.form.info.label' }),
          placeholder: formatMessage({ id: 'sites.form.info.placeholder' }),
          error: formatMessage({ id: 'sites.form.info.error' }),
          multiline: true,
          rows: 3,
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onSubmit}
        modalTitle={formatMessage({ id: 'sites.newSite' })}
        buttonLabel={formatMessage({ id: 'sites.newSite' })}
      />
    </FormStyled>
  );
};

Create.propTypes = {
  data: PropTypes.any,
};

export default Create;
