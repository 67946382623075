import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import CreateOrderDialog from './CreateOrderDialog';
import { CreateButton } from 'components/Button/CreateButton';

export default function CreateOrderButton() {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();
  const bootstrap = useBootstrapSelector();

  const handleOpenCreateDialog = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.orders.newOrder' }),
      content: () => <CreateOrderDialog />,
      closeOnEsc: false,
    });
  }, [dialogOpen, formatMessage]);

  if (!bootstrap) return null;

  return <CreateButton onClick={handleOpenCreateDialog} />;
}
