import { Status } from '@athonet/ui/components/Feedback/Status';
import { Button } from '@athonet/ui/components/Input/Button';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import Page404 from 'containers/Errors/Page404';
import { usePollOperation } from 'hooks/usePollOperation';
import moment from 'moment';
import { Fragment, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { getSegment } from 'store/actions/segments';
import { getPdpTypeText, Segment } from 'store/models/segment';
import { getStatusType } from 'store/models/usimProfile';
import { isEntityFailure } from 'store/reducers';
import { useSegmentsEntitySelector } from 'store/selectors/segments';
import {
  useCanUserDeleteSegmentSelector,
  useCanUserUpdateSegmentSelector,
  useHasUserCPMasterTenantSelector,
} from 'store/selectors/user';
import { humanizeSnakeCase } from 'utils/string';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { DetailPageSkeleton } from 'components/Skeletons/DetailPageSkeleton';

/* NOTE: CC-1285 */
type SegmentProfileDetailProps = {
  handleOpenEdit: (rowData: Segment) => void;
  handleOpenManageNodes: (rowData: Segment) => void;
  handleOpenManageTenants: (segmentId: Segment['id']) => void;
  handleOpenDelete: (rowData: Segment) => void;
};

export default function SegmentProfileDetail({
  handleOpenEdit,
  handleOpenManageNodes,
  handleOpenManageTenants,
  handleOpenDelete,
}: SegmentProfileDetailProps) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const segmentEntity = useSegmentsEntitySelector();
  const { id } = useParams<DetailPageParams>();
  const dispatch = useDispatch();
  const hasUserCPMasterTenant = useHasUserCPMasterTenantSelector();
  const canUserDeleteSegment = useCanUserDeleteSegmentSelector();
  const canUserUpdateSegment = useCanUserUpdateSegmentSelector();
  const { clearPollOperation } = usePollOperation();

  useEffect(() => {
    if (!id) {
      return;
    }
    void dispatch(getSegment(id));
    return () => {
      clearPollOperation();
    };
  }, [clearPollOperation, dispatch, id]);

  const entity = useMemo(() => {
    return segmentEntity.data;
  }, [segmentEntity]);

  if (!id || isEntityFailure(segmentEntity)) {
    return <Page404 />;
  }

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate('/4g-provisioning/apn-profiles');
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {entity && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              text={formatMessage({ id: 'common.button.delete' })}
              color="error"
              onClick={() => handleOpenDelete(entity)}
              disabled={!canUserDeleteSegment}
            />
          </Box>
        )}
      </Toolbar>
      {!entity ? (
        <DetailPageSkeleton />
      ) : (
        <GridContainer sx={{ pt: 0.5 }}>
          <GridItem size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Panel
                title="General Data"
                headerActionsComponent={
                  <IconButton
                    name="Pencil"
                    fontSize="small"
                    onClick={() => handleOpenEdit(entity)}
                    disabled={!canUserUpdateSegment}
                  />
                }
              >
                <PanelContent>
                  <GridContainer>
                    <KeyValueGridItem
                      label={formatMessage({ id: 'segments.table.name' })}
                      value={entity.name.toUpperCase()}
                      fullWidth
                    />
                    <KeyValueGridItem label={formatMessage({ id: 'segments.table.apn' })} value={entity.apn} />
                    <KeyValueGridItem
                      label={formatMessage({ id: 'segments.table.pdp_type' })}
                      value={getPdpTypeText(entity.pdp_type)}
                    />
                    <KeyValueGridItem
                      label={formatMessage({ id: 'segments.table.qos_template_4g_name' })}
                      value={entity.qos_template_4g.name}
                      valueComponent={
                        <Link
                          onClick={() => {
                            navigate(`/4g-provisioning/qos-profiles/${entity.qos_template_4g.id}`);
                          }}
                        >
                          <Text type={'body2'}>{entity.qos_template_4g.name}</Text>
                        </Link>
                      }
                    />
                  </GridContainer>
                </PanelContent>
              </Panel>
              <Panel
                title={formatMessage({ id: 'segments.table.nodes' })}
                headerActionsComponent={
                  <IconButton
                    name="Pencil"
                    fontSize="small"
                    onClick={() => handleOpenManageNodes(entity)}
                    disabled={!canUserUpdateSegment}
                  />
                }
              >
                <PanelContent>
                  <GridContainer>
                    {entity.nodes.map(
                      (node, i) =>
                        node && (
                          <Fragment key={node.id}>
                            <KeyValueGridItem label="Name" value={node.display_name} />
                            <KeyValueGridItem
                              label="Status"
                              valueComponent={
                                <Status
                                  status={getStatusType(node.status, node.updating)}
                                  label={humanizeSnakeCase(node.status)}
                                  asChip
                                />
                              }
                            />
                            {i + 1 < entity.nodes.length && (
                              <GridItem size={{ xs: 12 }}>
                                <Divider />
                              </GridItem>
                            )}
                          </Fragment>
                        )
                    )}
                  </GridContainer>
                </PanelContent>
              </Panel>
              <Panel title="Creation Details">
                <PanelContent>
                  <GridContainer>
                    <KeyValueGridItem
                      label={formatMessage({ id: 'segments.table.created' })}
                      value={moment(entity.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                    />
                    <KeyValueGridItem
                      label={formatMessage({ id: 'segments.table.updated' })}
                      value={moment(entity.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                    />
                  </GridContainer>
                </PanelContent>
              </Panel>
            </Stack>
          </GridItem>
          <GridItem size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Panel title={formatMessage({ id: 'segments.table.status' })}>
                <PanelContent>
                  <Status status={getStatusType(entity.status)} label={humanizeSnakeCase(entity.status)} asChip />
                </PanelContent>
              </Panel>
              {hasUserCPMasterTenant && (
                <Panel
                  title={formatMessage({ id: 'segments.table.tenants' })}
                  headerActionsComponent={
                    <IconButton
                      name="Pencil"
                      fontSize="small"
                      onClick={() => handleOpenManageTenants(entity.id)}
                      disabled={!canUserUpdateSegment}
                    />
                  }
                >
                  <PanelContent>
                    {entity.owners.length
                      ? entity.owners.map((owner, i) => (
                          <Fragment key={owner.id}>
                            <KeyValueGridItem label="Name" value={owner.name} />
                            {i + 1 < entity.owners.length && (
                              <GridItem size={{ xs: 12 }} sx={{ mt: 1, mb: 1 }}>
                                <Divider />
                              </GridItem>
                            )}
                          </Fragment>
                        ))
                      : '-'}
                  </PanelContent>
                </Panel>
              )}
            </Stack>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
