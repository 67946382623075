import styled from 'styled-components';

export const TenantsListStyled = styled.div`
  width: calc(100% - 46px);
  margin: 0 auto 20px;

  .tenantlist-total {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.typo.paragraph};
  }

  ul {
    height: 295px;
    overflow-y: scroll;
    border: solid 1px ${(props) => props.theme.colors.dark10};

    .MuiListItemText-multiline {
      margin: 0;
    }

    .button-delete-question {
      background: ${(props) => props.theme.colors.error};
      text-transform: initial;
    }
  }
`;

export const AddListStyled = styled.div`
  .MuiDialogContent-root {
    padding: 0 24px;
    overflow: hidden;
  }

  .MuiDialogActions-root {
    padding: 0 24px;
    position: relative;

    > button {
      padding: 3px 10px;
    }
  }
`;
