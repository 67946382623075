import { useSelector } from 'react-redux';
import { CdrGroupByOptions, CdrImeiChangesMetricsField, CdrMetricOptions, CdrSchemaField } from 'store/models/cdr';

export function useCdrImeiChangesAvailabilityDates() {
  const availabilityDates = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesAvailabilityDates);
  return availabilityDates;
}

export function useCdrImeiChangesSchema() {
  const schema = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesSchema);
  return schema;
}

export function useCdrImeiChangesTrendSeries() {
  const series = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesTrendSeries);
  return series;
}

export function useCdrImeiChangesRankingSeries() {
  const series = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesRankingSeries);
  return series;
}

export function useCdrImeiChangesTotal() {
  const total = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesTotal);
  return total;
}

export function useCdrImeiChangesRanking() {
  const ranking = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesRanking);
  return ranking;
}

export function useCdrImeiChangesHistory() {
  const history = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesHistory);
  return history;
}

export function useDescImeiChangesHistory() {
  const history = useCdrImeiChangesHistory();

  if (!history.data) {
    return [];
  }

  return history.data
    .map(({ datetime, to_imei }) => ({
      datetime,
      value: to_imei,
    }))
    .sort(() => {
      return -1;
    });
}

export function getCdrImeichangesMetricOptions(
  schema: CdrSchemaField[]
): CdrMetricOptions<CdrImeiChangesMetricsField>[] {
  const sortedFields: CdrImeiChangesMetricsField[] = ['imei_changes'];

  return sortedFields.map((field) => {
    const metricField = schema.find((schemaField) => schemaField.field === field) as CdrSchemaField;
    return {
      label: metricField.label,
      value: metricField.field as CdrImeiChangesMetricsField,
    };
  });
}

export function useCdrImeiChangesMetricOptions(): CdrMetricOptions<CdrImeiChangesMetricsField>[] {
  const imeiChangesSchema = useCdrImeiChangesSchema();

  if (!imeiChangesSchema.data) {
    return [];
  }

  return getCdrImeichangesMetricOptions(imeiChangesSchema.data.schema);
}

export function useCdrImeiChangesGroupByOptions(): CdrGroupByOptions[] {
  const imeiChangesSchema = useCdrImeiChangesSchema();

  if (!imeiChangesSchema.data) {
    return [];
  }
  const {
    schema,
    global: { group },
  } = imeiChangesSchema.data;
  return group.map((value) => ({
    value,
    label: schema.find((item) => item.field === value)?.label,
  }));
}

export function useImeiChangesDateRange() {
  const dateRange = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesDateRange);
  return dateRange;
}

export function useCdrImeiChangesPeriod() {
  const period = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesPeriod);
  return period;
}

export function useCdrImeiChangesFilter() {
  const filter = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesFilter);
  return filter;
}

export function useCdrImeiChangesRankingGroupBy() {
  const groupBy = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesRankingGroupBy);
  return groupBy;
}

export function useCdrImeiChangesRankingCalendarDate() {
  const calendarDate = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesRankingCalendarDate);
  return calendarDate;
}

export function useCdrImeiChangesRankingMetric() {
  const metric = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesRankingMetric);
  return metric;
}

export function useCdrImeiChangesTimeseriesSchema() {
  const timeseriesSchema = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesTimeseriesSchema);
  return timeseriesSchema;
}

export function useCdrImeiChangesTimeseriesAvailabilityDates() {
  const timeseriesAvailabilityDates = useSelector(
    (state) => state.cdrReportsImeiChanges.imeiChangesTimeseriesAvailabilityDates
  );
  return timeseriesAvailabilityDates;
}

export function useCdrImeiChangesTimeseriesQuery() {
  const timeseriesQuery = useSelector((state) => state.cdrReportsImeiChanges.imeiChangesTimeseriesQuery);
  return timeseriesQuery;
}
