import { MouseEvent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { DateRange, DateRangePeriod } from '@athonet/ui/components/Input/DateRange';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { Icon } from '@athonet/ui/components/Guidelines/Icon';
import { Badge } from '@athonet/ui/components/Feedback/Badge';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Entity, isEntityLoading } from 'store/reducers';
import { Moment } from 'moment';
import { useLocation, useNavigate } from 'react-router';
import {
  CdrAvailabilityDates,
  CdrAvailabilityPeriodRange,
  CdrDateRange,
  CdrFilter,
  CdrGroupByOptions,
  CdrMetricOptions,
  CdrMetricsField,
  CdrPeriod,
  CdrTimeSeriesQuery,
  CdrTimeSeriesQueryData,
  CdrTimeSeriesSchema,
  SourceNetwork,
} from 'store/models/cdr';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import CdrFilters from './CdrFilters';
import { Select, SelectChangeEvent } from '@athonet/ui/components/Input/Select';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { CdrSchemaState } from 'store/reducers/cdrReports';
import { ResponsiveControls } from '@athonet/ui/components/Navigation/ResponsiveControls';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { GetCdrSearchAction } from 'store/actions/cdrReports';
import { useCdrActiveSourceNetwork, useCdrSourceNetworks } from 'store/selectors/cdrReports/sourceNetwork';
import { setCdrActiveSourceNetwork } from 'store/actions/cdrReports/sourceNetwork';
import { Thunk } from 'store/actions';

import { CdrQueryBuilder } from './CdrQueryBuilder';
import { CdrExportQueryBuilder } from './CdrExportQueryBuilder';

export type CdrToolbarProps<M extends CdrMetricsField> = {
  schema: Entity<CdrSchemaState>;
  availabilityDates: Entity<CdrAvailabilityDates<Moment>>;
  dateRange: CdrDateRange | null;
  period: DateRangePeriod | undefined;
  filter: CdrFilter<string>;
  metricOptions: CdrMetricOptions<M>[];
  groupByOptions: CdrGroupByOptions[];
  metric: M;
  setDateRange: (payload: CdrDateRange) => void;
  setFilter: (payload: CdrFilter<string>) => void;
  setPeriod: (payload: CdrPeriod) => void;
  setMetric: (payload: M) => void;
  getSearch: GetCdrSearchAction;
  path: string;
  metricLabel: string;
  displaySourceNetwork?: true;
  displayAnalysis?: true;
  timeseriesSchema?: Entity<CdrTimeSeriesSchema>;
  timeseriesAvailabilityDates?: Entity<CdrAvailabilityPeriodRange<Moment>>;
  timeseriesQuery?: CdrTimeSeriesQuery;
  setQuery?: (payload: CdrTimeSeriesQuery) => void;
  setExportQuery?: (payload: CdrTimeSeriesQueryData) => Thunk<Promise<void>>;
};

export default function CdrToolbar<M extends CdrMetricsField>({
  schema,
  availabilityDates,
  dateRange,
  period,
  filter,
  groupByOptions,
  metricOptions,
  setDateRange,
  setFilter,
  setPeriod,
  setMetric,
  getSearch,
  path,
  metricLabel,
  metric,
  displaySourceNetwork,
  displayAnalysis,
  timeseriesSchema,
  timeseriesAvailabilityDates,
  timeseriesQuery,
  setQuery,
  setExportQuery,
}: CdrToolbarProps<M>) {
  const location = useLocation();
  const locationPaths = location.pathname.split('/');
  const viz = locationPaths[locationPaths.length - 1];
  const dispatch = useDispatch();
  const { popoverOpen } = useOverlay();
  const sourceNetworks = useCdrSourceNetworks();
  const activeSourceNetwork = useCdrActiveSourceNetwork();
  const navigate = useNavigate();

  const handleDateChange = useCallback(
    (periodValue: DateRangePeriod, startDate: Moment, endDate: Moment) => {
      if (periodValue === 'time') {
        return;
      }

      dispatch(setPeriod(periodValue));

      dispatch(
        setDateRange({
          start: startDate,
          end: endDate,
        })
      );
    },
    [dispatch, setDateRange, setPeriod]
  );

  const handleToggleChange = useCallback(
    (value: string) => {
      navigate(`/reports/${path}/${value}`);
    },
    [navigate, path]
  );

  const handleFiltersOpen = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      popoverOpen({
        anchorEl: e.currentTarget,
        content: () => {
          return (
            <CdrFilters
              setFilter={setFilter}
              filter={filter}
              groupByOptions={groupByOptions}
              schema={schema}
              getSearch={getSearch}
            />
          );
        },
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    },
    [filter, getSearch, groupByOptions, popoverOpen, schema, setFilter]
  );

  const handleFilterDelete = useCallback(() => {
    dispatch(
      setFilter({
        group_by: '',
        group_filter: '',
      })
    );
  }, [dispatch, setFilter]);

  const handleMetricChange = useCallback(
    (e: SelectChangeEvent<M>) => {
      dispatch(setMetric(e.target.value as M));
    },
    [dispatch, setMetric]
  );

  const handleSourceNetworkChange = useCallback(
    (e: SelectChangeEvent<SourceNetwork>) => {
      dispatch(setCdrActiveSourceNetwork(e.target.value as SourceNetwork));
    },
    [dispatch]
  );

  const disabledPeriods = useMemo(() => {
    let disabledArray: CdrPeriod[] = [];

    if (availabilityDates.data && schema?.data?.global?.period) {
      disabledArray = schema?.data?.global?.period.filter((singlePeriod) => {
        return (
          availabilityDates.data &&
          (!availabilityDates.data[singlePeriod].max || !availabilityDates.data[singlePeriod].min)
        );
      });
    }
    return disabledArray;
  }, [availabilityDates.data, schema]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Toolbar>
        <Stack direction="row" justify="space-between" align="center" fullWidth>
          <Stack direction="row" align="center">
            <ToggleButtonGroup
              size="small"
              color={'secondary'}
              exclusive={true}
              value={viz}
              onChange={handleToggleChange}
            >
              <ToggleButton value="trend" disabled={viz === 'trend'} data-testid="cdr-toolbar-toggle-trend">
                <Stack direction="row" spacing={0.5}>
                  <Icon name="Bar-Chart-2" />
                  {/* TODO: intl */}
                  <Text type="button">Trend</Text>
                </Stack>
              </ToggleButton>
              <ToggleButton value="ranking" disabled={viz === 'ranking'} data-testid="cdr-toolbar-toggle-ranking">
                <Stack direction="row" spacing={0.5}>
                  <Icon name="Decreasing-Chart-2" sx={{ transformOrigin: 'center', transform: 'rotate(90deg)' }} />
                  {/* TODO: intl */}
                  <Text type="button">Ranking</Text>
                </Stack>
              </ToggleButton>
              {displayAnalysis && (
                <ToggleButton value="analysis" disabled={viz === 'analysis'} data-testid="cdr-toolbar-toggle-analysis">
                  <Stack direction="row" spacing={0.5}>
                    <Icon name="Search-Database" />
                    {/* TODO: intl */}
                    <Text type="button">Analysis</Text>
                  </Stack>
                </ToggleButton>
              )}
            </ToggleButtonGroup>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {filter.group_by && viz === 'trend' && schema.data?.schema && (
                <Chip
                  label={`${schema.data?.schema.find((item) => item.field === filter.group_by)?.label}: ${
                    filter.group_filter
                  }`}
                  id={filter.group_by}
                  onDelete={handleFilterDelete}
                  color={'secondary'}
                  size="small"
                  data-testid="cdr-toolbar-chip-filter"
                />
              )}
            </Box>
          </Stack>
          {viz !== 'analysis' && (
            <ResponsiveControls wide breakpoint="lg">
              {viz === 'trend' ? (
                <Badge variant={'dot'} invisible={!filter.group_by} data-testid="cdr-toolbar-filter-dot">
                  {/* TODO: intl */}
                  <Button
                    variant="outlined"
                    onClick={handleFiltersOpen}
                    text="Filters"
                    startIcon={'Adjustments'}
                    sx={{ width: '100%' }}
                    data-testid="cdr-toolbar-filter-button"
                  />
                </Badge>
              ) : null}
              {viz === 'ranking' && metricOptions.length > 1 ? (
                <Box sx={{ width: { xs: '100%', lg: '100px' } }}>
                  <Select
                    label={metricLabel}
                    onChange={handleMetricChange}
                    size={'small'}
                    value={metric}
                    data-testid="cdr-toolbar-metric-select"
                  >
                    {metricOptions?.map((option) => (
                      <MenuItem value={option.value} key={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : null}
              {viz !== 'analysis' ? (
                <DateRange
                  maxDate={
                    availabilityDates.data
                      ? {
                          day: availabilityDates.data.day.max || undefined,
                          week: availabilityDates.data.week.max || undefined,
                          month: availabilityDates.data.month.max || undefined,
                          year: availabilityDates.data.year.max || undefined,
                        }
                      : undefined
                  }
                  minDate={
                    availabilityDates.data
                      ? {
                          day: availabilityDates.data.day.min || undefined,
                          week: availabilityDates.data.week.min || undefined,
                          month: availabilityDates.data.month.min || undefined,
                          year: availabilityDates.data.year.min || undefined,
                        }
                      : undefined
                  }
                  startDate={dateRange?.start || undefined}
                  endDate={dateRange?.end || undefined}
                  onChange={handleDateChange}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  period={period}
                  showPeriods={true}
                  periods={schema.data?.global.period}
                  loading={isEntityLoading(availabilityDates)}
                  disabledPeriods={disabledPeriods}
                  data-testid="cdr-toolbar-date-range"
                />
              ) : null}
              {displaySourceNetwork && activeSourceNetwork && sourceNetworks && sourceNetworks.length > 1 ? (
                <Box sx={{ width: { xs: '100%', lg: '100px' } }}>
                  <Select
                    label={'Source Network'}
                    onChange={handleSourceNetworkChange}
                    size={'small'}
                    value={activeSourceNetwork}
                    color="primary"
                    data-testid="cdr-toolbar-source-network-select"
                  >
                    {sourceNetworks.map((sourceNetwork) => (
                      <MenuItem value={sourceNetwork} key={sourceNetwork}>
                        {sourceNetwork}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : null}
            </ResponsiveControls>
          )}
          {viz === 'analysis' &&
          timeseriesSchema &&
          timeseriesAvailabilityDates &&
          timeseriesQuery &&
          setQuery &&
          setExportQuery ? (
            <ResponsiveControls wide breakpoint="lg">
              <CdrExportQueryBuilder timeseriesQuery={timeseriesQuery} setExportQuery={setExportQuery} />
              <CdrQueryBuilder
                timeseriesSchema={timeseriesSchema}
                timeseriesAvailabilityDates={timeseriesAvailabilityDates}
                timeseriesQuery={timeseriesQuery}
                setQuery={setQuery}
              />
            </ResponsiveControls>
          ) : null}
        </Stack>
      </Toolbar>
    </Box>
  );
}
