import { ServiceProfile } from 'store/models/serviceProfile';
import { AnyAction } from 'redux';
import { List, listFailure, listIdle, listLoading, listSuccess } from '.';
import { SERVICE_PROFILES_ACTION_TYPE } from 'store/actions/serviceProfiles';

export type ServiceProfileState = {
  list: List<ServiceProfile>;
};

const initialState: ServiceProfileState = {
  list: listIdle<ServiceProfile>(),
};

export default function serviceProfilesReducer(state = initialState, action: AnyAction): ServiceProfileState {
  switch (action.type) {
    case SERVICE_PROFILES_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };
    case SERVICE_PROFILES_ACTION_TYPE.LIST_SUCCESS:
      const { items, total_items, page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ...listSuccess({
            data: items,
            total: total_items,
            page,
          }),
        },
      };

    case SERVICE_PROFILES_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...listFailure(),
        },
      };
    case SERVICE_PROFILES_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
