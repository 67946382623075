import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import Page404 from 'containers/Errors/Page404';
import { deleteUsim, getUsim, resetEntity } from 'store/actions/usims';
import { Usim } from 'store/models/usim';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@athonet/ui/components/Input/Button';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCanUserDeleteUsimSelector } from 'store/selectors/user';
import { useUsimEntitySelector } from 'store/selectors/usims/cards';
import { DetailPageParams } from 'Router';
import { isEntityFailure } from 'store/reducers';
import { usePollOperation } from 'hooks/usePollOperation';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { UsimCardsIdDetails } from './UsimCardsIdDetails';
import { UsimCardsIdEvents } from './UsimCardsIdEvents';
import { UsimCardsIdInfo } from './UsimCardsIdInfo';

export enum TAB {
  DETAILS = 'details',
  EVENTS = 'events',
  INFO = 'info',
}

export default function UsimCard() {
  const { id, tab } = useParams<DetailPageParams<TAB>>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { confirmationSimpleDialogOpen } = useOverlay();

  const usimEntity = useUsimEntitySelector(id as string);
  const { pollOperation, clearPollOperation } = usePollOperation();
  const bootstrap = useBootstrapSelector();

  const canUserDeleteUsim = useCanUserDeleteUsimSelector(id as string);

  useEffect(() => {
    if (!id) {
      return;
    }
    void dispatch(getUsim(id));

    return () => {
      clearPollOperation();
      dispatch(resetEntity());
    };
  }, [clearPollOperation, dispatch, id]);

  const entity = useMemo(() => {
    return usimEntity?.data;
  }, [usimEntity]);

  const handleTabChange = useCallback(
    (newTab: TAB) => {
      navigate(`/4g-provisioning/usim-cards/${id}/${newTab}`, {
        replace: true,
      });
    },
    [id, navigate]
  );

  const openDelete = useCallback(
    (usim: Usim) => {
      confirmationSimpleDialogOpen({
        title: formatMessage({ id: 'usims.actions.delete.title' }),
        description: formatMessage({ id: 'usims.actions.delete.confirm.content' }),
        onConfirm: async () => {
          const op = await dispatch(deleteUsim([usim.id]));
          pollOperation(op.operation_id, async () => {
            navigate(`/4g-provisioning/usim-cards`, {
              replace: true,
            });
          });
        },
      });
    },
    [confirmationSimpleDialogOpen, dispatch, formatMessage, pollOperation, navigate]
  );

  const usimCardDetailTabs = useMemo(() => {
    if (!usimEntity) {
      return [];
    }

    const tabs = [
      {
        value: TAB.DETAILS,
        label: formatMessage({ id: 'usims.id.details' }),
        content: <UsimCardsIdDetails />,
        disabled: false,
      },
      {
        value: TAB.EVENTS,
        label: formatMessage({ id: 'usims.id.events' }),
        content: <UsimCardsIdEvents />,
        disabled: false,
      },
    ];

    if (bootstrap?.dynamic_info) {
      tabs.push({
        value: TAB.INFO,
        label: formatMessage({ id: 'usims.id.dynamicInfo' }),
        content: <UsimCardsIdInfo />,
        disabled: entity?.status !== 'active' && entity?.status !== 'inactive' && entity?.status !== 'barred',
      });
    }

    return tabs;
  }, [formatMessage, usimEntity, bootstrap, entity]);

  if (!id || (usimEntity && isEntityFailure(usimEntity))) {
    return <Page404 message="page404.detail.message" intlMessage={true} />;
  }

  if (tab === 'info' && !bootstrap?.dynamic_info) {
    throw new Error(`FF: dynamic_info`);
  }

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {bootstrap?.delete_usim && entity && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              text={formatMessage({ id: 'common.button.delete' })}
              color="error"
              onClick={() => openDelete(entity)}
              disabled={!canUserDeleteUsim}
            />
          </Box>
        )}
      </Toolbar>
      <Tabs
        value={tab as string}
        tabs={usimCardDetailTabs}
        onChange={(value) => {
          handleTabChange(value as TAB);
        }}
      />
    </>
  );
}
