import { AnyAction } from 'redux';
import { FiltersObj } from 'store/models/filters';
import { User } from 'store/models/user';
import { List, Sort, listFailure, listIdle, listLoading, listSuccess } from '.';
import { USERS_ACTION_TYPE } from '../actions/users';

export type UsersState = {
  filters: FiltersObj;
  sort: Sort;
  list: List<User>;
};

const initialState: UsersState = {
  filters: {},
  sort: '',
  list: listIdle<User>(),
};

// TODO: type the action here
export default function usersPageReducer(state = initialState, action: AnyAction): UsersState {
  switch (action.type) {
    case USERS_ACTION_TYPE.FILTERS_SET:
      return {
        ...state,
        filters: action.payload,
      };

    case USERS_ACTION_TYPE.SORT_SET:
      return {
        ...state,
        sort: action.payload,
      };

    case USERS_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };

    case USERS_ACTION_TYPE.LIST_SUCCESS:
      const { users, total_items, page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ...listSuccess({
            data: users,
            total: total_items,
            page,
          }),
        },
      };

    case USERS_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...listFailure(),
        },
      };

    case USERS_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
