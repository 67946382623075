import { EditUserModal, ManageTenantsModal, MODAL_ID } from 'store/models/modal';

export enum MODAL_ACTION_TYPE {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface OpenModalAction {
  type: MODAL_ACTION_TYPE.OPEN_MODAL;
  payload: ManageTenantsModal | EditUserModal;
}

export interface CloseModalAction {
  type: MODAL_ACTION_TYPE.CLOSE_MODAL;
}

function openModal(payload: ManageTenantsModal | EditUserModal) {
  return {
    type: MODAL_ACTION_TYPE.OPEN_MODAL,
    payload,
  };
}

export function openManageTenantsModal(data: ManageTenantsModal['data']) {
  return openModal({
    id: MODAL_ID.MANAGE_TENANTS,
    data,
  });
}

export function closeModal() {
  return {
    type: MODAL_ACTION_TYPE.CLOSE_MODAL,
  };
}
