import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { State } from 'store';
import { deleteScheduledOperation, setScheduledOperation } from '../bulkOperations';

export type UploadOrderRequest = {
  encrypted: boolean;
  key_id?: string;
  upload?: any; //TODOPS
};

export const uploadUsimOrder = createAsyncThunk<
  void,
  UploadOrderRequest,
  {
    rejectValue: string;
    state: State;
  }
>('usimOrders/upload', async (uploadRequest, { dispatch, rejectWithValue }) => {
  const uuid = await dispatch(setScheduledOperation());

  try {
    const uploadData = new FormData();
    uploadData.append('uploadfile', uploadRequest.upload[0]);
    uploadData.append('operation_id', uuid);
    if (uploadRequest.encrypted) uploadData.append('key_id', uploadRequest.key_id!);

    const options: AxiosRequestConfig = {
      url: `${config.apis.uploadUsimOrder}`,
      method: 'POST',
      data: uploadData,
    };

    const response = await dispatch(fetchData<any>(options));
    return response;
  } catch (e: any) {
    let errorText = e.response ? (e.response.data.error ? e.response.data.error : undefined) : undefined;
    dispatch(deleteScheduledOperation(uuid));
    sentryLogError(e);
    return rejectWithValue(errorText);
  }
});
