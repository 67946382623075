import {
  setCdrTrafficVolumeDateRange,
  setCdrTrafficVolumeFilter,
  setCdrTrafficVolumePeriod,
  getCdrTrafficVolumeSearch,
  setTrafficVolumeTimeSeriesQuery,
  getTrafficVolumeTimeseriesExportData,
} from 'store/actions/cdrReports/trafficVolume';
import {
  useCdrTrafficVolumeSchema,
  useCdrTrafficVolumeDateRange,
  useCdrTrafficVolumePeriod,
  useCdrTrafficVolumeFilter,
  useCdrTrafficVolumeRankingMetricOptions,
  useCdrTrafficVolumeGroupByOptions,
  useCdrTrafficVolumeRankingMetric,
  useCdrTrafficVolumeAvailabilityDates,
  useCdrTrafficVolumeTimeseriesAvailabilityDates,
  useCdrTrafficVolumeTimeseriesSchema,
  useCdrTrafficVolumeTimeseriesQuery,
} from 'store/selectors/cdrReports/trafficVolume';
import { setCdrTrafficVolumeRankingMetric } from 'store/actions/cdrReports/trafficVolume';
import CdrToolbar from '../CdrToolbar';

export default function TrafficVolumeToolbar() {
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();
  const trafficVolumeDateRange = useCdrTrafficVolumeDateRange();
  const trafficVolumePeriod = useCdrTrafficVolumePeriod();
  const trafficVolumeFilter = useCdrTrafficVolumeFilter();
  const trafficVolumeRankingMetricOptions = useCdrTrafficVolumeRankingMetricOptions();
  const trafficVolumeGroupByOptions = useCdrTrafficVolumeGroupByOptions();
  const trafficVolumeRankingMetric = useCdrTrafficVolumeRankingMetric();
  const trafficVolumeAvailabilityDates = useCdrTrafficVolumeAvailabilityDates();
  const trafficVolumeTimeseriesSchema = useCdrTrafficVolumeTimeseriesSchema();
  const trafficVolumeTimeseriesAvailabilityDates = useCdrTrafficVolumeTimeseriesAvailabilityDates();
  const trafficVolumeTimeseriesQuery = useCdrTrafficVolumeTimeseriesQuery();

  return (
    <CdrToolbar
      schema={trafficVolumeSchema}
      availabilityDates={trafficVolumeAvailabilityDates}
      dateRange={trafficVolumeDateRange}
      period={trafficVolumePeriod}
      filter={trafficVolumeFilter}
      metricOptions={trafficVolumeRankingMetricOptions}
      groupByOptions={trafficVolumeGroupByOptions}
      metric={trafficVolumeRankingMetric}
      setDateRange={setCdrTrafficVolumeDateRange}
      setFilter={setCdrTrafficVolumeFilter}
      setPeriod={setCdrTrafficVolumePeriod}
      setMetric={setCdrTrafficVolumeRankingMetric}
      getSearch={getCdrTrafficVolumeSearch}
      path={'traffic-volume'}
      metricLabel="Traffic" // TODO: intl
      displaySourceNetwork
      displayAnalysis
      timeseriesSchema={trafficVolumeTimeseriesSchema}
      timeseriesAvailabilityDates={trafficVolumeTimeseriesAvailabilityDates}
      timeseriesQuery={trafficVolumeTimeseriesQuery}
      setQuery={setTrafficVolumeTimeSeriesQuery}
      setExportQuery={getTrafficVolumeTimeseriesExportData}
    />
  );
}
