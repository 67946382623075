// Style
import { IconStyled, SvgIco } from './styled';

export type IconProps = {
  className?: string;
  icon?: string;
  file?: JSX.Element;
  size: number;
};

export default function Icon({ className, icon, file, size }: IconProps) {
  return file ? (
    <SvgIco className={className} size={size}>
      {file}
    </SvgIco>
  ) : (
    <IconStyled className={className} icon={icon} size={size} />
  );
}
