import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue } from 'store/models/filters';
import { useCallback, useMemo } from 'react';
import { FiltersDrawer, FiltersDrawerItem, FiltersProps } from '../Drawer';
import { string } from 'yup';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { useDispatch } from 'react-redux';
import { filtersSet } from 'store/actions/sites';
import getStatesCountries from 'utils/getStatesCountries';

export default function Filters({ values }: FiltersProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'name',
        label: formatMessage({ id: 'sites.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        // permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
        name: 'tenant_id',
        label: formatMessage({ id: 'sites.filters.tenant' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        initial: values['tenant_id'],
        schema: string(),
        getInitialOptions: async () => dispatch(getTenantsAutocompleteOptions()),
      },
      {
        name: 'address',
        label: formatMessage({ id: 'sites.filters.address' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        initial: values['address'],
        schema: string(),
      },
      {
        name: 'country',
        label: formatMessage({ id: 'sites.filters.country' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        autocompleteOptions: getStatesCountries(),
        initial: values['country'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
      },
    ],
    [dispatch, formatMessage, values]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(filtersSet(filtersValues)), [dispatch]);

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
