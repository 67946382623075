import React from 'react';
import { AutocompleteProps } from '@athonet/ui/components/Input/Autocomplete';
import AutocompleteComponent from './AutocompleteComponent';
import BaseField, { BaseFieldProps } from './BaseField';

const AutocompleteField: React.FC<AutocompleteProps<any, any, any, any> & BaseFieldProps> = (props) => {
  const { component, ...baseProps } = props;

  return <BaseField {...baseProps} component={component || AutocompleteComponent} />;
};

export default AutocompleteField;
