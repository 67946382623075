import { useSelector } from 'react-redux';

export function useManageTenantsModalSelector() {
  const modal = useSelector((state) => state.modal?.manageTenants);

  return modal;
}

export function useEditUserModalSelector() {
  const modal = useSelector((state) => state.modal?.editUserModal);

  return modal;
}
