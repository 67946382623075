import base from './config-base.json';

export const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';
const baseReportsApiUrl = process.env.REACT_APP_BASE_REPORTS_API_URL || '';

const LOCAL_STORAGE = 'Horus';
const LOCAL_STORAGE_ADMIN = `${LOCAL_STORAGE}-admin`;

const LOCAL_STORAGE_I18N = `${LOCAL_STORAGE}-i18n`;

const config: Record<string, any> = {
  ...base,
  localStorage: LOCAL_STORAGE,
  localStorageAdmin: LOCAL_STORAGE_ADMIN,
  localStorageI18N: LOCAL_STORAGE_I18N,
  sentry: {
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
  apis: {
    bootstrap: `${baseApiUrl}/api/configuration`,

    checkToken: `${baseApiUrl}/api/user/check`, // NOTE: the checkToken action has been removed
    refreshToken: `${baseApiUrl}/api/user/refresh`,

    authenticate: `${baseApiUrl}/api/session/authenticate`,
    login: `${baseApiUrl}/api/session/login`,
    sendReset: `${baseApiUrl}/api/session/forgotpassword`,
    resetPassword: `${baseApiUrl}/api/session/resetpassword`,
    confirmUser: `${baseApiUrl}/api/session/confirm/{temporary-code}`,
    confirmUserEmail: `${baseApiUrl}/api/session/email-confirm/{temporary-code}`,

    me: `${baseApiUrl}/api/user/me`,
    changePassword: `${baseApiUrl}/api/user/changepassword`,

    getLandingBadges: `${baseApiUrl}/api/landing/badges`,

    getTenantParents: `${baseApiUrl}/api/tenant/tenantparents?o={sort}&l={limit}&p={page}{filters}`,
    getTenantWithSubtenants: `${baseApiUrl}/api/tenant/tenantwithsubtenants?o={sort}&l={limit}&p={page}{filters}`,
    getTenants: `${baseApiUrl}/api/tenant/tenants/?o={sort}&l={limit}&p={page}{filters}`,
    createChannelPartner: `${baseApiUrl}/api/tenant/createchannelpartner`,
    createNetworkManager: `${baseApiUrl}/api/tenant/createnetworkmanager`,
    deleteTenants: `${baseApiUrl}/api/tenant/tenant/{id}`,
    getTenant: `${baseApiUrl}/api/tenant/tenant/{id}`,
    updateTenant: `${baseApiUrl}/api/tenant/tenant/{id}`,
    updateTenantLogo: `${baseApiUrl}/api/tenant/{id}/logo`,
    deleteTenantLogo: `${baseApiUrl}/api/tenant/{id}/logo`,

    getUsers: `${baseApiUrl}/api/tenant/users/?o={sort}&l={limit}&p={page}{filters}`,
    getUserRoles: `${baseApiUrl}/api/tenant/roles`,
    createUser: `${baseApiUrl}/api/tenant/createuser`,
    deleteUser: `${baseApiUrl}/api/tenant/user/{id}`,
    getUser: `${baseApiUrl}/api/tenant/user/{id}`,
    updateUser: `${baseApiUrl}/api/tenant/user/{id}`,

    getSites: `${baseApiUrl}/api/tenant/sites?o={sort}&l={limit}&p={page}{filters}`,
    createSite: `${baseApiUrl}/api/tenant/createsite`,
    deleteSite: `${baseApiUrl}/api/tenant/site/{id}`,
    updateSite: `${baseApiUrl}/api/tenant/site/{id}`,
    getSite: `${baseApiUrl}/api/tenant/site/{id}`,

    geolocal: 'https://nominatim.openstreetmap.org/search?q={address}&format=json&addressdetails=0', // TODO: remove

    getAllTenantsWithSites: `${baseApiUrl}/api/tenant/tenantswithsites`,

    getNodeTenants: `${baseApiUrl}/api/tenant/tenantswithsites?node_id={node_id}`,
    addTenantToNode: `${baseApiUrl}/api/sites/associatesitenode`,
    deleteTenantToNode: `${baseApiUrl}/api/sites/deassociatesitenode`,

    addTenantToUser: `${baseApiUrl}/api/tenant/adduser`,
    deleteTenantFromUser: `${baseApiUrl}/api/tenant/removeuser`,
    getTenantsByUser: `${baseApiUrl}/api/tenant/user/{user_id}/tenant`,

    getPools: `${baseApiUrl}/api/pools?o={sort}&l={limit}&p={page}{filters}`,

    getMetadataKeys: `${baseApiUrl}/api/metadata/keys?l={limit}&p={page}`,

    getUsims: `${baseApiUrl}/api/usims/filter?o={sort}&l={limit}&p={page}{filters}`,
    getUsim: `${baseApiUrl}/api/usims/{id}`,
    postUsims: `${baseApiUrl}/api/usims/filter?o={sort}&l={limit}&p={page}`,
    searchUsims: `${baseApiUrl}/api/usims/search`,
    getUsimEvents: `${baseApiUrl}/api/usims/{id}/events?o={sort}&l={limit}&p={page}{filters}`,
    createUsim: `${baseApiUrl}/api/usims`,
    createBulkUsim: `${baseApiUrl}/api/usims/upload`, // bulk
    editUsim: `${baseApiUrl}/api/usims/{id}`, // bulk
    deleteUsim: `${baseApiUrl}/api/usims/{id}`, // bulk
    updateUsimMetadata: `${baseApiUrl}/api/usims/{id}/metadata`, // bulk
    activateUsim: `${baseApiUrl}/api/usims/{id}/activate`, // bulk
    deactivateUsim: `${baseApiUrl}/api/usims/{id}/deactivate`, // bulk
    barUsim: `${baseApiUrl}/api/usims/{id}/bar`, // bulk
    unbarUsim: `${baseApiUrl}/api/usims/{id}/unbar`, // bulk
    deprovisionUsim: `${baseApiUrl}/api/usims/{id}/deprovision`, // bulk
    changeProfileUsim: `${baseApiUrl}/api/usims/{id}/changeprofile`, // bulk
    assignToTenantUsim: `${baseApiUrl}/api/usims/{id}/assigntotenant`, // bulk
    assignToSiteUsim: `${baseApiUrl}/api/usims/{id}/assigntosite`, // bulk
    assignToNodeUsim: `${baseApiUrl}/api/usims/{id}/assigntonode`, // bulk

    getUsims5g: `${baseApiUrl}/api/1/usims?o={sort}&l={limit}&p={page}`,
    createUsim5g: `${baseApiUrl}/api/1/usims`,
    createBulkUsim5g: `${baseApiUrl}/api/1/usims/upload`, // bulk
    editUsim5g: `${baseApiUrl}/api/1/usims/{id}`,
    provisionUsim5g: `${baseApiUrl}/api/1/usims/provision`, // bulk
    deprovisionUsim5g: `${baseApiUrl}/api/1/usims/deprovision`, // bulk
    prepareUsim5g: `${baseApiUrl}/api/1/usims/prepare-provision`, // bulk
    deleteUsims5g: `${baseApiUrl}/api/1/usims`, // bulk
    updateUsims5gMetadata: `${baseApiUrl}/api/1/usims/metadata`, // bulk

    deleteUsims: `${baseApiUrl}/api/usims/bulk{filters}`, // bulk
    updateUsimsMetadata: `${baseApiUrl}/api/usims/bulk/metadata{filters}`, // bulk
    activateUsims: `${baseApiUrl}/api/usims/bulk/activate{filters}`, // bulk
    deactivateUsims: `${baseApiUrl}/api/usims/bulk/deactivate{filters}`, // bulk
    barUsims: `${baseApiUrl}/api/usims/bulk/bar{filters}`, // bulk
    unbarUsims: `${baseApiUrl}/api/usims/bulk/unbar{filters}`, // bulk
    deprovisionUsims: `${baseApiUrl}/api/usims/bulk/deprovision{filters}`, // bulk
    changeProfileUsims: `${baseApiUrl}/api/usims/bulk/changeprofile{filters}`, // bulk
    assignToTenantUsims: `${baseApiUrl}/api/usims/bulk/assigntotenant{filters}`, // bulk
    assignToSiteUsims: `${baseApiUrl}/api/usims/bulk/assigntosite{filters}`, // bulk
    assignToNodeUsims: `${baseApiUrl}/api/usims/bulk/assigntonode{filters}`, // bulk
    exportUsims: `${baseApiUrl}/api/usims/export{filters}`,

    getUsimProfiles: `${baseApiUrl}/api/usim-profiles?o={sort}&l={limit}&p={page}{filters}`,
    getUsimProfile: `${baseApiUrl}/api/usim-profiles?id={id}`,
    exportUsimProfiles: `${baseApiUrl}/api/usim-profiles/export{filters}`,
    createUsimProfile: `${baseApiUrl}/api/usim-profiles`,
    assignUsimProfileSite: `${baseApiUrl}/api/usim-profiles/{id}/assignsite`, // bulk
    assignUsimProfileTenant: `${baseApiUrl}/api/usim-profiles/{id}/assigntenant`, // bulk
    deassignUsimProfileTenant: `${baseApiUrl}/api/usim-profiles/{id}/deassigntenant`, // bulk
    assignUsimProfileNode: `${baseApiUrl}/api/usim-profiles/{id}/assignnode`, // bulk
    deassignUsimProfileNode: `${baseApiUrl}/api/usim-profiles/{id}/deassignnode`, // bulk
    /* NOTE: CC-1285 */
    assignUsimProfileSegment: `${baseApiUrl}/api/usim-profiles/{id}/segments/{segment_id}`, // bulk
    deassignUsimProfileSegment: `${baseApiUrl}/api/usim-profiles/{id}/segments/{segment_id}`, // bulk
    editUsimProfile: `${baseApiUrl}/api/usim-profiles/{id}`,
    deleteUsimProfile: `${baseApiUrl}/api/usim-profiles/{id}`, // bulk

    /* NOTE: CC-1285 */
    getSegments: `${baseApiUrl}/api/segments?o={sort}&l={limit}&p={page}{filters}`,
    exportSegments: `${baseApiUrl}/api/segments/export{filters}`,
    getSegment: `${baseApiUrl}/api/segments?id={id}`,
    createSegment: `${baseApiUrl}/api/segments`,
    assignSegmentTenant: `${baseApiUrl}/api/segments/{id}/assigntenant`, // bulk
    deassignSegmentTenant: `${baseApiUrl}/api/segments/{id}/deassigntenant`, // bulk
    assignSegmentNode: `${baseApiUrl}/api/segments/{id}/assignnode`, // bulk
    deassignSegmentNode: `${baseApiUrl}/api/segments/{id}/deassignnode`, // bulk
    editSegment: `${baseApiUrl}/api/segments/{id}`, // bulk
    deleteSegment: `${baseApiUrl}/api/segments/{id}`, // bulk

    getQoSProfiles: `${baseApiUrl}/api/qos-profiles?o={sort}&l={limit}&p={page}{filters}`,
    getQoSProfile: `${baseApiUrl}/api/qos-profiles?id={id}`,
    createQoSProfile: `${baseApiUrl}/api/qos-profiles`,
    assignQoSProfileTenant: `${baseApiUrl}/api/qos-profiles/{id}/assigntenant`, // bulk
    deassignQoSProfileTenant: `${baseApiUrl}/api/qos-profiles/{id}/deassigntenant`, // bulk
    assignQoSProfileNode: `${baseApiUrl}/api/qos-profiles/{id}/assignnode`, // bulk
    deassignQoSProfileNode: `${baseApiUrl}/api/qos-profiles/{id}/deassignnode`, // bulk
    editQoSProfile: `${baseApiUrl}/api/qos-profiles/{id}`,
    deleteQoSProfile: `${baseApiUrl}/api/qos-profiles/{id}`, // bulk

    getBulkOperations: `${baseApiUrl}/api/1/bulk-operations`,
    getBulkOperation: `${baseApiUrl}/api/bulk-operations/{id}`,

    getUsimOrders: `${baseApiUrl}/api/usim-orders?o={sort}&l={limit}&p={page}{filters}`,
    createUsimOrder: `${baseApiUrl}/api/usim-orders`,
    deleteUsimOrder: `${baseApiUrl}/api/usim-orders/{id}`,
    downloadPlainUsimOrder: `${baseApiUrl}/api/usim-orders/{id}/download?type={type}`,
    downloadEncryptedUsimOrder: `${baseApiUrl}/api/usim-orders/{id}/download?type={type}&key={key_id}`,
    uploadUsimOrder: `${baseApiUrl}/api/usim-orders/upload`, // bulk

    getGpgKeys: `${baseApiUrl}/api/gpg-keys?o={sort}&l={limit}&p={page}{filters}`,
    createGpgKey: `${baseApiUrl}/api/gpg-keys`,
    deleteGpgKey: `${baseApiUrl}/api/gpg-keys/{id}`,

    getRoles: `${baseApiUrl}/api/role_management/role`,
    getRole: `${baseApiUrl}/api/role_management/role/{id}`,
    createRole: `${baseApiUrl}/api/role_management/role`,
    updateRole: `${baseApiUrl}/api/role_management/role/{id}`,
    deleteRole: `${baseApiUrl}/api/role_management/role/{id}`,
    getPermissions: `${baseApiUrl}/api/role_management/permission`,

    getRegionalSubscriptionsProfiles: `${baseApiUrl}/api/regional-subscriptions-profiles?o={sort}&l={limit}&p={page}{filters}`,

    getNodes: `${baseApiUrl}/api/node_management/node?o={sort}&l={limit}&p={page}{filters}&c={platform}`,
    getNode: `${baseApiUrl}/api/node_management/node/{id}`,
    updateNode: `${baseApiUrl}/api/node_management/node/{id}`,
    assignTenantNode: `${baseApiUrl}/api/node_management/node/{id}/assigntenant`,
    deassignTenantNode: `${baseApiUrl}/api/node_management/node/{id}/deassigntenant`,
    createNode: `${baseApiUrl}/api/node_management/node`,
    deleteNode: `${baseApiUrl}/api/node_management/node/{id}`,
    sendDataToRemoteUDR: `${baseApiUrl}/api/1/nodes/{id}/connect`,
    getAvailableNodes: `${baseApiUrl}/api/node_management/nodes_available`,

    getHssList: `${baseApiUrl}/api/node_management/network_element/hss?o={sort}&l={limit}&p={page}{filters}`,
    getHss: `${baseApiUrl}/api/node_management/network_element/hss/{id}`,
    updateHss: `${baseApiUrl}/api/node_management/network_element/hss/{id}`,
    createHss: `${baseApiUrl}/api/node_management/network_element/hss`,
    deleteHss: `${baseApiUrl}/api/node_management/network_element/hss/{id}`,

    getTimHssList: `${baseApiUrl}/api/node_management/network_element/hss_tim?o={sort}&l={limit}&p={page}{filters}`,
    getTimHss: `${baseApiUrl}/api/node_management/network_element/hss_tim/{id}`,
    updateTimHss: `${baseApiUrl}/api/node_management/network_element/hss_tim/{id}`,
    createTimHss: `${baseApiUrl}/api/node_management/network_element/hss_tim`,
    deleteTimHss: `${baseApiUrl}/api/node_management/network_element/hss_tim/{id}`,

    createUdr: `${baseApiUrl}/api/node_management/network_element/udr`,

    getPlmns: `${baseApiUrl}/api/1/nodes/{node_id}/plmns`,
    createPlmn: `${baseApiUrl}/api/1/nodes/{node_id}/plmns`,

    getServiceProfiles: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles`,
    createServiceProfile: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles`,
    editServiceProfile: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}`,
    createSlice: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}/slices`,
    createService: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}/slices/{slice_id}/services`,
    editSlice: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}/slices/{slice_id}`,
    editService: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}/slices/{slice_id}/services/{service_id}`,
    deleteServiceProfile: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}`,
    deleteSlice: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}/slices/{slice_id}`,
    deleteService: `${baseApiUrl}/api/1/nodes/{node_id}/service-profiles/{service_profile_id}/slices/{slice_id}/services/{service_id}`,

    getLandingPageUsimsInfo: `${baseApiUrl}/api/landing-page/usims-info`,
    getLandingPageUsimsInfo5g: `${baseApiUrl}/api/1/landing-page/usims-info`,
    getLandingPageTenants4gInfo: `${baseApiUrl}/api/landing-page/tenants-info`,
    getLandingPageTenants5gInfo: `${baseApiUrl}/api/1/landing-page/tenants-info`,
  },
  reportsApis: {
    diagnostic: {
      usimInfo: `${baseReportsApiUrl}/reports_api/diagnostic/usim_info/{imsi}`,
      usimOperator: `${baseReportsApiUrl}/reports_api/diagnostic/usim_operator/{imsi}`,
      usimEvents: `${baseReportsApiUrl}/reports_api/diagnostic/pgw_events?imsi={imsi}&l={limit}&p={page}`,
      // usimEvents: `${baseReportsApiUrl}/reports_api/diagnostic/pgw_events/{imsi}&l={limit}&p={page}`,
    },
  },
  cdrReportsApis: {
    trafficVolume: {
      total: `${baseReportsApiUrl}/cdr_api/traffic_volume/total`,
      series: `${baseReportsApiUrl}/cdr_api/traffic_volume/series`,
      schema: `${baseReportsApiUrl}/cdr_api/traffic_volume/schema`,
      availability_dates: `${baseReportsApiUrl}/cdr_api/traffic_volume/availability_dates`,
      ranking: `${baseReportsApiUrl}/cdr_api/traffic_volume/ranking`,
      search: `${baseReportsApiUrl}/cdr_api/traffic_volume/search`,
      timeseries: {
        schema: `${baseReportsApiUrl}/cdr_api/traffic_volume/timeseries/schema`,
        availabilityDates: `${baseReportsApiUrl}/cdr_api/traffic_volume/timeseries/availability_dates`,
        data: `${baseReportsApiUrl}/cdr_api/traffic_volume/timeseries/data`,
        exportData: `${baseReportsApiUrl}/cdr_api/traffic_volume/timeseries/export/data`,
      },
    },
    imeiChanges: {
      total: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/total`,
      series: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/series`,
      schema: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/schema`,
      availability_dates: `${baseReportsApiUrl}/cdr_api/traffic_volume/availability_dates`,
      ranking: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/ranking`,
      history: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/history`,
      search: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/search`,
      timeseries: {
        schema: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/timeseries/schema`,
        availabilityDates: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/timeseries/availability_dates`,
        data: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/timeseries/data`,
        exportData: `${baseReportsApiUrl}/cdr_api/sim_tracking/imei_changes/timeseries/export/data`,
      },
    },
  },
  cdrBillingApis: {
    billingConfiguration: `${baseReportsApiUrl}/cdr_api/billing/configuration`,
    updateBillingConfiguration: `${baseReportsApiUrl}/cdr_api/billing/configuration/{id}`,
    deleteBillingConfiguration: `${baseReportsApiUrl}/cdr_api/billing/configuration/{id}`,
    calculatedBills: `${baseReportsApiUrl}/cdr_api/billing/bill`,
  },
  adminConfigApis: {
    login: `${baseApiUrl}/api/admin-configs/login`,
    adminConfig: `${baseApiUrl}/api/admin-configs`,
    updateAdminConfig: `${baseApiUrl}/api/admin-configs/{id}`,
  },
};

export default config;
