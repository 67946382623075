import { useSelector } from 'react-redux';

export function useBulkOperations() {
  const bulkOperations = useSelector((state) => state.bulkOperations);

  return bulkOperations;
}

export function useInProgressBulkOperationCounter() {
  const bulkOperations = useBulkOperations();

  return (
    bulkOperations.scheduledOperations.length +
    (bulkOperations.entity.data?.filter((op) => {
      return op.progress < 100;
    }).length || 0)
  );
}
