import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';
import { useDispatch } from 'react-redux';
import { editTimHss } from 'store/actions/nodes';
import { parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';

export default function Edit({ data, isOpen, onClose }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const schema = object().shape({
    name: string().trim().required(),
    url: string().trim().required(),
    client_id: string().trim().max(50).required(),
    consumer_key: string().trim().required(),
    consumer_secret: string().trim(),
    username: string().trim().max(255).required(),
    tenant: string().trim().max(255).required(),
    password: string().trim(),
    certificate: string().trim(),
  });

  const fieldset = [
    {
      title: formatMessage({ id: 'timHss.form.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'timHss.form.name.label' }),
          placeholder: formatMessage({ id: 'timHss.form.name.placeholder' }),
          error: formatMessage({ id: 'timHss.form.name.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'url',
          label: formatMessage({ id: 'timHss.form.url.label' }),
          placeholder: formatMessage({ id: 'timHss.form.url.placeholder' }),
          error: formatMessage({ id: 'timHss.form.url.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'client_id',
          label: formatMessage({ id: 'timHss.form.client_id.label' }),
          placeholder: formatMessage({ id: 'timHss.form.client_id.placeholder' }),
          error: formatMessage({ id: 'timHss.form.client_id.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'consumer_key',
          label: formatMessage({ id: 'timHss.form.consumer_key.label' }),
          placeholder: formatMessage({ id: 'timHss.form.consumer_key.placeholder' }),
          error: formatMessage({ id: 'timHss.form.consumer_key.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'consumer_secret',
          label: formatMessage({ id: 'timHss.form.consumer_secret.label' }),
          placeholder: formatMessage({ id: 'timHss.form.consumer_secret.placeholder' }),
          error: formatMessage({ id: 'timHss.form.consumer_secret.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'username',
          label: formatMessage({ id: 'timHss.form.username.label' }),
          placeholder: formatMessage({ id: 'timHss.form.username.placeholder' }),
          error: formatMessage({ id: 'timHss.form.username.error' }),
        },
        {
          name: 'tenant',
          label: formatMessage({ id: 'timHss.form.tenant.label' }),
          placeholder: formatMessage({ id: 'timHss.form.tenant.placeholder' }),
          error: formatMessage({ id: 'timHss.form.tenant.error' }),
        },
        {
          name: 'password',
          label: formatMessage({ id: 'timHss.form.password.label' }),
          placeholder: formatMessage({ id: 'timHss.form.password.placeholder' }),
          error: formatMessage({ id: 'timHss.form.password.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'certificate',
          label: formatMessage({ id: 'timHss.form.certificate.label' }),
          placeholder: formatMessage({ id: 'timHss.form.certificate.placeholder' }),
          error: formatMessage({ id: 'timHss.form.certificate.error' }),
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  const initials = useMemo(
    () => ({
      name: data.name,
      url: data.payload.url,
      client_id: data.payload.client_id,
      consumer_key: data.payload.consumer_key,
      consumer_secret: '',
      username: data.payload.username,
      tenant: data.payload.tenant,
      password: '',
      certificate: data.payload.certificate || '',
    }),
    [data]
  );

  const handleEdit = useCallback(
    async (values, callback) => {
      const editData = {
        name: values.name.trim(),
        payload: {
          url: values.url.trim(),
          client_id: values.client_id.trim(),
          consumer_key: values.consumer_key.trim(),
          consumer_secret: values.consumer_secret.trim() !== '' ? values.consumer_secret.trim() : undefined,
          username: values.username.trim(),
          tenant: values.tenant.trim(),
          password: values.password.trim() !== '' ? values.password.trim() : undefined,
          certificate: values.certificate.trim() !== '' ? values.certificate.trim() : null,
        },
      };
      try {
        await dispatch(editTimHss(data.id, editData));
        callback(true);
        onClose();
      } catch (e) {
        sentryLogError(e);
        callback(false, parseError(e));
      }
    },
    [data.id, dispatch, onClose]
  );

  return (
    <FormStyled>
      <Base
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        initials={initials}
        fieldset={fieldset}
        onSubmit={handleEdit}
        modalTitle={formatMessage({ id: 'timHss.editTimHss' })}
      />
    </FormStyled>
  );
}

Edit.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
};
