import { useMemo } from 'react';

// Intl
import { useIntl } from 'react-intl';

// Material UI
import { Dialog } from '@athonet/ui/components/Overlay/Dialog';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';

import { Button } from '@athonet/ui/components/Input/Button';

// Style
import { ConfirmationPopupSubsectionStyled, ConfirmationPopupSubsectionField } from './styled';

export default function ConfirmationPopup({ onSubmit, onCancel, fieldset, values, fieldComponent }) {
  const { formatMessage } = useIntl();
  return (
    <Dialog open={true} title={formatMessage({ id: 'common.form.confirm' })}>
      <DialogContent>
        {fieldset
          .filter((fieldSection) =>
            // NOTE: check this rendering filter here
            fieldSection.data.some(
              (field) => values[field.name] !== '' && values[field.name] !== false && field.hidden !== true
            )
          )
          .map((fieldSection) => {
            return (
              <ConfirmationPopupSubsectionStyled>
                {fieldSection.title && <h3>{fieldSection.title}</h3>}
                <div>
                  {fieldSection.data
                    .filter(
                      (field) => values[field.name] !== '' && values[field.name] !== false && field.hidden !== true
                    )
                    .map((field) => (
                      <ConfirmationPopupField field={field} values={values} fieldComponent={fieldComponent} />
                    ))}
                </div>
              </ConfirmationPopupSubsectionStyled>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onCancel}
          disableElevation
          data-testid="footer-cancel"
          text={formatMessage({ id: 'common.form.cancel' })}
        />
        <Button
          onClick={onSubmit}
          disableElevation
          data-testid="footer-continue"
          text={formatMessage({ id: 'common.form.continue' })}
        />
      </DialogActions>
    </Dialog>
  );
}

export function ConfirmationPopupField({ field, values, fieldComponent: FieldComponent }) {
  const value = useMemo(() => {
    let v = '';
    if (field.options) {
      v = field.options.find((x) => x.value === values[field.name]).label;
    } else {
      v = values[field.name];
    }
    return v;
  }, [field, values]);

  if (FieldComponent) {
    return <FieldComponent field={field} value={value} />;
  }

  return (
    <ConfirmationPopupSubsectionField>
      <span>{field.label}:</span>
      <span className="value">{value}</span>
    </ConfirmationPopupSubsectionField>
  );
}
