import styled from 'styled-components';

export const MultiselectWrapper = styled.div`
  .multiselect-selectall {
    font-size: ${(props) => props.theme.input.footerText};
    padding: 0 !important;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding: 5px 10px 5px 10px;
    background-color: ${(props) => props.theme.input.background};
  }

  .MuiInputLabel-shrink {
    transform: translate(14px, -7px) scale(0.75);
  }

  .MuiInputBase-input {
    padding: 5px 14px 6px 4px !important;

    .MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
      padding: 10.5px 14px 10.5px 0;
    }
  }

  .MuiAutocomplete-tag {
    max-width: 35%;
  }
`;
