import { ButtonProps } from '@athonet/ui/components/Input/Button';
import React, { useCallback } from 'react';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { CancelButton } from 'components/Button/CancelButton';

const FormCancelButton: React.FC<
  ButtonProps & { formRef: React.RefObject<FormikProps<any>>; alertPendingChanges?: boolean }
> = (props) => {
  const { formRef, alertPendingChanges, ...buttonProps } = props;
  const { dialogClose, confirmationSimpleDialogOpen } = useOverlay();
  const { formatMessage } = useIntl();

  const handleDialogClose = useCallback(() => {
    if (!formRef?.current) console.error(formRef, 'SubmitButton form ref is not defined!');
    if (alertPendingChanges && formRef?.current?.dirty) {
      confirmationSimpleDialogOpen({
        description: formatMessage({ id: 'common.confirmPendingChanges' }),
        onConfirm: async () => void dialogClose(),
      });
    } else dialogClose();
  }, [alertPendingChanges, confirmationSimpleDialogOpen, dialogClose, formRef, formatMessage]);

  return (
    <CancelButton
      onClick={handleDialogClose}
      {...buttonProps}
      loading={formRef?.current?.isSubmitting}
      disabled={formRef?.current?.isSubmitting}
    />
  );
};

export default FormCancelButton;
