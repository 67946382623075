import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { State } from 'store';
import { showErrorToast, showSuccessToast } from '../toast';

export type CreateOrderRequest = {
  address_1: string;
  address_2: string;
  address_3: string;
  address_4: string;
  address_5: string;
  customer: string;
  description: string;
  first_imsi?: string;
  graph_ref: string;
  imsi2_pool_id?: string;
  imsi_pool_id?: string;
  msisdn_pool_id?: string;
  msisdn_type: string;
  profile: string;
  quantity: number;
  sim_id_1: string;
  sim_id_2: string;
  sim_id_3: string;
  sim_id_4: string;
  transport_key: string;
  type: string;
  encrypt: number;
  use_key?: string;
  op?: string;
};

export const createUsimOrder = createAsyncThunk<
  string,
  CreateOrderRequest,
  {
    rejectValue: null;
    state: State;
  }
>('usimOrders/create', async (orderRequest, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: `${config.apis.createUsimOrder}`,
      method: 'POST',
      data: orderRequest,
    };

    const response = await dispatch(fetchData<any>(options));
    dispatch(showSuccessToast());
    return response;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue(null);
  }
});
