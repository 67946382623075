import { useState, useEffect, useMemo, useCallback } from 'react';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import ListToolBar, { ListToolbarAction, ListToolbarProps } from 'components/ListToolBar';
import { FiltersObj, LegacyFiltersObj } from 'store/models/filters';
import { DATA_LIFECYCLE } from 'store/reducers';
import {
  Table,
  TableColumn,
  TableProps,
  TableSelectedRow,
  TABLE_SELECTION_OPERATION,
  TABLE_SORTING_ORDER,
} from '@athonet/ui/components/Data/Table';
import { getProperty } from 'dot-prop';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useDispatch } from 'react-redux';
import { setPageLimit } from 'store/actions/bootstrap';
import { PAGE_LIMIT, PAGE_LIMIT_OPTIONS } from 'store/models/environmentConfiguration';
import { AnyAction } from 'redux';

export type Alignment = 'left' | 'right' | 'center';

export type FrozenDirection = 'left' | 'right' | true | false;

export type CallOrReturn<T, P = any[]> = T | (P extends any[] ? (...p: P) => T : (p: P) => T);

// TODO: clean up the column shape to be as streamlined as possible to match what we actually pass from the containers
export interface ColumnShape<T = unknown> {
  key: React.Key;
  className?: CallOrReturn<
    string,
    {
      cellData: any;
      columns: ColumnShape<T>[];
      column: ColumnShape<T>;
      columnIndex: number;
      rowData: T;
      rowIndex: number;
    }
  >;
  headerClassName?: CallOrReturn<
    string,
    {
      columns: ColumnShape<T>[];
      column: ColumnShape<T>;
      columnIndex: number;
      headerIndex: number;
    }
  >;
  style?: React.CSSProperties;
  title?: string;
  dataKey?: string;
  dataGetter?: CallOrReturn<
    React.ReactNode,
    {
      columns: ColumnShape<T>[];
      column: ColumnShape<T>;
      columnIndex: number;
      rowData: T;
      rowIndex: number;
    }
  >;
  align?: Alignment;
  flexGrow?: number;
  flexShrink?: number;
  width: number;
  maxWidth?: number;
  minWidth?: number;
  frozen?: FrozenDirection;
  hidden?: boolean;
  resizable?: boolean;
  sortable?: boolean;
  cellRenderer?: CallOrReturn<
    React.ReactNode,
    {
      cellData: any;
      columns: ColumnShape<T>[];
      column: ColumnShape<T>;
      columnIndex: number;
      rowData: T;
      rowIndex: number;
    }
  >;
  headerRenderer?: CallOrReturn<
    React.ReactNode,
    {
      columns: ColumnShape<T>[];
      column: ColumnShape<T>;
      columnIndex: number;
      headerIndex: number;
    }
  >;
  [key: string]: any;
}

export type ListProps<D> = {
  columns: ColumnShape<D>[];
  totalRows: number;
  data: D[];
  page?: number;
  filters: FiltersObj | LegacyFiltersObj;
  onOrderChange: (sort: string) => void;
  selectable?: boolean;
  disableSelectAll?: boolean;
  toolbar?: Partial<{
    actions: boolean;
    hide: boolean;
    export: boolean;
    filters: boolean;
  }>;
  createComponent?: JSX.Element;
  filtersComponent?: JSX.Element;
  extraComponents?: JSX.Element;
  loadingState?: DATA_LIFECYCLE;
  onPageChange?: TableProps<D>['onPageChange'];
  actions?: ListToolbarAction<D>[];
  onDownload?: (rowDataOrFilters: D[] | FiltersObj) => void;
  rowsPerPage?: TableProps<D>['rowsPerPage'];
  onRefresh?: ListToolbarProps<D>['onRefresh'];
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  setFlushSelectionAction?: (shouldFlushSelection: boolean) => AnyAction;
  shouldFlushSelection?: boolean;
};

export default function List<D>({
  columns,
  totalRows,
  data,
  page = 0,
  filters,
  onOrderChange,
  selectable,
  disableSelectAll,
  toolbar,
  createComponent,
  filtersComponent,
  extraComponents,
  loadingState,
  actions,
  onDownload,
  onPageChange,
  onRefresh,
  rowsPerPage = PAGE_LIMIT,
  onRowsPerPageChange,
  shouldFlushSelection,
  setFlushSelectionAction,
}: ListProps<D>) {
  const bootstrap = useBootstrapSelector();
  const dispatch = useDispatch();

  const fieldSort = columns.filter((item) => item.defaultSort === 'asc' || item.defaultSort === 'desc')[0];
  const [sort, setSort] = useState<[keyof D, TABLE_SORTING_ORDER]>([
    (fieldSort?.key || columns[0].key) as keyof D,
    fieldSort?.defaultSort === TABLE_SORTING_ORDER.DESC ? TABLE_SORTING_ORDER.DESC : TABLE_SORTING_ORDER.ASC,
  ]);

  const [selectedRows, setSelectedRows] = useState<TableSelectedRow<D>[]>([]);
  const [selectedByQuery, setSelectedByQuery] = useState(false);
  const [selectedData, setSelectedData] = useState<D[]>([]);

  const defaultVisibleColumns = useMemo(() => {
    const visibleColumnsMap = new Map<keyof D, boolean>();
    columns.forEach((column: any) => {
      if (!column.secret) {
        // secret is a custom field to hide in columns management panel
        visibleColumnsMap.set(column.dataKey, column.visible !== false);
      }
    });
    return visibleColumnsMap;
  }, [columns]);

  const [visibleColumns, setVisibleColumns] = useState<Map<keyof D, boolean>>(defaultVisibleColumns);

  const selectedItems = useCallback(() => {
    if (selectedByQuery && bootstrap) {
      if (totalRows < bootstrap.max_selectable_items) {
        return totalRows;
      } else {
        return bootstrap.max_selectable_items;
      }
    } else {
      return selectedRows.length;
    }
  }, [bootstrap, selectedByQuery, selectedRows, totalRows]);

  const downloadData = useMemo(() => {
    if (onDownload) {
      return {
        onDownload,
        downloadItems: selectedItems,
      };
    }
    return;
  }, [onDownload, selectedItems]);

  const computedColumns = useMemo((): TableColumn<D>[] => {
    return columns
      .filter((column) => {
        return column.dataKey === 'tools' || (!column.hidden && visibleColumns.get(column.dataKey as keyof D));
      })
      .map((column, columnIndex) => {
        return {
          label: column.title || '',
          key: column.dataKey as unknown as keyof D,
          cellRender: column.cellRenderer
            ? (row) => {
                if (typeof column.cellRenderer === 'function') {
                  const cellData = getProperty(row, column.dataKey as string);
                  return column.cellRenderer({
                    cellData,
                    columns,
                    column,
                    columnIndex,
                    rowData: row,
                    rowIndex: 0,
                  });
                } else {
                  return column.cellRenderer;
                }
              }
            : undefined,
          maxWidth: column.maxWidth?.toString(),
          minWidth: column.width?.toString() || column.minWidth?.toString(),
          width: column.width?.toString(),
          sortable: column.sortable,
          wordBreak: 'keep-all',
          numeric: column.numeric || column.dataKey === 'tools',
        };
      });
  }, [columns, visibleColumns]);

  const onColumnChange = useCallback((visibleCols: Map<keyof D, boolean>) => {
    setVisibleColumns(visibleCols);
  }, []);

  const getSortQuery = useCallback((order: TABLE_SORTING_ORDER, orderBy: keyof D) => {
    return `${order === TABLE_SORTING_ORDER.DESC ? '-' : ''}${String(orderBy)}`;
  }, []);

  const handleSort = useCallback((order: TABLE_SORTING_ORDER, orderBy: keyof D) => {
    setSort([orderBy, order]);
  }, []);

  // NOTE: sorting + initial load (wrong pattern but needed)
  useEffect(() => {
    const [orderBy, order] = sort;
    if (onOrderChange) {
      onOrderChange(getSortQuery(order, orderBy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const handleSelectedRow = useCallback(
    (rows: TableSelectedRow<D>[], all: boolean, row: D | null, op: TABLE_SELECTION_OPERATION) => {
      setSelectedData((prevSelectedData) => {
        switch (op) {
          case TABLE_SELECTION_OPERATION.SELECTED: {
            if (!row) {
              return prevSelectedData;
            }
            return [...prevSelectedData, row];
          }
          case TABLE_SELECTION_OPERATION.DESELECTED: {
            if (!row) {
              return prevSelectedData;
            }
            return prevSelectedData.filter((item) => item['id' as keyof D] !== row['id' as keyof D]);
          }
          case TABLE_SELECTION_OPERATION.SELECTED_ALL:
          case TABLE_SELECTION_OPERATION.DESELECTED_ALL: {
            return [];
          }
        }
      });
      setSelectedRows(rows);
      setSelectedByQuery(all);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (r: number) => {
      dispatch(setPageLimit(r));
      if (onRowsPerPageChange) {
        onRowsPerPageChange(r);
      }
    },
    [dispatch, onRowsPerPageChange]
  );

  const handleFlushSelection = useCallback(() => {
    setSelectedRows([]);
    setSelectedByQuery(false);
    setSelectedData([]);
  }, []);

  useEffect(() => {
    if (shouldFlushSelection && setFlushSelectionAction) {
      handleFlushSelection();
      dispatch(setFlushSelectionAction(false));
    }
  }, [dispatch, handleFlushSelection, setFlushSelectionAction, shouldFlushSelection]);

  if (!bootstrap) {
    return null;
  }

  return (
    <>
      <ListToolBar
        hide={toolbar?.hide}
        showActions={toolbar?.actions}
        showFilters={toolbar?.filters}
        filters={filters}
        filtersComponent={filtersComponent}
        loadingState={loadingState}
        columns={columns}
        onColumnChange={onColumnChange}
        visibleColumns={visibleColumns}
        createComponent={createComponent}
        extraComponents={extraComponents}
        selectedRows={selectedData}
        totalRows={totalRows}
        selectable={selectable}
        selectedByQuery={selectedByQuery}
        actions={actions}
        downloadData={downloadData}
        onRefresh={onRefresh}
      />
      <Stack fullHeight fullWidth>
        <Table
          data={Array.isArray(data) ? data : []}
          columns={computedColumns}
          rowKey={'id' as keyof D}
          data-testid="table"
          orderBy={sort[0]}
          order={sort[1]}
          rowsCount={totalRows}
          page={page}
          onPageChange={onPageChange}
          autoScale={true}
          selectable={selectable ? selectable : undefined}
          disableSelectAll={disableSelectAll ? disableSelectAll : undefined}
          loading={loadingState !== undefined && loadingState !== DATA_LIFECYCLE.SUCCESS}
          onSort={handleSort}
          selectedRows={selectedRows}
          selectedAllRows={selectedByQuery}
          onSelectedRow={handleSelectedRow}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={PAGE_LIMIT_OPTIONS}
        />
      </Stack>
    </>
  );
}
