import { useSelector } from 'react-redux';
import { BillingConfig } from 'store/models/billing';

export function useBillingConfigurations() {
  const billingConfigurations = useSelector((state) => state.cdrBilling.billingConfigurations);
  return billingConfigurations;
}

export function useDeleteBillingConfigurations() {
  const deleteBillingConfigurations = useSelector((state) => state.cdrBilling.deleteBillingConfigurations);
  return deleteBillingConfigurations;
}

export function useFilterBillingConfigurations() {
  return (
    useBillingConfigurations()
      .data?.reduce((arr: BillingConfig[], obj: BillingConfig) => {
        if (arr.length && arr.some((o) => o.operator.toLowerCase() === obj.operator.toLowerCase())) return arr;
        arr.push(obj);
        return arr;
      }, [])
      .sort((a: BillingConfig, b: BillingConfig) => {
        return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
      }) || []
  );
}

export function useCalculatedBills() {
  const calculatedBills = useSelector((state) => state.cdrBilling.calculatedBills);
  return calculatedBills;
}
