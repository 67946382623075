import { getPointFileSize, getTotalFileSize } from 'containers/Reports/Cdr/utils/utils';
import { useSelector } from 'react-redux';
import { CdrTrafficVolumeSeries } from 'store/models/cdr';
import { Usim } from 'store/models/usim';
import { DATA_LIFECYCLE } from 'store/reducers';

export function useUsimsSelector() {
  const usims = useSelector((state) => state.usims);
  return usims;
}

export function useUsimsListSelector() {
  const list = useSelector((state) => state.usims.list);
  return list;
}

export function useUsimsFiltersSelector() {
  const filters = useSelector((state) => state.usims.filters);
  return filters;
}

export function useUsimEntitySelector(id: Usim['id']) {
  const usims = useSelector((state) => state.usims.entities);
  return usims[id];
}

function mapTrafficSeries(trafficSeries: CdrTrafficVolumeSeries) {
  return trafficSeries.map((item) => {
    const datetime = item.datetime.toString();
    return [datetime, item.totallink !== null ? Number(item.totallink) : '-'];
  });
}

export function useUsimTrafficVolumeSeriesSelector(usimId: Usim['id']) {
  const usim = useSelector((state) => state.usims.entities[usimId]);
  if (!usim || usim.state !== DATA_LIFECYCLE.SUCCESS || !usim.data?.trafficSeries) {
    return [];
  }
  return mapTrafficSeries(usim.data.trafficSeries);
}

export function useLast24hsTrafficKPISelector(usimId: Usim['id']) {
  const usim = useSelector((state) => state.usims.entities[usimId]);
  if (!usim || usim.state !== DATA_LIFECYCLE.SUCCESS || !usim.data?.trafficSeries) {
    return null;
  }
  const series = mapTrafficSeries(usim.data.trafficSeries);
  const lastValue = series?.length ? series[series.length - 1] : [null, null];
  const last24hsFileSize = typeof lastValue[1] === 'number' ? getPointFileSize(lastValue[1]) : ['0'];
  return last24hsFileSize ? `${last24hsFileSize[0].toString() || ''} ${last24hsFileSize[1] || ''}` : '';
}

export function useLast7DaysTrafficKPISelector(usimId: Usim['id']) {
  const usim = useSelector((state) => state.usims.entities[usimId]);
  if (!usim || usim.state !== DATA_LIFECYCLE.SUCCESS || !usim.data?.totalTraffic) {
    return null;
  }
  const totalFileSize = usim.data.totalTraffic.totallink ? getTotalFileSize(usim.data.totalTraffic).totallink : ['0'];
  return `${totalFileSize[0].toString() || ''} ${totalFileSize[1] || ''}`;
}
