import { useSelector } from 'react-redux';

export function useUsersSelector() {
  const users = useSelector((state) => state.users);
  return users;
}

export function useUsersListSelector() {
  const list = useSelector((state) => state.users.list);
  return list;
}

export function useUsersFiltersSelector() {
  const filters = useSelector((state) => state.users.filters);
  return filters;
}
