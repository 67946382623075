import { Outlet } from 'react-router-dom';
import TrafficVolumeToolbar from './TrafficVolumeToolbar';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import {
  getCdrTrafficVolumeAvailabilityDates,
  getCdrTrafficVolumeSchema,
} from 'store/actions/cdrReports/trafficVolume';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function CdrTrafficVolumeLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCdrTrafficVolumeSchema());
    dispatch(getCdrTrafficVolumeAvailabilityDates()); // TODO: fetch availability dates in long polling or at any other query being triggered under the sections
  }, [dispatch]);

  return (
    <>
      <TrafficVolumeToolbar />
      <Box sx={{ pt: 2, flex: '1 1 auto' }}>
        <Outlet />
      </Box>
    </>
  );
}
