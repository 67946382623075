import React from 'react';
import BaseField, { BaseFieldProps } from './BaseField';
import FileUploadComponent from './FileUploadComponent';
import { FileUploadProps } from '@athonet/ui/components/Input/FileUpload';

const FileUploadField: React.FC<FileUploadProps & BaseFieldProps> = (props) => {
  const { ...baseProps } = props;

  return <BaseField {...baseProps} component={FileUploadComponent} />;
};

export default FileUploadField;
