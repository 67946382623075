import { Divider } from '@athonet/ui/components/Layout/Divider';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { Fragment, useCallback, useMemo } from 'react';
import { SitesSection } from 'components/NodeId/Legacy/SitesSection';
import { LegacyNetworkElementsSection } from 'components/NodeId/Legacy/NetworkElementsSections';
import { useCanUserUpdateNodeSelector, useHasUserCPMasterTenantSelector } from 'store/selectors/user';
import { useIntl } from 'react-intl';
import { openManageTenantsModal } from 'store/actions/modal';
import { RESOURCE } from 'store/models/modal';
import { getNodeById } from 'store/actions/nodes';
import { useDispatch } from 'react-redux';
import { useNodeEntitySelector } from 'store/selectors/nodes';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { EditNode } from 'components/Edit/Nodes/EditNode';

export function LegacyNodeContent() {
  const { formatMessage } = useIntl();
  const hasUserCPMasterTenant = useHasUserCPMasterTenantSelector();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const dispatch = useDispatch();
  const nodeEntity = useNodeEntitySelector();
  const { dialogOpen } = useOverlay();

  const entity = useMemo(() => {
    return nodeEntity.data;
  }, [nodeEntity]);

  const openManageTenants = useCallback(() => {
    if (!entity) {
      return;
    }
    dispatch(
      openManageTenantsModal({
        resource: RESOURCE.NODES,
        id: entity.id,
        cb: () => dispatch(getNodeById(entity.id, true)),
      })
    );
  }, [dispatch, entity]);

  const handleOpenEdit = useCallback(() => {
    if (!entity) {
      return;
    }

    dialogOpen({
      title: formatMessage({ id: 'nodes.editNode' }),
      content: () => <EditNode node={entity} />,
    });
  }, [dialogOpen, entity, formatMessage]);

  if (!entity) {
    return null;
  }

  return (
    <GridContainer sx={{ mt: 1 }}>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <Panel
          title={formatMessage({ id: 'common.detail.generalInfo' })}
          headerActionsComponent={
            <IconButton name="Pencil" onClick={handleOpenEdit} fontSize="small" disabled={!canUserUpdateNode} />
          }
        >
          <PanelContent>
            <GridContainer>
              <KeyValueGridItem label={formatMessage({ id: 'nodes.table.name' })} value={entity.display_name} />
            </GridContainer>
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <Panel title={formatMessage({ id: 'nodes.detail.nodeType' })}>
          <PanelContent>
            <KeyValueGridItem label={formatMessage({ id: 'nodes.detail.nodeType' })} value={entity.product_version} />
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        {hasUserCPMasterTenant && (
          <Panel
            title={formatMessage({ id: 'common.detail.ownership' })}
            headerActionsComponent={
              <IconButton name="Pencil" fontSize="small" onClick={openManageTenants} disabled={!canUserUpdateNode} />
            }
          >
            <PanelContent>
              {entity.owners.length
                ? entity.owners.map((owner, i) => (
                    <Fragment key={owner.id}>
                      <KeyValueGridItem label={formatMessage({ id: 'nodes.detail.tenantName' })} value={owner.name} />
                      {i + 1 < entity.owners.length && (
                        <GridItem size={{ xs: 12 }} sx={{ mt: 1, mb: 1 }}>
                          <Divider />
                        </GridItem>
                      )}
                    </Fragment>
                  ))
                : '-'}
            </PanelContent>
          </Panel>
        )}
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <SitesSection id={entity.id} sites={entity.sites} />
      </GridItem>
      <LegacyNetworkElementsSection networkElements={entity.network_elements} />
    </GridContainer>
  );
}
