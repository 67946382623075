import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getAvailableNodes } from 'store/actions/nodes';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { object, string } from 'yup';
import { NewNodeStepProps } from './CreateNodeContent';

export function Step3({ onCompleteStep, initialValues }: NewNodeStepProps) {
  const { formatMessage } = useIntl();
  const bootstrap = useBootstrapSelector();
  const dispatch = useDispatch();
  const [nodesOptions, setNodesOptions] = useState([]);

  useEffect(() => {
    async function getNodeOptions() {
      const nodesRes = await dispatch(getAvailableNodes());
      setNodesOptions(nodesRes);
    }
    void getNodeOptions();
  }, [dispatch]);

  const validationSchema = useMemo(() => {
    return object().shape({
      product_version: string().required(),
    });
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(2, values);
    },
    [onCompleteStep]
  );

  if (!bootstrap) return null;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, setFieldValue }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
              <Field name="product_version">
                {({ field }: FieldProps<string>) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, v) => {
                      setFieldValue('product_version', v);
                    }}
                    label={formatMessage({ id: 'nodes.form.product_version.label' })}
                    placeholder={formatMessage({ id: 'nodes.form.product_version.placeholder' })}
                    error={Boolean(errors['product_version'])}
                    {...(Boolean(errors['product_version']) && {
                      helperText: formatMessage({ id: 'nodes.form.product_version.placeholder' }),
                    })}
                    options={nodesOptions}
                    size="small"
                  />
                )}
              </Field>
              <Button type="submit" variant="outlined" text={formatMessage({ id: 'common.form.next' })} />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
