import { RolesAction, ROLES_ACTION_TYPE } from 'store/actions/roles';
import { Role } from 'store/models/role';
import { List, listFailure, listIdle, listLoading, listSuccess } from '.';

export type RolesState = {
  list: List<Role>;
};

const initialState: RolesState = {
  list: listIdle<Role>(),
};

export default function rolesReducer(state = initialState, action: RolesAction): RolesState {
  switch (action.type) {
    case ROLES_ACTION_TYPE.LIST_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        list: listSuccess({
          data,
          total: data.length,
        }),
      };
    case ROLES_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };
    case ROLES_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: listFailure(),
      };
    default:
      return state;
  }
}
