import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { string, object, ref } from 'yup';
import { useIntl } from 'react-intl';
import Link from 'components/Link';
import Input from 'components/Form/Input';
import Spinner from 'components/Spinner';
import PasswordStrength from 'components/Form/PasswordStrength';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ResetPasswordFormStyled } from './styled';
import { Button } from '@athonet/ui/components/Input/Button';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Text } from '@athonet/ui/components/Guidelines/Text';

const ResetPasswordForm = ({ onSubmit, onBack }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { formatMessage } = useIntl();

  const schema = object().shape({
    password: string().min(8).required(),
    passwordConfirm: string()
      .oneOf([ref('password'), null], formatMessage({ id: 'login.reset.matchPasswordError' }))
      .required(formatMessage({ id: 'login.passwordError' })),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ResetPasswordFormStyled data-testid="resetPasswordForm">
      <Formik
        initialValues={{ password: '', passwordConfirm: '' }}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        validationSchema={schema}
      >
        {({ isSubmitting, submitForm, values }) => (
          <Panel title={formatMessage({ id: 'login.reset.title' })}>
            <PanelContent>
              {isSubmitting && (
                <div className="reset-spinner-wrapper">
                  <p>{formatMessage({ id: 'spinner.sending' })}</p>
                  <Spinner className="login-spinner" size={100} />
                </div>
              )}
              <p className="subtitle">{formatMessage({ id: 'login.reset.subtitleConfirm' })}</p>
              <Form noValidate autoComplete="off">
                <Field name="password">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }) => (
                    <Input
                      inputProps={{
                        'data-testid': 'resetPasswordForm-password',
                      }}
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      label={formatMessage({ id: 'login.passwordLabel' })}
                      placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                      error={touched['password'] && errors['password'] ? true : false}
                      helperText={
                        touched['password'] && errors['password'] && formatMessage({ id: 'login.passwordError' })
                      }
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-testid="resetPasswordForm-showPassword"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>

                <Field name="passwordConfirm">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }) => (
                    <Input
                      inputProps={{
                        'data-testid': 'resetPasswordForm-passwordConfirm',
                      }}
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      value={values.passwordConfirm}
                      label={formatMessage({ id: 'login.reset.passwordLabel' })}
                      placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                      error={touched['passwordConfirm'] && errors['passwordConfirm'] ? true : false}
                      helperText={touched['passwordConfirm'] && errors['passwordConfirm']}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-testid="resetPasswordForm-showPasswordConfirm"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>

                <PasswordStrength password={values['password']} />

                <Button
                  data-testid="resetPasswordForm-submitButton"
                  onClick={() => {
                    void submitForm();
                  }}
                  disabled={isSubmitting}
                  className="btn-submit"
                  color="secondary"
                  text={formatMessage({ id: 'login.reset.submitButton' })}
                />

                <Link data-testid="resetPasswordForm-backLink" onClick={onBack}>
                  <Text align="center">{formatMessage({ id: 'login.reset.backButton' })}</Text>
                </Link>
              </Form>
            </PanelContent>
          </Panel>
        )}
      </Formik>
    </ResetPasswordFormStyled>
  );
};

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
};
