import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { showInfoToast } from 'store/actions/toast';
import { Button } from '@athonet/ui/components/Input/Button';
import { useAppDispatch } from 'store';

interface JsonViewerDialogProps {
  data: any;
  onClose: () => void;
}

const JsonViewerDialog: React.FC<JsonViewerDialogProps> = ({ data, onClose }) => {
  const { formatMessage } = useIntl();
  const elTextarea = useRef<HTMLTextAreaElement>(null);
  const dispatch = useAppDispatch();

  const handleCopy = () => {
    elTextarea.current?.select();
    document.execCommand('copy');
    dispatch(
      showInfoToast({
        message: 'jsonViewer.copied',
        intlMessage: true,
      })
    );
  };

  return (
    <>
      <DialogContent>
        <pre>{JSON.stringify(data, undefined, 2)}</pre>
      </DialogContent>

      <DialogActions>
        <textarea
          readOnly
          className="clipboard"
          style={{ position: 'absolute', left: '-9999px' }}
          ref={elTextarea}
          value={JSON.stringify(data, undefined, 2)}
        />
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="footer-cancel"
          text={formatMessage({ id: 'common.form.cancel' })}
        />

        <Button onClick={handleCopy} data-testid="footer-copy" text={formatMessage({ id: 'jsonViewer.copy' })} />
      </DialogActions>
    </>
  );
};

export default JsonViewerDialog;
