import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cdrFlush } from 'store/actions/cdrReports';

export function CdrHoc({ children }: { children: JSX.Element }) {
  const [cdrFlushed, setCdrFlushed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function flushCdr() {
      await dispatch(cdrFlush());
      setCdrFlushed(true);
    }
    void flushCdr();
  }, [dispatch]);

  if (!cdrFlushed) {
    return null;
  }

  return children;
}
