// React
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string, number } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Style
import { FormStyled } from '../styled';

// Utils
// import { bitRateToString } from 'utils/byteConverter';

const Create = ({ data, onSubmit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();
  // const [uploadValue, setUploadValue] = useState(bitRateToString(1));
  // const [downloadValue, setDownloadValue] = useState(bitRateToString(1));

  // const uploadChange = (setFieldValue, val) => {
  //   setUploadValue(bitRateToString(val.target.value));
  // };
  // const downloadChange = (setFieldValue, val) => {
  //   setDownloadValue(bitRateToString(val.target.value));
  // };

  const schema = object().shape({
    name: string().trim().required(),
    qci: number().required(),
    alert_reason: number().required(),
    priority: number().min(1).max(15).required(),
    preemption_capability: number().min(0).max(1).required(),
    preemption_vulnerability: number().min(0).max(1).required(),
    ambr_ul: number().min(1).required(),
    ambr_dl: number().min(1).required(),
    vplmn_dynamic_address_allowed: number().min(0).max(1).required(),
  });

  const initials = {
    name: '',
    qci: 9,
    alert_reason: 1,
    priority: 1,
    preemption_capability: 0,
    preemption_vulnerability: 0,
    ambr_ul: 943718400,
    ambr_dl: 943718400,
    vplmn_dynamic_address_allowed: 0,
  };

  const qciOptions = useMemo(
    () => [
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 65, label: '65' },
      { value: 66, label: '66' },
      { value: 69, label: '69' },
      { value: 70, label: '70' },
    ],
    []
  );

  const preemptionCapabilityOptions = useMemo(
    () => [
      { value: 0, label: formatMessage({ id: 'common.form.enabled' }) },
      { value: 1, label: formatMessage({ id: 'common.form.disabled' }) },
    ],
    [formatMessage]
  );

  const preemptionVulnerabilityOptions = useMemo(
    () => [
      { value: 0, label: formatMessage({ id: 'common.form.enabled' }) },
      { value: 1, label: formatMessage({ id: 'common.form.disabled' }) },
    ],
    [formatMessage]
  );

  const VPLMNDynamicAddressOptions = useMemo(
    () => [
      { value: 1, label: formatMessage({ id: 'common.form.allowed' }) },
      { value: 0, label: formatMessage({ id: 'common.form.notAllowed' }) },
    ],
    [formatMessage]
  );

  const fieldset = useMemo(
    () => [
      {
        title: formatMessage({ id: 'segments.form.qosProfile.data' }),
        data: [
          {
            name: 'name',
            label: formatMessage({ id: 'segments.form.qosProfile.name.label' }),
            placeholder: formatMessage({ id: 'segments.form.qosProfile.name.placeholder' }),
            error: formatMessage({ id: 'segments.form.qosProfile.name.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'qci',
            label: formatMessage({ id: 'segments.form.qosProfile.qci' }),
            options: qciOptions,
          },
          {
            name: 'vplmn_dynamic_address_allowed',
            label: formatMessage({ id: 'segments.form.qosProfile.vplmn_dynamic_address_allowed' }),
            options: VPLMNDynamicAddressOptions,
          },
          /*{
          name: "alert_reason",
          label: formatMessage({id:"segments.form.qosProfile.alert_reason"}),
          placeholder: formatMessage({id:"segments.form.qosProfile.alert_reason.placeholder"}),
          error: formatMessage({id:"segments.form.qosProfile.alert_reason.error"}),
        },*/
          {
            name: 'ambr_ul',
            label: formatMessage({ id: 'segments.form.qosProfile.ambr_ul.label' }),
            placeholder: formatMessage({ id: 'segments.form.qosProfile.ambr_ul.placeholder' }),
            error: formatMessage({ id: 'segments.form.qosProfile.ambr_ul.error' }),
            //onChange: uploadChange,
            type: 'number',
          },
          {
            name: 'ambr_dl',
            label: formatMessage({ id: 'segments.form.qosProfile.ambr_dl.label' }),
            placeholder: formatMessage({ id: 'segments.form.qosProfile.ambr_dl.placeholder' }),
            error: formatMessage({ id: 'segments.form.qosProfile.ambr_dl.error' }),
            //onChange: downloadChange,
            type: 'number',
          },
        ],
      },
      {
        title: formatMessage({ id: 'segments.form.qosProfile.arp' }),
        data: [
          {
            name: 'priority',
            label: formatMessage({ id: 'segments.form.qosProfile.priority.label' }),
            placeholder: formatMessage({ id: 'segments.form.qosProfile.priority.placeholder' }),
            error: formatMessage({ id: 'segments.form.qosProfile.priority.error' }),
            gridClassName: 'fullwidth',
            type: 'number',
          },
          {
            name: 'preemption_capability',
            label: formatMessage({ id: 'segments.form.qosProfile.preemption_capability' }),
            options: preemptionCapabilityOptions,
          },
          {
            name: 'preemption_vulnerability',
            label: formatMessage({ id: 'segments.form.qosProfile.preemption_vulnerability' }),
            options: preemptionVulnerabilityOptions,
          },
        ],
      },
    ],
    [VPLMNDynamicAddressOptions, formatMessage, preemptionCapabilityOptions, preemptionVulnerabilityOptions, qciOptions]
  );

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [checkPermissions, data, fieldset]);

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onSubmit}
        modalTitle={formatMessage({ id: 'qosProfiles.newQoSProfile' })}
        buttonLabel={formatMessage({ id: 'qosProfiles.newQoSProfile' })}
      />
    </FormStyled>
  );
};

Create.propTypes = {
  data: PropTypes.any,
};

export default Create;
