import { Entity, entityLoading, entityIdle, entitySuccess, entityFailure } from '.';
import { AdminConfigAction, ADMIN_CONFIG_TYPE } from 'store/actions/adminconfig';
import { AdminConfig } from 'store/models/adminConfig';

export type AdminConfigState = {
  user: Entity<string>;
  adminConfigData: Entity<AdminConfig>;
};

const initialState: AdminConfigState = {
  user: entityIdle(),
  adminConfigData: entityIdle(),
};

export default function adminConfigReducer(state = initialState, action: AdminConfigAction): AdminConfigState {
  switch (action.type) {
    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_LOADING:
      return {
        ...state,
        user: entityLoading(),
      };
    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_SUCCESS:
      return {
        ...state,
        user: entitySuccess<string>(action.payload),
      };
    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_FAILURE:
      return {
        ...state,
        user: entityFailure(),
      };

    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_LOADING:
      return {
        ...state,
        adminConfigData: entityLoading(),
      };
    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        adminConfigData: entitySuccess<AdminConfig>(action.payload),
      };
    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_FAILURE:
      return {
        ...state,
        adminConfigData: entityFailure(),
      };
    case ADMIN_CONFIG_TYPE.ADMIN_CONFIG_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
