import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getUsims5gSupi, provisionUsim5g } from 'store/actions/usims5g';
import { useUsims5gDetailsSelector } from 'store/selectors/5gProvisioning/usimCards';
import { DetailPageSkeleton } from 'components/Skeletons/DetailPageSkeleton';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import { Box } from '@material-ui/core';
import { useCanUserUpdateUsimSelector } from '../../../store/selectors/user';

export enum TAB {
  DETAILS = 'details',
}

export function UsimDetailsLayout() {
  const { id } = useParams<DetailPageParams>();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const usim5gDetailsEntity = useUsims5gDetailsSelector();
  const canUserUpdateUsimEntity = useCanUserUpdateUsimSelector();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationPaths = location.pathname.split('/');
  const tab = locationPaths[locationPaths.length - 1];

  const entity = useMemo(() => {
    return usim5gDetailsEntity.data;
  }, [usim5gDetailsEntity]);

  useEffect(() => {
    void dispatch(getUsims5gSupi(id as string));
  }, [dispatch, id]);

  const handleSend = useCallback(() => {
    if (!entity) {
      return;
    }
    void dispatch(provisionUsim5g({ usims5g: [entity.id] }));
  }, [dispatch, entity]);

  const handleTabChange = useCallback(
    (newTab: TAB) => {
      navigate(`/subscribers/${id}/${newTab}`, {
        replace: true,
      });
    },
    [id, navigate]
  );

  const usimDetailTabs = useMemo(
    () => [
      {
        value: TAB.DETAILS,
        label: formatMessage({ id: 'usim.tabs.details' }),
      },
    ],
    [formatMessage]
  );

  useEffect(() => {
    if (tab !== TAB.DETAILS) {
      navigate(`/subscribers/${id}/${TAB.DETAILS}`, {
        replace: true,
      });
    }
  }, [id, navigate, tab]);

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {entity && (
          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            <Button
              text={formatMessage({ id: 'common.button.sync' })}
              onClick={handleSend}
              disabled={!canUserUpdateUsimEntity}
            />
          </Stack>
        )}
      </Toolbar>
      {!entity ? (
        <DetailPageSkeleton />
      ) : (
        <>
          <Box sx={{ mb: 2 }}>
            <Tabs
              value={tab}
              tabs={usimDetailTabs}
              onChange={(value) => {
                handleTabChange(value as TAB);
              }}
            />
          </Box>
          <Outlet />
        </>
      )}
    </>
  );
}
