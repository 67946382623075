import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Progress } from '@athonet/ui/components/Feedback/Progress';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useCallback, useEffect, useState } from 'react';
import { createSamplePlmn, createSampleServiceProfile } from 'store/actions/nodes';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { IconButton } from '@athonet/ui/components/Input/IconButton';

export function Step5({ newNodeId }: { newNodeId: string | null }) {
  const [serviceProfileError, setServiceProfileError] = useState(null);
  const [plmnError, setPlmnError] = useState<false | string | null>(null);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { dialogClose } = useOverlay();

  const handleCreateServiceProfile = useCallback(async () => {
    if (newNodeId) {
      setLoading(true);

      const serviceProfileErrorRes = await dispatch(createSampleServiceProfile(newNodeId));
      setServiceProfileError(serviceProfileErrorRes || false);
      setLoading(false);
    }
  }, [dispatch, newNodeId]);

  const handleCreatePlmn = useCallback(async () => {
    if (newNodeId) {
      setLoading(true);

      const plmnErrorRes = await dispatch(createSamplePlmn(newNodeId));
      setPlmnError(plmnErrorRes || false);
      setLoading(false);
    }
  }, [dispatch, newNodeId]);

  useEffect(() => {
    async function handlePrepareNode() {
      await Promise.all([handleCreatePlmn(), handleCreateServiceProfile()]);
    }
    void handlePrepareNode();
  }, [handleCreatePlmn, handleCreateServiceProfile]);

  return (
    <>
      <IconButton
        name="Close"
        fontSize="small"
        onClick={dialogClose}
        sx={{ position: 'absolute', right: '16px', top: '8px', zIndex: 100 }}
      />
      <DialogContent>
        <Stack spacing={2} align="flex-end">
          {loading && (
            <Stack spacing={2} fullWidth align="center">
              <Progress type="circular" />
              <Text>{formatMessage({ id: 'nodes.create.preparingNode' })}</Text>
            </Stack>
          )}
          {plmnError === false ? (
            <Box sx={{ width: '100%' }}>
              <Alert severity="success" title={formatMessage({ id: 'plmns.sample.create.success.title' })} />
            </Box>
          ) : plmnError ? (
            <>
              <Alert
                severity="error"
                title={formatMessage({ id: 'common.error' })}
                message={formatMessage({ id: 'plmns.sample.create.error.title' }, { error: plmnError })}
              />
              <Button
                text={formatMessage({ id: 'common.form.retry' })}
                loading={loading}
                onClick={() => void handleCreatePlmn()}
              />
            </>
          ) : (
            <></>
          )}
          {serviceProfileError === false ? (
            <Box sx={{ width: '100%' }}>
              <Alert severity="success" title={formatMessage({ id: 'serviceProfiles.sample.create.success.title' })} />
            </Box>
          ) : serviceProfileError ? (
            <>
              <Alert
                severity="error"
                title={formatMessage({ id: 'common.error' })}
                message={formatMessage(
                  { id: 'serviceProfiles.sample.create.error.title' },
                  { error: serviceProfileError }
                )}
              />
              <Button
                text={formatMessage({ id: 'common.form.retry' })}
                loading={loading}
                onClick={() => void handleCreateServiceProfile()}
              />
            </>
          ) : (
            <></>
          )}
          {!plmnError && !serviceProfileError && (
            <Button variant="outlined" text={formatMessage({ id: 'common.form.close' })} onClick={dialogClose} />
          )}
        </Stack>
      </DialogContent>
    </>
  );
}
