import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Button } from '@athonet/ui/components/Input/Button';
import { ResponsiveControls } from '@athonet/ui/components/Navigation/ResponsiveControls';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Badge } from '@athonet/ui/components/Feedback/Badge';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { ColumnShape } from 'components/List';
import { FiltersObj, LegacyFiltersObj } from 'store/models/filters';

function ColumnsPopover<D>({
  columns,
  onColumnChange,
  visibleColumns,
}: Pick<ListToolbarProps<D>, 'columns' | 'onColumnChange' | 'visibleColumns'>) {
  const [visibleColumnsState, setVisibleColumnsState] = useState(visibleColumns);

  const handleColumnChange = useCallback(
    (event: any) => {
      const newVisibleColumns = new Map(visibleColumnsState);
      newVisibleColumns.set(event.target.name, event.target.checked);
      setVisibleColumnsState(newVisibleColumns);
      onColumnChange(newVisibleColumns);
    },
    [onColumnChange, visibleColumnsState]
  );

  return (
    <FormGroup row={false}>
      {columns.map(
        (item: any, index: number) =>
          !item.secret && (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  data-testid={`checkbox-${item.dataKey}`}
                  checked={visibleColumnsState.get(item.dataKey)}
                  onChange={handleColumnChange}
                  name={item.dataKey}
                  color="primary"
                />
              }
              label={item.title}
            />
          )
      )}
    </FormGroup>
  );
}

export type ListToolbarAction<D> = {
  label: string;
  onClick: (selectedRows: D[] | FiltersObj | LegacyFiltersObj) => void;
  disabled?: (selectedRows: D[] | FiltersObj | LegacyFiltersObj) => boolean;
};

export type ListToolbarProps<D> = {
  hide?: boolean;
  showActions?: boolean;
  showFilters?: boolean;
  filters: FiltersObj | LegacyFiltersObj;
  filtersComponent?: JSX.Element;
  loadingState?: number;
  showColumns?: boolean;
  columns: ColumnShape<D>[];
  onColumnChange: (visibleColumns: Map<keyof D, boolean>) => void;
  visibleColumns: Map<keyof D, boolean>;
  createComponent?: JSX.Element;
  extraComponents?: JSX.Element;
  selectedRows: D[];
  totalRows: number;
  selectable?: boolean;
  selectedByQuery: unknown;
  actions?: ListToolbarAction<D>[];
  downloadData: any;
  onRefresh?: () => Promise<void> | void;
};

export default function ListToolBar<D>({
  hide,
  showActions = true,
  showFilters = true,
  filters,
  filtersComponent,
  loadingState,
  showColumns = true,
  columns,
  onColumnChange,
  createComponent,
  extraComponents,
  selectedRows,
  totalRows,
  selectable,
  selectedByQuery,
  actions,
  downloadData,
  visibleColumns,
  onRefresh,
}: ListToolbarProps<D>) {
  const { formatMessage } = useIntl();
  const { popoverOpen, popoverClose, drawerOpen, menuOpen, menuClose } = useOverlay();

  const handleDownload = useCallback(() => {
    downloadData.onDownload(
      selectedRows.length === totalRows || selectedByQuery ? filters : selectedRows,
      downloadData.downloadItems
    );
  }, [downloadData, filters, selectedByQuery, selectedRows, totalRows]);

  const handleActionsPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!actions) {
        return;
      }
      menuOpen({
        items: actions.map((item) => ({
          label: item.label,
          value: item.label,
          ...(item.disabled && { disabled: item.disabled(selectedRows) }),
          onClick: () => {
            menuClose();
            item.onClick(selectedByQuery ? filters : selectedRows);
          },
        })),
        anchorEl: event.currentTarget,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    },
    [actions, filters, menuClose, menuOpen, selectedByQuery, selectedRows]
  );

  const handleColumnsPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      popoverOpen({
        content: () => (
          <ColumnsPopover onColumnChange={onColumnChange} columns={columns} visibleColumns={visibleColumns} />
        ),
        anchorEl: event.currentTarget,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    },
    [columns, onColumnChange, popoverOpen, visibleColumns]
  );

  const setFiltersCount = useMemo(() => {
    let activeFilters = 0;

    const checkItemSet = (filterItem: unknown) =>
      (!Array.isArray(filterItem) && filterItem !== '' && filterItem !== undefined && filterItem !== null) ||
      (Array.isArray(filterItem) && filterItem.length > 0);

    if (filters) {
      Object.keys(filters).forEach((item) => {
        const filterItem = filters[item];
        if (typeof filterItem === 'object' && 'value' in filterItem) {
          if (checkItemSet(filterItem.value)) {
            activeFilters++;
          }
        } else if (checkItemSet(filters[item])) {
          activeFilters++;
        }
      });
    }
    return activeFilters;
  }, [filters]);

  const handleFilterDrawer = useCallback(() => {
    if (!filtersComponent) {
      return;
    }

    drawerOpen({
      content: () => filtersComponent,
      title: 'Filters',
      width: 356,
    });
  }, [drawerOpen, filtersComponent]);

  const handleRefresh = useCallback(() => {
    if (onRefresh) {
      void onRefresh();
    }
  }, [onRefresh]);

  useEffect(() => {
    if (loadingState && loadingState < 1) {
      popoverClose();
    }
  }, [loadingState, popoverClose]);

  return !hide ? (
    <Toolbar>
      <Stack fullWidth direction="row" justify="flex-end" sx={{ mb: 2 }}>
        <ResponsiveControls breakpoint="sm">
          {onRefresh ? (
            <Button
              variant="outlined"
              onClick={handleRefresh}
              text={'Refresh'}
              startIcon={'Refresh'}
              data-testid="toolbar-refresh-button"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            />
          ) : null}
          {extraComponents || null}
          {showActions ? (
            <Button
              variant="outlined"
              onClick={handleActionsPopover} // TODO: open up checkbox popover
              text={formatMessage({ id: 'listToolbar.actions' })}
              startIcon={'Click-2'}
              data-testid="toolbar-actions-button"
              disabled={!selectedByQuery && selectedRows.length === 0}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            />
          ) : null}
          {downloadData ? (
            <Button
              variant="outlined"
              onClick={handleDownload}
              text={formatMessage({ id: 'listToolbar.download' })}
              startIcon={'Download-Cloud'}
              data-testid="toolbar-download-button"
              disabled={!selectedByQuery && selectedRows.length === 0 && selectable}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            />
          ) : null}
          {showColumns ? (
            <Button
              variant="outlined"
              onClick={handleColumnsPopover}
              text={formatMessage({ id: 'listToolbar.columns' })}
              startIcon={'Isert-Tabel'}
              data-testid="toolbar-columns-button"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            />
          ) : null}
          {showFilters && filtersComponent ? (
            <Badge badgeContent={setFiltersCount} data-testid="cdr-toolbar-filter-dot">
              <Button
                variant="outlined"
                onClick={handleFilterDrawer}
                text={formatMessage({ id: 'listToolbar.filters' })}
                startIcon={'Adjustments'}
                data-testid="toolbar-filters-button"
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              />
            </Badge>
          ) : null}
          {createComponent || null}
        </ResponsiveControls>
      </Stack>
    </Toolbar>
  ) : null;
}
