import config from 'config';
import { Role } from 'store/models/role';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { GetResultsRes } from 'store/models/filters';
import { showErrorToast, showSuccessToast } from './toast';
import { AxiosRequestConfig } from 'axios';
import { sentryLogError } from 'sentry';

export enum ROLES_ACTION_TYPE {
  LIST_LOADING = 'ROLES_LIST_LOADING',
  LIST_SUCCESS = 'ROLES_LIST_SUCCESS',
  LIST_FAILURE = 'ROLES_LIST_FAILURE',
}

export type RolesAction =
  | {
      type: ROLES_ACTION_TYPE.LIST_LOADING;
    }
  | {
      type: ROLES_ACTION_TYPE.LIST_SUCCESS;
      payload: Role[];
    }
  | {
      type: ROLES_ACTION_TYPE.LIST_FAILURE;
    };

export function getRoles(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: ROLES_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const result = await dispatch(fetchData<GetResultsRes<Role>>({ url: config.apis.getRoles, method: 'GET' }));
      dispatch({
        type: ROLES_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: ROLES_ACTION_TYPE.LIST_FAILURE,
      });
      sentryLogError(e);
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    }
  };
}

export function getPermissions(): Thunk<Promise<string[]>> {
  return async (dispatch) => {
    try {
      const permissions = await dispatch(fetchData<string[]>({ url: config.apis.getPermissions, method: 'GET' }));

      return permissions;
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
      return [];
    }
  };
}

export function deleteRole(roleId: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.deleteRole.replace('{id}', roleId),
        method: 'DELETE',
      };
      await dispatch(fetchData(options));
      await dispatch(getRoles());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

type EditRolePayload = { data: { role: string; permissions: Role['permissions'] }; roleId: string };
export function editRole({ data, roleId }: EditRolePayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.updateRole.replace('{id}', roleId),
        method: 'PUT',
        data,
      };
      await dispatch(fetchData(options));
      await dispatch(getRoles());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function createRole(data: { role: string; permissions: Role['permissions'] }): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.createRole,
        method: 'POST',
        data,
      };
      await dispatch(fetchData(options));
      await dispatch(getRoles());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}
