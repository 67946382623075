import { NodeReference } from './node';
import { Owner } from './tenant';

export enum PROFILE_STATUS {
  IN_STOCK = 'in_stock',
  READY = 'ready',
  UPDATING = 'updating',
  ERROR = 'error',
}

export type QosTemplate = {
  id: string;
  name: string;
};

/* NOTE: CC-1285 */
export type Segment = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  owners: Owner[];
  nodes: NodeReference[];
  status: PROFILE_STATUS;
};

export type SegmentProfile = Segment & {
  display_name: string;
  network_elements: Record<string, unknown>[]; // TODO update once HSS; TimHSS and EdgeNode models are created
  apn: string;
  charging_characteristics: null;
  non_ip_template_id: string | null;
  pdp_type: number;
  pgw_allocation_profile: string | null;
  qos_template_4g: QosTemplate;
};

export function getPdpTypeText(pdp_type: number | string) {
  switch (pdp_type) {
    case 1:
      return 'IPv4';
    case 2:
      return 'IPv6';
    case 3:
      return 'IPv4v6';
    default:
      return 'Unknown (' + pdp_type + ')';
  }
}
