import {
  getCdrImeiChangesSchema,
  getCdrImeiChangesTotal,
  getCdrImeiChangesTrendSeries,
} from 'store/actions/cdrReports/imeiChanges';
import {
  useCdrImeiChangesTotal,
  useCdrImeiChangesTrendSeries,
  useImeiChangesDateRange,
} from 'store/selectors/cdrReports/imeiChanges';
import { getTotalFileSize } from 'containers/Reports/Cdr/utils/utils';
import {
  useCdrTrafficVolumeDateRange,
  useCdrTrafficVolumeTotal,
  useCdrTrafficVolumeTrendSeries,
} from 'store/selectors/cdrReports/trafficVolume';
import {
  getCdrTrafficVolumeSchema,
  getCdrTrafficVolumeTotal,
  getCdrTrafficVolumeTrendSeries,
} from 'store/actions/cdrReports/trafficVolume';
import {
  getCdrActiveSourceNetworkAggregation,
  useCdrActiveSourceNetwork,
} from 'store/selectors/cdrReports/sourceNetwork';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCdrTrafficDifferenceSchema,
  getCdrTrafficDifferenceTotals,
  getCdrTrafficDifferenceTrendSeries,
} from 'store/actions/cdrReports/trafficDifference';
import { CDR_SOURCE_NETWORKS_AGGREGATION_TYPE } from 'store/models/cdr';
import {
  useCdrTrafficDifferenceTotals,
  useTrafficDifferenceTotalByType,
  useTrafficDifferenceSeriesByType,
  useCdrTrafficDifferenceSeries,
  useCdrTrafficDifferenceDateRange,
  useCdrTrafficDifferenceSourceNetworksAggregations,
} from 'store/selectors/cdrReports/trafficDifference';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Chart } from '@athonet/ui/components/Data/Chart';
import theme from '@athonet/ui/theme';
import { isEntityLoading } from 'store/reducers';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Divider } from '@athonet/ui/components/Layout/Divider';

export default function TrafficWidgets() {
  const bootstrap = useBootstrapSelector();
  const trafficVolumeSeries = useCdrTrafficVolumeTrendSeries();
  const activeSourceNetwork = useCdrActiveSourceNetwork();
  const dispatch = useDispatch();
  const trafficVolumeTotal = useCdrTrafficVolumeTotal();
  const trafficDifferenceTotals = useCdrTrafficDifferenceTotals();
  const trafficDifferenceTotalsDIFFERENCE = useTrafficDifferenceTotalByType(
    CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.DIFFERENCE
  );
  const trafficDifferenceSeriesDIFFERENCE = useTrafficDifferenceSeriesByType(
    CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.DIFFERENCE
  );
  const imeiChangesSeries = useCdrImeiChangesTrendSeries();
  const imeiChangesTotal = useCdrImeiChangesTotal();
  const trafficDifferenceSeries = useCdrTrafficDifferenceSeries();
  const trafficVolumeDateRange = useCdrTrafficVolumeDateRange();
  const trafficDifferenceDateRange = useCdrTrafficDifferenceDateRange();
  const imeiChangesDateRange = useImeiChangesDateRange();
  const cdrTrafficDifferenceSourceNetworksAggregations = useCdrTrafficDifferenceSourceNetworksAggregations();

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (bootstrap?.traffic_service) {
      dispatch(getCdrTrafficVolumeSchema());
      dispatch(getCdrImeiChangesSchema());
    }
    if (bootstrap?.traffic_difference_service) {
      dispatch(getCdrTrafficDifferenceSchema());
    }
  }, [bootstrap, dispatch]);

  useEffect(() => {
    if (
      !trafficVolumeDateRange ||
      !trafficVolumeDateRange.start ||
      !trafficVolumeDateRange.end ||
      !bootstrap?.traffic_service ||
      !activeSourceNetwork
    ) {
      return;
    }
    dispatch(
      getCdrTrafficVolumeTrendSeries({
        start_date: trafficVolumeDateRange.start,
        end_date: trafficVolumeDateRange.end,
        period: 'day',
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
    dispatch(
      getCdrTrafficVolumeTotal({
        start_date: trafficVolumeDateRange.start,
        end_date: trafficVolumeDateRange.end,
        period: 'day',
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [activeSourceNetwork, bootstrap?.traffic_service, dispatch, trafficVolumeDateRange]);

  useEffect(() => {
    if (
      !trafficDifferenceDateRange ||
      !trafficDifferenceDateRange.start ||
      !trafficDifferenceDateRange.end ||
      !bootstrap?.traffic_difference_service ||
      !cdrTrafficDifferenceSourceNetworksAggregations
    ) {
      return;
    }
    dispatch(
      getCdrTrafficDifferenceTotals({
        start_date: trafficDifferenceDateRange.start,
        end_date: trafficDifferenceDateRange.end,
        period: 'day',
        sourceNetworkAggregations: cdrTrafficDifferenceSourceNetworksAggregations,
      })
    );
    dispatch(
      getCdrTrafficDifferenceTrendSeries({
        start_date: trafficDifferenceDateRange.start,
        end_date: trafficDifferenceDateRange.end,
        period: 'day',
        sourceNetworkAggregations: cdrTrafficDifferenceSourceNetworksAggregations,
      })
    );
  }, [
    bootstrap?.traffic_difference_service,
    cdrTrafficDifferenceSourceNetworksAggregations,
    dispatch,
    trafficDifferenceDateRange,
  ]);

  useEffect(() => {
    if (
      !imeiChangesDateRange ||
      !imeiChangesDateRange.start ||
      !imeiChangesDateRange.end ||
      !bootstrap?.traffic_service ||
      !activeSourceNetwork
    ) {
      return;
    }
    dispatch(
      getCdrImeiChangesTotal({
        start_date: imeiChangesDateRange.start,
        end_date: imeiChangesDateRange.end,
        period: 'day',
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
    dispatch(
      getCdrImeiChangesTrendSeries({
        start_date: imeiChangesDateRange.start,
        end_date: imeiChangesDateRange.end,
        period: 'day',
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [activeSourceNetwork, bootstrap?.traffic_service, dispatch, imeiChangesDateRange]);

  const trafficVolumeSeriesTotalLink = useMemo(() => {
    if (!trafficVolumeSeries.data) {
      return;
    }

    return trafficVolumeSeries.data.map((item) => {
      const datetime = item.datetime.toString();
      return [datetime, item.totallink !== null ? item.totallink : '-'];
    });
  }, [trafficVolumeSeries]);

  const trafficDifferenceSeriesTotalLink = useMemo(() => {
    if (!trafficDifferenceSeriesDIFFERENCE) {
      return;
    }

    return trafficDifferenceSeriesDIFFERENCE.series.map((item) => {
      const datetime = item.datetime.toString();
      return [datetime, item.totallink !== null ? item.totallink : '-'];
    }, []);
  }, [trafficDifferenceSeriesDIFFERENCE]);

  const imeiChangesTrendSeries = useMemo(() => {
    if (!imeiChangesSeries.data) {
      return;
    }
    const series: [string, number | string][] = [];
    imeiChangesSeries.data.forEach((item) => {
      const dateTime = item.datetime.toString();
      series.push([dateTime, item.imei_changes !== null ? item.imei_changes : '-']);
    });

    return series;
  }, [imeiChangesSeries]);

  const totalFileSize = useMemo(() => {
    if (!trafficVolumeTotal.data) {
      return;
    }
    return getTotalFileSize(trafficVolumeTotal.data);
  }, [trafficVolumeTotal]);

  const differenceFileSize = useMemo(() => {
    if (!trafficDifferenceTotalsDIFFERENCE) {
      return;
    }
    return getTotalFileSize(trafficDifferenceTotalsDIFFERENCE.total);
  }, [trafficDifferenceTotalsDIFFERENCE]);

  return (
    <>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <Text type="h5" fontWeight={600} sx={{ mb: 1 }}>
          {formatMessage({ id: 'landing.panel.title.networkKPI' })}
        </Text>
        <Divider color="secondary" />
      </GridItem>
      {bootstrap?.traffic_service && (
        <GridItem
          size={{
            xs: 12,
            sm: 6,
            md: 4,
          }}
          zeroMinWidth
          sx={{ display: 'flex', alignContent: 'stretch' }}
        >
          <Panel>
            <PanelContent>
              <Stack spacing={2}>
                {!isEntityLoading(trafficVolumeTotal) && !isEntityLoading(trafficVolumeSeries) ? (
                  <>
                    <SquaredTile
                      onClick={() => navigate('/reports/traffic-volume/trend')}
                      data-testid="landing-cdr-traffic-volume-kpi"
                    >
                      <SquaredChildTile
                        mainValue={(totalFileSize?.totallink && totalFileSize.totallink[0].toString()) ?? ''}
                        mainValueUnit={totalFileSize?.totallink && totalFileSize.totallink[1]}
                        variant="medium"
                        title={formatMessage({ id: 'landing.panel.tile.totalTraffic' })}
                        subtitle={`${trafficVolumeDateRange?.start?.format(
                          'MMM DD, YYYY'
                        )} / ${trafficVolumeDateRange?.end?.format('MMM DD, YYYY')}`}
                        titleColor="primary"
                      />
                    </SquaredTile>
                    <Chart
                      height={32}
                      options={{
                        grid: {
                          left: 0,
                          right: 0,
                          top: 24,
                          bottom: 0,
                          show: false,
                        },
                        series: {
                          type: 'line',
                          name: 'name',
                          data: trafficVolumeSeriesTotalLink,
                          color: theme.palette.primary.main,
                          symbol: 'none',
                        },

                        tooltip: {
                          show: false,
                        },
                        yAxis: {
                          splitLine: {
                            show: false,
                          },
                          min: Math.min(
                            ...(trafficVolumeSeriesTotalLink?.map(([, value]) =>
                              typeof value === 'string' ? parseInt(value) : value
                            ) || [])
                          ),
                          type: 'value',
                          axisLabel: {
                            show: false,
                          },
                        },
                        xAxis: {
                          type: 'category',
                          show: false,
                        },
                        legend: {
                          show: false,
                        },
                      }}
                      data-testid="landing-traffic-volume-sparkline"
                    />
                  </>
                ) : (
                  <Skeleton height={145} variant="rectangular" />
                )}
              </Stack>
            </PanelContent>
          </Panel>
        </GridItem>
      )}
      {bootstrap?.traffic_difference_service && (
        <GridItem
          size={{
            xs: 12,
            sm: 6,
            md: 4,
          }}
          zeroMinWidth
          sx={{ display: 'flex', alignContent: 'stretch' }}
        >
          <Panel>
            <PanelContent>
              <Stack spacing={2}>
                {!isEntityLoading(trafficDifferenceTotals) && !isEntityLoading(trafficDifferenceSeries) ? (
                  <>
                    <SquaredTile
                      onClick={() => navigate('/reports/traffic-difference/trend')}
                      data-testid="landing-cdr-traffic-difference-kpi"
                    >
                      <SquaredChildTile
                        mainValue={(differenceFileSize && differenceFileSize.totallink[0].toString()) ?? ''}
                        mainValueUnit={differenceFileSize && differenceFileSize.totallink[1]}
                        variant="medium"
                        title={formatMessage({ id: 'landing.panel.tile.trafficDifference' })}
                        subtitle={`${trafficDifferenceDateRange?.start?.format(
                          'MMM DD, YYYY'
                        )} / ${trafficDifferenceDateRange?.end?.format('MMM DD, YYYY')}`}
                        titleColor="series6"
                      />
                    </SquaredTile>
                    <Chart
                      height={32}
                      options={{
                        grid: {
                          left: 0,
                          right: 0,
                          top: 24,
                          bottom: 0,
                          show: false,
                        },
                        series: {
                          type: 'line',
                          name: 'name',
                          data: trafficDifferenceSeriesTotalLink,
                          color: theme.palette.series6.main,
                          symbol: 'none',
                        },
                        tooltip: {
                          show: false,
                        },
                        yAxis: {
                          splitLine: {
                            show: false,
                          },
                          min: Math.min(
                            ...(trafficDifferenceSeriesTotalLink?.map(([, value]) =>
                              typeof value === 'string' ? parseInt(value) : value
                            ) || [])
                          ),
                          type: 'value',
                          axisLabel: {
                            show: false,
                          },
                        },
                        xAxis: {
                          type: 'category',
                          show: false,
                        },
                        legend: {
                          show: false,
                        },
                      }}
                      data-testid="landing-traffic-difference-sparkline"
                    />
                  </>
                ) : (
                  <Skeleton height={145} variant="rectangular" />
                )}
              </Stack>
            </PanelContent>
          </Panel>
        </GridItem>
      )}
      {bootstrap?.traffic_service && (
        <GridItem
          size={{
            xs: 12,
            md: 4,
          }}
          zeroMinWidth
          sx={{ display: 'flex', alignContent: 'stretch' }}
        >
          <Panel>
            <PanelContent>
              <Stack spacing={2}>
                {!isEntityLoading(imeiChangesTotal) && !isEntityLoading(imeiChangesSeries) ? (
                  <>
                    <SquaredTile
                      onClick={() => navigate('/reports/imei-changes/trend')}
                      data-testid="landing-cdr-imei-changes-kpi"
                    >
                      <SquaredChildTile
                        mainValue={imeiChangesTotal.data?.imei_changes?.toLocaleString('en') || 'No Changes'}
                        variant="medium"
                        title={formatMessage({ id: 'landing.panel.tile.imeiChanges' })}
                        subtitle={`${imeiChangesDateRange?.start?.format(
                          'MMM DD, YYYY'
                        )} / ${imeiChangesDateRange?.end?.format('MMM DD, YYYY')}`}
                        titleColor="primary"
                      />
                    </SquaredTile>
                    <Chart
                      height={32}
                      options={{
                        grid: {
                          left: 0,
                          right: 0,
                          top: 24,
                          bottom: 0,
                          show: false,
                        },
                        series: {
                          type: 'line',
                          name: 'name',
                          data: imeiChangesTrendSeries,
                          color: theme.palette.primary.main,
                          symbol: 'none',
                        },
                        tooltip: {
                          show: false,
                        },
                        yAxis: {
                          splitLine: {
                            show: false,
                          },
                          min: Math.min(
                            ...(imeiChangesTrendSeries?.map(([, value]) =>
                              typeof value === 'string' ? parseInt(value) : value
                            ) || [])
                          ),
                          type: 'value',
                          axisLabel: {
                            show: false,
                          },
                        },
                        xAxis: {
                          type: 'category',
                          show: false,
                        },
                        legend: {
                          show: false,
                        },
                      }}
                      data-testid="landing-imei-changes-sparkline"
                    />
                  </>
                ) : (
                  <Skeleton height={145} variant="rectangular" />
                )}
              </Stack>
            </PanelContent>
          </Panel>
        </GridItem>
      )}
    </>
  );
}
