import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { CalculatedBills } from 'store/models/billing';
import { useMemo } from 'react';
import { useCalculatedBills } from 'store/selectors/billing';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useIntl } from 'react-intl';
import { isEntityLoading } from 'store/reducers';

export default function CalculatedBillsTable() {
  const calculatedBills = useCalculatedBills();
  const { formatMessage } = useIntl();

  const calculatedBillsColumns = useMemo((): TableColumn<CalculatedBills>[] => {
    return [
      {
        key: 'operator_name',
        label: formatMessage({ id: 'calculatedBills.table.operator_name' }),
      },
      {
        key: 'cost',
        label: formatMessage({ id: 'calculatedBills.table.cost' }),
        numeric: true,
        cellRender: ({ cost }) => <Text type="body2">{cost ? `€${Number(cost).toFixed(2)}` : ''}</Text>,
      },
      {
        key: 'sims',
        label: formatMessage({ id: 'calculatedBills.table.sims' }),
        numeric: true,
      },
    ];
  }, [formatMessage]);

  return (
    <Table
      data={calculatedBills.data || []}
      columns={calculatedBillsColumns}
      rowsCount={calculatedBills.data?.length || 0}
      rowsPerPage={calculatedBills.data?.length || 0}
      orderBy={'date'}
      rowKey={'id'}
      page={0}
      loading={isEntityLoading(calculatedBills)}
      data-testid="calculatedBills-table"
    />
  );
}
