// App Components
import SiteCard from 'components/SiteCard';
import Masonry from 'components/Masonry';

// Style
import { DatagridStyled } from './styled';

const Datagrid = ({ data, getTools, createComponent }) => {
  return (
    <DatagridStyled>
      <div className="site-grid-toolbar">{createComponent}</div>
      <Masonry xs={1} lg={2} xl={3}>
        {Array.isArray(data) && data.map((item, index) => <SiteCard key={index} data={item} getTools={getTools} />)}
      </Masonry>
    </DatagridStyled>
  );
};

export default Datagrid;
