import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { reset, getGpgKeys, deleteGpgKey } from 'store/actions/gpgKeys';
import moment from 'moment';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/GpgKeys';
import List from 'components/List';
import DropdownTools from 'components/DropdownTools';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import config from 'config';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useGpgKeysListSelector, useGpgKeysFiltersSelector } from 'store/selectors/gpgKeys';
import { useUserSelector } from 'store/selectors/user';
import { GpgKey } from 'store/models/gpgKey';
import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import CreateGpgKeyButton from 'components/Edit/GpgKeys/CreateGpgKeyButton';

export default function GpgKeys() {
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const bootstrap = useBootstrapSelector();
  const gpgKeysList = useGpgKeysListSelector();
  const gpgKeysFilters = useGpgKeysFiltersSelector();
  const user = useUserSelector();
  const { confirmationDialogOpen } = useOverlay();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page) => {
      dispatch(getGpgKeys({ page }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getGpgKeys({}));
  }, [bootstrap?.pageLimit, dispatch]);

  const handleDeleteGpgKey = useCallback(
    async (id) => {
      try {
        await dispatch(deleteGpgKey(id));
        dispatch(showSuccessToast());
      } catch (e) {
        dispatch(showErrorToast());
      }
    },
    [dispatch]
  );

  const openDelete = useCallback(
    ({ id, name }) => {
      confirmationDialogOpen({
        title: formatMessage({ id: 'usims.gpgKeys.delete.confirm.title' }),
        description: formatMessage({ id: 'usims.gpgkeys.delete.confirm.description' }),
        alertMessage: formatMessage({ id: 'usims.gpgKeys.itemsAffected' }, { element: name }),
        continueButtonText: formatMessage({ id: 'usims.gpgKeys.delete.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => handleDeleteGpgKey(id),
        severity: 'danger',
        dataTestid: 'confirm-delete-gpgKey',
      });
    },
    [confirmationDialogOpen, formatMessage, handleDeleteGpgKey]
  );

  const getTools = useCallback(
    (rowData) => {
      const options = checkPermissionsList(
        user?.permissions || [],
        [
          // TODO: domenico: implement EDIT form dialog
          // {
          //   icon: ico_edit,
          //   label: formatMessage({ id: 'usims.gpgkeys.edit' }),
          //   action: () => openEdit(rowData),
          //   disabled: false,
          // },
          {
            icon: ico_delete,
            name: 'delete-gpgKey',
            label: formatMessage({ id: 'usims.gpgkeys.delete' }),
            action: () => openDelete(rowData),
            disabled: false,
          },
        ],
        false
      );

      return <DropdownTools options={options} />;
    },
    [formatMessage, openDelete, user?.permissions]
  );

  const columns = useMemo(
    () =>
      checkPermissionsList(
        [user?.tenant_type],
        [
          {
            key: 'id',
            title: formatMessage({ id: 'usims.gpgkeys.table.id' }),
            dataKey: 'id',
            width: 150,
            maxWidth: 150,
            minWidth: 150,
            hidden: true,
            secret: true, // secret used to hide from columns management panel
          },
          {
            key: 'name',
            title: formatMessage({ id: 'usims.gpgkeys.table.name' }),
            dataKey: 'name',
            sortable: true,
            width: 100,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            minWidth: 100,
          },
          {
            key: 'private',
            title: formatMessage({ id: 'usims.gpgkeys.table.type' }),
            dataKey: 'private',
            sortable: true,
            width: 100,
            maxWidth: 100,
            minWidth: 100,
            cellRenderer: ({ cellData: v }: { cellData: string }) => {
              return v
                ? formatMessage({ id: 'usims.form.gpgkeys.private' })
                : formatMessage({ id: 'usims.form.gpgkeys.public' });
            },
          },
          {
            key: 'filename',
            title: formatMessage({ id: 'usims.gpgkeys.table.filename' }),
            dataKey: 'filename',
            sortable: true,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            width: 150,
            minWidth: 150,
          },
          {
            key: 'passphrase',
            title: formatMessage({ id: 'usims.gpgkeys.table.passphrase' }),
            dataKey: 'passphrase',
            sortable: true,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            width: 150,
            maxWidth: 200,
            minWidth: 90,
            cellRenderer: ({ cellData: value }: { cellData: string }) => {
              return <Status label={value ? 'Set' : 'Not Set'} status={value ? STATUS.SUCCESS : STATUS.ERROR} />;
            },
          },
          {
            key: 'tenant.name',
            title: formatMessage({ id: 'usims.gpgkeys.table.tenantName' }),
            dataKey: 'tenant.name',
            sortable: false,
            width: 150,
            maxWidth: 200,
            minWidth: 90,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
            visible: false,
          },
          {
            key: 'created_at',
            title: formatMessage({ id: 'usims.gpgkeys.table.created' }),
            dataKey: 'created_at',
            sortable: true,
            width: 140,
            maxWidth: 140,
            minWidth: 50,
            visible: false,
            defaultSort: 'desc', // set the columns sorted as default
            cellRenderer: ({ cellData }: { cellData: string }) => {
              return moment(cellData).format(localizedConfig.fullDateFormat);
            },
          },
          {
            key: 'updated_at',
            title: formatMessage({ id: 'usims.gpgkeys.table.updated' }),
            dataKey: 'updated_at',
            sortable: true,
            width: 140,
            maxWidth: 140,
            minWidth: 50,
            visible: false,
            cellRenderer: ({ rowData }: { rowData: GpgKey }) => {
              return rowData.created_at !== rowData.updated_at
                ? moment(rowData.updated_at).format(localizedConfig.fullDateFormat)
                : '-';
            },
          },
          {
            key: 'tools',
            title: '',
            dataKey: 'tools',
            width: 60,
            maxWidth: 60,
            minWidth: 60,
            secret: true, // secret used to hide from columns management panel
            cellRenderer: ({ rowData }: { rowData: GpgKey }) => {
              return getTools(rowData);
            },
          },
        ]
      ),
    [formatMessage, getTools, localizedConfig.fullDateFormat, user]
  );

  const handleSortChange = useCallback(
    (orderQuery) => {
      dispatch(getGpgKeys({ sortBy: orderQuery }));
    },
    [dispatch]
  );

  const handleRefresh = useCallback(() => {
    dispatch(getGpgKeys({}));
  }, [dispatch]);

  return (
    <List
      columns={columns}
      totalRows={gpgKeysList.data.total}
      data={gpgKeysList.data.data}
      page={gpgKeysList.data.page}
      filters={gpgKeysFilters}
      onOrderChange={handleSortChange}
      loadingState={gpgKeysList.state}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      toolbar={{ actions: false }}
      rowsPerPage={bootstrap?.pageLimit}
      onRefresh={handleRefresh}
      {...(checkPermissionToUse(user, U_PERMISSIONS.CREATE_TENANT) && {
        createComponent: <CreateGpgKeyButton />,
      })}
    />
  );
}
