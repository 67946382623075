import { useEffect, useRef } from 'react';

const useUnload = (fn: any) => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', onUnload, { capture: true });
    };
  }, [cb]);
};

export default useUnload;
