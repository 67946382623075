import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { TimHSSNetworkElement } from 'store/models/node';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { useCallback, useState } from 'react';
import { useCanUserUpdateNodeSelector, useUserSelector } from 'store/selectors/user';
import Edit from 'components/Edit/TimHss/Edit';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { checkPermissionsList, U_PERMISSIONS } from 'utils/permissionCodes';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteTimHss } from 'store/actions/nodes';

export function TimHssSection({ timHss }: { timHss: TimHSSNetworkElement }) {
  const [isEditTimHssOpen, setIsEditTimHssOpen] = useState(false);
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const { menuOpen, menuClose, confirmationSimpleDialogOpen } = useOverlay();
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const dispatch = useDispatch();

  const closeEdit = useCallback(() => {
    setIsEditTimHssOpen(false);
  }, []);

  const openDeleteTimHss = useCallback(() => {
    confirmationSimpleDialogOpen({
      title: formatMessage({ id: 'timHss.deleteTimHss' }),
      description: formatMessage({ id: 'timHss.deleteTimHss.confirm' }),
      onConfirm: async () => {
        await dispatch(deleteTimHss(timHss.id));
      },
    });
  }, [confirmationSimpleDialogOpen, formatMessage, dispatch, timHss]);

  const openActionsMenu = useCallback(
    (e) => {
      menuOpen({
        anchorEl: e.currentTarget,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
        items: checkPermissionsList(
          user?.permissions || [],
          [
            {
              label: formatMessage({ id: 'timHss.editTimHss' }),
              disabled: false,
              permissions: [U_PERMISSIONS.UPDATE_NETWORK_ELEMENT],
              value: 'edit-tim-hss',
              onClick: () => {
                setIsEditTimHssOpen(true);
                menuClose();
              },
            },
            {
              label: formatMessage({ id: 'timHss.deleteTimHss' }),
              disabled: false,
              permissions: [U_PERMISSIONS.DELETE_NETWORK_ELEMENT],
              value: 'delete-tim-hss',
              onClick: () => {
                openDeleteTimHss();
                menuClose();
              },
            },
          ],
          false
        ),
      });
    },
    [formatMessage, menuClose, menuOpen, openDeleteTimHss, user]
  );

  return (
    <>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <Panel
          title={formatMessage({ id: 'timHss.timHss' })}
          headerActionsComponent={
            <IconButton
              name="Other"
              onClick={openActionsMenu}
              fontSize="small"
              sx={{ transform: 'rotate(90deg)' }}
              disabled={!canUserUpdateNode}
            />
          }
        >
          <PanelContent>
            <GridContainer>
              <KeyValueGridItem label={formatMessage({ id: 'timHss.name' })} value={timHss.name} />
              <KeyValueGridItem label={formatMessage({ id: 'timHss.url' })} value={timHss.payload.url} />
              <KeyValueGridItem
                label={formatMessage({ id: 'timHss.clientId' })}
                value={timHss.payload.client_id}
                fullWidth
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'timHss.consumerKey' })}
                valueComponent={
                  <Status
                    label={timHss.payload.consumer_key ? 'Set' : 'Not Set'}
                    status={timHss.payload.consumer_key ? STATUS.SUCCESS : STATUS.DEFAULT}
                  />
                }
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'timHss.consumerSecret' })}
                valueComponent={
                  <Status
                    label={timHss.payload.consumer_secret ? 'Set' : 'Not Set'}
                    status={
                      <Status
                        label={timHss.payload.consumer_secret ? 'Set' : 'Not Set'}
                        status={timHss.payload.consumer_secret ? STATUS.SUCCESS : STATUS.DEFAULT}
                      /> ? (
                        STATUS.SUCCESS
                      ) : (
                        STATUS.DEFAULT
                      )
                    }
                  />
                }
              />
              <KeyValueGridItem label={formatMessage({ id: 'timHss.username' })} value={timHss.payload.username} />
              <KeyValueGridItem
                label={formatMessage({ id: 'timHss.password' })}
                valueComponent={
                  <Status
                    label={timHss.payload.password ? 'Set' : 'Not Set'}
                    status={timHss.payload.password ? STATUS.SUCCESS : STATUS.DEFAULT}
                  />
                }
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'timHss.certificate' })}
                value={timHss.payload.certificate}
              />
            </GridContainer>
          </PanelContent>
        </Panel>
      </GridItem>
      {isEditTimHssOpen && <Edit data={timHss} isOpen={isEditTimHssOpen} onClose={closeEdit} />}
    </>
  );
}
