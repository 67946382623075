import { AxiosRequestConfig } from 'axios';
import config from 'config';
import moment from 'moment';
import {
  CdrAvailabilityDates,
  CdrDateRange,
  CdrFilter,
  CdrPeriod,
  CdrRankingCalendarDate,
  CdrRankingGroupBy,
  CdrSchema,
  CdrSearch,
  CdrTrafficDifferenceSeries,
  CdrTrafficDifferenceTotal,
  CdrTrafficVolumeMetricsField,
} from 'store/models/cdr';
import { getCdrTrafficVolumeRankingMetricOptions } from 'store/selectors/cdrReports/trafficVolume';
import {
  CdrAggregationPayload,
  CdrTotalPayload,
  CdrSeriesPayload,
  CDR_ACTION_TYPE,
  GetCdrSeriesActionArgs,
  GetCdrSearchAction,
  CdrRankingPayload,
} from '.';
import { Thunk } from '..';
import { fetchData } from '../fetchData';
import { showErrorToast } from '../toast';
import { getCdrTrafficRanking, getCdrTrafficSeries } from './trafficVolume';
import { sentryLogError } from 'sentry';

function getOptionsList({
  payload,
  url,
}: {
  payload: CdrAggregationPayload<CdrTotalPayload> | CdrAggregationPayload<CdrSeriesPayload>;
  url: string;
}) {
  const { sourceNetworkAggregations, ...payloadOptions } = payload;

  const optionsList: AxiosRequestConfig[] = sourceNetworkAggregations.map((aggregation) => ({
    url,
    method: 'POST',
    data: {
      ...payloadOptions,
      start_date: payload.start_date.format('YYYY-MM-DD'),
      end_date: payload.end_date.format('YYYY-MM-DD'),
      source_networks_aggregation: aggregation.source_networks_aggregation,
    },
  }));
  return optionsList;
}

export function getCdrTrafficDifferenceTotals(payload: CdrAggregationPayload<CdrTotalPayload>): Thunk<Promise<void>> {
  return async (dispatch) => {
    const { sourceNetworkAggregations } = payload;

    const optionsList = getOptionsList({ payload, url: config.cdrReportsApis.trafficVolume.total });

    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_LOADING });

      const trafficDifferenceTotals = await Promise.all(
        optionsList.map(async (options) => dispatch(fetchData<CdrTrafficDifferenceTotal>(options)))
      );

      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_SUCCESS,
        payload: sourceNetworkAggregations.map((aggregation, i) => ({
          type: aggregation.type,
          total: trafficDifferenceTotals[i],
        })),
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_FAILURE });
    }
  };
}

function getCdrTrafficDifferenceSeries({
  payload,
  actions: { loadingAction, successAction, failureAction },
}: GetCdrSeriesActionArgs<CdrAggregationPayload<CdrSeriesPayload>>): Thunk<Promise<void>> {
  return async (dispatch) => {
    const { sourceNetworkAggregations } = payload;

    const optionsList = getOptionsList({ payload, url: config.cdrReportsApis.trafficVolume.series });

    try {
      dispatch({ type: loadingAction });
      const trafficDifferenceSeries = await Promise.all(
        optionsList.map(async (options) => dispatch(fetchData<CdrTrafficDifferenceSeries>(options)))
      );
      dispatch({
        type: successAction,
        payload: sourceNetworkAggregations.map((aggregation, i) => ({
          type: aggregation.type,
          series: trafficDifferenceSeries[i],
        })),
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: failureAction });
    }
  };
}

export function getCdrTrafficDifferenceTrendSeries(payload: CdrAggregationPayload<CdrSeriesPayload>) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_LOADING,
    successAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_SUCCESS,
    failureAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_FAILURE,
  };

  return getCdrTrafficDifferenceSeries({ payload, actions });
}

export function getCdrTrafficDifferenceRankingSeries(payload: CdrSeriesPayload) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_LOADING,
    successAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_SUCCESS,
    failureAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_FAILURE,
  };

  return getCdrTrafficSeries({ payload, actions });
}

export function getCdrTrafficDifferenceRanking(payload: CdrRankingPayload) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_LOADING,
    successAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SUCCESS,
    failureAction: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_FAILURE,
  };

  return getCdrTrafficRanking({ payload, actions });
}

export function getCdrTrafficDifferenceAvailabilityDates(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.availability_dates,
      method: 'POST',
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_LOADING });
      const trafficAvailabilityDates = await dispatch(fetchData<CdrAvailabilityDates>(options));

      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_SUCCESS,
        payload: trafficAvailabilityDates,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_FAILURE });
    }
  };
}

export function getCdrTrafficDifferenceSchema(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      cdrReportsTrafficDifference: {
        trafficDifferenceRankingGroupBy,
        trafficDifferenceRankingMetric,
        trafficDifferencePeriod,
        trafficDifferenceDateRange,
      },
    } = getState();
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.schema,
      method: 'POST',
    };

    try {
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_LOADING });
      const trafficDifferenceSchema = await dispatch(fetchData<CdrSchema>(options));

      if (!trafficDifferencePeriod) {
        dispatch(setCdrTrafficDifferencePeriod(trafficDifferenceSchema.global.default_period.period));
      }

      if (!trafficDifferenceDateRange) {
        const start = moment(trafficDifferenceSchema.global.default_period.start_date);
        const end = moment(trafficDifferenceSchema.global.default_period.end_date);
        dispatch(
          setCdrTrafficDifferenceDateRange({
            start,
            end,
          })
        );
      }

      if (!trafficDifferenceRankingGroupBy) {
        dispatch(setCdrTrafficDifferenceRankingGroupBy(trafficDifferenceSchema.global.group[0]));
      }

      if (!trafficDifferenceRankingMetric) {
        const trafficDifferenceMetricOptions = getCdrTrafficVolumeRankingMetricOptions(trafficDifferenceSchema.schema);
        dispatch(setCdrTrafficDifferenceRankingMetric(trafficDifferenceMetricOptions[0].value));
      }
      dispatch({
        type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_SUCCESS,
        payload: trafficDifferenceSchema,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_FAILURE });
    }
  };
}

export function setCdrTrafficDifferenceDateRange(payload: CdrDateRange) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_DATE_RANGE,
    payload,
  };
}

export function setCdrTrafficDifferencePeriod(payload: CdrPeriod) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_PERIOD,
    payload,
  };
}

export function setCdrTrafficDifferenceFilter(payload: CdrFilter<string>) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_FILTER,
    payload,
  };
}

export function setCdrTrafficDifferenceRankingGroupBy(payload: CdrRankingGroupBy) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_GROUP_BY,
    payload,
  };
}

export function setCdrTrafficDifferenceRankingMetric(payload: CdrTrafficVolumeMetricsField) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_METRIC,
    payload,
  };
}

export function setCdrTrafficDifferenceRankingCalendarDate(payload: CdrRankingCalendarDate) {
  return {
    type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_CALENDAR_DATE,
    payload,
  };
}

export const getCdrTrafficDifferenceSearch: GetCdrSearchAction = (payload) => {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.trafficVolume.search,
      method: 'POST',
      data: {
        ...payload,
        limit: 100,
      },
    };

    try {
      return dispatch(fetchData<CdrSearch>(options));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return;
    }
  };
};
