import { LandingPageTenantsinfo, LandingPageUsimsInfo, LandingPageUsimsInfo5g } from 'store/models/landing';
import {
  Entity,
  entityFailure,
  entityIdle,
  entityLoading,
  entitySuccess,
  List,
  listFailure,
  listIdle,
  listLoading,
  listSuccess,
} from '.';
import { LANDING_PAGE_ACTION_TYPE } from '../actions/landing';
import { AnyAction } from 'redux';
import { Node } from 'store/models/node';

export type LandingState = {
  usimsInfo: Entity<LandingPageUsimsInfo>;
  usimsInfo5g: Entity<LandingPageUsimsInfo5g>;
  nodes4gInfo: List<Node>;
  nodes5gInfo: List<Node>;
  tenants4gInfo: List<LandingPageTenantsinfo>;
  tenants5gInfo: List<LandingPageTenantsinfo>;
};

const initialState: LandingState = {
  usimsInfo: entityIdle<LandingPageUsimsInfo>(),
  usimsInfo5g: entityIdle<LandingPageUsimsInfo5g>(),
  nodes4gInfo: listIdle<Node>(),
  nodes5gInfo: listIdle<Node>(),
  tenants4gInfo: listIdle<LandingPageTenantsinfo>(),
  tenants5gInfo: listIdle<LandingPageTenantsinfo>(),
};

export default function landingPageReducer(state = initialState, action: AnyAction): LandingState {
  switch (action.type) {
    case LANDING_PAGE_ACTION_TYPE.USIMS_INFO_LOADING:
      return {
        ...state,
        usimsInfo: entityLoading<LandingPageUsimsInfo>(),
      };

    case LANDING_PAGE_ACTION_TYPE.USIMS_INFO_FAILURE:
      return {
        ...state,
        usimsInfo: entityFailure<LandingPageUsimsInfo>(),
      };

    case LANDING_PAGE_ACTION_TYPE.USIMS_INFO_SUCCESS:
      return {
        ...state,
        usimsInfo: entitySuccess<LandingPageUsimsInfo>(action.payload),
      };

    case LANDING_PAGE_ACTION_TYPE.USIMS_INFO_5G_LOADING:
      return {
        ...state,
        usimsInfo5g: entityLoading<LandingPageUsimsInfo5g>(),
      };

    case LANDING_PAGE_ACTION_TYPE.USIMS_INFO_5G_FAILURE:
      return {
        ...state,
        usimsInfo5g: entityFailure<LandingPageUsimsInfo5g>(),
      };

    case LANDING_PAGE_ACTION_TYPE.USIMS_INFO_5G_SUCCESS:
      return {
        ...state,
        usimsInfo5g: entitySuccess<LandingPageUsimsInfo5g>(action.payload),
      };

    case LANDING_PAGE_ACTION_TYPE.NODES_4G_INFO_LOADING:
      return {
        ...state,
        nodes4gInfo: listLoading(state.nodes4gInfo.data),
      };

    case LANDING_PAGE_ACTION_TYPE.NODES_4G_INFO_FAILURE:
      return {
        ...state,
        nodes4gInfo: listFailure<Node>(),
      };

    case LANDING_PAGE_ACTION_TYPE.NODES_4G_INFO_SUCCESS: {
      const { items, total_items } = action.payload;

      return {
        ...state,
        nodes4gInfo: listSuccess({
          data: items,
          total: total_items,
        }),
      };
    }

    case LANDING_PAGE_ACTION_TYPE.NODES_5G_INFO_LOADING:
      return {
        ...state,
        nodes5gInfo: listLoading(state.nodes5gInfo.data),
      };

    case LANDING_PAGE_ACTION_TYPE.NODES_5G_INFO_FAILURE:
      return {
        ...state,
        nodes5gInfo: listFailure<Node>(),
      };

    case LANDING_PAGE_ACTION_TYPE.NODES_5G_INFO_SUCCESS: {
      const { items, total_items } = action.payload;

      return {
        ...state,
        nodes5gInfo: listSuccess({
          data: items,
          total: total_items,
        }),
      };
    }

    case LANDING_PAGE_ACTION_TYPE.TENANTS_4G_INFO_LOADING:
      return {
        ...state,
        tenants4gInfo: listLoading<LandingPageTenantsinfo>(state.tenants4gInfo.data),
      };

    case LANDING_PAGE_ACTION_TYPE.TENANTS_4G_INFO_FAILURE:
      return {
        ...state,
        tenants4gInfo: listFailure<LandingPageTenantsinfo>(),
      };

    case LANDING_PAGE_ACTION_TYPE.TENANTS_4G_INFO_SUCCESS: {
      return {
        ...state,
        tenants4gInfo: listSuccess({
          data: action.payload,
          total: action.payload.length,
        }),
      };
    }

    case LANDING_PAGE_ACTION_TYPE.TENANTS_5G_INFO_LOADING:
      return {
        ...state,
        tenants5gInfo: listLoading<LandingPageTenantsinfo>(state.tenants5gInfo.data),
      };

    case LANDING_PAGE_ACTION_TYPE.TENANTS_5G_INFO_FAILURE:
      return {
        ...state,
        tenants5gInfo: listFailure<LandingPageTenantsinfo>(),
      };

    case LANDING_PAGE_ACTION_TYPE.TENANTS_5G_INFO_SUCCESS: {
      return {
        ...state,
        tenants5gInfo: listSuccess({
          data: action.payload,
          total: action.payload.length,
        }),
      };
    }

    default:
      return state;
  }
}
