import { detectLocale } from 'utils/locale';

import data_de from 'datalists/de/countries.json';
import data_fr from 'datalists/fr/countries.json';
import data_it from 'datalists/it/countries.json';
import data_en from 'datalists/en/countries.json';
import data_es from 'datalists/es/countries.json';

const translations = {
  en: data_en,
  it: data_it,
  de: data_de,
  fr: data_fr,
  es: data_es,
};

const [index] = detectLocale().split('-');

export const get = () => translations[index] || translations.en;

export default get;
