import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { CreatePlmnContent } from './CreatePlmnContent';
import { useCallback } from 'react';
import { Button } from '@athonet/ui/components/Input/Button';

export function CreatePlmn() {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const handleOpenCreate = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'plmns.newPlmn' }),
      content: () => <CreatePlmnContent />,
    });
  }, [dialogOpen, formatMessage]);

  return (
    <Button
      text={formatMessage({ id: 'common.button.new' })}
      data-testid="toolbar-new-button"
      startIcon="Add"
      variant="outlined"
      onClick={handleOpenCreate}
    />
  );
}
