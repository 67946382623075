// React
import { useEffect, useState } from 'react';

// External Components
import { object, string, boolean } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Style
import { FormStyled, AddListStyled } from '../styled';

/* NOTE: CC-1285 */
import SegmentsList from './SegmentsList';

const ManageSegments = ({ dataOptions, data, isOpen, onClose, onAssignSegment, onDeassignSegment }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    let availableSegments = [];
    if (data && data.nodes && data.nodes.length > 0) {
      const usimProfileNodes = data.nodes.map((n) => n.id);
      availableSegments = dataOptions.segments.filter(
        (s) => s.nodes.find((n) => usimProfileNodes.indexOf(n.id) >= 0) !== undefined
      );
    } else {
      availableSegments = dataOptions.segments;
    }

    setFieldlist(fieldset(availableSegments));
  }, [dataOptions, data]);

  const schema = object().shape({
    segment_id: string().required(),
    default: boolean().required(),
  });

  const fieldset = (segments) => [
    {
      title: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments' }),
      data: [
        {
          name: 'segment_id',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.segment_id.label' }),
          placeholder: formatMessage({
            id: 'usimProfiles.form.usimProfile.manageSegments.segment_id.placeholder',
          }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.segment_id.error' }),
          options: segments,
          gridClassName: 'fullwidth',
        },
        {
          name: 'default',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.default.label' }),
          placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.default.placeholder' }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.default.error' }),
          options: [
            {
              value: false,
              label: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.default.false' }),
            },
            {
              value: true,
              label: formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.default.true' }),
            },
          ],
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  const initials = {
    segment_id: '',
    default: false,
  };

  return (
    <FormStyled>
      <Base
        schema={schema}
        initials={initials}
        isOpen={isOpen}
        onClose={onClose}
        fieldset={fieldlist}
        onSubmit={onAssignSegment}
        modalTitle={formatMessage({ id: 'usimProfiles.form.usimProfile.manageSegments.title' })}
        modalDescription={<p>Usim profile: {data?.name}</p>}
        ComponentBottom={<SegmentsList data={data?.segments} onDeassignSegment={onDeassignSegment} />}
        labelContinue={formatMessage({ id: 'common.button.add' })}
        labelCancel={formatMessage({ id: 'common.form.close' })}
        StyleFormWrapper={AddListStyled}
        keepOpen
      />
    </FormStyled>
  );
};

export default ManageSegments;
