import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCdrTrafficVolumeRanking,
  getCdrTrafficVolumeRankingSeries,
  setCdrTrafficVolumeFilter,
  setCdrTrafficVolumeRankingCalendarDate,
  setCdrTrafficVolumeRankingGroupBy,
} from 'store/actions/cdrReports/trafficVolume';
import {
  useCdrTrafficVolumeRankingGroupBy,
  useCdrTrafficVolumeRankingMetric,
  useCdrTrafficVolumeDateRange,
  useCdrTrafficVolumePeriod,
  useCdrTrafficVolumeRanking,
  useCdrTrafficVolumeSchema,
  useCdrTrafficVolumeGroupByOptions,
  useCdrTrafficVolumeRankingSeries,
  useCdrTrafficVolumeRankingCalendarDate,
} from 'store/selectors/cdrReports/trafficVolume';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Ranking, TRAFFIC_RANK, RankingData } from '@athonet/ui/components/Data/Ranking';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import filesize from 'filesize';
import { isEntityLoading } from 'store/reducers';
import { Select } from '@athonet/ui/components/Input/Select';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Calendar } from '@athonet/ui/components/Data/Calendar';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import {
  getCdrActiveSourceNetworkAggregation,
  useCdrActiveSourceNetwork,
} from 'store/selectors/cdrReports/sourceNetwork';
import {
  getRankingCalendarData,
  getRankingChartData,
  getRankingGroupByLabel,
  rankingMetricLabel,
  getSelectedRankingCalendarDate,
} from '../utils/trafficRanking';

export function TrafficVolumeRanking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();
  const trafficVolumeRanking = useCdrTrafficVolumeRanking();
  const trafficVolumeDateRange = useCdrTrafficVolumeDateRange();
  const trafficVolumePeriod = useCdrTrafficVolumePeriod();
  const trafficVolumeSeries = useCdrTrafficVolumeRankingSeries();
  const trafficVolumeRankingGroupBy = useCdrTrafficVolumeRankingGroupBy();
  const trafficVolumeRankingMetric = useCdrTrafficVolumeRankingMetric();
  const trafficVolumeRankingCalendarDate = useCdrTrafficVolumeRankingCalendarDate();
  const trafficVolumeGroupByOptions = useCdrTrafficVolumeGroupByOptions();
  const activeSourceNetwork = useCdrActiveSourceNetwork();

  useEffect(() => {
    if (!trafficVolumeDateRange || !activeSourceNetwork) {
      return;
    }

    dispatch(
      getCdrTrafficVolumeRankingSeries({
        start_date: trafficVolumeDateRange.start,
        end_date: trafficVolumeDateRange.end,
        period: trafficVolumePeriod,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [dispatch, activeSourceNetwork, trafficVolumeDateRange, trafficVolumePeriod]);

  useEffect(() => {
    if (
      !trafficVolumeRankingCalendarDate ||
      !trafficVolumeRankingMetric ||
      !trafficVolumeRankingGroupBy ||
      !activeSourceNetwork
    ) {
      return;
    }

    dispatch(
      getCdrTrafficVolumeRanking({
        date: trafficVolumeRankingCalendarDate,
        period: trafficVolumePeriod,
        metric: trafficVolumeRankingMetric,
        group_by: trafficVolumeRankingGroupBy,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [
    dispatch,
    activeSourceNetwork,
    trafficVolumePeriod,
    trafficVolumeRankingCalendarDate,
    trafficVolumeRankingGroupBy,
    trafficVolumeRankingMetric,
  ]);

  const rankingVolumeChartRanking = useMemo(() => {
    return getRankingChartData(trafficVolumeRanking.data);
  }, [trafficVolumeRanking]);

  const trafficVolumeRankingGroupByLabel = useMemo(() => {
    return getRankingGroupByLabel({ schema: trafficVolumeSchema.data, groupBy: trafficVolumeRankingGroupBy });
  }, [trafficVolumeRankingGroupBy, trafficVolumeSchema]);

  const trafficVolumerankingMetricLabel = useMemo(() => {
    return rankingMetricLabel({ schema: trafficVolumeSchema.data, metric: trafficVolumeRankingMetric });
  }, [trafficVolumeRankingMetric, trafficVolumeSchema.data]);

  const handleAnalysisTypeChange = useCallback(
    (e) => {
      dispatch(setCdrTrafficVolumeRankingGroupBy(e.target.value));
    },
    [dispatch]
  );

  const handleOnClickValue = useCallback(
    (item: RankingData) => {
      dispatch(
        setCdrTrafficVolumeFilter({
          group_by: trafficVolumeRankingGroupBy,
          group_filter: item.field,
        })
      );
      navigate('/reports/traffic-volume/trend');
    },
    [dispatch, navigate, trafficVolumeRankingGroupBy]
  );

  const selectedCalendarDate = useMemo(() => {
    return getSelectedRankingCalendarDate({
      period: trafficVolumePeriod,
      calendarDate: trafficVolumeRankingCalendarDate,
    });
  }, [trafficVolumePeriod, trafficVolumeRankingCalendarDate]);

  const calendarData = useMemo(() => {
    return getRankingCalendarData({ seriesData: trafficVolumeSeries.data, rankingMetric: trafficVolumeRankingMetric });
  }, [trafficVolumeRankingMetric, trafficVolumeSeries.data]);

  const handleCalendarClick = useCallback(
    (_, date) => {
      dispatch(setCdrTrafficVolumeRankingCalendarDate(date));
    },
    [dispatch]
  );

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <span data-test-id="calendar-panel" style={{ flexGrow: 1 }}>
          <Panel
            title={`${trafficVolumerankingMetricLabel} Traffic Volume Calendar`}
            tooltip={`Pick a ${trafficVolumePeriod} on the Calendar to view the ${trafficVolumerankingMetricLabel} Traffic Ranking results`}
            collapsible
          >
            <PanelContent>
              {isEntityLoading(trafficVolumeSeries) ? (
                <Skeleton height={150} variant="rectangular" />
              ) : (
                <Calendar
                  period={trafficVolumePeriod}
                  data={calendarData}
                  onClick={handleCalendarClick}
                  formatTooltipValue={(value) => filesize(value, { base: 2, standard: 'jedec' })}
                  data-testid="cdr-traffic-volume-ranking-calendar"
                />
              )}
            </PanelContent>
          </Panel>
        </span>
      </GridItem>
      {trafficVolumeRankingCalendarDate && (
        <GridItem
          size={{
            xs: 12,
          }}
        >
          <span data-test-id="ranking-grid" style={{ flexGrow: 1 }}>
            <Panel
              title={`${trafficVolumerankingMetricLabel} Traffic Ranking`}
              tooltip={`${trafficVolumerankingMetricLabel} Traffic Ranking displays most/least ${trafficVolumerankingMetricLabel} traffic corresponding to the ${trafficVolumePeriod} selected in the Traffic Volume Calendar`}
              headerActionsComponent={
                <>
                  <Chip
                    id={selectedCalendarDate || ''}
                    label={selectedCalendarDate}
                    color="secondary"
                    data-testid="cdr-traffic-volume-ranking-date-chip"
                  />
                  <Box sx={{ width: { xs: '100%', md: '150px' } }}>
                    <Select
                      label="Analysis Type"
                      onChange={handleAnalysisTypeChange}
                      size={'small'}
                      value={trafficVolumeRankingGroupBy}
                      data-testid="cdr-traffic-volume-ranking-analysis-type-select"
                    >
                      {trafficVolumeGroupByOptions?.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              }
            >
              <PanelContent title={`Most ${trafficVolumerankingMetricLabel} traffic`}>
                <Ranking
                  data={rankingVolumeChartRanking?.top || []}
                  maxValue={rankingVolumeChartRanking?.maxValue || 0}
                  minValue={rankingVolumeChartRanking?.minValue || 0}
                  fieldLabel={trafficVolumeRankingGroupByLabel}
                  valueLabel={`Traffic (${trafficVolumerankingMetricLabel})`}
                  valueFormatter={(value) => filesize(value, { base: 2, standard: 'jedec' })}
                  loading={isEntityLoading(trafficVolumeRanking)}
                  onClickValue={handleOnClickValue}
                  data-testid="cdr-traffic-volume-ranking-most"
                />
              </PanelContent>
              <>
                {rankingVolumeChartRanking?.bottom ? (
                  <PanelContent title={`Least ${trafficVolumerankingMetricLabel} traffic`}>
                    <Ranking
                      rank={TRAFFIC_RANK.BOTTOM}
                      data={rankingVolumeChartRanking?.bottom || []}
                      maxValue={rankingVolumeChartRanking?.maxValue || 0}
                      minValue={rankingVolumeChartRanking?.minValue || 0}
                      fieldLabel={trafficVolumeRankingGroupByLabel}
                      valueLabel={`Traffic (${trafficVolumerankingMetricLabel})`}
                      valueFormatter={(value) => filesize(value, { base: 2, standard: 'jedec' })}
                      loading={isEntityLoading(trafficVolumeRanking)}
                      onClickValue={handleOnClickValue}
                      data-testid="cdr-traffic-volume-ranking-least"
                    />
                  </PanelContent>
                ) : null}
              </>
            </Panel>
          </span>
        </GridItem>
      )}
    </GridContainer>
  );
}
