import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { DateRange } from '@athonet/ui/components/Input/DateRange';
import { Select } from '@athonet/ui/components/Input/Select';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DrawerActions } from '@athonet/ui/components/Overlay/Drawer/DrawerActions';
import { DrawerContent } from '@athonet/ui/components/Overlay/Drawer/DrawerContent';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Box } from '@material-ui/core';
import { FastField, Field, FieldProps, Form, Formik } from 'formik';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  CdrAvailabilityPeriodRange,
  CdrTimeSeriesQuery,
  CdrTimeSeriesQueryData,
  CdrTimeSeriesSchema,
  SourceNetwork,
} from 'store/models/cdr';
import { Entity, isEntityLoading } from 'store/reducers';
import { useCdrActiveSourceNetwork, useCdrSourceNetworks } from 'store/selectors/cdrReports/sourceNetwork';
import { date, object } from 'yup';

type CdrQueryBuilderProps = {
  timeseriesSchema: Entity<CdrTimeSeriesSchema>;
  timeseriesAvailabilityDates: Entity<CdrAvailabilityPeriodRange<Moment>>;
  timeseriesQuery: CdrTimeSeriesQuery;
  setQuery: (payload: CdrTimeSeriesQuery) => void;
};

type CdrQueryBuilderContentProps = Pick<CdrQueryBuilderProps, 'timeseriesQuery' | 'setQuery'> & {
  timeseriesSchema: CdrTimeSeriesSchema;
  timeseriesAvailabilityDates: CdrAvailabilityPeriodRange<Moment>;
  activeSourceNetwork: SourceNetwork;
};

function CdrQueryBuilderContent({
  timeseriesSchema,
  timeseriesAvailabilityDates,
  timeseriesQuery,
  activeSourceNetwork,
  setQuery,
}: CdrQueryBuilderContentProps) {
  const dispatch = useDispatch();
  const sourceNetworks = useCdrSourceNetworks();
  const { currentQuery, defaultQuery } = timeseriesQuery; // TODO: pass it from props
  const { drawerClose } = useOverlay();

  const queryBuilderFields = useMemo(() => {
    return timeseriesSchema.schema.filter((schemaField) => {
      return timeseriesSchema.query_parameters.includes(schemaField.field);
    });
  }, [timeseriesSchema]);

  const handleQuerySubmit = useCallback(
    (values: Record<string, string | undefined>) => {
      if (!queryBuilderFields || !values.start_datetime || !values.end_datetime) {
        return;
      }

      const filteredValues: Record<string, string> = {};

      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          filteredValues[key] = value;
        }
      });

      const query: CdrTimeSeriesQueryData = {
        ...filteredValues,
        source_network: values.source_network || activeSourceNetwork,
        start_datetime: values.start_datetime,
        end_datetime: values.end_datetime,
      };

      dispatch(setQuery({ defaultQuery, currentQuery: query }));
      drawerClose();
    },
    [defaultQuery, dispatch, drawerClose, queryBuilderFields, setQuery, activeSourceNetwork]
  );

  const initialValues = useMemo(() => {
    const initValues: Record<string, string | undefined> = {
      start_datetime: currentQuery?.start_datetime,
      end_datetime: currentQuery?.end_datetime,
      source_network: activeSourceNetwork,
    };

    timeseriesSchema.query_parameters.forEach((queryParam) => {
      initValues[queryParam] = currentQuery ? currentQuery[queryParam]?.toString() : '';
    });
    return initValues;
  }, [currentQuery, timeseriesSchema, activeSourceNetwork]);

  const handleQueryReset = useCallback(
    (handleReset) => {
      handleReset();
      dispatch(setQuery({ defaultQuery, currentQuery: defaultQuery }));
      drawerClose();
    },
    [defaultQuery, dispatch, drawerClose, setQuery]
  );

  const maxRange = useMemo(
    () => moment.duration(timeseriesSchema.max_datetime_range_sec, 'seconds').humanize({ h: 25, d: 366 }),
    [timeseriesSchema]
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      start_datetime: date(),
      end_datetime: date().when(['start_datetime'], (start_datetime: string, schema) => {
        const startDatetime = moment(start_datetime);
        const minEndDateTime = startDatetime.clone().subtract(1, 'second').toISOString();
        const maxEndDatetime = startDatetime
          .clone()
          .add(timeseriesSchema.max_datetime_range_sec, 'seconds')
          .toISOString();
        return (
          schema
            // TODO intl
            .min(minEndDateTime, 'End datetime must be later than Start datetime')
            .max(maxEndDatetime, `Maximum selectable datetime range is ${maxRange}`)
        );
      }),
    });
  }, [maxRange, timeseriesSchema]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleQuerySubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, handleReset, setFieldValue, errors, isValid }) => {
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <DrawerContent>
              <Stack spacing={1.5}>
                <Box sx={{ mb: 1 }}>
                  {errors.end_datetime ? (
                    <Alert message={errors.end_datetime} severity="error" />
                  ) : (
                    <Alert message={`Datetime range selected can be ${maxRange} maximum`} />
                  )}
                </Box>
                <Field name={'start_datetime'}>
                  {({ field }: FieldProps<string>) => {
                    return (
                      <DateRange
                        {...field}
                        textFieldRender
                        minDate={timeseriesAvailabilityDates.min || undefined}
                        maxDate={timeseriesAvailabilityDates.max || undefined}
                        periods={['time']}
                        period="time"
                        fullWidth
                        singleDate
                        label="Start Datetime"
                        startDate={moment(field.value)}
                        endDate={moment(field.value)}
                        onChange={(_, startDate) => {
                          setFieldValue(field.name, startDate.toISOString());
                        }}
                        helperText={errors.start_datetime}
                        error={!isValid}
                      />
                    );
                  }}
                </Field>
                <Field name={'end_datetime'}>
                  {({ field }: FieldProps<string>) => {
                    return (
                      <Box sx={{ my: 4 }}>
                        <DateRange
                          {...field}
                          textFieldRender
                          minDate={moment(values.start_datetime) || undefined}
                          maxDate={
                            moment(values.start_datetime).add(timeseriesSchema.max_datetime_range_sec, 'seconds') ||
                            undefined
                          }
                          periods={['time']}
                          period="time"
                          fullWidth
                          singleDate
                          startDate={moment(field.value)}
                          endDate={moment(field.value)}
                          label="End Datetime"
                          onChange={(_, endDate) => {
                            setFieldValue(field.name, endDate.toISOString());
                          }}
                          error={!isValid}
                        />
                      </Box>
                    );
                  }}
                </Field>
                <Field name="source_network">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <Select
                        {...field}
                        label={'Source Network'}
                        onChange={(e) => {
                          setFieldValue('source_network', e.target.value);
                        }}
                        size={'small'}
                        color="primary"
                        data-testid="cdr-toolbar-source-network-select"
                      >
                        {sourceNetworks?.map((singleSourceNetwork) => (
                          <MenuItem value={singleSourceNetwork} key={singleSourceNetwork}>
                            {singleSourceNetwork}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                </Field>
                {queryBuilderFields?.map(({ field: fieldName, label, type }) => {
                  return (
                    <FastField name={fieldName} key={fieldName}>
                      {({ field }: FieldProps<string>) => {
                        if (type === 'string') {
                          return <TextField {...field} size="small" label={label} fullWidth />;
                        }
                        if (Array.isArray(type)) {
                          return (
                            <Autocomplete
                              {...field}
                              onInputChange={(_, val) => setFieldValue(field.name, val)}
                              onChange={(_, val) => setFieldValue(field.name, val)}
                              multiple={false}
                              size="small"
                              label={label}
                              fullWidth
                              options={type}
                            />
                          );
                        }
                        return null;
                      }}
                    </FastField>
                  );
                })}
              </Stack>
            </DrawerContent>
            <DrawerActions>
              <Stack spacing={2} direction="row">
                <Button
                  type="button"
                  text="Reset"
                  variant="outlined"
                  onClick={() => {
                    handleQueryReset(handleReset);
                  }}
                  data-testid="cdr-query-builder-reset"
                />
                <Button
                  type="submit"
                  text="Submit Query"
                  sx={{ width: 'fit-content' }}
                  data-testid="cdr-query-builder-submit"
                />
              </Stack>
            </DrawerActions>
          </Form>
        );
      }}
    </Formik>
  );
}

export function CdrQueryBuilder({
  timeseriesSchema,
  timeseriesAvailabilityDates,
  timeseriesQuery,
  setQuery,
}: CdrQueryBuilderProps) {
  const { drawerOpen } = useOverlay();
  const activeSourceNetwork = useCdrActiveSourceNetwork();

  const handleDrawerButtonClick = useCallback(() => {
    if (!timeseriesSchema?.data || !timeseriesAvailabilityDates?.data) {
      return;
    }

    drawerOpen({
      content: () => {
        if (!timeseriesSchema?.data || !timeseriesAvailabilityDates?.data || !activeSourceNetwork) {
          return <></>;
        }
        return CdrQueryBuilderContent({
          timeseriesSchema: timeseriesSchema.data,
          timeseriesAvailabilityDates: timeseriesAvailabilityDates.data,
          timeseriesQuery,
          setQuery,
          activeSourceNetwork,
        });
      },
      title: 'Query Parameters',
    });
  }, [
    activeSourceNetwork,
    drawerOpen,
    setQuery,
    timeseriesAvailabilityDates.data,
    timeseriesQuery,
    timeseriesSchema.data,
  ]);

  return (
    <Button
      variant="outlined"
      onClick={handleDrawerButtonClick}
      text="Query"
      startIcon={'Search-Database'}
      data-testid="cdr-query-builder-button"
      loading={isEntityLoading(timeseriesSchema) || isEntityLoading(timeseriesAvailabilityDates)}
    />
  );
}
