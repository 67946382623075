import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { useCallback, useMemo } from 'react';
import { useUsims5gDetailsSelector } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { isEntityLoading } from 'store/reducers';
import { getKText } from 'store/models/usim';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { EditUsimCard5g } from 'components/Edit/5gProvisioning/UsimCards/Edit';
import UsimStatus from 'components/UsimStatus';
import { DetailPageSkeleton } from 'components/Skeletons/DetailPageSkeleton';
import { EditNodeProfile } from 'components/Edit/5gProvisioning/UsimCards/EditNodeProfile';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useCanUserUpdateUsimSelector } from 'store/selectors/user';
import MetadataEditorDialog from 'components/Edit/5gProvisioning/UsimCards/MetadataEditorDialog';

export function UsimDetails() {
  const usim5gDetailsEntity = useUsims5gDetailsSelector();
  const canUserUpdateUsim = useCanUserUpdateUsimSelector();
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const entity = useMemo(() => {
    return usim5gDetailsEntity.data;
  }, [usim5gDetailsEntity]);

  const handleOpenEditGeneral = useCallback(() => {
    if (!entity) {
      return;
    }

    dialogOpen({
      title: formatMessage({ id: 'usims.actions.edit.title' }),
      content: () => <EditUsimCard5g usim5g={entity} />,
    });
  }, [dialogOpen, entity, formatMessage]);

  const openProvisioningStatusEditor = useCallback(() => {
    if (!entity) {
      return;
    }
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.prepare.title' }),
      content: () => <EditNodeProfile usims5g={entity} />,
    });
  }, [dialogOpen, formatMessage, entity]);

  const openMetadataEditor = useCallback(() => {
    if (!entity) {
      return;
    }
    dialogOpen({
      title: `${formatMessage({ id: 'usims.metadata.title' })} - USIM ${entity.supi}`,
      content: () => <MetadataEditorDialog metadata={entity?.metadata} id={entity?.id} />,
    });
  }, [dialogOpen, formatMessage, entity]);

  if (!entity) {
    return null;
  }

  return isEntityLoading(usim5gDetailsEntity) ? (
    <DetailPageSkeleton />
  ) : entity ? (
    <GridContainer>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Panel
          title={formatMessage({ id: 'usims.id.details.generalData' })}
          headerActionsComponent={<IconButton name="Pencil" onClick={handleOpenEditGeneral} fontSize="small" />}
        >
          <PanelContent>
            <GridContainer>
              <KeyValueGridItem label={formatMessage({ id: 'usims.table.name' })} value={entity.name} fullWidth />
              <KeyValueGridItem label={formatMessage({ id: 'usims.table.supi' })} value={entity.supi} />
              <KeyValueGridItem label={formatMessage({ id: 'usims.table.msisdn' })} value={entity.msisdn} />
            </GridContainer>
          </PanelContent>
          <PanelContent title="AKA Authentication" titleColor="text.secondary">
            <GridContainer>
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.k' })}
                value={getKText({
                  k: entity.k,
                  encrypt: entity.encrypt,
                  use_key: entity.use_key,
                })}
                compactWidth
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.op' })}
                value={entity.op ? 'Set' : 'Not Set'}
                compactWidth
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.opc' })}
                value={entity.opc ? 'Set' : 'Not Set'}
                compactWidth
              />
            </GridContainer>
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Panel
            title={formatMessage({ id: 'usims.table.status' })}
            headerActionsComponent={
              <IconButton name="Pencil" onClick={() => openProvisioningStatusEditor()} fontSize="small" />
            }
          >
            <PanelContent>
              <GridContainer>
                <KeyValueGridItem
                  fullWidth
                  label={formatMessage({ id: 'usims.id.details.provisioningStatus' })}
                  valueComponent={<UsimStatus status={entity.status || ''} error={undefined} updating={undefined} />}
                />
                <KeyValueGridItem
                  label={formatMessage({ id: 'usims.table.nodeName' })}
                  value={entity.node_name || '-'}
                />

                <KeyValueGridItem
                  label={formatMessage({ id: 'usims.table.profileName' })}
                  value={entity.profile_name || '-'}
                />
              </GridContainer>
            </PanelContent>
          </Panel>
          <Panel title={formatMessage({ id: 'usims.table.tenantName' })}>
            <PanelContent>
              <Text>{entity.tenant.name || '-'}</Text>
            </PanelContent>
          </Panel>
          <Panel
            title={formatMessage({ id: 'usims.metadata' })}
            headerActionsComponent={
              <IconButton
                name="Pencil"
                onClick={() => openMetadataEditor()}
                fontSize="small"
                disabled={!canUserUpdateUsim}
              />
            }
          >
            <PanelContent>
              <GridContainer>
                {Object.entries(entity.metadata).length === 0 ? (
                  <GridItem size={{ xs: 12, sm: 6 }}>{formatMessage({ id: 'usims.id.details.noMetadata' })}</GridItem>
                ) : (
                  Object.entries(entity.metadata).map(([key, value]) => (
                    <KeyValueGridItem key={key} label={key} value={value} />
                  ))
                )}
              </GridContainer>
            </PanelContent>
          </Panel>
        </Stack>
      </GridItem>
    </GridContainer>
  ) : null;
}
