import { useSelector } from 'react-redux';

export function useNodesSelector() {
  const nodes = useSelector((state) => state.nodes);
  return nodes;
}

export function useNodesListSelector() {
  const list = useSelector((state) => state.nodes.list);
  return list;
}

export function useNodesFiltersSelector() {
  const filters = useSelector((state) => state.nodes.filters);
  return filters;
}

export function useNodeEntitySelector() {
  const node = useSelector((state) => state.nodes.entity);
  return node;
}

export function useIsNodeLegacySelector() {
  const node = useSelector((state) => state.nodes.entity);
  return node?.data?.product_version.match(/EPC/);
}
