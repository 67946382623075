import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { listClear, deleteNode, getNodes } from 'store/actions/nodes';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Nodes';
import List, { ColumnShape } from 'components/List';
import { Create } from 'components/Edit/Nodes/Create';
import Spinner from 'components/Spinner';
import DropdownTools from 'components/DropdownTools';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { tenantsCellRenderer } from 'utils/tenantsCellRenderer';
import { useNavigate } from 'react-router-dom';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { EditNode } from 'components/Edit/Nodes/EditNode';
import { Node } from 'store/models/node';

export default function Nodes() {
  const bootstrap = useBootstrapSelector();
  const userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const datalist = useSelector((state) => state.nodes.list.data);
  const loadingState = useSelector((state) => state.nodes.list.state);
  const { confirmationDialogOpen } = useOverlay();
  const navigate = useNavigate();
  const { dialogOpen } = useOverlay();
  const filters = useSelector((state) => state.nodes.filters);

  useEffect(() => {
    return () => {
      dispatch(listClear());
    };
  }, [dispatch]);

  const handlePageChange = (page: number) => {
    dispatch(getNodes({ page }));
  };

  useEffect(() => {
    dispatch(getNodes({}));
  }, [dispatch, bootstrap?.pageLimit]);

  const onOrderChange = (orderQuery: string) => {
    dispatch(getNodes({ sortBy: orderQuery }));
  };

  const handleRefresh = useCallback(() => {
    dispatch(getNodes({}));
  }, [dispatch]);

  const handleOpenEdit = useCallback(
    (node) => {
      dialogOpen({
        title: formatMessage({ id: 'nodes.editNode' }),
        content: () => <EditNode node={node} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const openDelete = ({ id: nodeId, name }: Node) => {
    confirmationDialogOpen({
      title: formatMessage({ id: 'nodes.deleteNode.confirm.title' }),
      description: formatMessage({ id: 'nodes.deleteNode.confirm' }),
      alertMessage: formatMessage({ id: 'nodes.actions.itemsAffected' }, { element: name }),
      continueButtonText: formatMessage({ id: 'nodes.deleteNode.confirm.continueButton' }, { elements: 1 }),
      onConfirm: () => dispatch(deleteNode(nodeId)),
      dataTestid: 'confirm-delete-node',
    });
  };

  const getTools = (rowData: Node) => {
    const options = checkPermissionsList(userdata?.permissions || [], [
      {
        icon: ico_edit,
        label: formatMessage({ id: 'nodes.editNode' }),
        action: () => handleOpenEdit(rowData),
        disabled: false,
        permissions: [U_PERMISSIONS.UPDATE_NODE],
        name: 'edit-node',
      },
      {
        icon: ico_delete,
        label: formatMessage({ id: 'nodes.deleteNode' }),
        action: () => openDelete(rowData),
        disabled: false,
        permissions: [U_PERMISSIONS.DELETE_NODE],
        name: 'delete-node',
      },
    ]);

    return <DropdownTools options={options} />;
  };

  const columns: ColumnShape<Node>[] = [
    {
      key: 'id',
      title: 'ID',
      dataKey: 'id',
      width: 200,
      maxWidth: 300,
      minWidth: 100,
      visible: false,
    },
    {
      key: 'displayName',
      title: formatMessage({ id: 'nodes.table.name' }),
      dataKey: 'display_name',
      visible: true,
      sortable: true,
      cellRenderer: ({ cellData: display_name, rowData: { id: nodeId } }) => {
        return (
          <Link
            onClick={() => {
              navigate(`/networks/nodes/${nodeId}`);
            }}
          >
            <Text type={'body2'}>{display_name}</Text>
          </Link>
        );
      },
      width: 100, // TODO
    },
    {
      key: 'productVersion',
      title: formatMessage({ id: 'nodes.table.nodeType' }),
      dataKey: 'product_version',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable',
      className: 'table-cell-resizable',
      minWidth: 100,
    },
    {
      key: 'networkElements',
      title: formatMessage({ id: 'nodes.table.networkElements' }),
      dataKey: 'network_elements',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable',
      className: 'table-cell-resizable',
      minWidth: 100,
      permissions: [T_PERMISSIONS.MASTER],
      cellRenderer: ({ cellData: network_elements }: { cellData: Node['network_elements'] }) => {
        if (network_elements.length === 0) {
          return '-';
        } else if (network_elements.length === 1) {
          return <span title={network_elements[0].type}>{network_elements[0].name}</span>;
        } else {
          let title = network_elements.reduce((acc, ne) => acc + '\n' + ne.name + ' [' + ne.type + ']', '');
          return (
            <span title={title}>
              {network_elements[0].name} (+{network_elements.length - 1} more...)
            </span>
          );
        }
      },
      visible: false,
    },
    {
      // backend still returning "owners"
      key: 'owners',
      title: formatMessage({ id: 'nodes.table.tenants' }),
      dataKey: 'owners',
      sortable: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
      cellRenderer: ({ cellData: tenants }) => tenantsCellRenderer(tenants),

      visible: true,
    },
    {
      key: 'tools',
      title: '',
      dataKey: 'tools',
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      secret: true, // secret used to hide from columns management panel
      cellRenderer: ({ rowData }) => {
        return getTools(rowData);
      },
    },
  ];

  const columnsPermitted = checkPermissionsList([userdata?.tenant_type], columns);

  return columnsPermitted ? (
    <List
      key="node-list"
      columns={columnsPermitted}
      totalRows={datalist.total}
      data={datalist.data}
      page={datalist.page}
      filters={filters}
      onOrderChange={onOrderChange}
      loadingState={loadingState}
      createComponent={checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_NODE) ? <Create /> : undefined}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      toolbar={{ actions: false }}
      rowsPerPage={bootstrap?.pageLimit}
      onRefresh={handleRefresh}
    />
  ) : (
    <Spinner className="spinner" size={40} />
  );
}
