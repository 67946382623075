import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteQuestion from '../DeleteQuestion';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { ListItemSecondaryAction } from '@material-ui/core';
import { UsimProfileSegmentsListStyled } from '../styled';
import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';

/* NOTE: CC-1285 */
function SegmentStatus({ item }) {
  let statusType;
  switch (item.status) {
    case 'ready':
      statusType = STATUS.SUCCESS;
      break;
    case 'associating':
    case 'deassociating':
      statusType = STATUS.INFO;
      break;
    case 'error':
      statusType = STATUS.ERROR;
      break;
    default:
      statusType = STATUS.DEFAULT;
  }
  if (item.errors.length > 0) {
    statusType = STATUS.ERROR;
  }
  if (item.updating) {
    statusType = STATUS.INFO;
  }
  return <Status status={statusType} label={humanizeSnakeCase(item.status)} />;
}

const SegmentsList = ({ data, onDeassignSegment }) => {
  const { formatMessage } = useIntl();
  const [deleteSure, setDeleteSure] = useState();
  const [updating, setUpdating] = useState();

  useEffect(() => {
    setDeleteSure(undefined); // reset delete question
    setUpdating(undefined);
  }, [data]);

  const removeSegment = (index, nodeID) => {
    setUpdating(index);
    onDeassignSegment(nodeID);
  };

  return (
    <UsimProfileSegmentsListStyled>
      <div className="segmentlist-total">
        {formatMessage(
          {
            id:
              data?.length === 1
                ? 'usimProfiles.form.usimProfile.manageSegments.total'
                : 'usimProfiles.form.usimProfile.manageSegments.total_plural',
          },
          { value: data?.length }
        )}
      </div>
      <List>
        {data?.map((item, index) => {
          return (
            <>
              <ListItem>
                <ListItemText
                  primary={item.name + (item.default === true ? ' [default]' : '') + ' [APN: ' + item.apn + ']'}
                  secondary={<SegmentStatus item={item} />}
                />
                <ListItemSecondaryAction>
                  {item.default !== true && (
                    <DeleteQuestion
                      id={item.id}
                      onDelete={removeSegment}
                      setDeleteSure={setDeleteSure}
                      isUpdating={updating === index || item.updating}
                      isDeleteSure={deleteSure === index}
                      index={index}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              {data.length > index + 1 && <Divider variant="middle" />}
            </>
          );
        })}
      </List>
    </UsimProfileSegmentsListStyled>
  );
};

export default SegmentsList;
