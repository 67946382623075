import { Chart, ChartsRef } from '@athonet/ui/components/Data/Chart';
import ChartToolbox from '@athonet/ui/components/Data/Chart/Toolbox';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { lighten } from '@material-ui/core/styles';
import { useCallback, useRef } from 'react';
import {
  CdrTrafficVolumeMetrics,
  CdrTrafficVolumeMetricsField,
  CDR_SOURCE_NETWORKS_AGGREGATION_TYPE,
} from 'store/models/cdr';

export type DifferenceTrendChartProps = {
  isLoading?: boolean;
  headerActionsComponent?: JSX.Element | (JSX.Element | null)[];
  xAxisFormat?: (value: string) => string;
  yAxisFormat?: (value: number) => string;
  tooltipFormat?: (value: unknown) => string;
  metric: CdrTrafficVolumeMetricsField;
  seriesData:
    | {
        difference: CdrTrafficVolumeMetrics<[string, string | number][]>;
        mvno: CdrTrafficVolumeMetrics<[string, string | number][]>;
        mno: CdrTrafficVolumeMetrics<[string, string | number][]>;
      }
    | undefined;
  colors: { difference: string; mvno: string; mno: string };
};

export default function DifferenceTrendChart({
  isLoading,
  headerActionsComponent,
  xAxisFormat,
  yAxisFormat,
  tooltipFormat,
  seriesData,
  metric,
  colors,
}: DifferenceTrendChartProps) {
  const chartRef = useRef<ChartsRef | null>(null);

  const differenceSeriesData = useCallback(
    (aggregationType: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE) => {
      if (!seriesData?.mvno[metric][0] || !seriesData?.mno[metric][0]) {
        return [];
      }

      const diffSeriesData = seriesData?.difference[metric].map((point, i) => {
        const mvnoPoint = seriesData.mvno[metric][i][1];
        const mnoPoint = seriesData.mno[metric][i][1];

        let pointValue = 0;

        if (aggregationType === CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MVNO) {
          if (mnoPoint > mvnoPoint) {
            pointValue = typeof point[1] === 'string' ? Math.abs(parseInt(point[1])) : Math.abs(point[1]);
          }
        } else if (aggregationType === CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MNO) {
          if (mnoPoint < mvnoPoint) {
            pointValue = typeof point[1] === 'string' ? Math.abs(parseInt(point[1])) : Math.abs(point[1]);
          }
        }

        return [point[0], pointValue];
      });

      return diffSeriesData;
    },
    [metric, seriesData]
  );

  return (
    <Panel
      title={'Traffic trend'}
      headerActionsComponent={<ChartToolbox chartInstanceRef={chartRef} zoom actions={headerActionsComponent} />}
    >
      <PanelContent>
        {!isLoading ? (
          <Chart
            instanceRef={chartRef}
            height={240}
            zoom="1d"
            options={{
              series: [
                {
                  name: 'MVNO',
                  stack: 'mvno-sub-traffic',
                  data: seriesData?.mvno[metric],
                  color: lighten(colors.mvno, 0.1),
                  universalTransition: true,
                  type: 'bar',
                  itemStyle: {
                    decal: { color: 'transparent' },
                  },
                },

                {
                  name: 'MNOs',
                  stack: 'mno-sub-traffic',
                  data: seriesData?.mno[metric],
                  color: lighten(colors.mno, 0.1),
                  universalTransition: true,
                  type: 'bar',
                  itemStyle: {
                    decal: { color: 'transparent' },
                  },
                },
                {
                  name: 'Difference',
                  stack: 'mvno-sub-traffic',
                  data: differenceSeriesData(CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MVNO),
                  color: colors.difference,
                  universalTransition: true,
                  type: 'bar',
                  itemStyle: {
                    decal: {
                      color: colors.difference,
                      backgroundColor: 'white',
                      dashArrayX: [1, 0],
                      dashArrayY: [1, 4],
                      rotation: -Math.PI / 4,
                    },
                  },
                },
                {
                  name: 'Difference',
                  stack: 'mno-sub-traffic',
                  data: differenceSeriesData(CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MNO),
                  color: colors.difference,
                  universalTransition: true,
                  type: 'bar',
                  itemStyle: {
                    decal: {
                      color: colors.difference,
                      backgroundColor: 'white',

                      dashArrayX: [1, 0],
                      dashArrayY: [1, 4],
                      rotation: -Math.PI / 4,
                    },
                  },
                },
              ],
              xAxis: {
                type: 'category',
                axisLabel: {
                  ...(xAxisFormat && {
                    formatter: (value: string | number, zoomValue: string | number) => {
                      if (typeof value === 'string') {
                        return xAxisFormat(value);
                      }
                      return xAxisFormat(zoomValue.toString());
                    },
                  }),
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: yAxisFormat,
                },
              },
              tooltip: {
                formatter: tooltipFormat,
              },
              legend: {
                selectedMode: false,
                formatter: `{name} ${metric}`,
              },
            }}
            data-testid="cdr-traffic-difference-trend-chart"
          />
        ) : (
          <Skeleton height={240} variant="rectangular" />
        )}
      </PanelContent>
    </Panel>
  );
}
