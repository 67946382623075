import { CalculatedBills } from '../../models/billing';
import { fetchData } from '../../actions/fetchData';
import { AxiosRequestConfig } from 'axios';
import { Thunk } from '..';
import { BILLING_TYPE } from '.';
import config from 'config';
import { showErrorToast } from '../toast';

export function getCalculatedBills(date: string): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { bootstrap } = getState();
    const url = `${config.cdrBillingApis.calculatedBills}?date=${date}&source_network=${bootstrap?.source_networks[0]}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };
    try {
      dispatch({ type: BILLING_TYPE.CALCULATED_BILLS_LIST_LOADING });
      const calculatedBills = await dispatch(fetchData<CalculatedBills[]>(options));
      dispatch({ type: BILLING_TYPE.CALCULATED_BILLS_LIST_SUCCESS, payload: calculatedBills });
    } catch (e: any) {
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
      dispatch({ type: BILLING_TYPE.CALCULATED_BILLS_LIST_FAILURE });
    }
  };
}
