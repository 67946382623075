import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Button } from '@athonet/ui/components/Input/Button';
import UploadOrderDialog from './UploadOrderDialog';

export default function UploadOrderButton() {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();
  const bootstrap = useBootstrapSelector();

  const handleOpenUploadDialog = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.orders.form.order.upload.title' }),
      content: () => <UploadOrderDialog />,
    });
  }, [dialogOpen, formatMessage]);

  if (!bootstrap) return null;

  return (
    <Button
      onClick={handleOpenUploadDialog}
      text={formatMessage({ id: 'usims.orders.form.order.upload' })}
      startIcon="Cloud-Upload"
      variant="outlined"
    />
  );
}
