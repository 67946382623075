import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { HSSNetworkElement } from 'store/models/node';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { useCallback, useState } from 'react';
import Edit from 'components/Edit/Hss/Edit';
import { useCanUserUpdateNodeSelector, useUserSelector } from 'store/selectors/user';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteHss } from 'store/actions/nodes';
import { checkPermissionsList, U_PERMISSIONS } from 'utils/permissionCodes';

export function HssSection({ hss }: { hss: HSSNetworkElement }) {
  const [isEditHssOpen, setIsEditHssOpen] = useState(false);
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const { menuOpen, menuClose, confirmationSimpleDialogOpen } = useOverlay();
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const dispatch = useDispatch();

  const closeEdit = useCallback(() => {
    setIsEditHssOpen(false);
  }, []);

  const openDeleteHss = useCallback(() => {
    confirmationSimpleDialogOpen({
      title: formatMessage({ id: 'hss.deleteHss' }),
      description: formatMessage({ id: 'hss.deleteHss.confirm' }),
      onConfirm: async () => {
        await dispatch(deleteHss(hss.id));
      },
    });
  }, [confirmationSimpleDialogOpen, formatMessage, dispatch, hss]);

  const openActionsMenu = useCallback(
    (e) => {
      menuOpen({
        anchorEl: e.currentTarget,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
        items: checkPermissionsList(
          user?.permissions || [],
          [
            {
              label: formatMessage({ id: 'hss.editHss' }),
              disabled: false,
              permissions: [U_PERMISSIONS.UPDATE_NETWORK_ELEMENT],
              value: 'edit-hss',
              onClick: () => {
                setIsEditHssOpen(true);
                menuClose();
              },
            },
            {
              label: formatMessage({ id: 'hss.deleteHss' }),
              disabled: false,
              permissions: [U_PERMISSIONS.DELETE_NETWORK_ELEMENT],
              value: 'delete-hss',
              onClick: () => {
                openDeleteHss();
                menuClose();
              },
            },
          ],
          false
        ),
      });
    },
    [formatMessage, menuClose, menuOpen, openDeleteHss, user]
  );

  return (
    <>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <Panel
          title={formatMessage({ id: 'hss.hss' })}
          headerActionsComponent={
            <IconButton
              name="Other"
              onClick={openActionsMenu}
              fontSize="small"
              sx={{ transform: 'rotate(90deg)' }}
              disabled={!canUserUpdateNode}
            />
          }
        >
          <PanelContent>
            <GridContainer>
              <KeyValueGridItem label={formatMessage({ id: 'hss.name' })} value={hss.name} />
              <KeyValueGridItem label={formatMessage({ id: 'hss.agentID' })} value={hss.payload.agent_id} />
              <KeyValueGridItem
                label={formatMessage({ id: 'hss.defaultTK' })}
                valueComponent={
                  <Status
                    label={hss.payload.default_tk ? 'Set' : 'Not Set'}
                    status={hss.payload.default_tk ? STATUS.SUCCESS : STATUS.DEFAULT}
                  />
                }
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'hss.defaultOP' })}
                valueComponent={
                  <Status
                    label={hss.payload.default_op ? 'Set' : 'Not Set'}
                    status={hss.payload.default_op ? STATUS.SUCCESS : STATUS.DEFAULT}
                  />
                }
              />
            </GridContainer>
          </PanelContent>
        </Panel>
      </GridItem>
      {isEditHssOpen && <Edit data={hss} isOpen={isEditHssOpen} onClose={closeEdit} />}
    </>
  );
}
