import { useSelector } from 'react-redux';

export function useGpgKeysSelector() {
  const gpgKeys = useSelector((state) => state.gpgKeys);
  return gpgKeys;
}

export function useGpgKeysListSelector() {
  const list = useSelector((state) => state.gpgKeys.list);
  return list;
}

export function useGpgKeysFiltersSelector() {
  const filters = useSelector((state) => state.gpgKeys.filters);
  return filters;
}
