import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue } from 'store/models/filters';
import { useCallback, useMemo } from 'react';
import { FiltersDrawer, FiltersDrawerItem } from '../Drawer';
import { string } from 'yup';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { useDispatch, useSelector } from 'react-redux';
import { getGpgKeys } from 'store/actions/gpgKeys';

export default function GpgKeys() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const values = useSelector((state) => state.gpgKeys.filters);

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'name',
        label: formatMessage({ id: 'usims.gpgkeys.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'private',
        label: formatMessage({ id: 'usims.gpgkeys.filters.type' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['private'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'usims.form.gpgkeys.public' }), value: 'false' },
          { label: formatMessage({ id: 'usims.form.gpgkeys.private' }), value: 'true' },
        ],
      },
      {
        name: 'filename',
        label: formatMessage({ id: 'usims.gpgkeys.filters.filename' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['filename'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'tenant_id',
        label: formatMessage({ id: 'usims.gpgkeys.filters.tenant' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        not: [FILTER_INPUT_TYPE.OPTIONS],
        multiple: [FILTER_INPUT_TYPE.OPTIONS],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
        initial: values['tenant_id'],
        schema: string(),
        getInitialOptions: async () => dispatch(getTenantsAutocompleteOptions()),
      },
    ],
    [dispatch, formatMessage, values]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(getGpgKeys({ filterBy: filtersValues })), [dispatch]);

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
