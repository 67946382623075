import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteQuestion from '../DeleteQuestion';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { ListItemSecondaryAction } from '@material-ui/core';
import NodeResourceStatus from 'components/NodeResourceStatus';
import { NodesListStyled } from '../styled';

const NodesList = ({ data, onDeassignNode }) => {
  const { formatMessage } = useIntl();
  const [deleteSure, setDeleteSure] = useState();
  const [updating, setUpdating] = useState();

  useEffect(() => {
    setDeleteSure(undefined); // reset delete question
    setUpdating(undefined);
  }, [data]);

  const deassignNode = (index, nodeID) => {
    setUpdating(index);
    onDeassignNode(nodeID);
  };

  return (
    <NodesListStyled>
      <div className="nodelist-total">
        {formatMessage(
          {
            id:
              data?.length === 1
                ? 'segments.form.qosProfile.manageNodes.total'
                : 'segments.form.qosProfile.manageNodes.total_plural',
          },
          { value: data?.length }
        )}
      </div>
      <List>
        {data?.map((item, index) => (
          <>
            <ListItem>
              <ListItemText
                primary={item.display_name}
                secondary={
                  <NodeResourceStatus status={item.status} updating={item.updating} error={item.errors.length > 0} />
                }
              />
              <ListItemSecondaryAction>
                <DeleteQuestion
                  id={item.id}
                  onDelete={deassignNode}
                  setDeleteSure={setDeleteSure}
                  isUpdating={updating === index || item.updating}
                  isDeleteSure={deleteSure === index}
                  index={index}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {data.length > index + 1 && <Divider variant="middle" />}
          </>
        ))}
      </List>
    </NodesListStyled>
  );
};

export default NodesList;
