import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Formik, Form, Field, FieldProps } from 'formik';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { useCallback, useEffect } from 'react';
import { object, string } from 'yup';
import { adminConfigLogin, getDataUser, hydrateAdminConfigUser } from 'store/actions/adminconfig/login';
import { useDispatch } from 'react-redux';
import { isEntityFailure } from 'store/reducers';
import { useAdminConfigUserSelector } from 'store/selectors/adminConfig';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const adminConfigUser = useAdminConfigUserSelector();
  const navigate = useNavigate();

  const schema = object().shape({
    password: string().required(formatMessage({ id: 'login.enterprisePasswordError' })),
  });

  const handleSubmit = useCallback(
    async (values) => {
      const data = new FormData();
      data.append('password', values.password);
      dispatch(adminConfigLogin(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (adminConfigUser.data) {
      navigate('/adminconfig');
    } else if (getDataUser()) {
      dispatch(hydrateAdminConfigUser()); // TODO [minor]: can be moved into reducer
    }
  }, [adminConfigUser, dispatch, navigate]);

  return (
    <>
      <Stack align="center" justify="center" fullHeight fullWidth>
        <Box sx={{ width: '280px', mb: 2 }}>
          <Logo width={280} />
        </Box>
        <AuthPanel
          title={formatMessage({ id: 'login.title' })}
          description={formatMessage({ id: 'login.subtitle' })}
          data-testid="adminconfigloginForm"
        >
          <Box sx={{ mb: 2 }}>
            {isEntityFailure(adminConfigUser) && (
              <Alert severity="error" message={formatMessage({ id: 'login.errorMessage' })} />
            )}
          </Box>
          <Formik initialValues={{ password: '' }} onSubmit={handleSubmit} validationSchema={schema}>
            {({ isSubmitting, touched, errors }) => (
              <>
                <Form noValidate autoComplete="off">
                  <Field name="password">
                    {({ field }: FieldProps<string>) => (
                      <Box sx={{ width: '100%', height: '88px' }}>
                        <TextField
                          fullWidth
                          {...field}
                          type="password"
                          showPasswordVisibility
                          label={formatMessage({ id: 'login.passwordLabel' })}
                          placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                          error={Boolean(touched['password'] && errors['password'])}
                          {...(touched['password'] && errors['password'] && { helperText: errors['password'] })}
                        />
                      </Box>
                    )}
                  </Field>
                  <Stack spacing={2}>
                    <Button
                      size="large"
                      data-testid="adminconfigloginForm-submitButton"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      text={formatMessage({ id: `login.enterpriseSubmitButton` })}
                      color="secondary"
                      type="submit"
                    />
                  </Stack>
                </Form>
              </>
            )}
          </Formik>
        </AuthPanel>
      </Stack>
    </>
  );
}
