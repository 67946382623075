import moment from 'moment';
import { Moment } from 'moment';
import { CdrReportsAction, CDR_ACTION_TYPE } from 'store/actions/cdrReports';
import {
  CdrPeriod,
  CdrDateRange,
  CdrTrafficVolumeSeries,
  CdrTrafficVolumeTotal,
  CdrFilter,
  CdrRankingGroupBy,
  CdrRankingCalendarDate,
  CdrTrafficVolumeMetricsField,
  CdrAvailabilityDates,
  CdrAvailabilityPeriodRange,
  CdrTimeSeriesSchema,
  CdrTimeSeriesQuery,
} from 'store/models/cdr';
import { CdrRankingState, CdrSchemaState, getMomentAvailabilityDates } from '.';
import { Entity, entityFailure, entityIdle, entityLoading, entitySuccess } from '..';

export type CdrReportsTrafficVolumeTotalState = CdrTrafficVolumeTotal<number | null>;

export type CdrTrafficVolumeSeriesState = CdrTrafficVolumeSeries<number | null, Moment>;

export type CdrTrafficVolumeAvailabilityDates = CdrAvailabilityDates<Moment>;

export type CdrTrafficVolumeTimeseriesAvailabilityDates = CdrAvailabilityPeriodRange<Moment>;

export type CdrReportsTrafficVolumeState = {
  trafficVolumeTotal: Entity<CdrReportsTrafficVolumeTotalState>; // CHECK ENTITY - same as list
  trafficVolumeTrendSeries: Entity<CdrTrafficVolumeSeriesState>;
  trafficVolumeRankingSeries: Entity<CdrTrafficVolumeSeriesState>;
  trafficVolumeSchema: Entity<CdrSchemaState>;
  trafficVolumeAvailabilityDates: Entity<CdrTrafficVolumeAvailabilityDates>;
  trafficVolumeRanking: Entity<CdrRankingState>;
  trafficVolumeDateRange: CdrDateRange | null;
  trafficVolumePeriod: CdrPeriod;
  trafficVolumeFilter: CdrFilter<string>;
  trafficVolumeRankingGroupBy: CdrRankingGroupBy;
  trafficVolumeRankingMetric: CdrTrafficVolumeMetricsField;
  trafficVolumeRankingCalendarDate: CdrRankingCalendarDate;
  trafficVolumeTimeseriesSchema: Entity<CdrTimeSeriesSchema>;
  trafficVolumeTimeseriesAvailabilityDates: Entity<CdrTrafficVolumeTimeseriesAvailabilityDates>;
  trafficVolumeTimeseriesQuery: CdrTimeSeriesQuery;
};

const initialState: CdrReportsTrafficVolumeState = {
  trafficVolumeTotal: entityIdle(),
  trafficVolumeSchema: entityIdle(),
  trafficVolumeAvailabilityDates: entityIdle(),
  trafficVolumeTrendSeries: entityIdle(),
  trafficVolumeRankingSeries: entityIdle(),
  trafficVolumeRanking: entityIdle(),
  trafficVolumeDateRange: null,
  trafficVolumePeriod: 'day',
  trafficVolumeFilter: {
    group_by: '',
    group_filter: '',
  },
  trafficVolumeRankingGroupBy: '',
  trafficVolumeRankingMetric: 'totallink',
  trafficVolumeRankingCalendarDate: null,
  trafficVolumeTimeseriesSchema: entityIdle(),
  trafficVolumeTimeseriesAvailabilityDates: entityIdle(),
  trafficVolumeTimeseriesQuery: { currentQuery: null, defaultQuery: null },
};

export default function cdrReportsTrafficVolumeReducer(
  state = initialState,
  action: CdrReportsAction
): CdrReportsTrafficVolumeState {
  switch (action.type) {
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_LOADING:
      return {
        ...state,
        trafficVolumeTotal: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_SUCCESS: {
      return {
        ...state,
        trafficVolumeTotal: entitySuccess<CdrReportsTrafficVolumeTotalState>({
          uplink: action.payload.uplink === null ? null : Number(action.payload.uplink),
          downlink: action.payload.downlink === null ? null : Number(action.payload.downlink),
          totallink: action.payload.totallink === null ? null : Number(action.payload.totallink),
        }),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_FAILURE:
      return {
        ...state,
        trafficVolumeTotal: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_LOADING:
      return {
        ...state,
        trafficVolumeSchema: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_SUCCESS:
      return {
        ...state,
        trafficVolumeSchema: entitySuccess<CdrSchemaState>(action.payload),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_FAILURE:
      return {
        ...state,
        trafficVolumeSchema: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_LOADING:
      return {
        ...state,
        trafficVolumeAvailabilityDates: entityLoading(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_SUCCESS:
      return {
        ...state,
        trafficVolumeAvailabilityDates: entitySuccess<CdrTrafficVolumeAvailabilityDates>(
          getMomentAvailabilityDates(action.payload)
        ),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_FAILURE:
      return {
        ...state,
        trafficVolumeAvailabilityDates: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_LOADING:
      return {
        ...state,
        trafficVolumeTrendSeries: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_SUCCESS: {
      return {
        ...state,
        trafficVolumeTrendSeries: entitySuccess<CdrTrafficVolumeSeriesState>(
          action.payload.map((item) => {
            return {
              uplink: item.uplink === null ? null : Number(item.uplink),
              downlink: item.downlink === null ? null : Number(item.downlink),
              totallink: item.totallink === null ? null : Number(item.totallink),
              datetime: moment(item.datetime),
            };
          })
        ),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_FAILURE:
      return {
        ...state,
        trafficVolumeTrendSeries: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_LOADING:
      return {
        ...state,
        trafficVolumeRankingSeries: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_SUCCESS: {
      return {
        ...state,
        trafficVolumeRankingSeries: entitySuccess<CdrTrafficVolumeSeriesState>(
          action.payload.map((item) => {
            return {
              uplink: Number(item.uplink),
              downlink: Number(item.downlink),
              totallink: Number(item.totallink),
              datetime: moment(item.datetime),
            };
          })
        ),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_FAILURE:
      return {
        ...state,
        trafficVolumeRankingSeries: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_LOADING:
      return {
        ...state,
        trafficVolumeRanking: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SUCCESS: {
      return {
        ...state,
        trafficVolumeRanking: entitySuccess<CdrRankingState>({
          top: action.payload.top.map((item) => {
            return {
              group_by: {
                ...item.group_by,
                value: item.group_by.value,
              },
              metric: {
                ...item.metric,
                value: Number(item.metric.value),
              },
            };
          }),
          bottom: action.payload.bottom?.map((item) => {
            return {
              group_by: {
                ...item.group_by,
                value: item.group_by.value,
              },
              metric: {
                ...item.metric,
                value: Number(item.metric.value),
              },
            };
          }),
        }),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_FAILURE:
      return {
        ...state,
        trafficVolumeRanking: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_LOADING:
      return {
        ...state,
        trafficVolumeTimeseriesSchema: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_SUCCESS:
      return {
        ...state,
        trafficVolumeTimeseriesSchema: entitySuccess<CdrTimeSeriesSchema>(action.payload),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_FAILURE:
      return {
        ...state,
        trafficVolumeTimeseriesSchema: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_LOADING:
      return {
        ...state,
        trafficVolumeTimeseriesAvailabilityDates: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_SUCCESS:
      return {
        ...state,
        trafficVolumeTimeseriesAvailabilityDates: entitySuccess<CdrTrafficVolumeTimeseriesAvailabilityDates>({
          min: moment(action.payload.min),
          max: moment(action.payload.max),
        }),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_FAILURE:
      return {
        ...state,
        trafficVolumeTimeseriesAvailabilityDates: entityFailure(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SET_QUERY:
      return {
        ...state,
        trafficVolumeTimeseriesQuery: action.payload,
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_DATE_RANGE:
      return {
        ...state,
        trafficVolumeDateRange: action.payload,
        trafficVolumeRankingCalendarDate: null,
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_PERIOD:
      return {
        ...state,
        trafficVolumePeriod: action.payload,
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_FILTER:
      return {
        ...state,
        trafficVolumeFilter: action.payload,
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_GROUP_BY:
      return {
        ...state,
        trafficVolumeRankingGroupBy: action.payload,
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_METRIC:
      return {
        ...state,
        trafficVolumeRankingMetric: action.payload,
      };
    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_CALENDAR_DATE:
      return {
        ...state,
        trafficVolumeRankingCalendarDate: action.payload,
      };

    case CDR_ACTION_TYPE.TRAFFIC_VOLUME_FLUSH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
