import { BillingConfig, CalculatedBills } from '../../models/billing';

export enum BILLING_TYPE {
  BILLING_CONFIGURATION_LIST_SUCCESS = 'BILLING_CCONFIGURATION_LIST_SUCCESS',
  BILLING_CONFIGURATION_LIST_FAILURE = 'BILLING_CONFIGURATION_LIST_FAILURE',
  BILLING_CONFIGURATION_LIST_LOADING = 'BILLING_CONFIGURATION_LIST_LOADING',
  BILLING_CONFIGURATION_DELETE_LOADING = 'BILLING_CONFIGURATION_DELETE_LOADING',
  BILLING_CONFIGURATION_DELETE_SUCCESS = 'BILLING_CONFIGURATION_DELETE_SUCCESS',
  BILLING_CONFIGURATION_DELETE_FAILURE = 'BILLING_CONFIGURATION_DELETE_FAILURE',
  CALCULATED_BILLS_LIST_SUCCESS = 'CALCULATED_BILLS_LIST_SUCCESS',
  CALCULATED_BILLS_LIST_FAILURE = 'CALCULATED_BILLS_LIST_FAILURE',
  CALCULATED_BILLS_LIST_LOADING = 'CALCULATED_BILLS_LIST_LOADING',
}

export type BillingAction =
  | {
      type: BILLING_TYPE.BILLING_CONFIGURATION_LIST_LOADING;
    }
  | {
      type: BILLING_TYPE.BILLING_CONFIGURATION_LIST_SUCCESS;
      payload: BillingConfig[];
    }
  | {
      type: BILLING_TYPE.BILLING_CONFIGURATION_LIST_FAILURE;
    }
  | {
      type: BILLING_TYPE.BILLING_CONFIGURATION_DELETE_LOADING;
    }
  | {
      type: BILLING_TYPE.BILLING_CONFIGURATION_DELETE_SUCCESS;
      payload: String;
    }
  | {
      type: BILLING_TYPE.BILLING_CONFIGURATION_DELETE_FAILURE;
    }
  | {
      type: BILLING_TYPE.CALCULATED_BILLS_LIST_LOADING;
    }
  | {
      type: BILLING_TYPE.CALCULATED_BILLS_LIST_SUCCESS;
      payload: CalculatedBills[];
    }
  | {
      type: BILLING_TYPE.CALCULATED_BILLS_LIST_FAILURE;
    };
