import React from 'react';
import useGetInputTexts from './useGetInputTexts';
import { TextFieldProps } from '@athonet/ui/components/Input/TextField';
import { FieldProps } from 'formik';
import DebouncedTextField from 'components/Input/DebouncedTextfield';
import useIsRequiredField from './useIsRequiredField';
import { useBaseFormikContext } from '../BaseFormik';

export type TextFieldComponentProps = FieldProps &
  TextFieldProps & {
    /** override the default prefix for the translations */
    prefix: string;
  };

const TextFieldComponent: React.FC<TextFieldComponentProps> = (props) => {
  const {
    form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
    field,
    meta,
    prefix,
    children,
    onChange,
    ...textFieldProps
  } = props;

  const { validationSchema, validationPrefix } = useBaseFormikContext();
  const inputTexts = useGetInputTexts(prefix || validationPrefix);
  const isRequired = useIsRequiredField(validationSchema, field.name);

  return (
    <DebouncedTextField
      size="small"
      name={field.name}
      value={field.value}
      required={isRequired}
      {...inputTexts(field.name, Boolean(errors[field.name]), touched[field.name])}
      {...textFieldProps}
      onChange={(evt) => {
        setFieldValue(field.name, evt.target.value);
        setFieldTouched(field.name);
        if (onChange) onChange(evt);
      }}
      disabled={isSubmitting || textFieldProps.disabled}
    />
  );
};

export default TextFieldComponent;
