import bootstrapReducer, { BootstrapState } from './reducers/bootstrap';
import loginReducer, { LoginState } from './reducers/login';
import landingPageReducer, { LandingState } from './reducers/landing';
import tenantsPageReducer, { TenantsState } from './reducers/tenants';
import sitesPageReducer, { SitesState } from './reducers/sites';
import usersPageReducer, { UsersState } from './reducers/users';
import nodesReducer, { NodesState } from './reducers/nodes';
import segmentsReducer, { SegmentsState } from './reducers/segments';
import qosProfilesReducer, { QosProfilesState } from './reducers/qosProfiles';
import usimsReducer, { UsimsState } from './reducers/usims';
import usimProfilesReducer, { UsimProfilesState } from './reducers/usimProfiles';
import usimOrdersReducer, { UsimOrdersState } from './reducers/usimOrders';
import gpgKeysReducer, { GpgKeysState } from './reducers/gpgKeys';
import toastReducer, { ToastState } from './reducers/toast';
import rolesReducer, { RolesState } from './reducers/roles';
import modalReducer, { ModalState } from './reducers/modal';
import cdrReportsSourceNetworkReducer, { CdrReportsSourceNetworkState } from './reducers/cdrReports/sourceNetwork';
import cdrReportsTrafficVolumeReducer, { CdrReportsTrafficVolumeState } from './reducers/cdrReports/trafficVolume';
import cdrReportsImeiChangesReducer, { CdrReportsImeiChangesState } from './reducers/cdrReports/imeiChanges';
import cdrReportsTrafficDifferenceReducer, {
  CdrReportsTrafficDifferenceState,
} from './reducers/cdrReports/trafficDifference';
import billingReducer, { BillingState } from './reducers/billing';
import bulkOperationsReducer, { BulkOperationsState } from './reducers/bulkOperations';
import localeReducer, { LocaleState } from './reducers/locale';
import adminConfigReducer, { AdminConfigState } from './reducers/adminConfig';
import usims5gReducer, { Usims5gState } from './reducers/usims5g';
import plmnsPageReducer, { PlmnsState } from './reducers/plmns';
import serviceProfilesReducer, { ServiceProfileState } from './reducers/serviceProfiles';
import {
  Action,
  AnyAction,
  CombinedState,
  Dispatch,
  ThunkAction,
  ThunkDispatch,
  configureStore,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export interface State {
  bootstrap: BootstrapState;
  locale: LocaleState;
  user: LoginState;
  landing: LandingState;
  tenants: TenantsState;
  sites: SitesState;
  users: UsersState;
  nodes: NodesState;
  plmns: PlmnsState;
  serviceProfiles: ServiceProfileState;
  /* NOTE: CC-1285 */
  segments: SegmentsState;
  qosProfiles: QosProfilesState;
  usims: UsimsState;
  usims5g: Usims5gState;
  usimProfiles: UsimProfilesState;
  usimOrders: UsimOrdersState;
  gpgKeys: GpgKeysState;
  cdrReportsSourceNetwork: CdrReportsSourceNetworkState;
  cdrReportsTrafficVolume: CdrReportsTrafficVolumeState;
  cdrReportsImeiChanges: CdrReportsImeiChangesState;
  cdrReportsTrafficDifference: CdrReportsTrafficDifferenceState;
  cdrBilling: BillingState;
  roles: RolesState;
  toast: ToastState;
  modal: ModalState;
  bulkOperations: BulkOperationsState;
  adminConfig: AdminConfigState;
}

const rootReducer = {
  bootstrap: bootstrapReducer,
  locale: localeReducer,
  user: loginReducer,
  landing: landingPageReducer,
  tenants: tenantsPageReducer,
  sites: sitesPageReducer,
  users: usersPageReducer,
  nodes: nodesReducer,
  plmns: plmnsPageReducer,
  serviceProfiles: serviceProfilesReducer,
  segments: segmentsReducer,
  qosProfiles: qosProfilesReducer,
  usims: usimsReducer,
  usims5g: usims5gReducer,
  usimProfiles: usimProfilesReducer,
  usimOrders: usimOrdersReducer,
  gpgKeys: gpgKeysReducer,
  roles: rolesReducer,
  toast: toastReducer,
  modal: modalReducer,
  cdrReportsSourceNetwork: cdrReportsSourceNetworkReducer,
  cdrReportsTrafficVolume: cdrReportsTrafficVolumeReducer,
  cdrReportsImeiChanges: cdrReportsImeiChangesReducer,
  cdrReportsTrafficDifference: cdrReportsTrafficDifferenceReducer,
  cdrBilling: billingReducer,
  bulkOperations: bulkOperationsReducer,
  adminConfig: adminConfigReducer,
};

const store = configureStore<State>({
  reducer: rootReducer,
});

export type AppDispatch = Dispatch<AnyAction> &
  ThunkDispatch<CombinedState<State>, null, AnyAction> &
  ThunkDispatch<CombinedState<State>, undefined, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
//export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export type AppThunk = ThunkAction<void, CombinedState<State>, unknown, Action<string>>;

export default store;
