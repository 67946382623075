import React from 'react';
import { FieldProps } from 'formik';
import useGetInputTexts from './useGetInputTexts';
import useIsRequiredField from './useIsRequiredField';
import { Autocomplete, AutocompleteProps } from '@athonet/ui/components/Input/Autocomplete';
import { useBaseFormikContext } from '../BaseFormik';

export type AutocompleteComponentProps = FieldProps &
  AutocompleteProps<any, any, any, any> & {
    /** override the default prefix for the translations */
    prefix: string;
  };

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = (props) => {
  const {
    form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
    field,
    meta,
    prefix,
    children,
    onChange,
    ...autoCompleteProps
  } = props;

  const { validationSchema, validationPrefix } = useBaseFormikContext();

  const inputTexts = useGetInputTexts(prefix || validationPrefix);
  const isRequired = useIsRequiredField(validationSchema, field.name);

  return (
    <Autocomplete
      required={isRequired}
      size="small"
      name={field.name}
      value={field.value}
      {...inputTexts(field.name, Boolean(errors[field.name]), touched[field.name])}
      {...autoCompleteProps}
      disabled={isSubmitting || autoCompleteProps.disabled}
      onChange={(_, v, r, d) => {
        setFieldValue(field.name, v);
        setFieldTouched(field.name);
        if (onChange) onChange(_, v, r, d);
      }}
    />
  );
};

export default AutocompleteComponent;
