import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { reset, getUsers, getUser, deleteUser, editUser } from 'store/actions/users';
import { getTenantsWithSubtenants } from 'store/actions/tenants';
import { getRoles } from 'store/actions/roles';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Users';
import List, { ColumnShape } from 'components/List';
import Create from 'components/Edit/Users/Create';
import Edit from 'components/Edit/Users/Edit';
import DropdownTools from 'components/DropdownTools';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { ic_people as ico_change_tenant } from 'react-icons-kit/md/ic_people';
import Avatar from '@material-ui/core/Avatar';
import { U_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import config from 'config';
import { useUserSelector } from 'store/selectors/user';
import { useUsersFiltersSelector, useUsersListSelector } from 'store/selectors/users';
import { useTenantsWithSubtenantsSelector } from 'store/selectors/tenants';
import { DATA_LIFECYCLE } from 'store/reducers';
import { useRolesListSelector } from 'store/selectors/roles';
import { generateSelectOptions } from 'utils/forms';
import { SubTenant } from 'store/models/tenant';
import { Role } from 'store/models/role';
import { User } from 'store/models/user';
import { openManageTenantsModal } from 'store/actions/modal';
import { RESOURCE } from 'store/models/modal';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';

export default function Users() {
  const bootstrap = useBootstrapSelector();
  const { locale, formatMessage } = useIntl();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const user = useUserSelector();
  const dispatch = useDispatch();
  const usersList = useUsersListSelector();
  const usersFilters = useUsersFiltersSelector();
  const tenantsWithSubtenants = useTenantsWithSubtenantsSelector();
  const rolesList = useRolesListSelector();
  const { confirmationDialogOpen } = useOverlay();

  // TODO: this should be a hook
  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    // NOTE: getUsers is happening on init because of the handleSorting function being called by the list straight on load.
    dispatch(getTenantsWithSubtenants());
    dispatch(getRoles());

    return () => {
      dispatch(reset()); // clear redux when leaves page for best performances
    };
  }, [dispatch]);

  const getSelectOptions = useMemo(() => {
    if (tenantsWithSubtenants.state !== DATA_LIFECYCLE.SUCCESS && rolesList.state !== DATA_LIFECYCLE.SUCCESS) {
      return {};
    }

    const [roles, tenants] = generateSelectOptions<Role | SubTenant>({
      showAll: false,
      data: [
        {
          items: rolesList.data.data,
          transform: (role) => ({
            label: role.name,
            value: role.name,
          }),
        },
        {
          items: tenantsWithSubtenants.data.data,
          transform: (tenant) => ({
            label: tenant.name,
            value: tenant.id,
          }),
        },
      ],
    });

    return {
      // TODO: temporary fix to comply with autocomplete props
      roles: roles.map((role) => ({ ...role, value: role.value.toString() })),
      tenants,
    };
  }, [rolesList, tenantsWithSubtenants]);

  const handleRefresh = useCallback(() => {
    dispatch(getUsers({}));
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(
        getUsers({
          page,
        })
      );
    },
    [dispatch]
  );

  const handleRowsPerPageChange = useCallback(() => {
    dispatch(getUsers({}));
  }, [dispatch]);

  const handleSortChange = useCallback(
    (orderQuery) => {
      dispatch(
        getUsers({
          sortBy: orderQuery,
        })
      );
    },
    [dispatch]
  );

  const handleDeleteUser = useCallback(
    async (id) => {
      try {
        await dispatch(deleteUser(id));
        dispatch(showSuccessToast());
      } catch (e) {
        dispatch(showErrorToast());
      }
    },
    [dispatch]
  );

  const handleGetUser = useCallback(async () => {
    if (idEdit) {
      const res = await dispatch(getUser(idEdit));
      return res;
    }
  }, [dispatch, idEdit]);

  const handleEditUser = useCallback(
    async (values, callback) => {
      if (idEdit) {
        try {
          await dispatch(editUser(idEdit, values));
          callback(true);
        } catch (e) {
          callback(false);
        }
      } else {
        callback(false);
      }
    },
    [dispatch, idEdit]
  );

  // TODO: modals can be rendered in the same manner the toasts are rendered
  const openEdit = (id: string) => {
    setIsEditOpen(true);
    setIdEdit(id);
  };

  const closeEdit = () => {
    setIsEditOpen(false);
    setIdEdit('');
  };

  const openDelete = useCallback(
    ({ id, fullname }: User) => {
      confirmationDialogOpen({
        title: formatMessage({ id: 'users.deleteUser.confirm.title' }),
        description: formatMessage({ id: 'users.deleteUser.confirm' }),
        alertMessage: formatMessage({ id: 'users.itemsAffected' }, { element: fullname }),
        continueButtonText: formatMessage({ id: 'users.deleteUser.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => handleDeleteUser(id),
        severity: 'danger',
        dataTestid: 'confirm-delete-user',
      });
    },
    [confirmationDialogOpen, formatMessage, handleDeleteUser]
  );

  const openManageTenants = useCallback(
    (userId) => {
      dispatch(
        openManageTenantsModal({
          resource: RESOURCE.USERS,
          id: userId,
          cb: handleRefresh,
        })
      );
    },
    [dispatch, handleRefresh]
  );

  const getDropdownTools = useCallback(
    (rowData) => {
      const options = checkPermissionsList(
        user?.permissions || [],
        [
          {
            icon: ico_edit,
            name: 'edit-user',
            label: formatMessage({ id: 'users.editUser' }),
            action: () => openEdit(rowData.id),
            disabled: rowData.id === user?.id,
            permissions: [U_PERMISSIONS.UPDATE_USER],
          },
          {
            icon: ico_change_tenant,
            name: 'edit-user-tenant',
            label: formatMessage({ id: 'users.userTenants' }),
            action: () => openManageTenants(rowData.id),
            disabled: false,
            permissions: [U_PERMISSIONS.UPDATE_USER],
          },
          {
            icon: ico_delete,
            name: 'delete-user',
            label: formatMessage({ id: 'users.deleteUser' }),
            action: () => openDelete(rowData),
            disabled: rowData.id === user?.id,
            permissions: [U_PERMISSIONS.DELETE_USER],
          },
        ],
        false
      );

      return <DropdownTools options={options} />;
    },
    [formatMessage, openDelete, openManageTenants, user?.id, user?.permissions]
  );

  const columns: ColumnShape<User>[] = useMemo(
    () =>
      checkPermissionsList(
        [user?.tenant_type],
        [
          {
            key: 'image',
            title: '',
            dataKey: 'image',
            cellRenderer: ({ cellData: image }: { cellData: string }) => {
              return <Avatar alt={formatMessage({ id: 'users.table.avatar' })} src={image} />;
            },
            sortable: false,
            secret: true,
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          },
          {
            key: 'id',
            title: 'ID',
            dataKey: 'id',
            width: 200,
            maxWidth: 300,
            minWidth: 100,
            visible: false,
          },
          {
            key: 'fullname',
            title: formatMessage({ id: 'users.table.fullname' }),
            dataKey: 'fullname',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
          },
          {
            key: 'name',
            title: formatMessage({ id: 'users.table.username' }),
            dataKey: 'name',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
          },
          {
            key: 'email',
            title: formatMessage({ id: 'users.table.email' }),
            dataKey: 'email',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            minWidth: 100,
            visible: false,
            cellRenderer: ({ cellData, rowData }) => {
              if (rowData.realm === AUTH_PROVIDER.ENTERPRISE) {
                return cellData;
              }
              return '-';
            },
          },
          {
            key: 'realm',
            title: formatMessage({ id: 'users.table.realm' }),
            dataKey: 'realm',
            sortable: true,
            width: 100,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
            cellRenderer: ({ cellData }: { cellData: AUTH_PROVIDER }) => {
              return (
                <Chip
                  id="realm"
                  size="small"
                  color={cellData === AUTH_PROVIDER.ENTERPRISE ? 'default' : 'info'}
                  label={formatMessage({ id: `authProvider.${cellData}` })}
                />
              );
            },
          },
          {
            key: 'tenant',
            title: formatMessage({ id: 'users.table.tenant' }),
            dataKey: 'tenant',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
          },
          {
            key: 'created_at',
            title: formatMessage({ id: 'users.table.creation' }),
            dataKey: 'created_at',
            sortable: true,
            width: 140,
            maxWidth: 140,
            minWidth: 140,
            cellRenderer: ({ cellData }: { cellData: string }) =>
              cellData && <Moment format={localizedConfig.fullDateFormat}>{cellData}</Moment>,
          },
          {
            key: 'tools',
            title: '',
            dataKey: 'tools',
            width: 60,
            maxWidth: 60,
            minWidth: 60,
            secret: true, // secret used to hide from columns management panel
            cellRenderer: ({ rowData }: { rowData: User }) => {
              return getDropdownTools(rowData);
            },
          },
        ]
      ),
    [formatMessage, getDropdownTools, localizedConfig.fullDateFormat, user]
  );

  return (
    <>
      <List
        columns={columns}
        totalRows={usersList.data.total}
        data={usersList.data.data}
        filters={usersFilters}
        onOrderChange={handleSortChange}
        loadingState={usersList.state}
        {...(checkPermissionToUse(user, U_PERMISSIONS.CREATE_USER) && {
          createComponent: <Create dataOptions={getSelectOptions} />,
        })}
        filtersComponent={<Filters />}
        onPageChange={handlePageChange}
        toolbar={{
          actions: false,
        }}
        page={usersList.data.page}
        rowsPerPage={bootstrap?.pageLimit}
        onRowsPerPageChange={handleRowsPerPageChange}
        onRefresh={handleRefresh}
      />

      {isEditOpen && (
        <Edit getData={handleGetUser} dataOptions={getSelectOptions} onEdit={handleEditUser} onClose={closeEdit} />
      )}
    </>
  );
}
