import { useCallback, useMemo } from 'react';
import { string } from 'yup';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultValue } from 'store/models/filters';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { FiltersDrawer, FiltersDrawerItem } from '../Drawer';
import { getNodes } from 'store/actions/nodes';

export default function Nodes() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.nodes.filters);

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'name',
        label: formatMessage({ id: 'nodes.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: filters ? filters['display_name'] : undefined,
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
    ],
    [formatMessage, filters]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(getNodes({ filterBy: filtersValues })), [dispatch]);

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
