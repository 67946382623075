// FileSaver
import { saveAs } from 'file-saver';

import { AxiosRequestConfig } from 'axios';
import { Thunk } from '.';

// App Utils
import { fetchData } from './fetchData';
import { showErrorToast, showLoadingToast, showSuccessToast } from './toast';

export function exportViews<D>({
  fileName,
  url,
  data,
  filtersQuery,
}: {
  fileName: string;
  url: string;
  data: D;
  filtersQuery: string;
}): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${url.replace('{filters}', filtersQuery)}`,
      method: 'POST',
      responseType: 'blob',
      data,
    };

    dispatch(showLoadingToast());

    try {
      const exportBlob = await dispatch(fetchData<Blob | string>(options));
      saveAs(exportBlob, fileName);
      dispatch(showSuccessToast());
    } catch (e) {
      dispatch(showErrorToast());
    }
  };
}
