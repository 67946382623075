import moment, { Moment } from 'moment';
import { CdrAvailabilityDates, CdrRanking, CdrSchema } from 'store/models/cdr';

export type CdrRankingState = CdrRanking<string, number>;

export type CdrSchemaState = CdrSchema;

export function getMomentAvailabilityDates(
  availabilityDates: CdrAvailabilityDates<string>
): CdrAvailabilityDates<Moment> {
  return {
    day: {
      min: availabilityDates.day.min ? moment(availabilityDates.day.min) : null,
      max: availabilityDates.day.max ? moment(availabilityDates.day.max) : null,
    },
    week: {
      min: availabilityDates.week.min ? moment(availabilityDates.week.min) : null,
      max: availabilityDates.week.max ? moment(availabilityDates.week.max) : null,
    },
    month: {
      min: availabilityDates.month.min ? moment(availabilityDates.month.min) : null,
      max: availabilityDates.month.max ? moment(availabilityDates.month.max) : null,
    },
    year: {
      min: availabilityDates.year.min ? moment(availabilityDates.year.min) : null,
      max: availabilityDates.year.max ? moment(availabilityDates.year.max) : null,
    },
  };
}
