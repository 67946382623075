import { useSelector } from 'react-redux';

export function usePlmnsSelector() {
  const plmns = useSelector((state) => state.plmns);
  return plmns;
}

export function usePlmnsListSelector() {
  const list = useSelector((state) => state.plmns.list);
  return list;
}
