import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { createServiceProfile, editServiceProfile } from 'store/actions/serviceProfiles';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { ServiceProfile } from 'store/models/serviceProfile';
import { useNodeEntitySelector } from 'store/selectors/nodes';

export function EditServiceProfile({ serviceProfile }: { serviceProfile?: ServiceProfile }) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const nodeEntity = useNodeEntitySelector();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().trim().required(),
      }),
    []
  );

  const initials = useMemo(
    () => ({
      name: serviceProfile?.name || '',
    }),
    [serviceProfile?.name]
  );

  const handleCreateServiceProfile = useCallback(
    async (values) => {
      if (!nodeEntity.data) {
        return;
      }

      setLoading(true);
      const errorRes = serviceProfile
        ? await dispatch(
            editServiceProfile({ serviceProfileId: serviceProfile.id, data: values, nodeId: nodeEntity.data.id })
          )
        : await dispatch(createServiceProfile({ data: values, nodeId: nodeEntity.data.id }));
      setLoading(false);

      if (!errorRes) {
        dialogClose();
      } else {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, nodeEntity, serviceProfile]
  );

  return (
    <Formik
      initialValues={initials}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleCreateServiceProfile}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, touched }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <DialogContent>
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <Field name="name" key="name">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      label={formatMessage({ id: 'serviceProfiles.form.name.label' })}
                      placeholder={formatMessage({ id: 'serviceProfiles.form.name.placeholder' })}
                      error={Boolean(errors['name'] && touched['name'])}
                      {...(Boolean(errors['name'] && touched['name']) && {
                        helperText: formatMessage({ id: 'serviceProfiles.form.name.error' }),
                      })}
                    />
                  )}
                </Field>
              </Stack>
            </DialogContent>
            {error && (
              <Box sx={{ mx: 2 }}>
                <Alert
                  severity="error"
                  title={formatMessage({ id: 'common.error' })}
                  message={
                    serviceProfile
                      ? formatMessage({ id: 'serviceProfiles.edit.error.title' }, { error })
                      : formatMessage({ id: 'serviceProfiles.create.error.title' }, { error })
                  }
                />
              </Box>
            )}
            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  loading={loading}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={
                    error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                  }
                  loading={loading}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
