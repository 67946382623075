import { Moment } from 'moment';
import {
  CdrDateRange,
  CdrFilter,
  CdrRankingGroupBy,
  CdrPeriod,
  CdrRanking,
  CdrSchema,
  CdrTrafficVolumeSeries,
  CdrTrafficVolumeTotal,
  CdrRankingCalendarDate,
  CdrImeiChangesSeries,
  CdrImeiChangesTotal,
  CdrTrafficVolumeMetricsField,
  CdrImeiChangesMetricsField,
  CdrChangesHistory,
  CdrSearch,
  SourceNetwork,
  SourceNetworkAggregation,
  CdrAvailabilityDates,
  CdrTrafficDifferenceTotal,
  CDR_SOURCE_NETWORKS_AGGREGATION_TYPE,
  CdrTrafficDifferenceSeries,
  CdrTimeSeriesSchema,
  CdrAvailabilityPeriodRange,
  CdrTimeSeriesQuery,
  CdrTimeSeriesQueryData,
  CdrTimeSeriesData,
} from 'store/models/cdr';
import { Thunk } from '..';
import { AxiosRequestConfig } from 'axios';
import { fetchData } from '../fetchData';
import { showErrorToast } from '../toast';
import { sentryLogError } from 'sentry';

export type CdrSeriesPayload = {
  start_date: Moment;
  end_date: Moment;
  period: CdrPeriod;
  group_by?: string;
  group_filter?: string;
  source_networks_aggregation: SourceNetworkAggregation[];
};

export type CdrRankingPayload = {
  date: Moment;
  period: CdrPeriod;
  metric?: string;
  group_by?: string;
  source_networks_aggregation: SourceNetworkAggregation[];
};

export type CdrTotalPayload = {
  start_date: Moment;
  end_date: Moment;
  period: CdrPeriod;
  group_by?: string;
  group_filter?: string;
  source_networks_aggregation: SourceNetworkAggregation[];
};

export type CdrAggregationPayload<T extends CdrTotalPayload | CdrSeriesPayload | CdrRankingPayload> = Omit<
  T,
  'source_networks_aggregation'
> & {
  sourceNetworkAggregations: {
    type: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE;
    source_networks_aggregation: T['source_networks_aggregation'];
  }[];
};

export type CdrImeiChangesHistoryPayload = {
  start_date: Moment;
  end_date: Moment;
  period: CdrPeriod;
  imsi: string;
  source_networks_aggregation: SourceNetworkAggregation[];
};

export type CdrSearchPayload = {
  field: string;
  value: string;
  source_networks: SourceNetwork[];
};

export type GetCdrSeriesActionArgs<T> = {
  payload: T;
  actions: { loadingAction: CDR_ACTION_TYPE; successAction: CDR_ACTION_TYPE; failureAction: CDR_ACTION_TYPE };
};

export type GetCdrSearchAction = (payload: CdrSearchPayload) => Thunk<Promise<CdrSearch | undefined>>;

export enum CDR_ACTION_TYPE {
  SOURCE_NETWORK_SET_ACTIVE = 'CDR_SOURCE_NETWORK_SET_ACTIVE',

  TRAFFIC_VOLUME_AVAILABILITY_DATES_LOADING = 'CDR_TRAFFIC_VOLUME_AVAILABILITY_DATES_LOADING',
  TRAFFIC_VOLUME_AVAILABILITY_DATES_SUCCESS = 'CDR_TRAFFIC_VOLUME_AVAILABILITY_DATES_SUCCESS',
  TRAFFIC_VOLUME_AVAILABILITY_DATES_FAILURE = 'CDR_TRAFFIC_VOLUME_AVAILABILITY_DATES_FAILURE',
  TRAFFIC_VOLUME_TOTAL_LOADING = 'CDR_TRAFFIC_VOLUME_TOTAL_LOADING',
  TRAFFIC_VOLUME_TOTAL_SUCCESS = 'CDR_TRAFFIC_VOLUME_TOTAL_SUCCESS',
  TRAFFIC_VOLUME_TOTAL_FAILURE = 'CDR_TRAFFIC_VOLUME_TOTAL_FAILURE',
  TRAFFIC_VOLUME_TREND_SERIES_LOADING = 'CDR_TRAFFIC_VOLUME_TREND_SERIES_LOADING',
  TRAFFIC_VOLUME_TREND_SERIES_SUCCESS = 'CDR_TRAFFIC_VOLUME_TREND_SERIES_SUCCESS',
  TRAFFIC_VOLUME_TREND_SERIES_FAILURE = 'CDR_TRAFFIC_VOLUME_TREND_SERIES_FAILURE',
  TRAFFIC_VOLUME_RANKING_SERIES_LOADING = 'CDR_TRAFFIC_VOLUME_RANKING_SERIES_LOADING',
  TRAFFIC_VOLUME_RANKING_SERIES_SUCCESS = 'CDR_TRAFFIC_VOLUME_RANKING_SERIES_SUCCESS',
  TRAFFIC_VOLUME_RANKING_SERIES_FAILURE = 'CDR_TRAFFIC_VOLUME_RANKING_SERIES_FAILURE',
  TRAFFIC_VOLUME_SCHEMA_LOADING = 'CDR_TRAFFIC_VOLUME_SCHEMA_LOADING',
  TRAFFIC_VOLUME_SCHEMA_SUCCESS = 'CDR_TRAFFIC_VOLUME_SCHEMA_SUCCESS',
  TRAFFIC_VOLUME_SCHEMA_FAILURE = 'CDR_TRAFFIC_VOLUME_SCHEMA_FAILURE',
  TRAFFIC_VOLUME_RANKING_LOADING = 'CDR_TRAFFIC_VOLUME_RANKING_LOADING',
  TRAFFIC_VOLUME_RANKING_SUCCESS = 'CDR_TRAFFIC_VOLUME_RANKING_SUCCESS',
  TRAFFIC_VOLUME_RANKING_FAILURE = 'CDR_TRAFFIC_VOLUME_RANKING_FAILURE',
  TRAFFIC_VOLUME_SET_DATE_RANGE = 'CDR_TRAFFIC_VOLUME_SET_DATE_RANGE',
  TRAFFIC_VOLUME_SET_PERIOD = 'CDR_TRAFFIC_VOLUME_SET_PERIOD',
  TRAFFIC_VOLUME_SET_FILTER = 'CDR_TRAFFIC_VOLUME_SET_FILTER',
  TRAFFIC_VOLUME_SET_RANKING = 'CDR_TRAFFIC_VOLUME_SET_RANKING',
  TRAFFIC_VOLUME_SET_RANKING_GROUP_BY = 'CDR_TRAFFIC_VOLUME_SET_RANKING_GROUP_BY',
  TRAFFIC_VOLUME_SET_RANKING_METRIC = 'CDR_TRAFFIC_VOLUME_SET_RANKING_METRIC',
  TRAFFIC_VOLUME_SET_RANKING_CALENDAR_DATE = 'CDR_TRAFFIC_VOLUME_SET_RANKING_CALENDAR_DATE',
  TRAFFIC_VOLUME_FLUSH = 'TRAFFIC_VOLUME_FLUSH',
  TRAFFIC_VOLUME_TIMESERIES_SCHEMA_LOADING = 'TRAFFIC_VOLUME_TIMESERIES_SCHEMA_LOADING',
  TRAFFIC_VOLUME_TIMESERIES_SCHEMA_SUCCESS = 'TRAFFIC_VOLUME_TIMESERIES_SCHEMA_SUCCESS',
  TRAFFIC_VOLUME_TIMESERIES_SCHEMA_FAILURE = 'TRAFFIC_VOLUME_TIMESERIES_SCHEMA_FAILURE',
  TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_LOADING = 'TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_LOADING',
  TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_SUCCESS = 'TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_SUCCESS',
  TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_FAILURE = 'TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_FAILURE',
  TRAFFIC_VOLUME_TIMESERIES_SET_QUERY = 'TRAFFIC_VOLUME_TIMESERIES_SET_QUERY',

  IMEI_CHANGES_AVAILABILITY_DATES_LOADING = 'CDR_IMEI_CHANGES_AVAILABILITY_DATES_LOADING',
  IMEI_CHANGES_AVAILABILITY_DATES_SUCCESS = 'CDR_IMEI_CHANGES_AVAILABILITY_DATES_SUCCESS',
  IMEI_CHANGES_AVAILABILITY_DATES_FAILURE = 'CDR_IMEI_CHANGES_AVAILABILITY_DATES_FAILURE',
  IMEI_CHANGES_TREND_SERIES_LOADING = 'CDR_IMEI_CHANGES_TREND_SERIES_LOADING',
  IMEI_CHANGES_TREND_SERIES_SUCCESS = 'CDR_IMEI_CHANGES_TREND_SERIES_SUCCESS',
  IMEI_CHANGES_TREND_SERIES_FAILURE = 'CDR_IMEI_CHANGES_TREND_SERIES_FAILURE',
  IMEI_CHANGES_RANKING_SERIES_LOADING = 'CDR_IMEI_CHANGES_RANKING_SERIES_LOADING',
  IMEI_CHANGES_RANKING_SERIES_SUCCESS = 'CDR_IMEI_CHANGES_RANKING_SERIES_SUCCESS',
  IMEI_CHANGES_RANKING_SERIES_FAILURE = 'CDR_IMEI_CHANGES_RANKING_SERIES_FAILURE',
  IMEI_CHANGES_TOTAL_LOADING = 'CDR_IMEI_CHANGES_TOTAL_LOADING',
  IMEI_CHANGES_TOTAL_SUCCESS = 'CDR_IMEI_CHANGES_TOTAL_SUCCESS',
  IMEI_CHANGES_TOTAL_FAILURE = 'CDR_IMEI_CHANGES_TOTAL_FAILURE',
  IMEI_CHANGES_SCHEMA_LOADING = 'CDR_IMEI_CHANGES_SCHEMA_LOADING',
  IMEI_CHANGES_SCHEMA_SUCCESS = 'CDR_IMEI_CHANGES_SCHEMA_SUCCESS',
  IMEI_CHANGES_SCHEMA_FAILURE = 'CDR_IMEI_CHANGES_SCHEMA_FAILURE',
  IMEI_CHANGES_RANKING_LOADING = 'CDR_IMEI_CHANGES_RANKING_LOADING',
  IMEI_CHANGES_RANKING_SUCCESS = 'CDR_IMEI_CHANGES_RANKING_SUCCESS',
  IMEI_CHANGES_RANKING_FAILURE = 'CDR_IMEI_CHANGES_RANKING_FAILURE',
  IMEI_CHANGES_HISTORY_LOADING = 'CDR_IMEI_CHANGES_HISTORY_LOADING',
  IMEI_CHANGES_HISTORY_SUCCESS = 'CDR_IMEI_CHANGES_HISTORY_SUCCESS',
  IMEI_CHANGES_HISTORY_FAILURE = 'CDR_IMEI_CHANGES_HISTORY_FAILURE',
  IMEI_CHANGES_HISTORY_FLUSH = 'CDR_IMEI_CHANGES_HISTORY_FLUSH',
  IMEI_CHANGES_SET_DATE_RANGE = 'CDR_IMEI_CHANGES_SET_DATE_RANGE',
  IMEI_CHANGES_SET_PERIOD = 'CDR_IMEI_CHANGES_SET_PERIOD',
  IMEI_CHANGES_SET_FILTER = 'CDR_IMEI_CHANGES_SET_FILTER',
  IMEI_CHANGES_SET_RANKING_METRIC = 'CDR_IMEI_CHANGES_SET_RANKING_METRIC',
  IMEI_CHANGES_SET_RANKING_GROUP_BY = 'CDR_IMEI_CHANGES_SET_RANKING_GROUP_BY',
  IMEI_CHANGES_SET_RANKING_CALENDAR_DATE = 'CDR_IMEI_CHANGES_SET_RANKING_CALENDAR_DATE',
  IMEI_CHANGES_FLUSH = 'IMEI_CHANGES_FLUSH',
  IMEI_CHANGES_TIMESERIES_SCHEMA_LOADING = 'IMEI_CHANGES_TIMESERIES_SCHEMA_LOADING',
  IMEI_CHANGES_TIMESERIES_SCHEMA_SUCCESS = 'IMEI_CHANGES_TIMESERIES_SCHEMA_SUCCESS',
  IMEI_CHANGES_TIMESERIES_SCHEMA_FAILURE = 'IMEI_CHANGES_TIMESERIES_SCHEMA_FAILURE',
  IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_LOADING = 'IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_LOADING',
  IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_SUCCESS = 'IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_SUCCESS',
  IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_FAILURE = 'IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_FAILURE',
  IMEI_CHANGES_TIMESERIES_SET_QUERY = 'IMEI_CHANGES_TIMESERIES_SET_QUERY',

  TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_LOADING = 'CDR_TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_LOADING',
  TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_SUCCESS = 'CDR_TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_SUCCESS',
  TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_FAILURE = 'CDR_TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_FAILURE',
  TRAFFIC_DIFFERENCE_TOTALS_LOADING = 'CDR_TRAFFIC_DIFFERENCE_TOTALS_LOADING',
  TRAFFIC_DIFFERENCE_TOTALS_SUCCESS = 'CDR_TRAFFIC_DIFFERENCE_TOTALS_SUCCESS',
  TRAFFIC_DIFFERENCE_TOTALS_FAILURE = 'CDR_TRAFFIC_DIFFERENCE_TOTALS_FAILURE',
  TRAFFIC_DIFFERENCE_TREND_SERIES_LOADING = 'CDR_TRAFFIC_DIFFERENCE_TREND_SERIES_LOADING',
  TRAFFIC_DIFFERENCE_TREND_SERIES_SUCCESS = 'CDR_TRAFFIC_DIFFERENCE_TREND_SERIES_SUCCESS',
  TRAFFIC_DIFFERENCE_TREND_SERIES_FAILURE = 'CDR_TRAFFIC_DIFFERENCE_TREND_SERIES_FAILURE',
  TRAFFIC_DIFFERENCE_RANKING_SERIES_LOADING = 'CDR_TRAFFIC_DIFFERENCE_RANKING_SERIES_LOADING',
  TRAFFIC_DIFFERENCE_RANKING_SERIES_SUCCESS = 'CDR_TRAFFIC_DIFFERENCE_RANKING_SERIES_SUCCESS',
  TRAFFIC_DIFFERENCE_RANKING_SERIES_FAILURE = 'CDR_TRAFFIC_DIFFERENCE_RANKING_SERIES_FAILURE',
  TRAFFIC_DIFFERENCE_RANKING_LOADING = 'CDR_TRAFFIC_DIFFERENCE_RANKING_LOADING',
  TRAFFIC_DIFFERENCE_RANKING_SUCCESS = 'CDR_TRAFFIC_DIFFERENCE_RANKING_SUCCESS',
  TRAFFIC_DIFFERENCE_RANKING_FAILURE = 'CDR_TRAFFIC_DIFFERENCE_RANKING_FAILURE',
  TRAFFIC_DIFFERENCE_SET_DATE_RANGE = 'CDR_TRAFFIC_DIFFERENCE_SET_DATE_RANGE',
  TRAFFIC_DIFFERENCE_SET_PERIOD = 'CDR_TRAFFIC_DIFFERENCE_SET_PERIOD',
  TRAFFIC_DIFFERENCE_SET_FILTER = 'CDR_TRAFFIC_DIFFERENCE_SET_FILTER',
  TRAFFIC_DIFFERENCE_SET_RANKING = 'CDR_TRAFFIC_DIFFERENCE_SET_RANKING',
  TRAFFIC_DIFFERENCE_SET_RANKING_GROUP_BY = 'CDR_TRAFFIC_DIFFERENCE_SET_RANKING_GROUP_BY',
  TRAFFIC_DIFFERENCE_SET_RANKING_METRIC = 'CDR_TRAFFIC_DIFFERENCE_SET_RANKING_METRIC',
  TRAFFIC_DIFFERENCE_SET_RANKING_CALENDAR_DATE = 'CDR_TRAFFIC_DIFFERENCE_SET_RANKING_CALENDAR_DATE',
  TRAFFIC_DIFFERENCE_FLUSH = 'TRAFFIC_DIFFERENCE_FLUSH',
}

export type CdrReportsTrafficVolumeAction =
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_SUCCESS;
      payload: CdrTrafficVolumeTotal;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TOTAL_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_SUCCESS;
      payload: CdrAvailabilityDates;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_AVAILABILITY_DATES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_SUCCESS;
      payload: CdrSchema;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SCHEMA_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_SUCCESS;
      payload: CdrTrafficVolumeSeries;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TREND_SERIES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_SUCCESS;
      payload: CdrTrafficVolumeSeries;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SERIES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_SUCCESS;
      payload: CdrRanking<string, string>;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_RANKING_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_DATE_RANGE;
      payload: CdrDateRange;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_PERIOD;
      payload: CdrPeriod;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_FILTER;
      payload: CdrFilter<string>;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_GROUP_BY;
      payload: CdrRankingGroupBy;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_METRIC;
      payload: CdrTrafficVolumeMetricsField;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_SET_RANKING_CALENDAR_DATE;
      payload: CdrRankingCalendarDate;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_SUCCESS;
      payload: CdrTimeSeriesSchema;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SCHEMA_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_SUCCESS;
      payload: CdrAvailabilityPeriodRange;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_AVAILABILITY_DATES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_TIMESERIES_SET_QUERY;

      payload: CdrTimeSeriesQuery;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_FLUSH;
    };

export type CdrReportsImeiChangesAction =
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_SUCCESS;
      payload: CdrImeiChangesTotal;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_SUCCESS;
      payload: CdrSchema;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_SUCCESS;
      payload: CdrAvailabilityDates;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_SUCCESS;
      payload: CdrImeiChangesSeries;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_SUCCESS;
      payload: CdrImeiChangesSeries;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_FAILURE;
      payload: null;
    }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_LOADING }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SUCCESS; payload: CdrRanking }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_FAILURE; payload: null }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_LOADING }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_SUCCESS; payload: CdrChangesHistory }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_FAILURE; payload: null }
  | { type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_FLUSH }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_DATE_RANGE;
      payload: CdrDateRange;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_PERIOD;
      payload: CdrPeriod;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_FILTER;
      payload: CdrFilter<string>;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_GROUP_BY;
      payload: CdrRankingGroupBy;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_METRIC;
      payload: CdrImeiChangesMetricsField;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_CALENDAR_DATE;
      payload: CdrRankingCalendarDate;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_SUCCESS;
      payload: CdrTimeSeriesSchema;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_SUCCESS;
      payload: CdrAvailabilityPeriodRange;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SET_QUERY;

      payload: CdrTimeSeriesQuery;
    }
  | {
      type: CDR_ACTION_TYPE.IMEI_CHANGES_FLUSH;
    };

export type CdrReportsTrafficDifferenceAction =
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_SUCCESS;
      payload: CdrTrafficDifferenceTotal[];
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_SUCCESS;
      payload: CdrAvailabilityDates;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_SUCCESS;
      payload: CdrTrafficDifferenceSeries[];
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_SUCCESS;
      payload: CdrTrafficVolumeSeries;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_LOADING;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SUCCESS;
      payload: CdrRanking<string, string>;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_FAILURE;
      payload: null;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_DATE_RANGE;
      payload: CdrDateRange;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_PERIOD;
      payload: CdrPeriod;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_FILTER;
      payload: CdrFilter<string>;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_GROUP_BY;
      payload: CdrRankingGroupBy;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_METRIC;
      payload: CdrTrafficVolumeMetricsField;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_CALENDAR_DATE;
      payload: CdrRankingCalendarDate;
    }
  | {
      type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_FLUSH;
    };

export type CdrReportsAction =
  | {
      type: CDR_ACTION_TYPE.SOURCE_NETWORK_SET_ACTIVE;
      payload: SourceNetwork;
    }
  | CdrReportsTrafficVolumeAction
  | CdrReportsImeiChangesAction
  | CdrReportsTrafficDifferenceAction;

export function cdrFlush(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { bootstrap } = getState();
    dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_VOLUME_FLUSH });
    dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_FLUSH });
    dispatch({ type: CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_FLUSH });
    dispatch({ type: CDR_ACTION_TYPE.SOURCE_NETWORK_SET_ACTIVE, payload: bootstrap?.source_networks[0] });
  };
}

export function getCdrTimeseriesData(
  endpoint: string,
  payload: CdrTimeSeriesQueryData,
  page: string,
  page_size: number
): Thunk<Promise<CdrTimeSeriesData | null>> {
  return async (dispatch) => {
    let url = `${endpoint}?page_size=${page_size}`;

    if (page) {
      url += `&page=${page}`;
    }

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      data: payload,
    };

    try {
      return dispatch(fetchData<CdrTimeSeriesData>(options));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      throw e;
    }
  };
}
