import filesize from 'filesize';
import moment from 'moment';
import { CdrTrafficVolumeTotal } from 'store/models/cdr';

export function getTooltipDateTitle(period: string) {
  const dayFormat = 'MMMM DD, YYYY';
  const weekFormat = 'MMMM [W]WW YYYY';
  const monthFormat = 'MMMM YYYY';
  const yearFormat = 'YYYY';

  let format = null;

  switch (period) {
    case 'day':
      format = dayFormat;
      break;
    case 'week':
      format = weekFormat;
      break;
    case 'month':
      format = monthFormat;
      break;
    case 'year':
      format = yearFormat;
      break;
    default:
      format = dayFormat;
      break;
  }

  return format;
}

export function metricTooltipFormatter(
  params: any,
  dateTitle: string,
  unit: 'fileSize' | 'number',
  conditionalParam: (param: any) => boolean = () => true
) {
  return `${moment(params[0].data[0]).format(dateTitle)}  
    <hr style="margin: 4px 0; background-color: #DADADA; height: 1px; border: none" />    
    ${params
      .map((param: any) => {
        return typeof conditionalParam === 'function' && conditionalParam(param)
          ? `<div style="margin-top: 8px;">${param.marker} ${
              param.seriesName
            }: <h2 style="font-weight: 500; display: inline; font-size: 16px; line-height: 24px;"> ${
              unit === 'fileSize' && param.data[1] !== '-'
                ? param.data[1] < 0
                  ? `-${(filesize(Math.abs(param.data[1]) as number), { base: 2, standard: 'jedec' })}`
                  : filesize(Math.abs(param.data[1]) as number, { base: 2, standard: 'jedec' })
                : param.data[1].toLocaleString()
            }</h2></div>`
          : '';
      })
      .join('')}`;
}

export function getPointFileSize(point: number | null) {
  const NA = ['N/A', ''];

  return point === null
    ? NA
    : filesize(point, {
        output: 'array',
        base: 2,
        standard: 'jedec',
      });
}

export function getTotalFileSize(trafficVolumeTotal: CdrTrafficVolumeTotal<number | null>) {
  return {
    totallink: getPointFileSize(trafficVolumeTotal.totallink),
    uplink: getPointFileSize(trafficVolumeTotal.uplink),
    downlink: getPointFileSize(trafficVolumeTotal.downlink),
  };
}
