import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { mixed, object } from 'yup';
import { CreateUsim5gStepProps } from '../CreateUsimCard5gContent';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { Usim5gFields } from 'store/models/usim5g';
import { FileUpload } from '@athonet/ui/components/Input/FileUpload';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import commaSeparated from 'assets/files/5g_comma_separated.csv';
import spaceSeparated from 'assets/files/5g_space_separated.csv';
import { Text } from '@athonet/ui/components/Guidelines/Text';

export function BulkStep2({ onCompleteStep, initialValues, ...step }: CreateUsim5gStepProps) {
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      object().shape({
        upload: mixed().required(),
      }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(1, values);
    },
    [onCompleteStep]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.multiple_data_file_type' })} {...step}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Text type="body2">{formatMessage({ id: 'usims.form.usim.multiple_data_description' })}</Text>
                  <Text>
                    {formatMessage(
                      { id: 'usims.form.usim.multiple_data_example' },
                      {
                        comma_separated: <a href={commaSeparated}>comma_separated.csv</a>,
                        space_separated: <a href={spaceSeparated}>space_separated.csv</a>,
                      }
                    )}
                  </Text>
                </Stack>

                <Field name="upload" key="upload">
                  {({ field }: FieldProps<Usim5gFields['upload']>) => (
                    <Box sx={{ width: '100%' }}>
                      <FileUpload
                        label={formatMessage({ id: `usims.form.usim.upload.label` })}
                        onDropAccepted={(acceptedFiles: File[]) => {
                          setFieldValue(field.name, acceptedFiles[0]);
                        }}
                        {...field}
                        value={field.value ? [field.value] : []}
                        onChange={(acceptedFiles: File[]) => {
                          setFieldValue(field.name, acceptedFiles[0]);
                        }}
                        size="small"
                        formError={errors['upload'] && formatMessage({ id: `usims.form.usim.upload.error` })}
                        placeHolder={formatMessage({ id: `usims.form.usim.upload.placeholder` })}
                      />
                    </Box>
                  )}
                </Field>

                <Button
                  variant="outlined"
                  text={formatMessage({ id: 'common.form.next' })}
                  type="submit"
                  disabled={Boolean(errors['upload'])}
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Step>
  );
}
