import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import {
  FiltersObj,
  generateFiltersQuery,
  getFiltersByQueryString,
  GetResultsOptions,
  GetResultsRes,
} from 'store/models/filters';
import { User } from 'store/models/user';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { showErrorToast, showSuccessToast } from './toast';
import { setUser } from './login';
import { sentryLogError } from 'sentry';

export enum USERS_ACTION_TYPE {
  FILTERS_SET = 'USERS_FILTERS_SET',
  SORT_SET = 'USERS_SORT_SET',
  LIST_LOADING = 'USERS_LIST_LOADING',
  LIST_SUCCESS = 'USERS_LIST_SUCCESS',
  LIST_FAILURE = 'USERS_LIST_FAILURE',
  RESET = 'USERS_RESET',
}

// TODO: TBD
// export type UsersAction = {
//   type: LANDING_PAGE_ACTION_TYPE.
// }

export function listLoading() {
  return {
    type: USERS_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: USERS_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: USERS_ACTION_TYPE.LIST_FAILURE,
  };
}

export function reset() {
  return {
    type: USERS_ACTION_TYPE.RESET,
  };
}

export function getUsers({ page = 0, sortBy, filterBy }: GetResultsOptions): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();

    if (sortBy) {
      dispatch({
        type: USERS_ACTION_TYPE.SORT_SET,
        payload: sortBy,
      });
    }

    const newFilterBy: FiltersObj = filterBy || {
      ...getFiltersByQueryString(),
      ...getState().users.filters,
    };

    dispatch({
      type: USERS_ACTION_TYPE.FILTERS_SET,
      payload: newFilterBy,
    });

    const {
      users: { filters, sort },
    } = getState();

    dispatch({
      type: USERS_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const filtersQuery = generateFiltersQuery(filters, '&');
      const options: AxiosRequestConfig = {
        url: `${config.apis.getUsers
          .replace('{sort}', sort)
          .replace('{limit}', state.bootstrap?.pageLimit)
          .replace('{page}', `${page}`)
          .replace('{filters}', filtersQuery)}`,
        method: 'GET',
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<User>>(options))), page };

      // .then((result) => {
      //   let arr = [];
      //   result.items.forEach((item) => {
      //     item.traffic = '0';
      //     item.unit = 'Gb';
      //     item.trend = 'up';
      //     item.trend_value = '0%';
      //     arr.push(item);
      //   });
      //   const data = {
      //     total: result.total_items,
      //     type: 'sites',
      //     traffic: '0',
      //     unit: 'Gb',
      //     data: arr,
      //   };
      //   dispatch(sitesSuccess(data));
      // });

      dispatch({
        type: USERS_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: USERS_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}

type CreateUserPayload = {
  tenantId: string;
  full_name: string;
  role: string;
  phone: string;
  email: string;
  password: string;
  name: string;
  realm: AUTH_PROVIDER;
};

export function createUser({
  tenantId,
  full_name,
  role,
  phone,
  email,
  password,
  realm,
  name,
}: CreateUserPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createUser,
      method: 'POST',
      data: {
        tenant_id: tenantId,
        full_name,
        role,
        phone,
        realm,
        name: realm === AUTH_PROVIDER.ENTERPRISE ? email : name,
        ...(realm === AUTH_PROVIDER.ENTERPRISE && { email, password }),
      },
    };

    await dispatch(fetchData(options));
    await dispatch(getUsers({}));
  };
}

export function deleteUser(userId: User['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.deleteUser.replace('{id}', userId)}`,
      method: 'DELETE',
    };
    await dispatch(fetchData(options));
    await dispatch(getUsers({}));
  };
}

export function getUser(userId: User['id']): Thunk<Promise<User>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.getUser.replace('{id}', userId)}`,
      method: 'GET',
    };

    return dispatch(fetchData<User>(options));
  };
}

export type EditUserPayload = {
  fullname: string;
  role?: string;
  phone: string;
  email: string;
  realm?: AUTH_PROVIDER;
};

export function editUser(
  userId: User['id'],
  { realm, fullname, role, phone, email }: EditUserPayload
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('full_name', fullname);
    formData.append('phone', phone);

    if (realm !== AUTH_PROVIDER.LDAP) {
      formData.append('email', email);
    }

    if (role) {
      formData.append('role', role);
    }

    const options: AxiosRequestConfig = {
      url: `${config.apis.updateUser.replace('{id}', userId)}`,
      method: 'PATCH',
      data: formData,
    };

    await dispatch(fetchData(options));
    await dispatch(getUsers({}));
  };
}

export function getMe(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.me}`,
      method: 'GET',
    };
    const user = await dispatch(fetchData<User>(options));
    dispatch(setUser(user));
  };
}

type EditMePayload = Pick<EditUserPayload, 'fullname' | 'phone' | 'email'>;

export function editMe({ fullname, phone, email }: EditMePayload): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const user = getState().user;

    if (!user || !user.id) {
      dispatch(showErrorToast());
      return;
    }

    try {
      await dispatch(
        editUser(user.id, {
          fullname,
          phone,
          email,
        })
      );
      await dispatch(getMe());
      dispatch(showSuccessToast());
    } catch (e) {
      console.log(e);
      dispatch(showErrorToast());
    }
  };
}

export function changePassword(data: { oldpassword: string; newpassword: string }): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.changePassword,
        method: 'POST',
        data,
      };
      await dispatch(fetchData(options));
      dispatch(
        showSuccessToast({
          message: 'changePassword.successNotification',
          intlMessage: true,
        })
      );
    } catch (e) {
      dispatch(
        showErrorToast({
          message: 'changePassword.errorNotification',
          intlMessage: true,
        })
      );
    }
  };
}
