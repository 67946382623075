import { sentryLogError } from 'sentry';
import { CreateUsimOrderOptions } from 'store/reducers/usimOrders';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { State } from 'store';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { fetchData } from '../fetchData';
import { GetResultsRes } from 'store/models/filters';
import { showErrorToast } from '../toast';
import { GpgKey } from 'store/models/gpgKey';

export const loadUsimOrdersOptions = createAsyncThunk<
  CreateUsimOrderOptions,
  void,
  {
    rejectValue: null;
    state: State;
  }
>('usimOrders/loadOptions', async (_, { dispatch, rejectWithValue, getState }) => {
  const { bootstrap } = getState();

  const msisdnPoolOptions: AxiosRequestConfig = {
    url: `${config.apis.getPools
      .replace('{sort}', 'name')
      .replace('{limit}', 1000)
      .replace('{page}', 0)
      .replace('{filters}', '&type=match{{msisdn}}')}`,
    method: 'GET',
  };

  const imsiPoolOptions: AxiosRequestConfig = {
    url: `${config.apis.getPools
      .replace('{sort}', 'name')
      .replace('{limit}', 1000)
      .replace('{page}', 0)
      .replace('{filters}', '&type=match{{imsi}}')}`,
    method: 'GET',
  };

  const imsi2PoolOptions: AxiosRequestConfig | null = bootstrap?.dual_sim_service
    ? {
        url: `${config.apis.getPools
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '&type=match{{dualimsi}}')}`,
        method: 'GET',
      }
    : null;

  const keysOptions: AxiosRequestConfig = {
    url: `${config.apis.getGpgKeys
      .replace('{sort}', 'name')
      .replace('{limit}', 1000)
      .replace('{page}', 0)
      .replace('{filters}', '')}`,
    method: 'GET',
  };

  try {
    const [msisdns, imsis, imsi2s, keys] = await Promise.all([
      dispatch(fetchData<GetResultsRes<MsidnPoolOption>>(msisdnPoolOptions)),
      dispatch(fetchData<GetResultsRes<ImsiPoolOption>>(imsiPoolOptions)),
      imsi2PoolOptions ? dispatch(fetchData<GetResultsRes<ImsiPoolOption>>(imsi2PoolOptions)) : null,
      dispatch(fetchData<GetResultsRes<GpgKey>>(keysOptions)),
    ]);

    const options: CreateUsimOrderOptions = {
      msisdnPools: msisdns.items.map((mp) => ({ label: mapMSISDNPoolName(mp), value: mp.id })),
      imsiPools: imsis.items.map((ip) => ({ label: mapIMSIPoolName(ip), value: ip.id })),
      imsi2Pools: imsi2s?.items.map((ip) => ({ label: mapIMSIPoolName(ip), value: ip.id })) ?? [],
      privateKeys: keys?.items.filter((k) => k.private).map((pk) => ({ label: pk.name, value: pk.id })) ?? [],
      keys: keys?.items.map((pk) => ({ label: pk.name, value: pk.id })) ?? [],
    };

    return options;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue(null);
  }
});

const mapIMSIPoolName = (imsiPool: ImsiPoolOption) => {
  return imsiPool?.name + ' [' + imsiPool?.first_imsi + '-' + imsiPool?.last_imsi + ']';
};

const mapMSISDNPoolName = (msidnPool: MsidnPoolOption) => {
  return msidnPool?.name + ' [' + msidnPool?.first_msisdn + '-' + msidnPool?.last_msisdn + ']';
};

type ImsiPoolOption = {
  created_at: string;
  first_imsi: string;
  id: string;
  last_imsi: string;
  name: string;
  tenant: Tenant;
  type: string;
  updated_at: string;
};

type MsidnPoolOption = {
  created_at: string;
  first_msisdn: string;
  id: string;
  last_msisdn: string;
  name: string;
  tenant: Tenant;
  type: string;
  updated_at: string;
};

type Tenant = {
  id: string;
  name: string;
};
