export enum TOAST_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  LOADING = 'info', // not a typo
}

export type Toast = {
  intlMessage?: boolean;
  message: string;
  type: TOAST_TYPE;
  manualClose?: boolean;
};
