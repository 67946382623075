import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import {
  getDiagnosticUsimInfo,
  getDiagnosticUsimOperator,
  getDiagnosticUsimNWEvents,
  DiagnosticNWUsimEventsRes,
} from 'store/actions/reports/diagnostic';
import {
  CONNECTIVITY_EVENT_TYPE,
  DiagnosticInfo,
  DiagnosticNWConnectivityEvent,
  DiagnosticOperator,
  NW_EVENT_SUCCESS_CAUSE,
} from 'store/models/reports';
import moment from 'moment';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { isEntityLoading } from 'store/reducers';
import { useIntl } from 'react-intl';
import { Table } from '@athonet/ui/components/Data/Table';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { useUsimEntitySelector } from 'store/selectors/usims/cards';
import { useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { TAB } from '.';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { usimOperatorCountryNotAvailableRexExp } from 'store/models/usim';
import { sentryLogError } from 'sentry';

export function UsimCardsIdInfo() {
  const bootstrap = useBootstrapSelector();
  const dispatch = useDispatch();
  const { id } = useParams<DetailPageParams<TAB>>();
  const usimDetail = useUsimEntitySelector(id as string);
  const [diagnosticUsimInfo, setDiagnosticUsimInfo] = useState<DiagnosticInfo | null>(null);
  const [isDiagnosticUsimInfoLoading, setIsDiagnosticUsimInfoLoading] = useState<boolean>(true);
  const [diagnosticUsimOperator, setDiagnosticUsimOperator] = useState<DiagnosticOperator | null>(null);
  const [diagnosticUsimNotAvailableForRat, setDiagnosticUsimNotAvailableForRat] = useState<string | null>(null);
  const [isDiagnosticUsimOperatorLoading, setIsDiagnosticUsimOperatorLoading] = useState<boolean>(true);
  const [retention, setRetention] = useState<string | null>(null);
  const [nwEventsretention, setNWEventsRetention] = useState<string | null>(null);
  const [isDiagnosticUsimNWEventsLoading, setIsDiagnosticUsimNWEventsLoading] = useState<boolean>(true);
  const [diagnosticUsimNWEvents, setdiagnosticUsimNWEvents] = useState<{
    total_items: number;
    items: DiagnosticNWConnectivityEvent[];
    page: number;
  }>({
    total_items: 0,
    items: [],
    page: 0,
  });
  const [diagnosticUsimNWEventsError, setdiagnosticUsimNWEventsError] = useState(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!bootstrap?.dynamic_info_period_limit_s || !bootstrap?.pgw_events_period_limit_s) {
      return;
    }

    setRetention(moment.duration(bootstrap?.dynamic_info_period_limit_s, 'seconds').humanize());
    setNWEventsRetention(moment.duration(bootstrap?.pgw_events_period_limit_s, 'seconds').humanize());
  }, [bootstrap, retention]);

  const handleGetDiagnosticUsimInfo = useCallback(async () => {
    if (!usimDetail?.data) {
      return;
    }
    try {
      if (bootstrap?.dynamic_info) {
        setIsDiagnosticUsimInfoLoading(true);
        const diagnosticUsimRes = await dispatch(getDiagnosticUsimInfo(usimDetail.data.imsi));
        setDiagnosticUsimInfo(diagnosticUsimRes);
        const isOperatorNotAvailableForRat = diagnosticUsimRes.rat?.match(usimOperatorCountryNotAvailableRexExp);
        if (isOperatorNotAvailableForRat) {
          switch (isOperatorNotAvailableForRat) {
            case 'UTRAN':
              return setDiagnosticUsimNotAvailableForRat('3G');
            case 'GERAN':
              return setDiagnosticUsimNotAvailableForRat('2G');
          }
        }
      }
    } catch (e) {
      sentryLogError(e);
    }
    setIsDiagnosticUsimInfoLoading(false);
  }, [bootstrap, dispatch, usimDetail]);

  const handleGetDiagnosticUsimOperator = useCallback(async () => {
    if (!usimDetail?.data) {
      return;
    }
    try {
      if (bootstrap?.dynamic_info) {
        setIsDiagnosticUsimOperatorLoading(true);
        const diagnosticUsimRes = await dispatch(getDiagnosticUsimOperator(usimDetail.data.imsi));
        setDiagnosticUsimOperator(diagnosticUsimRes);
      }
    } catch (e) {
      sentryLogError(e);
    }
    setIsDiagnosticUsimOperatorLoading(false);
  }, [bootstrap, dispatch, usimDetail]);

  const handleGetDiagnosticUsimNWEvents = useCallback(
    async (page = 0) => {
      if (!usimDetail?.data) {
        return;
      }
      try {
        if (bootstrap?.dynamic_info) {
          setIsDiagnosticUsimNWEventsLoading(true);
          const diagnosticUsimRes: DiagnosticNWUsimEventsRes = await dispatch(
            getDiagnosticUsimNWEvents({ imsi: usimDetail.data.imsi, page })
          );
          setdiagnosticUsimNWEvents({ ...diagnosticUsimRes, page, total_items: diagnosticUsimRes.total_items.value });
        }
      } catch (e) {
        sentryLogError(e);
        setdiagnosticUsimNWEventsError(true);
      }
      setIsDiagnosticUsimNWEventsLoading(false);
    },
    [bootstrap, dispatch, usimDetail]
  );

  useEffect(() => {
    void handleGetDiagnosticUsimInfo();
    void handleGetDiagnosticUsimOperator();
    void handleGetDiagnosticUsimNWEvents();
  }, [bootstrap, handleGetDiagnosticUsimNWEvents, handleGetDiagnosticUsimInfo, handleGetDiagnosticUsimOperator]);

  const dynamicInfoError = useMemo(
    () => !isDiagnosticUsimInfoLoading && !diagnosticUsimInfo,
    [diagnosticUsimInfo, isDiagnosticUsimInfoLoading]
  );

  const operatorError = useMemo(
    () => !isDiagnosticUsimOperatorLoading && !diagnosticUsimOperator,
    [diagnosticUsimOperator, isDiagnosticUsimOperatorLoading]
  );

  const neverConnected = useMemo(
    () => formatMessage({ id: 'usims.id.dynamicInfo.notInRetentionWindow' }, { value: retention }),
    [formatMessage, retention]
  );

  const handleNWEventsPageChange = useCallback(
    (page: number) => {
      if (!usimDetail?.data) {
        return;
      }
      dispatch(handleGetDiagnosticUsimNWEvents(page));
    },
    [dispatch, handleGetDiagnosticUsimNWEvents, usimDetail]
  );

  const getResultJsonByType = useCallback((evt: DiagnosticNWConnectivityEvent['evt']) => {
    if (evt && evt.type) {
      switch (evt.type) {
        case CONNECTIVITY_EVENT_TYPE.CREATE_SESSION:
          return (({ msisdn, apn, user_addr }) => ({ msisdn, apn, user_addr }))(evt);
        case CONNECTIVITY_EVENT_TYPE.DELETE_SESSION:
          return (({ msisdn, apn, user_addr }) => ({ msisdn, apn, user_addr }))(evt);
        case CONNECTIVITY_EVENT_TYPE.AUTH:
          return (({ apn, srv }) => ({ apn, srv }))(evt);
      }
    }
    return null;
  }, []);

  const nWEventsTableData = useMemo(
    () =>
      diagnosticUsimNWEvents.items.map(({ type, timestamp, evt }) => ({
        type: type || '',
        timestamp: timestamp || '',
        result: getResultJsonByType(evt),
        cause: evt && 'cause' in evt && evt.cause ? evt.cause : null,
      })),
    [diagnosticUsimNWEvents.items, getResultJsonByType]
  );

  const [country, operator] = useMemo(() => {
    if (!diagnosticUsimInfo || operatorError) {
      return [neverConnected, neverConnected];
    }

    if (diagnosticUsimNotAvailableForRat) {
      return [
        diagnosticUsimOperator?.country ??
          formatMessage(
            { id: 'usims.id.dynamicInfo.operatorNotAvailableForRat' },
            { rat: diagnosticUsimNotAvailableForRat }
          ),
        diagnosticUsimOperator?.operator ??
          formatMessage(
            { id: 'usims.id.dynamicInfo.operatorNotAvailableForRat' },
            { rat: diagnosticUsimNotAvailableForRat }
          ),
      ];
    }

    return [diagnosticUsimOperator?.country ?? neverConnected, diagnosticUsimOperator?.operator ?? neverConnected];
  }, [
    diagnosticUsimInfo,
    diagnosticUsimNotAvailableForRat,
    diagnosticUsimOperator?.country,
    diagnosticUsimOperator?.operator,
    formatMessage,
    neverConnected,
    operatorError,
  ]);

  if (!usimDetail) {
    return null;
  }

  return (
    <GridContainer sx={{ mt: 1 }}>
      <GridItem size={{ xs: 12, md: 3 }}>
        <Panel title={formatMessage({ id: 'usims.id.dynamicInfo.title' })}>
          <PanelContent>
            {isEntityLoading(usimDetail) ? (
              <Skeleton height={420} variant="rectangular" />
            ) : (
              <>
                <Text type="body2" color={'grey.600'} fontWeight="400">
                  {formatMessage({ id: 'usims.id.dynamicInfo.retentionWindow' }, { value: retention })}
                </Text>
                <Stack spacing={1}>
                  {bootstrap?.dynamic_info && (
                    <Stack sx={{ my: 1 }}>
                      {(isDiagnosticUsimInfoLoading || isDiagnosticUsimOperatorLoading) && (
                        <Alert severity={'info'} message={formatMessage({ id: 'usims.id.dynamicInfo.warning' })} />
                      )}
                      {(dynamicInfoError || operatorError) && (
                        <Alert severity="error" message={formatMessage({ id: 'usims.id.dynamicInfo.error' })} />
                      )}
                    </Stack>
                  )}
                  <Stack direction="column" spacing={0} data-testid="usim-dynamic-info-imsi">
                    <Text color={'grey.600'}>{formatMessage({ id: 'usims.IMSI' })}</Text>
                    <Text fontWeight="bold">{usimDetail.data?.imsi}</Text>
                  </Stack>
                  {bootstrap?.dynamic_info && (
                    <>
                      {!dynamicInfoError && (
                        <>
                          <Divider />
                          <Stack
                            direction="column"
                            spacing={0}
                            data-testid="usim-dynamic-info-diagnostic-connectionStatus"
                          >
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.id.dynamicInfo.connectionStatus' })}
                            </Text>
                            {!isDiagnosticUsimInfoLoading ? (
                              <Chip
                                id={'diagnostic-usim-connected'}
                                size="small"
                                label={
                                  diagnosticUsimInfo?.connected
                                    ? formatMessage({ id: 'usims.connected' })
                                    : diagnosticUsimInfo?.connected === false
                                    ? formatMessage({ id: 'usims.disconnected' })
                                    : neverConnected
                                }
                                color={
                                  diagnosticUsimInfo?.connected
                                    ? 'success'
                                    : diagnosticUsimInfo?.connected === false
                                    ? 'error'
                                    : 'default'
                                }
                              />
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                          <Divider />
                          <Stack direction="column" spacing={0} data-testid="usim-dynamic-info-diagnostic-imei">
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.IMEI' })}
                            </Text>
                            {!isDiagnosticUsimInfoLoading ? (
                              <Text fontWeight="bold">{diagnosticUsimInfo?.imei ?? neverConnected}</Text>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                          <Divider />
                          <Stack direction="column" spacing={0} data-testid="usim-dynamic-info-diagnostic-ip">
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.id.dynamicInfo.lastIP' })}
                            </Text>
                            {!isDiagnosticUsimInfoLoading ? (
                              <Text fontWeight="bold">{diagnosticUsimInfo?.ip ?? neverConnected}</Text>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                          <Divider />
                          <Stack
                            direction="column"
                            spacing={0}
                            data-testid="usim-dynamic-info-diagnostic-last-connection"
                          >
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.id.dynamicInfo.lastConnection' })}
                            </Text>
                            {!isDiagnosticUsimInfoLoading ? (
                              <Text fontWeight="bold">
                                {diagnosticUsimInfo?.last_connection_timestamp
                                  ? moment(diagnosticUsimInfo?.last_connection_timestamp).format(
                                      'MMMM DD, YYYY HH:mm:ss'
                                    )
                                  : neverConnected}
                              </Text>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                          <Divider />
                          <Stack direction="column" spacing={0} data-testid="usim-dynamic-info-diagnostic-rat">
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.id.dynamicInfo.rat' })}
                            </Text>
                            {!isDiagnosticUsimInfoLoading ? (
                              <Text fontWeight="bold">
                                {(diagnosticUsimNotAvailableForRat
                                  ? diagnosticUsimNotAvailableForRat
                                  : diagnosticUsimInfo?.rat) ?? neverConnected}
                              </Text>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                          <Divider />
                        </>
                      )}
                      {!operatorError && (
                        <>
                          <Stack direction="column" spacing={0} data-testid="usim-dynamic-info-diagnostic-operator">
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.operator' })}
                            </Text>
                            {!isDiagnosticUsimOperatorLoading ? (
                              <Text fontWeight="bold">{operator}</Text>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                          <Divider />
                          <Stack direction="column" spacing={0} data-testid="usim-dynamic-info-diagnostic-country">
                            <Text color={'grey.600'} fontWeight="400">
                              {formatMessage({ id: 'usims.country' })}
                            </Text>
                            {!isDiagnosticUsimOperatorLoading ? (
                              <Text fontWeight="bold">{country}</Text>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                          </Stack>
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </>
            )}
          </PanelContent>
        </Panel>
      </GridItem>

      <GridItem size={{ xs: 12, md: 9 }}>
        <Panel title={formatMessage({ id: 'usims.connectivity.title' })}>
          <PanelContent>
            <Text type="body2" color={'grey.600'} fontWeight="400">
              {formatMessage({ id: 'usims.id.dynamicInfo.retentionWindow' }, { value: nwEventsretention })}
            </Text>
            {isEntityLoading(usimDetail) ? (
              <Skeleton height={420} variant="rectangular" />
            ) : diagnosticUsimNWEventsError ? (
              <Box sx={{ py: 1 }}>
                <Alert severity="error" message={formatMessage({ id: 'usims.id.dynamicInfo.nwEvents.error' })} />
              </Box>
            ) : (
              <Table
                loading={isDiagnosticUsimNWEventsLoading}
                columns={[
                  {
                    key: 'timestamp',
                    label: formatMessage({ id: 'usims.id.connectivity.events.table.timestamp' }),
                    cellRender: ({ timestamp }) => (
                      <>{timestamp && moment(timestamp).format('MMMM DD, YYYY HH:mm:ss')}</>
                    ),
                    minWidth: '240',
                    width: '240',
                  },
                  {
                    key: 'type',
                    label: formatMessage({ id: 'usims.id.connectivity.events.table.type' }),
                    cellRender: ({ type, timestamp }) =>
                      type ? (
                        <Chip
                          id={timestamp}
                          size="small"
                          label={formatMessage({ id: `usims.id.connectivity.events.${type}` })}
                        />
                      ) : (
                        <></>
                      ),
                  },
                  {
                    key: 'cause',
                    label: formatMessage({ id: 'usims.id.connectivity.events.table.cause' }),
                    cellRender: ({ cause, timestamp }) => {
                      if (!cause) {
                        return <></>;
                      }

                      const color =
                        cause === NW_EVENT_SUCCESS_CAUSE.ACCESS_ACCEPT || cause === NW_EVENT_SUCCESS_CAUSE.ACCEPT
                          ? 'success'
                          : 'warning';
                      return <Chip id={timestamp} size="small" label={cause} color={color} />;
                    },
                  },
                  {
                    key: 'result',
                    label: formatMessage({ id: 'usims.id.connectivity.events.table.result' }),
                    width: '350',
                    cellRender: ({ result }) => (
                      <>
                        {result &&
                          Object.entries(result).map(([key, value]) => {
                            if (typeof value !== 'string') {
                              value = JSON.stringify(value);
                            }
                            return (
                              <Stack direction="row" spacing={0.5} key={key}>
                                <Text type="body2" color={'grey.700'} fontWeight="400">
                                  {key} =
                                </Text>
                                <Text type="body2">{value}</Text>
                              </Stack>
                            );
                          })}
                      </>
                    ),
                  },
                ]}
                data={nWEventsTableData}
                orderBy="timestamp"
                page={diagnosticUsimNWEvents.page}
                rowKey="timestamp"
                rowsCount={diagnosticUsimNWEvents.total_items}
                rowsPerPage={10}
                onPageChange={handleNWEventsPageChange}
              />
            )}
          </PanelContent>
        </Panel>
      </GridItem>
    </GridContainer>
  );
}
