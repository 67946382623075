import List, { ColumnShape } from 'components/List';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getPlmns } from 'store/actions/plmns';
import { PLMN } from 'store/models/plmn';

import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { usePlmnsListSelector } from 'store/selectors/plmns';
import { useCanUserUpdateNodeSelector, useUserSelector } from 'store/selectors/user';
import { checkPermissionsList } from 'utils/permissionCodes';
import { useNodeEntitySelector } from 'store/selectors/nodes';
import { CreatePlmn } from './CreatePlmn';

export function PlmnsList() {
  const user = useUserSelector();
  const bootstrap = useBootstrapSelector();
  const plmnsList = usePlmnsListSelector();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const nodeEntity = useNodeEntitySelector();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();

  useEffect(() => {
    if (!nodeEntity.data) {
      return;
    }
    void dispatch(getPlmns(nodeEntity.data.id));
  }, [bootstrap, dispatch, nodeEntity]);

  const handleRefresh = useCallback(() => {
    if (!nodeEntity.data) {
      return;
    }
    dispatch(getPlmns(nodeEntity.data.id));
  }, [dispatch, nodeEntity.data]);

  const columns: ColumnShape<PLMN>[] = useMemo(() => {
    if (!bootstrap) {
      return [];
    }

    return checkPermissionsList(
      [user?.tenant_type],
      [
        {
          key: 'name',
          title: formatMessage({ id: 'plmns.table.name' }),
          dataKey: 'name',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          visible: true,
          resizable: true,
        },
        {
          key: 'mcc',
          title: formatMessage({ id: 'plmns.table.mcc' }),
          dataKey: 'mcc',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
        },
        {
          key: 'mnc',
          title: formatMessage({ id: 'plmns.table.mnc' }),
          dataKey: 'mnc',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
        },
        {
          key: 'id',
          title: formatMessage({ id: 'plmns.table.id' }),
          dataKey: 'id',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: false,
        },
        {
          key: 'tools',
          title: '',
          dataKey: 'tools',
          width: 60,
          maxWidth: 60,
          minWidth: 60,
          secret: true,
          cellRenderer: ({ rowData }) => {
            //return getTools(rowData);
          },
        },
      ]
    );
  }, [bootstrap, formatMessage, user?.tenant_type]);

  return (
    <List
      columns={columns}
      data={plmnsList.data.data}
      page={plmnsList.data.page}
      filters={{}}
      onOrderChange={(sort: string) => {
        return null;
      }}
      {...(canUserUpdateNode && { createComponent: <CreatePlmn /> })}
      toolbar={{ actions: false, filters: true }}
      loadingState={plmnsList.state}
      onRefresh={handleRefresh}
      rowsPerPage={30}
      totalRows={plmnsList.data.total}
    />
  );
}
