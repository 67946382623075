import React from 'react';
import { FieldProps } from 'formik';
import useGetInputTexts from './useGetInputTexts';
import { Select, SelectProps } from '@athonet/ui/components/Input/Select';
import useIsRequiredField from './useIsRequiredField';
import { useBaseFormikContext } from '../BaseFormik';

export type SelectComponentProps = FieldProps &
  SelectProps<any> & {
    /** override the default prefix for the translations */
    prefix: string;
    placeholder?: string;
  };

const SelectComponent: React.FC<SelectComponentProps> = (props) => {
  const {
    form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
    field,
    prefix,
    meta,
    children,
    onChange,
    ...selectProps
  } = props;

  const { validationSchema, validationPrefix } = useBaseFormikContext();
  const inputTexts = useGetInputTexts(prefix || validationPrefix);
  const isRequired = useIsRequiredField(validationSchema, field.name);

  return (
    <Select
      required={isRequired}
      size="small"
      name={field.name}
      value={field.value}
      {...inputTexts(field.name, Boolean(errors[field.name]), touched[field.name])}
      {...selectProps}
      onChange={(e, c) => {
        setFieldValue(field.name, e.target.value);
        setFieldTouched(field.name);
        if (onChange) onChange(e, c);
      }}
      disabled={isSubmitting || selectProps.disabled}
    >
      {children}
    </Select>
  );
};

export default SelectComponent;
