import { CdrTotalPayload } from './cdrReports/index';
import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import config from 'config';
import { getFileName } from 'utils/getFileName';
import { Thunk } from '.';
import { exportViews } from './exportViews';
import { fetchData } from './fetchData';
import {
  FiltersObj,
  generateUsimsFiltersQuery,
  getFiltersByQueryString,
  GetResultsOptions,
  GetResultsRes,
} from 'store/models/filters';
import { Usim, UsimFields } from 'store/models/usim';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Site } from 'store/models/site';
import { Node } from 'store/models/node';
import { UsimOrder } from 'store/models/usimOrder';
import { UsimProfile } from 'store/models/usimProfile';
import { setScheduledOperation, deleteScheduledOperation } from './bulkOperations';
import { showErrorToast } from './toast';
import { Location } from '@athonet/ui/components/Data/MapBox';
import { UsimEvent } from 'store/models/usimEvent';
import { CdrTrafficVolumeSeries, CdrTrafficVolumeTotal } from 'store/models/cdr';
import moment from 'moment';
import { fetchCdrTrafficVolumeSeries, fetchCdrTrafficVolumeTotal } from './cdrReports/trafficVolume';
import { getCdrActiveSourceNetworkAggregation } from 'store/selectors/cdrReports/sourceNetwork';
import { sentryLogError } from 'sentry';

export type EditUsimValues<F extends keyof UsimFields> = Partial<Pick<UsimFields, F>>;

export enum USIMS_ACTION_TYPE {
  FILTERS_SET = 'USIMS_FILTERS_SET',
  SORT_SET = 'USIMS_SORT_SET',
  LIST_LOADING = 'USIMS_LIST_LOADING',
  LIST_SUCCESS = 'USIMS_LIST_SUCCESS',
  LIST_FAILURE = 'USIMS_LIST_FAILURE',
  ENTITY_LOADING = 'USIM_ENTITY_LOADING',
  ENTITY_SUCCESS = 'USIM_ENTITY_SUCCESS',
  ENTITY_FAILURE = 'USIM_ENTITY_FAILURE',
  ENTITY_RESET = 'USIM_ENTITY_RESET',
  USIM_SHOULD_FLUSH_SELECTION = 'USIM_SHOULD_FLUSH_SELECTION',
  RESET = 'USIMS_RESET',
}

export function listLoading() {
  return {
    type: USIMS_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: USIMS_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: USIMS_ACTION_TYPE.LIST_FAILURE,
  };
}

export function reset() {
  return {
    type: USIMS_ACTION_TYPE.RESET,
  };
}

export function resetEntity() {
  return {
    type: USIMS_ACTION_TYPE.ENTITY_RESET,
  };
}

export function exportUsimsViews(usims: Array<Usim['id']>): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      usims: { filters },
    } = getState();

    const filtersQuery = encodeURI(generateUsimsFiltersQuery(filters, '?'));
    const formData = new FormData();
    const imsiFilter = filters['imsi'];

    usims.forEach((usim) => {
      formData.append('usims[]', usim);
    });

    if (
      filters &&
      imsiFilter &&
      imsiFilter.activeFilterType === FILTER_INPUT_TYPE.UPLOAD_FILE &&
      imsiFilter.value?.length
    ) {
      formData.append('imsi', imsiFilter.value[0]);
    }

    return dispatch(
      exportViews({
        fileName: getFileName('usims'),
        url: config.apis.exportUsims,
        data: formData,
        filtersQuery,
      })
    );
  };
}

type GetMetadataOption = { name: string; id: string; validation: string };
export type MetadataOption = { label: string; value: string; validation: string };

export function getMetadataOptions(): Thunk<Promise<MetadataOption[]>> {
  return async (dispatch) => {
    try {
      const url = config.apis.getMetadataKeys.replace('{limit}', 1000).replace('{page}', 0);

      const options: AxiosRequestConfig = {
        url,
        method: 'GET',
      };

      const metadata = await dispatch(fetchData<{ items: GetMetadataOption[] }>(options));
      return metadata.items.map((item: GetMetadataOption) => ({
        label: item.name,
        value: item.name,
        validation: item.validation,
      }));
    } catch (e) {
      sentryLogError(e);
      return [];
    }
  };
}

export type UsimSearchField = 'imsi' | 'imsi2' | 'iccid' | 'msisdn';

export function searchUsims(field: UsimSearchField, value: string): Thunk<Promise<string[]>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.searchUsims,
      method: 'POST',
      data: {
        field,
        value,
      },
    };

    const usims = await dispatch(fetchData<{ results: string[] }>(options));
    return usims.results;
  };
}

export function getUsims({ page = 0, sortBy, filterBy }: GetResultsOptions): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (sortBy) {
      dispatch({
        type: USIMS_ACTION_TYPE.SORT_SET,
        payload: sortBy,
      });
    }

    const newFilterBy: FiltersObj = filterBy || {
      ...getFiltersByQueryString(),
      ...getState().usims.filters,
    };

    dispatch({
      type: USIMS_ACTION_TYPE.FILTERS_SET,
      payload: newFilterBy,
    });

    const {
      bootstrap,
      usims: { filters, sort },
    } = getState();

    dispatch({
      type: USIMS_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const query = encodeURI(generateUsimsFiltersQuery(filters, '&'));
      const formData = new FormData();
      const imsiFilter = filters['imsi'];

      if (
        filters &&
        imsiFilter &&
        imsiFilter.activeFilterType === FILTER_INPUT_TYPE.UPLOAD_FILE &&
        imsiFilter.value?.length
      ) {
        formData.append('imsi', imsiFilter.value[0]);
      }

      const options: AxiosRequestConfig = {
        url: `${config.apis.getUsims
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap?.pageLimit)
          .replace('{page}', page)
          .replace('{filters}', query)}`,
        method: 'POST',
        data: formData,
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<Usim>>(options))), page };

      dispatch({
        type: USIMS_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: USIMS_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}

function getUsimOptions<D>(
  url: string,
  mapOptions: (item: D) => AutocompleteItemProps,
  filters = '',
  platformEnabled?: boolean
): Thunk<Promise<AutocompleteItemProps[]>> {
  return async (dispatch) => {
    try {
      let checkUrl;
      if (platformEnabled) {
        checkUrl = url
          .replace('{sort}', 'name')
          .replace('{limit}', '1000')
          .replace('{page}', '0')
          .replace('{filters}', filters)
          .replace('{platform}', 'athux');
      } else {
        checkUrl = url
          .replace('{sort}', 'name')
          .replace('{limit}', '1000')
          .replace('{page}', '0')
          .replace('{filters}', filters);
      }
      const options: AxiosRequestConfig = {
        url: checkUrl,
        method: 'GET',
      };

      const results = await dispatch(fetchData<{ items: D[] }>(options));
      let autocompleteOptions = results.items.map(mapOptions);
      return autocompleteOptions;
    } catch (e) {
      sentryLogError(e);
      return [];
    }
  };
}

// todo actions need to go into their specific model
export function getSitesOptions() {
  return getUsimOptions(config.apis.getSites, (item: Site) => ({ label: item.name, value: item.id }));
}

export function getNodesOptions() {
  return getUsimOptions(config.apis.getNodes, (item: Node) => ({ label: item.display_name, value: item.id }), '', true);
}

export function getOrdersOptions() {
  return getUsimOptions(config.apis.getUsimOrders, (item: UsimOrder) => ({
    label: item.order_id + ' - ' + item.description + ' (' + item.tenant.name + ')',
    value: item.id,
  }));
}

export function getProfilesOptions() {
  return getUsimOptions(
    config.apis.getUsimProfiles,
    (item: UsimProfile) => ({ label: item.name, value: item.id }),
    '&status=ready'
  );
}

export function createUsim(
  data: Pick<
    UsimFields,
    | 'name'
    | 'tenant'
    | 'profile'
    | 'imsi'
    | 'iccid'
    | 'ims_profile'
    | 'msisdn'
    | 'k'
    | 'encrypt'
    | 'use_key'
    | 'op'
    | 'opc'
    | 'use_default_tk'
    | 'key_type'
  >
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const newData: Partial<Omit<UsimFields, 'profile' | 'upload'>> & { profile_id: string; tenant_id: string } = {
      name: data.name.trim(),
      profile_id: data.profile?.id,
      tenant_id: data.tenant?.id,
      imsi: data.imsi,
      iccid: data.iccid,
      ims_profile: data.ims_profile,
      encrypt: data.encrypt,
      k: data.k,
      ...(data.msisdn !== '' && { msisdn: data.msisdn }),
      ...(!data.use_default_tk && data.use_key !== '' && { use_key: data.use_key }),
      ...(data.key_type === 1 && data.op !== '' && { op: data.op }),
      ...(data.key_type === 2 && data.opc !== '' && { opc: data.opc }),
    };

    const options: AxiosRequestConfig = {
      url: config.apis.createUsim,
      method: 'POST',
      data: newData,
    };

    await dispatch(fetchData(options));
    await dispatch(getUsims({}));
  };
}

export function createBulkUsim({ upload, tenant, profile }: Partial<UsimFields>): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const formData = new FormData();

    if (!upload || !tenant) {
      dispatch(showErrorToast());
      return;
    }

    formData.append('uploadfile', upload);
    formData.append('tenant_id', tenant.id);
    formData.append('operation_id', uuid);

    if (profile) {
      formData.append('profile_id', profile.id);
    }

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.createBulkUsim,
        method: 'POST',
        data: formData,
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims({}));
    } catch (e) {
      sentryLogError(e);
      dispatch(deleteScheduledOperation(uuid));
      throw e;
    }
  };
}

export function loadUsimEvents(usimID: Usim['id']): Thunk<Promise<UsimEvent[]>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getUsimEvents
          .replace('{id}', usimID)
          .replace('{sort}', '-timestamp')
          .replace('{limit}', 100)
          .replace('{page}', 0)
          .replace('{filters}', ''),
        method: 'GET',
      };

      const events = await dispatch(fetchData<{ items: UsimEvent[] }>(options));
      return events.items;
    } catch (e) {
      sentryLogError(e);
      return [];
    }
  };
}

export function usimBulkOperation({
  usims,
  url,
  errorMessage,
  method = 'POST',
}: {
  usims: Usim['id'][];
  url: string;
  errorMessage: string;
  method?: Method;
}): Thunk<
  Promise<{
    operation_id: string;
  } | void>
> {
  return async (dispatch, getState) => {
    const {
      usims: { filters },
    } = getState();
    const uuid = await dispatch(setScheduledOperation());
    const formData = new FormData();
    const imsiFilter = filters['imsi'];

    usims.forEach((usim) => {
      formData.append('usims[]', usim);
    });

    formData.append('operation_id', uuid);

    if (
      filters &&
      imsiFilter &&
      imsiFilter.activeFilterType === FILTER_INPUT_TYPE.UPLOAD_FILE &&
      imsiFilter.value?.length
    ) {
      formData.append('imsi', imsiFilter.value[0]);
    }

    const query = encodeURI(generateUsimsFiltersQuery(filters, '?'));

    try {
      const options: AxiosRequestConfig = {
        url: url.replace('{filters}', query),
        method: method || 'POST',
        data: formData,
      };

      const op = await dispatch(fetchData<{ operation_id: string }>(options)); // TODO: possibly returning an operation_id
      await dispatch(getUsims({}));
      return op;
    } catch (e) {
      sentryLogError(e);
      dispatch(deleteScheduledOperation(uuid));
      dispatch(showErrorToast({ message: errorMessage, intlMessage: true }));
      return;
    }
  };
}

export function deleteUsim(usims: Usim['id'][]) {
  return usimBulkOperation({
    usims,
    url: config.apis.deleteUsims,
    errorMessage: 'usims.actions.delete.errorMessage',
    method: 'DELETE',
  });
}

export function activateUsim(usims: Usim['id'][]) {
  return usimBulkOperation({
    usims,
    url: config.apis.activateUsims,
    errorMessage: 'usims.actions.activate.errorMessage',
  });
}

export function deactivateUsim(usims: Usim['id'][]) {
  return usimBulkOperation({
    usims,
    url: config.apis.deactivateUsims,
    errorMessage: 'usims.actions.deactivate.errorMessage',
  });
}

export function deprovisionUsim(usims: Usim['id'][]) {
  return usimBulkOperation({
    usims,
    url: config.apis.deprovisionUsims,
    errorMessage: 'usims.actions.deprovision.errorMessage',
  });
}

export function barUsim(usims: Usim['id'][]) {
  return usimBulkOperation({
    usims,
    url: config.apis.barUsims,
    errorMessage: 'usims.actions.bar.errorMessage',
  });
}

export function unbarUsim(usims: Usim['id'][]) {
  return usimBulkOperation({
    usims,
    url: config.apis.unbarUsims,
    errorMessage: 'usims.actions.unbar.errorMessage',
  });
}

export function updateUsimMetadataJson(jsonData: Record<string, string | null>, usims: Usim[]): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      usims: { filters, entities },
    } = getState();

    const query = encodeURI(generateUsimsFiltersQuery(filters, '?'));
    const uuid = await dispatch(setScheduledOperation());

    const formData = new FormData();

    for (const key in jsonData) {
      formData.append(`metadata[${key}]`, jsonData[key] ? String(jsonData[key]) : ''); // TODO: maybe pass empty string
    }

    usims.forEach((usim) => {
      formData.append('usims[]', usim.id);
    });

    formData.append('operation_id', uuid);

    const imsiFilter = filters['imsi'];
    if (
      filters &&
      imsiFilter &&
      imsiFilter.activeFilterType === FILTER_INPUT_TYPE.UPLOAD_FILE &&
      imsiFilter.value?.length
    ) {
      formData.append('imsi', imsiFilter.value[0]);
    }

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.updateUsimsMetadata.replace('{filters}', query),
        method: 'POST',
        data: formData,
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims({}));

      if (entities[usims[0].id]?.data) {
        await dispatch(getUsim(usims[0].id));
      }
    } catch (e) {
      sentryLogError(e);
      const error = e as AxiosError;

      dispatch(
        showErrorToast(error?.response?.data.error && { message: error.response.data.error, intlMessage: false })
      );
      dispatch(deleteScheduledOperation(uuid));
    }
  };
}

export function changeUsimAttribute({
  data,
  usims,
  errorMessage,
  url,
}: {
  data: { [field: string]: string | number };
  usims: Usim[];
  errorMessage: string;
  url: string;
}): Thunk<
  Promise<{
    operation_id: string;
  } | void>
> {
  return async (dispatch, getState) => {
    const {
      usims: { filters },
    } = getState();
    const uuid = await dispatch(setScheduledOperation());

    const formData = new FormData();

    for (const key in data) {
      formData.append(key, String(data[key]));
    }

    usims.forEach((usim) => {
      formData.append('usims[]', usim.id);
    });

    formData.append('operation_id', uuid);

    const imsiFilter = filters['imsi'];
    if (
      filters &&
      imsiFilter &&
      imsiFilter.activeFilterType === FILTER_INPUT_TYPE.UPLOAD_FILE &&
      imsiFilter.value?.length
    ) {
      formData.append('imsi', imsiFilter.value[0]);
    }

    const query = encodeURI(generateUsimsFiltersQuery(filters, '?'));

    try {
      const options: AxiosRequestConfig = {
        url: url.replace('{filters}', query),
        method: 'POST',
        data: formData,
      };

      const op = await dispatch(fetchData<{ operation_id: string }>(options));
      await dispatch(getUsims({}));
      return op;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AxiosError;

      dispatch(
        showErrorToast(
          error?.response?.data.error
            ? { message: error.response.data.error, intlMessage: false }
            : { message: errorMessage, intlMessage: true }
        )
      );
      dispatch(deleteScheduledOperation(uuid));
      return;
    }
  };
}

export function changeUsimProfile(
  { profile }: EditUsimValues<'profile'>,
  usims: Usim[]
): Thunk<Promise<{ operation_id: string } | void>> {
  return async (dispatch) => {
    if (!profile) {
      dispatch(showErrorToast({ message: 'usims.actions.changeNode.errorMessage', intlMessage: true }));
      return;
    }
    return dispatch(
      changeUsimAttribute({
        url: config.apis.changeProfileUsims,
        data: { profile_id: profile.id },
        usims,
        errorMessage: 'usims.actions.changeProfile.errorMessage',
      })
    );
  };
}

export function changeUsimNode(
  { node }: EditUsimValues<'node'>,
  usims: Usim[]
): Thunk<Promise<{ operation_id: string } | void>> {
  return async (dispatch) => {
    if (!node) {
      dispatch(showErrorToast({ message: 'usims.actions.changeNode.errorMessage', intlMessage: true }));
      return;
    }

    return dispatch(
      changeUsimAttribute({
        url: config.apis.assignToNodeUsims,
        data: { node_id: node.id },
        usims,
        errorMessage: 'usims.actions.changeNode.errorMessage',
      })
    );
  };
}

export function changeUsimSite(
  { site }: EditUsimValues<'site'>,
  usims: Usim[]
): Thunk<Promise<{ operation_id: string } | void>> {
  return async (dispatch) => {
    if (!site) {
      dispatch(showErrorToast({ message: 'usims.actions.changeSite.errorMessage', intlMessage: true }));
      return;
    }
    return dispatch(
      changeUsimAttribute({
        url: config.apis.assignToSiteUsims,
        data: { site_id: site.id },
        usims,
        errorMessage: 'usims.actions.changeSite.errorMessage',
      })
    );
  };
}

export function changeUsimTenant(
  { tenant }: EditUsimValues<'tenant'>,
  usims: Usim[]
): Thunk<Promise<{ operation_id: string } | void>> {
  return async (dispatch) => {
    if (!tenant) {
      dispatch(showErrorToast({ message: 'usims.actions.changeTenant.errorMessage', intlMessage: true }));
      return;
    }
    return dispatch(
      changeUsimAttribute({
        url: config.apis.assignToTenantUsims,
        data: { tenant_id: tenant.id },
        usims,
        errorMessage: 'usims.actions.changeTenant.errorMessage',
      })
    );
  };
}

export function deleteUsimMetadata(
  metadata_delete: EditUsimValues<'metadata_delete'>,
  usims: Usim[]
): Thunk<Promise<void>> {
  return async (dispatch) => {
    if (!metadata_delete.metadata_delete) {
      dispatch(showErrorToast({ message: 'usims.actions.changeTenant.errorMessage', intlMessage: true }));
      return;
    }

    const deletedKey = { [String(metadata_delete.metadata_delete)]: null };
    return dispatch(updateUsimMetadataJson(deletedKey, usims));
  };
}

export function updateUsimMetadataKey(
  { metadata }: { metadata: { [key: string]: string } },
  usims: Usim[]
): Thunk<Promise<void>> {
  return async (dispatch) => {
    if (!metadata) {
      dispatch(showErrorToast({ message: 'usims.actions.changeTenant.errorMessage', intlMessage: true }));
      return;
    }

    return dispatch(updateUsimMetadataJson(metadata, usims));
  };
}

type UsimEditPayload = Omit<Usim, 'profile'> & { profile_id: string; operation_id: string };

export function editSingleUsim({ data, usim }: { data: Partial<UsimFields>; usim: Usim }): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const uuid = await dispatch(setScheduledOperation());

    const newData: Partial<UsimEditPayload> = {
      name: data.name?.trim(),
      profile_id: data.profile?.id,
      imsi: data.imsi,
      msisdn: data.msisdn,
      iccid: data.iccid,
      ims_profile: data.ims_profile,
      encrypt: data.encrypt,
      ...(data.k !== '' && { k: data.k }),
      ...(!data.use_default_tk && data.use_key !== '' && { use_key: data.use_key }),
      ...(data.use_default_tk && { use_key: '' }),
      ...(data.encrypt === 0 && { use_key: '' }),
      ...(data.key_type === 1 && data.op !== '' && { op: data.op }),
      ...(data.key_type === 2 && data.opc !== '' && { opc: data.opc }),
      operation_id: uuid,
    };

    if (data.key_type === 0) {
      newData.op = null;
      newData.opc = null;
    }

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.editUsim.replace('{id}', usim.id),
        method: 'PUT',
        data: {
          ...newData,
          operation_id: uuid,
        },
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims({}));

      if (state.usims.entities[usim.id]) {
        await dispatch(getUsim(usim.id));
      }
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AxiosError;

      dispatch(
        showErrorToast(
          error?.response?.data.error
            ? { message: error.response.data.error, intlMessage: false }
            : { message: 'usims.actions.edit.errorMessage', intlMessage: true }
        )
      );
      dispatch(deleteScheduledOperation(uuid));
    }
  };
}

export function getUsimCountBySite(site: Site): Thunk<Promise<Location | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: `${config.apis.getUsims
          .replace('{sort}', '')
          .replace('{limit}', 1)
          .replace('{page}', 0)
          .replace('{filters}', `&site_id=${site.id}`)}`,
        method: 'POST',
      };

      const result = await dispatch(fetchData<GetResultsRes<Usim>>(options));

      return {
        address: `${site.address}, ${site.country}`,
        text: `
            <strong>${site.name}</strong>
            <br>
            ${site.address} - ${site.country}
            <br>
            <br>
            <a href="/4g-provisioning/usim-cards?filters=%257B%2522site_id%2522%253A%257B%2522value%2522%253A%255B%257B%2522label%2522%253A%2522${site.name}%2522%252C%2522value%2522%253A%2522${site.id}%2522%257D%255D%252C%2522activeFilterType%2522%253A%2522Options%2522%252C%2522not%2522%253Afalse%257D%257D">View Sims</a>
            `,

        kpi: result.total_items,
        center: site.coords.split(',').map((v) => parseFloat(v)),
      };
    } catch (e) {
      sentryLogError(e);
      return;
    }
  };
}

export function getUsimTraffic(
  imsi: Usim['imsi']
): Thunk<Promise<[CdrTrafficVolumeTotal, CdrTrafficVolumeSeries] | [null, null]>> {
  return async (dispatch, getState) => {
    const { bootstrap, cdrReportsSourceNetwork } = getState();

    if (!bootstrap || !cdrReportsSourceNetwork.activeSourceNetwork) {
      return [null, null];
    }

    const payload: CdrTotalPayload = {
      end_date: moment().subtract(1, 'days'),
      start_date: moment().subtract(8, 'days'),
      period: 'day',
      group_by: 'imsi',
      group_filter: imsi,
      source_networks_aggregation: getCdrActiveSourceNetworkAggregation(cdrReportsSourceNetwork.activeSourceNetwork),
    };

    try {
      const res = await Promise.all([
        dispatch(fetchCdrTrafficVolumeTotal(payload)),
        dispatch(fetchCdrTrafficVolumeSeries(payload)),
      ]);
      return res;
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return [null, null];
    }
  };
}

export function getUsim(id: Usim['id'], refresh: boolean = false): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (!refresh) {
      dispatch({
        type: USIMS_ACTION_TYPE.ENTITY_LOADING,
        payload: {
          id,
        },
      });
    }
    const options: AxiosRequestConfig = {
      url: config.apis.getUsim.replace('{id}', id),
    };
    try {
      const [usimRes, usimEvents] = await Promise.all([
        dispatch(fetchData<Usim>(options)),
        dispatch(loadUsimEvents(id)),
      ]);

      const [totalTraffic, trafficSeries] = await dispatch(getUsimTraffic(usimRes.imsi));

      dispatch({
        type: USIMS_ACTION_TYPE.ENTITY_SUCCESS,
        payload: {
          ...usimRes,
          events: usimEvents,
          totalTraffic,
          trafficSeries,
        },
      });
    } catch (e) {
      dispatch({
        type: USIMS_ACTION_TYPE.ENTITY_FAILURE,
        payload: {
          id,
        },
      });
    }
  };
}

export function setUsimShouldFlushSelection(shouldFlushSelection: boolean) {
  return {
    type: USIMS_ACTION_TYPE.USIM_SHOULD_FLUSH_SELECTION,
    payload: shouldFlushSelection,
  };
}
