import { Tenant } from './tenant';

export enum BULK_OPERATION_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  PROGRESS = 'progress',
}

export type BulkOperation = {
  id: string;
  name: string;
  progress: number;
  succeded_actions: number;
  errored_actions: number;
  pending_actions: number;
  status: BULK_OPERATION_STATUS;
  total_actions: number;
  tenant: Tenant;
  operation_id: string | null;
  completed_at: string | null;
  created_at: string;
};

export type ScheduledBulkOperation = string;
