import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';

export function DetailPageSkeleton() {
  return (
    <GridContainer sx={{ mt: 1 }}>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Skeleton height={420} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
        </Stack>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={420} variant="rectangular" />
        </Stack>
      </GridItem>
    </GridContainer>
  );
}
