import CalculatedBillsToolbar from './CalculatedBillsToolbar';
import CalculatedBillsTable from './CalculatedBillsTable';
import { useDispatch } from 'react-redux';
import { getCalculatedBills } from 'store/actions/billing/calculatedBills';
import moment from 'moment';
import { useEffect } from 'react';

export default function CalculatedBills() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCalculatedBills(moment().startOf('month').format('YYYY-MM-DD')));
  }, [dispatch]);

  return (
    <>
      <CalculatedBillsToolbar />
      <CalculatedBillsTable />
    </>
  );
}
