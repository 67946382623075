import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { CreateHss } from 'components/Edit/Hss/Create';
import { CreateTimHss } from 'components/Edit/TimHss/Create';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { HSSNetworkElement, Node, TimHSSNetworkElement } from 'store/models/node';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { HssSection } from './HssSection';
import { TimHssSection } from './TimHssSection';

export function LegacyNetworkElementsSection({ networkElements }: { networkElements: Node['network_elements'] }) {
  const { formatMessage } = useIntl();
  const boostrap = useBootstrapSelector();

  const [hss, timHss] = useMemo(
    () => [
      networkElements.find((nwE) => nwE.type === 'hss') as HSSNetworkElement,
      networkElements.find((nwE) => nwE.type === 'hss_tim') as TimHSSNetworkElement,
    ],
    [networkElements]
  );

  return (
    <>
      {hss ? (
        <HssSection hss={hss} key="hss" />
      ) : (
        <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
          <Panel title={formatMessage({ id: 'nodes.table.hss' })}>
            <PanelContent title={formatMessage({ id: 'nodes.noHss' })}>
              <CreateHss />
            </PanelContent>
          </Panel>
        </GridItem>
      )}
      {boostrap?.dual_sim_service && (
        <>
          {timHss ? (
            <TimHssSection timHss={timHss} key="hss_tim" />
          ) : (
            <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
              <Panel title={formatMessage({ id: 'nodes.table.timHss' })}>
                <PanelContent title={formatMessage({ id: 'nodes.noTimHss' })}>
                  <CreateTimHss />
                </PanelContent>
              </Panel>
            </GridItem>
          )}
        </>
      )}
    </>
  );
}
