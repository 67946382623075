import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  filtersLoading,
  filtersFailure,
  filtersSet,
  filtersInject,
  sortSet,
  listLoading,
  listSuccess,
  listFailure,
  listClear,
  exportUsimProfilesData,
} from 'store/actions/usimProfiles';
import moment from 'moment';
import async from 'async';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/UsimProfiles';
import List from 'components/List';
import Create from 'components/Edit/UsimProfiles/Create';
import Edit from 'components/Edit/UsimProfiles/Edit';
import ChangeSite from 'components/Edit/UsimProfiles/ChangeSite';
import ManageSegments from 'components/Edit/UsimProfiles/ManageSegments';
import ManageNodes from 'components/Edit/UsimProfiles/ManageNodes';
import Spinner from 'components/Spinner';
import DropdownTools from 'components/DropdownTools';
import ExportViewsConfirm from 'components/Confirm/ExportViews';
import { showErrorToast } from 'store/actions/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { cubes as ico_manage_segments } from 'react-icons-kit/fa/cubes';
import { server as ico_change_nodes } from 'react-icons-kit/fa/server';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { ic_people as ico_change_owners } from 'react-icons-kit/md/ic_people';
import { ic_exit_to_app as ico_change_site } from 'react-icons-kit/md/ic_exit_to_app';
import { bitRateToString } from 'utils/byteConverter';
import getFetchHeaders from 'utils/fetchHeaders';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { getFiltersByQueryString } from 'store/models/filters';
import { generateFiltersQuery } from 'store/models/filters';
import { deleteScheduledOperation, setScheduledOperation } from 'store/actions/bulkOperations';
import config from 'config';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { usePollOperation } from 'hooks/usePollOperation';
import { RESOURCE } from 'store/models/modal';
import { openManageTenantsModal } from 'store/actions/modal';
import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import UsimProfileDetail from 'containers/4gProvisioning/UsimProfiles/id';
import { getStatusType } from 'store/models/usimProfile';
import { getProfile } from 'store/actions/usimProfiles';
import { useFetchData } from 'hooks/useFetchData';
import { sentryLogError } from 'sentry';

const UsimProfiles = () => {
  const fetchData = useFetchData();
  const bootstrap = useBootstrapSelector();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  /* NOTE: CC-1285 */
  const [isManageSegmentsOpen, setIsManageSegmentsOpen] = useState(false);
  const [isManageNodesOpen, setIsManageNodesOpen] = useState(false);
  const [isChangeSiteOpen, setIsChangeSiteOpen] = useState(false);
  const userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const datalist = useSelector((state) => state.usimProfiles.list.data);
  const loadingState = useSelector((state) => state.usimProfiles.list.state);
  const filters = useSelector((state) => state.usimProfiles.filters.values) || {};
  const filtersState = useSelector((state) => state.usimProfiles.filters.state);
  const filtersData = useSelector((state) => state.usimProfiles.filters.data) || [];
  const sort = useSelector((state) => state.usimProfiles.sort);
  const { confirmationDialogOpen, dialogOpen } = useOverlay();
  const { pollOperation, clearPollOperation } = usePollOperation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    loadFilters();

    return () => {
      dispatch(listClear());
      clearPollOperation();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      loadData();
    }
  }, [filters]);

  useEffect(() => {
    if (sort !== '' && (filtersState === 1 || filtersState === 2)) {
      loadData();
    }
  }, [sort, filtersState]);

  const getFiltersData = () => {
    const options = {
      segments: [],
      nodes: [],
      sites: [],
      tenants: [],
      regional_subscriptions_profiles: [],
    };

    for (let i in filtersData.nodes) {
      options.nodes.push({ label: filtersData.nodes[i].display_name, value: filtersData.nodes[i].id });
    }

    for (let i in filtersData.rsp) {
      options.regional_subscriptions_profiles.push({ label: filtersData.rsp[i].name, value: filtersData.rsp[i].id });
    }

    for (let i in filtersData.segments) {
      let s = filtersData.segments[i];
      options.segments.push({ label: s.name + ' (' + s.apn + ')', value: s.id, nodes: s.nodes });
    }

    for (let i in filtersData.sites) {
      options.sites.push({ label: filtersData.sites[i].name, value: filtersData.sites[i].id });
    }

    for (let i in filtersData.tenants) {
      options.tenants.push({ label: filtersData.tenants[i].name, value: filtersData.tenants[i].id });
    }

    return options;
  };

  const loadFilters = () => {
    dispatch(filtersLoading());

    const calls = {};

    calls.tenants = (cb) => {
      const options = {
        url: `${config.apis.getTenantWithSubtenants
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((response) => {
          cb(null, response.tenants);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.sites = (cb) => {
      //TODO handle paginated entities...
      const options = {
        url: `${config.apis.getSites
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.segments = (cb) => {
      const options = {
        url: `${config.apis.getSegments
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '&status=ready')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.nodes = (cb) => {
      //TODO handle paginated entities...
      const options = {
        url: `${config.apis.getNodes
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')
          .replace('{platform}', 'athux')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.rsp = (cb) => {
      const options = {
        url: `${config.apis.getRegionalSubscriptionsProfiles
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    async.parallel(calls, (err, results) => {
      if (err) {
        sentryLogError(err);
        dispatch(filtersFailure());
      } else {
        dispatch(
          filtersInject({ state: 1, data: Object.assign(filtersData, results), values: getFiltersByQueryString() })
        );
      }
    });
  };

  const loadData = (page = 0) => {
    if (
      // load if..
      loadingState !== 0 &&
      sort !== '' // .. sort is set
    ) {
      dispatch(listLoading());

      const query = encodeURI(generateFiltersQuery(filters, '&'));

      const options = {
        url: `${config.apis.getUsimProfiles
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap.pageLimit)
          .replace('{page}', page)
          .replace('{filters}', query)}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          dispatch(
            listSuccess({
              data: result.items,
              total: result.total_items,
              page,
            })
          );

          // NOTE: dome => this is a patch, we're storing the selected item object instead of the id and so we need to update it to refresh to data being rendered in the modal
          setSelectedItem((prevSelectedItem) => {
            if (prevSelectedItem) {
              return result.items.find((item) => prevSelectedItem.id === item.id);
            }
          });
        })
        .catch(() => {
          dispatch(listFailure());
        });
    }
  };

  const handlePageChange = (page) => {
    loadData(page);
  };

  const exportUsimProfiles = () => {
    const filterQuery = generateFiltersQuery(filters, '&', ['name']);
    void dispatch(exportUsimProfilesData(filterQuery));
  };

  const openDownload = () => {
    dialogOpen({
      title: formatMessage({ id: 'listToolbar.download' }),
      content: () => <ExportViewsConfirm onSubmit={exportUsimProfiles} />,
    });
  };

  useEffect(() => {
    loadData();
  }, [bootstrap.pageLimit]);

  const setFilters = (filtersValues) => {
    dispatch(filtersSet(filtersValues));
  };

  const onOrderChange = (orderQuery) => {
    dispatch(sortSet(orderQuery));
  };

  const newUsimProfile = (values, callback) => {
    const options = {
      url: `${config.apis.createUsimProfile}`,
      method: 'POST',
      headers: getFetchHeaders(userdata),
      data: {
        name: values?.name.trim(),
        default_apn_profile_id: values?.default_apn_profile_id,
        //apn_profiles_id: values?.apn_profiles_id,
        //plmns_id: values?.plmns_id,
        //csgs_lists_id: values?.csgs_lists_id,
        //teleservices: values?.teleservices,
        ue_ambr_ul: values?.ue_ambr_ul,
        ue_ambr_dl: values?.ue_ambr_dl,
        charging_characteristics:
          values?.charging_characteristics !== null ? values?.charging_characteristics : undefined,
        roaming_allowed: values?.roaming_allowed,
        subscription_data_flags: values?.subscription_data_flags,
        s6a_nam: values?.s6a_nam,
        s6dgr_nam: values?.s6dgr_nam,
        regional_subscriptions_profile_id:
          values?.regional_subscriptions_profile_id !== '' ? values?.regional_subscriptions_profile_id : null,
      },
    };

    fetchData(options)
      .then(() => {
        loadData();
        callback(true, formatMessage({ id: 'usimProfiles.actions.create.successMessage' }));
      })
      .catch((e) => {
        sentryLogError(e);
        const defaultError = formatMessage({ id: 'usimProfiles.actions.create.errorMessage' });
        const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
        callback(false, errorText);
      });
  };

  const onDelete = async (profileId) => {
    const uuid = await dispatch(setScheduledOperation());
    const options = {
      url: `${config.apis.deleteUsimProfile.replace('{id}', profileId)}`,
      method: 'DELETE',
      headers: getFetchHeaders(userdata),
      data: {
        operation_id: uuid,
      },
    };

    fetchData(options)
      .then(async () => {
        loadData();
        if (id) {
          navigate(`/4g-provisioning/usim-profiles`);
        }
      })
      .catch((e) => {
        sentryLogError(e);
        const defaultError = formatMessage({ id: 'usimProfiles.actions.delete.errorMessage' });
        const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
        dispatch(deleteScheduledOperation(uuid));
        dispatch(
          showErrorToast({
            message: errorText,
          })
        );
      });
  };

  const onAssignSegment = async (values, callback) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());
      const options = {
        url: `${config.apis.assignUsimProfileSegment
          .replace('{id}', selectedItem.id)
          .replace('{segment_id}', values.segment_id)}`,
        method: 'POST',
        headers: getFetchHeaders(userdata),
        data: {
          default: values.default,
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then((res) => {
          if (id) {
            pollOperation(res.operation_id, () => void dispatch(getProfile(id, true)));
          }
          pollOperation(res.operation_id, loadData);

          callback(null);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'usimProfiles.actions.assignSegment.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          callback(false, errorText);
        });
    } else {
      callback(false);
    }
  };

  const onDeassignSegment = async (segment_id) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());
      const options = {
        url: `${config.apis.deassignUsimProfileSegment
          .replace('{id}', selectedItem.id)
          .replace('{segment_id}', segment_id)}`,
        method: 'DELETE',
        headers: getFetchHeaders(userdata),
        data: {
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then((res) => {
          if (id) {
            pollOperation(res.operation_id, () => void dispatch(getProfile(id, true)));
          }
          pollOperation(res.operation_id, loadData);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'usimProfiles.actions.deassignSegment.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          dispatch(
            showErrorToast({
              message: errorText,
            })
          );
        });
    }
  };

  const onAssignNode = async (values, callback) => {
    const uuid = await dispatch(setScheduledOperation());
    if (selectedItem) {
      const options = {
        url: `${config.apis.assignUsimProfileNode.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          node_id: values.node_id,
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then((res) => {
          if (id) {
            pollOperation(res.operation_id, () => void dispatch(getProfile(id, true)));
          }
          pollOperation(res.operation_id, loadData);
          callback(null);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'usimProfiles.actions.assignNode.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          callback(false, errorText);
        });
    } else {
      callback(false);
    }
  };

  const onDeassignNode = async (node_id) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());

      const options = {
        url: `${config.apis.deassignUsimProfileNode.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          operation_id: uuid,
          node_id: node_id,
        },
      };

      fetchData(options)
        .then((res) => {
          if (id) {
            pollOperation(res.operation_id, () => void dispatch(getProfile(id, true)));
          }
          pollOperation(res.operation_id, loadData);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'usimProfiles.actions.deassignNode.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          dispatch(
            showErrorToast({
              message: errorText,
            })
          );
        });
    }
  };

  const onChangeSite = async (values, callback) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());
      const options = {
        url: `${config.apis.assignUsimProfileSite.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          site_id: values.site_id,
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then(() => {
          loadData();
          if (id) {
            void dispatch(getProfile(id));
          }
          callback(null);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'usimProfiles.actions.changeSite.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          callback(false, errorText);
        });
    } else {
      callback(false);
    }
  };

  const onEdit = (values, callback) => {
    if (selectedItem) {
      const options = {
        url: `${config.apis.editUsimProfile.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          name: values?.name.trim(),
          ue_ambr_ul: values?.ue_ambr_ul,
          ue_ambr_dl: values?.ue_ambr_dl,
          charging_characteristics: values?.charging_characteristics,
          roaming_allowed: values?.roaming_allowed,
          subscription_data_flags: values?.subscription_data_flags,
          s6a_nam: values?.s6a_nam,
          s6dgr_nam: values?.s6dgr_nam,
          regional_subscriptions_profile_id:
            values?.regional_subscriptions_profile_id !== '' ? values?.regional_subscriptions_profile_id : undefined,
        },
      };

      fetchData(options)
        .then(() => {
          loadData();
          if (id) {
            void dispatch(getProfile(id, true));
          }
          callback(true, formatMessage({ id: 'usimProfiles.actions.edit.successMessage' }));
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'usimProfiles.actions.edit.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          callback(false, errorText);
        });
    } else {
      callback(false);
    }
  };

  const openEdit = (rowData) => {
    setIsEditOpen(true);
    setSelectedItem(rowData);
  };

  const closeEdit = () => {
    setIsEditOpen(false);
    setSelectedItem(undefined);
  };

  const openManageTenants = (profileId) => {
    dispatch(
      openManageTenantsModal({
        resource: RESOURCE.USIM_PROFILE,
        id: profileId,
        cb: () => {
          loadData();
          if (id) {
            void dispatch(getProfile(id, true));
          }
        },
      })
    );
  };

  const openManageSegments = (rowData) => {
    setIsManageSegmentsOpen(true);
    setSelectedItem(rowData);
  };

  const closeManageSegments = () => {
    setIsManageSegmentsOpen(false);
    setSelectedItem(undefined);
    clearPollOperation();
  };

  const openManageNodes = (rowData) => {
    setIsManageNodesOpen(true);
    setSelectedItem(rowData);
  };

  const closeManageNodes = () => {
    setIsManageNodesOpen(false);
    setSelectedItem(undefined);
    clearPollOperation();
  };

  const openChangeSite = (rowData) => {
    setIsChangeSiteOpen(true);
    setSelectedItem(rowData);
  };

  const closeChangeSite = () => {
    setIsChangeSiteOpen(false);
    setSelectedItem(undefined);
  };

  const openDelete = ({ id: profileId, name }) => {
    confirmationDialogOpen({
      title: formatMessage({ id: 'usimProfiles.actions.delete.confirm.title' }),
      description: formatMessage({ id: 'usimProfiles.actions.delete.confirm' }),
      alertMessage: formatMessage({ id: 'usimProfiles.actions.itemsAffected' }, { element: name }),
      continueButtonText: formatMessage({ id: 'usimProfiles.actions.delete.confirm.continueButton' }, { elements: 1 }),
      onConfirm: () => void onDelete(profileId),
      severity: 'danger',
      dataTestid: 'confirm-delete-usimProfile',
    });
  };

  const gets6aNamText = useCallback(
    (nam) => {
      switch (nam) {
        case 2:
          return formatMessage({ id: 'usimProfiles.form.usimProfile.s6a_nam.OnlyPacket' });
        case 3:
          return formatMessage({ id: 'usimProfiles.form.usimProfile.s6a_nam.PacketAndCircuit' });
        default:
          return nam;
      }
    },
    [formatMessage]
  );

  const gets6dgrNamText = useCallback(
    (nam) => {
      switch (nam) {
        case 1:
          return formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam.OnlyCircuit' });
        case 2:
          return formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam.OnlyPacket' });
        case 3:
          return formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam.PacketAndCircuit' });
        default:
          return nam;
      }
    },
    [formatMessage]
  );

  const getTools = (rowData) => {
    let options = [
      {
        icon: ico_edit,
        label: formatMessage({ id: 'usimProfiles.actions.edit.title' }),
        action: () => openEdit(rowData),
        disabled: rowData.updating,
        permissions: [U_PERMISSIONS.UPDATE_USIM_PROFILE],
      },
      {
        icon: ico_change_nodes,
        label: formatMessage({ id: 'usimProfiles.actions.manageNodes.title' }),
        action: () => openManageNodes(rowData),
        permissions: [U_PERMISSIONS.UPDATE_USIM_PROFILE],
      },
    ];
    if (userdata.tenant_type.toUpperCase() !== T_PERMISSIONS.NETWORK_MANAGER) {
      options = options.concat([
        {
          icon: ico_change_owners,
          label: formatMessage({ id: 'usimProfiles.actions.manageTenants.title' }),
          action: () => openManageTenants(rowData.id),
          permissions: [U_PERMISSIONS.UPDATE_USIM_PROFILE],
        },
      ]);
    }

    options = options.concat([
      {
        icon: ico_manage_segments,
        label: formatMessage({ id: 'usimProfiles.actions.manageSegments.title' }),
        action: () => openManageSegments(rowData),
        disabled: rowData.updating,
        permissions: [U_PERMISSIONS.UPDATE_USIM_PROFILE],
      },
      {
        icon: ico_change_site,
        label: formatMessage({ id: 'usimProfiles.actions.changeSite.title' }),
        action: () => openChangeSite(rowData),
        disabled: rowData.updating,
        permissions: [U_PERMISSIONS.UPDATE_USIM_PROFILE],
      },
      {
        icon: ico_delete,
        label: formatMessage({ id: 'usimProfiles.actions.delete.title' }),
        action: () => openDelete(rowData),
        disabled: rowData.updating || rowData.status !== 'in_stock',
        permissions: [U_PERMISSIONS.DELETE_USIM_PROFILE],
      },
    ]);

    return <DropdownTools options={checkPermissionsList(userdata.permissions, options, false)} />;
  };
  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataKey: 'id',
      width: 200,
      maxWidth: 300,
      minWidth: 100,
      visible: false,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'usimProfiles.table.name' }),
      dataKey: 'name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: name, rowData: { id: profileId } }) => {
        return (
          <Link
            onClick={() => {
              navigate(`/4g-provisioning/usim-profiles/${profileId}`);
            }}
          >
            <Text type={'body2'}>{name}</Text>
          </Link>
        );
      },
    },
    {
      key: 'status',
      title: formatMessage({ id: 'usimProfiles.table.status' }),
      dataKey: 'status',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: status }) => {
        return <Status status={getStatusType(status)} label={humanizeSnakeCase(status)} asChip />;
      },
    },
    {
      key: 'ue_ambr_ul',
      title: formatMessage({ id: 'usimProfiles.table.ue_ambr_ul' }),
      dataKey: 'ue_ambr_ul',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: value }) => <span title={value}>{bitRateToString(value)}</span>,
    },
    {
      key: 'ue_ambr_dl',
      title: formatMessage({ id: 'usimProfiles.table.ue_ambr_dl' }),
      dataKey: 'ue_ambr_dl',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: value }) => <span title={value}>{bitRateToString(value)}</span>,
    },
    {
      key: 'segments',
      title: formatMessage({ id: 'usimProfiles.table.segments' }),
      dataKey: 'segments',
      sortable: false,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: segments }) => {
        if (!segments || segments.length === 0) {
          return '-';
        } else if (segments.length === 1) {
          return segments[0].name + (segments[0].default ? ' [default]' : '');
        } else {
          segments.sort((a, b) => {
            if (a.default && !b.default) {
              return -1;
            } else if (!a.default && b.default) {
              return 1;
            } else {
              return 0;
            }
          });
          let title = segments.reduce((acc, s) => acc + '\n' + s.name + (s.default ? ' [default]' : ''), '');
          return (
            <span title={title}>
              {segments[0].name + (segments[0].default ? ' [default]' : '')} (+{segments.length - 1} more...)
            </span>
          );
        }
      },
    },
    {
      key: 'regional_subscriptions_profile.name',
      title: formatMessage({ id: 'usimProfiles.table.regional_subscriptions_profileName' }),
      dataKey: 'regional_subscriptions_profile.name',
      sortable: false,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
    },
    {
      key: 'charging_characteristics',
      title: formatMessage({ id: 'usimProfiles.table.charging_characteristics' }),
      dataKey: 'charging_characteristics',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
    },
    {
      key: 'subscription_data_flags',
      title: formatMessage({ id: 'usimProfiles.table.subscription_data_flags' }),
      dataKey: 'subscription_data_flags',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
    },
    {
      key: 's6a_nam',
      title: formatMessage({ id: 'usimProfiles.table.s6a_nam' }),
      dataKey: 's6a_nam',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
      cellRenderer: ({ cellData: value }) => gets6aNamText(value),
    },
    {
      key: 's6dgr_nam',
      title: formatMessage({ id: 'usimProfiles.table.s6dgr_nam' }),
      dataKey: 's6dgr_nam',
      sortable: true,
      width: 100,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
      cellRenderer: ({ cellData: value }) => gets6dgrNamText(value),
    },
    {
      key: 'roaming_allowed',
      title: formatMessage({ id: 'usimProfiles.table.roaming_allowed' }),
      dataKey: 'roaming_allowed',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
      cellRenderer: ({ cellData: value }) => {
        return (
          <Status
            label={
              value === 1
                ? formatMessage({ id: 'common.form.allowed' })
                : formatMessage({ id: 'common.form.notAllowed' })
            }
            status={value === 1 ? STATUS.SUCCESS : STATUS.ERROR}
          />
        );
      },
    },
    {
      key: 'site.name',
      title: formatMessage({ id: 'usimProfiles.table.siteName' }),
      dataKey: 'site.name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
    },
    {
      key: 'nodes',
      title: formatMessage({ id: 'usimProfiles.table.nodes' }),
      dataKey: 'nodes',
      sortable: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: nodes }) => {
        if (nodes.length === 0) {
          return '-';
        } else if (nodes.length === 1) {
          return nodes[0].display_name;
        } else {
          let title = nodes.reduce((acc, node) => acc + '\n' + node.display_name, '');
          return (
            <span title={title}>
              {nodes[0].display_name} (+{nodes.length - 1} more...)
            </span>
          );
        }
      },
    },
    {
      key: 'owners',
      title: formatMessage({ id: 'usimProfiles.table.tenants' }),
      dataKey: 'owners',
      sortable: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
      cellRenderer: ({ cellData: owners }) => {
        if (owners.length === 0) {
          return '-';
        } else if (owners.length === 1) {
          return owners[0].name;
        } else {
          let title = owners.reduce((acc, owner) => acc + '\n' + owner.name, '');
          return (
            <span title={title}>
              {owners[0].name} (+{owners.length - 1} more...)
            </span>
          );
        }
      },
      visible: false,
    },
    {
      key: 'created_at',
      title: formatMessage({ id: 'usimProfiles.table.created' }),
      dataKey: 'created_at',
      defaultSort: 'desc', // set the columns sorted as default
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 50,
      visible: false,
      cellRenderer: ({ cellData: created_at }) => moment(created_at).format(localizedConfig.fullDateFormat),
    },
    {
      key: 'updated_at',
      title: formatMessage({ id: 'usimProfiles.table.updated' }),
      dataKey: 'updated_at',
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 50,
      visible: false,
      cellRenderer: ({ rowData }) => {
        return rowData.created_at !== rowData.updated_at
          ? moment(rowData.updated_at).format(localizedConfig.fullDateFormat)
          : '-';
      },
    },
    {
      key: 'tools',
      title: '',
      dataKey: 'tools',
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      secret: true, // secret used to hide from columns management panel
      cellRenderer: ({ rowData }) => {
        return getTools(rowData);
      },
    },
  ];

  const onCheckPermissionsList = (list) => {
    return checkPermissionsList([userdata.tenant_type], list);
  };

  const columnsPermitted = checkPermissionsList([userdata.tenant_type], columns);

  return (
    <>
      <>
        {id ? (
          <UsimProfileDetail
            handleOpenEdit={openEdit}
            handleOpenManageNodes={openManageNodes}
            handleOpenManageTenants={openManageTenants}
            handleOpenManageSegments={openManageSegments}
            handleOpenDelete={openDelete}
            handleOpenChangeSite={openChangeSite}
            gets6aNamText={gets6aNamText}
            gets6dgrNamText={gets6dgrNamText}
          />
        ) : columnsPermitted ? (
          <List
            key="usim-profiles-list"
            columns={columnsPermitted}
            totalRows={datalist.total}
            data={datalist.data}
            page={datalist.page}
            filters={filters}
            onOrderChange={onOrderChange}
            loadingState={loadingState}
            createComponent={
              checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_USIM_PROFILE) && (
                <Create
                  data={{ default_tenant_id: userdata.tenant_id, ...getFiltersData() }}
                  dataOptions={getFiltersData()}
                  onSubmit={newUsimProfile}
                  checkPermissions={onCheckPermissionsList}
                />
              )
            }
            filtersComponent={
              <Filters
                data={getFiltersData()}
                values={filters}
                onSubmit={setFilters}
                checkPermissions={onCheckPermissionsList}
              />
            }
            onPageChange={handlePageChange}
            selectable={false}
            onDownload={openDownload}
            toolbar={{ actions: false }}
            rowsPerPage={bootstrap.pageLimit}
            onRefresh={loadData}
          />
        ) : (
          <Spinner className="spinner" size={40} />
        )}
      </>

      <>
        {isEditOpen && (
          <Edit
            data={selectedItem}
            dataOptions={getFiltersData()}
            isOpen={isEditOpen}
            onEdit={onEdit}
            checkPermissions={onCheckPermissionsList}
            onClose={closeEdit}
          />
        )}

        {isManageNodesOpen && (
          <ManageNodes
            dataOptions={getFiltersData()}
            data={selectedItem}
            isOpen={isManageNodesOpen}
            onClose={closeManageNodes}
            onAssignNode={onAssignNode}
            onDeassignNode={onDeassignNode}
          />
        )}

        {isManageSegmentsOpen && (
          <ManageSegments
            dataOptions={getFiltersData()}
            data={selectedItem}
            isOpen={isManageSegmentsOpen}
            onClose={closeManageSegments}
            onAssignSegment={onAssignSegment}
            onDeassignSegment={onDeassignSegment}
          />
        )}

        {isChangeSiteOpen && (
          <ChangeSite
            dataOptions={getFiltersData()}
            isOpen={isChangeSiteOpen}
            onClose={closeChangeSite}
            onChangeSite={onChangeSite}
            checkPermissions={onCheckPermissionsList}
          />
        )}
      </>
    </>
  );
};

export default UsimProfiles;
