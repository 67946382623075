import { AthonetProvider } from '@athonet/ui/components/AthonetProvider';
import { Provider } from 'react-redux';
import store from 'store';
import Bootstrap from 'containers/Bootstrap';
import ThemeProvider from 'theme/provider';
import Toasts from 'containers/Toasts';
import Modals from 'containers/Modals';
import Router from 'Router';
import { ErrorBoundary } from 'sentry';

import '@athonet/ui/assets/css/athonet.css';

export default function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Bootstrap>
          <ThemeProvider>
            <AthonetProvider>
              <>
                <Toasts />
                <Modals />
                <Router />
              </>
            </AthonetProvider>
          </ThemeProvider>
        </Bootstrap>
      </Provider>
    </ErrorBoundary>
  );
}
