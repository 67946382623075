const scrollDialogToTheTop = () => {
  setTimeout(() => {
    const element = document.querySelector(`.Athonet-MuiDialogContent-root`)?.children[0];
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 100);
};

export default scrollDialogToTheTop;
