import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getCdrImeiChangesTotal, getCdrImeiChangesTrendSeries } from 'store/actions/cdrReports/imeiChanges';
import {
  useCdrImeiChangesFilter,
  useCdrImeiChangesPeriod,
  useCdrImeiChangesSchema,
  useImeiChangesDateRange,
} from 'store/selectors/cdrReports/imeiChanges';
import { useCdrImeiChangesTrendSeries, useCdrImeiChangesTotal } from 'store/selectors/cdrReports/imeiChanges';
import { DATA_LIFECYCLE, isEntityLoading } from 'store/reducers';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { getTooltipDateTitle, metricTooltipFormatter } from '../utils/utils';
import { NoData } from '@athonet/ui/components/Data/NoData';
import {
  getCdrActiveSourceNetworkAggregation,
  useCdrActiveSourceNetwork,
} from 'store/selectors/cdrReports/sourceNetwork';
import TrendChart from '../TrendChart';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';

export function ImeiChangesTrend() {
  const imeiChangesSeries = useCdrImeiChangesTrendSeries();
  const imeiChangesTotal = useCdrImeiChangesTotal();
  const dispatch = useDispatch();
  const imeiChangesDateRange = useImeiChangesDateRange();
  const imeiChangesPeriod = useCdrImeiChangesPeriod();
  const imeiChangesFilter = useCdrImeiChangesFilter();
  const imeiChangesSchema = useCdrImeiChangesSchema();
  const activeSourceNetwork = useCdrActiveSourceNetwork();

  const tooltipDateTitle = useMemo(() => {
    return getTooltipDateTitle(imeiChangesPeriod);
  }, [imeiChangesPeriod]);

  const tooltipFormatter = useCallback(
    (params: any) => {
      return metricTooltipFormatter(params, tooltipDateTitle, 'number');
    },

    [tooltipDateTitle]
  );

  useEffect(() => {
    if (!imeiChangesDateRange || !imeiChangesDateRange.start || !imeiChangesDateRange.end || !activeSourceNetwork) {
      return;
    }
    dispatch(
      getCdrImeiChangesTotal({
        start_date: imeiChangesDateRange.start,
        end_date: imeiChangesDateRange.end,
        period: imeiChangesPeriod,
        group_by: imeiChangesFilter.group_by || undefined,
        group_filter: imeiChangesFilter.group_filter || undefined,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );

    dispatch(
      getCdrImeiChangesTrendSeries({
        start_date: imeiChangesDateRange.start,
        end_date: imeiChangesDateRange.end,
        period: imeiChangesPeriod,
        group_by: imeiChangesFilter.group_by || undefined,
        group_filter: imeiChangesFilter.group_filter || undefined,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [
    dispatch,
    imeiChangesDateRange,
    imeiChangesFilter,
    imeiChangesFilter.group_by,
    imeiChangesFilter.group_filter,
    imeiChangesPeriod,
    activeSourceNetwork,
  ]);

  const seriesData = useMemo(() => {
    if (!imeiChangesSeries.data) {
      return;
    }
    const series: [string, number | string][] = [];
    imeiChangesSeries.data.forEach((item) => {
      const dateTime = item.datetime.toString();
      series.push([dateTime, item.imei_changes !== null ? item.imei_changes : '-']);
    });

    return series;
  }, [imeiChangesSeries.data]);

  if (imeiChangesSchema.state === DATA_LIFECYCLE.SUCCESS && imeiChangesSchema.data === null) {
    return <NoData />;
  }

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <Panel highlightedColor="series1" fullHeight>
          <PanelContent>
            {!isEntityLoading(imeiChangesTotal) ? (
              <SquaredTile
                /* TODO intl */
                data-testid="cdr-imei-changes-trend-kpi-total"
              >
                <SquaredChildTile
                  mainValue={imeiChangesTotal.data?.imei_changes?.toLocaleString('en') || 'No Changes'}
                  title={'Total Changes'}
                  variant="large"
                  titleColor="series1"
                  subtitle="Total IMEI Changes"
                />
              </SquaredTile>
            ) : (
              <Skeleton height={113} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <TrendChart
          height={320}
          isLoading={isEntityLoading(imeiChangesSeries)}
          title="IMEI Changes"
          name="Total"
          seriesData={seriesData}
          xAxisFormat={(value: string) => moment(value).format(tooltipDateTitle)}
          tooltipFormat={tooltipFormatter}
          data-testid="cdr-imei-changes-trend-chart"
          noLegend
        />
      </GridItem>
    </GridContainer>
  );
}
