import { BillingConfig, CalculatedBills } from 'store/models/billing';
import { Entity, entityLoading, entityIdle, entitySuccess, entityFailure } from '.';
import { BillingAction, BILLING_TYPE } from 'store/actions/billing';

export type BillingConfigurationsState = BillingConfig[];
export type CalculatedBillsState = CalculatedBills[];

export type BillingState = {
  billingConfigurations: Entity<BillingConfigurationsState>;
  deleteBillingConfigurations: Entity<String>;
  calculatedBills: Entity<CalculatedBillsState>;
};

const initialState: BillingState = {
  billingConfigurations: entityIdle(),
  deleteBillingConfigurations: { state: 1, data: null },
  calculatedBills: entityIdle(),
};

export default function billingReducer(state = initialState, action: BillingAction): BillingState {
  switch (action.type) {
    case BILLING_TYPE.BILLING_CONFIGURATION_LIST_LOADING:
      return {
        ...state,
        billingConfigurations: entityLoading(),
      };
    case BILLING_TYPE.BILLING_CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        billingConfigurations: entitySuccess<BillingConfigurationsState>(action.payload),
      };
    case BILLING_TYPE.BILLING_CONFIGURATION_LIST_FAILURE:
      return {
        ...state,
        billingConfigurations: entityFailure(),
      };
    case BILLING_TYPE.BILLING_CONFIGURATION_DELETE_LOADING:
      return {
        ...state,
        deleteBillingConfigurations: entityLoading(),
      };
    case BILLING_TYPE.BILLING_CONFIGURATION_DELETE_SUCCESS:
      return {
        ...state,
        deleteBillingConfigurations: entitySuccess<String>(action.payload),
      };
    case BILLING_TYPE.BILLING_CONFIGURATION_DELETE_FAILURE:
      return {
        ...state,
        deleteBillingConfigurations: entityFailure(),
      };
    case BILLING_TYPE.CALCULATED_BILLS_LIST_LOADING:
      return {
        ...state,
        calculatedBills: entityLoading(),
      };
    case BILLING_TYPE.CALCULATED_BILLS_LIST_SUCCESS:
      return {
        ...state,
        calculatedBills: entitySuccess<CalculatedBillsState>(action.payload),
      };
    case BILLING_TYPE.CALCULATED_BILLS_LIST_FAILURE:
      return {
        ...state,
        calculatedBills: entityFailure(),
      };
    default:
      return state;
  }
}
