// React
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Intl
import { useIntl } from 'react-intl';

// Style
import { PasswordStrengthStyled } from './styled';

const PasswordStrength = ({ password }) => {
  const [passwordIndicator, setPasswordIndicator] = useState(0);
  const { formatMessage } = useIntl();

  useEffect(() => {
    var rank = 0;
    // uppercase
    const upper = /(?=.*[A-Z].*[A-Z])/g;

    // special chars
    const spec = /(?=.*[!£$%)(\<\>\[\]@#$&*])/g;

    // digits
    const dig = /(?=.*[0-9].*[0-9])/g;

    // lenght > 8
    const leng = /.{8}/g;

    rank = password.match(upper) !== null ? rank + 1 : rank;
    rank = password.match(spec) !== null ? rank + 1 : rank;
    rank = password.match(dig) !== null ? rank + 1 : rank;
    rank = password.match(leng) !== null ? rank + 1 : rank;

    setPasswordIndicator(rank);
  }, [password]);

  const label = [
    formatMessage({ id: 'login.passwordStrenght.veryweak' }),
    formatMessage({ id: 'login.passwordStrenght.weak' }),
    formatMessage({ id: 'login.passwordStrenght.soso' }),
    formatMessage({ id: 'login.passwordStrenght.good' }),
    formatMessage({ id: 'login.passwordStrenght.great' }),
  ];

  const getColor = (id) => {
    switch (passwordIndicator) {
      case 1:
        if (id < 1) {
          return 'password-weak';
        }
        break;

      case 2:
        if (id < 2) {
          return 'password-soso';
        }
        break;

      case 3:
        if (id < 3) {
          return 'password-good';
        }
        break;

      case 4:
        if (id < 4) {
          return 'password-great';
        }
        break;
    }
  };

  return (
    <PasswordStrengthStyled className="password-strength">
      <div className="password-strength-indicator">
        <div className={getColor(0)}></div>
        <div className={getColor(1)}></div>
        <div className={getColor(2)}></div>
        <div className={getColor(3)}></div>
      </div>
      <div className={`password-strength-label ${getColor(0)}`}>{label[passwordIndicator]}</div>
    </PasswordStrengthStyled>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
};

export default PasswordStrength;
