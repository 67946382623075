import { useSelector } from 'react-redux';

export function useUsimProfilesSelector() {
  const usimProfiles = useSelector((state) => state.usimProfiles);
  return usimProfiles;
}

export function useUsimProfilesListSelector() {
  const list = useSelector((state) => state.usimProfiles.list);
  return list;
}

export function useUsimProfilesFiltersSelector() {
  const filters = useSelector((state) => state.usimProfiles.filters);
  return filters;
}

export function useUsimProfileEntitySelector() {
  const usim = useSelector((state) => state.usimProfiles.entity);
  return usim;
}
