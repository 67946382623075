import { Thunk } from '.';
import { showErrorToast, showSuccessToast } from './toast';
import { fetchData } from './fetchData';
import config from 'config';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { GetResultsRes } from 'store/models/filters';
import { parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';

export enum PLMNS_ACTION_TYPE {
  LIST_LOADING = 'PLMNS_LIST_LOADING',
  LIST_SUCCESS = 'PLMNS_LIST_SUCCESS',
  LIST_FAILURE = 'PLMNS_LIST_FAILURE',
  RESET = 'PLMNS_RESET',
}

export function listLoading() {
  return {
    type: PLMNS_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: PLMNS_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: PLMNS_ACTION_TYPE.LIST_FAILURE,
  };
}

export function reset() {
  return {
    type: PLMNS_ACTION_TYPE.RESET,
  };
}

export function getPlmns(node_Id: string, page = 0): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: PLMNS_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getPlmns.replace('{node_id}', node_Id),
        method: 'GET',
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<unknown>>(options))), page };

      dispatch({
        type: PLMNS_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({ type: PLMNS_ACTION_TYPE.LIST_FAILURE });
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    }
  };
}

type createPlmnPayload = { nodeId: string; plmn: { name: string; mcc: string; mnc: string; default?: boolean } };
export function createPlmn({ nodeId, plmn }: createPlmnPayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.createPlmn.replace('{node_id}', nodeId),
        method: 'POST',
        data: {
          name: plmn.name,
          mcc: plmn.mcc,
          mnc: plmn.mnc,
        },
      };
      await dispatch(fetchData(options));
      await dispatch(getPlmns(nodeId));
      dispatch(showSuccessToast());
      return;
    } catch (e) {
      sentryLogError(e);
      const error = e as AxiosError;
      return parseError(error);
    }
  };
}
