import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Button } from '@athonet/ui/components/Input/Button';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useMemo } from 'react';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';

export function Step6({
  onCompleteStep,
  values,
  milestoneCompleted,
  error,
  loading,
  isBulkOperation,
  ...step
}: {
  values: { node?: string; profile?: string };
  error?: string | null;
  onCompleteStep: () => void;
  milestoneCompleted: boolean;
  loading: boolean;
  isBulkOperation?: boolean;
}) {
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();

  const [successTitle, successMessage] = useMemo(
    () =>
      isBulkOperation
        ? [
            formatMessage({ id: 'bulkOperations.scheduled' }),
            formatMessage({ id: 'usims.prepare.bulkOperationScheduled.message' }),
          ]
        : [
            formatMessage({ id: 'usims.prepare.success.title' }),
            formatMessage({ id: 'usims.prepare.success.message' }),
          ],
    [formatMessage, isBulkOperation]
  );

  return (
    <Step
      label={
        isBulkOperation
          ? formatMessage({ id: 'usims.form.usim.confirmPrepare.existing' })
          : formatMessage({ id: 'usims.form.usim.confirmPrepare.single' })
      }
      checkpoint
      {...step}
    >
      {milestoneCompleted ? (
        <>
          <Alert severity="success" title={successTitle} message={successMessage} />
          <Stack spacing={2} direction="row" justify="flex-end" sx={{ pt: 2 }}>
            <Button variant="outlined" text="Done" onClick={dialogClose} />
          </Stack>
        </>
      ) : (
        <Stack spacing={2} align="flex-end">
          <GridContainer>
            <GridItem size={{ xs: 12 }}>
              <Text type="h5">{formatMessage({ id: 'common.confirmValues' })}</Text>
            </GridItem>
            <KeyValueGridItem label={formatMessage({ id: 'usims.form.usim.node.label' })} value={values.node} />
            <KeyValueGridItem
              label={formatMessage({ id: 'usims.form.usim.profile_id.label' })}
              value={values.profile}
            />
          </GridContainer>
          {error && (
            <Box sx={{ width: '100%' }}>
              <Alert
                severity="error"
                title={formatMessage({ id: 'common.error' })}
                message={formatMessage({ id: 'common.edit.error' }, { message: error })}
              />
            </Box>
          )}
          <Button
            text={error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })}
            loading={loading}
            onClick={onCompleteStep}
          />
        </Stack>
      )}
    </Step>
  );
}
