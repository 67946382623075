import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';

export function BulkStep3({
  values,
  milestoneCompleted,
  children,
  ...step
}: {
  values: { tenant: AutocompleteItemProps | null; upload: File | null };
  milestoneCompleted: boolean;
  children: ReactNode;
}) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.creation.bulk' })} checkpoint {...step}>
      {milestoneCompleted ? (
        <>
          <Alert
            severity="success"
            title={formatMessage({ id: 'usims.create.bulk.success.title' })}
            message={formatMessage({ id: 'usims.create.bulk.success.message' })}
          />
          <Stack spacing={2} direction="row" justify="flex-end" sx={{ pt: 2 }}>
            <Button variant="outlined" text={formatMessage({ id: 'common.form.close' })} onClick={dialogClose} />
          </Stack>
        </>
      ) : (
        <Stack spacing={2} align="flex-end">
          <GridContainer>
            <GridItem size={{ xs: 12 }}>
              <Text type="h5">Please confirm the following values</Text>
            </GridItem>

            {values['tenant'] && (
              <KeyValueGridItem
                fullWidth
                label={formatMessage({ id: 'usims.form.usim.tenant' })}
                value={values['tenant'].label}
              />
            )}

            {values['upload'] && (
              <KeyValueGridItem
                fullWidth
                label={formatMessage({ id: 'usims.form.usim.upload' })}
                value={String(values['upload'].name)}
              />
            )}
          </GridContainer>
          {children}
        </Stack>
      )}
    </Step>
  );
}
