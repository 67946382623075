import { CreateUsimCard5g } from 'components/Edit/5gProvisioning/UsimCards/Create';
import List, { ColumnShape } from 'components/List';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useCanUserCreateUsimSelector, useCanUserUpdateUsimSelector, useUserSelector } from 'store/selectors/user';
import { checkPermissionsList, T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import { USIM5G_STATUS, Usim5g } from 'store/models/usim5g';
import { deprovisionUsim5g, getUsims5g, provisionUsim5g, setUsim5gShouldFlushSelection } from 'store/actions/usims5g';
import { useDispatch } from 'react-redux';
import { useShouldFlushUsims5gSelection, useUsims5gListSelector } from 'store/selectors/5gProvisioning/usimCards';
import { ic_settings_backup_restore as ico_deprovision } from 'react-icons-kit/md/ic_settings_backup_restore';
import DropdownTools from 'components/DropdownTools';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { ic_exit_to_app as ico_prepare_provisioning } from 'react-icons-kit/md/ic_exit_to_app';
import { ic_file_upload as ico_send } from 'react-icons-kit/md/ic_file_upload';
import { EditUsimCard5g } from 'components/Edit/5gProvisioning/UsimCards/Edit';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import UsimStatus from 'components/UsimStatus';
import { ListToolbarAction } from 'components/ListToolBar';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useNavigate } from 'react-router-dom';
import { EditNodeProfile } from 'components/Edit/5gProvisioning/UsimCards/EditNodeProfile';
import { code } from 'react-icons-kit/fa/code';
import MetadataEditorDialog from 'components/Edit/5gProvisioning/UsimCards/MetadataEditorDialog';

export default function UsimCards5g() {
  const user = useUserSelector();
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const usimsList = useUsims5gListSelector();
  const { confirmationDialogOpen, dialogOpen } = useOverlay();
  const canUserCreateUsim = useCanUserCreateUsimSelector();
  const shouldFlushUsims5gSelector = useShouldFlushUsims5gSelection();
  //const canUserDeleteUsim = useCanUserBulkDeleteUsims5gSelector();
  const canUserUpdateUsim = useCanUserUpdateUsimSelector();
  const navigate = useNavigate();

  useEffect(() => {
    void dispatch(getUsims5g({}));
  }, [bootstrap?.pageLimit, dispatch]);

  const handleSortChange = useCallback(
    (orderQuery) => {
      dispatch(getUsims5g({ sortBy: orderQuery }));
    },
    [dispatch]
  );

  const handleRefresh = useCallback(() => {
    dispatch(getUsims5g({}));
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page) => {
      dispatch(getUsims5g({ page }));
    },
    [dispatch]
  );

  const openEdit = useCallback(
    (usim5g) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.edit.title' }),
        content: () => <EditUsimCard5g usim5g={usim5g} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  //const openUsimOperationNotPossible = useCallback(
  //  ({ operation, message }) => {
  //    dialogOpen({
  //      content: () => (
  //        <DialogContent>
  //          <Alert message={message} severity="warning" title={operation} />
  //          <Stack spacing={2} fullWidth sx={{ pt: 2 }} align="flex-end">
  //            <Button text={formatMessage({ id: 'common.form.close' })} onClick={dialogClose} />
  //          </Stack>
  //        </DialogContent>
  //      ),
  //      title: formatMessage({ id: 'common.operationNotPossible' }),
  //    });
  //  },
  //  [dialogClose, dialogOpen, formatMessage]
  //);

  const openPrepareForProvisioning = useCallback(
    (rowData) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.prepare.title' }),
        content: () => <EditNodeProfile usims5g={rowData} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const getBulkOperationItems = useCallback(
    (rowData: Usim5g[]) => {
      let usims5g: Usim5g['id'][] = [];
      let singleItem;
      let elementsNumber = usimsList.data.total;

      if (Array.isArray(rowData)) {
        usims5g = rowData.map((usim5g) => usim5g.id);
        elementsNumber = rowData?.length;
        if (usims5g.length === 1) {
          singleItem = rowData[0].supi;
        }
      }
      return { usims5g, singleItem, elementsNumber };
    },
    [usimsList.data.total]
  );

  // const openUsimOperationconfirm = useCallback(
  //   ({
  //     rowData,
  //     operation,
  //     action,
  //     severity = undefined,
  //   }: {
  //     rowData: Usim5g[];
  //     operation: string;
  //     action: (items: Usim5g['id'][]) => Thunk<Promise<{ operation_id: string } | void>>;
  //     severity?: ConfirmationDialogStateProps['severity'];
  //   }) => {
  //     const { usims5g, singleItem, elementsNumber } = getBulkOperationItems(rowData);
  //     confirmationDialogOpen({
  //       description: formatMessage({ id: `usims.actions.${operation}.confirm.content` }),
  //       onConfirm: () => dispatch(action(usims5g)),
  //       severity,
  //       alertMessage: formatMessage(
  //         { id: `usims.actions.confirm.itemsAffected` },
  //         { elements: elementsNumber, usim: singleItem }
  //       ),
  //       title: formatMessage({ id: `usims.actions.${operation}.confirm.title` }, { elements: elementsNumber }),
  //       continueButtonText: formatMessage(
  //         { id: `usims.actions.${operation}.confirm.continueButton` },
  //         { elements: elementsNumber }
  //       ),
  //       dataTestid: `confirm-${operation}-usim${elementsNumber > 1 ? '-bulk' : ''}`,
  //     });
  //   },
  //   [confirmationDialogOpen, dispatch, formatMessage, getBulkOperationItems]
  // );

  const openProvision = useCallback(
    (rowData) => {
      const { usims5g, singleItem, elementsNumber } = getBulkOperationItems(rowData);
      confirmationDialogOpen({
        description: formatMessage({ id: `usims.actions.provision.confirm.content` }),
        onConfirm: () => dispatch(provisionUsim5g({ usims5g })),
        severity: 'warning',
        alertMessage: formatMessage(
          { id: `usims.actions.confirm.itemsAffected` },
          { elements: elementsNumber, usim: singleItem }
        ),
        title: formatMessage({ id: `usims.actions.provision.confirm.title` }, { elements: elementsNumber }),
        continueButtonText: formatMessage(
          { id: `usims.actions.provision.confirm.continueButton` },
          { elements: elementsNumber }
        ),
        dataTestid: `confirm-provision-usim${elementsNumber > 1 ? '-bulk' : ''}`,
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, getBulkOperationItems]
  );

  const openDeprovision = useCallback(
    (rowData) => {
      const { usims5g, singleItem, elementsNumber } = getBulkOperationItems(rowData);
      confirmationDialogOpen({
        description: formatMessage({ id: `usims.actions.deprovision.confirm.content` }),
        onConfirm: () => dispatch(deprovisionUsim5g({ usims5g })),
        severity: 'warning',
        alertMessage: formatMessage(
          { id: `usims.actions.confirm.itemsAffected` },
          { elements: elementsNumber, usim: singleItem }
        ),
        title: formatMessage({ id: `usims.actions.deprovision.confirm.title` }, { elements: elementsNumber }),
        continueButtonText: formatMessage(
          { id: `usims.actions.deprovision.confirm.continueButton` },
          { elements: elementsNumber }
        ),
        dataTestid: `confirm-deprovision-usim${elementsNumber > 1 ? '-bulk' : ''}`,
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, getBulkOperationItems]
  );

  // const openDelete: ListToolbarAction<Usim5g>['onClick'] = useCallback(
  //   (rowData) => {
  //     if (!Array.isArray(rowData)) {
  //       return;
  //     }
  //     openUsimOperationconfirm({
  //       rowData,
  //       operation: 'delete',
  //       action: (usim5g) => {
  //         dispatch(setUsim5gShouldFlushSelection(true));
  //         return deleteUsim5g(usim5g);
  //       },
  //       severity: 'danger',
  //     });
  //   },
  //   [dispatch, openUsimOperationconfirm]
  // );

  const openUsim5gMetadataEditor = useCallback(
    (usim5g: Usim5g) => {
      dialogOpen({
        title: `${formatMessage({ id: 'usims.metadata.title' })} - USIM ${usim5g.supi}`,
        content: () => <MetadataEditorDialog id={usim5g.id} metadata={usim5g.metadata} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const getTools = useCallback(
    (rowData: Usim5g) => {
      const options = checkPermissionsList(user?.permissions || [], [
        {
          icon: ico_edit,
          label: formatMessage({ id: 'usims.actions.edit.title' }),
          action: () => openEdit(rowData),
          disabled: false,
          show: true,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'edit-usim',
        },
        {
          icon: code,
          label: formatMessage({ id: 'usims.actions.metadata.title' }),
          action: () => openUsim5gMetadataEditor(rowData),
          disabled: false,
          show: true,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'edit-usim-metadata',
        },
        {
          icon: ico_prepare_provisioning,
          label: formatMessage({ id: 'usims.actions.prepare.title' }),
          action: () => openPrepareForProvisioning(rowData),
          disabled: false,
          show: Boolean(rowData.status !== USIM5G_STATUS.PROVISIONED),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'prepare-usim',
        },
        {
          icon: ico_send,
          label: formatMessage({ id: 'usims.actions.provision.title' }),
          action: () => openProvision([rowData]),
          disabled: !rowData.node_id,
          show: true,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'provision-usim',
        },
        {
          icon: ico_deprovision,
          label: formatMessage({ id: 'usims.actions.deprovision.title' }),
          action: () => openDeprovision([rowData]),
          disabled: !rowData.node_id,
          show: Boolean(rowData.status === USIM5G_STATUS.PROVISIONED),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'deprovision-usim',
        },
      ]);

      const optionsToShow = options.filter((item) => item.show !== false);

      return <DropdownTools options={optionsToShow} />;
    },
    [
      formatMessage,
      openDeprovision,
      openEdit,
      openUsim5gMetadataEditor,
      openPrepareForProvisioning,
      openProvision,
      user?.permissions,
    ]
  );

  const columns: ColumnShape<Usim5g>[] = useMemo(() => {
    if (!bootstrap) {
      return [];
    }

    return checkPermissionsList(
      [user?.tenant_type],
      [
        {
          key: 'supi',
          title: formatMessage({ id: 'usims.table.supi' }),
          dataKey: 'supi',
          sortable: true,
          width: 180,
          maxWidth: 250,
          minWidth: 160,
          resizable: true,
          cellRenderer: ({ cellData: supi }) => {
            return (
              <Link
                onClick={() => {
                  navigate(`/subscribers/${supi}`);
                }}
              >
                <Text type={'body2'}>{supi}</Text>
              </Link>
            );
          },
        },
        {
          key: 'name',
          title: formatMessage({ id: 'usims.table.name' }),
          dataKey: 'name',
          sortable: true,
          width: 160,
          maxWidth: 160,
          minWidth: 100,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable,
        },
        {
          key: 'msisdn',
          title: formatMessage({ id: 'usims.table.msisdn' }),
          dataKey: 'msisdn',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
        },
        {
          key: 'status',
          title: formatMessage({ id: 'usims.id.details.provisioningStatus' }),
          dataKey: 'status',
          sortable: true,
          visible: true,
          width: 160,
          maxWidth: 180,
          minWidth: 120,
          cellRenderer: ({ cellData: status }) => {
            return <UsimStatus status={status} error={undefined} updating={undefined} />;
          },
        },
        {
          key: 'tenant_id',
          title: formatMessage({ id: 'usims.table.tenantName' }),
          dataKey: 'tenant.name',
          sortable: false,
          width: 150,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
        },
        {
          key: 'node_id',
          title: formatMessage({ id: 'usims.table.node' }),
          dataKey: 'node_id',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
          cellRenderer: ({ cellData, rowData: { node_name } }) => {
            return (
              <Link
                onClick={() => {
                  navigate(`/networks/nodes/${cellData}`);
                }}
              >
                <Text type={'body2'}>{node_name}</Text>
              </Link>
            );
          },
        },
        {
          key: 'profile_id',
          title: formatMessage({ id: 'usims.table.profile_id' }),
          dataKey: 'profile_id',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
          cellRenderer: ({ cellData, rowData: { profile_name } }) => profile_name,
        },
        {
          key: 'tools',
          title: '',
          dataKey: 'tools',
          width: 60,
          maxWidth: 60,
          minWidth: 60,
          secret: true, // secret used to hide from columns management panel
          cellRenderer: ({ rowData }) => {
            return getTools(rowData);
          },
        },
      ]
    );
  }, [bootstrap, formatMessage, getTools, navigate, user]);

  const actionsData = useMemo(() => {
    let actions: ListToolbarAction<Usim5g>[] = [];

    // if (bootstrap?.delete_usim && canUserDeleteUsim) {
    //   actions.push({
    //     label: formatMessage({ id: 'usims5g.actions.delete.title' }),
    //     onClick: openDelete,
    //   });
    // }
    if (canUserUpdateUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.prepare.title' }),
        onClick: openPrepareForProvisioning,
      });
    }
    if (canUserUpdateUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.provision.title' }),
        onClick: openProvision,
      });
    }
    if (canUserUpdateUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.deprovision.title' }),
        onClick: openDeprovision,
      });
    }

    return actions.length ? actions : undefined;
  }, [canUserUpdateUsim, formatMessage, openDeprovision, openPrepareForProvisioning, openProvision]);

  return (
    <List
      selectable
      columns={columns}
      totalRows={usimsList.data.total}
      data={usimsList.data.data}
      page={usimsList.data.page}
      filters={{}}
      actions={actionsData}
      onOrderChange={handleSortChange}
      {...(canUserCreateUsim && { createComponent: <CreateUsimCard5g /> })}
      toolbar={{ filters: false, actions: Boolean(actionsData?.length) }}
      loadingState={usimsList.state}
      onRefresh={handleRefresh}
      setFlushSelectionAction={setUsim5gShouldFlushSelection}
      shouldFlushSelection={shouldFlushUsims5gSelector}
      rowsPerPage={bootstrap?.pageLimit}
      onPageChange={handlePageChange}
    />
  );
}
