import { QosProfile4G } from './../models/qosProfile4G';
import { AxiosRequestConfig } from 'axios';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import config from 'config';
import { sentryLogError } from 'sentry';

export const FILTERS_LOADING = 'QOS_PROFILES_FILTERS_LOADING';
export const FILTERS_SUCCESS = 'QOS_PROFILES_FILTERS_SUCCESS';
export const FILTERS_FAILURE = 'QOS_PROFILES_FILTERS_FAILURE';
export const FILTERS_SET = 'QOS_PROFILES_FILTERS_SET';
export const FILTERS_INJECT = 'QOS_PROFILES_FILTERS_INJECT';

export const SORT_SET = 'QOS_PROFILES_SORT_SET';

export const LIST_LOADING = 'QOS_PROFILES_LIST_LOADING';
export const LIST_SUCCESS = 'QOS_PROFILES_LIST_SUCCESS';
export const LIST_FAILURE = 'QOS_PROFILES_LIST_FAILURE';
export const LIST_CLEAR = 'QOS_PROFILES_LIST_CLEAR';

export const ENTITY_LOADING = 'QOS_PROFILES_ENTITY_LOADING';
export const ENTITY_SUCCESS = 'QOS_PROFILES_ENTITY_SUCCESS';
export const ENTITY_FAILURE = 'QOS_PROFILES_ENTITY_FAILURE';

export function filtersLoading() {
  return {
    type: FILTERS_LOADING,
  };
}

export function filtersSuccess(payload: unknown) {
  return {
    type: FILTERS_SUCCESS,
    payload,
  };
}

export function filtersFailure() {
  return {
    type: FILTERS_FAILURE,
  };
}

export function filtersSet(payload: unknown) {
  return {
    type: FILTERS_SET,
    payload,
  };
}

export function filtersInject(payload: unknown) {
  return {
    type: FILTERS_INJECT,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: LIST_FAILURE,
  };
}
export function listClear() {
  return {
    type: LIST_CLEAR,
  };
}

export function getQosProfile(id: QosProfile4G['id'], refresh: boolean = false): Thunk<Promise<void>> {
  return async (dispatch) => {
    if (!refresh) {
      dispatch({
        type: ENTITY_LOADING,
      });
    }
    const options: AxiosRequestConfig = {
      url: config.apis.getQoSProfile.replace('{id}', id),
    };

    try {
      const profiles = await dispatch(fetchData<{ items: QosProfile4G[] }>(options));
      if (!profiles.items.length) {
        throw new Error('No profile found');
      }
      const profile = profiles.items[0];

      dispatch({
        type: ENTITY_SUCCESS,
        payload: profile,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: ENTITY_FAILURE,
      });
    }
  };
}
