import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';
import { theme } from 'theme';

import { MasonryStyled } from './styled';

const BreakpointMasonry = ({ xs, sm, md, lg, xl, children }) => {
  const getXL = () => {
    if (xl) {
      return xl;
    } else if (lg) {
      return lg;
    } else if (md) {
      return md;
    } else if (sm) {
      return sm;
    } else if (xs) {
      return xs;
    } else {
      return 4;
    }
  };

  const getLG = () => {
    if (lg) {
      return lg;
    } else if (md) {
      return md;
    } else if (sm) {
      return sm;
    } else if (xs) {
      return xs;
    } else {
      return 3;
    }
  };

  const getMD = () => {
    if (md) {
      return md;
    } else if (sm) {
      return sm;
    } else if (xs) {
      return xs;
    } else {
      return 2;
    }
  };

  const getSM = () => {
    if (sm) {
      return sm;
    } else if (xs) {
      return xs;
    } else {
      return 1;
    }
  };

  const getXS = () => {
    if (xs) {
      return xs;
    } else {
      return 1;
    }
  };

  const breakpointCols = {
    default: getXL(),
    [theme.mediaquery.large]: getXL(),
    [theme.mediaquery.desktop]: getLG(),
    [theme.mediaquery.tablet]: getMD(),
    [theme.mediaquery.phone]: getSM(),
    [theme.mediaquery.smallphone]: getXS(),
  };

  return (
    <MasonryStyled>
      <Masonry breakpointCols={breakpointCols} className="masonry-grid" columnClassName="masonry-column">
        {children}
      </Masonry>
    </MasonryStyled>
  );
};

BreakpointMasonry.propTypes = {
  children: PropTypes.node,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
};

export default BreakpointMasonry;
