import 'moment/locale/it';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getBootstrap } from 'store/actions/bootstrap';
import { getBulkOperations } from 'store/actions/bulkOperations';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useLocaleSelector } from 'store/selectors/locale';
import { useUserSelector } from 'store/selectors/user';
import { getTranslations } from 'utils/locale';
import moment from 'moment';
import { initSentry, sentrySetUser } from 'sentry';

type BootstrapProps = {
  children: JSX.Element;
};

export default function Bootstrap({ children }: BootstrapProps) {
  const dispatch = useDispatch();
  const bootstrap = useBootstrapSelector();
  const user = useUserSelector();
  const locale = useLocaleSelector();
  const sentryInitialized = useRef(false);

  const [bulkOperationsPolling, setBulkOperationsPolling] = useState(false);

  useEffect(() => {
    dispatch(getBootstrap());
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      return;
    }
    sentrySetUser({
      id: user.id,
      username: user.name,
      tenant_id: user.tenant_id,
      tenant_name: user.tenant_name,
    });
    dispatch(getBulkOperations());
  }, [dispatch, user]);

  const pollBulkOperations = useCallback(async () => {
    if (!user) {
      return;
    }
    if (window.location.pathname.includes('adminconfig')) {
      return;
    }
    setBulkOperationsPolling(true);
    await dispatch(getBulkOperations());
    setBulkOperationsPolling(false);
  }, [dispatch, user]);

  useEffect(() => {
    if (bulkOperationsPolling) {
      return;
    }

    const timeout = window.setTimeout(() => {
      void pollBulkOperations();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [bulkOperationsPolling, pollBulkOperations]);

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  useEffect(() => {
    if (!bootstrap || sentryInitialized.current) {
      return;
    }
    initSentry(bootstrap);
    sentryInitialized.current = true;
  }, [bootstrap]);

  const localeMessages = useMemo(() => {
    return getTranslations(locale);
  }, [locale]);

  return bootstrap ? (
    <IntlProvider locale={locale} messages={localeMessages}>
      {children}
    </IntlProvider>
  ) : null;
}
