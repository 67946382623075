import { List, listIdle, DATA_LIFECYCLE } from '.';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loadUsimOrdersOptions } from 'store/actions/usimOrders/loadUsimOrdersOptions';
import { getUsimOrders } from 'store/actions/usimOrders/getUsimOrders';
import { UsimOrder } from 'store/models/usimOrder';
import { FiltersObj } from 'store/models/filters';

export type StringSelectOption = {
  label: string;
  value: string;
};

export type CreateUsimOrderOptions = {
  msisdnPools: StringSelectOption[];
  imsiPools: StringSelectOption[];
  imsi2Pools: StringSelectOption[];
  privateKeys: StringSelectOption[];
  keys: StringSelectOption[];
};

export type UsimOrdersState = {
  filters: FiltersObj;
  options: CreateUsimOrderOptions;
  sort: string;
  list: List<UsimOrder>;
};

const initialState: UsimOrdersState = {
  filters: {},
  options: {
    imsi2Pools: [],
    imsiPools: [],
    msisdnPools: [],
    privateKeys: [],
    keys: [],
  },
  sort: '',
  list: listIdle(),
};

const usimOrdersSlice = createSlice({
  name: 'usimOrdersSlice',
  initialState,
  reducers: {
    clearUsimOrderList: (state: Draft<UsimOrdersState>) => {
      state.list = listIdle();
    },
    setUsimOrderFilters: (state: Draft<UsimOrdersState>, action: PayloadAction<FiltersObj>) => {
      state.filters = action.payload;
    },
    setUsimOrderPageNumber: (state: Draft<UsimOrdersState>, action: PayloadAction<number>) => {
      state.list.data.page = action.payload;
    },
    setUsimOrderSort: (state: Draft<UsimOrdersState>, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUsimOrdersOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      })
      .addCase(getUsimOrders.pending, (state, action) => {
        state.list.state = DATA_LIFECYCLE.LOADING; //TODOPS it creates another render
      })
      .addCase(getUsimOrders.fulfilled, (state, action) => {
        state.list.data = action.payload;
        state.list.state = DATA_LIFECYCLE.SUCCESS;
      })
      .addCase(getUsimOrders.rejected, (state, action) => {
        state.list.state = DATA_LIFECYCLE.FAILURE;
      });
  },
});

export const { setUsimOrderPageNumber, setUsimOrderSort, setUsimOrderFilters, clearUsimOrderList } =
  usimOrdersSlice.actions;

const usimOrdersReducer = usimOrdersSlice.reducer;
export default usimOrdersReducer;
