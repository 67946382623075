import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { ResponsiveControls } from '@athonet/ui/components/Navigation/ResponsiveControls';
import { DateRange } from '@athonet/ui/components/Input/DateRange';
import { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { getCalculatedBills } from 'store/actions/billing/calculatedBills';
import { useDispatch } from 'react-redux';

export default function CalculatedBillsToolbar() {
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const [startDate, setStartDate] = useState(moment().startOf('month').format(dateFormat));

  const handleChange = useCallback(
    (eDate: Moment) => {
      const date = moment(eDate).format(dateFormat);
      setStartDate(date);
      dispatch(getCalculatedBills(date));
    },
    [dispatch]
  );

  return (
    <Box sx={{ pb: 2 }}>
      <Toolbar>
        <Stack direction="row" justify="flex-end" align="center" fullWidth>
          <ResponsiveControls wide>
            <DateRange
              onChange={(_period, _sDate, eDate) => {
                handleChange(eDate);
              }}
              startDate={moment(startDate, dateFormat)}
              color={'primary'}
              singleDate={true}
              period={'month'}
              periods={['month']}
              textFieldRender
              data-testid="calculatedbills-toolbar-date-daterange"
            />
          </ResponsiveControls>
        </Stack>
      </Toolbar>
    </Box>
  );
}
