import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { NewNodeStepProps } from './CreateNodeContent';
import { getNodesByName } from 'store/actions/nodes';
import { useDispatch } from 'react-redux';

export function Step2({ onCompleteStep, initialValues }: NewNodeStepProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const validationSchema = useMemo(() => {
    return object().shape({
      display_name: string().required().trim(),
    });
  }, []);

  const handleSubmit = useCallback(
    async (values, { setFieldError }) => {
      const nodesList = await dispatch(getNodesByName(values.display_name));
      nodesList.length === 0 ? onCompleteStep(1, values) : setFieldError('display_name', 'alreadyExist');
    },
    [dispatch, onCompleteStep]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, touched, isSubmitting }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
              <Field name="display_name">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...field}
                    label={formatMessage({ id: 'nodes.form.display_name.label' })}
                    placeholder={formatMessage({ id: 'nodes.form.display_name.placeholder' })}
                    error={Boolean(errors['display_name'] && touched['display_name'])}
                    {...(Boolean(errors['display_name'] && touched['display_name']) && {
                      helperText: formatMessage({ id: 'nodes.form.display_name.error' }),
                    })}
                    {...(Boolean(
                      errors['display_name'] && touched['display_name'] && errors.display_name === 'alreadyExist'
                    ) && {
                      helperText: formatMessage({ id: 'nodes.form.display_name.alreadyExist.error' }),
                    })}
                  />
                )}
              </Field>

              <Button
                type="submit"
                loading={isSubmitting}
                variant="outlined"
                text={formatMessage({ id: 'common.form.next' })}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
