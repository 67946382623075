import { useCallback, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createUser } from 'store/actions/users';
import { SelectOption } from 'utils/forms';
import { Multiselect } from 'components/Form/Multiselect';
import { getTenantsOptions, TenantOption } from 'store/actions/tenants';
import { useBootstrapSelector, useCreateUserAuthProvidersSelector } from 'store/selectors/bootstrap';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { FastField, Field, FieldProps, Form, Formik } from 'formik';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Button } from '@athonet/ui/components/Input/Button';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { Fieldset } from '@athonet/ui/components/Input/Fieldset';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { showSuccessToast } from 'store/actions/toast';
import { Alert } from '@athonet/ui/components/Feedback/Alert';

type CreateProps = {
  dataOptions: {
    roles?: AutocompleteItemProps[];
    tenants?: SelectOption[];
  };
};

const CreateUserContent = ({ dataOptions }: CreateProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const bootstrap = useBootstrapSelector();
  const { dialogClose } = useOverlay();
  const createUserAuthProviders = useCreateUserAuthProvidersSelector();
  const [error, setError] = useState('');
  const pattern = useMemo(
    () => /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/,
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(createUser({ ...values, tenantId: values.tenant.value.id, role: values.role.value }));
        dialogClose();
        void dispatch(showSuccessToast());
      } catch (e: any) {
        if (e.response?.data.error?.unique_name) {
          setError(formatMessage({ id: `users.form.user.error.email.unique.${values.realm}` }));
        } else {
          setError(formatMessage({ id: 'users.form.user.error' }));
        }
      }
    },
    [dialogClose, dispatch, formatMessage]
  );

  const schema = useMemo(
    () =>
      object().shape({
        tenant: object()
          .shape({
            label: string().required(),
            value: object().shape({ type: string(), id: string().required() }),
          })
          .required(),
        full_name: string().required(),
        role: object()
          .shape({
            label: string().required(),
            value: string().required(),
          })
          .required(),
        phone: string(),
        realm: string().oneOf(Object.values(AUTH_PROVIDER)).required(),
        email: string().when('realm', {
          is: AUTH_PROVIDER.ENTERPRISE,
          then: () => string().email().required(),
          otherwise: () => string().email(),
        }),
        password: string().when('realm', {
          is: AUTH_PROVIDER.ENTERPRISE,
          then: () =>
            string()
              .test('password', formatMessage({ id: 'changePassword.requiredNewpasswordError' }), (value) =>
                value ? pattern.test(value.toString()) : false
              )
              .required(formatMessage({ id: 'changePassword.requiredNewpasswordError' })),
          otherwise: () => string(),
        }),
        name: string().when('realm', {
          is: AUTH_PROVIDER.LDAP,
          then: () => string().required(),
          otherwise: () => string(),
        }),
      }),
    [formatMessage, pattern]
  );

  const initialOption = useMemo(
    () => ({
      label: '',
      value: { id: '', type: '' },
    }),
    []
  );

  const initials = useMemo(
    () => ({
      tenant: initialOption,
      full_name: '',
      role: null,
      phone: '',
      email: '',
      password: '',
      realm: createUserAuthProviders[0],
      name: '',
    }),
    [createUserAuthProviders, initialOption]
  );

  if (!bootstrap) return null;

  return (
    <Stack sx={{ width: { xs: '600px' }, p: 2 }} nowrap>
      {error && <Alert severity="error" message={formatMessage({ id: error })} />}
      <Formik initialValues={initials} onSubmit={handleSubmit} validationSchema={schema}>
        {({ isSubmitting, values, setFieldValue, errors, touched }) => {
          return (
            <Form noValidate autoComplete="off">
              <Fieldset label={formatMessage({ id: 'users.form.user.data' })} direction="column" spacing={2}>
                <FastField name="tenant">
                  {({ field }: FieldProps<TenantOption>) => (
                    <Multiselect
                      {...field}
                      options={[initialOption]}
                      asyncGetOptions={async (val: any) => {
                        return dispatch(getTenantsOptions(val));
                      }}
                      selectAll={false}
                      onChange={(v) => {
                        setFieldValue('tenant', v);
                      }}
                      setFieldValue={setFieldValue}
                      label={formatMessage({ id: 'users.form.user.tenant.label' })}
                      placeholder={formatMessage({ id: 'users.form.user.tenant.placeholder' })}
                      error={Boolean(errors['tenant'] && touched['tenant'])}
                      {...(Boolean(errors['tenant'] && touched['tenant']) && {
                        helperText: formatMessage({ id: 'users.form.user.tenant.error' }),
                      })}
                      disabled={isSubmitting}
                    />
                  )}
                </FastField>
                <Field name="full_name">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      label={formatMessage({ id: 'users.form.user.fullname.label' })}
                      placeholder={formatMessage({ id: 'users.form.user.fullname.placeholder' })}
                      error={Boolean(errors['full_name'] && touched['full_name'])}
                      {...(Boolean(errors['full_name'] && touched['full_name']) && {
                        helperText: formatMessage({ id: 'users.form.user.fullname.error' }),
                      })}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
                <Field name="role">
                  {({ field }: FieldProps<AutocompleteItemProps>) => (
                    <Autocomplete
                      size="small"
                      {...field}
                      label={formatMessage({ id: 'users.form.user.role.label' })}
                      placeholder={formatMessage({ id: 'users.form.user.role.placeholder' })}
                      options={dataOptions.roles || []}
                      error={Boolean(errors['role'] && touched['role'])}
                      {...(Boolean(errors['role'] && touched['role']) && {
                        helperText: formatMessage({ id: 'users.form.user.role.error' }),
                      })}
                      onChange={(_, v) => {
                        setFieldValue('role', v);
                      }}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      label={formatMessage({ id: 'users.form.user.phone.label' })}
                      placeholder={formatMessage({ id: 'users.form.user.phone.placeholder' })}
                      error={Boolean(errors['phone'] && touched['phone'])}
                      {...(Boolean(errors['phone'] && touched['phone']) && {
                        helperText: formatMessage({ id: 'users.form.user.phone.error' }),
                      })}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
              </Fieldset>
              <Fieldset
                label={formatMessage(
                  { id: 'users.form.user.auth' },
                  {
                    value:
                      createUserAuthProviders.length === 1
                        ? formatMessage({ id: `authProvider.${createUserAuthProviders[0]}` })
                        : '',
                  }
                )}
                direction="column"
                spacing={2}
              >
                <Field name="realm">
                  {({ field }: FieldProps<AUTH_PROVIDER>) =>
                    createUserAuthProviders.length > 1 ? (
                      <ToggleButtonGroup
                        {...field}
                        data-testid="field-realm"
                        size="small"
                        color={'secondary'}
                        exclusive={true}
                        value={values['realm']}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            setFieldValue('realm', newValue);
                            setError('');
                          }
                        }}
                      >
                        {createUserAuthProviders.map((provider) => (
                          <ToggleButton
                            value={provider}
                            disabled={values['realm'] === provider || isSubmitting}
                            data-testid={`field-input-realm-${provider})}`}
                            key={provider}
                          >
                            {formatMessage({ id: `authProvider.${provider}` })}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    ) : (
                      <></>
                    )
                  }
                </Field>
                {values['realm'] === AUTH_PROVIDER.ENTERPRISE && (
                  <>
                    <Field name="email">
                      {({ field }: FieldProps<string>) => {
                        return (
                          <TextField
                            size="small"
                            {...field}
                            label={formatMessage({ id: 'users.form.user.email.label' })}
                            placeholder={formatMessage({ id: 'users.form.user.email.placeholder' })}
                            error={Boolean(errors['email'] && touched['email'])}
                            {...(Boolean(errors['email'] && touched['email']) && {
                              helperText: formatMessage({ id: 'users.form.user.email.error' }),
                            })}
                            disabled={isSubmitting}
                          />
                        );
                      }}
                    </Field>
                    <Field name="password">
                      {({ field }: FieldProps<string>) => (
                        <TextField
                          size="small"
                          {...field}
                          label={formatMessage({ id: 'users.form.user.password.label' })}
                          placeholder={formatMessage({ id: 'users.form.user.password.placeholder' })}
                          error={Boolean(errors['password'] && touched['password'])}
                          {...(Boolean(errors['password'] && touched['password']) && {
                            helperText: errors['password'],
                          })}
                          type="password"
                          showPasswordVisibility
                          disabled={isSubmitting}
                          helperText={
                            touched.password ? errors.password : formatMessage({ id: 'changePassword.rules' })
                          }
                        />
                      )}
                    </Field>
                  </>
                )}
                {values['realm'] === AUTH_PROVIDER.LDAP && (
                  <Field name="name">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        size="small"
                        {...field}
                        label={formatMessage({ id: 'users.form.user.username.label' })}
                        placeholder={formatMessage({ id: 'users.form.user.username.placeholder' })}
                        error={Boolean(errors['name'] && touched['name'])}
                        {...(Boolean(errors['name'] && touched['name']) && {
                          helperText: formatMessage({ id: 'users.form.user.username.error' }),
                        })}
                        disabled={isSubmitting}
                      />
                    )}
                  </Field>
                )}
              </Fieldset>
              <DialogActions>
                <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                  <Button
                    variant="outlined"
                    data-testid="footer-cancel"
                    onClick={dialogClose}
                    text={formatMessage({ id: 'common.form.cancel' })}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                  <Button
                    data-testid="footer-continue"
                    type="submit"
                    text={formatMessage({ id: 'common.form.continue' })}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </Stack>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default function Create({ dataOptions }: CreateProps) {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();
  const bootstrap = useBootstrapSelector();

  const handleOpenCreate = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'users.newUser' }),
      content: () => <CreateUserContent dataOptions={dataOptions} />,
    });
  }, [dataOptions, dialogOpen, formatMessage]);

  if (!bootstrap) return null;

  return (
    <Button
      onClick={handleOpenCreate}
      text={formatMessage({ id: 'users.newUser' })}
      data-testid="toolbar-new-button"
      startIcon="Add"
      variant="outlined"
    />
  );
}
