import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import moment from 'moment';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import theme from '@athonet/ui/theme';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { tenantsCellRenderer } from 'utils/tenantsCellRenderer';
import { MapBox } from '@athonet/ui/components/Data/MapBox';
import { useCanUserUpdateNodeSelector } from 'store/selectors/user';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useCallback, useMemo } from 'react';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { EditNode5g } from 'components/Edit/Nodes/EditNode5g';
import { useIsNodeLegacySelector, useNodeEntitySelector } from 'store/selectors/nodes';
import { LegacyNodeContent } from '../Legacy/LegacyNodeContent';

export function NodeDetails() {
  const { formatMessage } = useIntl();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const bootstrap = useBootstrapSelector();
  const { dialogOpen } = useOverlay();
  const nodeEntity = useNodeEntitySelector();
  const isNodeLegacy = useIsNodeLegacySelector();

  const entity = useMemo(() => {
    return nodeEntity.data;
  }, [nodeEntity]);

  const handleOpenEdit = useCallback(() => {
    if (!entity) {
      return;
    }

    dialogOpen({
      title: formatMessage({ id: 'nodes.editNode' }),
      content: () => (
        <EditNode5g
          node={entity}
          initials={{
            endpoint: entity.endpoint,
            username: entity.username,
            password: entity.password,
          }}
        />
      ),
    });
  }, [dialogOpen, entity, formatMessage]);

  const handleOpenEditGeneralInfo = useCallback(() => {
    if (!entity) {
      return;
    }

    dialogOpen({
      title: formatMessage({ id: 'nodes.editNode' }),
      content: () => (
        <EditNode5g
          node={entity}
          initials={{
            display_name: entity.display_name,
            address: entity.address,
            country: entity.country,
            lat: entity.lat,
            long: entity.long,
          }}
        />
      ),
    });
  }, [dialogOpen, entity, formatMessage]);

  const nodeLocation = useMemo(() => {
    if (!entity) {
      return;
    }

    if ((entity.long || entity.long === 0) && (entity.lat || entity.lat === 0)) {
      return {
        center: [entity.long, entity.lat],
        address: entity.address || '',
        text: entity.display_name,
      };
    }
    return null;
  }, [entity]);

  const addressCountry = useMemo(() => {
    if (!entity) {
      return;
    }

    return entity.address && entity.country
      ? `${entity.address}, ${entity.country}`
      : entity.address || entity.country || null;
  }, [entity]);

  const isMapAvailable = useMemo(
    () => nodeLocation && bootstrap && bootstrap.mapbox_token && bootstrap.mapbox_styles,
    [bootstrap, nodeLocation]
  );

  if (!entity) {
    return null;
  }

  return isNodeLegacy ? (
    <LegacyNodeContent />
  ) : (
    <GridContainer sx={{ mt: -2 }}>
      <GridItem size={{ xs: 12, md: isMapAvailable ? 3 : 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <Panel title={formatMessage({ id: 'nodes.detail.nodeType' })}>
          <PanelContent>
            <Stack justify="space-between" fullHeight>
              <KeyValueGridItem label={formatMessage({ id: 'nodes.detail.nodeType' })} value={entity.product_version} />
              <Box>
                <Text color={theme.palette.grey[500]} type="body2">
                  {formatMessage({ id: 'common.detail.createdAt' })}
                </Text>
                <Text color={theme.palette.grey[500]} type="body2" sx={{ mb: 2 }}>
                  {moment(entity.created_at).format('MMMM DD, YYYY HH:mm:ss')}
                </Text>
                <Text color={theme.palette.info.dark} type="body2" sx={{ textDecoration: 'underline' }}>
                  {formatMessage({ id: 'common.detail.lastEdit' })}
                </Text>
                <Text color={theme.palette.info.dark} type="body2" sx={{ textDecoration: 'underline' }}>
                  {moment(entity.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                </Text>
              </Box>
            </Stack>
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem size={{ xs: 12, md: isMapAvailable ? 9 : 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <GridContainer>
          <GridItem
            size={{
              xs: 12,
              md: isMapAvailable ? 5 : 12,
            }}
            sx={{ display: 'flex', alignContent: 'stretch' }}
          >
            <Panel
              title={formatMessage({ id: 'common.detail.generalInfo' })}
              headerActionsComponent={
                <IconButton
                  name="Pencil"
                  onClick={handleOpenEditGeneralInfo}
                  fontSize="small"
                  disabled={!canUserUpdateNode}
                />
              }
            >
              <PanelContent>
                <GridContainer>
                  <KeyValueGridItem
                    fullWidth
                    label={formatMessage({ id: 'common.detail.ownership' })}
                    valueComponent={tenantsCellRenderer(entity.owners)}
                  />
                  <KeyValueGridItem
                    fullWidth
                    label={formatMessage({ id: 'nodes.table.name' })}
                    value={entity.display_name}
                  />
                  {addressCountry && <KeyValueGridItem fullWidth label="Location" value={addressCountry} />}
                </GridContainer>
              </PanelContent>
            </Panel>
          </GridItem>
          {nodeLocation && bootstrap?.mapbox_token && bootstrap?.mapbox_styles && (
            <GridItem size={{ xs: 12, md: 7 }} sx={{ ml: { xs: 0, md: -2 }, mt: { xs: -2, md: 0 } }}>
              <MapBox
                id="node-detail-map"
                centerAddress={nodeLocation.center}
                height={320}
                locations={[nodeLocation]}
                markers
                zoom={5}
                data-testid="node-detail-map"
                accessToken={bootstrap.mapbox_token}
                stylesUrl={bootstrap.mapbox_styles}
              />
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
        <Panel
          title={formatMessage({ id: 'nodes.detail.conection' })}
          headerActionsComponent={
            <IconButton name="Pencil" onClick={handleOpenEdit} fontSize="small" disabled={!canUserUpdateNode} />
          }
        >
          <PanelContent>
            <GridContainer>
              <KeyValueGridItem label={formatMessage({ id: 'nodes.detail.endpoint' })} value={entity.endpoint} />
              <KeyValueGridItem label={formatMessage({ id: 'nodes.detail.username' })} value={entity.username} />
              <KeyValueGridItem
                label={formatMessage({ id: 'nodes.detail.password' })}
                valueComponent={
                  <Status
                    label={entity.password ? 'Set' : 'Not Set'}
                    status={entity.password ? STATUS.SUCCESS : STATUS.DEFAULT}
                  />
                }
              />
            </GridContainer>
          </PanelContent>
        </Panel>
      </GridItem>
    </GridContainer>
  );
}
