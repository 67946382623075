import {
  CdrAvailabilityDates,
  CdrAvailabilityPeriodRange,
  CdrChangesHistory,
  CdrImeiChangesMetricsField,
  CdrSearch,
  CdrTimeSeriesQuery,
  CdrTimeSeriesQueryData,
  CdrTimeSeriesSchema,
} from './../../models/cdr';
import { fetchData } from 'store/actions/fetchData';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import moment from 'moment';
import {
  CdrDateRange,
  CdrFilter,
  CdrImeiChangesSeries,
  CdrImeiChangesTotal,
  CdrPeriod,
  CdrRanking,
  CdrRankingCalendarDate,
  CdrRankingGroupBy,
  CdrSchema,
} from 'store/models/cdr';
import {
  CdrImeiChangesHistoryPayload,
  CdrRankingPayload,
  CdrSeriesPayload,
  CdrTotalPayload,
  CDR_ACTION_TYPE,
  GetCdrSearchAction,
  GetCdrSeriesActionArgs,
  getCdrTimeseriesData,
} from '.';
import { Thunk } from '..';
import { showErrorToast } from '../toast';
import { getCdrImeichangesMetricOptions } from 'store/selectors/cdrReports/imeiChanges';
import { getFileName } from 'utils/getFileName';
import { exportViews } from '../exportViews';
import { sentryLogError } from 'sentry';

export function getCdrImeiChangesTotal(payload: CdrTotalPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.total,
      method: 'POST',
      data: {
        ...payload,
        start_date: payload.start_date.format('YYYY-MM-DD'),
        end_date: payload.end_date.format('YYYY-MM-DD'),
      },
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_LOADING });

      const total = await dispatch(fetchData<CdrImeiChangesTotal>(options));
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_SUCCESS, payload: total });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_FAILURE });
    }
  };
}

export function getCdrImeiChangesSeries({
  payload,
  actions: { loadingAction, successAction, failureAction },
}: GetCdrSeriesActionArgs<CdrSeriesPayload>): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.series,
      method: 'POST',
      data: {
        ...payload,
        start_date: payload.start_date.format('YYYY-MM-DD'),
        end_date: payload.end_date.format('YYYY-MM-DD'),
      },
    };
    try {
      dispatch({ type: loadingAction });
      const imeiChangesSeries = await dispatch(fetchData<CdrImeiChangesSeries>(options));
      dispatch({ type: successAction, payload: imeiChangesSeries });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: failureAction });
    }
  };
}

export function getCdrImeiChangesTrendSeries(payload: CdrSeriesPayload) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_LOADING,
    successAction: CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_SUCCESS,
    failureAction: CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_FAILURE,
  };

  return getCdrImeiChangesSeries({ payload, actions });
}

export function getCdrImeiChangesRankingSeries(payload: CdrSeriesPayload) {
  const actions = {
    loadingAction: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_LOADING,
    successAction: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_SUCCESS,
    failureAction: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_FAILURE,
  };

  return getCdrImeiChangesSeries({ payload, actions });
}

export function getCdrImeiChangesAvailabilyDates(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.availability_dates,
      method: 'POST',
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_LOADING });
      const imeiChangesAvailabilityDates = await dispatch(fetchData<CdrAvailabilityDates>(options));
      dispatch({
        type: CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_SUCCESS,
        payload: imeiChangesAvailabilityDates,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_LOADING });
    }
  };
}

export function getCdrImeiChangesSchema(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      cdrReportsImeiChanges: {
        imeiChangesRankingGroupBy,
        imeiChangesRankingMetric,
        imeiChangesPeriod,
        imeiChangesDateRange,
      },
    } = getState();
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.schema,
      method: 'POST',
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_LOADING });
      const imeiChangesSchema = await dispatch(fetchData<CdrSchema>(options));

      if (!imeiChangesPeriod) {
        dispatch(setCdrImeiChangesPeriod(imeiChangesSchema.global.default_period.period));
      }

      if (!imeiChangesDateRange) {
        const start = moment(imeiChangesSchema.global.default_period.start_date);
        const end = moment(imeiChangesSchema.global.default_period.end_date);
        dispatch(
          setCdrImeiChangesDateRange({
            start,
            end,
          })
        );
      }

      if (!imeiChangesRankingGroupBy) {
        dispatch(setCdrImeiChangesGroupBy(imeiChangesSchema.global.group[0]));
      }
      if (!imeiChangesRankingMetric) {
        const imeiChangesMetricOptions = getCdrImeichangesMetricOptions(imeiChangesSchema.schema);
        dispatch(setCdrImeiChangesMetric(imeiChangesMetricOptions[0].value));
      }
      dispatch({
        type: CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_SUCCESS,
        payload: imeiChangesSchema,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_FAILURE });
    }
  };
}

export function getCdrImeiChangesRanking(payload: CdrRankingPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.ranking,
      method: 'POST',
      data: {
        ...payload,
        date: payload.date.format('YYYY-MM-DD'),
        type: 'collapsed_topbottom',
      },
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_LOADING });
      const imeiChangesRanking = await dispatch(fetchData<CdrRanking<string, string>>(options));
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SUCCESS, payload: imeiChangesRanking });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_FAILURE });
    }
  };
}

export function getCdrImeiChangesHistory(payload: CdrImeiChangesHistoryPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.history,
      method: 'POST',
      data: {
        ...payload,
        start_date: payload.start_date.format('YYYY-MM-DD'),
        end_date: payload.end_date.format('YYYY-MM-DD'),
      },
    };
    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_LOADING });
      const imeiChangesHistory = await dispatch(fetchData<CdrChangesHistory>(options));
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_SUCCESS, payload: imeiChangesHistory });
    } catch (e) {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_FAILURE });
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function flushImeiChangesHistory() {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_FLUSH,
  };
}

export function setCdrImeiChangesDateRange(payload: CdrDateRange) {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_DATE_RANGE,
    payload,
  };
}

export function setCdrImeiChangesPeriod(payload: CdrPeriod) {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_PERIOD,
    payload,
  };
}

export function setCdrImeiChangesFilter(payload: CdrFilter<string>) {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_FILTER,
    payload,
  };
}

export function setCdrImeiChangesGroupBy(payload: CdrRankingGroupBy) {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_GROUP_BY,
    payload,
  };
}

export function setCdrImeiChangesMetric(payload: CdrImeiChangesMetricsField) {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_METRIC,
    payload,
  };
}

export function setCdrImeiChangesCalendarDate(payload: CdrRankingCalendarDate) {
  return {
    type: CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_CALENDAR_DATE,
    payload,
  };
}

export const getCdrImeiChangesSearch: GetCdrSearchAction = (payload) => {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.search,
      method: 'POST',
      data: {
        ...payload,
        limit: 100,
      },
    };

    try {
      return dispatch(fetchData<CdrSearch>(options));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return;
    }
  };
};

export function getCdrImeiChangesTimeseriesSchema(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { bootstrap } = getState();

    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.timeseries.schema,
      method: 'POST',
    };

    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_LOADING });
      const imeiChangesTimeseriesSchema = await dispatch(fetchData<CdrTimeSeriesSchema>(options));

      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_SUCCESS, payload: imeiChangesTimeseriesSchema });

      const initValues: Record<string, string | undefined> = {
        start_datetime: imeiChangesTimeseriesSchema?.default_period.start_datetime,
        end_datetime: moment(imeiChangesTimeseriesSchema?.default_period.start_datetime)
          .clone()
          .add(imeiChangesTimeseriesSchema.max_datetime_range_sec, 'seconds')
          .toISOString(),
        source_network: bootstrap?.source_networks[0],
      };

      dispatch({
        type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SET_QUERY,
        payload: { currentQuery: initValues, defaultQuery: initValues },
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_FAILURE });
    }
  };
}

export function getCdrImeiChangesTimeseriesAvailabilityDates(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.cdrReportsApis.imeiChanges.timeseries.availabilityDates,
      method: 'POST',
    };

    try {
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_LOADING });
      const imeiChangesTimeseriesAvailaibilityDates = await dispatch(fetchData<CdrAvailabilityPeriodRange>(options));

      dispatch({
        type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_SUCCESS,
        payload: imeiChangesTimeseriesAvailaibilityDates,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      dispatch({ type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_FAILURE });
    }
  };
}

export function getCdrImeiChangesTimeseriesData(payload: CdrTimeSeriesQueryData, page: string, page_size: number) {
  const endpoint = config.cdrReportsApis.imeiChanges.timeseries.data;
  return getCdrTimeseriesData(endpoint, payload, page, page_size);
}

export function setImeiChangesTimeSeriesQuery(payload: CdrTimeSeriesQuery) {
  return { type: CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SET_QUERY, payload };
}

export function getImeiChangesTimeseriesExportData(payload: CdrTimeSeriesQueryData): Thunk<Promise<void>> {
  return async (dispatch) => {
    return dispatch(
      exportViews({
        fileName: getFileName('imeiChanges'),
        url: config.cdrReportsApis.imeiChanges.timeseries.exportData,
        data: payload,
        filtersQuery: '',
      })
    );
  };
}
