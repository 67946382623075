import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  filtersLoading,
  filtersFailure,
  filtersInject,
  sortSet,
  listLoading,
  listSuccess,
  listFailure,
  listClear,
  createSite,
  updateSite,
} from 'store/actions/sites';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Sites';
import List from 'components/List';
import Create from 'components/Edit/Sites/Create';
import Edit from 'components/Edit/Sites/Edit';
import Spinner from 'components/Spinner';
import DropdownTools from 'components/DropdownTools';
import Dot from 'components/Dot';
import Datagrid from 'components/Datagrid';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import getFetchHeaders from 'utils/fetchHeaders';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { getFiltersByQueryString } from 'store/models/filters';
import { getCountryLabel } from 'utils/getStatesCountries';
import { getTimezoneValue } from 'utils/getTimezones';
import { generateFiltersQuery } from 'store/models/filters';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import config from 'config';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { sentryLogError } from 'sentry';

const Sites = () => {
  const fetchData = useFetchData();
  const bootstrap = useBootstrapSelector();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [idEdit, setIdEdit] = useState();
  const userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const datalist = useSelector((state) => state.sites.list.data);
  const loadingState = useSelector((state) => state.sites.list.state);
  const filters = useSelector((state) => state.sites.filters.values) || {};
  const filtersState = useSelector((state) => state.sites.filters.state);
  const filtersData = useSelector((state) => state.sites.filters.data) || [];
  const sort = useSelector((state) => state.sites.sort);
  const { confirmationDialogOpen } = useOverlay();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    loadFilters();

    if (userdata.tenant_type === T_PERMISSIONS.NETWORK_MANAGER) {
      dispatch(sortSet('id')); // set sort to load data
    }

    return () => {
      dispatch(listClear()); // clear redux when leaves page for best performances
    };
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      loadData();
    }
  }, [filters]);

  useEffect(() => {
    if (sort !== '' && (filtersState === 1 || filtersState === 2)) {
      loadData();
    }
  }, [sort, filtersState]);

  const getFiltersData = () => {
    const tenants = [];
    for (let i in filtersData.tenants) {
      tenants.push({ label: filtersData.tenants[i].name, value: filtersData.tenants[i].id });
    }

    return {
      tenants,
    };
  };

  const loadFilters = () => {
    dispatch(filtersLoading());

    const options = {
      url: `${config.apis.getTenantWithSubtenants
        .replace('{sort}', 'name')
        .replace('{limit}', 1000)
        .replace('{page}', 0)
        .replace('{filters}', '')}`,
      method: 'GET',
      headers: getFetchHeaders(userdata),
    };

    fetchData(options)
      .then((response) => {
        dispatch(filtersInject({ state: 1, data: response, values: getFiltersByQueryString() }));
      })
      .catch(() => {
        dispatch(filtersFailure());
      });
  };

  // TODO: use getSites
  const loadData = (page = 0) => {
    if (
      // load if..
      loadingState !== 0 &&
      sort !== '' // .. sort is set
    ) {
      dispatch(listLoading());

      const query = encodeURI(generateFiltersQuery(filters, '&'));

      const options = {
        url: `${config.apis.getSites
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap.pageLimit)
          .replace('{page}', page)
          .replace('{filters}', query)}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          dispatch(
            listSuccess({
              data: result.items,
              total: result.total_items,
              page,
            })
          );
        })
        .catch(() => {
          dispatch(listFailure());
        });
    }
  };

  const handlePageChange = (page) => {
    loadData(page);
  };

  useEffect(() => {
    loadData();
  }, [bootstrap.pageLimit]);

  const onOrderChange = (orderQuery) => {
    dispatch(sortSet(orderQuery));
  };

  const handleCreate = async (values, callback) => {
    try {
      await dispatch(
        createSite({
          ...values,
          ...(values?.country?.value && { country: values.country.value }),
        })
      );
      loadData();
      callback(true);
    } catch (e) {
      callback(false, errorMessages(e?.response?.data?.error));
      sentryLogError(e);
    }
  };

  const errorMessages = (error) => {
    switch (error) {
      case 'invalid site name':
        return formatMessage({ id: 'sites.error.invalid' });

      default:
        return undefined;
    }
  };

  const onDelete = ({ id, tenant_id }) => {
    const formData = new FormData();
    formData.append('tenant_id', tenant_id);

    const options = {
      url: `${config.apis.deleteSite.replace('{id}', id)}`,
      method: 'DELETE',
      headers: getFetchHeaders(userdata),
      data: formData,
    };

    fetchData(options)
      .then(() => {
        loadData();
        dispatch(showSuccessToast());
      })
      .catch(() => {
        dispatch(showErrorToast());
      });
  };

  const getData = () => {
    return new Promise(function (resolve, reject) {
      if (idEdit) {
        const options = {
          url: `${config.apis.getSite.replace('{id}', idEdit)}`,
          method: 'GET',
          headers: getFetchHeaders(userdata),
        };

        fetchData(options)
          .then((result) => {
            resolve(result);
          })
          .catch(() => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const handleEdit = async (values, callback) => {
    if (idEdit) {
      try {
        await dispatch(
          updateSite(idEdit, {
            ...values,
            ...(values?.country?.value && { country: values.country.value }),
          })
        );
        loadData();
        callback(true);
      } catch (e) {
        callback(false, errorMessages(e?.response?.data?.error));
        sentryLogError(e);
      }
    } else {
      callback(false);
    }
  };

  const openEdit = (id) => {
    setIsEditOpen(true);
    setIdEdit(id);
  };

  const closeEdit = () => {
    setIsEditOpen(false);
    setIdEdit(undefined);
  };

  const openDelete = (rowData) => {
    confirmationDialogOpen({
      title: formatMessage({ id: 'sites.deleteSite.confirm.title' }),
      description: formatMessage({ id: 'sites.deleteSite.confirm' }),
      alertMessage: formatMessage({ id: 'sites.actions.itemsAffected' }, { element: rowData.name }),
      continueButtonText: formatMessage({ id: 'sites.deleteSite.confirm.continueButton' }, { elements: 1 }),
      onConfirm: () => onDelete(rowData),
      severity: 'danger',
      dataTestid: 'confirm-delete-site',
    });
  };

  const getTools = (rowData) => {
    const options = [
      {
        icon: ico_edit,
        label: formatMessage({ id: 'sites.editSite' }),
        action: () => openEdit(rowData.id),
        disabled: false,
        permissions: [U_PERMISSIONS.UPDATE_SITE],
      },
      {
        icon: ico_delete,
        label: formatMessage({ id: 'sites.deleteSite' }),
        action: () => openDelete(rowData),
        disabled: rowData.default === true,
        permissions: [U_PERMISSIONS.DELETE_SITE],
      },
    ];

    return <DropdownTools options={checkPermissionsList(userdata.permissions, options, false)} />;
  };

  const columns = [
    {
      key: 'id',
      title: 'id',
      dataKey: 'id',
      hidden: true,
      width: 80,
      maxWidth: 80,
      minWidth: 80,
      secret: true, // secret used to hide from columns management panel
    },
    {
      key: 'default',
      title: '',
      dataKey: 'default',
      width: 14,
      maxWidth: 14,
      minWidth: 14,
      secret: true, // secret used to hide from columns management panel
      cellRenderer: ({ cellData }) => <Dot color={cellData === true ? 'primary' : 'midlightgray'} />,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'sites.table.siteName' }),
      dataKey: 'name',
      sortable: true,
      defaultSort: 'asc', // set the columns sorted as default
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'tenant',
      title: formatMessage({ id: 'sites.table.tenant' }),
      dataKey: 'tenant_name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      permissions: [T_PERMISSIONS.MASTER],
    },
    {
      key: 'address',
      title: formatMessage({ id: 'sites.table.address' }),
      dataKey: 'address',
      sortable: true,
      visible: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'country',
      title: formatMessage({ id: 'sites.table.country' }),
      dataKey: 'country',
      sortable: true,
      width: 150,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: country }) => {
        return getCountryLabel(country);
      },
    },
    {
      key: 'coords',
      title: formatMessage({ id: 'sites.table.coords' }),
      dataKey: 'coords',
      sortable: true,
      visible: false,
      width: 250,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: coords }) => {
        const [long, lat] = coords.split(',');
        return coords ? `Long: ${long} \n Lat: ${lat}` : '';
      },
    },
    {
      key: 'additional_info',
      title: formatMessage({ id: 'sites.table.info' }),
      dataKey: 'additional_info',
      sortable: true,
      visible: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'network_name',
      title: formatMessage({ id: 'sites.table.networkName' }),
      dataKey: 'network_name',
      sortable: true,
      visible: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'timezone',
      title: formatMessage({ id: 'sites.table.timezone' }),
      dataKey: 'timezone',
      sortable: true,
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      cellRenderer: ({ cellData: timezone }) => {
        return getTimezoneValue(timezone);
      },
    },
    {
      key: 'created_at',
      title: formatMessage({ id: 'sites.table.creation' }),
      dataKey: 'created_at',
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 140,
      cellRenderer: ({ cellData }) => cellData && <Moment format={localizedConfig.fullDateFormat}>{cellData}</Moment>,
    },
    {
      key: 'tools',
      title: '',
      dataKey: 'tools',
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      secret: true, // secret used to hide from columns management panel
      cellRenderer: ({ rowData }) => {
        return getTools(rowData);
      },
    },
  ];

  const onCheckPermissionsList = (list) => {
    return checkPermissionsList([userdata.tenant_type], list);
  };

  const columnsPermitted = checkPermissionsList([userdata.tenant_type], columns);

  return columnsPermitted ? (
    <>
      {userdata.tenant_type === T_PERMISSIONS.NETWORK_MANAGER ? (
        <Datagrid
          data={datalist.data}
          getTools={getTools}
          createComponent={
            <Create
              data={getFiltersData()}
              onSubmit={handleCreate}
              userdata={userdata}
              checkPermissions={onCheckPermissionsList}
            />
          }
        />
      ) : (
        <List
          columns={columnsPermitted}
          totalRows={datalist.total}
          data={datalist.data}
          page={datalist.page}
          filters={filters}
          onOrderChange={onOrderChange}
          loadingState={loadingState}
          toolbar={{ actions: false }}
          createComponent={
            checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_SITE) && (
              <Create
                data={getFiltersData()}
                onSubmit={handleCreate}
                userdata={userdata}
                checkPermissions={onCheckPermissionsList}
              />
            )
          }
          filtersComponent={<Filters values={filters} />}
          onPageChange={handlePageChange}
          rowsPerPage={bootstrap.pageLimit}
          onRefresh={loadData}
        />
      )}
      {isEditOpen && (
        <Edit
          getData={getData}
          userdata={userdata}
          dataOptions={getFiltersData()}
          isOpen={isEditOpen}
          onEdit={handleEdit}
          checkPermissions={onCheckPermissionsList}
          onClose={closeEdit}
        />
      )}
    </>
  ) : (
    <Spinner className="spinner" size={40} />
  );
};

export default Sites;
