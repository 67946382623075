import { SourceNetwork } from './cdr';

export enum AUTH_PROVIDER {
  ENTERPRISE = 'enterprise',
  LDAP = 'ldap',
}

export const PAGE_LIMIT = 50;

export const PAGE_LIMIT_OPTIONS = Array.from({ length: 4 }).map((_, i) => PAGE_LIMIT * (i + 1));

export type EnvironmentConfiguration = {
  auth_providers: AUTH_PROVIDER | AUTH_PROVIDER[];
  delete_usim: boolean; // delete feature on usims
  edge_nodes: boolean; // flag edge nodes tab
  forgot_password: boolean; // flag forgot password feature
  logo: string; // filename for the logo
  max_selectable_items: number; // selectable rows on table
  usim_orders: boolean; // Enel specific for usims orders
  verify_user_required: boolean; // if a new user must be verified (an email is sent in order to verify the email address)
  version: string; // tag release on git
  mapbox_token?: string;
  mapbox_styles?: string;
  mapbox_center?: [string, string];
  dual_sim_service: boolean;
  dynamic_info: boolean;
  traffic_service: boolean;
  billing_service: boolean;
  traffic_difference_service: boolean;
  source_networks: SourceNetwork[];
  create_user_with_enterprise_auth: boolean;
  dynamic_info_period_limit_s: number;
  pgw_events_period_limit_s: number;
  athonetos_support_enabled: boolean;
  athux_support_enabled: boolean;
  node_versions: string[];
  branding_enabled: boolean;
  sentry_enabled: boolean;
  sentry_dsn: string;
};
