import { AnyAction } from 'redux';
import { NODES_ACTION_TYPE } from 'store/actions/nodes';
import { FiltersObj } from 'store/models/filters';
import { Node } from 'store/models/node';
import {
  List,
  listFailure,
  listIdle,
  listLoading,
  listSuccess,
  entityIdle,
  Entity,
  entityLoading,
  entitySuccess,
  entityFailure,
} from '.';

export type NodesState = {
  filters: FiltersObj;
  sort: string;
  list: List<Node>;
  entity: Entity<Node>;
};

const initialState: NodesState = {
  filters: {},
  sort: '',
  list: listIdle<Node>(),
  entity: entityIdle<Node>(),
};

export default function nodesPageReducer(state = initialState, action: AnyAction): NodesState {
  switch (action.type) {
    case NODES_ACTION_TYPE.FILTERS_SET:
      return Object.assign({}, state, {
        filters: action.payload,
      });

    case NODES_ACTION_TYPE.SORT_SET:
      return Object.assign({}, state, {
        sort: action.payload,
      });

    case NODES_ACTION_TYPE.LIST_LOADING:
      return Object.assign({}, state, {
        list: listLoading(state.list.data),
      });

    case NODES_ACTION_TYPE.LIST_SUCCESS:
      return Object.assign({}, state, {
        list: listSuccess(action.payload),
      });

    case NODES_ACTION_TYPE.LIST_FAILURE:
      return Object.assign({}, state, {
        list: listFailure(),
      });

    case NODES_ACTION_TYPE.LIST_CLEAR:
      return initialState;

    case NODES_ACTION_TYPE.ENTITY_LOADING:
      return {
        ...state,
        entity: entityLoading(),
      };
    case NODES_ACTION_TYPE.ENTITY_SUCCESS:
      return {
        ...state,
        entity: entitySuccess(action.payload),
      };
    case NODES_ACTION_TYPE.ENTITY_FAILURE:
      return {
        ...state,
        entity: entityFailure(),
      };

    default:
      return state;
  }
}
