// React
import { useEffect, useState } from 'react';

// Router
import { useParams } from 'react-router';

// Config by ENV
import config from 'config';

// Axios
import axios from 'axios';

// App Components
import ConfirmUserEmailForm from 'components/ConfirmUserEmailForm';

const ConfirmUserEmail = () => {
  const [success, setSuccess] = useState(null);
  const { confirmToken } = useParams();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  };

  useEffect(() => {
    const options = {
      url: `${config.apis.confirmUserEmail.replace('{temporary-code}', confirmToken)}`,
      method: 'GET',
      headers: headers,
    };

    axios(options)
      .then(() => {
        // success message
        setSuccess(true);
      })
      .catch(() => {
        // error message
        setSuccess(false);
      });
  }, []);

  return (
    <div>
      <ConfirmUserEmailForm success={success} />
    </div>
  );
};

export default ConfirmUserEmail;
