import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { string } from 'yup';
import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue, usimCodeSchema } from 'store/models/filters';
import { iccidExactRegExp, iccidRegExp, imsiExactRegExp, imsiRegExp, msisdnRegExp } from 'store/models/usim';
import {
  getMetadataOptions,
  getNodesOptions,
  getOrdersOptions,
  getProfilesOptions,
  getSitesOptions,
  MetadataOption,
  setUsimShouldFlushSelection,
} from 'store/actions/usims';
import { useDispatch } from 'react-redux';
import { getUsims, searchUsims } from 'store/actions/usims';
import { FiltersDrawer, FiltersDrawerItem } from '../Drawer';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { useUsimsFiltersSelector } from 'store/selectors/usims/cards';

export default function Usims() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const bootstrap = useBootstrapSelector();
  const values = useUsimsFiltersSelector();
  const [metakeys, setMetakeys] = useState<MetadataOption[] | null>(null);

  useEffect(() => {
    async function getKeys() {
      const fetchedKeys = await dispatch(getMetadataOptions());
      setMetakeys(fetchedKeys);
    }
    void getKeys();
  }, [dispatch]);

  const filterItems = useMemo(() => {
    let items: FiltersDrawerItem[] = [
      {
        name: 'name',
        label: formatMessage({ id: 'usims.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
        multiple: [FILTER_INPUT_TYPE.MATCH],
        not: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
        initial: values['name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'status',
        label: formatMessage({ id: 'usims.filters.status' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        multiple: [FILTER_INPUT_TYPE.OPTIONS],
        not: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['status'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'usims.status.in_stock' }), value: 'in_stock' },
          { label: formatMessage({ id: 'usims.status.ordered' }), value: 'ordered' },
          { label: formatMessage({ id: 'usims.status.provisioning' }), value: 'provisioning' },
          { label: formatMessage({ id: 'usims.status.deprovisioning' }), value: 'deprovisioning' },
          { label: formatMessage({ id: 'usims.status.activating' }), value: 'activating' },
          { label: formatMessage({ id: 'usims.status.deactivating' }), value: 'deactivating' },
          { label: formatMessage({ id: 'usims.status.barring' }), value: 'barring' },
          { label: formatMessage({ id: 'usims.status.suspending' }), value: 'suspending' },
          { label: formatMessage({ id: 'usims.status.active' }), value: 'active' },
          { label: formatMessage({ id: 'usims.status.inactive' }), value: 'inactive' },
          { label: formatMessage({ id: 'usims.status.barred' }), value: 'barred' },
          { label: formatMessage({ id: 'usims.status.suspended' }), value: 'suspended' },
          { label: formatMessage({ id: 'usims.status.unavailable' }), value: 'unavailable' },
        ],
      },
      {
        name: 'error',
        label: formatMessage({ id: 'usims.filters.error' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['error'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'usims.filters.error.true' }), value: 'true' },
          { label: formatMessage({ id: 'usims.filters.error.false' }), value: 'false' },
        ],
      },
      {
        name: 'imsi',
        label: formatMessage({ id: 'usims.filters.imsi' }),
        filterTypes: [
          FILTER_INPUT_TYPE.MATCH,
          FILTER_INPUT_TYPE.CONTAINS,
          FILTER_INPUT_TYPE.STARTS,
          FILTER_INPUT_TYPE.ENDS,
          FILTER_INPUT_TYPE.RANGE,
          FILTER_INPUT_TYPE.UPLOAD_FILE,
        ],
        multiple: [FILTER_INPUT_TYPE.MATCH],
        not: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
        initial: values['imsi'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: usimCodeSchema('imsi', imsiRegExp, imsiExactRegExp),
        onOptionsChange: async (value: string) => dispatch(searchUsims('imsi', value)),
      },
      ...(bootstrap?.dual_sim_service
        ? ([
            {
              name: 'imsi2',
              label: formatMessage({ id: 'usims.filters.imsi2' }),
              filterTypes: [
                FILTER_INPUT_TYPE.MATCH,
                FILTER_INPUT_TYPE.CONTAINS,
                FILTER_INPUT_TYPE.STARTS,
                FILTER_INPUT_TYPE.ENDS,
                FILTER_INPUT_TYPE.RANGE,
              ],
              multiple: [FILTER_INPUT_TYPE.MATCH],
              not: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
              initial: values['imsi2'],
              default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
              schema: usimCodeSchema('imsi', imsiRegExp, imsiExactRegExp),
              onOptionsChange: async (value: string) => dispatch(searchUsims('imsi2', value)),
            },
            {
              name: 'is_dual_imsi',
              label: formatMessage({ id: 'usims.filters.is_dual_imsi' }),
              filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
              not: [FILTER_INPUT_TYPE.OPTIONS],
              initial: values['is_dual_imsi'],
              default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
              schema: string(),
              autocompleteOptions: [
                { label: formatMessage({ id: 'usims.filters.type.singleImsi' }), value: 'false' },
                { label: formatMessage({ id: 'usims.filters.type.dualImsi' }), value: 'true' },
              ],
            },
          ] as FiltersDrawerItem[])
        : []),
      {
        name: 'iccid',
        label: formatMessage({ id: 'usims.filters.iccid' }),
        filterTypes: [
          FILTER_INPUT_TYPE.MATCH,
          FILTER_INPUT_TYPE.CONTAINS,
          FILTER_INPUT_TYPE.STARTS,
          FILTER_INPUT_TYPE.ENDS,
          FILTER_INPUT_TYPE.RANGE,
        ],
        multiple: [FILTER_INPUT_TYPE.MATCH],
        not: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
        initial: values['iccid'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: usimCodeSchema('iccid', iccidRegExp, iccidExactRegExp),
        onOptionsChange: async (value: string) => dispatch(searchUsims('iccid', value)),
      },
      {
        name: 'msisdn',
        label: formatMessage({ id: 'usims.filters.msisdn' }),
        filterTypes: [
          FILTER_INPUT_TYPE.MATCH,
          FILTER_INPUT_TYPE.CONTAINS,
          FILTER_INPUT_TYPE.STARTS,
          FILTER_INPUT_TYPE.ENDS,
          FILTER_INPUT_TYPE.RANGE,
        ],
        multiple: [FILTER_INPUT_TYPE.MATCH],
        not: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
        initial: values['msisdn'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: usimCodeSchema('msisdn', msisdnRegExp, msisdnRegExp),
        onOptionsChange: async (value: string) => dispatch(searchUsims('msisdn', value)),
      },
      {
        name: 'profile_id',
        label: formatMessage({ id: 'usims.filters.profile' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        not: [FILTER_INPUT_TYPE.OPTIONS],
        multiple: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['profile_id'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
        schema: string(),
        getInitialOptions: async () => dispatch(getProfilesOptions()),
      },
      {
        name: 'node_id',
        label: formatMessage({ id: 'usims.filters.node' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        not: [FILTER_INPUT_TYPE.OPTIONS],
        multiple: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['node_id'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
        schema: string(),
        getInitialOptions: async () => dispatch(getNodesOptions()),
      },
      {
        name: 'site_id',
        label: formatMessage({ id: 'usims.filters.site' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        not: [FILTER_INPUT_TYPE.OPTIONS],
        multiple: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['site_id'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
        schema: string(),
        getInitialOptions: async () => dispatch(getSitesOptions()),
      },
      {
        name: 'tenant_id',
        label: formatMessage({ id: 'usims.filters.tenant' }),
        not: [FILTER_INPUT_TYPE.OPTIONS],
        multiple: [FILTER_INPUT_TYPE.OPTIONS],
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
        initial: values['tenant_id'],
        schema: string(),
        getInitialOptions: async () => dispatch(getTenantsAutocompleteOptions()),
      },
    ];

    if (bootstrap?.usim_orders) {
      items = [
        ...items,
        {
          name: 'order_id',
          label: formatMessage({ id: 'usims.filters.order_id' }),
          filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
          not: [FILTER_INPUT_TYPE.OPTIONS],
          multiple: [FILTER_INPUT_TYPE.OPTIONS],
          initial: values['order_id'],
          default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, true),
          schema: string(),
          getInitialOptions: async () => dispatch(getOrdersOptions()),
        },
        {
          name: 'batch_id',
          label: formatMessage({ id: 'usims.filters.batch_id' }),
          filterTypes: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
          not: [FILTER_INPUT_TYPE.MATCH],
          multiple: [FILTER_INPUT_TYPE.MATCH],
          initial: values['batch_id'],
          default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
          schema: string(),
        },
      ];
    }

    let metadataItems: FiltersDrawerItem[] = [];

    if (metakeys?.length) {
      metakeys.forEach((item: MetadataOption) =>
        metadataItems.push({
          name: `metadata_${item.label}`,
          label: `Metadata: ${item.label}`,
          filterTypes: [FILTER_INPUT_TYPE.MATCH, FILTER_INPUT_TYPE.CONTAINS],
          initial: values[`metadata_${item.label}`],
          default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
          schema: string().matches(new RegExp(item.validation)),
        })
      );
      items = [...items, ...metadataItems];
    }
    return items;
  }, [bootstrap, dispatch, formatMessage, metakeys, values]);

  const handleSubmit = useCallback(
    (filtersValues) => {
      dispatch(getUsims({ filterBy: filtersValues }));
      dispatch(setUsimShouldFlushSelection(true));
    },
    [dispatch]
  );

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
