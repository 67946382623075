import { Owner } from './tenant';
import { NodeReference } from './node';
import { STATUS } from '@athonet/ui/components/Feedback/Status';

/* NOTE: CC-1285 */
type UsimProfileSegment = {
  apn: string;
  default: boolean;
  errors?: unknown[];
  id: string;
  name: string;
  nodes: [
    {
      errors: unknown[];
      id: string;
      status: string;
      updating: boolean;
    }
  ];
  status: string;
  updating: boolean;
};

export type UsimProfile = {
  charging_characteristics?: number;
  created_at: string;
  csgs_lists_id: [];
  default: boolean;
  id: string;
  name: string;
  nodes: NodeReference[];
  owners: Owner[];
  plmns_id?: string[];
  regional_subscriptions_profile: null;
  regional_subscriptions_profile_id: null;
  roaming_allowed: number;
  s6a_nam: number;
  s6dgr_nam: number;
  segments: UsimProfileSegment[];
  site?: { id: string; name: string; address?: string; country?: string; coords?: string };
  site_id?: string;
  status: string;
  subscription_data_flags: number;
  teleservices?: string[];
  ue_ambr_dl: number;
  ue_ambr_ul: number;
  updated_at: string;
};

export function getStatusType(status: string, updating: boolean = false) {
  let statusType;

  switch (status) {
    case 'ready':
      statusType = STATUS.SUCCESS;
      break;
    case 'updating':
      statusType = STATUS.INFO;
      break;
    case 'error':
      statusType = STATUS.ERROR;
      break;
    default:
      statusType = STATUS.DEFAULT;
  }
  if (updating) {
    statusType = STATUS.INFO;
  }
  return statusType;
}
