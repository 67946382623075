import { Stack } from '@athonet/ui/components/Layout/Stack';
import { PageLayout } from '@athonet/ui/components/PageLayout';
import LanguageSwitch from 'components/LanguageSwitch';
import { Outlet } from 'react-router-dom';

export function AuthLayout() {
  return (
    <PageLayout fullHeight fullWidth>
      <Stack align="center" justify="center" fullHeight fullWidth>
        <Outlet />
        <LanguageSwitch />
      </Stack>
    </PageLayout>
  );
}
