export function splitPascalCase(word: string): string {
  var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
  return word.match(wordRe)?.join(' ') as string;
}

export function humanizeSnakeCase(word: string) {
  return word.replaceAll('_', ' ');
}

export function capitalizeSnakeCase(phrase: string) {
  return `${phrase[0].toUpperCase()}${humanizeSnakeCase(phrase.substring(1))}`;
}
