import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Icon from 'components/Icon';
import { check } from 'react-icons-kit/metrize/check';
import { cross } from 'react-icons-kit/metrize/cross';
import { ConfirmUserEmailFormStyled } from './styled';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';

const ConfirmUserEmailForm = ({ success }) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmUserEmailFormStyled data-testid="confirmUserEmailForm">
      <Panel>
        <div className={`confirm-user-panel ${success ? 'success' : 'failure'}`}>
          <PanelContent>
            <Icon icon={success ? check : cross} size="medium" />
            {success ? (
              <div>
                <h1>{formatMessage({ id: 'users.confirmEmail.success.title' })}</h1>
                <p>{formatMessage({ id: 'users.confirmEmail.success.text' })}</p>
              </div>
            ) : (
              <div>
                <h1>{formatMessage({ id: 'users.confirmEmail.failure.title' })}</h1>
                <p>{formatMessage({ id: 'users.confirmEmail.failure.text' })}</p>
              </div>
            )}
          </PanelContent>
        </div>
      </Panel>
    </ConfirmUserEmailFormStyled>
  );
};

export default ConfirmUserEmailForm;

ConfirmUserEmailForm.propTypes = {
  success: PropTypes.bool,
};
