import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import AccountData from 'components/UserProfile/AccountData';
import Authentication from 'components/UserProfile/Authentication';
import Language from 'components/UserProfile/Language';
import TenantsAndRoles from 'components/UserProfile/TenantsAndRoles';

export default function Profile() {
  return (
    <GridContainer>
      <GridItem size={{ xs: 12 }}>
        <AccountData />
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={1}>
          <Authentication />
          <Language />
        </Stack>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <TenantsAndRoles />
      </GridItem>
    </GridContainer>
  );
}
