import { BulkOperationsAction, BULK_OPERATIONS_ACTION_TYPE } from 'store/actions/bulkOperations';
import { BulkOperation } from 'store/models/bulkOperation';
import { entityIdle, Entity, entityLoading, entityFailure, entitySuccess } from '.';

export type BulkOperationsState = { entity: Entity<BulkOperation[]>; scheduledOperations: string[] };

const initialState: BulkOperationsState = { entity: entityIdle(), scheduledOperations: [] };

export default function bulkOperationsReducer(state = initialState, action: BulkOperationsAction) {
  switch (action.type) {
    case BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_LOADING:
      return {
        ...state,
        entity: {
          ...entityLoading(),
          data: state.entity.data,
        },
      };
    case BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_SUCCESS:
      return {
        ...state,
        entity: entitySuccess(action.payload.items),
        scheduledOperations: state.scheduledOperations.filter((scheduledOperation) => {
          const item = action.payload.items.find((op) => op.operation_id === scheduledOperation);
          return !item;
        }),
      };
    case BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_FAILURE:
      return {
        ...state,
        entity: entityFailure<BulkOperation[]>(),
      };
    case BULK_OPERATIONS_ACTION_TYPE.SET_SCHEDULED_BULK_OPERATION:
      return {
        ...state,
        scheduledOperations: [...state.scheduledOperations, action.payload],
      };
    case BULK_OPERATIONS_ACTION_TYPE.DELETE_SCHEDULED_BULK_OPERATION:
      return {
        ...state,
        scheduledOperations: state.scheduledOperations.filter(
          (scheduledOperation) => scheduledOperation !== action.payload
        ),
      };
    default:
      return state;
  }
}
