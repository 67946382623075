import { AxiosError } from 'axios';
import { capitalizeSnakeCase } from './string';

function parseErrorString(errorString: string) {
  return capitalizeSnakeCase(errorString);
}

function parseErrorObject(errorObject: Record<string, unknown>) {
  return Object.entries(errorObject)
    .map(([errKey, errMessg]) => `${parseErrorString(errKey)} ${errMessg}`)
    .join('\n');
}

export function parseError(e: AxiosError) {
  return typeof e === 'string'
    ? parseErrorString(e)
    : typeof e.response?.data.error === 'string'
    ? parseErrorString(e.response.data.error)
    : typeof e.response?.data.error === 'object' && e.response.data.error !== null
    ? parseErrorObject(e.response.data.error)
    : e.message || 'Unknown error';
}
