import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { AddListStyled } from './styled';
import { Tenant } from 'store/models/tenant';
import { Role } from 'store/models/role';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/actions/modal';
import TenantsList from 'components/Modals/ManageTenants/TenantsList';
import useHandlers from './hook';
import { useManageTenantsModalSelector } from 'store/selectors/modal';
import { TenantOption } from 'store/actions/tenants';
import { usePollOperation } from 'hooks/usePollOperation';

export default function ManageTenants() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const modalState = useManageTenantsModalSelector();
  const { pollOperation, clearPollOperation } = usePollOperation();

  const {
    handleAddTenantToResource,
    handleDeleteTenantFromResource,
    handleGetTenantsByResource,
    result: tenantList,
    schema,
    modalTitles: { modalTitle, fieldsetTitle },
    initials,
    fieldlist,
  } = useHandlers(
    modalState
      ? { resourceId: modalState.data.id, resource: modalState.data.resource }
      : { resourceId: null, resource: null } // impossible state
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
    clearPollOperation();
  }, [clearPollOperation, dispatch]);

  const handleOnSubmit = useCallback(
    async (
      values: { tenant: TenantOption; roles?: Role['name']; permissions?: string },
      callback: (result: boolean | null, msg?: string) => void
    ) => {
      const submitValues = {
        ...values,
        tenant: values.tenant.value.id,
      };
      const res = await handleAddTenantToResource(submitValues, callback);
      pollOperation(res.operation_id, async () => {
        await handleGetTenantsByResource();
        modalState?.data.cb();
      });
    },
    [handleAddTenantToResource, handleGetTenantsByResource, modalState, pollOperation]
  );

  const handleDeleteTenant = useCallback(
    async (tenantId: Tenant['id']) => {
      const res = await handleDeleteTenantFromResource(tenantId);
      pollOperation(res.operation_id, async () => {
        await handleGetTenantsByResource();
        await modalState?.data.cb();
      });
    },
    [handleDeleteTenantFromResource, handleGetTenantsByResource, modalState, pollOperation]
  );

  const fieldset = [
    {
      title: fieldsetTitle,
      data: [...fieldlist],
    },
  ];

  return (
    <Base
      schema={schema}
      initials={initials}
      isOpen={true}
      onClose={handleCloseModal}
      fieldset={fieldset}
      onSubmit={handleOnSubmit}
      modalTitle={modalTitle}
      // modalDescription={modalDescription ? <p>QoS Profile: {data?.name}</p>} // TODO ask for proper translations
      ComponentBottom={
        <TenantsList
          tenants={tenantList}
          onDeassignTenant={(tenantId) => {
            void handleDeleteTenant(tenantId);
          }}
        />
      }
      labelContinue={formatMessage({ id: 'common.button.add' })}
      labelCancel={formatMessage({ id: 'common.form.close' })}
      StyleFormWrapper={AddListStyled}
      keepOpen
      // TODO: remove all of the following props and type edit/base form component
      modalDescription={undefined}
      elType={undefined}
      elColor={undefined}
      elSize={undefined}
      getData={undefined}
      elClassName={undefined}
      elLabel={undefined}
      elVariant={undefined}
      elIcon={undefined}
      elDisabled={undefined}
      disableContinue={undefined}
      showContinue={undefined}
      disableCancel={undefined}
      showCancel={undefined}
      ComponentTop={undefined}
      isFormLoading={undefined}
      confirmation={undefined}
      confirmationField={undefined}
    />
  );
}
