import { Stepper } from '@athonet/ui/components/Navigation/Stepper';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useStepper } from '@athonet/ui/hooks/useStepper';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Usim5g } from 'store/models/usim5g';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Step5 } from '../Create/Steps/Step5';
import { Step6 } from '../Create/Steps/Step6';
import { bulkPrepareUsims5g, prepareSingleUsim5gForProvisioning } from 'store/actions/usims5g';
import { useDispatch } from 'react-redux';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { IconButton } from '@athonet/ui/components/Input/IconButton';

const PREPARE_PROVISION_STEPS = 2;
const MILESTONES_ARRAY = [1];

export function EditNodeProfile({ usims5g }: { usims5g: Usim5g | Usim5g[] }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const {
    handleGoToStep,
    active,
    skipped,
    completed,
    handleGoToNextIncompleteStep,
    handleCompleteMilestone,
    milestonesCompleted,
  } = useStepper({
    steps: PREPARE_PROVISION_STEPS,
    milestones: MILESTONES_ARRAY,
  });

  const [prepareForProvisionValues, setPrepareForProvisionValues] = useState<{
    node_id: AutocompleteItemProps | null;
    profile_id: AutocompleteItemProps | null;
  }>({
    node_id: null,
    profile_id: null,
  });

  const onCompleteStep5 = useCallback(
    (values) => {
      setPrepareForProvisionValues(values);
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handlePrepareForProvisioning = useCallback(async () => {
    const newNode =
      prepareForProvisionValues['node_id'] &&
      'value' in prepareForProvisionValues['node_id'] &&
      'label' in prepareForProvisionValues['node_id']
        ? String(prepareForProvisionValues['node_id'].value)
        : null;

    const newServiceProfile =
      prepareForProvisionValues['profile_id'] &&
      'value' in prepareForProvisionValues['profile_id'] &&
      'label' in prepareForProvisionValues['profile_id']
        ? String(prepareForProvisionValues['profile_id'].value)
        : null;

    if (newNode && newServiceProfile) {
      setLoading(true);
      const errorRes =
        Array.isArray(usims5g) || Object.keys(usims5g).length === 0
          ? await dispatch(
              bulkPrepareUsims5g({
                node_id: newNode,
                profile_id: newServiceProfile,
                ...(Array.isArray(usims5g) && { usims5g: usims5g.map((item) => item.id) }),
              })
            )
          : await dispatch(
              prepareSingleUsim5gForProvisioning({
                node_id: newNode,
                profile_id: newServiceProfile,
                usim5g: usims5g,
              })
            );
      setLoading(false);
      if (!errorRes) {
        setError(null);
        handleCompleteMilestone(MILESTONES_ARRAY[0]);
      } else {
        setError(errorRes);
      }
    }
  }, [dispatch, handleCompleteMilestone, prepareForProvisionValues, usims5g]);

  return (
    <>
      <IconButton
        name="Close"
        fontSize="small"
        onClick={dialogClose}
        sx={{ position: 'absolute', right: '16px', top: '8px', zIndex: 100 }}
      />
      <DialogContent>
        {usims5g && !Array.isArray(usims5g) && (
          <Text type="body2">
            {formatMessage({ id: 'usims.form.usim.prepare.subtitle' }, { element: usims5g.supi })}
          </Text>
        )}
        <Stepper
          orientation="vertical"
          completed={completed}
          nonLinear
          activeStep={active}
          onStepClick={handleGoToStep}
          skipped={skipped}
          milestonesCompleted={milestonesCompleted}
        >
          <Step5
            onCompleteStep={onCompleteStep5}
            initialValues={{
              node_id: prepareForProvisionValues['node_id'],
              profile_id: prepareForProvisionValues['profile_id'],
            }}
          />
          <Step6
            milestoneCompleted={milestonesCompleted.has(MILESTONES_ARRAY[0])}
            values={{
              node: prepareForProvisionValues['node_id']?.label,
              profile: prepareForProvisionValues['profile_id']?.label,
            }}
            error={error}
            onCompleteStep={() => void handlePrepareForProvisioning()}
            loading={loading}
            isBulkOperation={Array.isArray(usims5g)}
          />
        </Stepper>
      </DialogContent>
    </>
  );
}
