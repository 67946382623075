import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';

export const DropdownToolsStyle = styled.div``;

export const MenuItemStyled = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: 30px;
    color: ${(props) => props.theme.colors.primary};
  }
`;
