import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { array, object, string } from 'yup';
import { Role } from 'store/models/role';
import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { useDispatch } from 'react-redux';
import { createRole, editRole, getPermissions } from 'store/actions/roles';

export function EditRole({ role }: { role?: Role }) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState<AutocompleteItemProps[]>([]);

  useEffect(() => {
    async function mapPermissions() {
      const fetchedPermissions = await dispatch(getPermissions());
      setPermissions(
        fetchedPermissions.map((item: string) => ({ label: formatMessage({ id: `permissions.${item}` }), value: item }))
      );
    }
    void mapPermissions();
  }, [dispatch, formatMessage]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        role: string().trim().required(),
        permissions: array().required(),
      }),
    []
  );

  const initials = useMemo(
    () =>
      role
        ? {
            role: role.name,
            permissions: permissions.filter(({ value }) => role.permissions.find(({ code }) => code === value)),
          }
        : {
            role: '',
            permissions: [],
          },
    [permissions, role]
  );

  const handleEditRole = useCallback(
    async (values) => {
      const permissionsValues = values.permissions.map(({ value }: AutocompleteItemProps) => value);

      setLoading(true);
      role
        ? await dispatch(editRole({ data: { role: values.role, permissions: permissionsValues }, roleId: role.id }))
        : await dispatch(createRole({ role: values.role, permissions: permissionsValues }));
      setLoading(false);

      dialogClose();
    },
    [dialogClose, dispatch, role]
  );

  return (
    <Formik
      initialValues={initials}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleEditRole}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, touched, setFieldValue }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <DialogContent>
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <Field name="role" key="role">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      label={formatMessage({ id: 'security.form.role.label' })}
                      placeholder={formatMessage({ id: 'security.form.role.placeholder' })}
                      error={Boolean(errors['role'] && touched['role'])}
                      {...(Boolean(errors['role'] && touched['role']) && {
                        helperText: formatMessage({ id: 'security.form.role.error' }),
                      })}
                    />
                  )}
                </Field>
                <Field name="permissions" key="permissions">
                  {({ field }: FieldProps<AutocompleteItemProps[]>) => (
                    <>
                      <Autocomplete
                        {...field}
                        options={permissions}
                        multiple
                        onChange={(_, v) => setFieldValue('permissions', v)}
                        label={formatMessage({ id: 'security.form.permissions.label' })}
                        placeholder={formatMessage({ id: 'security.form.permissions.placeholder' })}
                        error={Boolean(errors['permissions'] && touched['permissions'])}
                        {...(Boolean(errors['permissions'] && touched['permissions']) && {
                          helperText: formatMessage({ id: 'security.form.permissions.error' }),
                        })}
                      />
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => setFieldValue('permissions', permissions)}
                        data-testid="multiselect-selectall"
                        text={formatMessage({ id: 'common.button.selectAll' })}
                        sx={{ marginRight: 0 }}
                      />
                    </>
                  )}
                </Field>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  loading={loading}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={formatMessage({ id: 'common.form.continue' })}
                  loading={loading}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
