import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import useHandlers from './hook';
import UploadLogoBox from './UploadLogoBox';
import AvatarEditor from 'react-avatar-editor';
import { RESOURCE } from 'store/models/modal';
import { FileUpload } from '@athonet/ui/components/Input/FileUpload';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';

export type UploadLogoProps = {
  id: string;
  resource: RESOURCE;
};

export default function UploadLogo({ id, resource }: UploadLogoProps) {
  const { formatMessage } = useIntl();
  const [image, setImage] = useState<File | null>();
  const editor = useRef<AvatarEditor>(null);
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);

  const { handleUploadLogo } = useHandlers({ tenantId: id, resource });

  const handleOnSubmit = useCallback(async () => {
    if (editor.current) {
      setLoading(true);
      const canvas = editor.current.getImageScaledToCanvas().toDataURL();
      const res = await fetch(canvas);
      const blob = await res.blob();
      await handleUploadLogo(blob);
      dialogClose();
      setLoading(false);
    }
  }, [dialogClose, handleUploadLogo]);

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          <UploadLogoBox image={image || null} setImage={setImage} editor={editor} id={id} resource={resource} />
          <FileUpload
            accept={{
              image: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
            }}
            maxSize={1024 ** 2}
            label={formatMessage({ id: 'tenants.form.logoUpload.subtitle' })}
            onChange={(files) => {
              setImage(files[0]);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" text="Cancel" onClick={dialogClose} />
        <Button text="Confirm" onClick={() => void handleOnSubmit()} loading={loading} />
      </DialogActions>
    </>
  );
}
