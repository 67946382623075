import { EditSlice } from './EditSlice';
import { EditService } from './EditService';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Service, ServiceProfile, Slice } from 'store/models/serviceProfile';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { EditServiceProfile } from './EditServiceProfile';
import theme from '@athonet/ui/theme';
import { useDispatch } from 'react-redux';
import { deleteService, deleteSlice } from 'store/actions/serviceProfiles';
import { useNodeEntitySelector } from 'store/selectors/nodes';

export function SlicesTable({ serviceProfile }: { serviceProfile: ServiceProfile }) {
  const { formatMessage } = useIntl();
  const { dialogOpen, confirmationDialogOpen } = useOverlay();
  const dispatch = useDispatch();
  const nodeEntity = useNodeEntitySelector();

  const slicesColumns: TableColumn<Slice>[] = useMemo(
    () => [
      {
        key: 'name',
        label: formatMessage({ id: 'serviceProfiles.slices.table.name' }),
      },
      {
        key: 'differentiator',
        label: formatMessage({ id: 'serviceProfiles.slices.table.differentiator' }),
        cellRender: ({ differentiator }) => <Chip id={differentiator} label={differentiator} />,
      },
      {
        key: 'services',
        label: formatMessage({ id: 'serviceProfiles.slices.table.services' }),
        cellRender: ({ services }) => <>{services.length}</>,
      },
    ],
    [formatMessage]
  );

  const servicesColumns: TableColumn<Service>[] = useMemo(
    () => [
      {
        key: 'name',
        label: formatMessage({ id: 'serviceProfiles.services.table.name' }),
      },
      {
        key: 'type',
        label: formatMessage({ id: 'serviceProfiles.services.table.type' }),
      },
      {
        key: 'default',
        label: '',
        cellRender: ({ default: isDefault, id }) =>
          isDefault ? <Chip color="secondary" label="Default" id={id} /> : <></>,
        maxWidth: '100',
      },
    ],
    [formatMessage]
  );

  const handleOpenCreateSlice = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'serviceProfiles.slices.createSlice' }),
      content: () => <EditSlice serviceProfileId={serviceProfile.id} />,
    });
  }, [dialogOpen, formatMessage, serviceProfile.id]);

  const handleOpenEditSlice = useCallback(
    (slice: Slice) => {
      dialogOpen({
        title: formatMessage({ id: 'serviceProfiles.slices.editSlice' }),
        content: () => <EditSlice serviceProfileId={serviceProfile.id} slice={slice} />,
      });
    },
    [dialogOpen, formatMessage, serviceProfile.id]
  );

  const handleOpenCreateService = useCallback(
    (sliceId: string) => {
      dialogOpen({
        title: formatMessage({ id: 'serviceProfiles.services.createService' }),
        content: () => <EditService serviceProfileId={serviceProfile.id} sliceId={sliceId} />,
      });
    },
    [dialogOpen, formatMessage, serviceProfile.id]
  );

  const handleOpenEditService = useCallback(
    (sliceId: string, service: Service) => {
      dialogOpen({
        title: formatMessage({ id: 'serviceProfiles.services.editService' }),
        content: () => <EditService serviceProfileId={serviceProfile.id} sliceId={sliceId} service={service} />,
      });
    },
    [dialogOpen, formatMessage, serviceProfile.id]
  );

  const handleOpenDeleteService = useCallback(
    (sliceId: string, service: Service) => {
      confirmationDialogOpen({
        description: formatMessage({ id: 'serviceProfiles.services.deleteService.description' }),
        title: formatMessage({ id: 'serviceProfiles.services.deleteService.title' }),
        alertMessage: formatMessage(
          { id: 'serviceProfiles.services.actions.confirm.itemsAffected' },
          { element: service.name }
        ),
        severity: 'danger',
        onConfirm: () => {
          if (!nodeEntity.data) {
            return;
          }
          return dispatch(
            deleteService({
              nodeId: nodeEntity.data.id,
              serviceId: service.id,
              serviceProfileId: serviceProfile.id,
              sliceId,
            })
          );
        },
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, nodeEntity, serviceProfile]
  );

  const handleOpenDeleteSlice = useCallback(
    (slice: Slice) => {
      confirmationDialogOpen({
        description: formatMessage({ id: 'serviceProfiles.slices.deleteSlice.description' }),
        title: formatMessage({ id: 'serviceProfiles.slices.deleteSlice.title' }),
        alertMessage: formatMessage(
          { id: 'serviceProfiles.slices.actions.confirm.itemsAffected' },
          { element: slice.name }
        ),
        severity: 'danger',
        onConfirm: () => {
          if (!nodeEntity.data) {
            return;
          }
          return dispatch(
            deleteSlice({
              nodeId: nodeEntity.data.id,
              serviceProfileId: serviceProfile.id,
              sliceId: slice.id,
            })
          );
        },
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, nodeEntity, serviceProfile]
  );

  const handleOpenEditServiceProfile = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'serviceProfiles.editServiceProfile' }, { element: serviceProfile.name }),
      content: () => <EditServiceProfile serviceProfile={serviceProfile} />,
    });
  }, [dialogOpen, formatMessage, serviceProfile]);

  const rowDetail = useMemo(
    () => ({
      content: (row: Slice) => {
        return (
          <Panel
            dense
            folder
            title={formatMessage({ id: 'serviceProfiles.services.table.title' }, { serviceProfile: row.name })}
            headerActionsComponent={
              <Button text="New Service" variant="outlined" onClick={() => handleOpenCreateService(row.id)} />
            }
          >
            <Table
              orderBy="name"
              columns={servicesColumns}
              data={row.services}
              rowsCount={row.services.length}
              page={0}
              rowsPerPage={10}
              rowKey="name"
              rowActions={[
                {
                  label: formatMessage({ id: 'serviceProfiles.services.editService' }),
                  onClick: (service) => handleOpenEditService(row.id, service),
                },
                {
                  label: formatMessage({ id: 'serviceProfiles.services.deleteService.title' }),
                  onClick: (service) => handleOpenDeleteService(row.id, service),
                },
              ]}
            />
          </Panel>
        );
      },
      disabled: () => {
        return false;
      },
    }),
    [formatMessage, handleOpenCreateService, handleOpenDeleteService, handleOpenEditService, servicesColumns]
  );

  return (
    <Panel
      fullHeight
      folder
      title={serviceProfile.name}
      sx={{ borderRadius: '0 4px 4px 0' }}
      headerActionsComponent={
        <Stack direction="row" spacing={2}>
          <Button
            text={formatMessage({ id: 'serviceProfiles.changeName' })}
            variant="outlined"
            onClick={handleOpenEditServiceProfile}
          />
        </Stack>
      }
    >
      <Stack direction="row" justify="space-between" sx={{ mx: 2, mt: 2 }}>
        <Text type="h6" fontWeight={600} color={theme.palette.text.secondary}>
          {formatMessage({ id: 'serviceProfiles.slices.table.title' }, { serviceProfile: serviceProfile.name })}
        </Text>
        <Button text="New Slice" variant="outlined" onClick={handleOpenCreateSlice} />
      </Stack>
      <Table
        autoScale
        orderBy="name"
        columns={slicesColumns}
        data={serviceProfile.slices}
        rowsCount={serviceProfile.slices.length}
        page={0}
        rowsPerPage={10}
        rowKey="name"
        rowDetail={rowDetail}
        rowActions={[
          {
            label: formatMessage({ id: 'serviceProfiles.slices.editSlice' }),
            onClick: handleOpenEditSlice,
          },
          {
            label: formatMessage({ id: 'serviceProfiles.slices.deleteSlice.title' }),
            onClick: (row) => handleOpenDeleteSlice(row),
          },
        ]}
      />
    </Panel>
  );
}
