import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Usim, UsimFields } from 'store/models/usim';
import { ShowField } from './EditUsim';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { EditUsimValues } from 'store/actions/usims';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';

export function ConfirmationEditUsim<F extends keyof UsimFields>({
  values,
  show,
  usim,
}: {
  values: EditUsimValues<F>;
  show: ShowField<F>[];
  usim?: Usim;
}) {
  const { formatMessage } = useIntl();

  const getValue = useCallback(
    (field: F): ReactNode => {
      const value = values[field];
      const emptyLabel = 'Empty';
      const untouchedLabel = 'Untouched';

      if (!usim) {
        return '';
      }

      switch (field) {
        case 'profile':
        case 'node':
        case 'tenant':
        case 'site':
        case 'order':
          if (typeof value === 'object' && value !== undefined && value !== null) {
            return <Text>{'name' in value ? value.name : value.toString()}</Text>;
          }
          return <Chip id={field} size="small" label={emptyLabel} />;
        case 'encrypt':
          return formatMessage({
            id: `usims.form.usim.encrypt.${value}`,
          });
        case 'key_type':
          return formatMessage({
            id: `usims.form.usim.key_type.${value === 1 ? 'customOP' : value === 2 ? 'customOPC' : 'defaultHSSOP'}`,
          });
        case 'k':
          if (value) {
            return value?.toString();
          }
          if (usim['k'] && value === '') {
            return <Chip id={field} size="small" label={untouchedLabel} />;
          }
          return <Chip id={field} size="small" label={emptyLabel} />;
        case 'use_key':
          if (value) {
            return value?.toString();
          }
          if (values['use_default_tk' as F]) {
            return (
              <Chip id={field} size="small" label={formatMessage({ id: 'usims.form.usim.use_default_tk.true' })} />
            );
          }
          return <Chip id={field} size="small" label={emptyLabel} />;
        case 'op':
          if (values['key_type' as F] === 0) {
            return (
              <Chip id={field} size="small" label={formatMessage({ id: 'usims.form.usim.key_type.defaultHSSOP' })} />
            );
          }
          if (value) {
            return value?.toString();
          }
          if (usim['op'] && value === '') {
            return <Chip id={field} size="small" label={untouchedLabel} />;
          }
          return <Chip id={field} size="small" label={emptyLabel} />;
        case 'opc':
          if (value) {
            return value?.toString();
          }
          if (usim['opc'] && value === '') {
            return <Chip id={field} size="small" label={untouchedLabel} />;
          }
          return <Chip id={field} size="small" label={emptyLabel} />;
        case 'ims_profile':
          return formatMessage({
            id: `common.form.${value ? 'enabled' : 'disabled'}`,
          });
        default: {
          if (value === undefined || value === null) {
            return <Chip id={field} size="small" label={emptyLabel} />;
          }
          return value?.toString();
        }
      }
    },
    [formatMessage, usim, values]
  );

  if (!usim) {
    return null;
  }

  return (
    <>
      {show.map((fieldSection, i) => {
        return (
          <Box key={i} sx={{ mt: 2 }}>
            {fieldSection.label && (
              <Text type="h5" sx={{ mb: 1 }}>
                {fieldSection.label}
              </Text>
            )}
            <div>
              {fieldSection.fields.map((field) => {
                let canBeRendered = false;

                if (field in usim) {
                  canBeRendered = true;
                }

                if (field === 'use_key' && values['encrypt' as F] === 0) {
                  canBeRendered = false;
                }

                if (field === 'op' && values['key_type' as F] !== 1 && values['key_type' as F] !== 0) {
                  canBeRendered = false;
                }

                if (field === 'opc' && values['key_type' as F] !== 2) {
                  canBeRendered = false;
                }

                if (!canBeRendered) {
                  return null;
                }

                const value = getValue(field);
                return (
                  <Stack direction="row" align="center" key={field} spacing={0.5} sx={{ mb: 1 }}>
                    <Text fontWeight={'bold'} type="body1">
                      {formatMessage({ id: `usims.form.usim.${field}` })}
                    </Text>
                    {value ? <Text>{value}</Text> : <Chip id={field} size="small" label="Empty" />}
                  </Stack>
                );
              })}
            </div>
          </Box>
        );
      })}
    </>
  );
}
