import React, { useCallback, useRef, useState } from 'react';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Form, FormikHelpers, FormikProps } from 'formik';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { setNestedObjectValues } from 'formik';
import { CancelButton } from 'components/Button/CancelButton';
import { ConfirmButton } from 'components/Button/ConfirmButton';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { ObjectSchema } from 'yup';
import { useIntl } from 'react-intl';
import useUnload from './useUnload';
import BaseFormik from 'components/Form/BaseFormik';
import FormSubmitButton from 'components/Form/Button/FormSubmitButton';
import FormCancelButton from 'components/Form/Button/FormCancelButton';

interface FormDialogProps {
  initialValues: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
  validationSchema: ObjectSchema<any>;
  /** default validation prefix to automatically grab translations */
  validationPrefix: string;
  formComponent: any;
  confirmComponent?: any;
  alertPendingChanges?: boolean;
}

/**
 * FormDialog is a component that renders a dialog with a form and a optional
 * confirmation view, allowing users to switch between the two views and validate the form before
 * submitting.
 */
const FormDialog: React.FC<FormDialogProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  validationPrefix,
  children,
  formComponent,
  confirmComponent,
  alertPendingChanges,
}) => {
  const [isFormView, toggleView] = useState(true);
  const { formatMessage } = useIntl();
  const formikRef = useRef<FormikProps<any>>(null);

  useUnload((evt: BeforeUnloadEvent) => {
    if (alertPendingChanges && formikRef?.current?.dirty) {
      evt.preventDefault();
      return (evt.returnValue = formatMessage({ id: 'common.confirmPendingChanges' }));
    } else return;
  });

  const handleValidateButtonClick = useCallback(() => {
    const validate = async () => {
      if (!formikRef?.current) return;
      const validationErrors = await formikRef.current.validateForm();
      const errorKeys = Object.keys(validationErrors);
      if (validationErrors && errorKeys.length > 0) {
        formikRef.current.setTouched(setNestedObjectValues(validationErrors, true));
        const element = document.querySelector(`input[name='${errorKeys[0]}']`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (confirmComponent) toggleView(false);
        else formikRef.current?.handleSubmit();
      }
    };
    void validate();
  }, [confirmComponent]);

  const handlePreviousButtonClick = useCallback(() => {
    toggleView(true);
  }, []);

  return (
    <>
      <DialogContent>
        {children}
        <BaseFormik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validationPrefix={validationPrefix}
        >
          {() => {
            return (
              <Form noValidate autoComplete="off">
                {isFormView && <>{formComponent}</>}
                {!isFormView && confirmComponent && <>{confirmComponent}</>}
              </Form>
            );
          }}
        </BaseFormik>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
          {isFormView && (
            <>
              <FormCancelButton formRef={formikRef} alertPendingChanges />
              <ConfirmButton onClick={handleValidateButtonClick} />
            </>
          )}
          {!isFormView && confirmComponent && (
            <>
              <CancelButton onClick={handlePreviousButtonClick} />
              <FormSubmitButton formRef={formikRef} />
            </>
          )}
        </Stack>
      </DialogActions>
    </>
  );
};

export default FormDialog;
