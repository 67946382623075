import moment from 'moment';
import { BULK_OPERATION_STATUS } from 'store/models/bulkOperation';
import { Operation, OPERATION_STATUS } from '@athonet/ui/components/Feedback/Operation';
import { splitPascalCase } from 'utils/string';
import { DrawerContent } from '@athonet/ui/components/Overlay/Drawer/DrawerContent';
import { useBulkOperations } from 'store/selectors/bulkOperations';
import { Stack } from '@athonet/ui/components/Layout/Stack';

export function OperationsDrawerContent() {
  const bulkOperations = useBulkOperations();

  return (
    <DrawerContent>
      <Stack spacing={3} divided>
        {bulkOperations.scheduledOperations.length > 0 && (
          <Operation
            status={OPERATION_STATUS.IDLE}
            name="Scheduled Operations"
            progress={bulkOperations.scheduledOperations.length}
          />
        )}
        {bulkOperations.entity.data?.map((bulkOperation) => {
          let status: OPERATION_STATUS;

          switch (bulkOperation.status) {
            case BULK_OPERATION_STATUS.SUCCESS:
              status = OPERATION_STATUS.SUCCESS;
              break;
            case BULK_OPERATION_STATUS.ERROR:
              status = OPERATION_STATUS.ERROR;
              break;
            case BULK_OPERATION_STATUS.PROGRESS:
              status = OPERATION_STATUS.PROGRESS;
              break;
            case BULK_OPERATION_STATUS.WARNING:
              status = OPERATION_STATUS.WARNING;
              break;
          }

          return (
            <Operation
              key={bulkOperation.id}
              status={status}
              name={splitPascalCase(bulkOperation.name)}
              progress={Math.round(bulkOperation.progress)}
              startedAt={moment(bulkOperation.created_at)}
              completedAt={bulkOperation.completed_at ? moment(bulkOperation.completed_at) : undefined}
              tasks={{
                total: bulkOperation.total_actions,
                errored: bulkOperation.errored_actions,
                succeeded: bulkOperation.succeded_actions,
              }}
            />
          );
        })}
      </Stack>
    </DrawerContent>
  );
}
