import { Button } from '@athonet/ui/components/Input/Button';
import { CdrTimeSeriesQuery, CdrTimeSeriesQueryData } from 'store/models/cdr';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Thunk } from 'store/actions';

type CdrExportQueryBuilderProps = {
  timeseriesQuery: CdrTimeSeriesQuery;
  setExportQuery: (payload: CdrTimeSeriesQueryData) => Thunk<Promise<void>>;
};

export function CdrExportQueryBuilder({ timeseriesQuery, setExportQuery }: CdrExportQueryBuilderProps) {
  const dispatch = useDispatch();
  const [exporting, setExporting] = useState(false);

  const handleExport = useCallback(async () => {
    if (!timeseriesQuery?.currentQuery) {
      return;
    }
    setExporting(true);
    await dispatch(setExportQuery(timeseriesQuery.currentQuery));
    setExporting(false);
  }, [dispatch, setExportQuery, timeseriesQuery]);

  return (
    <Button
      variant="outlined"
      onClick={() => void handleExport()}
      loading={exporting}
      text="Export"
      startIcon={'Cloud-Download'}
      data-testid="cdr-toolbar-export-button"
    />
  );
}
