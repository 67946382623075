import { Owner } from './tenant';

export enum UsimOrderStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  READY = 'ready',
  UPDATING = 'updating',
  COMPLETED = 'completed',
}

export type UsimOrder = {
  batch_size: number;
  batches: [
    {
      id: number;
      size: number;
    }
  ];
  completed_actions: number;
  created_at: string;
  description: string;
  error: boolean;
  first_available_imsi: string;
  first_imsi: string;
  id: string;
  imsi2_pool_id?: string;
  imsi_pool_id: string;
  msisdn_pool_id: string;
  order_id: number;
  pending_actions: number;
  progress: number;
  quantity: number;
  status: UsimOrderStatus;
  tenant: Owner;
  updated_at: string;
  vendor: string;
  vendor_info: {
    address_1?: string;
    address_2?: string;
    address_3?: string;
    address_4?: string;
    address_5?: string;
    customer: string;
    graph_ref: string;
    input_variables: string[];
    profile: string;
    sim_id_1: string;
    sim_id_2: string;
    sim_id_3?: string;
    sim_id_4?: string;
    transport_key: string;
    type: string;
  };
};
