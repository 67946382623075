export enum USIM5G_STATUS {
  DEPROVISIONED = 'deprovisioned',
  PROVISIONED = 'provisioned',
}

//TODOPS for now they are hardcoded
export const availableKeys = ['business_unit', 'owner', 'project'];
export type MetadataKey = (typeof availableKeys)[number];

export type MetadataDictionary = {
  [index in MetadataKey]: string;
};

export type Usim5g = {
  id: string;
  created_at: string;
  encrypt: number;
  supi: string; // imsi regexp
  msisdn: string; // 1-15 digits regexp
  k: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  name: string;
  op: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  opc: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  tenant: {
    id: string;
    name: string;
  };
  updated_at: string;
  use_key: string | null;
  node_id: string | null;
  status: USIM5G_STATUS | null;
  node_name: string | null;
  profile_id: string | null;
  profile_name: string | null;
  metadata: MetadataDictionary;
};

export type Usim5gFields = Usim5g & {
  single: boolean;
  upload: File;
  metadata_delete: string | null;
  key_type: number;
  key_override: boolean;
  use_default_tk: boolean;
  tenant_id: string;
};
