import { useCallback } from 'react';
import { useIntl } from 'react-intl';

/**
 * The `useGetInputTexts` function returns an object with label, placeholder, error, and helperText
 * properties based on the provided prefix and property name.
 * @param {string} prefix - The `prefix` parameter is a string that represents the prefix for the
 * message IDs used for localization. It is used to construct the message IDs for labels, placeholders,
 * and error messages.
 * @returns The function `getInputTexts` is being returned.
 */
const useGetInputTexts = (prefix?: string) => {
  const { formatMessage } = useIntl();

  const getInputTexts = useCallback(
    (propertyName: string, error: boolean, touched: any) => {
      if (!prefix || !propertyName) return {};
      return {
        label: formatMessage({ id: `${prefix}.${propertyName}.label` }),
        placeholder: formatMessage({ id: `${prefix}.${propertyName}.placeholder` }),
        error: error && touched,
        ...(error &&
          touched && {
            helperText: formatMessage({ id: `${prefix}.${propertyName}.error` }),
          }),
      };
    },
    [formatMessage, prefix]
  );
  return getInputTexts;
};

export default useGetInputTexts;
