import { Button } from '@athonet/ui/components/Input/Button';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Box } from '@material-ui/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getMetadataOptions, MetadataOption, updateUsimMetadataJson } from 'store/actions/usims';
import { Usim } from 'store/models/usim';
import { object, string, StringSchema } from 'yup';

export default function MetadataEditor({ usim }: { usim: Usim }) {
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();
  const [metadataOptions, setMetadataOptions] = useState<MetadataOption[]>([]);
  const [metadataVisibility, setMetadataVisibility] = useState<string[]>(Object.keys(usim['metadata']));
  const dispatch = useDispatch();

  useEffect(() => {
    async function getKeys() {
      const fetchedKeys = await dispatch(getMetadataOptions());
      setMetadataOptions(fetchedKeys);
    }
    void getKeys();
  }, [dispatch]);

  const initials = useMemo((): Record<string, string> => {
    const initialValues: Record<string, string> = {};

    metadataOptions.forEach(({ value: metadataOptionValue }) => {
      initialValues[metadataOptionValue] = usim['metadata'][metadataOptionValue] || '';
    });
    return initialValues;
  }, [metadataOptions, usim]);

  const handleSubmit = useCallback(
    (values) => {
      void dispatch(updateUsimMetadataJson(values, [usim]));
      dialogClose();
    },
    [dialogClose, dispatch, usim]
  );

  const handleMetadataSetVisiblity = useCallback((name: string) => {
    setMetadataVisibility((prevState) => {
      return [...prevState, name];
    });
  }, []);

  const handleMetadataUnsetVisiblity = useCallback((name: string) => {
    setMetadataVisibility((prevState) => {
      return prevState.filter((field) => field !== name);
    });
  }, []);

  const validationSchema = useMemo(() => {
    const shape: Record<string, StringSchema> = {};
    metadataOptions.forEach(({ value: metadataOptionValue }) => {
      shape[metadataOptionValue] = string().test(
        metadataOptionValue,
        `${metadataOptionValue} cannot be empty`,
        (value) => {
          return Boolean(value || !metadataVisibility.includes(metadataOptionValue));
        }
      );
    });

    return object().shape(shape);
  }, [metadataOptions, metadataVisibility]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initials}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue }) => {
        return (
          <Form>
            <DialogContent>
              <Stack spacing={2}>
                {metadataOptions.map(({ value: metadataOptionValue }) => {
                  return (
                    <Field name={metadataOptionValue}>
                      {({ field }: FieldProps<string>) => {
                        const isNotSet = !field.value && !metadataVisibility.includes(field.name);
                        return (
                          <Stack direction="row" fullWidth align="flex-start" justify="flex-start">
                            <Box sx={{ flex: '1 1 auto' }}>
                              <TextField
                                {...field}
                                label={
                                  isNotSet
                                    ? `${metadataOptionValue}: ${formatMessage({ id: 'common.form.notSet' })}`
                                    : metadataOptionValue
                                }
                                value={field.value || ''}
                                size="small"
                                error={Boolean(errors[metadataOptionValue] && !isNotSet)}
                                {...(errors[metadataOptionValue] &&
                                  !isNotSet && { helperText: errors[metadataOptionValue] })}
                                name={metadataOptionValue}
                                fullWidth
                                disabled={isNotSet}
                              />
                            </Box>
                            {isNotSet ? (
                              <IconButton
                                name="Add"
                                onClick={() => {
                                  handleMetadataSetVisiblity(field.name);
                                }}
                                color="success"
                                fontSize="small"
                              />
                            ) : (
                              <IconButton
                                name="Trashcan"
                                onClick={() => {
                                  handleMetadataUnsetVisiblity(field.name);
                                  setFieldValue(field.name, '');
                                }}
                                color={'error'}
                                fontSize="small"
                              />
                            )}
                          </Stack>
                        );
                      }}
                    </Field>
                  );
                })}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" text={formatMessage({ id: 'common.form.cancel' })} onClick={dialogClose} />
              <Button type="submit" text={formatMessage({ id: 'common.form.apply' })} />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
