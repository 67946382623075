import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import scrollDialogToTheTop from 'components/Dialog/scrollDialogToTheTop';
import { CreateOrderType } from './types';

const CreateOrderConfirm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<CreateOrderType>();

  useEffect(() => {
    scrollDialogToTheTop();
  }, []);

  const hasAddressValue =
    values['address_1'] || values['address_2'] || values['address_3'] || values['address_4'] || values['address_5'];

  return (
    <>
      <GridContainer spacing={1}>
        <GridItem size={{ xs: 12 }}>
          <Alert severity="info" message={formatMessage({ id: 'common.confirmValues' })} />
        </GridItem>
        <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
          <Text type="h6" fontWeight="bold">
            {formatMessage({ id: 'common.detail.generalInfo' })}
          </Text>
        </GridItem>
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.description' })}
          value={values['description']}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.customer' })}
          value={values['customer']}
        />
        <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
          <Text type="h6" fontWeight="bold">
            {formatMessage({ id: 'usims.orders.form.order.data' })}
          </Text>
        </GridItem>
        <KeyValueGridItem
          fullWidth
          label={formatMessage({ id: 'usims.orders.form.order.imsi_pool_id' })}
          value={values['imsi_pool_id']?.label}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.first_imsi' })}
          value={values['first_imsi']}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.quantity' })}
          value={values['quantity']}
        />
        <KeyValueGridItem
          fullWidth
          label={formatMessage({ id: 'usims.orders.form.order.msisdn_pool_id' })}
          value={values['msisdn_pool_id']?.label}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.msisdn_type' })}
          value={formatMessage({ id: `usims.orders.form.order.msisdn_type.${values['msisdn_type']}` })}
        />
        <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
          <Text type="h6" fontWeight="bold">
            {formatMessage({ id: 'usims.orders.form.order.settings' })}
          </Text>
        </GridItem>

        <KeyValueGridItem label={formatMessage({ id: 'usims.orders.form.order.profile' })} value={values['profile']} />
        <KeyValueGridItem label={formatMessage({ id: 'usims.orders.form.order.type' })} value={values['type']} />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.graph_ref' })}
          value={values['graph_ref']}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.transport_key' })}
          value={values['transport_key']}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.is_dual_imsi' })}
          value={formatMessage({
            id: `usims.orders.form.order.is_dual_imsi.${values['is_dual_imsi'] === 'true' ? 'yes' : 'no'}`,
          })}
        />

        {values['is_dual_imsi'] === 'true' && (
          <KeyValueGridItem
            fullWidth
            label={formatMessage({ id: 'usims.orders.form.order.imsi2_pool_id' })}
            value={values['imsi2_pool_id']?.label}
          />
        )}

        <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
          <Text type="h6" fontWeight="bold">
            {formatMessage({ id: 'usims.orders.form.order.simSettings' })}
          </Text>
        </GridItem>
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.sim_id_1' })}
          value={values['sim_id_1']}
        />
        <KeyValueGridItem
          label={formatMessage({ id: 'usims.orders.form.order.sim_id_2' })}
          value={values['sim_id_2']}
        />

        {values['sim_id_3'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.sim_id_3' })}
            value={values['sim_id_3']}
          />
        )}
        {values['sim_id_4'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.sim_id_4' })}
            value={values['sim_id_4']}
          />
        )}

        {hasAddressValue && (
          <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
            <Text type="h6" fontWeight="bold">
              {formatMessage({ id: 'usims.orders.form.order.addressData' })}
            </Text>
          </GridItem>
        )}

        {values['address_1'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.address_1' })}
            value={values['address_1']}
          />
        )}
        {values['address_2'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.address_2' })}
            value={values['address_2']}
          />
        )}
        {values['address_3'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.address_3' })}
            value={values['address_3']}
          />
        )}
        {values['address_4'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.address_4' })}
            value={values['address_4']}
          />
        )}
        {values['address_5'] && (
          <KeyValueGridItem
            label={formatMessage({ id: 'usims.orders.form.order.address_5' })}
            value={values['address_5']}
          />
        )}

        <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
          <Text type="h6" fontWeight="bold">
            {formatMessage({ id: 'usims.form.usim.kData' })}
          </Text>
        </GridItem>
        <KeyValueGridItem
          fullWidth
          label={formatMessage({ id: 'usims.form.usim.encrypt' })}
          value={formatMessage({ id: `usims.form.usim.encrypt.${values['encrypt']}` })}
        />
        {values['encrypt'] === 1 && (
          <KeyValueGridItem
            fullWidth
            label={formatMessage({ id: 'usims.form.usim.use_default_tk' })}
            value={formatMessage({ id: `usims.form.usim.use_default_tk.${values['use_default_tk']}` })}
          />
        )}
        {values['use_default_tk'] === 'false' && values['encrypt'] === 1 && (
          <KeyValueGridItem
            fullWidth
            label={formatMessage({ id: 'usims.form.usim.use_key' })}
            value={values['use_key']}
          />
        )}

        <GridItem size={{ xs: 12 }} sx={{ mt: 1 }}>
          <Text type="h6" fontWeight="bold">
            {formatMessage({ id: 'usims.form.usim.op' })}
          </Text>
        </GridItem>
        <KeyValueGridItem
          fullWidth
          label={formatMessage({ id: 'usims.form.usim.key_type' })}
          value={formatMessage({
            id: `usims.form.usim.key_type.${values['key_type'] === 0 ? 'defaultHSSOP' : 'customOP'}`,
          })}
        />
        {values['key_type'] === 1 && (
          <KeyValueGridItem fullWidth label={formatMessage({ id: 'usims.form.usim.op' })} value={values['op']} />
        )}
      </GridContainer>
    </>
  );
};

export default CreateOrderConfirm;
