import { Outlet } from 'react-router-dom';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import {
  getCdrTrafficDifferenceAvailabilityDates,
  getCdrTrafficDifferenceSchema,
} from 'store/actions/cdrReports/trafficDifference';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TrafficDifferenceToolbar from './TrafficDifferenceToolbar';

export function CdrTrafficDifferenceLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCdrTrafficDifferenceSchema());
    dispatch(getCdrTrafficDifferenceAvailabilityDates());
  }, [dispatch]);

  return (
    <>
      <TrafficDifferenceToolbar />
      <Box sx={{ pt: 2 }}>
        <Outlet />
      </Box>
    </>
  );
}
