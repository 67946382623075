import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { LandingPageUsimsInfo } from 'store/models/landing';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { GetResultsRes } from 'store/models/filters';
import { Node } from 'store/models/node';
import { AnyAction } from 'redux';
import { showErrorToast } from './toast';

export enum LANDING_PAGE_ACTION_TYPE {
  USIMS_INFO_LOADING = 'LANDING_PAGE_USIMS_INFO_LOADING',
  USIMS_INFO_SUCCESS = 'LANDING_PAGE_USIMS_INFO_SUCCESS',
  USIMS_INFO_FAILURE = 'LANDING_PAGE_USIMS_INFO_FAILURE',
  USIMS_INFO_5G_LOADING = 'LANDING_PAGE_USIMS_INFO_5G_LOADING',
  USIMS_INFO_5G_SUCCESS = 'LANDING_PAGE_USIMS_INFO_5G_SUCCESS',
  USIMS_INFO_5G_FAILURE = 'LANDING_PAGE_USIMS_INFO_5G_FAILURE',
  NODES_4G_INFO_LOADING = 'LANDING_PAGE_NODES_4G_INFO_LOADING',
  NODES_4G_INFO_SUCCESS = 'LANDING_PAGE_NODES_4G_INFO_SUCCESS',
  NODES_4G_INFO_FAILURE = 'LANDING_PAGE_NODES_4G_INFO_FAILURE',
  NODES_5G_INFO_LOADING = 'LANDING_PAGE_NODES_5G_INFO_LOADING',
  NODES_5G_INFO_SUCCESS = 'LANDING_PAGE_NODES_5G_INFO_SUCCESS',
  NODES_5G_INFO_FAILURE = 'LANDING_PAGE_NODES_5G_INFO_FAILURE',
  TENANTS_4G_INFO_LOADING = 'LANDING_PAGE_TENANTS_4G_INFO_LOADING',
  TENANTS_4G_INFO_SUCCESS = 'LANDING_PAGE_TENANTS_4G_INFO_SUCCESS',
  TENANTS_4G_INFO_FAILURE = 'LANDING_PAGE_TENANTS_4G_INFO_FAILURE',
  TENANTS_5G_INFO_LOADING = 'LANDING_PAGE_TENANTS_5G_INFO_LOADING',
  TENANTS_5G_INFO_SUCCESS = 'LANDING_PAGE_TENANTS_5G_INFO_SUCCESS',
  TENANTS_5G_INFO_FAILURE = 'LANDING_PAGE_TENANTS_5G_INFO_FAILURE',
}

export type LandingPageAction = {
  type: LANDING_PAGE_ACTION_TYPE;
  payload: AnyAction;
};

export function getLandingPageUsimsInfo(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: LANDING_PAGE_ACTION_TYPE.USIMS_INFO_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: `${config.apis.getLandingPageUsimsInfo}`,
        method: 'GET',
      };
      const result = await dispatch(fetchData<LandingPageUsimsInfo>(options));
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.USIMS_INFO_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.USIMS_INFO_FAILURE,
      });
    }
  };
}

export function getLandingPageUsimsInfo5g(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: LANDING_PAGE_ACTION_TYPE.USIMS_INFO_5G_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: `${config.apis.getLandingPageUsimsInfo5g}`,
        method: 'GET',
      };
      const result = await dispatch(fetchData<LandingPageUsimsInfo>(options));
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.USIMS_INFO_5G_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.USIMS_INFO_5G_FAILURE,
      });
    }
  };
}

export function getLandingPageNodes4g(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: LANDING_PAGE_ACTION_TYPE.NODES_4G_INFO_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getNodes
          .replace('{sort}', '')
          .replace('{limit}', '')
          .replace('{page}', 0)
          .replace('{filters}', '')
          .replace('{platform}', 'athux'),
      };

      const result = await dispatch(fetchData<GetResultsRes<Node>>(options));
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.NODES_4G_INFO_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.NODES_4G_INFO_FAILURE,
      });
    }
  };
}

export function getLandingPageNodes5g(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: LANDING_PAGE_ACTION_TYPE.NODES_5G_INFO_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getNodes
          .replace('{sort}', '')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')
          .replace('{platform}', 'athonetos'),
      };

      const result = await dispatch(fetchData<GetResultsRes<Node>>(options));
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.NODES_5G_INFO_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.NODES_5G_INFO_FAILURE,
      });
    }
  };
}

export function getLandingPageTenants4g(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: LANDING_PAGE_ACTION_TYPE.TENANTS_4G_INFO_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getLandingPageTenants4gInfo,
        method: 'GET',
      };
      const result = await dispatch(fetchData(options));

      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.TENANTS_4G_INFO_SUCCESS,
        payload: result,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.TENANTS_4G_INFO_FAILURE,
      });
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    }
  };
}

export function getLandingPageTenants5g(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: LANDING_PAGE_ACTION_TYPE.TENANTS_5G_INFO_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getLandingPageTenants5gInfo,
        method: 'GET',
      };
      const result = await dispatch(fetchData(options));
      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.TENANTS_5G_INFO_SUCCESS,
        payload: result,
      });
    } catch (e) {
      console.error(e);

      dispatch({
        type: LANDING_PAGE_ACTION_TYPE.TENANTS_5G_INFO_FAILURE,
      });
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    }
  };
}
