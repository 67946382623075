import moment from 'moment';
import { Moment } from 'moment';
import { CdrReportsAction, CDR_ACTION_TYPE } from 'store/actions/cdrReports';
import {
  CdrPeriod,
  CdrDateRange,
  CdrFilter,
  CdrAvailabilityDates,
  CdrTrafficDifferenceTotal,
  CdrTrafficDifferenceSeries,
  CdrRankingGroupBy,
  CdrTrafficVolumeMetricsField,
  CdrRankingCalendarDate,
  CdrTrafficVolumeSeries,
} from 'store/models/cdr';
import { CdrRankingState } from '.';
import { getMomentAvailabilityDates } from '.';
import { Entity, entityFailure, entityIdle, entityLoading, entitySuccess } from '..';
import { CdrTrafficVolumeSeriesState } from './trafficVolume';

export type CdrReportsTrafficDifferenceTotalsState = CdrTrafficDifferenceTotal<number | null>[];

export type CdrTrafficDifferenceSeriesState = CdrTrafficDifferenceSeries<number | null, Moment>[];

export type CdrTrafficDifferenceRankingSeriesState = CdrTrafficVolumeSeries<number | null, Moment>;

export type CdrTrafficDifferenceAvailabilityDates = CdrAvailabilityDates<Moment>;

export type CdrReportsTrafficDifferenceState = {
  trafficDifferenceTotals: Entity<CdrReportsTrafficDifferenceTotalsState>;
  trafficDifferenceTrendSeries: Entity<CdrTrafficDifferenceSeriesState>;
  trafficDifferenceRankingSeries: Entity<CdrTrafficVolumeSeriesState>;
  trafficDifferenceAvailabilityDates: Entity<CdrTrafficDifferenceAvailabilityDates>;
  trafficDifferenceRanking: Entity<CdrRankingState>;
  trafficDifferenceDateRange: CdrDateRange | null;
  trafficDifferencePeriod: CdrPeriod;
  trafficDifferenceFilter: CdrFilter<string>;
  trafficDifferenceRankingGroupBy: CdrRankingGroupBy;
  trafficDifferenceRankingMetric: CdrTrafficVolumeMetricsField;
  trafficDifferenceRankingCalendarDate: CdrRankingCalendarDate;
};

const initialState: CdrReportsTrafficDifferenceState = {
  trafficDifferenceTotals: entityIdle(),
  trafficDifferenceAvailabilityDates: entityIdle(),
  trafficDifferenceTrendSeries: entityIdle(),
  trafficDifferenceRankingSeries: entityIdle(),
  trafficDifferenceRanking: entityIdle(),
  trafficDifferenceDateRange: null,
  trafficDifferencePeriod: 'day',
  trafficDifferenceFilter: {
    group_by: '',
    group_filter: '',
  },
  trafficDifferenceRankingGroupBy: '',
  trafficDifferenceRankingMetric: 'totallink',
  trafficDifferenceRankingCalendarDate: null,
};

export default function cdrReportsTrafficDifferenceReducer(
  state = initialState,
  action: CdrReportsAction
): CdrReportsTrafficDifferenceState {
  switch (action.type) {
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_LOADING:
      return {
        ...state,
        trafficDifferenceTotals: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_SUCCESS: {
      return {
        ...state,
        trafficDifferenceTotals: entitySuccess<CdrReportsTrafficDifferenceTotalsState>(
          action.payload.map((trafficDifferenceTotal) => ({
            ...trafficDifferenceTotal,
            total: {
              uplink: trafficDifferenceTotal.total.uplink === null ? null : Number(trafficDifferenceTotal.total.uplink),
              downlink:
                trafficDifferenceTotal.total.downlink === null ? null : Number(trafficDifferenceTotal.total.downlink),
              totallink:
                trafficDifferenceTotal.total.totallink === null ? null : Number(trafficDifferenceTotal.total.totallink),
            },
          }))
        ),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TOTALS_FAILURE:
      return {
        ...state,
        trafficDifferenceTotals: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_LOADING:
      return {
        ...state,
        trafficDifferenceAvailabilityDates: entityLoading(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_SUCCESS:
      return {
        ...state,
        trafficDifferenceAvailabilityDates: entitySuccess<CdrTrafficDifferenceAvailabilityDates>(
          getMomentAvailabilityDates(action.payload)
        ),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_AVAILABILITY_DATES_FAILURE:
      return {
        ...state,
        trafficDifferenceAvailabilityDates: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_LOADING:
      return {
        ...state,
        trafficDifferenceTrendSeries: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_SUCCESS: {
      return {
        ...state,
        trafficDifferenceTrendSeries: entitySuccess<CdrTrafficDifferenceSeriesState>(
          action.payload.map((trafficDifferenceSeries) => ({
            ...trafficDifferenceSeries,
            series: trafficDifferenceSeries.series.map((item) => ({
              uplink: item.uplink === null ? null : Number(item.uplink),
              downlink: item.downlink === null ? null : Number(item.downlink),
              totallink: item.totallink === null ? null : Number(item.totallink),
              datetime: moment(item.datetime),
            })),
          }))
        ),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_TREND_SERIES_FAILURE:
      return {
        ...state,
        trafficDifferenceTrendSeries: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_LOADING:
      return {
        ...state,
        trafficDifferenceRankingSeries: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_SUCCESS: {
      return {
        ...state,
        trafficDifferenceRankingSeries: entitySuccess<CdrTrafficDifferenceRankingSeriesState>(
          action.payload.map((item) => {
            return {
              uplink: Number(item.uplink),
              downlink: Number(item.downlink),
              totallink: Number(item.totallink),
              datetime: moment(item.datetime),
            };
          })
        ),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SERIES_FAILURE:
      return {
        ...state,
        trafficDifferenceRankingSeries: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_LOADING:
      return {
        ...state,
        trafficDifferenceRanking: entityLoading(),
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_SUCCESS: {
      return {
        ...state,
        trafficDifferenceRanking: entitySuccess<CdrRankingState>({
          top: action.payload.top.map((item) => {
            return {
              group_by: {
                ...item.group_by,
                value: item.group_by.value,
              },
              metric: {
                ...item.metric,
                value: Number(item.metric.value),
              },
            };
          }),
          bottom: action.payload.bottom?.map((item) => {
            return {
              group_by: {
                ...item.group_by,
                value: item.group_by.value,
              },
              metric: {
                ...item.metric,
                value: Number(item.metric.value),
              },
            };
          }),
        }),
      };
    }
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_RANKING_FAILURE:
      return {
        ...state,
        trafficDifferenceRanking: entityFailure(),
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_DATE_RANGE:
      return {
        ...state,
        trafficDifferenceDateRange: action.payload,
        trafficDifferenceRankingCalendarDate: null,
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_PERIOD:
      return {
        ...state,
        trafficDifferencePeriod: action.payload,
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_FILTER:
      return {
        ...state,
        trafficDifferenceFilter: action.payload,
      };

    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_GROUP_BY:
      return {
        ...state,
        trafficDifferenceRankingGroupBy: action.payload,
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_METRIC:
      return {
        ...state,
        trafficDifferenceRankingMetric: action.payload,
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_SET_RANKING_CALENDAR_DATE:
      return {
        ...state,
        trafficDifferenceRankingCalendarDate: action.payload,
      };
    case CDR_ACTION_TYPE.TRAFFIC_DIFFERENCE_FLUSH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
