export enum TENANT_TYPE {
  MASTER = 'master',
  CHANNEL_PARTNER = 'channel_partner',
  NETWORK_MANAGER = 'network_manager',
}

export type Tenant = {
  address: string;
  country: string;
  created_at: string;
  id: string;
  name: string;
  parent_tenant_name: string;
  parent_id: string;
  traffic: string;
  trend: string;
  trend_value: string;
  type: TENANT_TYPE;
  unit: string;
  logo: string;
};

export type SubTenant = {
  id: string;
  name: string;
  type: TENANT_TYPE;
};

export enum OWNER_PERMISSION {
  READ = 1,
  READ_WRITE = 255,
}

export type Owner = {
  id: string;
  name: string;
  permissions?: OWNER_PERMISSION;
  roles?: string[];
  type?: TENANT_TYPE;
};
