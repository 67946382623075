import { Outlet } from 'react-router-dom';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { getCdrImeiChangesAvailabilyDates, getCdrImeiChangesSchema } from 'store/actions/cdrReports/imeiChanges';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ImeiChangesToolbar } from './ImeiChangesToolbar';

export function CdrImeiChangesLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCdrImeiChangesSchema());
    dispatch(getCdrImeiChangesAvailabilyDates());
  }, [dispatch]);

  return (
    <>
      <ImeiChangesToolbar />
      <Box sx={{ pt: 2, flex: '1 1 auto' }}>
        <Outlet />
      </Box>
    </>
  );
}
