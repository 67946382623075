import { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { muiTheme, theme } from './index';

type ThemeProviderProps = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <StyledThemeProvider theme={theme}>
      <MUIThemeProvider theme={muiTheme}>{children}</MUIThemeProvider>
    </StyledThemeProvider>
  );
}
