import { useIntl } from 'react-intl';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useMemo } from 'react';

export default function Page404({ message, intlMessage }: { message?: string; intlMessage?: boolean }) {
  const { formatMessage } = useIntl();

  const getMessage = useMemo(() => {
    return intlMessage ? formatMessage({ id: message }) : message || formatMessage({ id: 'page404.message' });
  }, [formatMessage, intlMessage, message]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Text type="h4" fontWeight="bold" lineHeight={2}>
        {formatMessage({ id: 'page404.title' })}
      </Text>

      <Text>{getMessage}</Text>
    </Box>
  );
}
