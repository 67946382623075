import { useManageTenantsModalSelector } from 'store/selectors/modal';
import ManageTenants from 'components/Modals/ManageTenants';

export default function Modals() {
  const manageTenantsModal = useManageTenantsModalSelector();

  if (manageTenantsModal) {
    return <ManageTenants />;
  }

  return null;
}
