import { User } from '../models/user';
import { LoginAction, LOGIN_ACTION_TYPE } from '../actions/login';

export type LoginState = Partial<User> | null;

const initialState: LoginState = null;

export default function loginReducer(state = initialState, action: LoginAction): LoginState {
  switch (action.type) {
    case LOGIN_ACTION_TYPE.AUTH_USER:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case LOGIN_ACTION_TYPE.LOGIN_USER:
      return {
        ...state,
        id: action.payload.id,
        fullname: action.payload.fullname,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        lang: action.payload.lang,
        picture: action.payload.picture,
        permissions: action.payload.permissions.map((item: string) => item.toUpperCase()),
        loggable_tenants: action.payload.loggable_tenants,
        roles: action.payload.roles,
        tenant_id: action.payload.tenant_id,
        tenant_type: action.payload.tenant_type.toUpperCase(),
        tenant_name: action.payload.tenant_name,
        realm: action.payload.realm,
      };

    case LOGIN_ACTION_TYPE.UPDATE_TOKEN:
      return { ...state, accessToken: action.payload.accessToken };

    case LOGIN_ACTION_TYPE.LOGOUT_USER:
      return initialState;

    case LOGIN_ACTION_TYPE.SET_USER:
      return {
        ...state,
        fullname: action.payload.fullname,
        email: action.payload.email,
        phone: action.payload.phone,
        name: action.payload.name,
      };

    default:
      return state;
  }
}
