// Utils
import usStates from 'utils/usStates';
import countries from 'utils/countries';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';

type StateCountry = {
  code: string;
  name: string;
  group: string;
};

export default function getStatesCountries(): AutocompleteItemProps[] {
  const optionsCountry = countries().map((item: StateCountry) => ({
    value: item.code,
    label: item.name,
    group: item.group,
  }));
  const optionsUsStates = usStates().map((item: StateCountry) => ({
    value: item.code,
    label: item.name,
    group: item.group,
  }));

  return optionsUsStates.concat(optionsCountry);
}

export function getStateContryGroupBy(option: AutocompleteItemProps): string {
  return option.group || '';
}

export function getCountryLabel(code: StateCountry['code']): StateCountry['name'] | undefined {
  let stateCountry: StateCountry[] = countries().filter((item: StateCountry) => item.code === code);
  if (!stateCountry[0]) {
    stateCountry = usStates().filter((item: StateCountry) => item.code === code);
  }
  return stateCountry[0]?.name;
}
