import { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import { Button } from '@athonet/ui/components/Input/Button';
import TextField from '@material-ui/core/TextField';
import { MultiselectWrapper } from './styled';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { Value } from '@material-ui/lab/useAutocomplete';
import { showErrorToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';
import { sentryLogError } from 'sentry';

export type OptionT<T> = T & {
  group?: string;
  label: string;
};

export type Metadata = {
  label?: string;
};

type MultiSelectValue<T, M extends boolean> = Value<T, M, true, true>;

export type MultiSelectProps<T, M extends boolean> = AutocompleteProps<T, M, true, true> & {
  values?: MultiSelectValue<T, M>;
  asyncGetOptions?: (query?: string) => Promise<T[]>;
  onChange?: (value: MultiSelectValue<T, M>) => void;
  selectAll: boolean;
  name: string;
  label: string;
  setFieldValue: (field: string, values: MultiSelectValue<T, M> | OptionT<T>[]) => void;
  groupBy?: (option: T) => string;
  selectAllLabel?: string;
  renderInput: never;
  error?: boolean;
  helperText?: string;
};

/* ON ASYNC COMPONENTS To prevent MUI "None of the options..." error when passing down an empty string, 
pass down an array with a value on the options prop with the shape of the rest of the options 
and an empty value and label 
    options={[{
      label: '',
      value: '',
    }]} 
  which the component will filter out when rendering options.
  NOTE that the schema will need to check for a correct value as required instead of a string
  or it will not detect this value as an empty one */

export function Multiselect<T extends Record<string, unknown>, M extends boolean>({
  asyncGetOptions,
  values,
  onChange,
  options,
  limitTags,
  selectAll: canSelectAll,
  placeholder,
  setFieldValue,
  label,
  name,
  groupBy,
  selectAllLabel,
  error,
  helperText,
  ...props
}: Omit<MultiSelectProps<OptionT<T>, M>, 'renderInput'>) {
  /** For Async use */
  const [loadedOptions, setLoadedOptions] = useState(options);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const timer = useRef<number>();
  const dispatch = useDispatch();

  const getOptions = useCallback(
    async (val?: string) => {
      if (!asyncGetOptions) {
        return;
      }
      setLoading(true);
      try {
        const result = await asyncGetOptions(val);
        setLoadedOptions([...options, ...result]);
      } catch (e) {
        dispatch(showErrorToast());
        sentryLogError(e);
      }
      setLoading(false);
    },
    [asyncGetOptions, dispatch, options]
  );

  const asyncChange = useCallback(
    async (val) => {
      if (asyncGetOptions) {
        clearTimeout(timer.current);
        if (val.length > 0) {
          setLoading(true);
          timer.current = setTimeout(() => {
            void getOptions(val);
          }, 500) as unknown as number;
        }
      }
    },
    [asyncGetOptions, getOptions]
  );

  const handleOnChange = useCallback(
    (_: ChangeEvent<Record<string, unknown>>, newValue: MultiSelectValue<OptionT<T>, M>) => {
      if (onChange) {
        return onChange(newValue);
      }
      return setFieldValue(name, newValue);
    },
    [name, onChange, setFieldValue]
  );

  const handleSelectAll = useCallback(() => {
    setFieldValue(name, options);
  }, [name, options, setFieldValue]);

  const onAsyncChange = useCallback(
    async (e) => {
      void asyncChange(e.target.value);
    },
    [asyncChange]
  );

  useEffect(() => {
    void getOptions();
  }, [getOptions]);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <MultiselectWrapper>
      <Autocomplete
        fullWidth
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        loading={loading} // if Async
        autoComplete={false}
        limitTags={limitTags || 2}
        value={values}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        getOptionSelected={(option, value) => {
          return option.value === value || option.label === value.label;
        }}
        onChange={handleOnChange}
        options={loadedOptions}
        groupBy={groupBy}
        {...props}
        filterOptions={(optionsFilter) => optionsFilter.filter((option) => option.label !== '')}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            label={label}
            onChange={(e) => {
              void onAsyncChange(e);
            }} // if Async
            value={values}
            variant="outlined"
            placeholder={placeholder}
            autoComplete="off"
            data-testid={'field-' + name}
            inputProps={{
              autocomplete: 'field-input-' + name,
              'data-testid': 'field-input-' + name,
              ...params.inputProps,
            }}
          />
        )}
        renderTags={(optionItems, getTagProps) =>
          optionItems.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} size="small" />)
        }
      />
      {canSelectAll && (
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={handleSelectAll}
          data-testid="multiselect-selectall"
          text={selectAllLabel}
        />
      )}
    </MultiselectWrapper>
  );
}
