// Icons
import { circle as ico_dot } from 'react-icons-kit/fa/circle';

// Style
import { DotStyled } from './styled';

const Dot = ({ color }) => {
  return <DotStyled color={color} icon={ico_dot} size={4} />;
};

export default Dot;
