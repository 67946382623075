import { Text } from '@athonet/ui/components/Guidelines/Text';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { ReactNode } from 'react';

type KeyValueGridItemProps = {
  label: string;
  value?: string | number | boolean | null;
  valueComponent?: ReactNode;
  fullWidth?: boolean;
  compactWidth?: boolean;
};

export default function KeyValueGridItem({
  label,
  value,
  valueComponent,
  fullWidth,
  compactWidth,
}: KeyValueGridItemProps) {
  return (
    <GridItem size={{ xs: 12, ...(!fullWidth && { sm: 6 }), ...(compactWidth && { lg: 4 }) }}>
      <Stack spacing={0}>
        <Text fontWeight="bold">{label}</Text>
        {valueComponent || <Text>{value || '-'}</Text>}
      </Stack>
    </GridItem>
  );
}
