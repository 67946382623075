import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { ResponsiveControls } from '@athonet/ui/components/Navigation/ResponsiveControls';
import { Button } from '@athonet/ui/components/Input/Button';
import BillingConfigurationForm from './BillingConfigurationForm';
import { U_PERMISSIONS, checkPermissionToUse } from 'utils/permissionCodes';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';

export default function BillingToolbar() {
  const { drawerOpen } = useOverlay();
  const handleDrawerOpen = useCallback(() => {
    drawerOpen({
      title: `New Configuration`,
      content: () => <BillingConfigurationForm />,
    });
  }, [drawerOpen]);
  const userdata = useSelector((state) => state.user);

  return (
    <Box sx={{ pb: 2 }}>
      <Toolbar>
        <Stack direction="row" justify="flex-end" align="center" fullWidth>
          <ResponsiveControls wide>
            {/* <Button disabled variant="outlined" text="Import" startIcon={'Upload-Cloud'} />
                <Button disabled variant="outlined" text="Export" startIcon={'Download-Cloud'} />
                <Button disabled variant="outlined" text="Filters" startIcon={'Adjustments'} /> */}
            {checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_BILLING) ? (
              <Button
                onClick={handleDrawerOpen}
                variant="outlined"
                text="New"
                startIcon={'Add'}
                data-testid="billingconfiguration-new-button"
              />
            ) : (
              <></>
            )}
          </ResponsiveControls>
        </Stack>
      </Toolbar>
    </Box>
  );
}
