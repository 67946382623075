import { useSelector } from 'react-redux';

export function useUsims5gSelector() {
  const usims5g = useSelector((state) => state.usims5g);
  return usims5g;
}

export function useUsims5gListSelector() {
  const list = useSelector((state) => state.usims5g.list);
  return list;
}

export function useSingleUsim5gSelector(supi: string) {
  const list = useUsims5gListSelector();
  return list.data.data.find((usim5g) => usim5g.supi === supi);
}

export function useShouldFlushUsims5gSelection() {
  return useSelector((state) => state.usims5g.shouldflushSelection);
}

export function useUsims5gDetailsSelector() {
  const usim5gDetails = useSelector((state) => state.usims5g.usim5gDetails);
  return usim5gDetails;
}
