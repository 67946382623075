import { Button } from '@athonet/ui/components/Input/Button';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Box } from '@material-ui/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateUsim5gMetadata } from 'store/actions/usims5g';
import { MetadataDictionary, MetadataKey, availableKeys } from 'store/models/usim5g';

interface Usim5gMetadataEditorProps {
  metadata: MetadataDictionary;
  id: string;
}

const MetadataEditorDialog: React.FC<Usim5gMetadataEditorProps> = ({ id, metadata }) => {
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values) => {
      void dispatch(updateUsim5gMetadata(values, [id]));
      dialogClose();
    },
    [dialogClose, dispatch, id]
  );

  return (
    // TODOPS use baseformik/basedialog
    <Formik onSubmit={handleSubmit} initialValues={metadata}>
      {({ setFieldValue, dirty }) => {
        return (
          <Form>
            <DialogContent>
              <Stack spacing={2}>
                {availableKeys.map((key: MetadataKey) => {
                  return (
                    <Field name={key} key={key} fast>
                      {({ field }: FieldProps<string>) => {
                        const isDisabled = field.value === undefined || field.value == null;
                        return (
                          <Stack direction="row" fullWidth align="flex-start" justify="flex-start">
                            <Box sx={{ flex: '1 1 auto' }}>
                              <TextField //TODOPS use Debounced
                                {...field}
                                label={key}
                                size="small"
                                //helperText={key}
                                fullWidth
                                disabled={isDisabled}
                                value={field.value ?? ''}
                              />
                            </Box>
                            {isDisabled ? (
                              <IconButton
                                name="Add"
                                onClick={() => setFieldValue(field.name, '')}
                                color="success"
                                fontSize="small"
                              />
                            ) : (
                              <IconButton
                                name="Trashcan"
                                onClick={() => setFieldValue(field.name, null)}
                                color={'error'}
                                fontSize="small"
                              />
                            )}
                          </Stack>
                        );
                      }}
                    </Field>
                  );
                })}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" text={formatMessage({ id: 'common.form.cancel' })} onClick={dialogClose} />
              <Button disabled={dirty === false} type="submit" text={formatMessage({ id: 'common.form.apply' })} />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MetadataEditorDialog;
