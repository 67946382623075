import { useAppDispatch } from 'store';
import { ObjectSchema, StringSchema, array, object, string } from 'yup';
import React, { useCallback, useMemo, useRef } from 'react';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Form, FormikProps } from 'formik';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import BaseFormik from 'components/Form/BaseFormik';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { useIntl } from 'react-intl';
import SelectField from 'components/Form/Field/SelectField';
import { selectUsimOrderOptions } from 'store/selectors/usimOrders';
import { useSelector } from 'react-redux';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import { UploadOrderRequest, uploadUsimOrder } from 'store/actions/usimOrders/uploadUsimOrder';
import { StringSelectOption } from 'store/reducers/usimOrders';
import FileUploadField from 'components/Form/Field/FileUploadField';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import FormSubmitButton from 'components/Form/Button/FormSubmitButton';
import FormCancelButton from 'components/Form/Button/FormCancelButton';

type UploadOrderDialogType = {
  upload: File[];
  encryptionLevel: 'true' | 'false';
  gpgkey: StringSelectOption | null;
};

const UploadOrderDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();
  const privateKeys = useSelector((state) => selectUsimOrderOptions(state)?.privateKeys);
  const formikRef = useRef<FormikProps<any>>(null);

  const handleUpload = useCallback(
    async (formValues: UploadOrderDialogType) => {
      const uploadRequest: UploadOrderRequest = {
        encrypted: formValues.encryptionLevel === 'true',
        key_id: formValues.gpgkey?.value,
        upload: formValues.upload,
      };
      const result = await dispatch(uploadUsimOrder(uploadRequest));
      if (uploadUsimOrder.fulfilled.match(result)) {
        void dialogClose();
      }
      if (uploadUsimOrder.rejected.match(result)) {
        const errorText = result.payload;
        formikRef?.current?.setFieldError('upload', errorText ?? formatMessage({ id: 'common.error' }));
      }
    },
    [dialogClose, dispatch, formatMessage]
  );

  const initialValues: UploadOrderDialogType = useMemo(
    () => ({
      upload: [],
      encryptionLevel: 'false',
      gpgkey: null,
    }),
    []
  );

  const validationSchema: ObjectSchema<any> = useMemo(
    () =>
      object().shape({
        upload: array().min(1).max(1).required(), //UploadSchema(),
        encryptionLevel: string().required(), //boolean string, 'true' or 'false'
        gpgkey: object()
          .shape({ label: string(), value: string() })
          .nullable()
          .when('encryptionLevel', {
            is: 'true',
            then: (fieldSchema: StringSchema<any>) => fieldSchema.required(),
          }),
      }),
    []
  );

  return (
    <>
      <DialogContent>
        <BaseFormik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleUpload}
          validationSchema={validationSchema}
          validationPrefix={'usims.orders.form.order.upload'}
        >
          {({ values, setFieldValue, setFieldTouched }) => {
            return (
              <Form noValidate autoComplete="off">
                <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.upload.settings' })}>
                  <SelectField
                    name="encryptionLevel"
                    fast
                    onChange={(e) => {
                      if (e.target.value === 'false') {
                        setFieldValue('gpgkey', null);
                        setFieldTouched('gpgkey', false);
                      }
                    }}
                  >
                    <MenuItem value={'false'}>
                      {formatMessage({ id: 'usims.orders.form.order.upload.plainText' })}
                    </MenuItem>
                    <MenuItem value={'true'}>
                      {formatMessage({ id: 'usims.orders.form.order.upload.encrypted' })}
                    </MenuItem>
                  </SelectField>
                  <AutocompleteField
                    name="gpgkey"
                    fast
                    options={privateKeys}
                    disabled={values['encryptionLevel'] === 'false'}
                  />
                </BaseFieldset>
                <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.upload.subtitle' })}>
                  <FileUploadField
                    name="upload"
                    fast
                    label={formatMessage({ id: 'usims.orders.form.order.upload.title' })}
                    placeHolder={formatMessage({ id: 'usims.form.usim.upload.error' })}
                  />
                </BaseFieldset>
              </Form>
            );
          }}
        </BaseFormik>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
          <FormCancelButton formRef={formikRef} />
          <FormSubmitButton formRef={formikRef} />
        </Stack>
      </DialogActions>
    </>
  );
};

export default UploadOrderDialog;
