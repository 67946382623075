import { AnyAction } from 'redux';
import { USIMS_ACTION_TYPE } from '../actions/usims';
import {
  Entity,
  entityFailure,
  entityLoading,
  entitySuccess,
  List,
  listFailure,
  listIdle,
  listLoading,
  listSuccess,
  Sort,
} from '.';
import { FiltersObj } from 'store/models/filters';
import { Usim, UsimDetail } from 'store/models/usim';

export type UsimsState = {
  filters: FiltersObj;
  sort: Sort;
  list: List<Usim>;
  entities: Partial<Record<UsimDetail['id'], Entity<UsimDetail>>>;
  shouldflushSelection: boolean;
};

const initialState: UsimsState = {
  filters: {},
  sort: '',
  list: listIdle<Usim>(),
  entities: {},
  shouldflushSelection: false,
};

export default function usimsReducer(state = initialState, action: AnyAction): UsimsState {
  switch (action.type) {
    case USIMS_ACTION_TYPE.FILTERS_SET:
      return {
        ...state,
        filters: action.payload,
      };

    case USIMS_ACTION_TYPE.SORT_SET:
      return {
        ...state,
        sort: action.payload,
      };

    case USIMS_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };

    case USIMS_ACTION_TYPE.LIST_SUCCESS:
      const { items, total_items, page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ...listSuccess({
            data: items,
            total: total_items,
            page,
          }),
        },
      };

    case USIMS_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...listFailure(),
        },
      };

    case USIMS_ACTION_TYPE.ENTITY_LOADING:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: entityLoading(),
        },
      };
    case USIMS_ACTION_TYPE.ENTITY_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: entitySuccess(action.payload),
        },
      };
    case USIMS_ACTION_TYPE.ENTITY_FAILURE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: entityFailure(),
        },
      };

    case USIMS_ACTION_TYPE.USIM_SHOULD_FLUSH_SELECTION:
      return {
        ...state,
        shouldflushSelection: action.payload,
      };

    case USIMS_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
