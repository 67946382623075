import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { GetResultsRes, generateFiltersQuery } from 'store/models/filters';
import { UsimOrder } from 'store/models/usimOrder';
import { ListData } from 'store/reducers';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { showErrorToast } from '../toast';

export const getUsimOrders = createAsyncThunk<
  ListData<UsimOrder>,
  void,
  {
    rejectValue: null;
    state: State;
  }
>('usimOrders/get', async (_, { dispatch, rejectWithValue, getState }) => {
  const {
    bootstrap,
    usimOrders: {
      sort,
      filters,
      list: {
        data: { page },
      },
    },
  } = getState();

  try {
    const query = encodeURI(generateFiltersQuery(filters, '&'));

    const options: AxiosRequestConfig = {
      url: `${config.apis.getUsimOrders
        .replace('{sort}', sort)
        .replace('{limit}', bootstrap?.pageLimit)
        .replace('{page}', page)
        .replace('{filters}', query)}`,
      method: 'GET',
    };

    const fetchedData = await dispatch(fetchData<GetResultsRes<UsimOrder>>(options));

    const payload: ListData<UsimOrder> = {
      data: fetchedData.items,
      total: fetchedData.total_items,
      page,
    };
    return payload;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    return rejectWithValue(null);
  }
});
