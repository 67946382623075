import { fetchAdminConfigData } from '../../actions/fetchData';
import { AxiosRequestConfig } from 'axios';
import { Thunk } from '..';
import { hideToast, showErrorToast, showLoadingToast } from '../toast';
import config from 'config';
import { ADMIN_CONFIG_TYPE } from '.';
import { AdminConfig, AdminConfigItem } from '../../models/adminConfig';

export function getAdminConfigData(loadingState: boolean = true): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.adminConfigApis.adminConfig}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };
    try {
      dispatch(showLoadingToast());
      if (loadingState) {
        dispatch({ type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_LOADING });
      }
      const adminConfig = await dispatch(fetchAdminConfigData<AdminConfig>(options));
      dispatch({ type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_SUCCESS, payload: adminConfig });
      dispatch(hideToast());
    } catch (e: any) {
      dispatch({ type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_FAILURE });
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
    }
  };
}

export function editAdminConfig(payload: AdminConfigItem): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.adminConfigApis.updateAdminConfig.replace('{id}', encodeURIComponent(payload.id))}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'PUT',
      data: { ...payload },
    };
    try {
      await dispatch(fetchAdminConfigData<AdminConfigItem>(options));
      void dispatch(getAdminConfigData(false));
    } catch (e: any) {
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
    }
  };
}
