// React
import { useEffect, useState } from 'react';

// Intl
import { useIntl } from 'react-intl';

// App Components
import IconButton from '@material-ui/core/IconButton';

// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { ListItemSecondaryAction } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { TenantsSitesListStyled } from '../styled';
import { Button } from '@athonet/ui/components/Input/Button';

const TenantsSitesList = ({ data, onDeleteTenant }) => {
  const { formatMessage } = useIntl();
  const [deleteSure, setDeleteSure] = useState();

  useEffect(() => {
    setDeleteSure(undefined); // reset delete question
  }, [data]);

  return (
    <TenantsSitesListStyled>
      <div className="tenantlist-total">
        {formatMessage(
          { id: data.length === 1 ? 'nodes.form.tenants.total_one' : 'nodes.form.tenants.total' },
          { value: data.length }
        )}
      </div>
      <List>
        {data.map((item, index) => (
          <>
            <ListItem>
              <ListItemText primary={item.name} secondary={item.sites[0].name} />
              <ListItemSecondaryAction>
                <DeleteQuestion
                  tenantID={item.id}
                  siteID={item.sites[0].id}
                  onDeleteTenant={onDeleteTenant}
                  setDeleteSure={setDeleteSure}
                  isDeleteSure={deleteSure === index}
                  index={index}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {data.length > index + 1 && <Divider variant="middle" />}
          </>
        ))}
      </List>
    </TenantsSitesListStyled>
  );
};

const DeleteQuestion = ({ tenantID, siteID, onDeleteTenant, isDeleteSure, setDeleteSure, index }) => {
  const { formatMessage } = useIntl();

  const deleteQuestion = () => {
    setDeleteSure(index);
  };

  return isDeleteSure ? (
    <>
      <Button
        size="small"
        onClick={() => onDeleteTenant(tenantID, siteID)}
        text={formatMessage({ id: 'delete.form.textShort' })}
        color="error"
      />
      <IconButton edge="end" aria-label="delete">
        <CloseIcon onClick={() => setDeleteSure(undefined)} />
      </IconButton>
    </>
  ) : (
    <IconButton edge="end" aria-label="delete">
      <DeleteIcon onClick={() => deleteQuestion()} />
    </IconButton>
  );
};

export default TenantsSitesList;
