import moment, { Moment } from 'moment';
import { CdrReportsAction, CDR_ACTION_TYPE } from 'store/actions/cdrReports';
import {
  CdrAvailabilityDates,
  CdrAvailabilityPeriodRange,
  CdrChangesHistory,
  CdrDateRange,
  CdrFilter,
  CdrImeiChangesMetricsField,
  CdrImeiChangesSeries,
  CdrImeiChangesTotal,
  CdrPeriod,
  CdrRankingCalendarDate,
  CdrRankingGroupBy,
  CdrTimeSeriesQuery,
  CdrTimeSeriesSchema,
} from 'store/models/cdr';
import { CdrRankingState, CdrSchemaState, getMomentAvailabilityDates } from '.';
import { Entity, entityFailure, entityIdle, entityLoading, entitySuccess } from '..';

export type CdrReportsImeiChangesSeriesState = CdrImeiChangesSeries<Moment>;

export type CdrReportsImeiChangesTotalState = CdrImeiChangesTotal;

export type CdrReportsImeiChangesHistoryState = CdrChangesHistory<Moment>;

export type CdrImeiChangesAvailabilityDates = CdrAvailabilityDates<Moment>;

export type CdrImeiChangesTimeSeriesAvailabilityDates = CdrAvailabilityPeriodRange<Moment>;

export type CdrReportsImeiChangesState = {
  imeiChangesTotal: Entity<CdrReportsImeiChangesTotalState>;
  imeiChangesSchema: Entity<CdrSchemaState>;
  imeiChangesAvailabilityDates: Entity<CdrImeiChangesAvailabilityDates>;
  imeiChangesTrendSeries: Entity<CdrReportsImeiChangesSeriesState>;
  imeiChangesRankingSeries: Entity<CdrReportsImeiChangesSeriesState>;
  imeiChangesRanking: Entity<CdrRankingState>;
  imeiChangesHistory: Entity<CdrReportsImeiChangesHistoryState>;
  imeiChangesDateRange: CdrDateRange | null;
  imeiChangesPeriod: CdrPeriod;
  imeiChangesFilter: CdrFilter<string>;
  imeiChangesRankingGroupBy: CdrRankingGroupBy;
  imeiChangesRankingMetric: CdrImeiChangesMetricsField;
  imeiChangesRankingCalendarDate: CdrRankingCalendarDate;
  imeiChangesTimeseriesSchema: Entity<CdrTimeSeriesSchema>;
  imeiChangesTimeseriesAvailabilityDates: Entity<CdrImeiChangesTimeSeriesAvailabilityDates>;
  imeiChangesTimeseriesQuery: CdrTimeSeriesQuery;
};

const initialState: CdrReportsImeiChangesState = {
  imeiChangesTotal: entityIdle(),
  imeiChangesTrendSeries: entityIdle(),
  imeiChangesRankingSeries: entityIdle(),
  imeiChangesSchema: entityIdle(),
  imeiChangesAvailabilityDates: entityIdle(),
  imeiChangesRanking: entityIdle(),
  imeiChangesHistory: entityIdle(),
  imeiChangesDateRange: null,
  imeiChangesPeriod: 'day',
  imeiChangesFilter: {
    group_by: '',
    group_filter: '',
  },
  imeiChangesRankingMetric: 'imei_changes',
  imeiChangesRankingGroupBy: '',
  imeiChangesRankingCalendarDate: null,
  imeiChangesTimeseriesSchema: entityIdle(),
  imeiChangesTimeseriesAvailabilityDates: entityIdle(),
  imeiChangesTimeseriesQuery: { currentQuery: null, defaultQuery: null },
};

export default function cdrReportsImeiChangesReducer(
  state = initialState,
  action: CdrReportsAction
): CdrReportsImeiChangesState {
  switch (action.type) {
    case CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_LOADING:
      return {
        ...state,
        imeiChangesSchema: entityLoading(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_SUCCESS:
      return {
        ...state,
        imeiChangesSchema: entitySuccess<CdrSchemaState>(action.payload),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_SCHEMA_FAILURE:
      return {
        ...state,
        imeiChangesSchema: entityFailure(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_LOADING:
      return {
        ...state,
        imeiChangesAvailabilityDates: entityLoading(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_SUCCESS:
      return {
        ...state,
        imeiChangesAvailabilityDates: entitySuccess<CdrImeiChangesAvailabilityDates>(
          getMomentAvailabilityDates(action.payload)
        ),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_AVAILABILITY_DATES_FAILURE:
      return {
        ...state,
        imeiChangesAvailabilityDates: entityFailure(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_LOADING:
      return {
        ...state,
        imeiChangesTrendSeries: entityLoading(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_SUCCESS:
      return {
        ...state,
        imeiChangesTrendSeries: entitySuccess<CdrReportsImeiChangesSeriesState>(
          action.payload.map((item) => {
            return {
              imei_changes: item.imei_changes,
              datetime: moment(item.datetime),
            };
          })
        ),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TREND_SERIES_FAILURE:
      return {
        ...state,
        imeiChangesTrendSeries: entityFailure(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_LOADING:
      return {
        ...state,
        imeiChangesRankingSeries: entityLoading(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_SUCCESS:
      return {
        ...state,
        imeiChangesRankingSeries: entitySuccess<CdrReportsImeiChangesSeriesState>(
          action.payload.map((item) => {
            return {
              imei_changes: item.imei_changes,
              datetime: moment(item.datetime),
            };
          })
        ),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SERIES_FAILURE:
      return {
        ...state,
        imeiChangesRankingSeries: entityFailure(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_LOADING:
      return {
        ...state,
        imeiChangesRanking: entityLoading(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_SUCCESS: {
      return {
        ...state,
        imeiChangesRanking: entitySuccess<CdrRankingState>({
          top: action.payload.top.map((item) => {
            return {
              group_by: {
                ...item.group_by,
                value: item.group_by.value,
              },
              metric: {
                ...item.metric,
                value: Number(item.metric.value),
              },
            };
          }),
          bottom: action.payload.bottom?.map((item) => {
            return {
              group_by: {
                ...item.group_by,
                value: item.group_by.value,
              },
              metric: {
                ...item.metric,
                value: Number(item.metric.value),
              },
            };
          }),
        }),
      };
    }
    case CDR_ACTION_TYPE.IMEI_CHANGES_RANKING_FAILURE:
      return {
        ...state,
        imeiChangesRanking: entityFailure(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_LOADING:
      return {
        ...state,
        imeiChangesTotal: entityLoading(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_SUCCESS:
      return {
        ...state,
        imeiChangesTotal: entitySuccess<CdrReportsImeiChangesTotalState>({ imei_changes: action.payload.imei_changes }),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TOTAL_FAILURE:
      return {
        ...state,
        imeiChangesTotal: entityFailure(),
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_LOADING:
      return {
        ...state,
        imeiChangesHistory: entityLoading(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_SUCCESS: {
      return {
        ...state,
        imeiChangesHistory: entitySuccess<CdrReportsImeiChangesHistoryState>(
          action.payload.map((item) => {
            return {
              datetime: moment(item.datetime),
              from_imei: item.from_imei,
              to_imei: item.to_imei,
            };
          })
        ),
      };
    }
    case CDR_ACTION_TYPE.IMEI_CHANGES_HISTORY_FLUSH:
      return {
        ...state,
        imeiChangesHistory: initialState.imeiChangesHistory,
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_LOADING:
      return {
        ...state,
        imeiChangesTimeseriesSchema: entityLoading(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_SUCCESS:
      return {
        ...state,
        imeiChangesTimeseriesSchema: entitySuccess<CdrTimeSeriesSchema>(action.payload),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SCHEMA_FAILURE:
      return {
        ...state,
        imeiChangesTimeseriesSchema: entityFailure(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_LOADING:
      return {
        ...state,
        imeiChangesTimeseriesAvailabilityDates: entityLoading(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_SUCCESS:
      return {
        ...state,
        imeiChangesTimeseriesAvailabilityDates: entitySuccess<CdrImeiChangesTimeSeriesAvailabilityDates>({
          min: moment(action.payload.min),
          max: moment(action.payload.max),
        }),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_AVAILABILITY_DATES_FAILURE:
      return {
        ...state,
        imeiChangesTimeseriesAvailabilityDates: entityFailure(),
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_TIMESERIES_SET_QUERY:
      return {
        ...state,
        imeiChangesTimeseriesQuery: action.payload,
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_SET_DATE_RANGE:
      return {
        ...state,
        imeiChangesDateRange: action.payload,
        imeiChangesRankingCalendarDate: null,
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_SET_PERIOD:
      return {
        ...state,
        imeiChangesPeriod: action.payload,
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_SET_FILTER:
      return {
        ...state,
        imeiChangesFilter: action.payload,
      };

    case CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_GROUP_BY:
      return {
        ...state,
        imeiChangesRankingGroupBy: action.payload,
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_CALENDAR_DATE:
      return {
        ...state,
        imeiChangesRankingCalendarDate: action.payload,
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_SET_RANKING_METRIC:
      return {
        ...state,
        imeiChangesRankingMetric: action.payload,
      };
    case CDR_ACTION_TYPE.IMEI_CHANGES_FLUSH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
