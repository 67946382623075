import { useSelector } from 'react-redux';
import { SourceNetwork, SourceNetworkAggregation, SourceNetworkOperation } from 'store/models/cdr';

export function useCdrSourceNetworks(): SourceNetwork[] | undefined {
  const sourceNetworks = useSelector((state) => state.bootstrap?.source_networks);
  return sourceNetworks;
}

export function useCdrDefaultSourceNetwork(): SourceNetwork | null {
  const sourceNetworks = useCdrSourceNetworks();
  return (sourceNetworks && sourceNetworks[0]) || null;
}

export function useCdrActiveSourceNetwork(): SourceNetwork | null {
  const activeSourceNetwork = useSelector((state) => state.cdrReportsSourceNetwork.activeSourceNetwork);
  return activeSourceNetwork;
}

export function getCdrActiveSourceNetworkAggregation(
  sourceNetwork: SourceNetwork,
  operation: SourceNetworkOperation = '+'
): SourceNetworkAggregation[] {
  return [{ source_network: sourceNetwork, operation }];
}
