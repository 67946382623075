import { AdminConfig } from '../../models/adminConfig';

export enum ADMIN_CONFIG_TYPE {
  ADMIN_CONFIG_AUTH_SUCCESS = 'ADMIN_CONFIG_AUTH_SUCCESS',
  ADMIN_CONFIG_AUTH_FAILURE = 'ADMIN_CONFIG_AUTH_FAILURE',
  ADMIN_CONFIG_AUTH_LOADING = 'ADMIN_CONFIG_AUTH_LOADING',
  ADMIN_CONFIG_DATA_LOADING = 'ADMIN_CONFIG_DATA_LOADING',
  ADMIN_CONFIG_DATA_SUCCESS = 'ADMIN_CONFIG_DATA_SUCCESS',
  ADMIN_CONFIG_DATA_FAILURE = 'ADMIN_CONFIG_DATA_FAILURE',
  ADMIN_CONFIG_RESET = 'ADMIN_CONFIG_RESET',
}

export type AdminConfigAction =
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_LOADING;
    }
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_SUCCESS;
      payload: string;
    }
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_FAILURE;
    }
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_LOADING;
    }
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_SUCCESS;
      payload: AdminConfig;
    }
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_DATA_FAILURE;
    }
  | {
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_RESET;
    };
