import styled from 'styled-components';

export const ResetPasswordFormStyled = styled.div`
  .subtitle {
    margin-top: 0;
  }

  .reset-spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.panel.background + 'dd'};
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 60px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .MuiFormControl-root {
      margin-bottom: 30px;
    }

    .btn-submit {
      margin-top: 30px;
    }
  }
`;
