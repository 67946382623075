import { useSelector } from 'react-redux';
import { Usim } from 'store/models/usim';
import { checkPermissionToUse, checkTenantType, T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import { useQosProfileEntitySelector } from './qosProfiles';
import { useSegmentsEntitySelector } from './segments';
import { useUsimEntitySelector } from './usims/cards';
import { useUsimProfileEntitySelector } from './usims/usimProfiles';

export function useUserSelector() {
  const user = useSelector((state) => state.user);

  return user;
}

export function useCanUserReadUsimSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.READ_USIM);
}

export function useCanUserCreateUsimSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.CREATE_USER);
}

export function useCanUserUpdateUsimSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM);
}

export function useCanUserUpdateUsimProfileSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM_PROFILE);
}

/* NOTE: CC-1285 */
export function useCanUserUpdateSegmentSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_SEGMENT);
}

export function useCanUserUpdateQosProfileSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_QOS_PROFILE);
}

export function useHasUserMasterTenantSelector() {
  const user = useUserSelector();

  return checkTenantType(user?.tenant_type, [T_PERMISSIONS.MASTER]);
}

export function useHasUserCPMasterTenantSelector() {
  const user = useUserSelector();

  return checkTenantType(user?.tenant_type, [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER]);
}

export function useUserCPTenantSelector() {
  const user = useUserSelector();

  if (checkTenantType(user?.tenant_type, [T_PERMISSIONS.CHANNEL_PARTNER])) {
    return user?.tenant_id;
  } else {
    return null;
  }
}

export function useCanUserViewUsimOrdersSelector() {
  const user = useUserSelector();

  return (
    checkPermissionToUse(user, U_PERMISSIONS.READ_USIM_ORDER) ||
    checkTenantType(user?.tenant_type, [T_PERMISSIONS.MASTER])
  );
}

export function useCanUserDeleteUsimSelector(usimId: Usim['id']) {
  const user = useUserSelector();
  const usim = useUsimEntitySelector(usimId);

  return (
    checkPermissionToUse(user, U_PERMISSIONS.DELETE_USIM) &&
    usim?.data &&
    usim.data.status === 'in_stock' &&
    !usim.data.updating
  );
}

export function useCanUserBulkDeleteUsims5gSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.DELETE_USIM);
}

export function useCanUserDeleteUsimProfileSelector() {
  const user = useUserSelector();
  const profile = useUsimProfileEntitySelector();

  return checkPermissionToUse(user, U_PERMISSIONS.DELETE_USIM_PROFILE) && profile?.data?.status === 'in_stock';
}

export function useCanUserDeleteQosProfileSelector() {
  const user = useUserSelector();
  const profile = useQosProfileEntitySelector();
  const hasUserMasterTenant = useHasUserMasterTenantSelector();

  return (
    checkPermissionToUse(user, U_PERMISSIONS.DELETE_QOS_PROFILE) &&
    profile?.data?.status === 'in_stock' &&
    hasUserMasterTenant
  );
}

/* NOTE: CC-1285 */
export function useCanUserDeleteSegmentSelector() {
  const user = useUserSelector();
  const segment = useSegmentsEntitySelector();

  return checkPermissionToUse(user, U_PERMISSIONS.DELETE_SEGMENT) && segment?.data?.status === 'in_stock';
}

export function useCanUserUpdateTenantParentSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_TENANT_PARENT);
}

export function useLoggableTenantsSelector() {
  const user = useUserSelector();

  return user?.loggable_tenants
    ? user.loggable_tenants.map(({ id, name: tenantName, roles }) => ({
        tenant: tenantName,
        status: id === user.tenant_id,
        roles,
      }))
    : [];
}

export function useCanUserUpdateUserSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USER);
}

export function useCanUserCreateTenantSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.CREATE_TENANT);
}

export function useCanUserReadNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.READ_NODE);
}

export function useCanUserAssignSiteToNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.ASSOCIATE_SITE_NODE);
}

export function useCanUserUpdateNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_NODE);
}

export function useCanUserDeleteNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.DELETE_NODE);
}

export function useCanUserCreateNetworkElementSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.CREATE_NETWORK_ELEMENT);
}

export function useCanUserViewCDRSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, [U_PERMISSIONS.READ_TRAFFIC, U_PERMISSIONS.READ_SIM_TRACKING]);
}

export function useCanUserViewSimsBySiteMapSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.READ_SITE) && checkPermissionToUse(user, U_PERMISSIONS.READ_USIM);
}
