import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useCallback, useMemo } from 'react';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Step5 } from './Step5';
import { useIntl } from 'react-intl';

export function Step4({ isLegacyNode, newNodeId }: { isLegacyNode: boolean; newNodeId: string | null }) {
  const { formatMessage } = useIntl();
  const { dialogOpen, dialogClose } = useOverlay();

  const message = useMemo(
    () =>
      isLegacyNode
        ? formatMessage({ id: 'nodes.create.success.message' })
        : `${formatMessage({ id: 'nodes.create.success.title' })} ${formatMessage({
            id: 'nodes.create.prepare',
          })}`,
    [formatMessage, isLegacyNode]
  );

  const handleOpenStep5 = useCallback(() => {
    dialogClose();
    dialogOpen({ id: 'node-preparation', title: 'Node Preparation', content: () => <Step5 newNodeId={newNodeId} /> });
  }, [dialogClose, dialogOpen, newNodeId]);

  return (
    <>
      <Alert severity="success" title={formatMessage({ id: 'nodes.create.success.title' })} message={message} />
      <Stack spacing={2} direction="row" justify="flex-end" sx={{ pt: 2 }}>
        <Button
          variant="outlined"
          text={
            isLegacyNode
              ? formatMessage({ id: 'common.form.close' })
              : formatMessage({ id: '5gProvisioning.skipPreparation' })
          }
          onClick={dialogClose}
        />
        {!isLegacyNode && (
          <Button
            variant="outlined"
            text={formatMessage({ id: 'nodes.create.prepare.continue' })}
            onClick={handleOpenStep5}
          />
        )}
      </Stack>
    </>
  );
}
