import React from 'react';
import { SelectProps } from '@athonet/ui/components/Input/Select';
import SelectComponent from './SelectComponent';
import BaseField, { BaseFieldProps } from './BaseField';

const SelectField: React.FC<SelectProps<any> & { placeholder?: string } & BaseFieldProps> = (props) => {
  const { component, ...baseProps } = props;

  return <BaseField {...baseProps} component={component || SelectComponent} />;
};

export default SelectField;
