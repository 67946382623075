import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue } from 'store/models/filters';
import { useCallback, useMemo } from 'react';
import { FiltersDrawer, FiltersDrawerItem, FiltersProps } from '../Drawer';
import { string } from 'yup';
import { useDispatch } from 'react-redux';
import { filtersSet } from 'store/actions/segments';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';

type SegmentsFiltersProps = FiltersProps & {
  data: {
    qos_templates_4g: AutocompleteItemProps[];
  };
};

/* NOTE: CC-1285 */
export default function Segments({ data, values }: SegmentsFiltersProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'name',
        label: formatMessage({ id: 'segments.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'status',
        label: formatMessage({ id: 'segments.filters.status' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['status'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'segments.status.in_stock' }), value: 'in_stock' },
          { label: formatMessage({ id: 'segments.status.ready' }), value: 'ready' },
          { label: formatMessage({ id: 'segments.status.updating' }), value: 'updating' },
          { label: formatMessage({ id: 'segments.status.error' }), value: 'error' },
        ],
      },
      {
        name: 'apn',
        label: formatMessage({ id: 'segments.filters.apn.label' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['apn'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'pdp_type',
        label: formatMessage({ id: 'segments.filters.pdp_type' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['pdp_type'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { value: 1, label: 'IPv4' },
          { value: 2, label: 'IPv6' },
          { value: 3, label: 'IPv4v6' },
        ],
      },
      // {
      //   name: 'charging_characteristics',
      //   label: formatMessage({ id: 'segments.filters.charging_characteristics' }),
      //   // error: formatMessage({ id: 'segments.filters.charging_characteristics.error' }),
      //   initial: values['charging_characteristics'],
      // },
      {
        name: 'id_qos_template_4g',
        label: formatMessage({ id: 'segments.filters.id_qos_template_4g' }),
        // helperText: formatMessage({ id: 'segments.filters.id_qos_template_4g.error' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['id_qos_template_4g'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: data.qos_templates_4g,
      },
      /*{
      name: "pgw_allocation_profile",
      label: formatMessage({id:"segments.filters.pgw_allocation_profile"}),
      placeholder: formatMessage({id:"segments.filters.pgw_allocation_profile.placeholder"}),
      error: formatMessage({id:"segments.filters.pgw_allocation_profile.error"}),
      options: data.pgw_allocation_profiles
    },
    {
      name: "non_ip_template_id",
      label: formatMessage({id:"segments.filters.non_ip_template_id"}),
      placeholder: formatMessage({id:"segments.filters.non_ip_template_id.placeholder"}),
      error: formatMessage({id:"segments.filters.non_ip_template_id.error"}),
      options: data.non_ip_templates
    },
    {
      name: "node_id",
      label: formatMessage({id:"segments.filters.node"}),
      placeholder: formatMessage({id:"segments.filters.node.placeholder"}),
      options: data.nodes,
      permissions: [T_PERMISSIONS.MASTER],
    },
    {
      name: "tenant_id",
      label: formatMessage({id:"segments.filters.tenant"}),
      placeholder: formatMessage({id:"segments.filters.tenant.placeholder"}),
      options: data.tenants,
      permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
    }*/
    ],
    [data, formatMessage, values]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(filtersSet(filtersValues)), [dispatch]);

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
