import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const messages = defineMessages({
  title: { id: 'unauthorized.title' },
  message: { id: 'unauthorized.message' },
});

const Centered = styled.div`
  text-align: center;
`;

const Unauthorized = () => (
  <Centered>
    <h1>
      <FormattedMessage {...messages.title} />
    </h1>

    <p>
      <FormattedMessage {...messages.message} />
    </p>
  </Centered>
);

export default Unauthorized;
