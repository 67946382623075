import { useAppDispatch } from 'store';
import { ObjectSchema, StringSchema, object, string } from 'yup';
import React, { useCallback, useMemo, useRef } from 'react';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Form, FormikProps } from 'formik';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import BaseFormik from 'components/Form/BaseFormik';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { useIntl } from 'react-intl';
import SelectField from 'components/Form/Field/SelectField';
import { selectUsimOrderOptions } from 'store/selectors/usimOrders';
import { useSelector } from 'react-redux';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import { StringSelectOption } from 'store/reducers/usimOrders';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import config from 'config';
import axios, { AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';
import getFetchHeaders from 'utils/fetchHeaders';
import { showErrorToast } from 'store/actions/toast';
import FormSubmitButton from 'components/Form/Button/FormSubmitButton';
import FormCancelButton from 'components/Form/Button/FormCancelButton';

type DownloadOrderDialogType = {
  encryptionLevel: 'true' | 'false';
  gpgkey: StringSelectOption | null;
  fileType: 'tar' | 'zip';
};

const DownloadOrderDialog: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const keys = useSelector((state) => selectUsimOrderOptions(state)?.keys);
  const userdata = useSelector((state) => state.user);
  const formikRef = useRef<FormikProps<any>>(null);

  const handleDownload = useCallback(
    async (formValues: DownloadOrderDialogType) => {
      let url = `${config.apis.downloadPlainUsimOrder.replace('{id}', id).replace('{type}', formValues.fileType)}`;
      if (formValues.encryptionLevel === 'true') {
        url = `${config.apis.downloadEncryptedUsimOrder
          .replace('{id}', id)
          .replace('{type}', formValues.fileType)
          .replace('{key_id}', formValues.gpgkey?.value)}`;
      }

      const options: AxiosRequestConfig = {
        url,
        responseType: 'blob',
        headers: getFetchHeaders(userdata, false),
      };
      let filename = '';
      axios
        .get(url, options)
        .then((response) => {
          if (response.status !== 200) {
            dispatch(showErrorToast());
          }
          const contentDisposition = response.headers['content-disposition']; //TODOPS how to get filename?
          const startIndex = contentDisposition.indexOf('filename=') + 10;
          const endIndex = contentDisposition.length - 1;
          filename = contentDisposition.substring(startIndex, endIndex);
          saveAs(response.data, filename);
        })
        .catch((e) => {
          dispatch(showErrorToast());
        });
    },
    [dispatch, id, userdata]
  );

  const initialValues: DownloadOrderDialogType = useMemo(
    () => ({
      encryptionLevel: 'false',
      gpgkey: null,
      fileType: 'zip',
    }),
    []
  );

  const validationSchema: ObjectSchema<any> = useMemo(
    () =>
      object().shape({
        encryptionLevel: string().required(), //boolean string, 'true' or 'false' boolean().required(),
        fileType: string().required(), //'zip' or 'tar'
        gpgkey: object()
          .shape({ label: string(), value: string() })
          .nullable()
          .when('encryptionLevel', {
            is: 'true',
            then: (fieldSchema: StringSchema<any>) => fieldSchema.required(),
          }),
      }),
    []
  );

  return (
    <>
      <DialogContent>
        <BaseFormik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleDownload}
          validationSchema={validationSchema}
          validationPrefix={'usims.orders.form.order.download'}
        >
          {({ values, setFieldValue, setFieldTouched }) => {
            return (
              <Form noValidate autoComplete="off">
                <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.download.settings' })}>
                  <SelectField name="fileType" fast disabled>
                    <MenuItem value={'zip'}>
                      {formatMessage({ id: 'usims.orders.form.order.download.fileTypes.zip' })}
                    </MenuItem>
                    <MenuItem value={'tar'}>
                      {formatMessage({ id: 'usims.orders.form.order.download.fileTypes.tar' })}
                    </MenuItem>
                  </SelectField>

                  <SelectField
                    name="encryptionLevel"
                    fast
                    onChange={(e) => {
                      if (e.target.value === 'false') {
                        setFieldValue('gpgkey', null);
                        setFieldTouched('gpgkey', false);
                      }
                    }}
                  >
                    <MenuItem value={'false'}>
                      {formatMessage({ id: 'usims.orders.form.order.download.plainText' })}
                    </MenuItem>
                    <MenuItem value={'true'}>
                      {formatMessage({ id: 'usims.orders.form.order.download.encrypted' })}
                    </MenuItem>
                  </SelectField>

                  <AutocompleteField
                    name="gpgkey"
                    fast
                    options={keys}
                    disabled={values['encryptionLevel'] === 'false'}
                  />
                </BaseFieldset>
              </Form>
            );
          }}
        </BaseFormik>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
          <FormCancelButton formRef={formikRef} />
          <FormSubmitButton formRef={formikRef} />
        </Stack>
      </DialogActions>
    </>
  );
};

export default DownloadOrderDialog;
