export type ServiceProfile = { id: string; name: string; node_id: string; slices: Slice[] };

export type Slice = {
  id: string;
  name: string;
  differentiator: string; // differentiatorRegExp
  services: Service[];
};

export enum SERVICE_TYPE {
  DATA_TRAFFIC = 'DATA_TRAFFIC',
  VOICE_SERVICES = 'VOICE_SERVICE',
  MC_PTT = 'MC_PTT',
}

export type Service = {
  id: string;
  name: string; // apnRegExp
  type: SERVICE_TYPE;
  default: boolean;
};

export const differentiatorRegExp = /^[a-f0-9]{1,6}$/;
