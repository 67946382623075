import { Button, ButtonProps } from '@athonet/ui/components/Input/Button';
import React from 'react';
import { useIntl } from 'react-intl';

export const CancelButton: React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      variant="outlined"
      text={formatMessage({ id: 'common.form.cancel' })}
      {...props}
      data-testid="footer-cancel"
    />
  );
};
