import { Button } from '@athonet/ui/components/Input/Button';
import { Fieldset } from '@athonet/ui/components/Input/Fieldset';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { DrawerActions } from '@athonet/ui/components/Overlay/Drawer/DrawerActions';
import { DrawerContent } from '@athonet/ui/components/Overlay/Drawer/DrawerContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editAdminConfig } from 'store/actions/adminconfig/adminConfig';
import { AdminConfigItem } from 'store/models/adminConfig';
import { number, object, string, array } from 'yup';
import { useIntl } from 'react-intl';
import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';

type AdminConfigFormProps = {
  adminConfig: AdminConfigItem;
};

export default function AdminConfigForm({ adminConfig }: AdminConfigFormProps) {
  const { drawerClose } = useOverlay();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const handleFormSubmit = useCallback(
    (values: AdminConfigItem) => {
      if (values) {
        dispatch(editAdminConfig(values));
      }
      drawerClose();
    },
    [dispatch, drawerClose]
  );

  const parsedAdminConfig = useMemo(() => {
    const ac = { ...adminConfig };
    if (ac.type_value === 'array' && typeof ac.value === 'object') {
      ac.value = ac.value.map((s) => s.toString());
    }
    return ac;
  }, [adminConfig]);

  const initialValues = useMemo(
    () =>
      parsedAdminConfig || {
        id: '',
        category: '',
        sub_category: '',
        access_level: '',
        description: '',
        key: '',
        requires_reboot: '',
        value: [],
        type_value: '',
      },
    [parsedAdminConfig]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        id: string(),
        category: string().required(),
        value:
          parsedAdminConfig.type_value === 'string' || parsedAdminConfig.type_value === 'boolean'
            ? string().required()
            : parsedAdminConfig.type_value === 'number'
            ? number().required()
            : array().min(1).required(),
      }),
    [parsedAdminConfig]
  );

  useEffect(() => {
    if (parsedAdminConfig.type_value === 'boolean') {
      setSearchResults(['true', 'false']);
    } else {
      setSearchResults([]);
    }
  }, [parsedAdminConfig]);

  const handleAutoCompleteChange = useCallback((selected, setFieldValue) => {
    if (selected) {
      setFieldValue('value', selected);
    }
  }, []);

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
      >
        {({ isValid, dirty, setFieldValue, errors, touched, handleSubmit }) => {
          return (
            <Form style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
              <DrawerContent>
                <Fieldset sx={{ pt: 1 }} direction="column">
                  <Field name="category">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label={formatMessage({ id: 'adminConfig.form.category' })}
                        size="small"
                        disabled
                        variant={'outlined'}
                        error={Boolean(errors.category && touched.category)}
                        helperText={Boolean(errors.category && touched.category) ? errors.category : ''}
                        data-testid="adminconfig-form-drawer-category-textfield"
                      />
                    )}
                  </Field>
                  <Field name="key">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label={formatMessage({ id: 'adminConfig.form.key' })}
                        size="small"
                        disabled
                        variant={'outlined'}
                        error={Boolean(errors.key && touched.key)}
                        helperText={Boolean(errors.key && touched.key) ? errors.key : ''}
                        data-testid="adminconfig-form-drawer-key-textfield"
                      />
                    )}
                  </Field>
                  <Field name="value">
                    {({ field }: FieldProps<string | string[]>) =>
                      parsedAdminConfig.type_value === 'array' || parsedAdminConfig.type_value === 'boolean' ? (
                        <Autocomplete
                          {...field}
                          value={field.value}
                          label={formatMessage({ id: 'adminConfig.form.value' })}
                          onChange={(e, selected) => handleAutoCompleteChange(selected, setFieldValue)}
                          size="small"
                          options={searchResults}
                          freeSolo={parsedAdminConfig.type_value === 'array' ? true : false}
                          variant={'outlined'}
                          error={Boolean(errors.value && touched.value)}
                          helperText={Boolean(errors.value && touched.value) ? errors.value : ''}
                          data-testid="adminconfig-form-drawer-value-textfield"
                          multiple={parsedAdminConfig.type_value === 'array'}
                        />
                      ) : (
                        <TextField
                          {...field}
                          value={field.value}
                          label={formatMessage({ id: 'adminConfig.form.value' })}
                          size="small"
                          variant={'outlined'}
                          type={parsedAdminConfig.type_value === 'number' ? 'number' : undefined}
                          error={Boolean(errors.value && touched.value)}
                          helperText={Boolean(errors.value && touched.value) ? errors.value : ''}
                          data-testid="adminconfig-form-drawer-value-textfield"
                        />
                      )
                    }
                  </Field>
                </Fieldset>
              </DrawerContent>
              <DrawerActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  text={formatMessage({ id: 'adminConfig.form.button.cancel' })}
                  onClick={drawerClose}
                  data-testid="adminconfig-form-drawer-cancel-button"
                />
                <Button
                  disabled={!isValid || !dirty}
                  sx={{ width: 'fit-content' }}
                  text={formatMessage({ id: 'adminConfig.form.button.update' })}
                  color="secondary"
                  data-testid="adminconfig-form-drawer-update-button"
                  onClick={() => handleSubmit()}
                />
              </DrawerActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
