// Router
import { useNavigate, useParams } from 'react-router';

// Config by ENV
import config from 'config';

// Axios
import axios from 'axios';

// App Components
import ResetPasswordForm from 'components/ResetPasswordForm';

// Intl
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetToken } = useParams();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  };

  const resetSubmit = (values, actions) => {
    const data = new FormData();
    data.append('code', resetToken);
    data.append('newpassword', values.password);

    const options = {
      url: `${config.apis.resetPassword}`,
      method: 'POST',
      headers: headers,
      data: data,
    };

    axios(options)
      .then(() => {
        // success message
        dispatch(
          showSuccessToast({
            message: 'login.reset.passwordChanged',
            intlMessage: true,
          })
        );
        // go to landing page
        navigate('/login');
      })
      .catch(() => {
        // error message
        dispatch(
          showErrorToast({
            message: 'login.reset.resetError',
            intlMessage: true,
          })
        );
        // reset form
        actions.resetForm();
      });
  };

  const onBack = () => {
    navigate('/login');
  };

  return (
    <div>
      <ResetPasswordForm onSubmit={resetSubmit} onBack={onBack} />
    </div>
  );
};

export default ResetPassword;
