import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Slice, differentiatorRegExp } from 'store/models/serviceProfile';
import { useDispatch } from 'react-redux';
import { createSlice, editSlice } from 'store/actions/serviceProfiles';
import { useNodeEntitySelector } from 'store/selectors/nodes';

export function EditSlice({ serviceProfileId, slice }: { serviceProfileId: string; slice?: Slice }) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const nodeEntity = useNodeEntitySelector();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required(),
        differentiator: string().matches(differentiatorRegExp),
      }),
    []
  );

  const initials = useMemo(
    () => ({
      name: slice?.name || '',
      differentiator: slice?.differentiator || '',
    }),
    [slice]
  );

  const handleEditSlice = useCallback(
    async ({ name, differentiator }) => {
      if (!nodeEntity.data) {
        return;
      }
      const paddedDifferentiator = String(differentiator).padStart(6, '0');
      setLoading(true);

      const errorRes = slice
        ? await dispatch(
            editSlice({
              nodeId: nodeEntity.data.id,
              serviceProfileId,
              sliceId: slice.id,
              data: { name, differentiator: paddedDifferentiator },
            })
          )
        : await dispatch(
            createSlice({
              nodeId: nodeEntity.data.id,
              serviceProfileId,
              data: { name, differentiator: paddedDifferentiator },
            })
          );
      setLoading(false);

      if (!errorRes) {
        dialogClose();
      } else {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, nodeEntity.data, serviceProfileId, slice]
  );

  return (
    <Formik
      initialValues={initials}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleEditSlice}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <DialogContent>
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <Field name="name" key="name">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      label={formatMessage({ id: 'serviceProfiles.slices.form.name.label' })}
                      placeholder={formatMessage({ id: 'serviceProfiles.slices.form.name.placeholder' })}
                      error={Boolean(errors['name'] && touched['name'])}
                      {...(Boolean(errors['name'] && touched['name']) && {
                        helperText: formatMessage({ id: 'serviceProfiles.slices.form.name.error' }),
                      })}
                    />
                  )}
                </Field>
                <Field name="differentiator" key="differentiator">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      label={formatMessage({ id: 'serviceProfiles.slices.form.differentiator.label' })}
                      placeholder={formatMessage({ id: 'serviceProfiles.slices.form.differentiator.placeholder' })}
                      error={Boolean(errors['differentiator'] && touched['differentiator'])}
                      {...(Boolean(errors['differentiator'] && touched['differentiator']) && {
                        helperText: formatMessage({ id: 'serviceProfiles.slices.form.differentiator.error' }),
                      })}
                    />
                  )}
                </Field>
              </Stack>
            </DialogContent>
            {error && (
              <Box sx={{ mx: 2 }}>
                <Alert
                  severity="error"
                  title={formatMessage({ id: 'common.error' })}
                  message={
                    slice
                      ? formatMessage({ id: 'serviceProfile.slices.form.edit.error.title' }, { error })
                      : formatMessage({ id: 'serviceProfile.slices.form.create.error.title' }, { error })
                  }
                />
              </Box>
            )}
            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  loading={loading}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={
                    error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                  }
                  loading={loading}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
