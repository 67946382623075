import { useMemo, useCallback, useRef, useEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import { loadUsimOrdersOptions } from '../../../store/actions/usimOrders/loadUsimOrdersOptions';
import { getUsimOrders } from '../../../store/actions/usimOrders/getUsimOrders';
import moment from 'moment';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/UsimOrders';
import List from 'components/List';
import CreateOrderButton from 'components/Edit/UsimOrders/CreateOrderButton';
import DropdownTools from 'components/DropdownTools';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { code } from 'react-icons-kit/fa/code';
import { ic_cloud_download as ico_download } from 'react-icons-kit/md/ic_cloud_download';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import config from 'config';
import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';
import { deleteUsimOrder } from 'store/actions/usimOrders/deleteUsimOrder';
import { useAppDispatch } from 'store';
import { UsimOrder, UsimOrderStatus } from 'store/models/usimOrder';
import JsonViewerDialog from 'components/Dialog/JsonViewerDialog';
import { DATA_LIFECYCLE } from 'store/reducers';
import {
  clearUsimOrderList,
  setUsimOrderFilters,
  setUsimOrderPageNumber,
  setUsimOrderSort,
} from 'store/reducers/usimOrders';
import UploadOrderButton from 'components/Edit/UsimOrders/UploadOrderButton';
import DownloadOrderDialog from 'components/Edit/UsimOrders/DownloadOrderDialog';
import { PAGE_LIMIT } from 'store/models/environmentConfiguration';
import { getFiltersByQueryString } from 'store/models/filters';
import isEmpty from 'lodash/isEmpty';
import { selectBootstrap } from 'store/selectors/bootstrap';

const UsimOrders = () => {
  const dispatch = useAppDispatch();

  const data = useSelector((state) => state.usimOrders.list.data);
  const pageLimit = useSelector((state) => selectBootstrap(state)?.pageLimit);
  const { locale, formatMessage } = useIntl();
  const userdata = useSelector((state) => state.user);
  const { confirmationDialogOpen, dialogOpen, dialogClose } = useOverlay();
  const [configLocale] = locale.split('-');
  const localizedConfig = useMemo(() => config[configLocale] || config.en, [configLocale]);
  const loadingState: DATA_LIFECYCLE = useSelector((state) => state.usimOrders.list.state);
  const filters = useSelector((state) => state.usimOrders.filters);
  const queryStringFilters = useMemo(() => getFiltersByQueryString(), []);
  const initialPageLimit = useRef<number>(PAGE_LIMIT);
  const initialSort = useRef<string>('-created_at');

  useEffect(() => {
    void dispatch(loadUsimOrdersOptions()); //only one time
    return () => {
      dispatch(clearUsimOrderList());
    };
  }, [dispatch]);

  useEffect(() => {
    batch(() => {
      if (!isEmpty(filters)) return;
      if (!isEmpty(queryStringFilters)) dispatch(setUsimOrderFilters(queryStringFilters)); //set filters only if not in the state
      void dispatch(getUsimOrders()); //it's the first load with or without query filters
    });
  }, [dispatch, filters, queryStringFilters]);

  useEffect(() => {
    if (pageLimit && pageLimit !== initialPageLimit.current) {
      initialPageLimit.current = pageLimit!;
      void dispatch(getUsimOrders());
    }
  }, [dispatch, pageLimit]);

  const handlePageChange = useCallback(
    (pageChanged: number) => {
      batch(() => {
        dispatch(setUsimOrderPageNumber(pageChanged));
        void dispatch(getUsimOrders());
      });
    },
    [dispatch]
  );

  const handleOrderChange = useCallback(
    (orderQuery: string) => {
      batch(() => {
        if (orderQuery && orderQuery !== initialSort.current) {
          dispatch(setUsimOrderSort(orderQuery));
          initialSort.current = orderQuery!;
          void dispatch(getUsimOrders());
        }
      });
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    ({ id, description }) => {
      confirmationDialogOpen({
        title: formatMessage({ id: 'usims.orders.deleteOrder.confirm.title' }),
        description: formatMessage({ id: 'usims.orders.deleteOrder.confirm' }),
        alertMessage: formatMessage({ id: 'usims.orders.itemsAffected' }, { element: description }),
        continueButtonText: formatMessage({ id: 'usims.orders.deleteOrder.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => {
          const resultAction = await dispatch(deleteUsimOrder({ id }));
          if (deleteUsimOrder.fulfilled.match(resultAction)) void dispatch(getUsimOrders()); //TODOPS better without reload all (rtk query?)
        },
        severity: 'danger',
        dataTestid: 'confirm-delete-usimOrder',
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage]
  );

  const handleOpenExtraData = useCallback(
    (extraData) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.orders.extra.title' }),
        content: () => <JsonViewerDialog data={extraData} onClose={dialogClose} />,
      });
    },
    [dialogClose, dialogOpen, formatMessage]
  );

  const handleDownload = useCallback(
    (id) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.orders.form.order.download' }),
        content: () => <DownloadOrderDialog id={id} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const handleRefresh = useCallback(() => {
    void dispatch(getUsimOrders());
  }, [dispatch]);

  const columns = useMemo(() => {
    const getTools = (rowData: UsimOrder) => {
      const options = checkPermissionsList(userdata?.permissions || [], [
        {
          icon: ico_download,
          label: formatMessage({ id: 'usims.orders.downloadOrder' }),
          action: () => handleDownload(rowData.id),
          disabled: rowData.status !== UsimOrderStatus.READY && rowData.status !== UsimOrderStatus.COMPLETED,
          permissions: [U_PERMISSIONS.DOWNLOAD_USIM_ORDER],
          name: 'download-usim-order',
        },
        {
          icon: code,
          label: formatMessage({ id: 'usims.orders.extraData' }),
          action: () => handleOpenExtraData(rowData.vendor_info),
          disabled: !rowData.vendor_info,
          name: 'extra-data-usim-order',
        },
        {
          icon: ico_delete,
          label: formatMessage({ id: 'usims.orders.deleteOrder' }),
          action: () => handleDelete(rowData),
          disabled: true,
          permissions: [U_PERMISSIONS.DELETE_USIM_ORDER],
          name: 'delete-usim-order',
        },
      ]);

      return <DropdownTools options={options} />;
    };

    return [
      {
        key: 'id',
        title: formatMessage({ id: 'usims.orders.table.id' }),
        dataKey: 'id',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        secret: true,
        hidden: true,
      },
      {
        key: 'order_id',
        title: formatMessage({ id: 'usims.orders.table.id' }),
        dataKey: 'order_id',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        key: 'description',
        title: formatMessage({ id: 'usims.orders.table.description' }),
        dataKey: 'description',
        width: 150,
        maxWidth: 200,
        minWidth: 100,
      },
      {
        key: 'status',
        title: formatMessage({ id: 'usims.orders.table.status' }),
        dataKey: 'status',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        cellRenderer: ({ cellData: status }: { cellData: string }) => {
          let statusType;
          switch (status) {
            case 'ready':
            case 'completed':
              statusType = STATUS.SUCCESS;
              break;
            case 'updating':
            case 'processing':
              statusType = STATUS.INFO;
              break;
            case 'pending':
              statusType = STATUS.WARNING;
              break;
            case 'error':
              statusType = STATUS.ERROR;
              break;
            default:
              statusType = STATUS.DEFAULT;
          }
          return <Status status={statusType} label={humanizeSnakeCase(status)} asChip />;
        },
      },
      {
        key: 'progress',
        title: formatMessage({ id: 'usims.orders.table.progress' }),
        dataKey: 'progress',
        sortable: true,
        width: 90,
        maxWidth: 100,
        minWidth: 60,
        cellRenderer: ({ cellData }: { cellData: string }) => cellData + '%',
      },
      {
        key: 'first_available_imsi',
        title: formatMessage({ id: 'usims.orders.table.imsi' }),
        dataKey: 'first_available_imsi',
        sortable: true,
        width: 150,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
      },
      {
        key: 'quantity',
        title: formatMessage({ id: 'usims.orders.table.quantity' }),
        dataKey: 'quantity',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 50,
      },
      {
        key: 'batch_size',
        title: formatMessage({ id: 'usims.orders.table.batch_size' }),
        dataKey: 'batch_size',
        sortable: true,
        width: 90,
        maxWidth: 100,
        minWidth: 60,
      },
      {
        key: 'batches',
        title: formatMessage({ id: 'usims.orders.table.batches' }),
        dataKey: 'batches',
        sortable: true,
        width: 90,
        maxWidth: 100,
        minWidth: 60,
        visible: true,
        cellRenderer: ({ cellData }: { cellData: string }) => cellData.length,
      },
      {
        key: 'msisdn_pool.name',
        title: formatMessage({ id: 'usims.orders.table.msisdnPoolName' }),
        dataKey: 'msisdn_pool.name',
        sortable: false,
        width: 150,
        maxWidth: 200,
        minWidth: 90,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        hidden: true,
        secret: true,
      },
      {
        key: 'imsi_pool.name',
        title: formatMessage({ id: 'usims.orders.table.imsiPoolName' }),
        dataKey: 'imsi_pool.name',
        sortable: false,
        width: 150,
        maxWidth: 200,
        minWidth: 90,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        hidden: true,
        secret: true,
      },
      {
        key: 'tenant.name',
        title: formatMessage({ id: 'usims.orders.table.tenantName' }),
        dataKey: 'tenant.name',
        sortable: false,
        width: 150,
        maxWidth: 200,
        minWidth: 90,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
        visible: false,
      },
      {
        key: 'created_at',
        title: formatMessage({ id: 'usims.orders.table.created' }),
        dataKey: 'created_at',
        sortable: true,
        width: 140,
        maxWidth: 140,
        minWidth: 50,
        visible: false,
        defaultSort: 'desc', // set the columns sorted as default
        cellRenderer: ({ cellData }: { cellData: string }) => {
          return moment(cellData).format(localizedConfig.fullDateFormat);
        },
      },
      {
        key: 'updated_at',
        title: formatMessage({ id: 'usims.orders.table.updated' }),
        dataKey: 'updated_at',
        sortable: true,
        width: 140,
        maxWidth: 140,
        minWidth: 50,
        visible: false,
        cellRenderer: ({ rowData }: { rowData: UsimOrder }) => {
          return rowData.created_at !== rowData.updated_at
            ? moment(rowData.updated_at).format(localizedConfig.fullDateFormat)
            : '-';
        },
      },
      {
        key: 'tools',
        title: '',
        dataKey: 'tools',
        width: 60,
        maxWidth: 60,
        minWidth: 60,
        secret: true, // secret used to hide from columns management panel
        cellRenderer: ({ rowData }: { rowData: UsimOrder }) => {
          return getTools(rowData);
        },
      },
    ];
  }, [
    formatMessage,
    handleDelete,
    handleDownload,
    handleOpenExtraData,
    localizedConfig.fullDateFormat,
    userdata?.permissions,
  ]);

  const toolBar = useMemo(
    () => ({
      actions: false,
    }),
    []
  );

  return (
    <List
      key="orders-list"
      columns={columns}
      totalRows={data?.total}
      data={data.data}
      page={data.page}
      filters={filters}
      onOrderChange={handleOrderChange}
      loadingState={loadingState}
      createComponent={checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_TENANT) ? <CreateOrderButton /> : undefined}
      extraComponents={<UploadOrderButton />}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      toolbar={toolBar}
      rowsPerPage={pageLimit}
      onRefresh={handleRefresh}
    />
  );
};

export default UsimOrders;
