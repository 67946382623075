import { getDataUser } from 'store/actions/adminconfig/login';
import { User } from 'store/models/user';

type FetchHeaders = {
  accept: string;
  'content-type': string;
  'horus-token'?: string;
  'horus-admin-token'?: string;
};

export const getFetchHeaders = (user: Partial<User> | null, adminConfig: boolean): FetchHeaders => {
  const headers: FetchHeaders = {
    accept: 'application/json',
    'content-type': 'application/json;charset=UTF-8',
  };
  if (user) {
    headers['horus-token'] = user.accessToken;
  }
  if (adminConfig) {
    const adminUser = getDataUser();
    if (adminUser) {
      headers['horus-admin-token'] = adminUser;
    }
  }
  return headers;
};

export const getFetchFilesHeaders = (user: User, boundary: string) => {
  const headers = {
    'content-type': `multipart/form-data;`,
    'horus-token': user.accessToken,
  };
  return headers;
};

export default getFetchHeaders;
