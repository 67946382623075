// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Style
import { FormStyled } from '../styled';

const ChangeSite = ({ dataOptions, isOpen, onClose, onChangeSite, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [dataOptions]);

  const schema = object().shape({
    site_id: string().required(),
  });

  const initials = {
    site_id: '',
  };

  const fieldset = [
    {
      title: formatMessage({ id: 'usimProfiles.actions.changeSite.site_id.title' }),
      data: [
        {
          name: 'site_id',
          label: formatMessage({ id: 'usimProfiles.actions.changeSite.site_id.label' }),
          placeholder: formatMessage({ id: 'usimProfiles.actions.changeSite.site_id.placeholder' }),
          error: formatMessage({ id: 'usimProfiles.actions.changeSite.site_id.error' }),
          options: dataOptions.sites,
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onChangeSite}
        modalTitle={formatMessage({ id: 'usimProfiles.actions.changeSite.title' })}
      />
    </FormStyled>
  );
};

ChangeSite.propTypes = {
  getOptions: PropTypes.func,
  options: PropTypes.any,
};

export default ChangeSite;
