import * as Sentry from '@sentry/react';
import config from 'config';
import { PageSentryError } from 'containers/Errors/PageSentryError';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { EnvironmentConfiguration } from 'store/models/environmentConfiguration';

export function initSentry(envConfig: EnvironmentConfiguration) {
  if (!envConfig.sentry_enabled || !Boolean(envConfig.sentry_dsn) || process.env.NODE_ENV !== 'production') {
    return;
  }
  Sentry.init({
    dsn: envConfig.sentry_dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: config.sentry.tracesSampleRate,
    replaysSessionSampleRate: config.sentry.tracesSampleRate,
    replaysOnErrorSampleRate: config.sentry.replaysOnErrorSampleRate,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_VERSION,
  });
}

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export function sentryLogError(e: any) {
  console.error(e);
  Sentry.captureException(e);
}

export function sentrySetUser(user: Record<string, string | undefined>) {
  Sentry.setUser(user);
}

export function ErrorBoundary({ children }: Sentry.ErrorBoundaryProps) {
  return <Sentry.ErrorBoundary fallback={PageSentryError}>{children}</Sentry.ErrorBoundary>;
}
