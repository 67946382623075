import { Chart, ChartsRef } from '@athonet/ui/components/Data/Chart';
import ChartToolbox from '@athonet/ui/components/Data/Chart/Toolbox';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { useCallback, useRef, useState } from 'react';

export type TrendChartProps = {
  isLoading?: boolean;
  name?: string;
  title?: string;
  seriesData?: [string, string | number][] | undefined;
  seriesDataArray?: { name: string; data: [string, string | number][] | undefined; color: string }[];
  headerActionsComponent?: JSX.Element | (JSX.Element | null)[];
  color?: string;
  yAxisFormat?: (value: number) => string;
  xAxisFormat?: (value: string) => string;
  tooltipFormat?: (value: unknown) => string;
  legendFormat?: string;
  height?: number;
  noLegend?: true;
};

export default function TrendChart({
  isLoading,
  title,
  name,
  headerActionsComponent,
  seriesData,
  color,
  xAxisFormat,
  yAxisFormat,
  tooltipFormat,
  legendFormat,
  height = 180,
  noLegend,
  seriesDataArray,
}: TrendChartProps) {
  const chartRef = useRef<ChartsRef | null>(null);
  const [format, setFormat] = useState<'bar' | 'line'>('line');

  const handleFormatChange = useCallback((value) => {
    setFormat(value);
  }, []);

  return (
    <Panel
      title={title}
      headerActionsComponent={
        <ChartToolbox
          chartInstanceRef={chartRef}
          onTypeChange={handleFormatChange}
          format={format}
          lineBarToggle
          zoom
          actions={headerActionsComponent}
        />
      }
    >
      <PanelContent>
        {!isLoading ? (
          <Chart
            instanceRef={chartRef}
            height={height}
            zoom="1d"
            options={{
              series: seriesData
                ? {
                    universalTransition: true,
                    animationDurationUpdate: 500,
                    type: format,
                    name,
                    data: seriesData,
                    color,
                  }
                : seriesDataArray?.map((series) => ({
                    ...series,
                    universalTransition: true,
                    animationDurationUpdate: 500,
                    type: format,
                  })),
              xAxis: {
                type: 'category',
                axisLabel: {
                  ...(xAxisFormat && {
                    formatter: (value: string | number, zoomValue: string | number) => {
                      if (typeof value === 'string') {
                        return xAxisFormat(value);
                      }
                      return xAxisFormat(zoomValue.toString());
                    },
                  }),
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: yAxisFormat,
                },
              },
              tooltip: {
                formatter: tooltipFormat,
              },
              legend: {
                show: !noLegend,
                selectedMode: false,
                formatter: legendFormat,
              },
            }}
          />
        ) : (
          <Skeleton height={height} variant="rectangular" />
        )}
      </PanelContent>
    </Panel>
  );
}
