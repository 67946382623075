import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ChipsArray } from '@athonet/ui/components/Data/ChipsArray';
import { Entity } from 'store/reducers';
import {
  CdrAvailabilityPeriodRange,
  CdrTimeSeriesData,
  CdrTimeSeriesDataItem,
  CdrTimeSeriesQueryData,
  CdrTimeSeriesSchema,
} from 'store/models/cdr';
import { Thunk } from 'store/actions';
import { PAGE_LIMIT, PAGE_LIMIT_OPTIONS } from 'store/models/environmentConfiguration';
import { sentryLogError } from 'sentry';

export type AnalysisTableProps = {
  timeseriesSchema?: Entity<CdrTimeSeriesSchema>;
  timeseriesAvailabilityDates?: Entity<CdrAvailabilityPeriodRange<Moment>>;
  query: CdrTimeSeriesQueryData | null;
  getTimeseriesAvailabilityDates: () => Thunk<Promise<void>>;
  getTimeseriesData: (
    payload: CdrTimeSeriesQueryData,
    page: string,
    page_size: number
  ) => Thunk<Promise<CdrTimeSeriesData | null>>;
  getTimeseriesSchema: () => Thunk<Promise<void>>;
};

export function AnalysisTable({
  timeseriesSchema,
  timeseriesAvailabilityDates,
  query,
  getTimeseriesAvailabilityDates,
  getTimeseriesData,
  getTimeseriesSchema,
}: AnalysisTableProps) {
  const dispatch = useDispatch();
  const [queryRes, setQueryRes] = useState<CdrTimeSeriesData | null>(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState('');
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_LIMIT);
  const [rowsCount, setRowsCount] = useState(PAGE_LIMIT * 3);

  useEffect(() => {
    dispatch(getTimeseriesSchema());
    dispatch(getTimeseriesAvailabilityDates());
  }, [dispatch, getTimeseriesAvailabilityDates, getTimeseriesSchema]);

  useEffect(() => {
    async function loadQuery() {
      if (!query) {
        return;
      }
      try {
        setLoading(true);
        const res: CdrTimeSeriesData = await dispatch(getTimeseriesData(query, page, pageSize));

        // single page
        if (res.prev_page === null && res.next_page === null) {
          setRowsCount(pageSize);
          setTablePage(0);
        }

        // first page
        if (res.prev_page === null && res.next_page) {
          setTablePage(0);
        }

        // middle page
        if (res.next_page && res.prev_page) {
          setTablePage(1);
        }

        // last page
        if (res.prev_page && res.next_page === null) {
          setTablePage(2);
        }

        setQueryRes(res);
      } catch (e) {
        sentryLogError(e);
        setQueryRes(null);
      }
      setLoading(false);
    }
    void loadQuery();
  }, [dispatch, getTimeseriesData, page, pageSize, query]);

  useEffect(() => {
    setQueryRes(null);
    setPage('');
    setTablePage(0);
    setRowsCount(pageSize * 3);
  }, [pageSize, query]);

  const columns: TableColumn<CdrTimeSeriesDataItem>[] = useMemo(() => {
    if (!timeseriesSchema?.data) {
      return [];
    }

    return timeseriesSchema.data.schema.map(({ field, label, type }) => {
      const col: TableColumn<CdrTimeSeriesDataItem> = {
        key: field,
        label,
        numeric: type === 'numeric',
        wordBreak: 'keep-all',
        cellRender:
          type === 'datetime'
            ? (row) => {
                return <Text type="body2">{moment(row[field] as string).format('MMM DD, YYYY HH:mm:ss')}</Text>;
              }
            : undefined,
      };
      return col;
    });
  }, [timeseriesSchema]);

  const handlePageChange = useCallback(
    (p: number) => {
      if (!queryRes) {
        return;
      }
      switch (p) {
        case 0:
          if (queryRes.prev_page) {
            setPage(queryRes.prev_page);
          }
          break;
        case 1:
          if (p < tablePage && queryRes.prev_page) {
            setPage(queryRes.prev_page);
          }
          if (p >= tablePage && queryRes.next_page) {
            setPage(queryRes.next_page);
          }
          break;
        case 2:
          if (queryRes.next_page) {
            setPage(queryRes.next_page);
          }
          break;
      }
    },
    [queryRes, tablePage]
  );

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
    setRowsCount(newPageSize * 3);
  }, []);

  const chips = useMemo(() => {
    if (!query) {
      return [];
    }

    const chipsArray: { id: string; label: string }[] = [
      { id: 'Source Network', label: `Source Network: ${query.source_network}` },
      {
        id: 'Start Datetime',
        label: `Start Datetime: ${moment(query.start_datetime).format('MMM DD, YYYY HH:mm:ss')}`,
      },
      { id: 'End Datetime', label: `End Datetime: ${moment(query.end_datetime).format('MMM DD, YYYY HH:mm:ss')}` },
    ];

    if (timeseriesSchema?.data?.schema) {
      const chipFields = timeseriesSchema.data.schema.filter(({ field }) => query[field] || query[field] === false);
      chipFields.forEach(({ field, label }) => chipsArray.push({ id: label, label: `${label}: ${query[field]}` }));
    }

    return chipsArray;
  }, [query, timeseriesSchema]);

  if (!timeseriesSchema?.data || !timeseriesAvailabilityDates?.data) {
    return null;
  }

  return (
    <Stack fullHeight nowrap>
      <ChipsArray size="small" color="secondary" items={chips} />

      <Table
        loading={loading}
        data={queryRes?.data || []}
        columns={columns}
        orderBy={'sequence_number'}
        rowsCount={rowsCount}
        rowsPerPage={pageSize}
        page={tablePage}
        rowKey={'sequence_number'}
        autoScale
        onPageChange={handlePageChange}
        rowsPerPageOptions={PAGE_LIMIT_OPTIONS}
        onRowsPerPageChange={handlePageSizeChange}
        labelDisplayedRows={() => {
          if (queryRes?.data) {
            return `Rows: ${queryRes.data[0].seq_number} ... ${queryRes.data[queryRes.data.length - 1].seq_number}`;
          }
          return '';
        }}
      />
    </Stack>
  );
}
