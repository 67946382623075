import { BillingConfig } from '../../models/billing';
import { fetchData } from '../../actions/fetchData';
import { AxiosRequestConfig } from 'axios';
import { Thunk } from '..';
import { BILLING_TYPE } from '.';
import config from 'config';
import { showErrorToast } from '../toast';

export function getBillingConfigurations(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.cdrBillingApis.billingConfiguration}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };
    try {
      dispatch({ type: BILLING_TYPE.BILLING_CONFIGURATION_LIST_LOADING });
      const billingConfigurations = await dispatch(fetchData<BillingConfig[]>(options));
      dispatch({ type: BILLING_TYPE.BILLING_CONFIGURATION_LIST_SUCCESS, payload: billingConfigurations });
    } catch (e: any) {
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
      dispatch({ type: BILLING_TYPE.BILLING_CONFIGURATION_LIST_FAILURE });
    }
  };
}

export function saveBillingConfigurations(
  payload: Pick<
    BillingConfig,
    | 'operator'
    | 'cost_per_gb'
    | 'source_network'
    | 'alarm_threshold'
    | 'prepaid_bundle'
    | 'sim_minimum_pay'
    | 'start_date'
  >
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.cdrBillingApis.billingConfiguration}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      data: { ...payload },
    };
    try {
      await dispatch(fetchData<BillingConfig>(options));
      void dispatch(getBillingConfigurations());
    } catch (e: any) {
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
    }
  };
}

export function editBillingConfigurations(payload: BillingConfig): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.cdrBillingApis.updateBillingConfiguration.replace('{id}', encodeURIComponent(payload.id))}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'PUT',
      data: { ...payload },
    };
    try {
      await dispatch(fetchData<BillingConfig>(options));
      void dispatch(getBillingConfigurations());
    } catch (e: any) {
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
    }
  };
}

export function deleteBillingConfigurations(id: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.cdrBillingApis.deleteBillingConfiguration.replace('{id}', encodeURIComponent(id))}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'DELETE',
    };
    try {
      dispatch({ type: BILLING_TYPE.BILLING_CONFIGURATION_DELETE_LOADING });
      const deleteBillingConfiguration = await dispatch(fetchData<String>(options));
      void dispatch(getBillingConfigurations());
      dispatch({ type: BILLING_TYPE.BILLING_CONFIGURATION_DELETE_SUCCESS, payload: deleteBillingConfiguration });
    } catch (e: any) {
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
      dispatch({ type: BILLING_TYPE.BILLING_CONFIGURATION_DELETE_FAILURE });
    }
  };
}
