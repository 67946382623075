import { Formik, Form, Field, FieldProps } from 'formik';
import { object, string, ref } from 'yup';
import { useIntl } from 'react-intl';
import PasswordStrength from 'components/Form/PasswordStrength';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Button } from '@athonet/ui/components/Input/Button';
import { Link } from '@athonet/ui/components/Navigation/Link';

type ChangePasswordFormProps = {
  onSubmit: () => void;
  onBack: () => void;
};

export default function ChangePasswordForm({ onSubmit, onBack }: ChangePasswordFormProps) {
  const { formatMessage } = useIntl();

  const schema = object().shape({
    password: string()
      .min(8)
      .required(formatMessage({ id: 'login.passwordError' })),
    passwordConfirm: string()
      .oneOf([ref('password'), undefined], formatMessage({ id: 'login.change.matchPasswordError' }))
      .required(formatMessage({ id: 'login.passwordError' })),
  });

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.change.title' })}
      description={formatMessage({ id: 'login.change.subtitle' })}
      data-testid="changePasswordForm"
    >
      <Formik
        initialValues={{
          password: '',
          passwordConfirm: '',
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <>
            <Form noValidate autoComplete="off">
              <Stack fullWidth>
                <Field name="password">
                  {({ field }: FieldProps<string>) => (
                    <Box sx={{ width: '100%', height: '88px' }}>
                      <TextField
                        fullWidth
                        {...field}
                        type="password"
                        disabled={isSubmitting}
                        showPasswordVisibility
                        label={formatMessage({ id: 'login.newPasswordLabel' })}
                        placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                        error={Boolean(touched['password'] && errors['password'])}
                        helperText={
                          touched['password'] && errors['password']
                            ? errors['password']
                            : formatMessage({ id: 'login.change.rules' })
                        }
                      />
                    </Box>
                  )}
                </Field>

                <Field name="passwordConfirm">
                  {({ field }: FieldProps<string>) => (
                    <Box sx={{ width: '100%', height: '80px' }}>
                      <TextField
                        fullWidth
                        {...field}
                        disabled={isSubmitting}
                        type="password"
                        showPasswordVisibility
                        label={formatMessage({ id: 'login.change.passwordLabel' })}
                        placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                        error={Boolean(touched['passwordConfirm'] && errors['passwordConfirm'])}
                        {...(touched['passwordConfirm'] &&
                          errors['passwordConfirm'] && { helperText: errors['passwordConfirm'] })}
                      />
                    </Box>
                  )}
                </Field>

                <PasswordStrength password={values['password']} />

                <Button
                  size="large"
                  data-testid="changePasswordForm-submitButton"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text={formatMessage({ id: 'login.change.submitButton' })}
                />

                <Link data-testid="changePasswordForm-backLink" onClick={onBack}>
                  <Text align="center">{formatMessage({ id: 'login.change.backButton' })}</Text>
                </Link>
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </AuthPanel>
  );
}
