import {
  setCdrImeiChangesDateRange,
  setCdrImeiChangesFilter,
  setCdrImeiChangesMetric,
  setCdrImeiChangesPeriod,
  getCdrImeiChangesSearch,
  setImeiChangesTimeSeriesQuery,
  getImeiChangesTimeseriesExportData,
} from 'store/actions/cdrReports/imeiChanges';
import CdrToolbar from '../CdrToolbar';
import {
  useCdrImeiChangesAvailabilityDates,
  useCdrImeiChangesFilter,
  useCdrImeiChangesGroupByOptions,
  useCdrImeiChangesMetricOptions,
  useCdrImeiChangesPeriod,
  useCdrImeiChangesRankingMetric,
  useCdrImeiChangesSchema,
  useCdrImeiChangesTimeseriesAvailabilityDates,
  useCdrImeiChangesTimeseriesQuery,
  useCdrImeiChangesTimeseriesSchema,
  useImeiChangesDateRange,
} from 'store/selectors/cdrReports/imeiChanges';

export function ImeiChangesToolbar() {
  const imeiChangesSchema = useCdrImeiChangesSchema();
  const imeiChangesDateRange = useImeiChangesDateRange();
  const imeiChangesPeriod = useCdrImeiChangesPeriod();
  const imeiChangesFilter = useCdrImeiChangesFilter();
  const imeiGroupByOptions = useCdrImeiChangesGroupByOptions();
  const imeiChangesMetricOptions = useCdrImeiChangesMetricOptions();
  const imeiChangesRankingMetric = useCdrImeiChangesRankingMetric();
  const imeiChangesAvailabilityDates = useCdrImeiChangesAvailabilityDates();
  const imeiChangesTimeseriesSchema = useCdrImeiChangesTimeseriesSchema();
  const imeiChangesTimeseriesAvailabilityDates = useCdrImeiChangesTimeseriesAvailabilityDates();
  const imeiChangesTimeseriesQuery = useCdrImeiChangesTimeseriesQuery();

  return (
    <CdrToolbar
      schema={imeiChangesSchema}
      availabilityDates={imeiChangesAvailabilityDates}
      dateRange={imeiChangesDateRange}
      period={imeiChangesPeriod}
      filter={imeiChangesFilter}
      groupByOptions={imeiGroupByOptions}
      metricOptions={imeiChangesMetricOptions}
      metric={imeiChangesRankingMetric}
      path={'imei-changes'}
      setPeriod={setCdrImeiChangesPeriod}
      setFilter={setCdrImeiChangesFilter}
      setDateRange={setCdrImeiChangesDateRange}
      setMetric={setCdrImeiChangesMetric}
      getSearch={getCdrImeiChangesSearch}
      metricLabel="Changes" // TODO: intl
      displaySourceNetwork
      displayAnalysis
      timeseriesSchema={imeiChangesTimeseriesSchema}
      timeseriesAvailabilityDates={imeiChangesTimeseriesAvailabilityDates}
      timeseriesQuery={imeiChangesTimeseriesQuery}
      setQuery={setImeiChangesTimeSeriesQuery}
      setExportQuery={getImeiChangesTimeseriesExportData}
    />
  );
}
