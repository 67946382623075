import { useSelector } from 'react-redux';

export function useServiceProfilesSelector() {
  const serviceProfiles = useSelector((state) => state.serviceProfiles);
  return serviceProfiles;
}

export function useServiceProfilesListSelector() {
  const list = useSelector((state) => state.serviceProfiles.list);
  return list;
}
