// React
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { LinkStyled } from './styled';

const Link = ({ children, ...props }) => {
  return <LinkStyled {...props}>{children}</LinkStyled>;
};

Link.propTypes = {
  children: PropTypes.element,
};

export default Link;
