import DeleteQuestion from 'components/Edit/DeleteQuestion';
import { useIntl } from 'react-intl';
import AvatarEditor from 'react-avatar-editor';
import useHandlers from '../hook';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { UploadLogoProps } from '..';
import { Slider } from '@athonet/ui/components/Input/Slider';
import { PALETTE } from '@athonet/ui/theme';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';

type UploadLogoBoxProps = {
  image: File | string | null;
  setImage: (file: File | null) => void;
  editor: RefObject<AvatarEditor>;
} & UploadLogoProps;

export default function UploadLogoBox({ image, setImage, editor, id, resource }: UploadLogoBoxProps) {
  const { formatMessage } = useIntl();

  const [deleteSure, setDeleteSure] = useState<number | undefined>();
  const [zoom, setZoom] = useState(1);

  const handleZoomSlider = useCallback((event, value) => {
    setZoom(value);
  }, []);

  const { tenant, handleDeleteLogo } = useHandlers({ tenantId: id, resource });

  useEffect(() => {
    setDeleteSure(undefined); // reset delete question
  }, []);

  const handleDelete = useCallback(async () => {
    await handleDeleteLogo();
    setDeleteSure(undefined);
    setImage(null);
  }, [handleDeleteLogo, setImage]);

  return (
    <>
      <AvatarEditor
        ref={editor}
        image={image || tenant?.logo || ''}
        border={0}
        scale={zoom}
        width={322}
        height={62}
        style={{
          border: `16px solid ${PALETTE.background.default}`,
          borderRightWidth: `63px`,
          background: PALETTE.background.default,
          boxSizing: 'content-box',
        }}
      />
      <>
        {tenant?.logo && ( // Always returning parent logo as own logo at the moment, controllers always present */
          <>
            <Stack direction="row" justify="space-between" fullWidth>
              <Box sx={{ width: 160 }}>
                <Slider
                  min={0.5}
                  max={1.5}
                  step={0.1}
                  value={zoom}
                  onChange={handleZoomSlider}
                  label={formatMessage({ id: 'tenants.form.logoUpload.zoomLevel' })}
                />
              </Box>
              <div>
                {deleteSure !== 0 && <span>Remove Logo</span>}

                <DeleteQuestion
                  id={''}
                  onDelete={() => {
                    void handleDelete();
                  }}
                  setDeleteSure={setDeleteSure}
                  isDeleteSure={deleteSure === 0}
                  index={0}
                  isUpdating={false}
                />
              </div>
            </Stack>
          </>
        )}
      </>
    </>
  );
}
