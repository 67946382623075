import BillingConfigurationToolbar from './BillingConfigurationToolbar';
import BillingConfigurationTable from './BillingConfigurationTable';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBillingConfigurations } from 'store/actions/billing/billingConfiguration';

export default function BillingConfiguration() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillingConfigurations());
  }, [dispatch]);

  return (
    <>
      <BillingConfigurationToolbar />
      <BillingConfigurationTable />
    </>
  );
}
