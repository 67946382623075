import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';

const NodeResourceStatus = ({ status, error, updating }) => {
  let statusType;
  switch (status) {
    case 'provisioned':
      statusType = STATUS.SUCCESS;
      break;
    case 'deprovisioning':
    case 'provisioning':
      statusType = STATUS.INFO;
      break;
    case 'error':
      statusType = STATUS.ERROR;
      break;
    default:
      statusType = STATUS.DEFAULT;
  }
  if (error > 0) {
    statusType = STATUS.ERROR;
  }
  if (updating) {
    statusType = STATUS.INFO;
  }
  return <Status status={statusType} label={humanizeSnakeCase(status)} />;
};

export default NodeResourceStatus;
