import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { CreateUsimCard5gContent } from './CreateUsimCard5gContent';
import { BulkCreateUsimCard5gContent } from './BulkCreateUsimCard5gContent';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';

const CreateDialogContent = () => {
  const { formatMessage } = useIntl();
  const [single, setSingle] = useState(false);
  const { dialogClose } = useOverlay();

  return (
    <>
      <IconButton
        name="Close"
        fontSize="small"
        onClick={dialogClose}
        sx={{ position: 'absolute', right: '16px', top: '8px', zIndex: 100 }}
      />
      <DialogContent>
        <ToggleButtonGroup
          data-testid={'field-single'}
          size="small"
          color={'secondary'}
          exclusive={true}
          value={single}
          onChange={(newValue) => {
            if (newValue !== null) setSingle(newValue);
          }}
          sx={{ mb: 2 }}
        >
          <ToggleButton value={true}>{formatMessage({ id: 'usims.form.usim.typology.single' })}</ToggleButton>
          <ToggleButton value={false}>{formatMessage({ id: 'usims.form.usim.typology.multiple' })}</ToggleButton>
        </ToggleButtonGroup>
        {single ? <CreateUsimCard5gContent /> : <BulkCreateUsimCard5gContent />}
      </DialogContent>
    </>
  );
};

export function CreateUsimCard5g() {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const handleOpenCreate = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.newUsim5g' }),
      content: () => <CreateDialogContent />,
    });
  }, [dialogOpen, formatMessage]);

  return (
    <Button
      text={formatMessage({ id: 'common.button.new' })}
      data-testid="toolbar-new-button"
      startIcon="Add"
      variant="outlined"
      onClick={handleOpenCreate}
    />
  );
}
