import { getIn } from 'formik';
import { useMemo } from 'react';
import { ObjectSchema } from 'yup';

/**
 * The `useIsRequiredField` function checks if a field is required based on a validation schema.
 * @param validationSchema - The `validationSchema` parameter is an object that represents a validation
 * schema. It is typically used with libraries like Yup to define validation rules for form fields.
 * @param {string} name - The `name` parameter is a string that represents the name of the field in the
 * validation schema that you want to check if it is required or not.
 * @returns The function `useIsRequiredField` returns a boolean value indicating whether the specified
 * field is required according to the provided validation schema.
 */
const useIsRequiredField = (validationSchema: ObjectSchema<any>, name: string) => {
  const isRequired = useMemo(() => {
    const schemaDescription = validationSchema.describe();
    const accessor = name.split('.').join('.fields.');
    const field = getIn(schemaDescription.fields, accessor);
    if (!field) {
      return false;
    }
    return field.tests.some((test: { name: string }) => test.name === 'required');
  }, [name, validationSchema]);
  return isRequired;
};

export default useIsRequiredField;
