import React from 'react';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useIntl } from 'react-intl';
import { CreateUsimOrderOptions } from 'store/reducers/usimOrders';
import { selectUsimOrderOptions } from 'store/selectors/usimOrders';
import { useSelector } from 'react-redux';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import SelectField from 'components/Form/Field/SelectField';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { useFormikContext } from 'formik';
import { CreateOrderType } from './types';

const CreateOrderForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const options: CreateUsimOrderOptions = useSelector((state) => selectUsimOrderOptions(state));
  const bootstrap = useBootstrapSelector();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<CreateOrderType>();
  const usimsFormPrefix = 'usims.form.usim';

  return (
    <>
      <BaseFieldset label={formatMessage({ id: 'common.detail.generalInfo' })}>
        <Stack direction="row" spacing={3}>
          <TextFieldField name="description" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="customer" fast sx={{ flexGrow: 1 }} />
        </Stack>
      </BaseFieldset>

      <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.data' })}>
        <AutocompleteField name="imsi_pool_id" fast options={options.imsiPools} />
        <Stack direction="row" spacing={3}>
          <TextFieldField name="first_imsi" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="quantity" fast type="number" sx={{ flexGrow: 1 }} />
        </Stack>
        <AutocompleteField name="msisdn_pool_id" fast options={options.msisdnPools} sx={{ flexGrow: 1 }} />

        <SelectField name="msisdn_type" fast>
          <MenuItem value={'new'}>{formatMessage({ id: 'usims.orders.form.order.msisdn_type.new' })}</MenuItem>
          <MenuItem value={'used'}>{formatMessage({ id: 'usims.orders.form.order.msisdn_type.used' })}</MenuItem>
          <MenuItem value={'both'}>{formatMessage({ id: 'usims.orders.form.order.msisdn_type.both' })}</MenuItem>
        </SelectField>
      </BaseFieldset>

      <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.settings' })} direction="column">
        <SelectField name="preset_profile" fast>
          <MenuItem value={0}>{formatMessage({ id: 'usims.orders.form.order.profile.custom' })}</MenuItem>
        </SelectField>

        <Stack direction="row" spacing={3}>
          <TextFieldField name="profile" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="type" fast sx={{ flexGrow: 1 }} />
        </Stack>

        <Stack direction="row" spacing={3}>
          <TextFieldField name="graph_ref" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="transport_key" fast sx={{ flexGrow: 1 }} />
        </Stack>

        {bootstrap?.dual_sim_service && (
          <SelectField
            name="is_dual_imsi"
            fast
            onChange={(e) => {
              if (e.target.value === 'false') {
                setFieldValue('imsi2_pool_id', null);
                setFieldTouched('imsi2_pool_id', false);
              }
            }}
          >
            <MenuItem value={'true'}>{formatMessage({ id: 'usims.orders.form.order.is_dual_imsi.yes' })}</MenuItem>
            <MenuItem value={'false'}>{formatMessage({ id: 'usims.orders.form.order.is_dual_imsi.no' })}</MenuItem>
          </SelectField>
        )}

        {bootstrap?.dual_sim_service && (
          <AutocompleteField
            name="imsi2_pool_id"
            fast
            options={options?.imsi2Pools}
            disabled={values['is_dual_imsi'] === 'false'}
            sx={{ flexGrow: 1 }}
          />
        )}
      </BaseFieldset>

      <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.simSettings' })}>
        <Stack direction="row" spacing={3}>
          <TextFieldField name="sim_id_1" fast sx={{ flexGrow: 1 }} disabled />
          <TextFieldField name="sim_id_2" fast sx={{ flexGrow: 1 }} disabled />
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextFieldField name="sim_id_3" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="sim_id_4" fast sx={{ flexGrow: 1 }} />
        </Stack>
      </BaseFieldset>

      <BaseFieldset label={formatMessage({ id: 'usims.orders.form.order.addressData' })}>
        <Stack direction="row" spacing={3}>
          <TextFieldField name="address_1" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="address_2" fast sx={{ flexGrow: 1 }} />
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextFieldField name="address_3" fast sx={{ flexGrow: 1 }} />
          <TextFieldField name="address_4" fast sx={{ flexGrow: 1 }} />
        </Stack>
        <TextFieldField fast name="address_5" />
      </BaseFieldset>

      <BaseFieldset label={formatMessage({ id: 'usims.form.usim.kData' })}>
        <SelectField
          name="encrypt"
          fast
          prefix={usimsFormPrefix}
          onChange={(e) => {
            if (e.target.value === 0) {
              setFieldValue('use_key', '');
              setFieldTouched('use_key', false);
              setFieldValue('use_default_tk', 'false');
              setFieldTouched('use_default_tk', false);
            }
          }}
        >
          <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.encrypt.1' })}</MenuItem>
          <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.encrypt.0' })}</MenuItem>
        </SelectField>

        <SelectField
          name="use_default_tk"
          prefix={usimsFormPrefix}
          onChange={(e) => {
            if (e.target.value === 'true') {
              setFieldValue('use_key', '');
              setFieldTouched('use_key', false);
            }
          }}
        >
          <MenuItem value={'true'}>{formatMessage({ id: 'usims.form.usim.use_default_tk.true' })}</MenuItem>
          <MenuItem value={'false'}>{formatMessage({ id: 'usims.form.usim.use_default_tk.false' })}</MenuItem>
        </SelectField>

        <TextFieldField
          name="use_key"
          fast
          prefix={usimsFormPrefix}
          sx={{ display: values['encrypt'] === 1 && values['use_default_tk'] === 'false' ? '' : 'none' }}
        />
      </BaseFieldset>

      <BaseFieldset label={formatMessage({ id: 'usims.form.usim.op' })}>
        <SelectField
          name="key_type"
          fast
          prefix={usimsFormPrefix}
          onChange={(e) => {
            if (e.target.value === 0) {
              setFieldValue('op', '');
              setFieldTouched('op', false);
            }
          }}
        >
          <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.key_type.defaultHSSOP' })}</MenuItem>
          <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.key_type.customOP' })}</MenuItem>
        </SelectField>
        <TextFieldField
          fast
          name="op"
          prefix={usimsFormPrefix}
          sx={{ display: values['key_type'] === 1 ? '' : 'none' }}
        />
      </BaseFieldset>
    </>
  );
};

export default CreateOrderForm;
