// React
import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// External Components
import { Formik, Form, Field } from 'formik';

// Intl
import { useIntl } from 'react-intl';

// App Components
import IconButton from '@material-ui/core/IconButton';
import Input from 'components/Form/Input';
import Grid from 'components/Grid';
import Icon from 'components/Icon';
import ConfirmationPopup from 'components/Edit/ConfirmationPopup';

// Material UI
import { Dialog } from '@athonet/ui/components/Overlay/Dialog';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';

// Style
import { BaseStyled, FormBaseStyled, FormHelperErrorStyled } from '../styled';

import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { Button } from '@athonet/ui/components/Input/Button';

const Base = ({
  schema,
  isOpen,
  onClose,
  getData,
  initials,
  fieldset,
  onSubmit,
  modalTitle,
  modalDescription,
  elClassName,
  elType,
  elSize,
  elLabel,
  elColor,
  elIcon = 'Add',
  elDisabled,
  disableContinue,
  showContinue,
  labelContinue,
  disableCancel,
  showCancel,
  labelCancel,
  ComponentTop,
  ComponentBottom,
  StyleFormWrapper,
  keepOpen,
  isFormLoading,
  confirmation,
  confirmationField,
}) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(isOpen);
  const [initialData, setInitialData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(isFormLoading);
  const dispatch = useDispatch();

  const StyleWrapper = useMemo(() => {
    return StyleFormWrapper ? StyleFormWrapper : ({ children }) => <>{children}</>;
  }, [StyleFormWrapper]);

  const [openedConfirmationPopup, setOpenedConfirmationPopup] = useState(false);

  const closePopup = () => {
    setOpenedConfirmationPopup(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(isFormLoading);
  }, [isFormLoading]);

  useEffect(() => {
    if (isOpen === true) {
      setIsLoading(true);
      handleClickOpen();
    }
    if (isOpen === false) {
      setIsLoading(false);
      handleClose();
    }
  }, [isOpen]);

  const handleClickOpen = () => {
    if (typeof getData === 'function') {
      setInitialData(undefined);
      getData().then((result) => {
        setInitialData(result);
        setIsLoading(false);
      });
    } else {
      setInitialData(initials);
      setIsLoading(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
    onClose();
  };

  const onCancel = (resetForm) => {
    resetForm();
    handleClose();
  };

  const checkIfRequired = (name) => {
    let val = '';
    if (schema.fields[name]?._exclusive.required) val = '*';
    return val;
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (confirmation) {
      if (!openedConfirmationPopup) {
        setOpenedConfirmationPopup(true);
        return;
      } else {
        setOpenedConfirmationPopup(false);
      }
    }

    setIsLoading(true);

    onSubmit(values, (result, msg = undefined) => {
      // callback: result is true/false/null
      if (result) {
        dispatch(
          showSuccessToast(
            msg && {
              message: msg,
            }
          )
        );
        if (keepOpen) {
          resetForm();
        } else {
          onCancel(resetForm);
        }
      } else {
        if (result === null) {
          if (keepOpen) {
            resetForm();
          } else {
            onCancel(resetForm);
          }
        } else {
          dispatch(
            showErrorToast(
              msg && {
                message: msg,
              }
            )
          );
        }
      }
      setOpenedConfirmationPopup(false);
      setIsLoading(false);
      setSubmitting(false);
      return result;
    });
  };

  const onChange = useCallback((selected, setFieldValue, name) => {
    setFieldValue(name, selected);
  }, []);

  return (
    <BaseStyled>
      {/* NOTE: TRIGGER BUTTONS */}
      {/* TODO: they must be removed from here and handled by another component */}
      {elType === 'button' && (
        <Button
          variant="outlined"
          onClick={handleClickOpen} // TODO: open up filters popover
          text={elLabel || formatMessage({ id: 'common.button.new' })}
          startIcon={elIcon}
          data-testid="toolbar-new-button"
          disabled={elDisabled}
          sx={{ width: { xs: '100%', lg: 'auto' } }}
        />
      )}
      {elType === 'iconbutton' && (
        <IconButton
          className={elClassName}
          color={elColor}
          size={elSize}
          onClick={handleClickOpen}
          disabled={elDisabled}
        >
          <Icon icon={elIcon} size={16} />
        </IconButton>
      )}
      {/* isloading was the cause of formik-rerender, reintroduce a form loading mechanism while refactoring this component, reading this after 4 months and it doesn't make sense and so we need to drop BASE. */}
      {(keepOpen || !isLoading) && initialData !== undefined && open && (
        <Dialog
          data-testid="create-form"
          title={modalTitle}
          open={open}
          onClose={handleClose}
          fullWidth
          closeOnBackdropClick={false}
        >
          {ComponentTop}

          <Formik
            initialValues={initialData}
            enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form)
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={schema}
          >
            {({ touched, errors, submitForm, resetForm, setFieldValue, values }) => (
              <StyleWrapper>
                <FormBaseStyled>
                  <DialogContent>
                    <>
                      {modalDescription}
                      <Form noValidate autoComplete="off">
                        {fieldset.map((fields, index) => {
                          return (
                            fields.hidden !== true && (
                              <Grid key={`container_${index}`} className="edit-body" container spacing={2}>
                                <div className="form-fieldset-title">{fields.title}</div>
                                {fields.data.map((item, j) => {
                                  return (
                                    item.hidden !== true && (
                                      <Grid item key={`grid_${j}`} xs={12} sm={6} md={6} className={item.gridClassName}>
                                        {item.component ? (
                                          item.component
                                        ) : item.fieldComponent ? (
                                          item.componentProps?.size ? (
                                            <div className="field-component">
                                              <item.fieldComponent
                                                key={`field_${j}`}
                                                className={`${item.className}
                                                  ${
                                                    touched[item.name] && errors[item.name] && 'error-field-component'
                                                  }`}
                                                name={item.name}
                                                label={`${item.label} ${checkIfRequired(item.name)}`}
                                                placeholder={item.placeholder}
                                                setFieldValue={setFieldValue}
                                                value={values ? values[item.name] : undefined}
                                                onChange={(e, selected) => onChange(selected, setFieldValue, item.name)}
                                                onInputChange={(e, selected) =>
                                                  onChange(selected, setFieldValue, item.name)
                                                }
                                                {...item.componentProps}
                                              />
                                              {touched[item.name] && errors[item.name] && (
                                                <FormHelperErrorStyled error={true} margin="dense" variant="filled">
                                                  {item.error}
                                                </FormHelperErrorStyled>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="field-component">
                                              <item.fieldComponent
                                                key={`field_${j}`}
                                                className={`${item.className}
                                                  ${
                                                    touched[item.name] && errors[item.name] && 'error-field-component'
                                                  }`}
                                                name={item.name}
                                                label={`${item.label} ${checkIfRequired(item.name)}`}
                                                placeholder={item.placeholder}
                                                setFieldValue={setFieldValue}
                                                values={values ? values[item.name] : undefined}
                                                onChange={
                                                  item.onChange
                                                    ? (e) => {
                                                        item.onChange(setFieldValue, e);
                                                      }
                                                    : undefined
                                                }
                                                {...item.componentProps}
                                              />
                                              {touched[item.name] && errors[item.name] && (
                                                <FormHelperErrorStyled error={true} margin="dense" variant="filled">
                                                  {item.error}
                                                </FormHelperErrorStyled>
                                              )}
                                            </div>
                                          )
                                        ) : (
                                          <Field key={item.name} name={item.name}>
                                            {({
                                              field, // { name, value, onChange, onBlur }
                                              form: { touched: fieldTouched, errors: fieldErrors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                            }) => (
                                              <Input
                                                inputProps={{
                                                  'data-testid': 'field-input-' + item.name,
                                                  onChange: (e) => {
                                                    item.onChange && item.onChange(setFieldValue, e);
                                                  },
                                                }}
                                                data-testid={'field-' + item.name}
                                                multiline={item.multiline}
                                                rows={item.rows}
                                                className={item.className}
                                                {...field}
                                                type={item.type ? item.type : 'text'}
                                                values={values ? values[item.name] : undefined}
                                                label={`${item.label} ${checkIfRequired(item.name)}`}
                                                placeholder={item.placeholder}
                                                error={fieldTouched[item.name] && fieldErrors[item.name] ? true : false}
                                                helperText={
                                                  fieldTouched[item.name] && fieldErrors[item.name] && item.error
                                                }
                                                autoComplete="off"
                                                options={item.options}
                                                disabled={item.disabled || item.options?.length === 0}
                                              />
                                            )}
                                          </Field>
                                        )}
                                      </Grid>
                                    )
                                  );
                                })}
                              </Grid>
                            )
                          );
                        })}
                      </Form>
                    </>
                  </DialogContent>

                  <DialogActions>
                    {showCancel !== false && (
                      <Button
                        onClick={() => onCancel(resetForm)}
                        variant="outlined"
                        disableElevation
                        data-testid="footer-cancel"
                        disabled={disableCancel}
                        text={labelCancel || formatMessage({ id: 'common.form.cancel' })}
                      />
                    )}
                    {showContinue !== false && (
                      <Button
                        onClick={() => {
                          void submitForm();
                        }}
                        data-testid="footer-continue"
                        disabled={disableContinue}
                        text={labelContinue ? labelContinue : formatMessage({ id: 'common.form.continue' })}
                        loading={isLoading}
                      />
                    )}
                  </DialogActions>
                  {openedConfirmationPopup && (
                    <ConfirmationPopup
                      onSubmit={submitForm}
                      onCancel={closePopup}
                      fieldset={fieldset}
                      values={values}
                      fieldComponent={confirmationField}
                    />
                  )}
                </FormBaseStyled>
              </StyleWrapper>
            )}
          </Formik>

          {ComponentBottom}
        </Dialog>
      )}
    </BaseStyled>
  );
};

Base.propTypes = {
  schema: PropTypes.any,
  initials: PropTypes.any,
  fieldset: PropTypes.array,
  onSubmit: PropTypes.func,
  modalTitle: PropTypes.string,
  getData: PropTypes.func,
  elClassName: PropTypes.string,
  elType: PropTypes.oneOf(['button', 'iconbutton']),
  elSize: PropTypes.string,
  elLabel: PropTypes.string,
  elVariant: PropTypes.string,
  elIcon: PropTypes.any,
  disableContinue: PropTypes.bool,
};

Base.defaultProps = {
  elVariant: 'text',
  elSize: 'small',
  disableContinue: false,
  onClose: () => {},
};

export default Base;
