import React from 'react';
import { FastField, Field } from 'formik';

export interface BaseFieldProps {
  component?: any;
  /** override the default prefix for the translations */
  prefix?: string;
  /** use FastField instead of a Field */
  fast?: boolean;
}

const BaseField: React.FC<BaseFieldProps> = (props) => {
  const { component, fast, prefix, ...otherProps } = props;

  return (
    <>
      {fast && <FastField {...otherProps} prefix={prefix} component={component} />}
      {!fast && <Field {...otherProps} prefix={prefix} component={component} />}
    </>
  );
};

export default BaseField;
