import styled from 'styled-components';

export const ConfirmUserFormStyled = styled.div`
  .confirm-user-panel {
    min-height: 250px;
    padding: 20px 5vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    h1 {
      font-weight: 100;
    }

    i {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      display: block;
    }

    &.success {
      i {
        color: ${(props) => props.theme.colors.success};
      }
    }

    &.failure {
      i {
        color: ${(props) => props.theme.colors.error};
      }
    }

    .panel-forgot-link {
      text-align: center;
      margin-top: 30px;
    }
  }
`;
