import { FiltersObj, generateFiltersQuery, getFiltersByQueryString, GetResultsOptions } from './../models/filters';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AxiosRequestConfig, AxiosError } from 'axios';
import config from 'config';
import { Owner, Tenant, TENANT_TYPE } from 'store/models/tenant';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { User } from 'store/models/user';
import { Role } from 'store/models/role';
import { getBootstrap } from './bootstrap';
import { Node } from 'store/models/node';
import { QosProfile4G } from 'store/models/qosProfile4G';
import { SegmentProfile } from 'store/models/segment';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { setScheduledOperation } from './bulkOperations';
import { UsimProfile } from 'store/models/usimProfile';
import { sentryLogError } from 'sentry';

export enum TENANTS_ACTION_TYPE {
  FILTERS_SET = 'TENANTS_FILTERS_SET',

  SORT_SET = 'TENANTS_SORT_SET',

  LIST_LOADING = 'TENANTS_LIST_LOADING',
  LIST_SUCCESS = 'TENANTS_LIST_SUCCESS',
  LIST_FAILURE = 'TENANTS_LIST_FAILURE',
  LIST_CLEAR = 'TENANTS_LIST_CLEAR',

  CHANNEL_PARTNER_LOADING = 'TENANTS_CHANNEL_PARTNER_LOADING',
  CHANNEL_PARTNER_SUCCESS = 'TENANTS_CHANNEL_PARTNER_SUCCESS',
  CHANNEL_PARTNER_FAILURE = 'TENANTS_CHANNEL_PARTNER_FAILURE',

  NETWORK_MANAGERS_LOADING = 'TENANTS_NETWORK_MANAGERS_LOADING',
  NETWORK_MANAGERS_SUCCESS = 'TENANTS_NETWORK_MANAGERS_SUCCESS',
  NETWORK_MANAGERS_FAILURE = 'TENANTS_NETWORK_MANAGERS_FAILURE',

  SITES_LOADING = 'TENANTS_SITES_LOADING',
  SITES_SUCCESS = 'TENANTS_SITES_SUCCESS',
  SITES_FAILURE = 'TENANTS_SITES_FAILURE',
  SITES_RESET = 'TENANTS_SITES_RESET',

  SUBTENANTS_LOADING = 'TENANTS_SUBTENANTS_LOADING',
  SUBTENANTS_SUCCESS = 'TENANTS_SUBTENANTS_SUCCESS',
  SUBTENANTS_FAILURE = 'TENANTS_SUBTENANTS_FAILURE',

  RESET = 'TENANTS_RESET',
}

export function filtersSet(payload: unknown) {
  return {
    type: TENANTS_ACTION_TYPE.FILTERS_SET,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: TENANTS_ACTION_TYPE.SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: TENANTS_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: TENANTS_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: TENANTS_ACTION_TYPE.LIST_FAILURE,
  };
}

export function listClear() {
  return {
    type: TENANTS_ACTION_TYPE.LIST_CLEAR,
  };
}

export function resetTenantSites() {
  return {
    type: TENANTS_ACTION_TYPE.SITES_RESET,
  };
}

export function reset() {
  return {
    type: TENANTS_ACTION_TYPE.RESET,
  };
}
// TODO: remove and cleanup the functions above

export type GetTenantsRes = {
  tenants: Tenant[];
  total_items: number;
};

export function getTenants({
  sort,
  limit,
  page,
  filters,
}: {
  sort: string;
  limit: number;
  page: number;
  filters: string;
}): Thunk<Promise<GetTenantsRes>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.getTenants
        .replace('{sort}', sort)
        .replace('{limit}', `${limit}`)
        .replace('{page}', `${page}`)
        .replace('{filters}', filters)}`,
      method: 'GET',
    };
    return dispatch(fetchData<GetTenantsRes>(options));
  };
}

export function getTenantsList({ page = 0, sortBy, filterBy }: GetResultsOptions): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (sortBy) {
      dispatch({
        type: TENANTS_ACTION_TYPE.SORT_SET,
        payload: sortBy,
      });
    }

    const newFilterBy: FiltersObj = filterBy || {
      ...getFiltersByQueryString(),
      ...getState().tenants.filters,
    };

    dispatch({
      type: TENANTS_ACTION_TYPE.FILTERS_SET,
      payload: newFilterBy,
    });

    const {
      bootstrap,
      tenants: { filters, sort },
    } = getState();

    dispatch({
      type: TENANTS_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const query = encodeURI(generateFiltersQuery(filters, '&'));

      const results = await dispatch(getTenants({ sort, limit: bootstrap?.pageLimit || 1000, page, filters: query }));

      dispatch({
        type: TENANTS_ACTION_TYPE.LIST_SUCCESS,
        payload: { ...results, page },
      });
    } catch {
      dispatch({
        type: TENANTS_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}

export function getTenant(tenantId: string): Thunk<Promise<Tenant | null>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getTenant.replace('{id}', tenantId),
        method: 'GET',
      };
      const tenant = await dispatch(fetchData<Tenant>(options));
      return tenant;
    } catch (e) {
      dispatch(showErrorToast());
      sentryLogError(e);
      return null;
    }
  };
}

export function getChannelPartners(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: TENANTS_ACTION_TYPE.CHANNEL_PARTNER_LOADING,
    });
    try {
      const result = await dispatch(
        getTenants({
          sort: 'name',
          limit: 10,
          page: 0,
          filters: '&type=channel_partner',
        })
      );
      dispatch({
        type: TENANTS_ACTION_TYPE.CHANNEL_PARTNER_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: TENANTS_ACTION_TYPE.CHANNEL_PARTNER_FAILURE,
      });
    }
  };
}

export function getNetworkManagers(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: TENANTS_ACTION_TYPE.CHANNEL_PARTNER_LOADING,
    });
    try {
      const result = await dispatch(
        getTenants({
          sort: 'name',
          limit: 10,
          page: 0,
          filters: '&type=network_manager',
        })
      );
      dispatch({
        type: TENANTS_ACTION_TYPE.NETWORK_MANAGERS_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: TENANTS_ACTION_TYPE.NETWORK_MANAGERS_FAILURE,
      });
    }
  };
}

export function getTenantsWithSubtenants(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: TENANTS_ACTION_TYPE.SUBTENANTS_LOADING,
    });
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getTenantWithSubtenants
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', ''),
        method: 'GET',
      };
      const response = await dispatch(fetchData<GetTenantsRes>(options));
      dispatch({
        type: TENANTS_ACTION_TYPE.SUBTENANTS_SUCCESS,
        payload: response.tenants,
      });
    } catch (e) {
      dispatch({
        type: TENANTS_ACTION_TYPE.SUBTENANTS_FAILURE,
      });
    }
  };
}

export function getTenantsByUser(userId: User['id']): Thunk<Promise<Owner[] | null>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.getTenantsByUser.replace('{user_id}', userId)}`,
      method: 'GET',
    };
    return dispatch(fetchData<Tenant[]>(options));
  };
}

type CreateTenantByUserPayload = {
  tenant_id: Tenant['id'];
  role: Role['name'];
};

export function addTenantToUser(
  userId: User['id'],
  { tenant_id, role }: CreateTenantByUserPayload
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.addTenantToUser}`,
      method: 'POST',
      data: {
        tenant_id,
        role,
        user_id: userId,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function deleteTenantFromUser(userId: User['id'], tenantId: Tenant['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.deleteTenantFromUser}`,
      method: 'POST',
      data: {
        tenant_id: tenantId,
        user_id: userId,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export type AssignTenantByProfilePayload = {
  tenant_id: Tenant['id'];
  permissions: string; // TODO create enum
};

/* NOTE: CC-1285 */
export function getTenantsBySegmentProfile(segmentId: SegmentProfile['id']): Thunk<Promise<SegmentProfile['owners']>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.getSegment.replace('{id}', segmentId)}`,
      method: 'GET',
    };
    const profile = await dispatch(
      fetchData<{
        items: SegmentProfile[];
      }>(options)
    );
    return profile.items[0].owners;
  };
}

export function assignTenantToSegmentProfile(
  segmentId: SegmentProfile['id'],
  { tenant_id, permissions }: AssignTenantByProfilePayload
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const options: AxiosRequestConfig = {
      url: `${config.apis.assignSegmentTenant.replace('{id}', segmentId)}`,
      method: 'PUT',
      data: {
        tenant_id,
        permissions,
        operation_id: uuid,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function deassignTenantToSegmentProfile(segmentId: string, tenant_id: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const options: AxiosRequestConfig = {
      url: `${config.apis.deassignSegmentTenant.replace('{id}', segmentId)}`,
      method: 'PUT',
      data: {
        tenant_id,
        operation_id: uuid,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function getTenantsByQosProfile4g(profileId: string): Thunk<Promise<QosProfile4G['owners']>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.getQoSProfiles
        .replace('{sort}', '')
        .replace('{limit}', `1`)
        .replace('{page}', '0')
        .replace('{filters}', `&id=${profileId}`),
      method: 'GET',
    };
    const profile = await dispatch(
      fetchData<{
        items: QosProfile4G[];
      }>(options)
    );
    return profile.items[0].owners;
  };
}

export function assignTenantToQosProfile4g(
  profileId: string,
  { tenant_id, permissions }: AssignTenantByProfilePayload
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const options: AxiosRequestConfig = {
      url: `${config.apis.assignQoSProfileTenant.replace('{id}', profileId)}`,
      method: 'PUT',
      data: {
        tenant_id,
        permissions,
        operation_id: uuid,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function deassignTenantToQosProfile4g(profileId: string, tenant_id: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const options: AxiosRequestConfig = {
      url: `${config.apis.deassignQoSProfileTenant.replace('{id}', profileId)}`,
      method: 'PUT',
      data: {
        tenant_id,
        operation_id: uuid,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function getTenantsByNode(id: string): Thunk<Promise<Node['owners']>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.getNodes
        .replace('{sort}', '')
        .replace('{limit}', `1`)
        .replace('{page}', '0')
        .replace('{filters}', `&id=${id}`)
        .replace('{platform}', ''),
      method: 'GET',
    };

    const nodes = await dispatch(
      fetchData<{
        items: Node[];
      }>(options)
    );
    return nodes.items[0].owners;
  };
}

export function assignTenantToNode(id: string, tenant_id: Tenant['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.assignTenantNode.replace('{id}', id),
      method: 'PUT',
      data: {
        tenant_id,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function deassignTenantToNode(id: string, tenant_id: Tenant['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deassignTenantNode.replace('{id}', id),
      method: 'PUT',
      data: {
        tenant_id,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function getTenantsByUsimProfile(profileId: UsimProfile['id']): Thunk<Promise<UsimProfile['owners']>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.getUsimProfiles
        .replace('{sort}', '')
        .replace('{limit}', `1`)
        .replace('{page}', '0')
        .replace('{filters}', `&id=${profileId}`),
      method: 'GET',
    };
    const profile = await dispatch(
      fetchData<{
        items: UsimProfile[];
      }>(options)
    );
    return profile.items[0].owners;
  };
}

export function assignTenantToUsimProfile(
  profileId: UsimProfile['id'],
  { tenant_id, permissions }: AssignTenantByProfilePayload
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const options: AxiosRequestConfig = {
      url: `${config.apis.assignUsimProfileTenant.replace('{id}', profileId)}`,
      method: 'PUT',
      data: {
        tenant_id,
        permissions,
        operation_id: uuid,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

export function deassignTenantToUsimProfile(profileId: UsimProfile['id'], tenant_id: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uuid = await dispatch(setScheduledOperation());
    const options: AxiosRequestConfig = {
      url: `${config.apis.deassignUsimProfileTenant.replace('{id}', profileId)}`,
      method: 'PUT',
      data: {
        tenant_id,
        operation_id: uuid,
      },
    };
    return dispatch(fetchData<void>(options));
  };
}

function fetchTenantsOptions(query?: string, onlyParents?: boolean): Thunk<Promise<Tenant[]>> {
  return async (dispatch) => {
    const api = onlyParents ? config.apis.getTenantParents : config.apis.getTenantWithSubtenants;

    const url = api
      .replace('{sort}', 'name')
      .replace('{limit}', 1000)
      .replace('{page}', 0)
      .replace('{filters}', query ? `?name=contains{{${query}}}` : '');

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };

    try {
      const res = await dispatch(fetchData<GetTenantsRes>(options));
      return res.tenants;
    } catch (e) {
      dispatch(showErrorToast());
      sentryLogError(e);
      return [];
    }
  };
}

export type TenantOption = { label: string; value: { id: string; type: string } };

export function getTenantsOptions(query?: string): Thunk<Promise<TenantOption[]>> {
  return async (dispatch) => {
    const tenants = await dispatch(fetchTenantsOptions(query));
    return tenants.map((tenant) => ({ label: tenant.name, value: { id: tenant.id, type: tenant.type } }));
  };
}

export function getParentTenants(query?: string): Thunk<Promise<Tenant[]>> {
  return async (dispatch) => {
    return dispatch(fetchTenantsOptions(query, true));
  };
}

export function getTenantsAutocompleteOptions(query?: string): Thunk<Promise<AutocompleteItemProps[]>> {
  return async (dispatch) => {
    const tenants = await dispatch(fetchTenantsOptions(query));
    return tenants.map((tenant) => ({ label: tenant.name, value: tenant.id }));
  };
}

export function updateTenantLogo(
  tenantId: string,
  file: Blob,
  encrypted?: string,
  key_id?: string | Blob
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uploadData = new FormData();
    uploadData.append('uploadfile', file);

    if (encrypted && key_id) uploadData.append('key_id', key_id);

    const options: AxiosRequestConfig = {
      url: config.apis.updateTenantLogo.replace('{id}', tenantId),
      method: 'PUT',
      data: uploadData,
    };

    await dispatch(fetchData<void>(options));
    await dispatch(getBootstrap());
  };
}

export function deleteTenantLogo(tenantId: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteTenantLogo.replace('{id}', tenantId),
      method: 'DELETE',
    };
    await dispatch(fetchData<void>(options));
    await dispatch(getBootstrap());
  };
}

const getTenantErrorMessages = (error: unknown): Thunk<Promise<void>> => {
  return async (dispatch) => {
    const e = error as AxiosError;
    let errorString: string | undefined;

    switch (e.response?.data?.error) {
      case 'invalid tenant role':
        errorString = 'tenants.error.invalidRole';
        break;
      case 'user name already been taken':
        errorString = 'tenants.error.userTaken';
        break;
      case 'tenant name already been taken':
        errorString = 'tenants.error.tenantTaken';
        break;
      case 'cannot change tenant parent_id':
        errorString = 'tenants.error.cannotChangeParent';
        break;
      default:
        errorString = undefined;
        break;
    }

    void dispatch(
      showErrorToast(
        errorString
          ? {
              message: errorString,
              intlMessage: true,
            }
          : undefined
      )
    );
  };
};

export type CreateTenantPayload = Pick<Tenant, 'name' | 'address' | 'country' | 'type' | 'parent_id'>;

export function createTenant({ name, address, country, parent_id, type }: CreateTenantPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    let apiCall =
      type === TENANT_TYPE.CHANNEL_PARTNER
        ? config.apis.createChannelPartner
        : type === TENANT_TYPE.NETWORK_MANAGER
        ? config.apis.createNetworkManager
        : '';

    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('country', country);
    formData.append('tenant_id', parent_id);

    const options: AxiosRequestConfig = {
      url: apiCall,
      method: 'POST',
      data: formData,
    };

    try {
      await dispatch(fetchData<void>(options));
      await dispatch(getTenantsList({}));
      dispatch(showSuccessToast());
    } catch (e) {
      getTenantErrorMessages(e);
    }
  };
}

export type EditTenantPayload = { values: CreateTenantPayload; tenantId: Tenant['id'] };

export function editTenant({
  values: { name, address, country, parent_id },
  tenantId,
}: EditTenantPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('country', country);

    if (parent_id) {
      formData.append('parent_id', parent_id);
    }

    const options: AxiosRequestConfig = {
      url: config.apis.updateTenant.replace('{id}', tenantId),
      method: 'PATCH',
      data: formData,
    };

    try {
      await dispatch(fetchData<void>(options));
      await dispatch(getTenantsList({}));
      dispatch(showSuccessToast());
    } catch (e) {
      getTenantErrorMessages(e);
    }
  };
}

export function deleteTenant(tenantId: Tenant['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteTenants.replace('{id}', tenantId),
      method: 'DELETE',
    };

    try {
      await dispatch(fetchData<void>(options));
      await dispatch(getTenantsList({}));
      dispatch(showSuccessToast());
    } catch (e) {
      const error = e as AxiosError;
      if (error.response) {
        dispatch(
          showErrorToast({
            message: error.response.data?.error,
            intlMessage: false,
          })
        );
      } else {
        dispatch(showErrorToast());
      }
    }
  };
}
