import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData } from 'store/actions/fetchData';

export function useFetchData() {
  const dispatch = useDispatch();
  const handleFetchData = useCallback(
    async (options: AxiosRequestConfig) => {
      try {
        return dispatch(fetchData(options));
      } catch (e: any) {
        throw new Error(e);
      }
    },
    [dispatch]
  );
  return handleFetchData;
}
