import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormBaseStyled } from 'components/Edit/styled';
import Input from 'components/Form/Input';
import PasswordStrength from 'components/Form/PasswordStrength';
import Grid from 'components/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { string, object, ref } from 'yup';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { Button } from '@athonet/ui/components/Input/Button';
import { useDispatch } from 'react-redux';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { changePassword } from 'store/actions/users';

const PasswordMuiInputComp = ({ show, handler, dataTestid }) => {
  const preventDefault = (event) => event.preventDefault();

  return (
    <InputAdornment position="end">
      <IconButton
        data-testid={`changePasswordDialog-${dataTestid}`}
        aria-label="toggle password visibility"
        onClick={handler}
        onMouseDown={preventDefault}
        edge="end"
      >
        {show ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export default function ChangePasswordDialog() {
  const [showOldpassword, setShowOldpassword] = useState(false);
  const [showNewpasword, setShowNewpassword] = useState(false);
  const [showConfirmpasword, setShowConfirmpassword] = useState(false);
  const pattern = useMemo(
    () => /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/,
    []
  );
  const dispatch = useDispatch();
  const { dialogClose } = useOverlay();

  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      object().shape({
        oldpassword: string().required(formatMessage({ id: 'changePassword.requiredOldpasswordError' })),
        newpassword: string()
          .required(formatMessage({ id: 'changePassword.requiredNewpasswordError' }))
          .test('newpassword', formatMessage({ id: 'changePassword.requiredNewpasswordError' }), (value) =>
            value ? pattern.test(value.toString()) : false
          ),
        confirmpassword: string()
          .oneOf([ref('newpassword'), null], formatMessage({ id: 'changePassword.matchPasswordError' }))
          .required(formatMessage({ id: 'changePassword.requiredConfirmpasswordError' })),
      }),
    [formatMessage, pattern]
  );

  const handleClickShowOldpassword = () => setShowOldpassword((prev) => !prev);

  const handleClickShowNewpassword = () => setShowNewpassword((prev) => !prev);

  const handleClickShowConfirmpassword = () => setShowConfirmpassword((prev) => !prev);

  /** @type {FormData} */
  const initialValues = {
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  };

  const oldpasswordInputProps = { 'data-testid': 'changePasswordDialog-oldpassword' };

  const newpasswordInputProps = { 'data-testid': 'changePasswordDialog-newpassword' };

  const confirmpasswordInputProps = { 'data-testid': 'changePasswordDialog-confirmpassword' };

  const oldpasswordMuiInputProps = {
    endAdornment: (
      <PasswordMuiInputComp
        show={showOldpassword}
        handler={handleClickShowOldpassword}
        dataTestId={'showOldPassword'}
      />
    ),
  };

  const newpasswordMuiInputProps = {
    endAdornment: (
      <PasswordMuiInputComp show={showNewpasword} handler={handleClickShowNewpassword} dataTestId={'showNewpasword'} />
    ),
  };

  const confirmpasswordMuiInputProps = {
    endAdornment: (
      <PasswordMuiInputComp
        show={showConfirmpasword}
        handler={handleClickShowConfirmpassword}
        dataTestId={'showConfirmpasword'}
      />
    ),
  };

  const handleFormikSubmit = useCallback(
    async (values, actions) => {
      try {
        await dispatch(changePassword(values));
        actions.resetForm();
        dialogClose();
      } catch (err) {
        // TODO handle errors in some way (changePassword action is being catched)
      }
    },
    [dialogClose, dispatch]
  );

  const { handleSubmit, handleChange, errors, touched, submitForm, values } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: handleFormikSubmit,
  });

  return (
    <FormBaseStyled>
      <DialogContent>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid className="edit-body" container spacing={2}>
            <div className="form-fieldset-title"></div>

            <Grid item xs={12}>
              <Input
                name="oldpassword"
                onChange={handleChange}
                inputProps={oldpasswordInputProps}
                autoComplete="off"
                type={showOldpassword ? 'text' : 'password'}
                value={values.oldpassword}
                label={formatMessage({ id: 'changePassword.oldpasswordLabel' })}
                placeholder={formatMessage({ id: 'changePassword.oldpasswordPlaceholder' })}
                error={touched.oldpassword && !!errors.oldpassword}
                helperText={touched.oldpassword && errors.oldpassword}
                InputProps={oldpasswordMuiInputProps}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="newpassword"
                onChange={handleChange}
                inputProps={newpasswordInputProps}
                autoComplete="off"
                type={showNewpasword ? 'text' : 'password'}
                value={values.newpassword}
                label={formatMessage({ id: 'changePassword.newpasswordLabel' })}
                placeholder={formatMessage({ id: 'changePassword.newpasswordPlaceholder' })}
                error={touched.newpassword && !!errors.newpassword}
                helperText={touched.newpassword ? errors.newpassword : formatMessage({ id: 'changePassword.rules' })}
                InputProps={newpasswordMuiInputProps}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                name="confirmpassword"
                onChange={handleChange}
                autoComplete="off"
                inputProps={confirmpasswordInputProps}
                type={showConfirmpasword ? 'text' : 'password'}
                value={values.confirmpassword}
                label={formatMessage({ id: 'changePassword.confirmpasswordLabel' })}
                placeholder={formatMessage({ id: 'changePassword.confirmpasswordPlaceholder' })}
                error={touched.confirmpassword && !!errors.confirmpassword}
                helperText={touched.confirmpassword && errors.confirmpassword}
                InputProps={confirmpasswordMuiInputProps}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordStrength password={values.newpassword} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={dialogClose}
          variant="outlined"
          data-testid="changePasswordDialog-cancelButton"
          text={formatMessage({ id: 'common.form.cancel' })}
        />

        <Button
          onClick={() => {
            void submitForm();
          }}
          data-testid="changePasswordDialog-submitButton"
          text={formatMessage({ id: 'common.form.continue' })}
        />
      </DialogActions>
    </FormBaseStyled>
  );
}
