import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { loginUser, authUser } from 'store/actions/login';
import config from 'config';
import axios from 'axios';
import LoginForm from 'components/LoginForm';
import SendPasswordForm from 'components/SendPasswordForm';
import TenantsListForm from 'components/TenantsListForm';
import ChangePasswordForm from 'components/ChangePasswordForm';
import Box from '@material-ui/core/Box';
import { lsSet } from 'utils/localStorage';
import { showErrorToast, showSuccessToast, showWarningToast } from 'store/actions/toast';
import { getBootstrap } from 'store/actions/bootstrap';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Login = () => {
  const [value, setValue] = useState(0);
  const [credentials, setCredentials] = useState(null);
  const [tenantsList, setTenantsList] = useState(null);
  const [tokens, setTokens] = useState(null);
  const dispatch = useDispatch();
  const bootstrap = useBootstrapSelector();
  const navigate = useNavigate();

  const tabChange = (newValue) => {
    setValue(newValue);
  };

  const headers = {
    accept: 'application/json',
    'content-type': 'application/json;charset=UTF-8',
  };

  const manageTenants = (result, authData, actions) => {
    setCredentials(authData);
    if (result.tenants?.length > 1) {
      // choose tenants
      // switch to tenants list form
      setTenantsList(result.tenants);
      tabChange(2); // 2: tenants list
    } else if (result.tenants?.length === 1) {
      // if 1 tenant only -> auto login
      if (result.tenants[0]?.id) {
        loginSubmit(authData, result.tenants[0].id, actions);
      } else {
        // wrong ID in tenant
        dispatch(
          showWarningToast({
            message: 'login.noTenant',
            intlMessage: true,
          })
        );
        actions.resetForm();
        actions.setFieldValue('auth_provider', authData.auth_provider);
      }
    } else {
      // no tenants associated -> can't login
      dispatch(
        showWarningToast({
          message: 'login.noTenant',
          intlMessage: true,
        })
      );
      actions.resetForm();
      actions.setFieldValue('auth_provider', authData.auth_provider);
    }
  };

  const confirmTenant = (id) => {
    loginSubmit(credentials, id);
  };

  const loginSubmit = (values, tenant, actions) => {
    const data = new FormData();
    data.append('user', values.user);
    data.append('password', values.password);
    data.append('auth_provider', values.auth_provider);
    data.append('tenant_id', tenant);

    const options = {
      url: `${config.apis.login}`,
      method: 'POST',
      headers: headers,
      data: data,
    };

    axios(options)
      .then((result) => {
        // call API user/me -> then goto landing
        if (result.data.change_password) {
          // Temporary password -> insert a new password
          setTokens(result.data);
          tabChange(3); // reset password if temporary
        } else {
          getUserData(result.data, () => {
            dispatch(getBootstrap()).then(() => {
              // NOTE: get bootstrap here is responsible of fetching the new bootstrap data after a login
              navigate('/home');
            });
          });
        }
      })
      .catch(() => {
        // error message
        if (actions) {
          actions.resetForm();
          actions.setFieldValue('auth_provider', values.auth_provider);
        }
        dispatch(
          showErrorToast({
            message: 'login.errorMessage',
            intlMessage: true,
          })
        );
      });
  };

  const sendResetSubmit = (values, actions) => {
    const data = new FormData();
    data.append('user', values.email);

    const options = {
      url: `${config.apis.sendReset}`,
      method: 'POST',
      headers: headers,
      data: data,
    };

    axios(options)
      .then(() => {
        // success message
        dispatch(
          showSuccessToast({
            message: 'login.reset.emailSent',
            intlMessage: true,
          })
        );
        // back to login form
        tabChange(0);
      })
      .catch(() => {
        // error message
        dispatch(
          showErrorToast({
            message: 'login.reset.errorMessage',
            intlMessage: true,
          })
        );
        // reset form
        actions.resetForm();
      });
  };

  const getUserData = (authTokens, callback) => {
    let headersToken = Object.assign({}, headers);
    headersToken['horus-token'] = authTokens.token;

    const options = {
      url: `${config.apis.me}`,
      method: 'GET',
      headers: headersToken,
    };

    axios(options)
      .then((result) => {
        // set tokens and user data to redux
        dispatch(authUser({ accessToken: authTokens.token, refreshToken: authTokens.refresh_token }));
        dispatch(loginUser(result.data));
        // set localstorage as Redux
        let lsdata = Object.assign({}, result.data);
        lsdata['accessToken'] = authTokens.token;
        lsdata['refreshToken'] = authTokens.refresh_token;
        lsSet(lsdata);

        // callback
        callback();
      })
      .catch(() => {
        // error message
        dispatch(showErrorToast());
      });
  };

  const changePasswordSubmit = (values, actions) => {
    const data = new FormData();
    data.append('oldpassword', credentials.password);
    data.append('newpassword', values.password);

    let headersToken = Object.assign({}, headers);
    headersToken['horus-token'] = tokens.token;

    const options = {
      url: `${config.apis.changePassword}`,
      method: 'POST',
      headers: headersToken,
      data: data,
    };

    axios(options)
      .then(() => {
        // success message
        dispatch(
          showSuccessToast({
            message: 'login.reset.passwordChanged',
            intlMessage: true,
          })
        );
        // back to login
        tabChange(0);
      })
      .catch(() => {
        // error message
        dispatch(
          showErrorToast({
            message: 'login.reset.resetError',
            intlMessage: true,
          })
        );
        // reset form
        actions.resetForm();
      });
  };

  if (!bootstrap) {
    return null;
  }

  return (
    <div data-testid="login">
      <Stack fullWidth align="center">
        <Box sx={{ width: '280px', mb: 2 }}>
          <Logo imageUrl={bootstrap.logo} width={280} />
        </Box>
      </Stack>
      <TabPanel value={value} index={0}>
        <LoginForm onSubmit={manageTenants} onForgot={() => tabChange(1)} />
      </TabPanel>
      {bootstrap.forgot_password && (
        <TabPanel value={value} index={1}>
          <SendPasswordForm onSubmit={sendResetSubmit} onBack={() => tabChange(0)} />
        </TabPanel>
      )}
      <TabPanel value={value} index={2}>
        <TenantsListForm onSubmit={confirmTenant} onBack={() => tabChange(0)} tenantsList={tenantsList} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ChangePasswordForm onSubmit={changePasswordSubmit} onBack={() => tabChange(0)} />
      </TabPanel>
    </div>
  );
};

export default Login;
