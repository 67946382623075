import { AnalysisTable } from '../AnalysisTable';
import {
  getCdrImeiChangesTimeseriesAvailabilityDates,
  getCdrImeiChangesTimeseriesData,
  getCdrImeiChangesTimeseriesSchema,
} from 'store/actions/cdrReports/imeiChanges';
import {
  useCdrImeiChangesTimeseriesAvailabilityDates,
  useCdrImeiChangesTimeseriesQuery,
  useCdrImeiChangesTimeseriesSchema,
} from 'store/selectors/cdrReports/imeiChanges';

export function ImeiChangesAnalysis() {
  const imeiChangesTimeseriesSchema = useCdrImeiChangesTimeseriesSchema();
  const imeiChangesTimeseriesAvailabilityDates = useCdrImeiChangesTimeseriesAvailabilityDates();
  const { currentQuery: query } = useCdrImeiChangesTimeseriesQuery();

  return (
    <AnalysisTable
      timeseriesSchema={imeiChangesTimeseriesSchema}
      timeseriesAvailabilityDates={imeiChangesTimeseriesAvailabilityDates}
      query={query}
      getTimeseriesAvailabilityDates={getCdrImeiChangesTimeseriesAvailabilityDates}
      getTimeseriesData={getCdrImeiChangesTimeseriesData}
      getTimeseriesSchema={getCdrImeiChangesTimeseriesSchema}
    />
  );
}
