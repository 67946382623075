// Utils
import timezones from 'utils/timezones';

const getTimezones = () => {
  const optionsTimezones = timezones().map((item) => ({ value: item.code, label: item.label }));

  return optionsTimezones;
};

export default getTimezones;

export const getTimezoneValue = (code) => {
  const value = timezones().filter((item) => item.code === code)[0]?.value;
  return value;
};

export const getTimezoneLabel = (code) => {
  const value = timezones().filter((item) => item.code === code)[0]?.label;
  return value;
};
