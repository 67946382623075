// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';
import { useCallback } from 'react';
import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';
// Utils
import getTimezones from 'utils/getTimezones';
import getStatesCountries, { getStateContryGroupBy } from 'utils/getStatesCountries';
import { T_PERMISSIONS } from 'utils/permissionCodes';

// Style
import { FormStyled } from '../styled';

const Edit = ({ getData, dataOptions, isOpen, onClose, onEdit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [dataOptions]);

  const schema = object().shape({
    name: string().required(),
    tenant_id: string().required(),
    address: string(),
    country: string(),
    additional_info: string(),
    network_name: string(),
    timezone: string(),
  });

  const getSiteData = useCallback(async () => {
    const site = await getData();
    const country = getStatesCountries().find((c) => c.value === site.country);
    return {
      ...site,
      country,
    };
  }, [getData]);

  const fieldset = [
    {
      title: formatMessage({ id: 'sites.form.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'sites.form.name.label' }),
          placeholder: formatMessage({ id: 'sites.form.name.placeholder' }),
          error: formatMessage({ id: 'sites.form.name.error' }),
        },
        {
          name: 'tenant_id',
          label: formatMessage({ id: 'sites.form.tenant.label' }),
          placeholder: formatMessage({ id: 'sites.form.tenant.placeholder' }),
          error: formatMessage({ id: 'sites.form.tenant.error' }),
          options: dataOptions.tenants,
          permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
          disabled: true,
        },
      ],
    },
    {
      title: formatMessage({ id: 'sites.form.location' }),
      data: [
        {
          name: 'address',
          label: formatMessage({ id: 'sites.form.address.label' }),
          placeholder: formatMessage({ id: 'sites.form.address.placeholder' }),
          gridClassName: 'fullwidth',
          error: formatMessage({ id: 'sites.form.address.error' }),
        },
        {
          name: 'country',
          label: formatMessage({ id: 'sites.form.country.label' }),
          placeholder: formatMessage({ id: 'sites.form.country.placeholder' }),
          error: formatMessage({ id: 'sites.form.country.error' }),
          fieldComponent: Autocomplete,
          componentProps: {
            options: getStatesCountries(),
            multiple: false,
            size: 'small',
            groupBy: getStateContryGroupBy,
            disableClearable: false,
          },
        },
        {
          name: 'timezone',
          label: formatMessage({ id: 'sites.form.timezone.label' }),
          placeholder: formatMessage({ id: 'sites.form.timezone.placeholder' }),
          error: formatMessage({ id: 'sites.form.timezone.error' }),
          options: getTimezones(),
        },
      ],
    },
    {
      title: formatMessage({ id: 'sites.form.extra' }),
      data: [
        {
          name: 'network_name',
          label: formatMessage({ id: 'sites.form.networkname.label' }),
          placeholder: formatMessage({ id: 'sites.form.networkname.placeholder' }),
          error: formatMessage({ id: 'sites.form.networkname.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'additional_info',
          label: formatMessage({ id: 'sites.form.info.label' }),
          placeholder: formatMessage({ id: 'sites.form.info.placeholder' }),
          error: formatMessage({ id: 'sites.form.info.error' }),
          multiline: true,
          rows: 3,
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        getData={getSiteData}
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        fieldset={fieldlist}
        onSubmit={onEdit}
        modalTitle={formatMessage({ id: 'sites.editSite' })}
      />
    </FormStyled>
  );
};

Edit.propTypes = {
  getOptions: PropTypes.func,
  getData: PropTypes.func,
  options: PropTypes.any,
};

export default Edit;
