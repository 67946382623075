import { Button, ButtonProps } from '@athonet/ui/components/Input/Button';
import React from 'react';
import { useIntl } from 'react-intl';

export const CreateButton: React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="outlined"
      startIcon="Add"
      text={formatMessage({ id: 'common.new' })}
      {...props}
      data-testid="toolbar-new-button"
    />
  );
};
