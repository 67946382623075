import styled from 'styled-components';

export const SiteCardStyled = styled.div`
  .site-card-icons {
    margin-bottom: 16px;

    > span {
      margin-right: 15px;

      .site-card-icon {
        margin-right: 5px;
      }

      svg {
        fill: ${(props) => props.theme.colors.midlightgray};
      }
    }
  }

  .site-card-address {
    display: flex;

    svg {
      fill: ${(props) => props.theme.colors.midlightgray};
      margin-left: -6px;
    }
  }

  .site-card-title {
    color: ${(props) => props.theme.colors.main};
    font-weight: 100;
  }

  p {
    font-weight: 100;
    margin-top: 0;
  }

  .site-card-map {
    height: 300px;
  }
`;
