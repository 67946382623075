import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBulkOperations } from 'store/actions/bulkOperations';
import { BulkOperation, BULK_OPERATION_STATUS } from 'store/models/bulkOperation';
import { useBulkOperations } from 'store/selectors/bulkOperations';

export function usePollOperation() {
  const dispatch = useDispatch();
  const bulkOperations = useBulkOperations();
  const [bulkOperationId, setBulkOperationId] = useState<BulkOperation['id'] | null>(null);
  const [polling, setPolling] = useState(false);
  const callbackRef = useRef<(() => Promise<void>) | null>(null);
  const pollingTimeout = useRef<number | null>(null);

  const pollOperation = useCallback((opId: BulkOperation['id'], cb: () => Promise<void>) => {
    void cb();
    callbackRef.current = cb;
    setBulkOperationId(opId);
  }, []);

  const clearPollingTimeout = useCallback(() => {
    if (pollingTimeout.current) {
      clearTimeout(pollingTimeout.current);
      pollingTimeout.current = null;
    }
  }, []);

  const clearPollOperation = useCallback(() => {
    callbackRef.current = null;
    setBulkOperationId(null);
    setPolling(false);
    clearPollingTimeout();
  }, [clearPollingTimeout]);

  const executePollingOperation = useCallback(async () => {
    if (!callbackRef.current) {
      return;
    }

    setPolling(true);
    await dispatch(getBulkOperations());
    await callbackRef.current();

    pollingTimeout.current = window.setTimeout(() => {
      setPolling(false);
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    const operation = bulkOperations.entity.data?.find((bulkOp) => bulkOp.id === bulkOperationId);
    if (operation && operation.status !== BULK_OPERATION_STATUS.PROGRESS) {
      clearPollOperation();
    }
  }, [bulkOperationId, bulkOperations.entity.data, clearPollOperation]);

  useEffect(() => {
    if (!bulkOperationId || polling) {
      return;
    }

    void executePollingOperation();

    return () => {
      clearPollingTimeout();
    };
  }, [bulkOperationId, clearPollingTimeout, executePollingOperation, polling]);

  return { pollOperation, clearPollOperation };
}
