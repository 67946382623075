import { useUserSelector } from 'store/selectors/user';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editMe } from 'store/actions/users';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { Button } from '@athonet/ui/components/Input/Button';
import { useCallback } from 'react';

type FormValues = {
  fullname: string;
  phone: string;
  email: string;
};

export default function Edituser() {
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const dispatch = useDispatch();
  const { dialogClose } = useOverlay();

  const handleEditUser = async (values: FormValues) => {
    dispatch(editMe(values));
    dispatch(dialogClose());
  };

  // TODO ADD PHONE GOOGLE VALIDATION LIBRARY
  const schema = object().shape({
    fullname: string().required(),
    phone: string(),
    email: string().email().required(),
  });

  const getInputTextsError = useCallback(
    //TODOPS use unique hook
    (inputname, errors, touched) => ({
      label: formatMessage({ id: `users.form.user.${inputname}.label` }),
      placeholder: formatMessage({ id: `users.form.user.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `users.form.user.${inputname}.error` }),
      }),
    }),

    [formatMessage]
  );

  if (!user?.fullname || !user?.phone || !user?.email) {
    return null;
  }

  const initials = { fullname: user.fullname, phone: user.phone, email: user.email };

  return (
    <Formik initialValues={initials} onSubmit={handleEditUser} validationSchema={schema} enableReinitialize>
      {({ isSubmitting, errors, touched }) => {
        return (
          <Form noValidate autoComplete="off">
            <DialogContent>
              <Stack fullWidth spacing={2} sx={{ pt: 2 }}>
                <Field name="fullname">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('fullname', errors, touched)}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('phone', errors, touched)}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('email', errors, touched)}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={formatMessage({ id: 'common.form.continue' })}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
