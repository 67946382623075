import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';

const UsimStatus = ({ status, error, updating }) => {
  let statusType;
  switch (status) {
    case 'provisioned':
    case 'active':
      statusType = STATUS.SUCCESS;
      break;
    case 'deprovisioning':
    case 'provisioning':
    case 'activating':
    case 'deactivating':
    case 'barring':
    case 'suspending':
      statusType = STATUS.INFO;
      break;
    case 'inactive':
    case 'barred':
    case 'unavailable':
      statusType = STATUS.WARNING;
      break;
    case 'error':
    case 'suspended':
      statusType = STATUS.ERROR;
      break;
    default:
      statusType = STATUS.DEFAULT;
  }
  if (error) {
    statusType = STATUS.ERROR;
  }
  if (updating) {
    statusType = STATUS.INFO;
  }
  return <Status status={statusType} label={humanizeSnakeCase(status)} asChip />;
};

export default UsimStatus;
