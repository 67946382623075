import { AnyAction } from 'redux';
import { List, listFailure, listIdle, listLoading, listSuccess } from '.';
import { PLMNS_ACTION_TYPE } from 'store/actions/plmns';
import { PLMN } from 'store/models/plmn';

export type PlmnsState = {
  list: List<PLMN>;
};

const initialState: PlmnsState = {
  list: listIdle<PLMN>(),
};

export default function plmnsPageReducer(state = initialState, action: AnyAction): PlmnsState {
  switch (action.type) {
    case PLMNS_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };
    case PLMNS_ACTION_TYPE.LIST_SUCCESS:
      const { items, total_items, page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ...listSuccess({
            data: items,
            total: total_items,
            page,
          }),
        },
      };

    case PLMNS_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...listFailure(),
        },
      };
    case PLMNS_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
