import { Formik, FormikConfig } from 'formik';
import React, { createContext, useContext } from 'react';
import { ObjectSchema, object } from 'yup';

interface BaseFormikProps {
  validationSchema: ObjectSchema<any>;
  validationPrefix?: string;
  children: React.ReactNode;
}

interface BaseFormikContextType {
  validationSchema: ObjectSchema<any>;
  /** specify a default common prefix to automatically grab translations */
  validationPrefix?: string;
}

const BaseFormikContext = createContext<BaseFormikContextType>({
  validationSchema: object(),
  validationPrefix: '',
});

export const useBaseFormikContext = () => useContext(BaseFormikContext);

/**
 * The `BaseFormik` function is a wrapper component that provides additional context and props to the
 * `Formik` component: validationPrefix and validationSchema */
const BaseFormik: React.FC<BaseFormikProps & FormikConfig<any>> = (props) => {
  const { validationSchema, validationPrefix, initialValues, innerRef, children, ...formikProps } = props;

  const contextValue = {
    validationSchema,
    validationPrefix,
  };

  return (
    <BaseFormikContext.Provider value={contextValue}>
      <Formik {...formikProps} validationSchema={validationSchema} initialValues={initialValues} innerRef={innerRef}>
        {children}
      </Formik>
    </BaseFormikContext.Provider>
  );
};

export default BaseFormik;
