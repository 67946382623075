export type DiagnosticInfo = {
  connected: boolean | null;
  imei: string | null;
  ip: string | null;
  rat: string | null;
  last_connection_timestamp: string | null;
};

export type DiagnosticOperator = {
  mcc?: string | null;
  mnc?: string | null;
  operator: string | null;
  country: string | null;
};

export type DiagnosticNWConnectivityEvent = {
  type: CONNECTIVITY_EVENT_TYPE | null;
  timestamp: string | null;
  evt: CreateEvent | DeleteEvent | AuthEvent | null;
};

type GTP = {
  'addr-out': string | null;
  'teid-in': number | null;
  'addr-in': string | null;
  'teid-out': number | null;
};

type CreateEvent = {
  user_addr: string[];
  rat: string | null;
  'gtp-u-downlink': GTP;
  imei: string | null;
  cause: string;
  imsi: string | null;
  bearer_id: number | null;
  msisdn: string | null;
  interface: string | null;
  type: CONNECTIVITY_EVENT_TYPE.CREATE_SESSION;
  apn: string | null;
  'gtp-c-downlink': GTP;
};

type DeleteEvent = {
  user_addr: string[];
  rat: string | null;
  'gtp-u-downlink': GTP;
  imei: string | null;
  imsi: string | null;
  bearer_id: number | null;
  msisdn: string | null;
  interface: string | null;
  type: CONNECTIVITY_EVENT_TYPE.DELETE_SESSION;
  apn: string | null;
  'gtp-c-downlink': GTP;
};

type AuthEvent = {
  srv: string | null;
  cause: string;
  imsi: string | null;
  type: CONNECTIVITY_EVENT_TYPE.AUTH;
  apn: string | null;
  username: string | null;
};

export enum CONNECTIVITY_EVENT_TYPE {
  CREATE_SESSION = 'create_session',
  DELETE_SESSION = 'delete_session',
  AUTH = 'auth',
}

export enum NW_EVENT_SUCCESS_CAUSE {
  ACCESS_ACCEPT = 'ACCESS_ACCEPT',
  ACCEPT = 'ACCEPT',
}
