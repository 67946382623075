import { Formik, Form, Field, FieldProps } from 'formik';
import { string, object } from 'yup';
import { useIntl } from 'react-intl';
import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { useCallback, useEffect, useState } from 'react';
import { useBootstrapSelector, useDefaultAuthProviderSelector } from 'store/selectors/bootstrap';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config';
import { fetchRequest } from 'store/actions/fetchData';
import { useDispatch } from 'react-redux';

export type LoginFormProps = {
  onSubmit: (tenants: AxiosResponse<any, any>, authData: unknown, actions: unknown) => void;
  onForgot: () => void;
};

export default function LoginForm({ onSubmit, onForgot }: LoginFormProps) {
  const { formatMessage } = useIntl();
  const bootstrap = useBootstrapSelector();
  const defaultAuthProvider = useDefaultAuthProviderSelector();
  const [signinMode, setSigninMode] = useState<AUTH_PROVIDER | null>(null);
  const { locale } = useIntl();
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const schema = object().shape({
    user: string().when('auth_provider', (authProviderValue) => {
      return authProviderValue === AUTH_PROVIDER.LDAP
        ? string().required(formatMessage({ id: 'login.ldapUsernameError' }))
        : string()
            .email(formatMessage({ id: 'login.emailError' }))
            .required(formatMessage({ id: 'login.emailError' }));
    }),
    password: string().when('auth_provider', (authProviderValue) => {
      return authProviderValue === AUTH_PROVIDER.LDAP
        ? string().required(formatMessage({ id: 'login.ldapPasswordError' }))
        : string()
            .min(8, formatMessage({ id: 'login.enterprisePasswordError' }))
            .required(formatMessage({ id: 'login.enterprisePasswordError' }));
    }),

    auth_provider: string().oneOf(Object.values(AUTH_PROVIDER)).required(),
  });

  useEffect(() => {
    if (defaultAuthProvider) {
      setSigninMode(defaultAuthProvider);
    }
  }, [defaultAuthProvider]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        const data = new FormData();
        data.append('user', values.user);
        data.append('password', values.password);
        data.append('lang', locale); // NOTE: not used by the backend
        data.append('auth_provider', values.auth_provider);

        const options: AxiosRequestConfig = {
          url: config.apis.authenticate,
          method: 'POST',
          data,
        };
        const result = await dispatch(fetchRequest<unknown>(options, false));
        onSubmit(result, values, actions);
      } catch (e: any) {
        const errorRes = e.response?.data?.error;
        switch (errorRes) {
          case 'no-user':
            setError('login.noUser');
            break;
          case 'unauthorized':
            setError(`login.errorMessage.${values.auth_provider}`);
            break;
          default:
            setError('login.errorMessage');
            break;
        }
        actions.resetForm();
        actions.setFieldValue('user', values.user);
        actions.setFieldValue('auth_provider', values.auth_provider);
      }
    },
    [dispatch, locale, onSubmit]
  );

  if (!bootstrap || !signinMode) return null;

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.title' })}
      description={formatMessage({ id: 'login.subtitle' })}
      data-testid="loginForm"
    >
      {error && <Alert severity="error" message={formatMessage({ id: error })} />}
      <Formik
        initialValues={{ user: '', password: '', auth_provider: signinMode }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, touched, errors, resetForm, setFieldValue }) => (
          <>
            {Array.isArray(bootstrap.auth_providers) && bootstrap.auth_providers.length > 1 && (
              <Box sx={{ mb: 2 }} data-testid="auth-tabs">
                <Tabs
                  onChange={(v) => {
                    resetForm();
                    setFieldValue('auth_provider', v);
                    setSigninMode(v as AUTH_PROVIDER);
                    setError('');
                  }}
                  value={signinMode}
                  tabs={bootstrap.auth_providers.map((provider) => ({
                    label: formatMessage({ id: `authProvider.${provider}` }),
                    value: provider,
                  }))}
                />
              </Box>
            )}
            <Form noValidate autoComplete="off">
              <Field name="user">
                {({ field }: FieldProps<string>) => (
                  <Box sx={{ width: '100%', height: '88px' }}>
                    <TextField
                      fullWidth
                      {...field}
                      label={formatMessage({
                        id: `login.${signinMode}Label`,
                      })}
                      placeholder={formatMessage({ id: `login.${signinMode}UserPlaceholder` })}
                      error={Boolean(touched['user'] && errors['user'])}
                      {...(touched['user'] && errors['user'] && { helperText: errors['user'] })}
                    />
                  </Box>
                )}
              </Field>

              <Field name="password">
                {({ field }: FieldProps<string>) => (
                  <Box sx={{ width: '100%', height: '88px' }}>
                    <TextField
                      fullWidth
                      {...field}
                      type="password"
                      showPasswordVisibility
                      label={formatMessage({ id: 'login.passwordLabel' })}
                      placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                      error={Boolean(touched['password'] && errors['password'])}
                      {...(touched['password'] && errors['password'] && { helperText: errors['password'] })}
                    />
                  </Box>
                )}
              </Field>
              <Stack spacing={2}>
                <Button
                  size="large"
                  data-testid="loginForm-submitButton"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  text={formatMessage({ id: `login.${signinMode}SubmitButton` })}
                  color="secondary"
                  type="submit"
                />

                {signinMode === AUTH_PROVIDER.ENTERPRISE && bootstrap.forgot_password && (
                  <Link data-testid="loginForm-forgotLink" onClick={onForgot}>
                    <Text align="center">{formatMessage({ id: 'login.forgotPasswordLink' })}</Text>
                  </Link>
                )}
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </AuthPanel>
  );
}
