import { Dispatch, SetStateAction, useCallback } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@athonet/ui/components/Input/Button';

type DeleteQuestionProps = {
  id: string;
  onDelete: (index: number, id: string) => void;
  isDeleteSure: boolean;
  setDeleteSure: Dispatch<SetStateAction<number | undefined>>;
  isUpdating: boolean;
  index: number;
};

export default function DeleteQuestion({
  id,
  onDelete,
  isDeleteSure,
  setDeleteSure,
  isUpdating,
  index,
}: DeleteQuestionProps) {
  const { formatMessage } = useIntl();

  const confirmDelete = useCallback(() => setDeleteSure(index), [index, setDeleteSure]);
  const cancelDelete = useCallback(() => setDeleteSure(undefined), [setDeleteSure]);
  const handleDelete = useCallback(() => onDelete(index, id), [index, id, onDelete]);

  return isDeleteSure && !isUpdating ? (
    <>
      <Button color="error" size="small" onClick={handleDelete} text={formatMessage({ id: 'delete.form.textShort' })} />
      <IconButton edge="end" aria-label="delete">
        <CloseIcon onClick={cancelDelete} />
      </IconButton>
    </>
  ) : (
    <IconButton disabled={isUpdating} edge="end" aria-label="delete">
      <DeleteIcon onClick={confirmDelete} />
    </IconButton>
  );
}
