import { useDispatch } from 'react-redux';
import { useLandingSelector } from 'store/selectors/landing';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { getLandingPageTenants4g, getLandingPageTenants5g } from 'store/actions/landing';
import { useCallback, useEffect, useMemo } from 'react';
import { LandingPageTenantsinfo } from 'store/models/landing';
import { useIntl } from 'react-intl';
import { TENANT_TYPE } from 'store/models/tenant';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { isListLoading } from 'store/reducers';

export function DeploymentOverview() {
  const { tenants4gInfo, tenants5gInfo } = useLandingSelector();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const bootstrap = useBootstrapSelector();

  useEffect(() => {
    if (bootstrap?.athonetos_support_enabled) {
      dispatch(getLandingPageTenants5g());
    }
    if (bootstrap?.athux_support_enabled) {
      dispatch(getLandingPageTenants4g());
    }
  }, [dispatch, bootstrap]);

  const getTenantChipTextByType = useCallback(
    (type: TENANT_TYPE): string => {
      switch (type) {
        case TENANT_TYPE.MASTER:
          return formatMessage({ id: 'common.master' });
        case TENANT_TYPE.CHANNEL_PARTNER:
          return 'CP';
        case TENANT_TYPE.NETWORK_MANAGER:
          return 'NWM';
      }
    },
    [formatMessage]
  );
  const nodeColumns: TableColumn<LandingPageTenantsinfo>[] = [
    {
      key: 'name',
      label: 'Tenant',
      width: '200',
    },
    {
      key: 'type',
      label: '',
      maxWidth: '100',
      width: '100',
      cellRender: ({ id, type }) => {
        return <Chip id={id} size="small" color="secondary" label={getTenantChipTextByType(type)} />;
      },
    },
    {
      key: 'total_nodes',
      label: '# Nodes',
      numeric: true,
    },
    {
      key: 'total_usims',
      label: '# Sims',
      numeric: true,
    },
  ];

  const tableData = useMemo(
    () => [...tenants4gInfo.data.data, ...tenants5gInfo.data.data],
    [tenants4gInfo, tenants5gInfo]
  );

  const areTenantsLoading = useMemo(
    () =>
      (bootstrap?.athonetos_support_enabled && isListLoading(tenants5gInfo)) ||
      (bootstrap?.athux_support_enabled && isListLoading(tenants4gInfo)),
    [bootstrap, tenants4gInfo, tenants5gInfo]
  );

  return (
    <Stack sx={{ height: { xs: '500px', md: 'calc(100% - 16px)' } }} direction="column" spacing={2} nowrap>
      <Text type="h5" fontWeight={600} sx={{ mb: -1 }}>
        Deployment Overview
      </Text>
      <Divider color="secondary" />
      <Panel sx={{ height: '100%' }}>
        <Table
          orderBy="id"
          columns={nodeColumns}
          data={tableData}
          rowsCount={tableData.length}
          page={0}
          rowsPerPage={50}
          rowKey="name"
          autoScale
          loading={areTenantsLoading}
        />
      </Panel>
    </Stack>
  );
}
