import { Table, TableColumn, TableRowAction } from '@athonet/ui/components/Data/Table';
import { BillingConfig } from 'store/models/billing';
import { useCallback, useMemo } from 'react';
import { BillingConfigurationsState } from 'store/reducers/billing';
import { useBillingConfigurations, useFilterBillingConfigurations } from 'store/selectors/billing';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useIntl } from 'react-intl';
import { isEntityLoading } from 'store/reducers';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import BillingConfigurationForm from './BillingConfigurationForm';
import { deleteBillingConfigurations } from 'store/actions/billing/billingConfiguration';
import { U_PERMISSIONS, checkPermissionToUse } from 'utils/permissionCodes';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AccordionGroup } from '@athonet/ui/components/Surfaces/AccordionGroup';
import { Accordion } from '@athonet/ui/components/Surfaces/Accordion';
import moment from 'moment';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { Icon54 } from '@athonet/ui/components/Guidelines/Icon/catalog';

export default function BillingTable() {
  const billingConfigurations = useBillingConfigurations();
  const filterBillingConfigurations = useFilterBillingConfigurations();
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.user);
  const { formatMessage } = useIntl();
  const { drawerOpen, confirmationDialogOpen } = useOverlay();

  const billingColumns = useMemo((): TableColumn<BillingConfig>[] => {
    return [
      {
        key: 'active',
        label: '',
        cellRender: ({ active, start_date }) => (
          <Status
            label=""
            status={active ? STATUS.SUCCESS : moment(start_date).isBefore(moment()) ? STATUS.DEFAULT : STATUS.INFO}
          />
        ),
      },
      {
        key: 'start_date',
        label: formatMessage({ id: 'billing.table.startDate' }),
      },
      {
        key: 'operator',
        label: formatMessage({ id: 'billing.table.operator' }),
      },
      {
        key: 'cost_per_gb',
        label: formatMessage({ id: 'billing.table.cost' }),
        numeric: true,
        cellRender: ({ cost_per_gb }) => <Text type="body2">{`€${cost_per_gb}`}</Text>,
      },
      {
        key: 'alarm_threshold',
        label: formatMessage({ id: 'billing.table.alarm' }),
        numeric: true,
        cellRender: ({ alarm_threshold }) => <Text type="body2">{alarm_threshold ? `${alarm_threshold}%` : ''}</Text>,
      },
      {
        key: 'prepaid_bundle',
        label: formatMessage({ id: 'billing.table.prepaid' }),
        numeric: true,
        cellRender: ({ prepaid_bundle }) => <Text type="body2">{prepaid_bundle ? `€${prepaid_bundle}` : ''}</Text>,
      },
      {
        key: 'sim_minimum_pay',
        label: formatMessage({ id: 'billing.table.sim' }),
        numeric: true,
        cellRender: ({ sim_minimum_pay }) => <Text type="body2">{sim_minimum_pay ? `€${sim_minimum_pay}` : ''}</Text>,
      },
    ];
  }, [formatMessage]);

  const filterBillingConfigurationsDetail = useCallback(
    (data: BillingConfigurationsState | null, row: BillingConfig) => {
      return (
        data?.filter((item: { operator: string }) => {
          return item.operator.toLowerCase() === row.operator.toLowerCase();
        }) || []
      );
    },
    []
  );

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(deleteBillingConfigurations(id));
    },
    [dispatch]
  );

  const enableRowActions = useMemo((): TableRowAction<BillingConfig<string>>[] => {
    const edit_icon: Icon54 = 'Edit-Note';
    const delete_icon: Icon54 = 'Delete-Note';
    return [
      ...(checkPermissionToUse(userdata, U_PERMISSIONS.UPDATE_BILLING)
        ? [
            {
              label: 'Edit', //TODO: intl
              iconName: edit_icon,
              disabled: (row: BillingConfig) => {
                return row.read_only;
              },
              onClick(row: BillingConfig) {
                drawerOpen({
                  title: `Edit Configuration`, //TODO: intl
                  content: () => <BillingConfigurationForm billingConfig={row} />,
                });
              },
            },
          ]
        : []),
      ...(checkPermissionToUse(userdata, U_PERMISSIONS.DELETE_BILLING)
        ? [
            {
              label: 'Delete', //TODO: intl
              iconName: delete_icon,
              disabled: (row: BillingConfig) => {
                return row.read_only;
              },
              onClick(row: BillingConfig) {
                return confirmationDialogOpen({
                  title: formatMessage({ id: 'billing.actions.delete.confirm.title' }),
                  description: formatMessage({ id: 'billing.actions.delete.confirm' }),
                  alertMessage: formatMessage({ id: 'billing.actions.itemsAffected' }, { element: row.operator }),
                  continueButtonText: formatMessage(
                    { id: 'billing.actions.delete.confirm.continueButton' },
                    { elements: 1 }
                  ),
                  onConfirm: async () => handleDelete(row.id),
                  severity: 'danger',
                  dataTestid: 'confirm-delete-billingConfiguration',
                });
              },
            },
          ]
        : []),
    ];
  }, [handleDelete, confirmationDialogOpen, drawerOpen, formatMessage, userdata]);

  const billingDetailView = useCallback(
    (rowBilling: BillingConfig) => {
      const filterBillingConfigData = filterBillingConfigurationsDetail(billingConfigurations.data, rowBilling).sort(
        (a: BillingConfig, b: BillingConfig) => {
          return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
        }
      );
      return (
        <Table
          data={filterBillingConfigData}
          columns={billingColumns}
          rowsCount={filterBillingConfigData.length}
          rowsPerPage={filterBillingConfigData.length}
          orderBy={'start_date'}
          rowKey={'id'}
          page={0}
          loading={isEntityLoading(billingConfigurations)}
          data-testid="billingconfiguration-rowdetail-table"
          rowActions={enableRowActions}
        />
      );
    },
    [billingColumns, billingConfigurations, enableRowActions, filterBillingConfigurationsDetail]
  );

  return (
    <AccordionGroup exclusive={false} spacing={2}>
      {filterBillingConfigurations?.map((row, i) => {
        return <Accordion title={row.operator} content={billingDetailView(row)} key={i} />;
      })}
    </AccordionGroup>
  );
}
