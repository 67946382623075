import { CloseModalAction, MODAL_ACTION_TYPE, OpenModalAction } from 'store/actions/modal';
import { EditUserModal, ManageTenantsModal, MODAL_ID } from 'store/models/modal';

export type ModalState = {
  manageTenants: ManageTenantsModal | null;
  editUserModal: EditUserModal | null;
};

const initialState: ModalState = {
  manageTenants: null,
  editUserModal: null,
};

export default function modalReducer(state = initialState, action: OpenModalAction | CloseModalAction): ModalState {
  switch (action.type) {
    case MODAL_ACTION_TYPE.OPEN_MODAL:
      switch (action.payload.id) {
        case MODAL_ID.MANAGE_TENANTS:
          return {
            ...state,
            manageTenants: action.payload,
          };
        case MODAL_ID.EDIT_USER:
          return {
            ...state,
            editUserModal: action.payload,
          };
        default:
          return initialState;
      }

    case MODAL_ACTION_TYPE.CLOSE_MODAL:
      return initialState;

    default:
      return state;
  }
}
