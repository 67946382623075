import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { showErrorToast, showSuccessToast } from '../toast';

type StringError = {
  error: string;
};

type DeleteUsimOrderPayload = {
  id: string;
};

export const deleteUsimOrder = createAsyncThunk<
  void,
  DeleteUsimOrderPayload,
  {
    rejectValue: StringError;
    state: State;
  }
>('usimOrders/delete', async ({ id }, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: `${config.apis.deleteUsimOrder.replace('{id}', id)}`,
      method: 'DELETE',
    };
    const response = await dispatch(fetchData<void>(options));
    dispatch(showSuccessToast());
    return response;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue({ error: '' });
  }
});
