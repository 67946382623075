import {
  getCdrImeiChangesRanking,
  getCdrImeiChangesRankingSeries,
  setCdrImeiChangesCalendarDate,
  setCdrImeiChangesFilter,
} from 'store/actions/cdrReports/imeiChanges';
import { useDispatch } from 'react-redux';
import {
  useCdrImeiChangesPeriod,
  useCdrImeiChangesRanking,
  useCdrImeiChangesRankingCalendarDate,
  useCdrImeiChangesRankingGroupBy,
  useCdrImeiChangesRankingMetric,
  useCdrImeiChangesRankingSeries,
  useCdrImeiChangesSchema,
  useImeiChangesDateRange,
} from 'store/selectors/cdrReports/imeiChanges';
import { useCallback, useEffect, useMemo } from 'react';
import { Moment } from 'moment';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Calendar } from '@athonet/ui/components/Data/Calendar';
import { isEntityLoading } from 'store/reducers';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Ranking, RankingData, TRAFFIC_RANK } from '@athonet/ui/components/Data/Ranking';
import { useNavigate } from 'react-router-dom';
import { CdrImeiChangesMetricsField } from 'store/models/cdr';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { TimeLineDrawerContent } from './TimeLineDrawerContent';
import { getTooltipDateTitle } from '../utils/utils';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import {
  getCdrActiveSourceNetworkAggregation,
  useCdrActiveSourceNetwork,
} from 'store/selectors/cdrReports/sourceNetwork';

export function ImeiChangesRanking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { drawerOpen } = useOverlay();
  const imeiChangesSchema = useCdrImeiChangesSchema();
  const imeiChangesRankingCalendarDate = useCdrImeiChangesRankingCalendarDate();
  const imeiChangesPeriod = useCdrImeiChangesPeriod();
  const imeiChangesSeries = useCdrImeiChangesRankingSeries();
  const imeiChangesRankingGroupBy = useCdrImeiChangesRankingGroupBy();
  const imeiChangesDateRange = useImeiChangesDateRange();
  const imeiChangesRanking = useCdrImeiChangesRanking();
  const imeiChangesRankingMetric = useCdrImeiChangesRankingMetric();
  const activeSourceNetwork = useCdrActiveSourceNetwork();

  const handleDrawerOpen = useCallback(
    (e: any) => {
      if (!imeiChangesDateRange) {
        return;
      }

      const value: string = e.currentTarget.value;
      drawerOpen({
        title: `Historic IMEI Changes`,
        content: () => <TimeLineDrawerContent imsi={value} />,
        width: 360,
      });
    },
    [drawerOpen, imeiChangesDateRange]
  );

  useEffect(() => {
    if (!imeiChangesDateRange || !activeSourceNetwork) {
      return;
    }
    dispatch(
      getCdrImeiChangesRankingSeries({
        start_date: imeiChangesDateRange.start,
        end_date: imeiChangesDateRange.end,
        period: imeiChangesPeriod,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [imeiChangesDateRange, dispatch, imeiChangesPeriod, activeSourceNetwork]);

  useEffect(() => {
    if (!imeiChangesRankingCalendarDate || !activeSourceNetwork) {
      return;
    }

    dispatch(
      getCdrImeiChangesRanking({
        date: imeiChangesRankingCalendarDate,
        period: imeiChangesPeriod,
        group_by: imeiChangesRankingGroupBy,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [dispatch, imeiChangesPeriod, imeiChangesRankingCalendarDate, imeiChangesRankingGroupBy, activeSourceNetwork]);

  const imeiChangesChartRanking = useMemo(() => {
    if (!imeiChangesRanking.data) {
      return;
    }

    const top = imeiChangesRanking.data.top.map((x) => ({
      field: x.group_by.value,
      value: x.metric.value,
    }));

    const bottom = imeiChangesRanking.data.bottom?.map((x) => {
      return {
        field: x.group_by.value,
        value: x.metric.value,
      };
    });

    const maxValue = Math.max(...top.map((x) => x.value));
    return { top, bottom, maxValue };
  }, [imeiChangesRanking]);

  const handleOnClickValue = useCallback(
    (item: RankingData) => {
      dispatch(
        setCdrImeiChangesFilter({
          group_by: imeiChangesRankingGroupBy,
          group_filter: item.field,
        })
      );
      navigate('/reports/imei-changes/trend');
    },
    [dispatch, imeiChangesRankingGroupBy, navigate]
  );

  const selectedCalendarDate = useMemo(() => {
    const format = getTooltipDateTitle(imeiChangesPeriod);
    return imeiChangesRankingCalendarDate?.format(format);
  }, [imeiChangesPeriod, imeiChangesRankingCalendarDate]);

  const rankingGroupByLabel = useMemo(() => {
    return imeiChangesSchema.data?.schema.find((item) => item.field === imeiChangesRankingGroupBy)?.label || '';
  }, [imeiChangesRankingGroupBy, imeiChangesSchema]);

  const calendarData: [Moment, number][] | undefined = useMemo(() => {
    return imeiChangesSeries.data?.map(({ datetime, ...metric }) => {
      return [
        datetime,
        metric[imeiChangesRankingMetric as CdrImeiChangesMetricsField] !== null
          ? (metric[imeiChangesRankingMetric] as number)
          : 0,
      ];
    });
  }, [imeiChangesRankingMetric, imeiChangesSeries.data]);

  const handleCalendarClick = useCallback(
    (_, date) => {
      dispatch(setCdrImeiChangesCalendarDate(date));
    },
    [dispatch]
  );

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <span data-test-id="calendar-panel" style={{ flexGrow: 1 }}>
          <Panel
            title={'IMEI Changes Volume Calendar'}
            tooltip={`Pick a ${imeiChangesPeriod} on the Calendar to view the IMEI Changes Ranking results`}
            collapsible
          >
            <PanelContent>
              {isEntityLoading(imeiChangesSeries) ? (
                <Skeleton height={150} variant="rectangular" />
              ) : (
                <Calendar
                  period={imeiChangesPeriod}
                  data={calendarData}
                  onClick={handleCalendarClick}
                  formatTooltipValue={(number) => number.toLocaleString()}
                  data-testid="cdr-imei-changes-ranking-calendar"
                />
              )}
            </PanelContent>
          </Panel>
        </span>
      </GridItem>
      {imeiChangesRankingCalendarDate && (
        <GridItem
          size={{
            xs: 12,
          }}
        >
          <span data-test-id="ranking-grid" style={{ flexGrow: 1 }}>
            <Panel
              title={'IMEI Changes Ranking'}
              tooltip={`IMEI Changes Ranking displays most/least IMEI changes corresponding to the ${imeiChangesPeriod} selected in the Imei Changes Volume Calendar`}
              headerActionsComponent={
                <Chip
                  id={selectedCalendarDate || ''}
                  label={selectedCalendarDate}
                  color="secondary"
                  data-testid="cdr-imei-changes-ranking-date-chip"
                />
              }
            >
              <PanelContent title={'Most changes'}>
                <Ranking
                  data={imeiChangesChartRanking?.top || []}
                  maxValue={imeiChangesChartRanking?.maxValue || 0}
                  minValue={0}
                  fieldLabel={rankingGroupByLabel}
                  valueLabel={'Changes'}
                  loading={isEntityLoading(imeiChangesRanking)}
                  onClickValue={handleOnClickValue}
                  iconName={'Change'}
                  onClickIcon={handleDrawerOpen}
                  data-testid="cdr-imei-changes-ranking-most"
                />
              </PanelContent>
              <>
                {imeiChangesChartRanking?.bottom ? (
                  <PanelContent title={'Least changes'}>
                    <Ranking
                      rank={TRAFFIC_RANK.BOTTOM}
                      data={imeiChangesChartRanking?.bottom || []}
                      maxValue={imeiChangesChartRanking?.maxValue || 0}
                      minValue={0}
                      fieldLabel={rankingGroupByLabel}
                      valueLabel={'Changes'}
                      loading={isEntityLoading(imeiChangesRanking)}
                      onClickValue={handleOnClickValue}
                      iconName={'Change'}
                      onClickIcon={handleDrawerOpen}
                      data-testid="cdr-imei-changes-ranking-least"
                    />
                  </PanelContent>
                ) : null}
              </>
            </Panel>
          </span>
        </GridItem>
      )}
    </GridContainer>
  );
}
