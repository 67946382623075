import { useCallback, useMemo } from 'react';
import { string } from 'yup';
import { useIntl } from 'react-intl';
import { filtersSet } from 'store/actions/qosProfiles';
import { useDispatch } from 'react-redux';
import { FiltersProps, getDefaultValue } from 'store/models/filters';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { FiltersDrawer, FiltersDrawerItem } from '../Drawer';

export default function QoSProfiles({ values }: FiltersProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'name',
        label: formatMessage({ id: 'qosProfiles.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'status',
        label: formatMessage({ id: 'qosProfiles.filters.status' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['status'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'qosProfiles.status.in_stock' }), value: 'in_stock' },
          { label: formatMessage({ id: 'qosProfiles.status.ready' }), value: 'ready' },
          { label: formatMessage({ id: 'qosProfiles.status.updating' }), value: 'updating' },
          { label: formatMessage({ id: 'qosProfiles.status.error' }), value: 'error' },
        ],
      },
      {
        name: 'qci',
        label: formatMessage({ id: 'qosProfiles.filters.qci' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['qci'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { value: 5, label: '5' },
          { value: 6, label: '6' },
          { value: 7, label: '7' },
          { value: 8, label: '8' },
          { value: 9, label: '9' },
          { value: 65, label: '65' },
          { value: 66, label: '66' },
          { value: 69, label: '69' },
          { value: 70, label: '70' },
        ],
      },
      {
        name: 'vplmn_dynamic_address_allowed',
        label: formatMessage({ id: 'qosProfiles.filters.vplmn_dynamic_address_allowed' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['vplmn_dynamic_address_allowed'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { value: 1, label: formatMessage({ id: 'common.form.allowed' }) },
          { value: 0, label: formatMessage({ id: 'common.form.notAllowed' }) },
        ],
      },
      // {
      //   name: 'ambr_ul',
      //   label: formatMessage({ id: 'qosProfiles.filters.ambr_ul' }),
      //   error: formatMessage({ id: 'qosProfiles.filters.ambr_ul.error' }),
      //   type: 'number',
      //   schema: number(),

      // },
      // {
      //   name: 'ambr_dl',
      //   label: formatMessage({ id: 'qosProfiles.filters.ambr_dl' }),
      //   error: formatMessage({ id: 'qosProfiles.filters.ambr_dl.error' }),
      //   type: 'number',
      //   schema: number(),

      // },
      {
        name: 'priority',
        label: formatMessage({ id: 'qosProfiles.filters.priority' }),
        // error: formatMessage({ id: 'qosProfiles.filters.priority.error' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['priority'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
      {
        name: 'preemption_capability',
        label: formatMessage({ id: 'qosProfiles.filters.preemption_capability' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['preemption_capability'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { value: 0, label: formatMessage({ id: 'common.form.enabled' }) },
          { value: 1, label: formatMessage({ id: 'common.form.disabled' }) },
        ],
      },
      {
        name: 'preemption_vulnerability',
        label: formatMessage({ id: 'qosProfiles.filters.preemption_vulnerability' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['preemption_vulnerability'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { value: 0, label: formatMessage({ id: 'common.form.enabled' }) },
          { value: 1, label: formatMessage({ id: 'common.form.disabled' }) },
        ],
      },
      /*{
      name: "node_id",
      label: formatMessage({id:"qosProfiles.filters.node"}),
      placeholder: formatMessage({id:"qosProfiles.filters.node.placeholder"}),
      options: data.nodes,
      permissions: [T_PERMISSIONS.MASTER],
    },
  }*/
      // {
      //   name: 'tenant_id',
      //   label: formatMessage({ id: 'qosProfiles.filters.tenant' }),
      //   filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
      //   default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
      //   initial: values['tenant_id'],
      //   schema: string(),
      //   getInitialOptions: async () => dispatch(getTenantsAutocompleteOptions()),
      //   permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
      // },
    ],
    [formatMessage, values]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(filtersSet(filtersValues)), [dispatch]);

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
