import React from 'react';
import { TextFieldProps } from '@athonet/ui/components/Input/TextField';
import TextFieldComponent from './TextFieldComponent';
import BaseField, { BaseFieldProps } from './BaseField';

const TextFieldField: React.FC<TextFieldProps & BaseFieldProps> = (props) => {
  const { component, ...baseProps } = props;

  return <BaseField {...baseProps} component={component || TextFieldComponent} />;
};

export default TextFieldField;
