import { useIntl } from 'react-intl';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { useMemo } from 'react';

type ExportViewsProps = {
  downloadItems?: number;
  onSubmit: () => void;
};

export default function ExportViewsConfirm({ onSubmit, downloadItems }: ExportViewsProps) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const bootstrap = useBootstrapSelector();

  const handleSubmit = () => {
    onSubmit();
    dialogClose();
  };

  const isDisabled = useMemo(() => {
    return Boolean(downloadItems && bootstrap && downloadItems > bootstrap.max_selectable_items);
  }, [bootstrap, downloadItems]);

  return (
    <>
      <DialogContent>
        {isDisabled && (
          <Alert
            severity="warning"
            message={formatMessage({ id: 'export.exportDenied' }, { elements: bootstrap?.max_selectable_items })}
          />
        )}
        {downloadItems && <p>{formatMessage({ id: 'export.confirmRows' }, { elements: downloadItems })}</p>}

        <p>{formatMessage({ id: 'export.synchronousDownload' })}</p>

        <b>{formatMessage({ id: 'export.synchronousLoseProgress' })}</b>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={dialogClose}
          variant="outlined"
          data-testid="footer-cancel"
          text={formatMessage({ id: 'common.form.cancel' })}
        />
        <Button
          onClick={handleSubmit}
          data-testid="footer-continue"
          text={formatMessage({ id: 'common.form.continue' })}
          disabled={isDisabled}
        />
      </DialogActions>
    </>
  );
}
