import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { addTenantToUser, deleteTenantFromUser, getTenantsByUser } from 'store/actions/tenants';
import { SelectOption } from 'utils/forms';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
export default function useUserHandlers(
  selectOptions: { roles?: SelectOption[]; tenants?: SelectOption[] },
  id: string
) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const userSchema = object().shape({
    tenant: string().required(),
    roles: string().required(),
  });

  const handleGetTenantsByUser = useCallback(async () => {
    return dispatch(getTenantsByUser(id));
  }, [dispatch, id]);

  const userFormTitles = useMemo(
    () => ({
      modalTitle: formatMessage({ id: 'users.form.manageTenant' }),
      fieldsetTitle: formatMessage({ id: 'users.form.addTenants' }),
    }),
    [formatMessage]
  );

  const handleAddTenantToUser = useCallback(
    async (tenantId: string, roles: string) => {
      return dispatch(
        addTenantToUser(id, {
          tenant_id: tenantId,
          role: roles,
        })
      );
    },
    [dispatch, id]
  );

  const handleDeleteTenantFromUser = useCallback(
    async (tenantId: string) => {
      return dispatch(deleteTenantFromUser(id, tenantId));
    },
    [dispatch, id]
  );

  const userfields = useMemo(
    () => [
      {
        name: 'tenant',
        label: formatMessage({ id: 'users.form.tenant.label' }),
        placeholder: formatMessage({ id: 'users.form.tenant.placeholder' }),
        error: formatMessage({ id: 'users.form.tenant.error' }),
        options: selectOptions.tenants,
        gridClassName: 'fullwidth',
      },
      {
        name: 'roles',
        label: formatMessage({ id: 'users.form.roles.label' }),
        placeholder: formatMessage({ id: 'users.form.roles.placeholder' }),
        error: formatMessage({ id: 'users.form.roles.error' }),
        options: selectOptions.roles,
        gridClassName: 'fullwidth',
      },
    ],
    [formatMessage, selectOptions]
  );

  return {
    handleGetTenantsByUser,
    handleAddTenantToUser,
    handleDeleteTenantFromUser,
    userSchema,
    userFormTitles,
    userfields,
  };
}
