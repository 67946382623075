import { Formik, Form, Field, FieldProps } from 'formik';
import { string, object } from 'yup';
import { useIntl } from 'react-intl';
import { Button } from '@athonet/ui/components/Input/Button';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Link } from '@athonet/ui/components/Navigation/Link';

export type SendPasswordFormProps = {
  onSubmit: () => void;
  onBack: () => void;
};

const SendPasswordForm = ({ onSubmit, onBack }: SendPasswordFormProps) => {
  const { formatMessage } = useIntl();

  const schema = object().shape({
    email: string()
      .email()
      .required(formatMessage({ id: 'login.emailError' })),
  });

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.reset.title' })}
      description={formatMessage({ id: 'login.reset.subtitle' })}
      data-testid="sendPasswordForm"
    >
      <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={schema}>
        {({ isSubmitting, touched, errors, values }) => (
          <Form noValidate autoComplete="off">
            <Stack fullWidth spacing={2} sx={{ mt: 4 }}>
              <Field name="email">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    fullWidth
                    {...field}
                    label={formatMessage({ id: 'login.enterpriseLabel' })}
                    placeholder={formatMessage({ id: 'login.enterpriseUserPlaceholder' })}
                    error={Boolean(touched['email'] && errors['email'])}
                    {...(touched['email'] &&
                      errors['email'] && { helperText: formatMessage({ id: 'login.emailError' }) })}
                    disabled={isSubmitting}
                  />
                )}
              </Field>
              <Button
                color="secondary"
                size="large"
                data-testid="sendPasswordForm-submitButton"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                text={formatMessage({ id: 'login.reset.submitButton' })}
              />

              <Link data-testid="sendPasswordForm-backLink" onClick={onBack}>
                <Text align="center">{formatMessage({ id: 'login.reset.backButton' })}</Text>
              </Link>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthPanel>
  );
};

export default SendPasswordForm;
