import React from 'react';
import { FileUpload, FileUploadProps } from '@athonet/ui/components/Input/FileUpload';
import { FieldProps } from 'formik';

export type FileUploadComponentProps = FieldProps & FileUploadProps;

const FileUploadComponent: React.FC<FileUploadComponentProps> = (props) => {
  const {
    form: { setFieldValue, touched, errors },
    field,
    meta,
    children,
    onChange,
    maxSize,
    ...otherProps
  } = props;

  const defaultMaxSize = 10 * 1024 ** 2;
  const formError = Boolean(errors[field.name]) && touched[field.name];

  return (
    <FileUpload
      {...otherProps}
      size="small"
      maxSize={defaultMaxSize || maxSize}
      name={field.name}
      value={field.value} //value={field.value ? [field.value] : []}
      formError={formError ? errors[field.name]?.toString() : undefined} //add error distinct error label
      onChange={(files) => {
        setFieldValue(field.name, files); //setFieldValue(field.name, files[0]); setFieldTouched(field.name);
        if (onChange) onChange(files);
      }}
    />
  );
};

export default FileUploadComponent;
