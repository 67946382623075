import { useSelector } from 'react-redux';

export function useQosProfilesSelector() {
  const qosProfiles = useSelector((state) => state.qosProfiles);
  return qosProfiles;
}

export function useQosProfilesListSelector() {
  const list = useSelector((state) => state.qosProfiles.list);
  return list;
}

export function useQosProfilesFiltersSelector() {
  const filters = useSelector((state) => state.qosProfiles.filters);
  return filters;
}

export function useQosProfileEntitySelector() {
  const qosProfile = useSelector((state) => state.qosProfiles.entity);
  return qosProfile;
}
