import { PALETTE } from '@athonet/ui/theme';
import { PropTypes } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
export type Color = PropTypes.Color | 'tool';

const colors = {
  main: PALETTE.primary.main,
  primary: PALETTE.secondary.main,
  secondary: '#ABABAB',

  info: PALETTE.info.main,
  error: PALETTE.error.main,
  warning: PALETTE.warning.main,
  success: PALETTE.success.main,
  highlight: '#e00070',
  highlightDark: '#a80045',

  font: '#666666',
  fontLight: '#666666',
  fontInvert: '#ffffff',

  transparent: 'transparent',

  darkgray: '#575757',
  middarkgray: '',
  gray: '#d2d2d2',
  midlightgray: '#eaebed',
  lightgray: '#f6f6f6',

  dark: '#000000', // black
  dark80: 'rgba(0, 0, 0, 0.8)',
  dark60: 'rgba(0, 0, 0, 0.6)',
  dark40: 'rgba(0, 0, 0, 0.4)',
  dark20: 'rgba(0, 0, 0, 0.2)',
  dark10: 'rgba(0, 0, 0, 0.1)',
  dark05: 'rgba(0, 0, 0, 0.05)',
  dark03: 'rgba(0, 0, 0, 0.03)',
  dark02: 'rgba(0, 0, 0, 0.02)',

  light: '#ffffff', // white
  light80: 'rgba(255, 255, 255, 0.8)',
  light60: 'rgba(255, 255, 255, 0.6)',
  light50: 'rgba(255, 255, 255, 0.5)',
  light40: 'rgba(255, 255, 255, 0.4)',
  light20: 'rgba(255, 255, 255, 0.2)',
  light10: 'rgba(255, 255, 255, 0.1)',

  // rgb
  chart1: '39,55,84', // main
  chart2: '0,150,224', // primary
  chart3: '248,76,76', // red
  chart4: '',
  chart5: '',
};

const typo = {
  default: '16px',
  title: '24px',
  paragraph: '14px',
  xlarge: '36px',
  large: '28px',
  medium: '22px',
  medium20: '20px',
  reduced: '18px',
  small: '12px',
  xsmall: '10px',
  fontFamily: `"Source Sans Pro"', sans-serif`,
};

const layout = {
  simple: {
    background: colors.dark03,
  },
  master: {
    headerHeight: 55,
    headerHeightMobile: 100,
    background: colors.dark03,
    headerBgColor: colors.light,
    shadow: '0px 0px 5px ' + colors.dark05,
  },
};

const button = {
  tool: {
    background: colors.midlightgray,
    hoverBg: colors.gray,
    color: colors.main,
    disabledColor: colors.secondary,
    fontSize: typo.small,
    fontSizeSmall: typo.xsmall,
  },
};

const panel = {
  background: colors.light,
  header: {
    fontSize: typo.medium,
  },
  body: {
    fontSize: typo.default,
  },
};

const input = {
  background: colors.light,
  borderHover: colors.dark40,
  footerText: typo.small,
};

const passwordStrength = {
  colorVeryWeak: colors.dark20,
  colorWeak: colors.error,
  colorSoso: colors.warning,
  colorGood: colors.success,
  colorGreat: colors.success,
};

const menu = {
  background: colors.main,
  width: 60,
  logoBg: colors.dark20,
  color: colors.light10,
  itemColor: colors.light20,
  itemSelectedColor: colors.light60,
  itemHoverColor: colors.light60,
  itemPadding: '6px',
  itemLabelColor: colors.light50,
  itemHoverLabelColor: colors.light,
  itemSelectedLabelColor: colors.light,
  titleFontSize: typo.xsmall,
  itemLabelVerticalFontSize: typo.xsmall,
  itemLabelFontSize: typo.default,
  selectedBg: colors.dark20,
  selectedBar: colors.primary,
  hoverBg: colors.dark10,
  buttonTopbarColor: colors.main,
  versionFontSize: typo.xsmall,
};

const topbar = {
  color: colors.main,
  background: colors.transparent,
};

const breadcrumb = {
  fontSize: typo.paragraph,
  color: colors.dark40,
  currentFontSize: typo.medium,
  currentColor: colors.main,
};

const searchbar = {};

const usermenu = {
  borderColor: colors.midlightgray,
  roleFontSize: typo.xsmall,
  roleFontSizeMobile: typo.small,
  roleColor: colors.dark40,
  nameFontSize: typo.paragraph,
  nameFontSizeMobile: typo.medium20,
  nameColor: colors.main,
};

const alertmenu = {
  titleFontSize: typo.title,
  color: colors.dark60,
  borderColor: colors.primary,
};

const page = {};

const badge = {
  iconColor: colors.dark20,
  borderColor: colors.lightgray,
  color: colors.font,
  errorColor: colors.error,
  typeFontSize: typo.default,
  typeColor: colors.dark40,
  valueFontSize: typo.xlarge,
  valueColor: colors.main,
  itemFontSize: typo.default,
  labelFontSize: typo.small,
  colorBarDefault: colors.lightgray,
  colorBar: colors.main,
  colorBarHighlight: colors.primary,
  colorBarError: colors.error,
  iconColorMobile: colors.light20,
  buttonSelectedMobileBg: colors.primary,
  buttonSelectedMobileColor: colors.fontInvert,
  buttonMobileColor: colors.main,
  valueBarFontSize: typo.reduced,
  labelBarFontSize: typo.small,
  valueBarColor: colors.main,
  valueBarErrorColor: colors.error,
};

const ranking = {
  headerTitleFontSize: typo.default,
  headerInfoFontSize: typo.small,
  headerValueFontSize: typo.medium,
  headerTrafficLabelFontSize: typo.xsmall,
  headerTrafficValueFontSize: typo.default,
  headerTrafficColor: colors.primary,
  headerBorderColor: colors.midlightgray,
  borderColor: colors.lightgray,
  iconFontSize: typo.large,
  iconColor: colors.gray,
  tenantColor: colors.primary,
  buttonSelectedBg: colors.primary,
  buttonSelectedColor: colors.fontInvert,
  titleFontSize: typo.paragraph,
  addressFontSize: typo.small,
  unitFontSize: typo.small,
  trafficFontSize: typo.default,
  trendFontSize: typo.small,
  trendUpColor: colors.success,
  trendDownColor: colors.error,
  footerFontSize: typo.xsmall,
};

const filters = {
  borderColor: colors.primary,
};

const table = {
  header: {
    background: colors.midlightgray,
    height: 50,
  },
  row: {
    selectedBg: colors.primary,
    selectedColor: colors.light,
  },
  overlay: {
    background: colors.dark40,
    color: colors.light,
  },
  shadow: '0px 3px 1px -2px ' + colors.dark20,
  fontSize: typo.paragraph,
  totalFontSize: typo.paragraph,
};

const listToolbar = {
  height: 30,
  fontSize: typo.xsmall,
  badgeColor: colors.fontInvert,
  badgeBgColor: colors.primary,
  badgeFontSize: typo.xsmall,
  buttonOnBg: colors.dark05,
  buttonOnColor: colors.light,
};

const listToolRow = {
  fontSize: typo.small,
  color: colors.primary,
};

const form = {
  title: {
    fontSize: typo.medium,
    fontWeight: 100,
    color: colors.main,
  },
  fontSize: typo.paragraph,
  fontWeight: 100,
};

const confirmForm = {
  bgColor: colors.warning,
  color: colors.light,
};

const confirmationPopup = {
  headerBorderColor: colors.midlightgray,
  borderColor: colors.lightgray,
  titleFontSize: typo.paragraph,
  tenantColor: colors.primary,
};

const deleteForm = {
  bgColor: colors.error,
  color: colors.light,
};

const datepicker = {
  errorColor: colors.error,
};

const tabs = {
  height: '48px',
  fontSize: '14px',
  borderColor: colors.midlightgray,
};

const profile = {
  fontSize: typo.paragraph,
  labelColor: colors.dark40,
  dividerColor: colors.dark20,
};

const rangeString = {
  buttonFontSize: typo.xsmall,
};

const mediaquery = {
  smallphone: 375, // xs
  phone: 600, // sm
  tablet: 768, // md min:768 max:767
  desktop: 1024, // lg
  large: 1280, // xl
};

export const theme = {
  colors,
  typo,
  layout,
  button,
  panel,
  input,
  passwordStrength,
  menu,
  topbar,
  breadcrumb,
  searchbar,
  usermenu,
  alertmenu,
  page,
  badge,
  ranking,
  filters,
  table,
  listToolbar,
  listToolRow,
  form,
  confirmForm,
  confirmationPopup,
  deleteForm,
  datepicker,
  tabs,
  profile,
  mediaquery,
  rangeString,
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.secondary,
      contrastText: theme.colors.light,
    },
    error: {
      main: theme.colors.error,
      contrastText: theme.colors.light,
    },
    warning: {
      main: theme.colors.warning,
    },
    info: {
      main: theme.colors.info,
      contrastText: theme.colors.light,
    },
    success: {
      main: theme.colors.success,
    },
  },
  typography: {
    fontFamily: theme.typo.fontFamily,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: theme.mediaquery.phone,
      md: theme.mediaquery.tablet,
      lg: theme.mediaquery.desktop,
      xl: theme.mediaquery.large,
    },
  },
});
