export type SelectOptionsParams<T> = {
  showAll?: boolean;
  showAllLabel?: string;
  data: {
    items: T[];
    transform: (item: T) => SelectOption;
  }[];
};

export type SelectOption = {
  label: string;
  value: number | string | boolean;
};

export function generateSelectOptions<T>({ showAll, showAllLabel, data }: SelectOptionsParams<T>): SelectOption[][] {
  const options: SelectOption[][] = [];

  data.forEach(({ items, transform }) => {
    const option: SelectOption[] = [];
    if (showAll && showAllLabel) {
      option.push({
        label: showAllLabel,
        value: '',
      });
    }

    items.forEach((item) => {
      option.push(transform(item));
    });

    options.push(option);
  });

  return options;
}

export type Field<P> = {
  name: string;
  label?: string;
  placeholder?: string;
  error?: string;
  options?: SelectOption[] | undefined;
  gridClassName?: string;
  componentProps?: P;
  fieldComponent?: React.ReactNode;
  permissions?: string[];
};

export type MultiselectComponentProps = {
  options: []; // async multiselect,
  asyncGetOptions?: (val: string) => Promise<any>;
  selectAll?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  limitTags?: number;
};
