import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import {
  filtersLoading,
  filtersFailure,
  filtersInject,
  sortSet,
  listLoading,
  listSuccess,
  listFailure,
  listClear,
  exportSegmentsData,
  getSegment,
} from 'store/actions/segments';
import moment from 'moment';
import async from 'async';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Segments';
import List from 'components/List';
import Create from 'components/Edit/Segments/Create';
import Edit from 'components/Edit/Segments/Edit';
import ManageNodes from 'components/Edit/Segments/ManageNodes';
import Spinner from 'components/Spinner';
import DropdownTools from 'components/DropdownTools';
import ExportViewsConfirm from 'components/Confirm/ExportViews';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { server as ico_change_nodes } from 'react-icons-kit/fa/server';
import { RESOURCE } from 'store/models/modal';
import { openManageTenantsModal } from 'store/actions/modal';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { ic_people as ico_change_owners } from 'react-icons-kit/md/ic_people';
import getFetchHeaders from 'utils/fetchHeaders';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { getFiltersByQueryString } from 'store/models/filters';
import { generateFiltersQuery } from 'store/models/filters';
import config from 'config';
import { showErrorToast } from 'store/actions/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { deleteScheduledOperation, setScheduledOperation } from 'store/actions/bulkOperations';
import { usePollOperation } from 'hooks/usePollOperation';
import { Status } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';
import { tenantsCellRenderer } from 'utils/tenantsCellRenderer';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import SegmentProfileDetail from './id';
import { getStatusType } from 'store/models/usimProfile';
import { getPdpTypeText } from 'store/models/segment';
import { useFetchData } from 'hooks/useFetchData';
import { sentryLogError } from 'sentry';

/* NOTE: CC-1285 */
const Segments = () => {
  const fetchData = useFetchData();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isManageNodesOpen, setIsManageNodesOpen] = useState(false);
  const userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const datalist = useSelector((state) => state.segments.list.data);
  const loadingState = useSelector((state) => state.segments.list.state);
  const filters = useSelector((state) => state.segments.filters.values) || {};
  const filtersState = useSelector((state) => state.segments.filters.state);
  const filtersData = useSelector((state) => state.segments.filters.data) || [];
  const sort = useSelector((state) => state.segments.sort);
  const bootstrap = useBootstrapSelector();
  const { confirmationDialogOpen, dialogOpen } = useOverlay();
  const { pollOperation, clearPollOperation } = usePollOperation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    loadFilters();

    return () => {
      dispatch(listClear());
      clearPollOperation();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      loadData();
    }
  }, [filters]);

  useEffect(() => {
    if (sort !== '' && (filtersState === 1 || filtersState === 2)) {
      loadData();
    }
  }, [sort, filtersState]);

  const getFiltersData = () => {
    const options = {
      nodes: [],
      qos_templates_4g: [],
      tenants: [],
    };

    for (let i in filtersData.nodes) {
      options.nodes.push({ label: filtersData.nodes[i].display_name, value: filtersData.nodes[i].id });
    }

    for (let i in filtersData.qos_templates_4g) {
      let profile = filtersData.qos_templates_4g[i];
      options.qos_templates_4g.push({ label: profile.name, value: profile.id, nodes: profile.nodes });
    }

    for (let i in filtersData.tenants) {
      options.tenants.push({ label: filtersData.tenants[i].name, value: filtersData.tenants[i].id });
    }

    return options;
  };

  const loadFilters = () => {
    dispatch(filtersLoading());

    const calls = {};

    calls.tenants = (cb) => {
      const options = {
        url: `${config.apis.getTenantWithSubtenants
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((response) => {
          cb(null, response.tenants);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.qos_templates_4g = (cb) => {
      const options = {
        url: `${config.apis.getQoSProfiles
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '&status=ready')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    /** LOAD Nodes */
    calls.nodes = (cb) => {
      //TODO handle paginated entities...
      const options = {
        url: `${config.apis.getNodes
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')
          .replace('{platform}', 'athux')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    async.parallel(calls, (err, results) => {
      if (err) {
        sentryLogError(err);
        dispatch(filtersFailure());
      } else {
        dispatch(
          filtersInject({ state: 1, data: Object.assign(filtersData, results), values: getFiltersByQueryString() })
        );
      }
    });
  };

  const loadData = (page = 0) => {
    if (
      // load if..
      loadingState !== 0 &&
      sort !== '' // .. sort is set
    ) {
      dispatch(listLoading());

      const query = encodeURI(generateFiltersQuery(filters, '&'));

      const options = {
        url: `${config.apis.getSegments
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap.pageLimit)
          .replace('{page}', page)
          .replace('{filters}', query)}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          dispatch(
            listSuccess({
              data: result.items,
              total: result.total_items,
              page,
            })
          );
          // NOTE: dome => this is a patch, we're storing the selected item object instead of the id and so we need to update it to refresh to data being rendered in the modal
          setSelectedItem((prevSelectedItem) => {
            if (prevSelectedItem) {
              return result.items.find((item) => prevSelectedItem.id === item.id);
            }
          });
        })
        .catch(() => {
          dispatch(listFailure());
        });
    }
  };

  const handlePageChange = (page) => {
    loadData(page);
  };

  const exportSegments = () => {
    const filterQuery = generateFiltersQuery(filters, '&');
    void dispatch(exportSegmentsData(filterQuery));
  };

  const openDownload = () => {
    dialogOpen({
      title: formatMessage({ id: 'listToolbar.download' }),
      content: () => <ExportViewsConfirm onSubmit={exportSegments} />,
    });
  };

  useEffect(() => {
    loadData();
  }, [bootstrap.pageLimit]);

  const onOrderChange = (orderQuery) => {
    dispatch(sortSet(orderQuery));
  };

  const newSegment = (values, callback) => {
    const options = {
      url: `${config.apis.createSegment}`,
      method: 'POST',
      headers: getFetchHeaders(userdata),
      data: {
        name: values.name.trim(),
        apn: values.apn,
        pdp_type: values.pdp_type,
        charging_characteristics: values.charging_characteristics !== '' ? values.charging_characteristics : undefined,
        id_qos_template_4g: values?.id_qos_template_4g || null,
        pgw_allocation_profile: values?.pgw_allocation_profile,
        non_ip_template_id: values?.non_ip_template_id,
      },
    };

    fetchData(options)
      .then(() => {
        loadData();
        callback(true, formatMessage({ id: 'segments.actions.create.successMessage' }));
      })
      .catch((e) => {
        sentryLogError(e);
        const defaultError = formatMessage({ id: 'segments.actions.create.errorMessage' });
        const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
        callback(false, errorText);
      });
  };

  const onDelete = async (segmentId) => {
    const uuid = await dispatch(setScheduledOperation());
    const options = {
      url: `${config.apis.deleteSegment.replace('{id}', segmentId)}`,
      method: 'DELETE',
      headers: getFetchHeaders(userdata),
      data: {
        operation_id: uuid,
      },
    };

    fetchData(options)
      .then(async () => {
        loadData();
        if (id) {
          navigate(`/4g-provisioning`);
        }
      })
      .catch((e) => {
        sentryLogError(e);
        const defaultError = formatMessage({ id: 'segments.actions.delete.errorMessage' });
        const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
        dispatch(deleteScheduledOperation(uuid));
        showErrorToast({ message: errorText });
      });
  };

  const onAssignNode = async (values, callback) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());
      const options = {
        url: `${config.apis.assignSegmentNode.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          node_id: values.node_id,
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then((res) => {
          if (id) {
            pollOperation(res.operation_id, () => void dispatch(getSegment(id, true)));
          } else {
            pollOperation(res.operation_id, loadData);
          }
          callback(null);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'segments.actions.assignNode.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          callback(false, errorText);
        });
    } else {
      callback(false);
    }
  };

  const onDeassignNode = async (node_id) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());
      const options = {
        url: `${config.apis.deassignSegmentNode.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          node_id: node_id,
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then((res) => {
          if (id) {
            pollOperation(res.operation_id, () => void dispatch(getSegment(id, true)));
          } else {
            pollOperation(res.operation_id, loadData);
          }
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'segments.actions.deassignNode.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          dispatch(
            showErrorToast({
              message: errorText,
            })
          );
        });
    }
  };

  const onEdit = async (values, callback) => {
    if (selectedItem) {
      const uuid = await dispatch(setScheduledOperation());
      const options = {
        url: `${config.apis.editSegment.replace('{id}', selectedItem.id)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: {
          name: values?.name.trim(),
          charging_characteristics:
            values.charging_characteristics !== '' ? values.charging_characteristics : undefined,
          id_qos_template_4g: values?.id_qos_template_4g,
          pgw_allocation_profile: values?.pgw_allocation_profile,
          non_ip_template_id: values?.non_ip_template_id,
          operation_id: uuid,
        },
      };

      fetchData(options)
        .then(() => {
          if (id) {
            void dispatch(getSegment(id, true));
          } else {
            loadData();
          }
          callback(null);
        })
        .catch((e) => {
          sentryLogError(e);
          const defaultError = formatMessage({ id: 'segments.actions.edit.errorMessage' });
          const errorText = e.response ? (e.response.data.error ? e.response.data.error : defaultError) : defaultError;
          dispatch(deleteScheduledOperation(uuid));
          callback(false, errorText);
        });
    } else {
      callback(false);
    }
  };

  const openEdit = (rowData) => {
    setIsEditOpen(true);
    setSelectedItem(rowData);
  };

  const closeEdit = () => {
    setIsEditOpen(false);
    setSelectedItem(undefined);
  };

  const openManageTenants = (segmentId) => {
    dispatch(
      openManageTenantsModal({
        resource: RESOURCE.SEGMENT,
        id: segmentId,
        cb: () => {
          loadData();
          if (id) {
            void dispatch(getSegment(id, true));
          }
        },
      })
    );
  };

  const openManageNodes = (rowData) => {
    setIsManageNodesOpen(true);
    setSelectedItem(rowData);
  };

  const closeManageNodes = () => {
    setIsManageNodesOpen(false);
    setSelectedItem(undefined);
    clearPollOperation();
  };

  const openDelete = ({ id: segmentId, name }) => {
    confirmationDialogOpen({
      title: formatMessage({ id: 'segments.delete.confirm.title' }),
      description: formatMessage({ id: 'segments.delete.confirm' }),
      alertMessage: formatMessage({ id: 'segments.itemsAffected' }, { element: name }),
      continueButtonText: formatMessage({ id: 'segments.delete.confirm.continueButton' }, { elements: 1 }),
      onConfirm: () => void onDelete(segmentId),
      severity: 'danger',
      dataTestid: 'confirm-delete-segment',
    });
  };

  const getTools = (rowData) => {
    let options = [
      {
        icon: ico_edit,
        label: formatMessage({ id: 'segments.actions.edit.title' }),
        action: () => openEdit(rowData),
        permissions: [U_PERMISSIONS.UPDATE_SEGMENT],
      },
      {
        icon: ico_change_nodes,
        label: formatMessage({ id: 'segments.actions.manageNodes.title' }),
        action: () => openManageNodes(rowData),
        permissions: [U_PERMISSIONS.UPDATE_SEGMENT],
      },
    ];

    if (userdata.tenant_type.toUpperCase() !== T_PERMISSIONS.NETWORK_MANAGER) {
      options = options.concat([
        {
          icon: ico_change_owners,
          label: formatMessage({ id: 'segments.actions.manageTenants.title' }),
          action: () => openManageTenants(rowData.id),
          permissions: [U_PERMISSIONS.UPDATE_SEGMENT],
        },
      ]);
    }
    if (userdata.tenant_type.toUpperCase() === T_PERMISSIONS.MASTER) {
      options = options.concat([
        {
          icon: ico_delete,
          label: formatMessage({ id: 'segments.actions.delete.title' }),
          action: () => openDelete(rowData),
          permissions: [U_PERMISSIONS.DELETE_SEGMENT],
        },
      ]);
    }

    return <DropdownTools options={checkPermissionsList(userdata.permissions, options, false)} />;
  };
  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataKey: 'id',
      width: 200,
      maxWidth: 300,
      minWidth: 100,
      visible: false,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'segments.table.name' }),
      dataKey: 'name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: name, rowData: { id: segmentId } }) => {
        return (
          <Link
            onClick={() => {
              navigate(`/4g-provisioning/apn-profiles/${segmentId}`);
            }}
          >
            <Text type={'body2'}>{name}</Text>
          </Link>
        );
      },
    },
    {
      key: 'status',
      title: formatMessage({ id: 'segments.table.status' }),
      dataKey: 'status',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: status }) => (
        <Status status={getStatusType(status)} label={humanizeSnakeCase(status)} asChip />
      ),
    },
    {
      key: 'apn',
      title: formatMessage({ id: 'segments.table.apn' }),
      dataKey: 'apn',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'pdp_type',
      title: formatMessage({ id: 'segments.table.pdp_type' }),
      dataKey: 'pdp_type',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: pdp_type }) => getPdpTypeText(pdp_type),
    },
    {
      key: 'charging_characteristics',
      title: formatMessage({ id: 'segments.table.charging_characteristics' }),
      dataKey: 'charging_characteristics',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      visible: false,
    },
    {
      key: 'qos_template_4g.name',
      title: formatMessage({ id: 'segments.table.qos_template_4g_name' }),
      dataKey: 'qos_template_4g.name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'pgw_allocation_profile.name',
      title: formatMessage({ id: 'segments.table.pgw_allocation_profile_name' }),
      dataKey: 'pgw_allocation_profile.name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      secret: true,
      hidden: true,
    },
    {
      key: 'non_ip_template.name',
      title: formatMessage({ id: 'segments.table.non_ip_template_name' }),
      dataKey: 'non_ip_template.name',
      sortable: true,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      secret: true,
      hidden: true,
    },
    {
      key: 'nodes',
      title: formatMessage({ id: 'segments.table.nodes' }),
      dataKey: 'nodes',
      sortable: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      cellRenderer: ({ cellData: nodes }) => {
        if (nodes.length === 0) {
          return '-';
        } else if (nodes.length === 1) {
          return nodes[0].display_name;
        } else {
          let title = nodes.reduce((acc, node) => acc + '\n' + node.display_name, '');
          return (
            <span title={title}>
              {nodes[0].display_name} (+{nodes.length - 1} more...)
            </span>
          );
        }
      },
      visible: true,
    },
    {
      // backend still returning "owners"
      key: 'owners',
      title: formatMessage({ id: 'segments.table.tenants' }),
      dataKey: 'owners',
      sortable: false,
      width: 200,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
      permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
      cellRenderer: ({ cellData: tenants }) => tenantsCellRenderer(tenants),
      visible: false,
    },
    {
      key: 'created_at',
      title: formatMessage({ id: 'segments.table.created' }),
      dataKey: 'created_at',
      defaultSort: 'desc', // set the columns sorted as default
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 50,
      visible: false,
      cellRenderer: ({ cellData: created_at }) => moment(created_at).format(localizedConfig.fullDateFormat),
    },
    {
      key: 'updated_at',
      title: formatMessage({ id: 'segments.table.updated' }),
      dataKey: 'updated_at',
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 50,
      visible: false,
      cellRenderer: ({ rowData }) => {
        return rowData.created_at !== rowData.updated_at
          ? moment(rowData.updated_at).format(localizedConfig.fullDateFormat)
          : '-';
      },
    },
    {
      key: 'tools',
      title: '',
      dataKey: 'tools',
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      secret: true, // secret used to hide from columns management panel
      cellRenderer: ({ rowData }) => {
        return getTools(rowData);
      },
    },
  ];

  const onCheckPermissionsList = (list) => {
    return checkPermissionsList([userdata.tenant_type], list);
  };

  const columnsPermitted = checkPermissionsList([userdata.tenant_type], columns);

  return (
    <>
      <>
        {id ? (
          <SegmentProfileDetail
            handleOpenEdit={openEdit}
            handleOpenManageNodes={openManageNodes}
            handleOpenManageTenants={openManageTenants}
            handleOpenDelete={openDelete}
          />
        ) : columnsPermitted ? (
          <List
            selectable={false}
            key="segments-list"
            columns={columnsPermitted}
            totalRows={datalist.total}
            data={datalist.data}
            page={datalist.page}
            filters={filters}
            onOrderChange={onOrderChange}
            loadingState={loadingState}
            createComponent={
              checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_SEGMENT) && (
                <Create data={getFiltersData()} onSubmit={newSegment} checkPermissions={onCheckPermissionsList} />
              )
            }
            filtersComponent={<Filters data={getFiltersData()} values={filters} />}
            onPageChange={handlePageChange}
            onDownload={openDownload}
            toolbar={{ actions: false }}
            rowsPerPage={bootstrap.pageLimit}
            onRefresh={loadData}
          />
        ) : (
          <Spinner className="spinner" size={40} />
        )}
      </>
      <>
        {isEditOpen && (
          <Edit
            dataOptions={getFiltersData()}
            data={selectedItem}
            isOpen={isEditOpen}
            onEdit={onEdit}
            checkPermissions={onCheckPermissionsList}
            onClose={closeEdit}
          />
        )}

        {isManageNodesOpen && (
          <ManageNodes
            dataOptions={getFiltersData()}
            data={selectedItem}
            isOpen={isManageNodesOpen}
            onClose={closeManageNodes}
            onAssignNode={onAssignNode}
            onDeassignNode={onDeassignNode}
          />
        )}
      </>
    </>
  );
};

export default Segments;
