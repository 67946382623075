import { ButtonProps } from '@athonet/ui/components/Input/Button';
import React, { useCallback } from 'react';
import { ConfirmButton } from '../../Button/ConfirmButton';
import { FormikProps } from 'formik';

const FormSubmitButton: React.FC<ButtonProps & { formRef: React.RefObject<FormikProps<any>> }> = (props) => {
  const { formRef, ...buttonProps } = props;

  const handleSubmitButtonClick = useCallback(() => {
    if (!formRef?.current) console.error(formRef, 'SubmitButton form ref is not defined!');
    formRef.current?.handleSubmit();
  }, [formRef]);

  return (
    <ConfirmButton
      onClick={handleSubmitButtonClick}
      {...buttonProps}
      loading={formRef?.current?.isSubmitting}
      disabled={formRef?.current?.isSubmitting}
    />
  );
};

export default FormSubmitButton;
