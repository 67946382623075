import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';
import List, { ColumnShape } from 'components/List';
import UsimStatus from 'components/UsimStatus';
import DropdownTools from 'components/DropdownTools';
import ExportViewsConfirm from 'components/Confirm/ExportViews';
import { edit as ico_edit } from 'react-icons-kit/fa/edit';
import { ic_people as ico_change_tenant } from 'react-icons-kit/md/ic_people';
import { ic_exit_to_app as ico_change_site } from 'react-icons-kit/md/ic_exit_to_app';
import { ic_filter_none as ico_change_profile } from 'react-icons-kit/md/ic_filter_none';
import { ic_signal_cellular_4_bar as ico_activate } from 'react-icons-kit/md/ic_signal_cellular_4_bar';
import { ic_signal_cellular_off as ico_deactivate } from 'react-icons-kit/md/ic_signal_cellular_off';
import { ic_settings_backup_restore as ico_deprovision } from 'react-icons-kit/md/ic_settings_backup_restore';
import { ic_find_in_page as ico_events } from 'react-icons-kit/md/ic_find_in_page';
import { ic_clear as ico_bar } from 'react-icons-kit/md/ic_clear';
import { ic_undo as ico_unbar } from 'react-icons-kit/md/ic_undo';
import { code } from 'react-icons-kit/fa/code';
import { infoCircle } from 'react-icons-kit/fa/infoCircle';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { T_PERMISSIONS, checkPermissionsList, U_PERMISSIONS, checkPermissionToUse } from 'utils/permissionCodes';
import { useCanUserCreateUsimSelector, useUserSelector } from 'store/selectors/user';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import config from 'config';
import { Usim } from 'store/models/usim';
import {
  activateUsim,
  barUsim,
  changeUsimNode,
  changeUsimProfile,
  changeUsimSite,
  changeUsimTenant,
  deactivateUsim,
  deleteUsim,
  deleteUsimMetadata,
  deprovisionUsim,
  editSingleUsim,
  exportUsimsViews,
  getUsims,
  reset,
  setUsimShouldFlushSelection,
  unbarUsim,
  updateUsimMetadataKey,
} from 'store/actions/usims';
import { useUsimsFiltersSelector, useUsimsListSelector } from 'store/selectors/usims/cards';
import Filters from 'components/Filters/Usims';
import UsimEvents from 'components/UsimEvents';
import { ConfirmationDialogStateProps } from '@athonet/ui/store/atoms/overlay/confirmationDialog';
import { FiltersObj, LegacyFiltersObj } from 'store/models/filters';
import { Thunk } from 'store/actions';
import EditUsim, { ShowField } from 'components/Edit/Usims/EditUsim';
import Create from 'components/Edit/Usims/Create';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { useNavigate } from 'react-router-dom';
import { getKText } from 'store/models/usim';
import MetadataEditor from 'components/Modals/MetadataEditor';
import { ListToolbarAction } from 'components/ListToolBar';

export default function Usims() {
  const user = useUserSelector();
  const bootstrap = useBootstrapSelector();
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const loadingState = useSelector((state) => state.usims.list.state);
  const { confirmationDialogOpen, dialogClose, dialogOpen } = useOverlay();
  const usimsFilters = useUsimsFiltersSelector();
  const usimsList = useUsimsListSelector();
  const navigate = useNavigate();
  const shouldFlushSelection = useSelector((state) => state.usims.shouldflushSelection);
  const canUserCreateUsim = useCanUserCreateUsimSelector();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  const handlePageChange = useCallback(
    (page) => {
      dispatch(getUsims({ page }));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      void dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    void dispatch(getUsims({}));
  }, [bootstrap?.pageLimit, dispatch]);

  const handleSortChange = useCallback(
    (orderQuery) => {
      dispatch(getUsims({ sortBy: orderQuery }));
    },
    [dispatch]
  );

  const handleRefresh = useCallback(() => {
    dispatch(getUsims({}));
  }, [dispatch]);

  const openDownload: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      dialogOpen({
        title: formatMessage({ id: 'listToolbar.download' }),
        content: () => (
          <ExportViewsConfirm
            onSubmit={() => {
              void dispatch(
                exportUsimsViews(Array.isArray(rowDataOrFilters) ? rowDataOrFilters.map((usim) => usim.id) : [])
              );
            }}
            downloadItems={Array.isArray(rowDataOrFilters) ? rowDataOrFilters.length : usimsList.data.total}
          />
        ),
      });
    },
    [dialogOpen, dispatch, formatMessage, usimsList.data.total]
  );

  const openUsimOperationconfirm = useCallback(
    ({
      // TODO coming from ListToolbar can be either the filters object (which won't be used in favor of the filters fetched from the store) or the array of rows (usims in this case)
      rowDataOrFilters,
      operation,
      action,
      severity = undefined,
    }: {
      rowDataOrFilters: Usim[] | FiltersObj | LegacyFiltersObj;
      operation: string;
      action: (items: Usim['id'][]) => Thunk<Promise<{ operation_id: string } | void>>;
      severity?: ConfirmationDialogStateProps['severity'];
    }) => {
      let usims: Usim['id'][] = [];
      let singleItem;
      let number = usimsList.data.total;

      if (Array.isArray(rowDataOrFilters)) {
        usims = rowDataOrFilters.map((usim) => usim.id);
        number = rowDataOrFilters?.length;
        if (usims.length === 1) {
          singleItem = rowDataOrFilters[0].imsi;
        }
      }

      confirmationDialogOpen({
        onConfirm: () => dispatch(action(usims)),
        severity,
        title: formatMessage({ id: `usims.actions.${operation}.confirm.title` }, { elements: number }),
        description: formatMessage({ id: 'common.confirmOperation' }),
        alertMessage: formatMessage(
          { id: `usims.actions.confirm.itemsAffected` },
          { elements: number, usim: singleItem }
        ),
        continueButtonText: formatMessage(
          { id: `usims.actions.${operation}.confirm.continueButton` },
          { elements: number }
        ),
        dataTestid: `confirm-${operation}-usim${number > 1 ? '-bulk' : ''}`,
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, usimsList.data.total]
  );

  const openDelete: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      openUsimOperationconfirm({
        rowDataOrFilters,
        operation: 'delete',
        action: (usims) => {
          dispatch(setUsimShouldFlushSelection(true));
          return deleteUsim(usims);
        },
        severity: 'danger',
      });
    },
    [dispatch, openUsimOperationconfirm]
  );

  const openActivate: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      openUsimOperationconfirm({
        rowDataOrFilters,
        operation: 'activate',
        action: activateUsim,
        severity: 'warning',
      });
    },
    [openUsimOperationconfirm]
  );

  const openDeactivate: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      openUsimOperationconfirm({
        rowDataOrFilters,
        operation: 'deactivate',
        action: deactivateUsim,
        severity: 'warning',
      });
    },
    [openUsimOperationconfirm]
  );

  const openDeprovision: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      openUsimOperationconfirm({
        rowDataOrFilters,
        operation: 'deprovision',
        action: deprovisionUsim,
        severity: 'danger',
      });
    },
    [openUsimOperationconfirm]
  );

  const openBar: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      openUsimOperationconfirm({
        rowDataOrFilters,
        operation: 'bar',
        action: barUsim,
        severity: 'warning',
      });
    },
    [openUsimOperationconfirm]
  );

  const openUnbar: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      openUsimOperationconfirm({
        rowDataOrFilters,
        operation: 'unbar',
        action: unbarUsim,
        severity: 'warning',
      });
    },
    [openUsimOperationconfirm]
  );

  const openUsimEvents = useCallback(
    (usimId: Usim['id']) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.events.title' }),
        content: () => <UsimEvents usim={usimId} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  // const openDynamicInfoDrawer = useCallback(
  //   (usim: Usim) => {
  //     drawerOpen({
  //       title: formatMessage({ id: 'usims.dynamicInfo.title' }),
  //       width: 360,
  //       content: () => <UsimDynamicStatus usim={usim} />,
  //     });
  //   },
  //   [drawerOpen, formatMessage]
  // );

  const openMetadataEditor: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      if (Array.isArray(rowDataOrFilters)) {
        dialogOpen({
          title: `${formatMessage({ id: 'usims.metadata.title' })} - USIM ${rowDataOrFilters[0].imsi}`,
          content: () => <MetadataEditor usim={rowDataOrFilters[0]} />,
        });
      }
    },
    [dialogOpen, formatMessage]
  );

  const getInitialUsim = useCallback(
    (rowDataOrFilters: Usim[] | FiltersObj | LegacyFiltersObj): Usim | null =>
      Array.isArray(rowDataOrFilters) && rowDataOrFilters.length === 1 ? rowDataOrFilters[0] : null,
    []
  );

  const getUsimAmount = useCallback(
    (rowDataOrFilters: Usim[] | FiltersObj | LegacyFiltersObj): number => {
      if (Array.isArray(rowDataOrFilters)) {
        return rowDataOrFilters.length;
      } else {
        return usimsList.data.total;
      }
    },
    [usimsList.data.total]
  );

  const editUsimConfirm = useCallback(
    (values, rowDataOrFilters, action, operation) => {
      let usims = Array.isArray(rowDataOrFilters) ? rowDataOrFilters : [];
      let number = usimsList.data.total;
      let singleItem = '';

      if (Array.isArray(rowDataOrFilters)) {
        number = rowDataOrFilters?.length;
        if (usims.length === 1) {
          singleItem = rowDataOrFilters[0].imsi;
        }
      }

      confirmationDialogOpen({
        onConfirm: async () => {
          dialogClose();
          void dispatch(action(values, Array.isArray(usims) ? usims : []));
        },
        onCancel: () => dialogClose(),

        title: formatMessage({ id: `usims.actions.${operation}.confirm.title` }, { elements: number }),
        description: formatMessage({ id: 'common.confirmOperation' }),
        alertMessage: formatMessage(
          { id: `usims.actions.confirm.itemsAffected` },
          { elements: number, usim: singleItem }
        ),
        continueButtonText: formatMessage(
          { id: `usims.actions.${operation}.confirm.continueButton` },
          { elements: number }
        ),
        dataTestid: `confirm-${operation}-usim${number > 1 ? '-bulk' : ''}`,
      });
    },
    [confirmationDialogOpen, dialogClose, dispatch, formatMessage, usimsList.data.total]
  );

  const openChangeSite: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      dialogOpen({
        title: formatMessage({ id: 'usimProfiles.actions.changeSite.site_id.title' }),
        content: () => (
          <EditUsim
            usims={getUsimAmount(rowDataOrFilters)}
            onSubmit={(values) => editUsimConfirm(values, rowDataOrFilters, changeUsimSite, 'changeSite')}
            show={[{ fields: ['site'] }]}
            initials={{
              site: getInitialUsim(rowDataOrFilters)?.site,
            }}
          />
        ),
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, editUsimConfirm, formatMessage, getInitialUsim, getUsimAmount]
  );

  const openChangeNode: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.changeNode.node_id.title' }),
        content: () => (
          <EditUsim
            usims={getUsimAmount(rowDataOrFilters)}
            onSubmit={(values) => editUsimConfirm(values, rowDataOrFilters, changeUsimNode, 'changeNode')}
            show={[{ fields: ['node'] }]}
            initials={{
              node: getInitialUsim(rowDataOrFilters)?.node,
            }}
          />
        ),
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, editUsimConfirm, formatMessage, getInitialUsim, getUsimAmount]
  );

  const openChangeProfile: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.changeProfile.profile_id.title' }),
        content: () => (
          <EditUsim
            usims={getUsimAmount(rowDataOrFilters)}
            onSubmit={(values) => editUsimConfirm(values, rowDataOrFilters, changeUsimProfile, 'changeProfile')}
            show={[{ fields: ['profile'] }]}
            initials={{
              profile: getInitialUsim(rowDataOrFilters)?.profile,
            }}
          />
        ),
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, editUsimConfirm, formatMessage, getInitialUsim, getUsimAmount]
  );

  const openChangeTenant: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.changeTenant.tenant_id.title' }),
        content: () => (
          <EditUsim
            usims={getUsimAmount(rowDataOrFilters)}
            onSubmit={(values) => editUsimConfirm(values, rowDataOrFilters, changeUsimTenant, 'changeTenant')}
            show={[{ fields: ['tenant'] }]}
            initials={{
              tenant: getInitialUsim(rowDataOrFilters)?.tenant,
            }}
          />
        ),
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, editUsimConfirm, formatMessage, getInitialUsim, getUsimAmount]
  );

  const openDeleteMetadata: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      const show: ShowField<'metadata_delete'>[] = [{ fields: ['metadata_delete'] }];
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.deletemetadata.key.placeholder' }),
        content: () => {
          return (
            <EditUsim
              usims={getUsimAmount(rowDataOrFilters)}
              onSubmit={(values) => editUsimConfirm(values, rowDataOrFilters, deleteUsimMetadata, 'deleteMetadata')}
              show={show}
              initials={{
                metadata_delete: null,
              }}
            />
          );
        },
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, editUsimConfirm, formatMessage, getUsimAmount]
  );

  const openUpdateMetadata: ListToolbarAction<Usim>['onClick'] = useCallback(
    (rowDataOrFilters) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.updateMetadata.key.placeholder' }),
        content: () => (
          <EditUsim
            usims={getUsimAmount(rowDataOrFilters)}
            onSubmit={(values) => editUsimConfirm(values, rowDataOrFilters, updateUsimMetadataKey, 'changeMetadata')}
            show={[{ fields: ['metadata'] }]}
            initials={{
              metadata: getInitialUsim(rowDataOrFilters)?.metadata,
            }}
          />
        ),
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, editUsimConfirm, formatMessage, getInitialUsim, getUsimAmount]
  );

  const openEdit = useCallback(
    (usim: Usim) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.edit.title' }),
        content: () => (
          <EditUsim
            usim={usim}
            onSubmit={(data) => {
              void dispatch(editSingleUsim({ data, usim }));
              dialogClose();
            }}
            show={[
              {
                label: formatMessage({ id: 'usims.form.usim.data' }),
                fields: ['name', 'profile', 'imsi', 'iccid', 'ims_profile', 'msisdn'],
              },
              {
                label: 'Secret K for AKA authentication',
                fields: ['k', 'encrypt', 'use_default_tk', 'use_key'],
                showIf: [
                  {
                    field: 'use_key',
                    condition: ({ encrypt, use_default_tk }) => Boolean(encrypt && !use_default_tk),
                  },
                  {
                    field: 'use_default_tk',
                    condition: ({ encrypt }) => Boolean(encrypt),
                  },
                ],
              }, // TODO: intl,
              {
                label: 'Secret OP/OPC for AKA authentication',
                fields: ['key_type', 'key_override', 'op', 'opc'],
                showIf: [
                  {
                    field: 'key_override',
                    condition: ({ key_type }) =>
                      Boolean((key_type === 1 && usim['op']) || (key_type === 2 && usim['opc'])),
                  },
                  {
                    field: 'op',
                    condition: ({ key_type, key_override }) => Boolean(key_type === 1 && (!usim['op'] || key_override)),
                  },
                  {
                    field: 'opc',
                    condition: ({ key_type, key_override }) =>
                      Boolean(key_type === 2 && (!usim['opc'] || key_override)),
                  },
                ],
              }, // TODO: intl,
            ]}
            initials={{
              ...usim,
              op: '',
              opc: '',
              k: '',
              key_type: !usim.op && !usim.opc ? 0 : usim.op && !usim.opc ? 1 : 2,
              key_override: false,
              use_default_tk: Boolean(!usim['use_key']),
            }}
          />
        ),
        closeOnBackdropClick: false,
      });
    },
    [dialogClose, dialogOpen, dispatch, formatMessage]
  );

  const getTools = useCallback(
    (rowData: Usim) => {
      const usimBarredOrUnavailable = rowData.status === 'barred' || rowData.status === 'unavailable';
      const forceDisable = rowData.updating || usimBarredOrUnavailable;
      const initialOptions = [
        {
          icon: code,
          label: formatMessage({ id: 'usims.actions.metadata.title' }),
          action: () => openMetadataEditor([rowData]),
          disabled: forceDisable, // || (selectedItems && selectedItems.length > 0),
          name: 'edit-usim-metadata',
        },
        {
          icon: ico_events,
          label: formatMessage({ id: 'usims.actions.events.title' }),
          action: () => openUsimEvents(rowData.id),
          disabled: rowData.updating || false, // || (selectedItems && selectedItems.length > 0),
          name: 'view-usim-events',
        },
        {
          icon: ico_edit,
          label: formatMessage({ id: 'usims.actions.edit.title' }),
          action: () => openEdit(rowData),
          disabled: forceDisable || rowData.status === 'ordered' || false,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'edit-usim-events',
        },
        {
          icon: ico_change_profile,
          label: formatMessage({ id: 'usims.actions.changeProfile.title' }),
          action: () => openChangeProfile([rowData]),
          disabled: forceDisable || false,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'change-usim-profile',
        },
        {
          icon: ico_change_tenant,
          label: formatMessage({ id: 'usims.actions.changeTenant.title' }),
          action: () => openChangeTenant([rowData]),
          disabled: forceDisable,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'change-usim-tenant',
        },
        {
          icon: ico_change_site,
          label: formatMessage({ id: 'usims.actions.changeNode.title' }),
          action: () => openChangeNode([rowData]),
          disabled: forceDisable,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'change-usim-node',
        },
        {
          icon: ico_change_site,
          label: formatMessage({ id: 'usims.actions.changeSite.title' }),
          action: () => openChangeSite([rowData]),
          disabled: forceDisable,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          name: 'change-usim-site',
        },
        {
          icon: ico_deprovision,
          label: formatMessage({ id: 'usims.actions.deprovision.title' }),
          action: () => openDeprovision([rowData]),
          disabled: !rowData.node || forceDisable,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          show: !!rowData.node,
          name: 'deprovision-usim',
        },
        {
          icon: ico_bar,
          label: formatMessage({ id: 'usims.actions.bar.title' }),
          action: () => openBar([rowData]),
          disabled:
            (rowData.status !== 'inactive' && rowData.status !== 'active' && rowData.status !== 'barring') ||
            forceDisable,
          permissions: [U_PERMISSIONS.BAR_USIM],
          show:
            rowData.status === 'active' ||
            rowData.status === 'inactive' ||
            (rowData.error && rowData.status === 'barring'),
          name: 'bar-usim',
        },
        {
          icon: ico_unbar,
          label: formatMessage({ id: 'usims.actions.unbar.title' }),
          action: () => openUnbar([rowData]),
          disabled: rowData.updating || rowData.status !== 'barred',
          permissions: [U_PERMISSIONS.UNBAR_USIM],
          show: rowData.status === 'barred',
          name: 'unbar-usim',
        },
        {
          icon: ico_activate,
          label: formatMessage({ id: 'usims.actions.activate.title' }),
          action: () => openActivate([rowData]),
          disabled: rowData.status === 'in_stock' || forceDisable,
          permissions: [U_PERMISSIONS.ACTIVATE_USIM],
          show:
            rowData.status === 'in_stock' ||
            rowData.status === 'inactive' ||
            (rowData.error && rowData.status === 'activating') ||
            (rowData.error && rowData.status === 'deactivating'),
          name: 'activate-usim',
        },
        {
          icon: ico_deactivate,
          label: formatMessage({ id: 'usims.actions.deactivate.title' }),
          action: () => openDeactivate([rowData]),
          disabled: rowData.status === 'in_stock' || forceDisable,
          permissions: [U_PERMISSIONS.DEACTIVATE_USIM],
          show:
            rowData.status === 'active' ||
            (rowData.error && rowData.status === 'activating') ||
            (rowData.error && rowData.status === 'deactivating'),
          name: 'deactivate-usim',
        },
        {
          icon: ico_delete,
          label: formatMessage({ id: 'usims.actions.delete.title' }),
          action: () => openDelete([rowData]),
          disabled: rowData.status !== 'in_stock' || forceDisable,
          permissions: [U_PERMISSIONS.DELETE_USIM],
          show: bootstrap?.delete_usim,
          name: 'delete-usim',
        },
      ];

      const opt = [
        ...(bootstrap?.dynamic_info
          ? [
              {
                icon: infoCircle,
                label: formatMessage({ id: 'usims.actions.dynamicInfo.title' }),
                action: () => navigate(`/4g-provisioning/usim-cards/${rowData.id}/info`),
                disabled:
                  forceDisable ||
                  (rowData.status !== 'active' && rowData.status !== 'inactive' && rowData.status !== 'barred'), // || (selectedItems && selectedItems.length > 0),
                name: 'edit-usim-dynamic-info',
              },
            ]
          : []),
        ...initialOptions,
      ];

      const options = checkPermissionsList(
        user?.permissions || [],
        // TODO disable tools if there are selected items
        opt,
        false
      );

      const optionsToShow = options.filter((item) => item.show !== false);

      return <DropdownTools options={optionsToShow} />;
    },
    [
      bootstrap,
      formatMessage,
      openActivate,
      openBar,
      openChangeNode,
      openChangeProfile,
      openChangeSite,
      openChangeTenant,
      openDeactivate,
      openDelete,
      openDeprovision,
      openEdit,
      openMetadataEditor,
      openUnbar,
      openUsimEvents,
      navigate,
      user,
    ]
  );

  const columns: ColumnShape<Usim>[] = useMemo(() => {
    if (!bootstrap) {
      return [];
    }

    return checkPermissionsList(
      [user?.tenant_type],
      [
        {
          key: 'id',
          title: 'ID',
          dataKey: 'id',
          width: 200,
          maxWidth: 300,
          minWidth: 100,
          visible: false,
        },
        {
          key: 'name',
          title: formatMessage({ id: 'usims.table.name' }),
          dataKey: 'name',
          sortable: true,
          width: 200,
          maxWidth: 250,
          minWidth: 100,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable,
          cellRenderer: ({ cellData: name, rowData: { id } }) => {
            return (
              <Link
                onClick={() => {
                  navigate(`/4g-provisioning/usim-cards/${id}`);
                }}
              >
                <Text type={'body2'}>{name}</Text>
              </Link>
            );
          },
        },
        {
          key: 'imsi',
          title: formatMessage({ id: 'usims.table.imsi' }),
          dataKey: 'imsi',
          sortable: true,
          width: 130,
          maxWidth: 250,
          minWidth: 100,
          resizable: true,
        },
        ...(bootstrap.dual_sim_service
          ? [
              {
                key: 'imsi2',
                title: formatMessage({ id: 'usims.table.imsi2' }),
                dataKey: 'imsi2',
                sortable: true,
                width: 130,
                maxWidth: 250,
                minWidth: 100,
                resizable: true,
                visible: false,
              },
            ]
          : []),
        {
          key: 'iccid',
          title: formatMessage({ id: 'usims.table.iccid' }),
          dataKey: 'iccid',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
        },
        {
          key: 'msisdn',
          title: formatMessage({ id: 'usims.table.msisdn' }),
          dataKey: 'msisdn',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: false,
          cellRenderer: ({ cellData: msisdn, rowData: { msisdn_status } }) => (
            <span title={msisdn_status}>{msisdn}</span>
          ),
        },
        {
          key: 'msisdn_status',
          title: formatMessage({ id: 'usims.table.msisdn_status' }),
          dataKey: 'msisdn_status',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          hidden: true,
          secret: true,
          visible: false,
        },
        {
          key: 'ims_profile',
          title: formatMessage({ id: 'usims.table.ims_profile' }),
          dataKey: 'ims_profile',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: false,
          cellRenderer: ({ cellData: ims_profile }) => (ims_profile ? 'Enabled' : 'Disabled'),
        },
        {
          key: 'status',
          title: formatMessage({ id: 'usims.table.status' }),
          dataKey: 'status',
          sortable: true,
          width: 120,
          maxWidth: 100,
          minWidth: 120,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          cellRenderer: ({ cellData: status, rowData: { updating, error } }) => {
            return <UsimStatus status={status} error={error} updating={updating} />;
          },
        },
        {
          key: 'use_key',
          title: formatMessage({ id: 'usims.table.k' }),
          dataKey: 'k',
          sortable: false,
          width: 220,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          cellRenderer: ({ rowData: { k, encrypt, use_key } }) => {
            return <Status label={getKText({ k, encrypt, use_key })} status={k ? STATUS.SUCCESS : STATUS.ERROR} />;
          },
          visible: false,
        },
        {
          key: 'op',
          title: formatMessage({ id: 'usims.table.op' }),
          dataKey: 'op',
          sortable: false,
          width: 100,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          cellRenderer: ({ rowData: { op, opc } }) => {
            return <Status label={op ? 'Set' : 'Not Set'} status={op ? STATUS.SUCCESS : STATUS.DEFAULT} />;
          },
          visible: false,
        },
        {
          key: 'opc',
          title: formatMessage({ id: 'usims.table.opc' }),
          dataKey: 'opc',
          sortable: false,
          width: 100,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          cellRenderer: ({ rowData: { op, opc } }) => {
            return <Status label={opc ? 'Set' : 'Not Set'} status={opc ? STATUS.SUCCESS : STATUS.DEFAULT} />;
          },
          visible: false,
        },
        {
          key: 'order_id',
          title: formatMessage({ id: 'usims.table.order_id' }),
          dataKey: 'order.order_id',
          sortable: false, //waiting for sortable nested fields
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: false,
          permissions: [T_PERMISSIONS.MASTER, 'READ_USIM_ORDER'],
          hidden: !bootstrap?.usim_orders,
          secret: !bootstrap?.usim_orders,
        },
        {
          key: 'batch_id',
          title: formatMessage({ id: 'usims.table.batch_id' }),
          dataKey: 'batch_id',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: false,
          permissions: [T_PERMISSIONS.MASTER, 'READ_USIM_ORDER'],
          hidden: !bootstrap?.usim_orders,
          secret: !bootstrap?.usim_orders,
        },
        {
          key: 'profile_id',
          title: formatMessage({ id: 'usims.table.profileName' }),
          dataKey: 'profile.name',
          sortable: false,
          width: 150,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
        },
        {
          key: 'node_id',
          title: formatMessage({ id: 'usims.table.nodeName' }),
          dataKey: 'node.display_name',
          sortable: false,
          width: 150,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
        },
        {
          key: 'site_id',
          title: formatMessage({ id: 'usims.table.siteName' }),
          dataKey: 'site.name',
          sortable: false,
          width: 150,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          visible: false,
        },
        {
          key: 'tenant_id',
          title: formatMessage({ id: 'usims.table.tenantName' }),
          dataKey: 'tenant.name',
          sortable: false,
          width: 150,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
        },
        {
          key: 'created_at',
          title: formatMessage({ id: 'usims.table.created' }),
          dataKey: 'created_at',
          sortable: true,
          width: 140,
          maxWidth: 140,
          minWidth: 50,
          visible: false,
          defaultSort: 'desc', // set the columns sorted as default
          cellRenderer: ({ cellData: created_at }) => moment(created_at).format(localizedConfig.fullDateFormat),
        },
        {
          key: 'updated_at',
          title: formatMessage({ id: 'usims.table.updated' }),
          dataKey: 'updated_at',
          sortable: true,
          width: 140,
          maxWidth: 140,
          minWidth: 50,
          visible: false,
          cellRenderer: ({ rowData }) => {
            return rowData.created_at !== rowData.updated_at
              ? moment(rowData.updated_at).format(localizedConfig.fullDateFormat)
              : '-';
          },
        },
        {
          key: 'tools',
          title: '',
          dataKey: 'tools',
          width: 60,
          maxWidth: 60,
          minWidth: 60,
          secret: true, // secret used to hide from columns management panel
          cellRenderer: ({ rowData }) => {
            return getTools(rowData);
          },
        },
      ]
    );
  }, [bootstrap, formatMessage, getTools, localizedConfig.fullDateFormat, navigate, user?.tenant_type]);

  let actionsData: ListToolbarAction<Usim>[] = useMemo(() => {
    const actionsArray: (ListToolbarAction<Usim> | undefined | boolean)[] = [
      // not using checkpermissionslist due to absence of permission property on items
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.changeNode.title' }),
        onClick: openChangeNode,
      },
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.changeSite.title' }),
        onClick: openChangeSite,
      },
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.changeTenant.title' }),
        onClick: openChangeTenant,
      },
      bootstrap?.delete_usim &&
        checkPermissionToUse(user, U_PERMISSIONS.DELETE_USIM) && {
          label: formatMessage({ id: 'usims.actions.delete.title' }),
          onClick: openDelete,
        },
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.changeProfile.title' }),
        onClick: openChangeProfile,
      },
      checkPermissionToUse(user, U_PERMISSIONS.ACTIVATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.activate.title' }),
        onClick: openActivate,
      },
      checkPermissionToUse(user, U_PERMISSIONS.DEACTIVATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.deactivate.title' }),
        onClick: openDeactivate,
      },
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.deprovision.title' }),
        onClick: openDeprovision,
      },
      checkPermissionToUse(user, U_PERMISSIONS.BAR_USIM) && {
        label: formatMessage({ id: 'usims.actions.bar.title' }),
        onClick: openBar,
      },
      checkPermissionToUse(user, U_PERMISSIONS.UNBAR_USIM) && {
        label: formatMessage({ id: 'usims.actions.unbar.title' }),
        onClick: openUnbar,
      },
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.updateMetadata.title' }),
        onClick: openUpdateMetadata,
      },
      checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM) && {
        label: formatMessage({ id: 'usims.actions.deletemetadata.title' }),
        onClick: openDeleteMetadata,
      },
    ];

    return actionsArray.filter((item) => Boolean(item)) as ListToolbarAction<Usim>[];
  }, [
    bootstrap?.delete_usim,
    formatMessage,
    openActivate,
    openBar,
    openChangeNode,
    openChangeProfile,
    openChangeSite,
    openChangeTenant,
    openDeactivate,
    openDelete,
    openDeleteMetadata,
    openDeprovision,
    openUnbar,
    openUpdateMetadata,
    user,
  ]);

  return (
    <List
      disableSelectAll={false}
      selectable
      columns={columns}
      totalRows={usimsList.data.total}
      data={usimsList.data.data}
      page={usimsList.data.page}
      filters={usimsFilters}
      onOrderChange={handleSortChange}
      loadingState={loadingState}
      {...(canUserCreateUsim && { createComponent: <Create /> })}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      actions={actionsData}
      onDownload={openDownload}
      rowsPerPage={bootstrap?.pageLimit}
      onRefresh={handleRefresh}
      setFlushSelectionAction={setUsimShouldFlushSelection}
      shouldFlushSelection={shouldFlushSelection}
    />
  );
}
