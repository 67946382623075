import styled from 'styled-components';

export const PasswordStrengthStyled = styled.div`
  .password-strength-indicator {
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: space-between;

    & > div {
      margin: 0 2px;
      background-color: ${(props) => props.theme.passwordStrength.colorVeryWeak};
      width: 25%;

      &.password-weak {
        background-color: ${(props) => props.theme.passwordStrength.colorWeak};
      }

      &.password-soso {
        background-color: ${(props) => props.theme.passwordStrength.colorSoso};
      }

      &.password-good {
        background-color: ${(props) => props.theme.passwordStrength.colorGood};
      }

      &.password-great {
        background-color: ${(props) => props.theme.passwordStrength.colorGreat};
      }
    }
  }

  .password-strength-label {
    text-align: right;
    color: ${(props) => props.theme.passwordStrength.colorVeryWeak};

    &.password-weak {
      color: ${(props) => props.theme.passwordStrength.colorWeak};
    }

    &.password-soso {
      color: ${(props) => props.theme.passwordStrength.colorSoso};
    }

    &.password-good {
      color: ${(props) => props.theme.passwordStrength.colorGood};
    }

    &.password-great {
      color: ${(props) => props.theme.passwordStrength.colorGreat};
    }
  }
`;
