import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CDR_SOURCE_NETWORKS_AGGREGATION_TYPE, SourceNetwork, SourceNetworkAggregation } from 'store/models/cdr';
import { useCdrSourceNetworks } from './sourceNetwork';

export function useCdrTrafficDifferenceTotals() {
  const totals = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceTotals);
  return totals;
}

export function useTrafficDifferenceTotalByType(type: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE) {
  const totals = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceTotals);
  return totals.data?.find((total) => total.type === type);
}

export function useCdrTrafficDifferenceSeries() {
  const series = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceTrendSeries);
  return series;
}

export function useTrafficDifferenceSeriesByType(type: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE) {
  const series = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceTrendSeries);
  return series.data?.find((total) => total.type === type);
}

export function useCdrTrafficDifferenceAvailabilityDates() {
  const availabilityDates = useSelector(
    (state) => state.cdrReportsTrafficDifference.trafficDifferenceAvailabilityDates
  );
  return availabilityDates;
}

export function useCdrTrafficDifferenceRanking() {
  const ranking = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceRanking);
  return ranking;
}

export function useCdrTrafficDifferenceDateRange() {
  const dateRange = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceDateRange);
  return dateRange;
}

export function useCdrTrafficDifferencePeriod() {
  const period = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferencePeriod);
  return period;
}

export function useCdrTrafficDifferenceFilter() {
  const filter = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceFilter);
  return filter;
}

export function useCdrTrafficDifferenceRankingSeries() {
  const series = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceRankingSeries);
  return series;
}

export function useCdrTrafficDifferenceRankingMetric() {
  const metric = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceRankingMetric);
  return metric;
}

export function useCdrTrafficDifferenceRankingGroupBy() {
  const groupBy = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceRankingGroupBy);
  return groupBy;
}

export function useCdrTrafficDifferenceRankingCalendarDate() {
  const calendarDate = useSelector((state) => state.cdrReportsTrafficDifference.trafficDifferenceRankingCalendarDate);
  return calendarDate;
}

export function getCdrTrafficDifferenceSourceNetworksAggregations(
  sourceNetworks: SourceNetwork[]
): Record<CDR_SOURCE_NETWORKS_AGGREGATION_TYPE, SourceNetworkAggregation[]> {
  // ASSUMPTION: first in the list is the MVNO
  const [defaultSN, ...rest] = sourceNetworks;
  const operations: Record<CDR_SOURCE_NETWORKS_AGGREGATION_TYPE, SourceNetworkAggregation[]> = {
    [CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.DIFFERENCE]: [
      { source_network: defaultSN, operation: '+' },
      ...rest.map((sourceNetwork): SourceNetworkAggregation => ({ source_network: sourceNetwork, operation: '-' })),
    ],
    [CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MVNO]: [{ source_network: defaultSN, operation: '+' }],
    [CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MNO]: rest.map(
      (sourceNetwork): SourceNetworkAggregation => ({ source_network: sourceNetwork, operation: '+' })
    ),
  };
  return operations;
}

export function useCdrTrafficDifferenceSourceNetworksAggregations():
  | {
      type: CDR_SOURCE_NETWORKS_AGGREGATION_TYPE;
      source_networks_aggregation: SourceNetworkAggregation[];
    }[]
  | null {
  const sourceNetworks = useCdrSourceNetworks();

  const sourceNetworkAggregations = useMemo(() => {
    if (!sourceNetworks) {
      return null;
    }

    const cdrTrafficdifferenceSourceNetworksAggregations =
      getCdrTrafficDifferenceSourceNetworksAggregations(sourceNetworks);

    const cdrTrafficDifferenceSourceNetworksAggregationTypes = Object.keys(
      cdrTrafficdifferenceSourceNetworksAggregations
    ) as CDR_SOURCE_NETWORKS_AGGREGATION_TYPE[];

    return cdrTrafficDifferenceSourceNetworksAggregationTypes.map((type) => {
      return {
        type,
        source_networks_aggregation: cdrTrafficdifferenceSourceNetworksAggregations[type],
      };
    });
  }, [sourceNetworks]);

  return sourceNetworkAggregations;
}
