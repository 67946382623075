import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RESOURCE } from 'store/models/modal';
import { deleteTenantLogo, getTenant, updateTenantLogo } from 'store/actions/tenants';
import { Tenant } from 'store/models/tenant';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';

type UseHandlerProps =
  | {
      tenantId: string;
      resource: RESOURCE;
    }
  | {
      tenantId: null;
      resource: null;
    };

export default function useHandlers({ tenantId, resource }: UseHandlerProps) {
  const [tenant, setTenant] = useState<Tenant | null>(null);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleGetTenant = useCallback(async () => {
    if (!tenantId) {
      return;
    }

    const result: Tenant | null = await dispatch(getTenant(tenantId));

    setTenant(result);
    return result;
  }, [dispatch, tenantId]);

  useEffect(() => {
    void handleGetTenant();
  }, [dispatch, handleGetTenant]);

  const getErrorMessages = useCallback(
    (error: AxiosError) => {
      const errorCode: string = error.response?.data?.error;
      switch (errorCode) {
        case 'invalid image size':
          return formatMessage({ id: 'users.form.upload.error.pixels' });
        default:
          return errorCode;
      }
    },
    [formatMessage]
  );

  const handleUploadLogo = useCallback(
    async (file: Blob) => {
      if (!tenantId) {
        return;
      }

      try {
        await dispatch(updateTenantLogo(tenantId, file));
        dispatch(showSuccessToast());
      } catch (e: any) {
        dispatch(
          showErrorToast({
            message: getErrorMessages(e),
          })
        );
      }
    },
    [dispatch, getErrorMessages, tenantId]
  );

  const handleDeleteLogo = useCallback(async () => {
    if (!tenantId) {
      return;
    }
    try {
      await dispatch(deleteTenantLogo(tenantId));
      void handleGetTenant();
      dispatch(showSuccessToast());
    } catch (e) {
      dispatch(showErrorToast());
    }
  }, [dispatch, handleGetTenant, tenantId]);

  return {
    tenant,
    handleGetTenant,
    handleUploadLogo,
    handleDeleteLogo,
  };
}
