import PropTypes from 'prop-types';

// Style
import { GridStyled } from './styled';

const Grid = ({ container, item, className, spacing, xs, sm, md, lg, xl, children }) => {
  return (
    <GridStyled
      className={className}
      container={container}
      item={item}
      spacing={spacing}
      xl={xl}
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
    >
      {children}
    </GridStyled>
  );
};

Grid.propTypes = {
  className: PropTypes.string,
  container: PropTypes.bool,
  item: PropTypes.bool,
  spacing: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  children: PropTypes.any,
};

export default Grid;
