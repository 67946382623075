import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNodeEntitySelector } from 'store/selectors/nodes';
import { useServiceProfilesListSelector } from 'store/selectors/serviceProfiles';
import { getServiceProfiles } from 'store/actions/serviceProfiles';
import { useDispatch } from 'react-redux';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { List } from '@athonet/ui/components/Navigation/List';
import { ListItem } from '@athonet/ui/components/Navigation/List/ListItem';
import { NoData } from '@athonet/ui/components/Data/NoData';
import { isListLoading } from 'store/reducers';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { EditServiceProfile } from './EditServiceProfile';
import { useCanUserUpdateNodeSelector } from 'store/selectors/user';
import theme from '@athonet/ui/theme';
import { SlicesTable } from './SlicesTable';

export function ServiceProfiles() {
  const nodeEntity = useNodeEntitySelector();
  const serviceProfiles = useServiceProfilesListSelector();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  const selectedServiceProfile = useMemo(() => {
    return serviceProfiles.data.data[selectedIndex];
  }, [selectedIndex, serviceProfiles.data.data]);

  const entity = useMemo(() => {
    return nodeEntity.data;
  }, [nodeEntity]);

  useEffect(() => {
    if (!entity) {
      return;
    }

    void dispatch(getServiceProfiles(entity.id));
  }, [dispatch, entity]);

  const handleOpenNewServiceProfile = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'serviceProfiles.newServiceProfile' }),
      content: () => <EditServiceProfile />,
    });
  }, [dialogOpen, formatMessage]);

  if (!entity || !serviceProfiles) {
    return null;
  }

  return (
    <GridContainer spacing={0} sx={{ height: 'calc(100% - 108px)' }}>
      <GridItem size={{ xs: 12, md: 4 }}>
        <Panel
          title={formatMessage({ id: 'serviceProfiles.table.title' })}
          folder
          fullHeight
          sx={{ borderRight: 'none', borderRadius: '4px 0 0 4px' }}
          {...(canUserUpdateNode && {
            headerActionsComponent: (
              <Button
                text={formatMessage({ id: 'common.new' })}
                variant="outlined"
                onClick={handleOpenNewServiceProfile}
              />
            ),
          })}
        >
          <PanelContent sx={{ px: 0, mt: -2, mb: -3 }}>
            <List divided>
              {serviceProfiles.data.data.length ? (
                serviceProfiles.data.data.map((spData, index) => (
                  <ListItem
                    key={index}
                    selected={selectedIndex === index}
                    onClick={() => handleListItemClick(index)}
                    primaryText={spData.name}
                    secondaryText={`${spData.slices.length} Slices`}
                    endIcon="Right-2"
                  />
                ))
              ) : isListLoading(serviceProfiles) ? (
                <>
                  <Skeleton variant="rectangular" height={72} />
                  <Divider />
                  <Skeleton variant="rectangular" height={72} />
                  <Divider />
                </>
              ) : (
                <NoData />
              )}
            </List>
          </PanelContent>
        </Panel>
      </GridItem>
      {selectedServiceProfile ? (
        <GridItem size={{ xs: 12, md: 8 }} sx={{ height: '100%' }}>
          <SlicesTable serviceProfile={selectedServiceProfile} />
        </GridItem>
      ) : (
        <GridItem size={{ xs: 12, md: 8 }} sx={{ height: '100%', backgroundColor: theme.palette.common.white }}>
          <Panel fullHeight sx={{ borderRadius: '0 4px 4px 0', p: 8 }}>
            <NoData />
          </Panel>
        </GridItem>
      )}
    </GridContainer>
  );
}
