import styled from 'styled-components';

export const ConfirmationPopupSubsectionStyled = styled.div`
  position: relative;
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const ConfirmationPopupSubsectionField = styled.div`
  flex-basis: calc(50% - 16px);
  flex-grow: 0;
  flex-shrink: 0;

  .value {
    margin-left: 8px;
    font-size: ${(props) => props.theme.confirmationPopup.titleFontSize};
    color: ${(props) => props.theme.confirmationPopup.tenantColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
