import Icon from 'components/Icon';
import { useIntl } from 'react-intl';
import { ic_sim_card as ico_sim } from 'react-icons-kit/md/ic_sim_card';
import { ic_network_check as ico_simprofile } from 'react-icons-kit/md/ic_network_check';
import { mapMarker as ico_marker } from 'react-icons-kit/fa/mapMarker';
import { Tooltip } from '@material-ui/core';
import { getCountryLabel } from 'utils/getStatesCountries';
import { getTimezoneValue, getTimezoneLabel } from 'utils/getTimezones';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { SiteCardStyled } from './styled';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';

const SiteCard = ({ data, getTools }) => {
  const { formatMessage } = useIntl();

  return (
    <SiteCardStyled>
      <Panel title={data.name} headerActionsComponent={getTools(data)}>
        <PanelContent>
          <div className="site-card-icons">
            <span>
              <Tooltip title={formatMessage({ id: 'sites.table.usims' })} placement="top" arrow>
                <span className="site-card-icon">
                  <Icon icon={ico_sim} size={24} />
                </span>
              </Tooltip>
              {data.usims || 0}
            </span>
            <span>
              <Tooltip title={formatMessage({ id: 'sites.table.usimProfiles' })} placement="top" arrow>
                <span className="site-card-icon">
                  <Icon icon={ico_simprofile} size={24} />
                </span>
              </Tooltip>
              {data.profiles || 0}
            </span>
          </div>

          {data.tenant && (
            <div>
              <div className="site-card-title">{formatMessage({ id: 'sites.table.tenant' })}</div>
              <p>{data.tenant}</p>
            </div>
          )}

          {data.network_name && (
            <div>
              <div className="site-card-title">{formatMessage({ id: 'sites.table.networkName' })}</div>
              <p>{data.network_name}</p>
            </div>
          )}

          {data.additional_info && (
            <div>
              <div className="site-card-title">{formatMessage({ id: 'sites.table.info' })}</div>
              <p>{data.additional_info}</p>
            </div>
          )}

          <div className={(data.address || data.country) && 'site-card-address'}>
            {(data.address || data.country) && <Icon icon={ico_marker} size={24} />}
            <div>
              {data.address && <div>{data.address}</div>}

              {data.country ? (
                <p>{`${getCountryLabel(data.country)} ${data.timezone && getTimezoneValue(data.timezone)}`}</p>
              ) : (
                data.timezone && <span>{getTimezoneLabel(data.timezone)}</span>
              )}
            </div>
          </div>

          {data.coords.length > 0 && (
            <MapContainer className="site-card-map" center={data.coords.split(',')} zoom={13} scrollWheelZoom={true}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={data.coords.split(',')}>
                <Popup>
                  {data.name} <br /> {data.address}
                </Popup>
              </Marker>
            </MapContainer>
          )}
        </PanelContent>
      </Panel>
    </SiteCardStyled>
  );
};

export default SiteCard;
