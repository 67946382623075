import { Button } from '@athonet/ui/components/Input/Button';
import { DrawerContent } from '@athonet/ui/components/Overlay/Drawer/DrawerContent';
import { DrawerActions } from '@athonet/ui/components/Overlay/Drawer/DrawerActions';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BillingConfig } from 'store/models/billing';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { useDispatch } from 'react-redux';
import { Fieldset } from '@athonet/ui/components/Input/Fieldset';
import { Formik, Form, Field, FieldProps } from 'formik';
import { DateRange } from '@athonet/ui/components/Input/DateRange';
import { editBillingConfigurations, saveBillingConfigurations } from 'store/actions/billing/billingConfiguration';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment';
import { number, object, string } from 'yup';
import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';
import { useIntl } from 'react-intl';
import { getCdrTrafficVolumeSearch } from 'store/actions/cdrReports/trafficVolume';
import { useCdrDefaultSourceNetwork } from 'store/selectors/cdrReports/sourceNetwork';

type BillingDrawerContentProps = {
  billingConfig?: BillingConfig;
};

export default function BillingConfigurationForm({ billingConfig }: BillingDrawerContentProps) {
  const { drawerClose } = useOverlay();
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const { formatMessage } = useIntl();
  const validationDecimalLimit = useMemo(() => /^\d+(\.\d{1,2})?$/, []);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const searchTimeout = useRef<number | null>();
  const defaultSourceNetwork = useCdrDefaultSourceNetwork();

  const startLoadingSearchResults = useCallback(() => {
    setSearchLoading(true);
    setSearchResults([]);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      if (values && !billingConfig) {
        dispatch(saveBillingConfigurations(values));
      } else {
        dispatch(editBillingConfigurations(values));
      }
      drawerClose();
    },
    [billingConfig, dispatch, drawerClose]
  );

  const initialValues = useMemo(
    () =>
      billingConfig || {
        operator: '',
        cost_per_gb: 1,
        alarm_threshold: null,
        prepaid_bundle: null,
        sim_minimum_pay: null,
        start_date: moment().startOf('month').format(dateFormat),
        source_network: defaultSourceNetwork,
      },
    [defaultSourceNetwork, billingConfig]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        id: string(),
        operator: string().required(),
        cost_per_gb: number()
          .required()
          .min(1)
          .test('cost_per_gb', formatMessage({ id: 'billing.form.messageValidationRegex' }), (value) =>
            value ? validationDecimalLimit.test(value.toString()) : false
          ),
        alarm_threshold: number()
          .nullable()
          .min(1)
          .test('alarm_threshold', formatMessage({ id: 'billing.form.messageValidationRegex' }), (value) =>
            value ? validationDecimalLimit.test(value.toString()) : true
          ),
        prepaid_bundle: number()
          .nullable()
          .min(1)
          .test('prepaid_bundle', formatMessage({ id: 'billing.form.messageValidationRegex' }), (value) =>
            value ? validationDecimalLimit.test(value.toString()) : true
          ),
        sim_minimum_pay: number()
          .nullable()
          .min(1)
          .test('sim_minimum_pay', formatMessage({ id: 'billing.form.messageValidationRegex' }), (value) =>
            value ? validationDecimalLimit.test(value.toString()) : true
          ),
        start_date: string().required(),
        source_network: string(),
      }),
    [formatMessage, validationDecimalLimit]
  );

  const getSearchResults = useCallback(
    async (query) => {
      if (!defaultSourceNetwork) {
        return;
      }

      startLoadingSearchResults();
      const searchResultsOtions = await dispatch(
        getCdrTrafficVolumeSearch({
          field: 'operator',
          value: query,
          source_networks: [defaultSourceNetwork],
        })
      );
      setSearchLoading(false);
      setSearchResults(searchResultsOtions.results);
    },
    [defaultSourceNetwork, dispatch, startLoadingSearchResults]
  );

  //TODO: create new endpoint to return list of operators
  const handleAutoCompleteChange = useCallback(
    (selected, setFieldValue) => {
      setFieldValue('operator', selected);
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      startLoadingSearchResults();
      const timeout = window.setTimeout(() => {
        void getSearchResults(selected);
      }, 300);
      searchTimeout.current = timeout;
    },
    [getSearchResults, startLoadingSearchResults]
  );

  useEffect(() => {
    void getSearchResults('');
  }, [getSearchResults]);

  const handleChange = useCallback((value, name, setFieldValue) => {
    setFieldValue(name, value ? Number(value) : null);
  }, []);

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
      >
        {({ isValid, dirty, setFieldValue, errors, touched, values }) => {
          return (
            <Form style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
              <DrawerContent>
                <Fieldset sx={{ pt: 1 }} direction="column">
                  <Field name="start_date">
                    {({ field }: FieldProps<string>) =>
                      !billingConfig ? (
                        <DateRange
                          {...field}
                          onChange={(_period, _sDate, eDate) => {
                            setFieldValue('start_date', moment(eDate).format(dateFormat));
                          }}
                          startDate={moment(field.value, dateFormat)}
                          color={'primary'}
                          singleDate={true}
                          period={'month'}
                          periods={['month']}
                          textFieldRender
                          data-testid="billingconfiguration-toolbar-drawer-start_date-daterange"
                        />
                      ) : (
                        <TextField
                          {...field}
                          disabled
                          value={field.value}
                          label={'Start Date'} //TODO: intl
                          size="small"
                          variant={'outlined'}
                        />
                      )
                    }
                  </Field>
                  <Field name="operator">
                    {({ field }: FieldProps<string>) => (
                      <Autocomplete
                        {...field}
                        value={field.value}
                        label={'Operator'} //TODO: intl
                        onChange={(e, selected) => handleAutoCompleteChange(selected, setFieldValue)}
                        onInputChange={(e, selected) => handleAutoCompleteChange(selected, setFieldValue)}
                        size="small"
                        disabled={billingConfig ? true : false}
                        loading={searchLoading}
                        options={searchResults}
                        multiple={false}
                        freeSolo
                        loadingText="Searching..."
                        error={Boolean(errors.operator && touched.operator)}
                        helperText={Boolean(errors.operator && touched.operator) ? errors.operator : ''}
                        data-testid="billingconfiguration-toolbar-drawer-operator-textfield"
                      />
                    )}
                  </Field>
                  <Field name="cost_per_gb">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        required
                        value={field.value}
                        label={'Cost X GB'} //TODO: intl
                        size="small"
                        variant={'outlined'}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        type="number"
                        error={Boolean(errors.cost_per_gb && touched.cost_per_gb)}
                        helperText={Boolean(errors.cost_per_gb && touched.cost_per_gb) ? errors.cost_per_gb : ''}
                        data-testid="billingconfiguration-toolbar-drawer-cost_per_gb-textfield"
                      />
                    )}
                  </Field>
                  <Field name="alarm_threshold">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label={'Alarm Threshold'} //TODO: intl
                        size="small"
                        variant={'outlined'}
                        onChange={(e) => handleChange(e.target.value, 'alarm_threshold', setFieldValue)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        error={Boolean(errors.alarm_threshold && touched.alarm_threshold)}
                        helperText={
                          Boolean(errors.alarm_threshold && touched.alarm_threshold) ? errors.alarm_threshold : ''
                        }
                        type="number"
                        data-testid="billingconfiguration-toolbar-drawer-alarm_threshold-textfield"
                      />
                    )}
                  </Field>
                  <Field name="prepaid_bundle">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label={'Prepaid Bundle'} //TODO: intl
                        size="small"
                        variant={'outlined'}
                        tooltip={"It's a prepaid of all SIM's in the month"}
                        onChange={(e) => handleChange(e.target.value, 'prepaid_bundle', setFieldValue)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        error={Boolean(errors.prepaid_bundle && touched.prepaid_bundle)}
                        helperText={
                          Boolean(errors.prepaid_bundle && touched.prepaid_bundle) ? errors.prepaid_bundle : ''
                        }
                        type="number"
                        data-testid="billingconfiguration-toolbar-drawer-prepaid_bundle-textfield"
                      />
                    )}
                  </Field>
                  <Field name="sim_minimum_pay">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label={'SIM minimum pay'} //TODO: intl
                        size="small"
                        variant={'outlined'}
                        tooltip={"It's the minimum cost to pay for each SIM"}
                        onChange={(e) => handleChange(e.target.value, 'sim_minimum_pay', setFieldValue)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        error={Boolean(errors.sim_minimum_pay && touched.sim_minimum_pay)}
                        helperText={
                          Boolean(errors.sim_minimum_pay && touched.sim_minimum_pay) ? errors.sim_minimum_pay : ''
                        }
                        type="number"
                        data-testid="billingconfiguration-toolbar-drawer-sim_minimum_pay-textfield"
                      />
                    )}
                  </Field>
                </Fieldset>
              </DrawerContent>
              <DrawerActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  text="Cancel"
                  onClick={drawerClose}
                  data-testid="billingconfiguration-toolbar-drawer-cancel-button"
                />
                <Button
                  disabled={!isValid || !dirty}
                  type="submit"
                  sx={{ width: 'fit-content' }}
                  text="Save"
                  color="secondary"
                  data-testid="billingconfiguration-toolbar-drawer-save-button"
                />
              </DrawerActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
