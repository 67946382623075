import { useCallback, useMemo } from 'react';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';
import { useDispatch } from 'react-redux';
import { createTimHss } from 'store/actions/nodes';
import { parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';

const schema = object().shape({
  name: string().trim().required(),
  url: string().trim().required(),
  client_id: string().trim().max(50).required(),
  consumer_key: string().trim().required(),
  consumer_secret: string().trim().required(),
  username: string().trim().max(255).required(),
  tenant: string().trim().max(255).required(),
  password: string().trim().required(),
  certificate: string().trim(),
});

const initials = {
  name: '',
  url: '',
  client_id: '',
  consumer_key: '',
  consumer_secret: '',
  username: '',
  tenant: '',
  password: '',
  certificate: '',
};

export function CreateTimHss() {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const fieldset = useMemo(
    () => [
      {
        title: formatMessage({ id: 'timHss.form.data' }),
        data: [
          {
            name: 'name',
            label: formatMessage({ id: 'timHss.form.name.label' }),
            placeholder: formatMessage({ id: 'timHss.form.name.placeholder' }),
            error: formatMessage({ id: 'timHss.form.name.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'url',
            label: formatMessage({ id: 'timHss.form.url.label' }),
            placeholder: formatMessage({ id: 'timHss.form.url.placeholder' }),
            error: formatMessage({ id: 'timHss.form.url.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'client_id',
            label: formatMessage({ id: 'timHss.form.client_id.label' }),
            placeholder: formatMessage({ id: 'timHss.form.client_id.placeholder' }),
            error: formatMessage({ id: 'timHss.form.client_id.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'consumer_key',
            label: formatMessage({ id: 'timHss.form.consumer_key.label' }),
            placeholder: formatMessage({ id: 'timHss.form.consumer_key.placeholder' }),
            error: formatMessage({ id: 'timHss.form.consumer_key.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'consumer_secret',
            label: formatMessage({ id: 'timHss.form.consumer_secret.label' }),
            placeholder: formatMessage({ id: 'timHss.form.consumer_secret.placeholder' }),
            error: formatMessage({ id: 'timHss.form.consumer_secret.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'username',
            label: formatMessage({ id: 'timHss.form.username.label' }),
            placeholder: formatMessage({ id: 'timHss.form.username.placeholder' }),
            error: formatMessage({ id: 'timHss.form.username.error' }),
          },
          {
            name: 'tenant',
            label: formatMessage({ id: 'timHss.form.tenant.label' }),
            placeholder: formatMessage({ id: 'timHss.form.tenant.placeholder' }),
            error: formatMessage({ id: 'timHss.form.tenant.error' }),
          },
          {
            name: 'password',
            label: formatMessage({ id: 'timHss.form.password.label' }),
            placeholder: formatMessage({ id: 'timHss.form.password.placeholder' }),
            error: formatMessage({ id: 'timHss.form.password.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'certificate',
            label: formatMessage({ id: 'timHss.form.certificate.label' }),
            placeholder: formatMessage({ id: 'timHss.form.certificate.placeholder' }),
            error: formatMessage({ id: 'timHss.form.certificate.error' }),
            gridClassName: 'fullwidth',
          },
        ],
      },
    ],
    [formatMessage]
  );

  const handleSubmit = useCallback(
    async (values, callback) => {
      const timHssData = {
        name: values.name.trim(),
        payload: {
          url: values.url.trim(),
          client_id: values.client_id.trim(),
          consumer_key: values.consumer_key.trim(),
          consumer_secret: values.consumer_secret.trim(),
          username: values.username.trim(),
          tenant: values.tenant.trim(),
          password: values.password.trim(),
          certificate: values.certificate.trim() !== '' ? values.certificate.trim() : null,
        },
      };
      try {
        await dispatch(createTimHss(timHssData));
        callback(true);
      } catch (e) {
        sentryLogError(e);
        callback(false, parseError(e));
      }
    },
    [dispatch]
  );

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldset}
        onSubmit={handleSubmit}
        modalTitle={formatMessage({ id: 'timHss.newTimHss' })}
        elLabel={formatMessage({ id: 'timHss.newTimHss' })}
      />
    </FormStyled>
  );
}
