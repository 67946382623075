import { Field, SelectOption } from 'utils/forms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Role } from 'store/models/role';
import { Owner, OWNER_PERMISSION, SubTenant, Tenant } from 'store/models/tenant';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RESOURCE } from 'store/models/modal';
import useQosProfileHandlers from './segments/qosProfiles';
import useUserHandlers from './users';
import { string, object } from 'yup';
import { AxiosError } from 'axios';
import { generateSelectOptions } from 'utils/forms';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useRolesListSelector } from 'store/selectors/roles';
import { useTenantsWithSubtenantsSelector } from 'store/selectors/tenants';
import { DATA_LIFECYCLE } from 'store/reducers';
import { getTenantsWithSubtenants, getTenantsOptions } from 'store/actions/tenants';
import { getRoles } from 'store/actions/roles'; // todo merge
import { Multiselect, MultiSelectProps } from 'components/Form/Multiselect';
import useNodeHandlers from './nodes';
import useSegmentHandlers from './segments/segments';
import useUsimProfileHandlers from './usimProfiles';

type UseHandlerProps =
  | {
      resourceId: string;
      resource: RESOURCE;
    }
  | {
      resourceId: null;
      resource: null;
    };

type ExtendedResource<T> = T & {
  additionalInfo: string;
};

export type TenantsResult = ExtendedResource<Owner>[];

export const permissionsIntl = function (value: OWNER_PERMISSION): string {
  let permission;
  switch (value) {
    case 1:
      permission = 'read';
      break;
    default:
      permission = 'readWrite';
      break;
  }
  return permission;
};

export default function useHandlers({ resourceId, resource }: UseHandlerProps) {
  const { formatMessage } = useIntl();
  const [result, setResult] = useState<TenantsResult>([]);
  const tenantsWithSubtenants = useTenantsWithSubtenantsSelector();
  const rolesList = useRolesListSelector();

  const dispatch = useDispatch();

  const selectOptions = useMemo(() => {
    const permissions = [
      { value: 255, label: formatMessage({ id: 'segments.form.qosProfile.permissions.readWrite' }) },
      { value: 1, label: formatMessage({ id: 'segments.form.qosProfile.permissions.read' }) },
    ];

    if (tenantsWithSubtenants.state !== DATA_LIFECYCLE.SUCCESS && rolesList.state !== DATA_LIFECYCLE.SUCCESS) {
      return { permissions };
    }

    const [roles, tenants] = generateSelectOptions<Role | SubTenant>({
      data: [
        {
          items: rolesList.data.data,
          transform: (role) => ({
            label: role.name,
            value: role.name,
          }),
        },
        {
          items: tenantsWithSubtenants.data.data,
          transform: (tenant) => ({
            label: tenant.name,
            value: tenant.id,
          }),
        },
      ],
    });

    return {
      roles,
      tenants,
      permissions,
    };
  }, [formatMessage, rolesList, tenantsWithSubtenants]);

  const {
    userSchema,
    userFormTitles,
    userfields,
    handleGetTenantsByUser,
    handleAddTenantToUser,
    handleDeleteTenantFromUser,
  } = useUserHandlers(selectOptions, resourceId || '');

  const {
    qosProfileSchema,
    qosProfileFormTitles,
    qosProfilefields,
    handlegetTenantByQosProfile,
    handleAddTenantToQosProfile,
    handleDeleteTenantFromQosProfile,
  } = useQosProfileHandlers(selectOptions, resourceId || '');

  /* NOTE: CC-1285 */
  const {
    segmentSchema,
    segmentFormTitles,
    segmentfields,
    handleGetTenantsBySegment,
    handleAddTenantToSegment,
    handleDeleteTenantFromSegment,
  } = useSegmentHandlers(selectOptions, resourceId || '');

  const {
    usimProfileSchema,
    usimProfileFormTitles,
    usimProfilefields,
    handleGetTenantsByUsimProfile,
    handleAddTenantToUsimProfile,
    handleDeleteTenantFromUsimProfile,
  } = useUsimProfileHandlers(selectOptions, resourceId || '');

  const { nodeFields, nodeTitles, handleGetTenantsByNode, handleAddTenantToNode, handleDeleteTenantFromNode } =
    useNodeHandlers(selectOptions, resourceId || '');

  // TODO handle
  const getErrorMessages = useCallback(
    (error: AxiosError): string | undefined => {
      const errorCode = error.response?.data?.error;
      switch (errorCode) {
        case 'invalid role':
          return formatMessage({ id: 'users.form.tenants.error.wrongrole' });
        case 'field_role_not_nullable':
          return formatMessage({ id: 'users.form.tenants.error.emptyrole' });
        case 'user already in tenant':
          return formatMessage({ id: 'users.form.tenants.error.conflict' });
        case 'node already associated':
          return formatMessage({ id: 'nodes.form.tenants.error.conflict' });
        case 'cannot deassociate user':
          return formatMessage({ id: 'users.form.tenants.error.deassociate' });
        case 'cannot deassociate user from tenant':
          return formatMessage({ id: 'users.form.tenants.error.deassociate' });
        default:
          return 'Unknown Error';
      }
    },
    [formatMessage]
  );

  const handleGetTenantsByResource = useCallback(async () => {
    let owners: Owner[] | null = [];
    let res: TenantsResult = [];

    if (!resourceId) {
      return;
    }

    try {
      switch (resource) {
        case RESOURCE.USERS:
          owners = await handleGetTenantsByUser();
          break;
        case RESOURCE.SEGMENT:
          owners = await handleGetTenantsBySegment();
          break;
        case RESOURCE.QOS_PROFILE_4G:
          owners = await handlegetTenantByQosProfile();
          break;
        case RESOURCE.NODES:
          owners = await handleGetTenantsByNode();
          break;
        case RESOURCE.USIM_PROFILE:
          owners = await handleGetTenantsByUsimProfile();
          break;
        default: {
          break;
        }
      }
    } catch (e: any) {
      const message = getErrorMessages(e);
      dispatch(showErrorToast(message ? { message } : undefined));
    }

    if (owners) {
      switch (resource) {
        case RESOURCE.USERS:
          res = owners.map((item) => ({
            ...item,
            additionalInfo: item.roles?.join(', ') || '',
          }));
          break;
        case RESOURCE.NODES:
          res = owners.map((item) => ({
            ...item,
            additionalInfo: '',
          }));
          break;
        default:
          res = owners.map((item) => ({
            ...item,
            additionalInfo: item.permissions
              ? formatMessage({
                  id: `segments.form.qosProfile.permissions.${permissionsIntl(item.permissions)}`,
                })
              : '',
          }));
          break;
      }
    }

    setResult(res);
  }, [
    resourceId,
    resource,
    handleGetTenantsByUser,
    handleGetTenantsBySegment,
    handlegetTenantByQosProfile,
    handleGetTenantsByNode,
    handleGetTenantsByUsimProfile,
    getErrorMessages,
    dispatch,
    formatMessage,
  ]);

  useEffect(() => {
    dispatch(getTenantsWithSubtenants());
    dispatch(getRoles());
    void handleGetTenantsByResource();
  }, [dispatch, handleGetTenantsByResource]);

  const handleAddTenantToResource = useCallback(
    async (
      values: { tenant: Tenant['id']; roles?: Role['name']; permissions?: string },
      callback: (result: boolean | null, msg?: string) => void
    ) => {
      if (!resourceId) {
        return;
      }

      let res = null;

      try {
        switch (resource) {
          case RESOURCE.USERS:
            if (values.roles) {
              res = await handleAddTenantToUser(values.tenant, values.roles);
            } else {
              callback(false);
            }
            break;
          case RESOURCE.SEGMENT:
            if (values.permissions) {
              res = await handleAddTenantToSegment(values.tenant, values.permissions);
            } else {
              callback(false);
            }
            break;
          case RESOURCE.QOS_PROFILE_4G:
            if (values.permissions) {
              res = await handleAddTenantToQosProfile(values.tenant, values.permissions);
            } else {
              callback(false);
            }
            break;
          case RESOURCE.NODES:
            res = await handleAddTenantToNode(values.tenant);
            break;
          case RESOURCE.USIM_PROFILE:
            if (values.permissions) {
              res = await handleAddTenantToUsimProfile(values.tenant, values.permissions);
            } else {
              callback(false);
            }
            break;
        }
        callback(null);
      } catch (e: any) {
        callback(false, getErrorMessages(e));
      }

      return res;
    },
    [
      getErrorMessages,
      handleAddTenantToNode,
      handleAddTenantToQosProfile,
      handleAddTenantToSegment,
      handleAddTenantToUser,
      handleAddTenantToUsimProfile,
      resource,
      resourceId,
    ]
  );

  const handleDeleteTenantFromResource = useCallback(
    async (tenantId: string) => {
      if (!resourceId) {
        return;
      }

      let res = null;

      try {
        switch (resource) {
          case RESOURCE.USERS:
            res = await handleDeleteTenantFromUser(tenantId);
            break;
          case RESOURCE.SEGMENT:
            res = await handleDeleteTenantFromSegment(tenantId);
            break;
          case RESOURCE.QOS_PROFILE_4G:
            res = await handleDeleteTenantFromQosProfile(tenantId);
            break;
          case RESOURCE.NODES:
            res = await handleDeleteTenantFromNode(tenantId);
            break;
          case RESOURCE.USIM_PROFILE:
            res = await handleDeleteTenantFromUsimProfile(tenantId);
            break;
        }
        await dispatch(showSuccessToast());
      } catch (e: any) {
        const message = getErrorMessages(e);
        dispatch(showErrorToast(message ? { message } : undefined));
      }
      return res;
    },
    [
      dispatch,
      getErrorMessages,
      handleDeleteTenantFromNode,
      handleDeleteTenantFromQosProfile,
      handleDeleteTenantFromSegment,
      handleDeleteTenantFromUser,
      handleDeleteTenantFromUsimProfile,
      resource,
      resourceId,
    ]
  );

  const schema = useMemo(() => {
    switch (resource) {
      case RESOURCE.USERS:
        return userSchema;
      case RESOURCE.SEGMENT:
        return segmentSchema;
      case RESOURCE.QOS_PROFILE_4G:
        return qosProfileSchema;
      case RESOURCE.USIM_PROFILE:
        return usimProfileSchema;
      default:
        return object().shape({
          tenant: string().required(),
        });
    }
  }, [qosProfileSchema, resource, segmentSchema, userSchema, usimProfileSchema]);

  const modalTitles = useMemo((): {
    modalTitle: string;
    fieldsetTitle: string;
    modalDescription?: string;
  } => {
    switch (resource) {
      case RESOURCE.USERS:
        return userFormTitles;
      case RESOURCE.SEGMENT:
        return segmentFormTitles;
      case RESOURCE.QOS_PROFILE_4G:
        return qosProfileFormTitles;
      case RESOURCE.NODES:
        return nodeTitles;
      case RESOURCE.USIM_PROFILE:
        return usimProfileFormTitles;
      default:
        return {
          modalTitle: 'common.form.editTenant',
          fieldsetTitle: 'common.form.selectTenant',
        };
    }
  }, [nodeTitles, qosProfileFormTitles, resource, segmentFormTitles, userFormTitles, usimProfileFormTitles]);

  const fieldlist = useMemo(() => {
    let fields: Field<
      Pick<MultiSelectProps<SelectOption[], true>, 'asyncGetOptions' | 'options' | 'selectAll' | 'selectAllLabel'>
    >[] = [];

    switch (resource) {
      case RESOURCE.USERS:
        fields = userfields;
        break;
      case RESOURCE.SEGMENT:
        fields = segmentfields;
        break;
      case RESOURCE.QOS_PROFILE_4G:
        fields = qosProfilefields;
        break;
      case RESOURCE.NODES:
        fields = nodeFields;
        break;
      case RESOURCE.USIM_PROFILE:
        fields = usimProfilefields;
        break;
      default:
        fields = [
          {
            name: 'tenant',
            label: formatMessage({ id: 'users.form.tenant.label' }),
            placeholder: formatMessage({ id: 'users.form.tenant.placeholder' }),
            error: formatMessage({ id: 'users.form.tenant.error' }),
            options: selectOptions.tenants,
            gridClassName: 'fullwidth',
          },
        ];
        break;
    }

    const tenantField = fields.find((x) => x.name === 'tenant');

    if (tenantField !== undefined) {
      tenantField.fieldComponent = Multiselect;
      tenantField.componentProps = {
        options: [], // async multiselect,
        asyncGetOptions: async (val?: string) => {
          return dispatch(getTenantsOptions(val));
        },
        selectAll: false,
        selectAllLabel: formatMessage({ id: 'common.button.selectAll' }),
      };
    }

    return fields;
  }, [
    resource,
    userfields,
    segmentfields,
    qosProfilefields,
    nodeFields,
    usimProfilefields,
    formatMessage,
    selectOptions.tenants,
    dispatch,
  ]);

  const initials = useMemo(() => {
    let initialsObj = {};
    fieldlist
      .map((field) => field.name as string)
      .forEach(
        (name) =>
          (initialsObj = {
            ...initialsObj,
            [name]: '',
          })
      );
    return initialsObj;
  }, [fieldlist]);

  return {
    handleGetTenantsByResource,
    handleAddTenantToResource,
    handleDeleteTenantFromResource,
    result,
    schema,
    fieldlist,
    initials,
    modalTitles,
    getErrorMessages,
  };
} // endOfUsehandlers
