import { useEffect } from 'react';

import Layout from 'containers/Layout';
import { loginUser } from 'store/actions/login';
import { useDispatch, useSelector } from 'react-redux';
import { lsGet } from 'utils/localStorage';
import { AuthLayout } from 'containers/Layout/AuthLayout';
import { User } from 'store/models/user';

export default function OptionalAuthLayout() {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const persistedUser = lsGet<User>();

    if (persistedUser?.accessToken) {
      dispatch(loginUser(persistedUser));
    }
  }, [dispatch]);

  if (user?.id) {
    return <Layout />;
  }

  return <AuthLayout />;
}
