import { useIntl } from 'react-intl';
import moment from 'moment';
import { useEffect, useState } from 'react';
import config from 'config';
import { Button } from '@athonet/ui/components/Input/Button';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { UsimEventsStyled } from './styled';
import { loadUsimEvents } from 'store/actions/usims';
import { useDispatch } from 'react-redux';
import Spinner from 'components/Spinner';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';

const UsimEvents = ({ usim }) => {
  const { formatMessage, locale } = useIntl();
  const [configLocale] = locale.split('-');
  const dispatch = useDispatch();
  const localizedConfig = config[configLocale] || config.en;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dialogClose } = useOverlay();

  const handleClose = () => {
    dialogClose();
  };

  useEffect(() => {
    async function getEvents() {
      setLoading(true);
      const fetchedEvents = await dispatch(loadUsimEvents(usim));
      setEvents(fetchedEvents);
      setLoading(false);
    }
    void getEvents();
  }, [dispatch, usim]);

  const getUser = (event) => {
    let res = '';
    if (event.operator) {
      res = event.operator.user.name + ' (' + event.operator.tenant.name + ')';
    }
    return res;
  };

  return (
    <>
      <DialogContent>
        {loading ? (
          <Spinner className="spinner" size={40} />
        ) : (
          <List>
            {events.map((event, index) => {
              return (
                <UsimEventsStyled key={index.toString()}>
                  <ListItem className="usim-events-row">
                    <div className="usim-events-row-data">
                      <div className="usim-events-row-timestamp">
                        {moment(event.timestamp).format(localizedConfig.fullDateFormat)}
                      </div>
                      <div className="usim-events-row-label">{event.event_type}</div>
                      <div className="usim-events-row-user">{getUser(event)}</div>
                    </div>
                  </ListItem>
                  {events.length > index + 1 && <Divider variant="middle" />}
                </UsimEventsStyled>
              );
            })}
          </List>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} data-testid="footer-cancel" text={formatMessage({ id: 'common.form.close' })} />
      </DialogActions>
    </>
  );
};

export default UsimEvents;
