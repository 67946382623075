import { CdrTrafficVolumeSeries, CdrTrafficVolumeTotal } from './cdr';
import { UsimEvent } from './usimEvent';

export type Usim = {
  batch_id: string;
  created_at: string;
  encrypt: number;
  error: boolean;
  iccid: string;
  id: string;
  ims_profile: boolean;
  imsi: string;
  imsi2: string | null;
  is_dual_imsi: boolean;
  k: string | null;
  metadata: Record<string, string>;
  msisdn: string;
  msisdn_status: string;
  name: string;
  node: {
    display_name: string;
    id: string;
    name: string;
  };
  op: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  opc: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  order: {
    description: string;
    id: string;
    order_id: number;
    created_at: string;
    updated_at: string;
    batch_id: string;
  };
  private_identity: string | null;
  profile: {
    id: string;
    name: string;
  };
  regional_subscriptions_profile_id: string | null;
  site: {
    id: string;
    name: string;
    address: string;
    coords: string;
    country: string;
  };
  status: string;
  tenant: {
    id: string;
    name: string;
  };
  updated_at: string;
  updating: boolean;
  use_key: string | null;
};

export type UsimFields = Usim & {
  single: boolean;
  upload: File;
  metadata_delete: string | null;
  key_type: number;
  key_override: boolean;
  use_default_tk: boolean;
};

export type UsimDetail = Usim & {
  events: UsimEvent[];
  totalTraffic?: CdrTrafficVolumeTotal<number | null>;
  trafficSeries?: CdrTrafficVolumeSeries;
};

export const imsiRegExp = /^[0-9]{1,15}?$/;
export const imsiExactRegExp = /^[0-9]{10,15}?$/;
export const iccidRegExp = /^[0-9]{1,20}?$/;
export const iccidExactRegExp = /^[0-9]{19,20}?$/;
export const msisdnRegExp = /^[0-9]{5,15}?$/;
export const tkRegExp = /^[a-zA-Z0-9]+?$/;
export const keysRegExp = /^[0-9a-fA-F]{32}?$/;
export const usimOperatorCountryNotAvailableRexExp = /(UTRAN|GERAN)/i;
export const SUPI_PADDING = 'imsi-';
export const MSISDN_PADDING = '+';

export function getKText({ k, encrypt, use_key }: Pick<Usim, 'k' | 'encrypt' | 'use_key'>) {
  const tk = use_key && use_key !== '' ? use_key : 'HSS default TK';
  return `${!k ? 'Not set -' : ''} ${encrypt === 1 ? 'Encrypted with ' + tk : 'Plain'}`;
}
