import { TextField } from '@athonet/ui/components/Input/TextField';
import { Fieldset } from '@athonet/ui/components/Input/Fieldset';
import { Select } from '@athonet/ui/components/Input/Select';
import { Autocomplete } from '@athonet/ui/components/Input/Autocomplete';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { Button } from '@athonet/ui/components/Input/Button';
import { object, string } from 'yup';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { CdrFilter, CdrGroupByOptions, CdrSchemaField, CdrSearch } from 'store/models/cdr';
import { useDispatch } from 'react-redux';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Entity } from 'store/reducers';
import { CdrSchemaState } from 'store/reducers/cdrReports';
import { GetCdrSearchAction } from 'store/actions/cdrReports';
import { useCdrActiveSourceNetwork } from 'store/selectors/cdrReports/sourceNetwork';

export type CdrFilterProps = {
  filter: CdrFilter<string>;
  setFilter: (payload: CdrFilter<string>) => void;
  groupByOptions: CdrGroupByOptions[];
  schema: Entity<CdrSchemaState>;
  getSearch: GetCdrSearchAction;
};

export type CdrFiltersGroupFilterProps = {
  fieldProps: FieldProps<string, CdrFilter<string>>;
  schema: Entity<CdrSchemaState>;
  groupFilterItem?: CdrSchemaField;
  getSearch: CdrFilterProps['getSearch'];
};

function CdrFiltersGroupFilter({ fieldProps, groupFilterItem, getSearch }: CdrFiltersGroupFilterProps) {
  const {
    field,
    form: { values, errors, touched, setFieldValue },
  } = fieldProps;
  const [searchResultsOptions, setSearchResultsOptions] = useState<string[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const searchTimeout = useRef<number | null>();
  const dispatch = useDispatch();
  const activeSourceNetwork = useCdrActiveSourceNetwork();

  const startLoadingSearchResults = useCallback(() => {
    setSearchLoading(true);
    setSearchResultsOptions([]);
  }, []);

  const getSearchResults = useCallback(
    async (query: string) => {
      if (!groupFilterItem || !activeSourceNetwork) {
        return;
      }
      startLoadingSearchResults();
      const searchResults: CdrSearch = await dispatch(
        getSearch({
          field: groupFilterItem?.field,
          value: query,
          source_networks: [activeSourceNetwork],
        })
      );
      setSearchLoading(false);
      setSearchResultsOptions(searchResults.results);
    },
    [dispatch, getSearch, groupFilterItem, activeSourceNetwork, startLoadingSearchResults]
  );

  const handleAutoCompleteChange = useCallback(
    (_, query: string | null) => {
      const value = query || '';
      setFieldValue('group_filter', value);
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      startLoadingSearchResults();
      const timeout = window.setTimeout(() => {
        void getSearchResults(value);
      }, 300);
      searchTimeout.current = timeout;
    },
    [getSearchResults, setFieldValue, startLoadingSearchResults]
  );

  useEffect(() => {
    if (groupFilterItem && groupFilterItem.search) {
      void getSearchResults('');
    }
  }, [getSearchResults, groupFilterItem]);

  if (groupFilterItem?.type === 'string' || groupFilterItem?.type === 'numeric') {
    if (groupFilterItem.search) {
      return (
        <Autocomplete
          {...field}
          value={field.value}
          label={`${groupFilterItem.label} value`}
          onChange={handleAutoCompleteChange}
          onInputChange={handleAutoCompleteChange}
          size="small"
          disabled={values.group_by === ''}
          loading={searchLoading}
          options={searchResultsOptions}
          multiple={false}
          error={Boolean(errors.group_filter && touched.group_filter)}
          helperText={Boolean(errors.group_filter && touched.group_filter) ? errors.group_filter : ''}
          freeSolo
          loadingText="Searching..."
          data-testid="cdr-toolbar-filter-popover-filter-by-textfield"
        />
      );
    }

    return (
      <TextField
        label={`${groupFilterItem.label} value`}
        size="small"
        {...field}
        disabled={values.group_by === ''}
        data-testid="cdr-toolbar-filter-popover-filter-by-textfield"
        error={Boolean(errors.group_filter && touched.group_filter)}
        helperText={Boolean(errors.group_filter && touched.group_filter) ? errors.group_filter : ''}
      />
    );
  }

  if (Array.isArray(groupFilterItem?.type)) {
    return (
      <Select {...field} size="small" label="Filter" data-testid="cdr-toolbar-filter-popover-filter-by-select">
        {groupFilterItem?.type.map((value) => {
          return (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  return null;
}

export default function CdrFilters({ setFilter, filter, groupByOptions, schema, getSearch }: CdrFilterProps) {
  const dispatch = useDispatch();
  const { popoverClose } = useOverlay();

  const handleOnSubmit = useCallback(
    (values: CdrFilter<string>) => {
      popoverClose();
      dispatch(setFilter(values));
    },
    [dispatch, popoverClose, setFilter]
  );

  const handleReset = useCallback(
    (setValues) => {
      const newValues = {
        group_by: '',
        group_filter: '',
      };
      setValues(newValues);
      dispatch(setFilter(newValues));
    },
    [dispatch, setFilter]
  );

  const getGroupFilterItem = useCallback(
    (groupByValue: CdrGroupByOptions['value']) => {
      return schema.data?.schema.find((item) => item.field === groupByValue);
    },
    [schema]
  );

  const filtersValidationSchema = useMemo(
    () =>
      object().shape({
        group_by: string().required(),
        group_filter: string().when('group_by', (value, s) => {
          const groupFilterItem = getGroupFilterItem(value);
          let validationSchema = s;
          if (!groupFilterItem) {
            return validationSchema;
          }
          const pattern = groupFilterItem?.pattern;
          if (pattern) {
            const regexp = new RegExp(pattern);
            validationSchema = validationSchema.matches(regexp, `Please enter a valid ${groupFilterItem.label} value.`);
          }
          return validationSchema.required(`${groupFilterItem.label} value cannot be left empty.`);
        }),
      }),
    [getGroupFilterItem]
  );

  // TODO: intl
  return (
    <Box sx={{ width: '240px' }}>
      <Formik
        initialValues={filter}
        onSubmit={handleOnSubmit}
        validationSchema={filtersValidationSchema}
        validateOnMount={true}
      >
        {({ isValid, dirty, setValues, setFieldValue, values, setErrors, setTouched }) => {
          return (
            <Form>
              <Fieldset direction="column">
                <Field name="group_by">
                  {({ field }: FieldProps<string>) => (
                    <Select
                      {...field}
                      size="small"
                      label="Filter By"
                      onChange={(e) => {
                        field.onChange(e);
                        setTouched({
                          group_filter: false,
                        });
                        setFieldValue('group_filter', '');
                      }}
                      data-testid="cdr-toolbar-filter-popover-group-by-select"
                    >
                      {groupByOptions?.map(({ value, label }) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Field>
                <Field name="group_filter">
                  {(fieldProps: FieldProps<string, CdrFilter<string>>) => (
                    <CdrFiltersGroupFilter
                      fieldProps={fieldProps}
                      schema={schema}
                      groupFilterItem={getGroupFilterItem(values.group_by)}
                      getSearch={getSearch}
                    />
                  )}
                </Field>
              </Fieldset>
              <Stack direction="row" sx={{ pt: 2 }} justify="flex-end">
                <Button
                  type="button"
                  text="Reset"
                  variant="text"
                  onClick={() => handleReset(setValues)}
                  data-testid="cdr-toolbar-filter-popover-reset-button"
                />
                <Button
                  type="submit"
                  text="Save"
                  sx={{ width: 'fit-content' }}
                  disabled={!isValid || !dirty}
                  data-testid="cdr-toolbar-filter-popover-save-button"
                />
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
