// Intl
import { useIntl } from 'react-intl';

import commaSeparated from 'assets/files/4g_comma_separated.csv';
import spaceSeparated from 'assets/files/4g_space_separated.csv';

export function CreateModalDescription() {
  const { formatMessage } = useIntl();

  return (
    <>
      <span>{formatMessage({ id: 'usims.form.usim.multiple_data_description' })}</span>
      <span>
        {formatMessage(
          { id: 'usims.form.usim.multiple_data_example' },
          {
            comma_separated: <a href={commaSeparated}>comma_separated.csv</a>,
            space_separated: <a href={spaceSeparated}>space_separated.csv</a>,
          }
        )}
      </span>
    </>
  );
}
