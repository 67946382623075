import { useIntl } from 'react-intl';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import LanguageSwitch from 'components/LanguageSwitch';
import { localeOptions } from 'utils/locale';

export default function Language() {
  const { formatMessage } = useIntl();

  if (localeOptions.length < 2) {
    return null;
  }

  return (
    <Panel title={formatMessage({ id: 'profile.language' })}>
      <PanelContent>
        <LanguageSwitch />
      </PanelContent>
    </Panel>
  );
}
