// import { User } from './user';

export enum MODAL_ID {
  MANAGE_TENANTS,
  EDIT_USER,
}

export enum RESOURCE { // convert to string in order to handle in hook
  USERS = 'users',
  /* NOTE: CC-1285 */
  SEGMENT = 'segment',
  QOS_PROFILE_4G = 'qosProfile4G',
  NODES = 'nodes',
  TENANTS = 'tenants',
  USIM_PROFILE = 'usimProfile',
}

interface BaseModal {
  id: MODAL_ID;
  data: {
    resource: RESOURCE;
    [key: string]: unknown;
  };
}

export interface ManageTenantsModal extends BaseModal {
  id: MODAL_ID.MANAGE_TENANTS;
  data: {
    resource: RESOURCE;
    id: string;
    cb: () => void;
  };
}

export interface EditUserModal extends BaseModal {
  id: MODAL_ID.EDIT_USER;
  data: {
    resource: RESOURCE;
  };
}
