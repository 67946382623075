import { useSelector } from 'react-redux';
import { CdrMetricOptions, CdrSchemaField, CdrTrafficVolumeMetricsField } from 'store/models/cdr';

export function useCdrTrafficVolumeTotal() {
  const total = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeTotal);
  return total;
}

export function useCdrTrafficVolumeTrendSeries() {
  const series = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeTrendSeries);
  return series;
}

export function useCdrTrafficVolumeRankingSeries() {
  const series = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeRankingSeries);
  return series;
}

export function useCdrTrafficVolumeSchema() {
  const schema = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeSchema);
  return schema;
}

export function useCdrTrafficVolumeAvailabilityDates() {
  const availabilityDates = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeAvailabilityDates);
  return availabilityDates;
}

export function useCdrTrafficVolumeRanking() {
  const ranking = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeRanking);
  return ranking;
}

export function useCdrTrafficVolumeDateRange() {
  const dateRange = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeDateRange);
  return dateRange;
}

export function useCdrTrafficVolumePeriod() {
  const period = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumePeriod);
  return period;
}

export function useCdrTrafficVolumeFilter() {
  const filter = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeFilter);
  return filter;
}

export function useCdrTrafficVolumeRankingMetric() {
  const metric = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeRankingMetric);
  return metric;
}

export function useCdrTrafficVolumeRankingGroupBy() {
  const groupBy = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeRankingGroupBy);
  return groupBy;
}

export function getCdrTrafficVolumeRankingMetricOptions(
  schema: CdrSchemaField[]
): CdrMetricOptions<CdrTrafficVolumeMetricsField>[] {
  const sortedFields: CdrTrafficVolumeMetricsField[] = ['totallink', 'downlink', 'uplink'];

  return sortedFields.map((field) => {
    const metricField = schema.find((schemaField) => schemaField.field === field) as CdrSchemaField;
    return {
      label: metricField.label,
      value: metricField.field as CdrTrafficVolumeMetricsField,
    };
  });
}

export function useCdrTrafficVolumeRankingMetricOptions(): CdrMetricOptions<CdrTrafficVolumeMetricsField>[] {
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();

  if (!trafficVolumeSchema.data) {
    return [];
  }

  return getCdrTrafficVolumeRankingMetricOptions(trafficVolumeSchema.data.schema);
}

export function useCdrTrafficVolumeGroupByOptions() {
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();

  if (!trafficVolumeSchema.data) {
    return [];
  }
  const {
    schema,
    global: { group },
  } = trafficVolumeSchema.data;

  return group.map((value) => ({
    value,
    label: schema.find((item) => item.field === value)?.label,
  }));
}

export function useCdrTrafficVolumeRankingCalendarDate() {
  const calendarDate = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeRankingCalendarDate);
  return calendarDate;
}

export function useCdrTrafficVolumeTimeseriesSchema() {
  const timeseriesSchema = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeTimeseriesSchema);
  return timeseriesSchema;
}

export function useCdrTrafficVolumeTimeseriesAvailabilityDates() {
  const timeseriesAvailabilityDates = useSelector(
    (state) => state.cdrReportsTrafficVolume.trafficVolumeTimeseriesAvailabilityDates
  );
  return timeseriesAvailabilityDates;
}

export function useCdrTrafficVolumeTimeseriesQuery() {
  const timeseriesQuery = useSelector((state) => state.cdrReportsTrafficVolume.trafficVolumeTimeseriesQuery);
  return timeseriesQuery;
}
