import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Ranking, TRAFFIC_RANK, RankingData } from '@athonet/ui/components/Data/Ranking';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import filesize from 'filesize';
import { isEntityLoading } from 'store/reducers';
import { Select } from '@athonet/ui/components/Input/Select';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Calendar } from '@athonet/ui/components/Data/Calendar';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import {
  getCdrTrafficDifferenceRanking,
  getCdrTrafficDifferenceRankingSeries,
  setCdrTrafficDifferenceFilter,
  setCdrTrafficDifferenceRankingCalendarDate,
  setCdrTrafficDifferenceRankingGroupBy,
} from 'store/actions/cdrReports/trafficDifference';
import {
  useCdrTrafficDifferenceDateRange,
  useCdrTrafficDifferencePeriod,
  useCdrTrafficDifferenceRankingMetric,
  useCdrTrafficDifferenceRankingSeries,
  useCdrTrafficDifferenceRankingCalendarDate,
  useCdrTrafficDifferenceRanking,
  useCdrTrafficDifferenceRankingGroupBy,
  useCdrTrafficDifferenceSourceNetworksAggregations,
} from 'store/selectors/cdrReports/trafficDifference';
import {
  getRankingCalendarData,
  getRankingChartData,
  getRankingGroupByLabel,
  rankingMetricLabel,
  getSelectedRankingCalendarDate,
} from '../utils/trafficRanking';
import { useCdrTrafficVolumeGroupByOptions, useCdrTrafficVolumeSchema } from 'store/selectors/cdrReports/trafficVolume';
import { CDR_SOURCE_NETWORKS_AGGREGATION_TYPE } from 'store/models/cdr';
import { useNavigate } from 'react-router-dom';

export function TrafficDifferenceRanking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();
  const trafficDifferenceRanking = useCdrTrafficDifferenceRanking();
  const trafficDifferenceDateRange = useCdrTrafficDifferenceDateRange();
  const trafficDifferencePeriod = useCdrTrafficDifferencePeriod();
  const trafficDifferenceRankingSeries = useCdrTrafficDifferenceRankingSeries();
  const trafficDifferenceRankingGroupBy = useCdrTrafficDifferenceRankingGroupBy();
  const trafficDifferenceRankingMetric = useCdrTrafficDifferenceRankingMetric();
  const trafficDifferenceRankingCalendarDate = useCdrTrafficDifferenceRankingCalendarDate();
  const trafficDifferenceGroupByOptions = useCdrTrafficVolumeGroupByOptions();
  const trafficDifferenceSourceNetworksAggregations = useCdrTrafficDifferenceSourceNetworksAggregations();

  const sourceNetworkAggregationDifference = useMemo(() => {
    return trafficDifferenceSourceNetworksAggregations?.find(
      (trafficDifferenceSourceNetworksAggregation) =>
        trafficDifferenceSourceNetworksAggregation.type === CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.DIFFERENCE
    )?.source_networks_aggregation;
  }, [trafficDifferenceSourceNetworksAggregations]);

  useEffect(() => {
    if (
      !trafficDifferenceDateRange ||
      !trafficDifferenceSourceNetworksAggregations ||
      !sourceNetworkAggregationDifference
    ) {
      return;
    }

    dispatch(
      getCdrTrafficDifferenceRankingSeries({
        start_date: trafficDifferenceDateRange.start,
        end_date: trafficDifferenceDateRange.end,
        period: trafficDifferencePeriod,
        source_networks_aggregation: sourceNetworkAggregationDifference,
      })
    );
  }, [
    dispatch,
    trafficDifferencePeriod,
    trafficDifferenceDateRange,
    trafficDifferenceSourceNetworksAggregations,
    sourceNetworkAggregationDifference,
  ]);

  useEffect(() => {
    if (
      !trafficDifferenceRankingCalendarDate ||
      !trafficDifferenceRankingMetric ||
      !trafficDifferenceRankingGroupBy ||
      !trafficDifferenceSourceNetworksAggregations ||
      !sourceNetworkAggregationDifference
    ) {
      return;
    }

    dispatch(
      getCdrTrafficDifferenceRanking({
        date: trafficDifferenceRankingCalendarDate,
        period: trafficDifferencePeriod,
        metric: trafficDifferenceRankingMetric,
        group_by: trafficDifferenceRankingGroupBy,
        source_networks_aggregation: sourceNetworkAggregationDifference,
      })
    );
  }, [
    trafficDifferenceSourceNetworksAggregations,
    dispatch,
    trafficDifferencePeriod,
    trafficDifferenceRankingCalendarDate,
    trafficDifferenceRankingGroupBy,
    trafficDifferenceRankingMetric,
    sourceNetworkAggregationDifference,
  ]);

  const rankingDifferenceChartRanking = useMemo(() => {
    return getRankingChartData(trafficDifferenceRanking.data);
  }, [trafficDifferenceRanking.data]);

  const trafficDifferenceRankingGroupByLabel = useMemo(() => {
    return getRankingGroupByLabel({ schema: trafficVolumeSchema.data, groupBy: trafficDifferenceRankingGroupBy });
  }, [trafficDifferenceRankingGroupBy, trafficVolumeSchema.data]);

  const trafficDifferenceRankingMetricLabel = useMemo(() => {
    return rankingMetricLabel({ schema: trafficVolumeSchema.data, metric: trafficDifferenceRankingMetric });
  }, [trafficDifferenceRankingMetric, trafficVolumeSchema.data]);

  const handleAnalysisTypeChange = useCallback(
    (e) => {
      dispatch(setCdrTrafficDifferenceRankingGroupBy(e.target.value));
    },
    [dispatch]
  );

  const handleOnClickValue = useCallback(
    (item: RankingData) => {
      dispatch(
        setCdrTrafficDifferenceFilter({
          group_by: trafficDifferenceRankingGroupBy,
          group_filter: item.field,
        })
      );
      navigate('/reports/traffic-difference/trend');
    },
    [dispatch, navigate, trafficDifferenceRankingGroupBy]
  );

  const selectedCalendarDate = useMemo(() => {
    return getSelectedRankingCalendarDate({
      period: trafficDifferencePeriod,
      calendarDate: trafficDifferenceRankingCalendarDate,
    });
  }, [trafficDifferencePeriod, trafficDifferenceRankingCalendarDate]);

  const calendarData = useMemo(() => {
    return getRankingCalendarData({
      seriesData: trafficDifferenceRankingSeries.data,
      rankingMetric: trafficDifferenceRankingMetric,
    });
  }, [trafficDifferenceRankingMetric, trafficDifferenceRankingSeries.data]);

  const handleCalendarClick = useCallback(
    (_, date) => {
      dispatch(setCdrTrafficDifferenceRankingCalendarDate(date));
    },
    [dispatch]
  );

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <span data-test-id="calendar-panel" style={{ flexGrow: 1 }}>
          <Panel
            // TODO intl
            title={`${trafficDifferenceRankingMetricLabel} Traffic Difference Calendar`}
            tooltip={`Pick a ${trafficDifferencePeriod} on the Calendar to view the ${trafficDifferenceRankingMetricLabel} Traffic Difference Ranking results`}
            collapsible
          >
            <PanelContent>
              {isEntityLoading(trafficDifferenceRankingSeries) ? (
                <Skeleton height={150} variant="rectangular" />
              ) : (
                <Calendar
                  period={trafficDifferencePeriod}
                  data={calendarData}
                  onClick={handleCalendarClick}
                  formatTooltipValue={(value) => filesize(value, { base: 2, standard: 'jedec' })}
                  data-testid="cdr-traffic-difference-ranking-calendar"
                />
              )}
            </PanelContent>
          </Panel>
        </span>
      </GridItem>
      {trafficDifferenceRankingCalendarDate && (
        <GridItem
          size={{
            xs: 12,
          }}
        >
          <span data-test-id="ranking-grid" style={{ flexGrow: 1 }}>
            <Panel
              title={`${trafficDifferenceRankingMetricLabel} Traffic Difference Ranking`}
              tooltip={`${trafficDifferenceRankingMetricLabel} Traffic Difference Ranking displays most/least ${trafficDifferenceRankingMetricLabel} traffic corresponding to the ${trafficDifferencePeriod} selected in the Traffic Difference Calendar`}
              headerActionsComponent={
                <>
                  <Chip
                    id={selectedCalendarDate || ''}
                    label={selectedCalendarDate}
                    color="secondary"
                    data-testid="cdr-traffic-difference-ranking-date-chip"
                  />
                  <Box sx={{ width: { xs: '100%', md: '150px' } }}>
                    <Select
                      label="Analysis Type"
                      onChange={handleAnalysisTypeChange}
                      size={'small'}
                      value={trafficDifferenceRankingGroupBy}
                      data-testid="cdr-traffic-difference-ranking-analysis-type-select"
                    >
                      {trafficDifferenceGroupByOptions?.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              }
            >
              <PanelContent title={`Most ${trafficDifferenceRankingMetricLabel} traffic difference`}>
                <Ranking
                  data={rankingDifferenceChartRanking?.top || []}
                  maxValue={rankingDifferenceChartRanking?.maxValue || 0}
                  minValue={rankingDifferenceChartRanking?.minValue || 0}
                  fieldLabel={trafficDifferenceRankingGroupByLabel}
                  valueLabel={`Traffic (${trafficDifferenceRankingMetricLabel})`}
                  valueFormatter={(value) => filesize(value, { base: 2, standard: 'jedec' })}
                  loading={isEntityLoading(trafficDifferenceRanking)}
                  onClickValue={handleOnClickValue}
                  data-testid="cdr-traffic-difference-ranking-most"
                />
              </PanelContent>
              <>
                {rankingDifferenceChartRanking?.bottom ? (
                  <PanelContent title={`Least ${trafficDifferenceRankingMetricLabel} traffic difference`}>
                    <Ranking
                      rank={TRAFFIC_RANK.BOTTOM}
                      data={rankingDifferenceChartRanking?.bottom || []}
                      maxValue={rankingDifferenceChartRanking?.maxValue || 0}
                      minValue={rankingDifferenceChartRanking?.minValue || 0}
                      fieldLabel={trafficDifferenceRankingGroupByLabel}
                      valueLabel={`Traffic (${trafficDifferenceRankingMetricLabel})`}
                      valueFormatter={(value) => filesize(value, { base: 2, standard: 'jedec' })}
                      loading={isEntityLoading(trafficDifferenceRanking)}
                      onClickValue={handleOnClickValue}
                      data-testid="cdr-traffic-difference-ranking-least"
                    />
                  </PanelContent>
                ) : null}
              </>
            </Panel>
          </span>
        </GridItem>
      )}
    </GridContainer>
  );
}
