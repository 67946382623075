// TODO: dome, remove all of this, SITES are now part of...
// ...the tenants field in the store and already consumed by the landing page

import { forwardGeolocate } from '@athonet/ui/utils/mapbox';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { Site } from 'store/models/site';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { TENANTS_ACTION_TYPE } from './tenants';
import { sentryLogError } from 'sentry';

export const FILTERS_LOADING = 'SITES_FILTERS_LOADING';
export const FILTERS_SUCCESS = 'SITES_FILTERS_SUCCESS';
export const FILTERS_FAILURE = 'SITES_FILTERS_FAILURE';
export const FILTERS_SET = 'SITES_FILTERS_SET';
export const FILTERS_INJECT = 'SITES_FILTERS_INJECT';

export const SORT_SET = 'SITES_SORT_SET';

export const LIST_LOADING = 'SITES_LIST_LOADING';
export const LIST_SUCCESS = 'SITES_LIST_SUCCESS';
export const LIST_FAILURE = 'SITES_LIST_FAILURE';
export const LIST_CLEAR = 'SITES_LIST_CLEAR';

export function filtersLoading() {
  return {
    type: FILTERS_LOADING,
  };
}

export function filtersSuccess(payload: unknown) {
  return {
    type: FILTERS_SUCCESS,
    payload,
  };
}

export function filtersFailure() {
  return {
    type: FILTERS_FAILURE,
  };
}

export function filtersSet(payload: unknown) {
  return {
    type: FILTERS_SET,
    payload,
  };
}

export function filtersInject(payload: unknown) {
  return {
    type: FILTERS_INJECT,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: LIST_FAILURE,
  };
}

export function listClear() {
  return {
    type: LIST_CLEAR,
  };
}

type SitePayload = {
  name: string;
  tenant_id: string;
  address: string;
  country: string;
  additional_info: string;
  network_name: string;
  timezone: string;
};

async function createSiteFormData(site: SitePayload, accessToken: string) {
  const formData = new FormData();
  formData.append('name', site.name);
  formData.append('tenant_id', site.tenant_id);
  formData.append('address', site.address);
  formData.append('country', site.country);
  formData.append('additional_info', site.additional_info);
  formData.append('network_name', site.network_name);
  formData.append('timezone', site.timezone);
  formData.append('coords', '');

  if (site.address && site.country) {
    try {
      const forwardedLocation = await forwardGeolocate({
        accessToken,
        query: `${site.address}, ${site.country}`,
      });

      if (!forwardedLocation) {
        return;
      }

      formData.append('coords', forwardedLocation.center.join(','));
    } catch (e) {
      sentryLogError(e);
    }
  }
  return formData;
}

export function updateSite(id: Site['id'], site: SitePayload): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { bootstrap } = getState();

    if (!bootstrap || !bootstrap.mapbox_token) {
      return;
    }

    const formData = await createSiteFormData(site, bootstrap.mapbox_token);

    const options: AxiosRequestConfig = {
      url: `${config.apis.updateSite.replace('{id}', id)}`,
      data: formData,
      method: 'PUT',
    };

    return dispatch(fetchData(options));
  };
}

export function createSite(values: SitePayload): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { bootstrap } = getState();

    if (!bootstrap || !bootstrap.mapbox_token) {
      return;
    }

    const formData = await createSiteFormData(values, bootstrap.mapbox_token);

    const options: AxiosRequestConfig = {
      url: config.apis.createSite,
      data: formData,
      method: 'POST',
    };

    return dispatch(fetchData(options));
  };
}

export function getSites(): Thunk<Promise<Site[] | void>> {
  return async (dispatch) => {
    dispatch({
      type: TENANTS_ACTION_TYPE.SITES_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        url: `${config.apis.getSites
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', '0')
          .replace('{filters}', '')}`,
        method: 'GET',
      };

      let result = await dispatch(
        fetchData<{
          items: Site[];
        }>(options)
      );

      const locatedSites = result.items.filter(({ address, country, coords }) => address && country && !coords);

      if (locatedSites.length) {
        await Promise.all(locatedSites.map(async (site) => dispatch(updateSite(site.id, site))));
        result = await dispatch(
          fetchData<{
            items: Site[];
          }>(options)
        );
      }

      dispatch({
        type: TENANTS_ACTION_TYPE.SITES_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: TENANTS_ACTION_TYPE.SITES_FAILURE,
      });
    }
  };
}

export function getSite(siteId: Site['id']): Thunk<Promise<Site | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.getSite.replace('{id}', siteId),
      method: 'GET',
    };

    // TODO: forward geolocate

    try {
      return dispatch(fetchData<Site>(options));
    } catch (e) {
      sentryLogError(e);
      return;
    }
  };
}
