import { useCallback, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';
import { SelectOption } from 'utils/forms';
import { User } from 'store/models/user';
import { EditUserPayload } from 'store/actions/users';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { showErrorToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { sentryLogError } from 'sentry';

type EditProps = {
  getData: () => Promise<User>;
  dataOptions: { roles?: SelectOption[] };
  onClose: () => void;
  onEdit: (values: EditUserPayload, callback: () => boolean) => Promise<void>;
};

export default function Edit({ getData, onClose, onEdit, dataOptions: { roles } }: EditProps) {
  const { formatMessage } = useIntl();
  const [user, setUser] = useState<User | null>(null);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const handleGetUser = useCallback(async () => {
    try {
      const fetchedUser = await getData();
      setUser(fetchedUser);
      return fetchedUser;
    } catch (e) {
      sentryLogError(e);
      setError(true);
      dispatch(showErrorToast());
      return null;
    }
  }, [dispatch, getData]);

  const schema = useMemo(
    () =>
      object().shape({
        fullname: string().required(),
        role: string().required(),
        phone: string(),
        email: string().email().when('realm', {
          is: AUTH_PROVIDER.ENTERPRISE,
          then: string().email().required(),
          otherwise: string().email(),
        }),
      }),
    []
  );

  const fieldset = useMemo(() => {
    const fieldsData = ['fullname', 'role', 'phone'].map((field) => ({
      name: field,
      label: formatMessage({ id: `users.form.user.${field}` }),
      placeholder: formatMessage({ id: `users.form.user.${field}.placeholder` }),
      error: formatMessage({ id: `users.form.user.${field}.error` }),
      ...(field === 'role' && { options: roles }),
    }));

    if (user?.realm === AUTH_PROVIDER.ENTERPRISE) {
      fieldsData.push({
        name: 'email',
        label: formatMessage({ id: 'users.form.user.email.label' }),
        placeholder: formatMessage({ id: 'users.form.user.email.placeholder' }),
        error: formatMessage({ id: 'users.form.user.email.error' }),
      });
    }

    return error
      ? []
      : [
          {
            title: formatMessage({ id: 'users.form.user.data' }),
            data: fieldsData,
          },
        ];
  }, [error, formatMessage, roles, user?.realm]);

  return (
    <FormStyled>
      <Base
        getData={handleGetUser}
        schema={schema}
        isOpen={true}
        onClose={onClose}
        fieldset={fieldset}
        onSubmit={onEdit}
        modalTitle={`${formatMessage({ id: 'users.editUser' })} - ${user?.name}`}
        ComponentTop={
          error ? (
            <Box sx={{ px: 2 }}>
              <Alert severity="error" message={formatMessage({ id: 'common.noData' })} />
            </Box>
          ) : undefined
        }
        disableContinue={error}
        // TODO: remove all of the following props and type edit/base form component
        initials={undefined}
        modalDescription={undefined}
        elColor={undefined}
        elClassName={undefined}
        elLabel={undefined}
        elVariant={undefined}
        elIcon={undefined}
        elDisabled={undefined}
        showContinue={undefined}
        labelContinue={undefined}
        disableCancel={undefined}
        showCancel={undefined}
        labelCancel={undefined}
        ComponentBottom={undefined}
        StyleFormWrapper={undefined}
        keepOpen={undefined}
        isFormLoading={undefined}
        confirmation={undefined}
        confirmationField={undefined}
      />
    </FormStyled>
  );
}
