import { ShowToastAction, HideToastAction, TOAST_ACTION_TYPE } from 'store/actions/toast';
import { Toast } from 'store/models/toast';

export type ToastState = Toast | null;

const initialState: ToastState = null;

export default function toastsReducer(state = initialState, action: ShowToastAction | HideToastAction): ToastState {
  switch (action.type) {
    case TOAST_ACTION_TYPE.SHOW_TOAST:
      return action.payload;

    case TOAST_ACTION_TYPE.HIDE_TOAST:
      return null;

    default:
      return state;
  }
}
