import { getSite } from 'store/actions/sites';
import { UsimProfile } from './../models/usimProfile';
import { Thunk } from '.';
import { getFileName } from 'utils/getFileName';
import config from 'config';
import { exportViews } from './exportViews';
import { AxiosRequestConfig } from 'axios';
import { fetchData } from './fetchData';
import { Site } from 'store/models/site';
import { sentryLogError } from 'sentry';

export const FILTERS_LOADING = 'USIM_PROFILES_FILTERS_LOADING';
export const FILTERS_SUCCESS = 'USIM_PROFILES_FILTERS_SUCCESS';
export const FILTERS_FAILURE = 'USIM_PROFILES_FILTERS_FAILURE';
export const FILTERS_SET = 'USIM_PROFILES_FILTERS_SET';
export const FILTERS_INJECT = 'USIM_PROFILES_FILTERS_INJECT';

export const SORT_SET = 'USIM_PROFILES_SORT_SET';

export const LIST_LOADING = 'USIM_PROFILES_LIST_LOADING';
export const LIST_SUCCESS = 'USIM_PROFILES_LIST_SUCCESS';
export const LIST_FAILURE = 'USIM_PROFILES_LIST_FAILURE';
export const LIST_CLEAR = 'USIM_PROFILES_LIST_CLEAR';

export const ENTITY_LOADING = 'USIM_PROFILES_ENTITY_LOADING';
export const ENTITY_SUCCESS = 'USIM_PROFILES_ENTITY_SUCCESS';
export const ENTITY_FAILURE = 'USIM_PROFILES_ENTITY_FAILURE';

export function filtersLoading() {
  return {
    type: FILTERS_LOADING,
  };
}

export function filtersSuccess(payload: unknown) {
  return {
    type: FILTERS_SUCCESS,
    payload,
  };
}

export function filtersFailure() {
  return {
    type: FILTERS_FAILURE,
  };
}

export function filtersSet(payload: unknown) {
  return {
    type: FILTERS_SET,
    payload,
  };
}

export function filtersInject(payload: unknown) {
  return {
    type: FILTERS_INJECT,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: LIST_FAILURE,
  };
}
export function listClear() {
  return {
    type: LIST_CLEAR,
  };
}

export function exportUsimProfilesData(filter: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const filtersQuery = filter.replace('&', '?');
    return dispatch(
      exportViews({
        fileName: getFileName('usim_profiles'),
        url: `${config.apis.exportUsimProfiles}`,
        data: null,
        filtersQuery,
      })
    );
  };
}

export function getProfile(id: UsimProfile['id'], refresh: boolean = false): Thunk<Promise<void>> {
  return async (dispatch) => {
    if (!refresh) {
      dispatch({
        type: ENTITY_LOADING,
      });
    }
    const options: AxiosRequestConfig = {
      url: config.apis.getUsimProfile.replace('{id}', id),
    };

    try {
      const profiles = await dispatch(fetchData<{ items: UsimProfile[] }>(options));
      if (!profiles.items.length) {
        throw new Error('No profile found');
      }
      const profile = profiles.items[0];

      let site: Site | void;

      try {
        if (profile.site) {
          site = await dispatch(getSite(profile.site.id));
        }
      } catch (error) {
        site = undefined;
      }

      dispatch({
        type: ENTITY_SUCCESS,
        payload: {
          ...profile,
          site,
        },
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: ENTITY_FAILURE,
      });
    }
  };
}
