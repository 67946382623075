import { Moment } from 'moment';
import { CdrPeriod, CdrRankingCalendarDate, CdrSchema, CdrTrafficVolumeMetrics } from 'store/models/cdr';
import { CdrRankingState } from 'store/reducers/cdrReports';
import { CdrTrafficVolumeSeriesState } from 'store/reducers/cdrReports/trafficVolume';
import { getTooltipDateTitle } from './utils';

export function getRankingChartData(trafficRanking: CdrRankingState | null) {
  if (!trafficRanking) {
    return;
  }

  const top = trafficRanking.top.map((x) => ({
    field: x.group_by.value,
    value: x.metric.value,
  }));

  const bottom = trafficRanking.bottom?.map((x) => {
    return {
      field: x.group_by.value,
      value: x.metric.value,
    };
  });

  const maxValue = Math.max(...top.map((x) => x.value));
  const minValue = bottom ? Math.min(...bottom.map((x) => x.value)) : Math.min(...top.map((x) => x.value));

  return { top, bottom, maxValue, minValue };
}

export function getRankingGroupByLabel({ schema, groupBy }: { schema: CdrSchema | null; groupBy: string }) {
  return schema?.schema.find((item) => item.field === groupBy)?.label || '';
}

export function rankingMetricLabel({ schema, metric }: { schema: CdrSchema | null; metric: string }) {
  return schema?.schema.find((item) => item.field === metric)?.label || '';
}

export function getRankingCalendarData({
  seriesData,
  rankingMetric,
}: {
  seriesData: CdrTrafficVolumeSeriesState | null;
  rankingMetric: keyof CdrTrafficVolumeMetrics<string>;
}): [Moment, number][] | undefined {
  return seriesData?.map(({ datetime, ...metric }) => {
    return [datetime, metric[rankingMetric] !== null ? Number(metric[rankingMetric]) : 0];
  });
}

export function getSelectedRankingCalendarDate({
  period,
  calendarDate,
}: {
  period: CdrPeriod;
  calendarDate: CdrRankingCalendarDate;
}) {
  const format = getTooltipDateTitle(period);
  return calendarDate?.format(format);
}
