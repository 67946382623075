import styled from 'styled-components';

export const MasonryStyled = styled.div`
  .masonry-grid {
    display: flex;
    margin-left: -20px;
    width: inherit;
  }

  .masonry-column {
    padding-left: 20px;
    background-clip: padding-box;

    > div {
      margin-bottom: 20px;
    }
  }
`;
