import { Owner } from 'store/models/tenant';

export function tenantsCellRenderer(tenants: Owner[] | Owner) {
  if (Array.isArray(tenants)) {
    if (tenants.length === 0) {
      return '-';
    } else if (tenants.length === 1) {
      return tenants[0].name;
    } else {
      let title = tenants.reduce((acc, tenant) => acc + '\n' + tenant.name, '');
      return (
        <span title={title}>
          {tenants[0].name} (+{tenants.length - 1} more...)
        </span>
      );
    }
  } else {
    return tenants.name;
  }
}
