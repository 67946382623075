import { Button } from '@athonet/ui/components/Input/Button';
import { Select } from '@athonet/ui/components/Input/Select';
import { TextField } from '@athonet/ui/components/Input/TextField';
// import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
// import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { keysRegExp } from 'store/models/usim';
import { number, object, string } from 'yup';
import { CreateUsim5gStepProps } from '../CreateUsimCard5gContent';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';

export function Step3({ onCompleteStep, initialValues, ...step }: CreateUsim5gStepProps) {
  const { formatMessage } = useIntl();

  const handleKeyTypeChange = useCallback((e, setFieldValue) => {
    const v = e.target.value;
    setFieldValue('key_type', v);
    if (v === 0) {
      setFieldValue('op', '');
      setFieldValue('opc', '');
    }
    // OP
    if (v === 1) {
      setFieldValue('opc', '');
    }
    // OPC
    if (v === 2) {
      setFieldValue('op', '');
    }
  }, []);

  const validationSchema = useMemo(() => {
    return object().shape({
      k: string().matches(keysRegExp).required(),
      /* TODO: update when encryption is contemplated again */
      key_type: number().min(1).max(2),
      /* TODO: update when encryption is contemplated again */
      encrypt: number().min(0).max(0),
      // use_key: string().when(['encrypt', 'use_default_tk'], {
      //   is: (encryptValue, use_default_tk) => encryptValue === 1 && !use_default_tk,
      //   then: string().matches(tkRegExp),
      //   otherwise: string().nullable().matches(tkRegExp),
      // }),
      op: string().when(['key_type'], {
        is: 1,
        then: string().matches(keysRegExp).required(),
        otherwise: string().nullable().matches(keysRegExp),
      }),
      opc: string().when(['key_type'], {
        is: 2,
        then: string().matches(keysRegExp).required(),
        otherwise: string().nullable().matches(keysRegExp),
      }),
    });
  }, []);

  const getInputTextsError = useCallback(
    //TODOPS use unique hook
    (inputname, errors, touched) => ({
      label: formatMessage({ id: `usims.form.usim.${inputname}.label` }),
      placeholder: formatMessage({ id: `usims.form.usim.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `usims.form.usim.${inputname}.error` }),
      }),
    }),

    [formatMessage]
  );

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(2, values);
    },
    [onCompleteStep]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.keys' })} {...step}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack spacing={2} align="flex-end">
                <Field name="k" key="k">
                  {({ field }: FieldProps<string>) => (
                    <TextField fullWidth {...field} size="small" {...getInputTextsError('k', errors, touched)} />
                  )}
                </Field>
                <Field name="encrypt" key="encrypt">
                  {({ field }: FieldProps<string>) => (
                    <Select
                      {...field}
                      size="small"
                      /* TODO: update when encryption is contemplated again */
                      // label={formatMessage({ id: 'usims.form.usim.encrypt' })}
                      label="K type: Plain"
                      onChange={(e) => setFieldValue('encrypt', e.target.value)}
                      multiple={false}
                      /* TODO: update when encryption is contemplated again */
                      disabled
                    >
                      {/* <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.encrypt.1' })}</MenuItem> */}
                      <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.encrypt.0' })}</MenuItem>
                    </Select>
                  )}
                </Field>
                {/* /* TODO: update when encryption is contemplated again */}
                {/* {values['encrypt'] === 1 && (
                <Field name="use_default_tk" key="use_default_tk">
                  {({ field }: FieldProps<string>) => (
                    <ToggleButtonGroup
                      sx={{ alignSelf: 'flex-start' }}
                      key="use_default_tk"
                      size="small"
                      color={'secondary'}
                      exclusive={true}
                      onChange={(v) => {
                        if (v !== null) {
                          setFieldValue('use_default_tk', v);
                        }
                      }}
                      data-testid={'field-use_default_tk'}
                      value={values['use_default_tk']}
                    >
                      <ToggleButton disabled={values['use_default_tk'] === true} value={true}>
                        {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.true' })}
                      </ToggleButton>
                      <ToggleButton disabled={values['use_default_tk'] === false} value={false}>
                        {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.false' })}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Field>
              )}
              {values['encrypt'] === 1 && !values['use_default_tk'] && (
                <Field name="use_key" key="use_key">
                  {({ field }: FieldProps<string>) => (
                    <TextField fullWidth size="small" {...field} {...getInputTextsError('use_key', errors, touched)} />
                  )}
                </Field>
              )} */}
                <Field name="key_type" key="key_type">
                  {({ field }: FieldProps<string>) => (
                    <Select
                      size="small"
                      {...field}
                      label={formatMessage({ id: `usims.form.usim.key_type` })}
                      value={values['key_type']}
                      onChange={(e) => handleKeyTypeChange(e, setFieldValue)}
                    >
                      {/* /* TODO: update when encryption is contemplated again  */}
                      {/* <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.key_type.defaultOP_5g' })}</MenuItem> */}
                      <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.key_type.customOP' })}</MenuItem>
                      <MenuItem value={2}>{formatMessage({ id: 'usims.form.usim.key_type.customOPC' })}</MenuItem>
                    </Select>
                  )}
                </Field>
                {values['key_type'] === 1 && (
                  <Field name="op" key="op">
                    {({ field }: FieldProps<string>) => (
                      <TextField fullWidth size="small" {...field} {...getInputTextsError('op', errors, touched)} />
                    )}
                  </Field>
                )}
                {values['key_type'] === 2 && (
                  <Field name="opc" key="opc">
                    {({ field }: FieldProps<string>) => (
                      <TextField fullWidth size="small" {...field} {...getInputTextsError('opc', errors, touched)} />
                    )}
                  </Field>
                )}
                <Button type="submit" variant="outlined" text={formatMessage({ id: 'common.form.next' })} />
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Step>
  );
}
